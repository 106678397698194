'use client';

import { Accordion } from 'flowbite-react';

export default function CollapseAll({contents, info}) {
    let output = contents.map((content,i) =>{
        return (
            <Accordion.Panel key={i}>
                <h2 className='bg-gray-100 p-3'>
                    {content.title}
                </h2>
                <Accordion.Title className='text-sm'>
                lihat detail
                </Accordion.Title>
                <Accordion.Content className='p-0'>
                {content.content}
                </Accordion.Content>
                <Accordion.Content>
                {info.map((item,j) =>{
                  return (
                    <p key={j} className='mb-3 text-xs text-gray-400'>
                      {item}
                    </p>
                  )
                })}
                </Accordion.Content>
            </Accordion.Panel>
        )
    })
    
  return (
    <Accordion collapseAll>
      {output}
    </Accordion>
  )
}