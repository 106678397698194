export function getAdministrativeData() {
    let provinces = [
        {
          "id": 11,
          "name": "ACEH"
        },
        {
          "id": 12,
          "name": "SUMATERA UTARA"
        },
        {
          "id": 13,
          "name": "SUMATERA BARAT"
        },
        {
          "id": 14,
          "name": "RIAU"
        },
        {
          "id": 15,
          "name": "JAMBI"
        },
        {
          "id": 16,
          "name": "SUMATERA SELATAN"
        },
        {
          "id": 17,
          "name": "BENGKULU"
        },
        {
          "id": 18,
          "name": "LAMPUNG"
        },
        {
          "id": 19,
          "name": "KEPULAUAN BANGKA BELITUNG"
        },
        {
          "id": 21,
          "name": "KEPULAUAN RIAU"
        },
        {
          "id": 31,
          "name": "DKI JAKARTA"
        },
        {
          "id": 32,
          "name": "JAWA BARAT"
        },
        {
          "id": 33,
          "name": "JAWA TENGAH"
        },
        {
          "id": 34,
          "name": "DI YOGYAKARTA"
        },
        {
          "id": 35,
          "name": "JAWA TIMUR"
        },
        {
          "id": 36,
          "name": "BANTEN"
        },
        {
          "id": 51,
          "name": "BALI"
        },
        {
          "id": 52,
          "name": "NUSA TENGGARA BARAT"
        },
        {
          "id": 53,
          "name": "NUSA TENGGARA TIMUR"
        },
        {
          "id": 61,
          "name": "KALIMANTAN BARAT"
        },
        {
          "id": 62,
          "name": "KALIMANTAN TENGAH"
        },
        {
          "id": 63,
          "name": "KALIMANTAN SELATAN"
        },
        {
          "id": 64,
          "name": "KALIMANTAN TIMUR"
        },
        {
          "id": 65,
          "name": "KALIMANTAN UTARA"
        },
        {
          "id": 71,
          "name": "SULAWESI UTARA"
        },
        {
          "id": 72,
          "name": "SULAWESI TENGAH"
        },
        {
          "id": 73,
          "name": "SULAWESI SELATAN"
        },
        {
          "id": 74,
          "name": "SULAWESI TENGGARA"
        },
        {
          "id": 75,
          "name": "GORONTALO"
        },
        {
          "id": 76,
          "name": "SULAWESI BARAT"
        },
        {
          "id": 81,
          "name": "MALUKU"
        },
        {
          "id": 82,
          "name": "MALUKU UTARA"
        },
        {
          "id": 91,
          "name": "PAPUA BARAT"
        },
        {
          "id": 94,
          "name": "PAPUA"
        }
      ]
    let regencies = [
        {
          "id": 1101,
          "province_id": 11,
          "name": "KABUPATEN SIMEULUE"
        },
        {
          "id": 1102,
          "province_id": 11,
          "name": "KABUPATEN ACEH SINGKIL"
        },
        {
          "id": 1103,
          "province_id": 11,
          "name": "KABUPATEN ACEH SELATAN"
        },
        {
          "id": 1104,
          "province_id": 11,
          "name": "KABUPATEN ACEH TENGGARA"
        },
        {
          "id": 1105,
          "province_id": 11,
          "name": "KABUPATEN ACEH TIMUR"
        },
        {
          "id": 1106,
          "province_id": 11,
          "name": "KABUPATEN ACEH TENGAH"
        },
        {
          "id": 1107,
          "province_id": 11,
          "name": "KABUPATEN ACEH BARAT"
        },
        {
          "id": 1108,
          "province_id": 11,
          "name": "KABUPATEN ACEH BESAR"
        },
        {
          "id": 1109,
          "province_id": 11,
          "name": "KABUPATEN PIDIE"
        },
        {
          "id": 1110,
          "province_id": 11,
          "name": "KABUPATEN BIREUEN"
        },
        {
          "id": 1111,
          "province_id": 11,
          "name": "KABUPATEN ACEH UTARA"
        },
        {
          "id": 1112,
          "province_id": 11,
          "name": "KABUPATEN ACEH BARAT DAYA"
        },
        {
          "id": 1113,
          "province_id": 11,
          "name": "KABUPATEN GAYO LUES"
        },
        {
          "id": 1114,
          "province_id": 11,
          "name": "KABUPATEN ACEH TAMIANG"
        },
        {
          "id": 1115,
          "province_id": 11,
          "name": "KABUPATEN NAGAN RAYA"
        },
        {
          "id": 1116,
          "province_id": 11,
          "name": "KABUPATEN ACEH JAYA"
        },
        {
          "id": 1117,
          "province_id": 11,
          "name": "KABUPATEN BENER MERIAH"
        },
        {
          "id": 1118,
          "province_id": 11,
          "name": "KABUPATEN PIDIE JAYA"
        },
        {
          "id": 1171,
          "province_id": 11,
          "name": "KOTA BANDA ACEH"
        },
        {
          "id": 1172,
          "province_id": 11,
          "name": "KOTA SABANG"
        },
        {
          "id": 1173,
          "province_id": 11,
          "name": "KOTA LANGSA"
        },
        {
          "id": 1174,
          "province_id": 11,
          "name": "KOTA LHOKSEUMAWE"
        },
        {
          "id": 1175,
          "province_id": 11,
          "name": "KOTA SUBULUSSALAM"
        },
        {
          "id": 1201,
          "province_id": 12,
          "name": "KABUPATEN NIAS"
        },
        {
          "id": 1202,
          "province_id": 12,
          "name": "KABUPATEN MANDAILING NATAL"
        },
        {
          "id": 1203,
          "province_id": 12,
          "name": "KABUPATEN TAPANULI SELATAN"
        },
        {
          "id": 1204,
          "province_id": 12,
          "name": "KABUPATEN TAPANULI TENGAH"
        },
        {
          "id": 1205,
          "province_id": 12,
          "name": "KABUPATEN TAPANULI UTARA"
        },
        {
          "id": 1206,
          "province_id": 12,
          "name": "KABUPATEN TOBA SAMOSIR"
        },
        {
          "id": 1207,
          "province_id": 12,
          "name": "KABUPATEN LABUHAN BATU"
        },
        {
          "id": 1208,
          "province_id": 12,
          "name": "KABUPATEN ASAHAN"
        },
        {
          "id": 1209,
          "province_id": 12,
          "name": "KABUPATEN SIMALUNGUN"
        },
        {
          "id": 1210,
          "province_id": 12,
          "name": "KABUPATEN DAIRI"
        },
        {
          "id": 1211,
          "province_id": 12,
          "name": "KABUPATEN KARO"
        },
        {
          "id": 1212,
          "province_id": 12,
          "name": "KABUPATEN DELI SERDANG"
        },
        {
          "id": 1213,
          "province_id": 12,
          "name": "KABUPATEN LANGKAT"
        },
        {
          "id": 1214,
          "province_id": 12,
          "name": "KABUPATEN NIAS SELATAN"
        },
        {
          "id": 1215,
          "province_id": 12,
          "name": "KABUPATEN HUMBANG HASUNDUTAN"
        },
        {
          "id": 1216,
          "province_id": 12,
          "name": "KABUPATEN PAKPAK BHARAT"
        },
        {
          "id": 1217,
          "province_id": 12,
          "name": "KABUPATEN SAMOSIR"
        },
        {
          "id": 1218,
          "province_id": 12,
          "name": "KABUPATEN SERDANG BEDAGAI"
        },
        {
          "id": 1219,
          "province_id": 12,
          "name": "KABUPATEN BATU BARA"
        },
        {
          "id": 1220,
          "province_id": 12,
          "name": "KABUPATEN PADANG LAWAS UTARA"
        },
        {
          "id": 1221,
          "province_id": 12,
          "name": "KABUPATEN PADANG LAWAS"
        },
        {
          "id": 1222,
          "province_id": 12,
          "name": "KABUPATEN LABUHAN BATU SELATAN"
        },
        {
          "id": 1223,
          "province_id": 12,
          "name": "KABUPATEN LABUHAN BATU UTARA"
        },
        {
          "id": 1224,
          "province_id": 12,
          "name": "KABUPATEN NIAS UTARA"
        },
        {
          "id": 1225,
          "province_id": 12,
          "name": "KABUPATEN NIAS BARAT"
        },
        {
          "id": 1271,
          "province_id": 12,
          "name": "KOTA SIBOLGA"
        },
        {
          "id": 1272,
          "province_id": 12,
          "name": "KOTA TANJUNG BALAI"
        },
        {
          "id": 1273,
          "province_id": 12,
          "name": "KOTA PEMATANG SIANTAR"
        },
        {
          "id": 1274,
          "province_id": 12,
          "name": "KOTA TEBING TINGGI"
        },
        {
          "id": 1275,
          "province_id": 12,
          "name": "KOTA MEDAN"
        },
        {
          "id": 1276,
          "province_id": 12,
          "name": "KOTA BINJAI"
        },
        {
          "id": 1277,
          "province_id": 12,
          "name": "KOTA PADANGSIDIMPUAN"
        },
        {
          "id": 1278,
          "province_id": 12,
          "name": "KOTA GUNUNGSITOLI"
        },
        {
          "id": 1301,
          "province_id": 13,
          "name": "KABUPATEN KEPULAUAN MENTAWAI"
        },
        {
          "id": 1302,
          "province_id": 13,
          "name": "KABUPATEN PESISIR SELATAN"
        },
        {
          "id": 1303,
          "province_id": 13,
          "name": "KABUPATEN SOLOK"
        },
        {
          "id": 1304,
          "province_id": 13,
          "name": "KABUPATEN SIJUNJUNG"
        },
        {
          "id": 1305,
          "province_id": 13,
          "name": "KABUPATEN TANAH DATAR"
        },
        {
          "id": 1306,
          "province_id": 13,
          "name": "KABUPATEN PADANG PARIAMAN"
        },
        {
          "id": 1307,
          "province_id": 13,
          "name": "KABUPATEN AGAM"
        },
        {
          "id": 1308,
          "province_id": 13,
          "name": "KABUPATEN LIMA PULUH KOTA"
        },
        {
          "id": 1309,
          "province_id": 13,
          "name": "KABUPATEN PASAMAN"
        },
        {
          "id": 1310,
          "province_id": 13,
          "name": "KABUPATEN SOLOK SELATAN"
        },
        {
          "id": 1311,
          "province_id": 13,
          "name": "KABUPATEN DHARMASRAYA"
        },
        {
          "id": 1312,
          "province_id": 13,
          "name": "KABUPATEN PASAMAN BARAT"
        },
        {
          "id": 1371,
          "province_id": 13,
          "name": "KOTA PADANG"
        },
        {
          "id": 1372,
          "province_id": 13,
          "name": "KOTA SOLOK"
        },
        {
          "id": 1373,
          "province_id": 13,
          "name": "KOTA SAWAH LUNTO"
        },
        {
          "id": 1374,
          "province_id": 13,
          "name": "KOTA PADANG PANJANG"
        },
        {
          "id": 1375,
          "province_id": 13,
          "name": "KOTA BUKITTINGGI"
        },
        {
          "id": 1376,
          "province_id": 13,
          "name": "KOTA PAYAKUMBUH"
        },
        {
          "id": 1377,
          "province_id": 13,
          "name": "KOTA PARIAMAN"
        },
        {
          "id": 1401,
          "province_id": 14,
          "name": "KABUPATEN KUANTAN SINGINGI"
        },
        {
          "id": 1402,
          "province_id": 14,
          "name": "KABUPATEN INDRAGIRI HULU"
        },
        {
          "id": 1403,
          "province_id": 14,
          "name": "KABUPATEN INDRAGIRI HILIR"
        },
        {
          "id": 1404,
          "province_id": 14,
          "name": "KABUPATEN PELALAWAN"
        },
        {
          "id": 1405,
          "province_id": 14,
          "name": "KABUPATEN S I A K"
        },
        {
          "id": 1406,
          "province_id": 14,
          "name": "KABUPATEN KAMPAR"
        },
        {
          "id": 1407,
          "province_id": 14,
          "name": "KABUPATEN ROKAN HULU"
        },
        {
          "id": 1408,
          "province_id": 14,
          "name": "KABUPATEN BENGKALIS"
        },
        {
          "id": 1409,
          "province_id": 14,
          "name": "KABUPATEN ROKAN HILIR"
        },
        {
          "id": 1410,
          "province_id": 14,
          "name": "KABUPATEN KEPULAUAN MERANTI"
        },
        {
          "id": 1471,
          "province_id": 14,
          "name": "KOTA PEKANBARU"
        },
        {
          "id": 1473,
          "province_id": 14,
          "name": "KOTA D U M A I"
        },
        {
          "id": 1501,
          "province_id": 15,
          "name": "KABUPATEN KERINCI"
        },
        {
          "id": 1502,
          "province_id": 15,
          "name": "KABUPATEN MERANGIN"
        },
        {
          "id": 1503,
          "province_id": 15,
          "name": "KABUPATEN SAROLANGUN"
        },
        {
          "id": 1504,
          "province_id": 15,
          "name": "KABUPATEN BATANG HARI"
        },
        {
          "id": 1505,
          "province_id": 15,
          "name": "KABUPATEN MUARO JAMBI"
        },
        {
          "id": 1506,
          "province_id": 15,
          "name": "KABUPATEN TANJUNG JABUNG TIMUR"
        },
        {
          "id": 1507,
          "province_id": 15,
          "name": "KABUPATEN TANJUNG JABUNG BARAT"
        },
        {
          "id": 1508,
          "province_id": 15,
          "name": "KABUPATEN TEBO"
        },
        {
          "id": 1509,
          "province_id": 15,
          "name": "KABUPATEN BUNGO"
        },
        {
          "id": 1571,
          "province_id": 15,
          "name": "KOTA JAMBI"
        },
        {
          "id": 1572,
          "province_id": 15,
          "name": "KOTA SUNGAI PENUH"
        },
        {
          "id": 1601,
          "province_id": 16,
          "name": "KABUPATEN OGAN KOMERING ULU"
        },
        {
          "id": 1602,
          "province_id": 16,
          "name": "KABUPATEN OGAN KOMERING ILIR"
        },
        {
          "id": 1603,
          "province_id": 16,
          "name": "KABUPATEN MUARA ENIM"
        },
        {
          "id": 1604,
          "province_id": 16,
          "name": "KABUPATEN LAHAT"
        },
        {
          "id": 1605,
          "province_id": 16,
          "name": "KABUPATEN MUSI RAWAS"
        },
        {
          "id": 1606,
          "province_id": 16,
          "name": "KABUPATEN MUSI BANYUASIN"
        },
        {
          "id": 1607,
          "province_id": 16,
          "name": "KABUPATEN BANYU ASIN"
        },
        {
          "id": 1608,
          "province_id": 16,
          "name": "KABUPATEN OGAN KOMERING ULU SELATAN"
        },
        {
          "id": 1609,
          "province_id": 16,
          "name": "KABUPATEN OGAN KOMERING ULU TIMUR"
        },
        {
          "id": 1610,
          "province_id": 16,
          "name": "KABUPATEN OGAN ILIR"
        },
        {
          "id": 1611,
          "province_id": 16,
          "name": "KABUPATEN EMPAT LAWANG"
        },
        {
          "id": 1612,
          "province_id": 16,
          "name": "KABUPATEN PENUKAL ABAB LEMATANG ILIR"
        },
        {
          "id": 1613,
          "province_id": 16,
          "name": "KABUPATEN MUSI RAWAS UTARA"
        },
        {
          "id": 1671,
          "province_id": 16,
          "name": "KOTA PALEMBANG"
        },
        {
          "id": 1672,
          "province_id": 16,
          "name": "KOTA PRABUMULIH"
        },
        {
          "id": 1673,
          "province_id": 16,
          "name": "KOTA PAGAR ALAM"
        },
        {
          "id": 1674,
          "province_id": 16,
          "name": "KOTA LUBUKLINGGAU"
        },
        {
          "id": 1701,
          "province_id": 17,
          "name": "KABUPATEN BENGKULU SELATAN"
        },
        {
          "id": 1702,
          "province_id": 17,
          "name": "KABUPATEN REJANG LEBONG"
        },
        {
          "id": 1703,
          "province_id": 17,
          "name": "KABUPATEN BENGKULU UTARA"
        },
        {
          "id": 1704,
          "province_id": 17,
          "name": "KABUPATEN KAUR"
        },
        {
          "id": 1705,
          "province_id": 17,
          "name": "KABUPATEN SELUMA"
        },
        {
          "id": 1706,
          "province_id": 17,
          "name": "KABUPATEN MUKOMUKO"
        },
        {
          "id": 1707,
          "province_id": 17,
          "name": "KABUPATEN LEBONG"
        },
        {
          "id": 1708,
          "province_id": 17,
          "name": "KABUPATEN KEPAHIANG"
        },
        {
          "id": 1709,
          "province_id": 17,
          "name": "KABUPATEN BENGKULU TENGAH"
        },
        {
          "id": 1771,
          "province_id": 17,
          "name": "KOTA BENGKULU"
        },
        {
          "id": 1801,
          "province_id": 18,
          "name": "KABUPATEN LAMPUNG BARAT"
        },
        {
          "id": 1802,
          "province_id": 18,
          "name": "KABUPATEN TANGGAMUS"
        },
        {
          "id": 1803,
          "province_id": 18,
          "name": "KABUPATEN LAMPUNG SELATAN"
        },
        {
          "id": 1804,
          "province_id": 18,
          "name": "KABUPATEN LAMPUNG TIMUR"
        },
        {
          "id": 1805,
          "province_id": 18,
          "name": "KABUPATEN LAMPUNG TENGAH"
        },
        {
          "id": 1806,
          "province_id": 18,
          "name": "KABUPATEN LAMPUNG UTARA"
        },
        {
          "id": 1807,
          "province_id": 18,
          "name": "KABUPATEN WAY KANAN"
        },
        {
          "id": 1808,
          "province_id": 18,
          "name": "KABUPATEN TULANGBAWANG"
        },
        {
          "id": 1809,
          "province_id": 18,
          "name": "KABUPATEN PESAWARAN"
        },
        {
          "id": 1810,
          "province_id": 18,
          "name": "KABUPATEN PRINGSEWU"
        },
        {
          "id": 1811,
          "province_id": 18,
          "name": "KABUPATEN MESUJI"
        },
        {
          "id": 1812,
          "province_id": 18,
          "name": "KABUPATEN TULANG BAWANG BARAT"
        },
        {
          "id": 1813,
          "province_id": 18,
          "name": "KABUPATEN PESISIR BARAT"
        },
        {
          "id": 1871,
          "province_id": 18,
          "name": "KOTA BANDAR LAMPUNG"
        },
        {
          "id": 1872,
          "province_id": 18,
          "name": "KOTA METRO"
        },
        {
          "id": 1901,
          "province_id": 19,
          "name": "KABUPATEN BANGKA"
        },
        {
          "id": 1902,
          "province_id": 19,
          "name": "KABUPATEN BELITUNG"
        },
        {
          "id": 1903,
          "province_id": 19,
          "name": "KABUPATEN BANGKA BARAT"
        },
        {
          "id": 1904,
          "province_id": 19,
          "name": "KABUPATEN BANGKA TENGAH"
        },
        {
          "id": 1905,
          "province_id": 19,
          "name": "KABUPATEN BANGKA SELATAN"
        },
        {
          "id": 1906,
          "province_id": 19,
          "name": "KABUPATEN BELITUNG TIMUR"
        },
        {
          "id": 1971,
          "province_id": 19,
          "name": "KOTA PANGKAL PINANG"
        },
        {
          "id": 2101,
          "province_id": 21,
          "name": "KABUPATEN KARIMUN"
        },
        {
          "id": 2102,
          "province_id": 21,
          "name": "KABUPATEN BINTAN"
        },
        {
          "id": 2103,
          "province_id": 21,
          "name": "KABUPATEN NATUNA"
        },
        {
          "id": 2104,
          "province_id": 21,
          "name": "KABUPATEN LINGGA"
        },
        {
          "id": 2105,
          "province_id": 21,
          "name": "KABUPATEN KEPULAUAN ANAMBAS"
        },
        {
          "id": 2171,
          "province_id": 21,
          "name": "KOTA B A T A M"
        },
        {
          "id": 2172,
          "province_id": 21,
          "name": "KOTA TANJUNG PINANG"
        },
        {
          "id": 3101,
          "province_id": 31,
          "name": "KABUPATEN KEPULAUAN SERIBU"
        },
        {
          "id": 3171,
          "province_id": 31,
          "name": "KOTA JAKARTA SELATAN"
        },
        {
          "id": 3172,
          "province_id": 31,
          "name": "KOTA JAKARTA TIMUR"
        },
        {
          "id": 3173,
          "province_id": 31,
          "name": "KOTA JAKARTA PUSAT"
        },
        {
          "id": 3174,
          "province_id": 31,
          "name": "KOTA JAKARTA BARAT"
        },
        {
          "id": 3175,
          "province_id": 31,
          "name": "KOTA JAKARTA UTARA"
        },
        {
          "id": 3201,
          "province_id": 32,
          "name": "KABUPATEN BOGOR"
        },
        {
          "id": 3202,
          "province_id": 32,
          "name": "KABUPATEN SUKABUMI"
        },
        {
          "id": 3203,
          "province_id": 32,
          "name": "KABUPATEN CIANJUR"
        },
        {
          "id": 3204,
          "province_id": 32,
          "name": "KABUPATEN BANDUNG"
        },
        {
          "id": 3205,
          "province_id": 32,
          "name": "KABUPATEN GARUT"
        },
        {
          "id": 3206,
          "province_id": 32,
          "name": "KABUPATEN TASIKMALAYA"
        },
        {
          "id": 3207,
          "province_id": 32,
          "name": "KABUPATEN CIAMIS"
        },
        {
          "id": 3208,
          "province_id": 32,
          "name": "KABUPATEN KUNINGAN"
        },
        {
          "id": 3209,
          "province_id": 32,
          "name": "KABUPATEN CIREBON"
        },
        {
          "id": 3210,
          "province_id": 32,
          "name": "KABUPATEN MAJALENGKA"
        },
        {
          "id": 3211,
          "province_id": 32,
          "name": "KABUPATEN SUMEDANG"
        },
        {
          "id": 3212,
          "province_id": 32,
          "name": "KABUPATEN INDRAMAYU"
        },
        {
          "id": 3213,
          "province_id": 32,
          "name": "KABUPATEN SUBANG"
        },
        {
          "id": 3214,
          "province_id": 32,
          "name": "KABUPATEN PURWAKARTA"
        },
        {
          "id": 3215,
          "province_id": 32,
          "name": "KABUPATEN KARAWANG"
        },
        {
          "id": 3216,
          "province_id": 32,
          "name": "KABUPATEN BEKASI"
        },
        {
          "id": 3217,
          "province_id": 32,
          "name": "KABUPATEN BANDUNG BARAT"
        },
        {
          "id": 3218,
          "province_id": 32,
          "name": "KABUPATEN PANGANDARAN"
        },
        {
          "id": 3271,
          "province_id": 32,
          "name": "KOTA BOGOR"
        },
        {
          "id": 3272,
          "province_id": 32,
          "name": "KOTA SUKABUMI"
        },
        {
          "id": 3273,
          "province_id": 32,
          "name": "KOTA BANDUNG"
        },
        {
          "id": 3274,
          "province_id": 32,
          "name": "KOTA CIREBON"
        },
        {
          "id": 3275,
          "province_id": 32,
          "name": "KOTA BEKASI"
        },
        {
          "id": 3276,
          "province_id": 32,
          "name": "KOTA DEPOK"
        },
        {
          "id": 3277,
          "province_id": 32,
          "name": "KOTA CIMAHI"
        },
        {
          "id": 3278,
          "province_id": 32,
          "name": "KOTA TASIKMALAYA"
        },
        {
          "id": 3279,
          "province_id": 32,
          "name": "KOTA BANJAR"
        },
        {
          "id": 3301,
          "province_id": 33,
          "name": "KABUPATEN CILACAP"
        },
        {
          "id": 3302,
          "province_id": 33,
          "name": "KABUPATEN BANYUMAS"
        },
        {
          "id": 3303,
          "province_id": 33,
          "name": "KABUPATEN PURBALINGGA"
        },
        {
          "id": 3304,
          "province_id": 33,
          "name": "KABUPATEN BANJARNEGARA"
        },
        {
          "id": 3305,
          "province_id": 33,
          "name": "KABUPATEN KEBUMEN"
        },
        {
          "id": 3306,
          "province_id": 33,
          "name": "KABUPATEN PURWOREJO"
        },
        {
          "id": 3307,
          "province_id": 33,
          "name": "KABUPATEN WONOSOBO"
        },
        {
          "id": 3308,
          "province_id": 33,
          "name": "KABUPATEN MAGELANG"
        },
        {
          "id": 3309,
          "province_id": 33,
          "name": "KABUPATEN BOYOLALI"
        },
        {
          "id": 3310,
          "province_id": 33,
          "name": "KABUPATEN KLATEN"
        },
        {
          "id": 3311,
          "province_id": 33,
          "name": "KABUPATEN SUKOHARJO"
        },
        {
          "id": 3312,
          "province_id": 33,
          "name": "KABUPATEN WONOGIRI"
        },
        {
          "id": 3313,
          "province_id": 33,
          "name": "KABUPATEN KARANGANYAR"
        },
        {
          "id": 3314,
          "province_id": 33,
          "name": "KABUPATEN SRAGEN"
        },
        {
          "id": 3315,
          "province_id": 33,
          "name": "KABUPATEN GROBOGAN"
        },
        {
          "id": 3316,
          "province_id": 33,
          "name": "KABUPATEN BLORA"
        },
        {
          "id": 3317,
          "province_id": 33,
          "name": "KABUPATEN REMBANG"
        },
        {
          "id": 3318,
          "province_id": 33,
          "name": "KABUPATEN PATI"
        },
        {
          "id": 3319,
          "province_id": 33,
          "name": "KABUPATEN KUDUS"
        },
        {
          "id": 3320,
          "province_id": 33,
          "name": "KABUPATEN JEPARA"
        },
        {
          "id": 3321,
          "province_id": 33,
          "name": "KABUPATEN DEMAK"
        },
        {
          "id": 3322,
          "province_id": 33,
          "name": "KABUPATEN SEMARANG"
        },
        {
          "id": 3323,
          "province_id": 33,
          "name": "KABUPATEN TEMANGGUNG"
        },
        {
          "id": 3324,
          "province_id": 33,
          "name": "KABUPATEN KENDAL"
        },
        {
          "id": 3325,
          "province_id": 33,
          "name": "KABUPATEN BATANG"
        },
        {
          "id": 3326,
          "province_id": 33,
          "name": "KABUPATEN PEKALONGAN"
        },
        {
          "id": 3327,
          "province_id": 33,
          "name": "KABUPATEN PEMALANG"
        },
        {
          "id": 3328,
          "province_id": 33,
          "name": "KABUPATEN TEGAL"
        },
        {
          "id": 3329,
          "province_id": 33,
          "name": "KABUPATEN BREBES"
        },
        {
          "id": 3371,
          "province_id": 33,
          "name": "KOTA MAGELANG"
        },
        {
          "id": 3372,
          "province_id": 33,
          "name": "KOTA SURAKARTA"
        },
        {
          "id": 3373,
          "province_id": 33,
          "name": "KOTA SALATIGA"
        },
        {
          "id": 3374,
          "province_id": 33,
          "name": "KOTA SEMARANG"
        },
        {
          "id": 3375,
          "province_id": 33,
          "name": "KOTA PEKALONGAN"
        },
        {
          "id": 3376,
          "province_id": 33,
          "name": "KOTA TEGAL"
        },
        {
          "id": 3401,
          "province_id": 34,
          "name": "KABUPATEN KULON PROGO"
        },
        {
          "id": 3402,
          "province_id": 34,
          "name": "KABUPATEN BANTUL"
        },
        {
          "id": 3403,
          "province_id": 34,
          "name": "KABUPATEN GUNUNG KIDUL"
        },
        {
          "id": 3404,
          "province_id": 34,
          "name": "KABUPATEN SLEMAN"
        },
        {
          "id": 3471,
          "province_id": 34,
          "name": "KOTA YOGYAKARTA"
        },
        {
          "id": 3501,
          "province_id": 35,
          "name": "KABUPATEN PACITAN"
        },
        {
          "id": 3502,
          "province_id": 35,
          "name": "KABUPATEN PONOROGO"
        },
        {
          "id": 3503,
          "province_id": 35,
          "name": "KABUPATEN TRENGGALEK"
        },
        {
          "id": 3504,
          "province_id": 35,
          "name": "KABUPATEN TULUNGAGUNG"
        },
        {
          "id": 3505,
          "province_id": 35,
          "name": "KABUPATEN BLITAR"
        },
        {
          "id": 3506,
          "province_id": 35,
          "name": "KABUPATEN KEDIRI"
        },
        {
          "id": 3507,
          "province_id": 35,
          "name": "KABUPATEN MALANG"
        },
        {
          "id": 3508,
          "province_id": 35,
          "name": "KABUPATEN LUMAJANG"
        },
        {
          "id": 3509,
          "province_id": 35,
          "name": "KABUPATEN JEMBER"
        },
        {
          "id": 3510,
          "province_id": 35,
          "name": "KABUPATEN BANYUWANGI"
        },
        {
          "id": 3511,
          "province_id": 35,
          "name": "KABUPATEN BONDOWOSO"
        },
        {
          "id": 3512,
          "province_id": 35,
          "name": "KABUPATEN SITUBONDO"
        },
        {
          "id": 3513,
          "province_id": 35,
          "name": "KABUPATEN PROBOLINGGO"
        },
        {
          "id": 3514,
          "province_id": 35,
          "name": "KABUPATEN PASURUAN"
        },
        {
          "id": 3515,
          "province_id": 35,
          "name": "KABUPATEN SIDOARJO"
        },
        {
          "id": 3516,
          "province_id": 35,
          "name": "KABUPATEN MOJOKERTO"
        },
        {
          "id": 3517,
          "province_id": 35,
          "name": "KABUPATEN JOMBANG"
        },
        {
          "id": 3518,
          "province_id": 35,
          "name": "KABUPATEN NGANJUK"
        },
        {
          "id": 3519,
          "province_id": 35,
          "name": "KABUPATEN MADIUN"
        },
        {
          "id": 3520,
          "province_id": 35,
          "name": "KABUPATEN MAGETAN"
        },
        {
          "id": 3521,
          "province_id": 35,
          "name": "KABUPATEN NGAWI"
        },
        {
          "id": 3522,
          "province_id": 35,
          "name": "KABUPATEN BOJONEGORO"
        },
        {
          "id": 3523,
          "province_id": 35,
          "name": "KABUPATEN TUBAN"
        },
        {
          "id": 3524,
          "province_id": 35,
          "name": "KABUPATEN LAMONGAN"
        },
        {
          "id": 3525,
          "province_id": 35,
          "name": "KABUPATEN GRESIK"
        },
        {
          "id": 3526,
          "province_id": 35,
          "name": "KABUPATEN BANGKALAN"
        },
        {
          "id": 3527,
          "province_id": 35,
          "name": "KABUPATEN SAMPANG"
        },
        {
          "id": 3528,
          "province_id": 35,
          "name": "KABUPATEN PAMEKASAN"
        },
        {
          "id": 3529,
          "province_id": 35,
          "name": "KABUPATEN SUMENEP"
        },
        {
          "id": 3571,
          "province_id": 35,
          "name": "KOTA KEDIRI"
        },
        {
          "id": 3572,
          "province_id": 35,
          "name": "KOTA BLITAR"
        },
        {
          "id": 3573,
          "province_id": 35,
          "name": "KOTA MALANG"
        },
        {
          "id": 3574,
          "province_id": 35,
          "name": "KOTA PROBOLINGGO"
        },
        {
          "id": 3575,
          "province_id": 35,
          "name": "KOTA PASURUAN"
        },
        {
          "id": 3576,
          "province_id": 35,
          "name": "KOTA MOJOKERTO"
        },
        {
          "id": 3577,
          "province_id": 35,
          "name": "KOTA MADIUN"
        },
        {
          "id": 3578,
          "province_id": 35,
          "name": "KOTA SURABAYA"
        },
        {
          "id": 3579,
          "province_id": 35,
          "name": "KOTA BATU"
        },
        {
          "id": 3601,
          "province_id": 36,
          "name": "KABUPATEN PANDEGLANG"
        },
        {
          "id": 3602,
          "province_id": 36,
          "name": "KABUPATEN LEBAK"
        },
        {
          "id": 3603,
          "province_id": 36,
          "name": "KABUPATEN TANGERANG"
        },
        {
          "id": 3604,
          "province_id": 36,
          "name": "KABUPATEN SERANG"
        },
        {
          "id": 3671,
          "province_id": 36,
          "name": "KOTA TANGERANG"
        },
        {
          "id": 3672,
          "province_id": 36,
          "name": "KOTA CILEGON"
        },
        {
          "id": 3673,
          "province_id": 36,
          "name": "KOTA SERANG"
        },
        {
          "id": 3674,
          "province_id": 36,
          "name": "KOTA TANGERANG SELATAN"
        },
        {
          "id": 5101,
          "province_id": 51,
          "name": "KABUPATEN JEMBRANA"
        },
        {
          "id": 5102,
          "province_id": 51,
          "name": "KABUPATEN TABANAN"
        },
        {
          "id": 5103,
          "province_id": 51,
          "name": "KABUPATEN BADUNG"
        },
        {
          "id": 5104,
          "province_id": 51,
          "name": "KABUPATEN GIANYAR"
        },
        {
          "id": 5105,
          "province_id": 51,
          "name": "KABUPATEN KLUNGKUNG"
        },
        {
          "id": 5106,
          "province_id": 51,
          "name": "KABUPATEN BANGLI"
        },
        {
          "id": 5107,
          "province_id": 51,
          "name": "KABUPATEN KARANG ASEM"
        },
        {
          "id": 5108,
          "province_id": 51,
          "name": "KABUPATEN BULELENG"
        },
        {
          "id": 5171,
          "province_id": 51,
          "name": "KOTA DENPASAR"
        },
        {
          "id": 5201,
          "province_id": 52,
          "name": "KABUPATEN LOMBOK BARAT"
        },
        {
          "id": 5202,
          "province_id": 52,
          "name": "KABUPATEN LOMBOK TENGAH"
        },
        {
          "id": 5203,
          "province_id": 52,
          "name": "KABUPATEN LOMBOK TIMUR"
        },
        {
          "id": 5204,
          "province_id": 52,
          "name": "KABUPATEN SUMBAWA"
        },
        {
          "id": 5205,
          "province_id": 52,
          "name": "KABUPATEN DOMPU"
        },
        {
          "id": 5206,
          "province_id": 52,
          "name": "KABUPATEN BIMA"
        },
        {
          "id": 5207,
          "province_id": 52,
          "name": "KABUPATEN SUMBAWA BARAT"
        },
        {
          "id": 5208,
          "province_id": 52,
          "name": "KABUPATEN LOMBOK UTARA"
        },
        {
          "id": 5271,
          "province_id": 52,
          "name": "KOTA MATARAM"
        },
        {
          "id": 5272,
          "province_id": 52,
          "name": "KOTA BIMA"
        },
        {
          "id": 5301,
          "province_id": 53,
          "name": "KABUPATEN SUMBA BARAT"
        },
        {
          "id": 5302,
          "province_id": 53,
          "name": "KABUPATEN SUMBA TIMUR"
        },
        {
          "id": 5303,
          "province_id": 53,
          "name": "KABUPATEN KUPANG"
        },
        {
          "id": 5304,
          "province_id": 53,
          "name": "KABUPATEN TIMOR TENGAH SELATAN"
        },
        {
          "id": 5305,
          "province_id": 53,
          "name": "KABUPATEN TIMOR TENGAH UTARA"
        },
        {
          "id": 5306,
          "province_id": 53,
          "name": "KABUPATEN BELU"
        },
        {
          "id": 5307,
          "province_id": 53,
          "name": "KABUPATEN ALOR"
        },
        {
          "id": 5308,
          "province_id": 53,
          "name": "KABUPATEN LEMBATA"
        },
        {
          "id": 5309,
          "province_id": 53,
          "name": "KABUPATEN FLORES TIMUR"
        },
        {
          "id": 5310,
          "province_id": 53,
          "name": "KABUPATEN SIKKA"
        },
        {
          "id": 5311,
          "province_id": 53,
          "name": "KABUPATEN ENDE"
        },
        {
          "id": 5312,
          "province_id": 53,
          "name": "KABUPATEN NGADA"
        },
        {
          "id": 5313,
          "province_id": 53,
          "name": "KABUPATEN MANGGARAI"
        },
        {
          "id": 5314,
          "province_id": 53,
          "name": "KABUPATEN ROTE NDAO"
        },
        {
          "id": 5315,
          "province_id": 53,
          "name": "KABUPATEN MANGGARAI BARAT"
        },
        {
          "id": 5316,
          "province_id": 53,
          "name": "KABUPATEN SUMBA TENGAH"
        },
        {
          "id": 5317,
          "province_id": 53,
          "name": "KABUPATEN SUMBA BARAT DAYA"
        },
        {
          "id": 5318,
          "province_id": 53,
          "name": "KABUPATEN NAGEKEO"
        },
        {
          "id": 5319,
          "province_id": 53,
          "name": "KABUPATEN MANGGARAI TIMUR"
        },
        {
          "id": 5320,
          "province_id": 53,
          "name": "KABUPATEN SABU RAIJUA"
        },
        {
          "id": 5321,
          "province_id": 53,
          "name": "KABUPATEN MALAKA"
        },
        {
          "id": 5371,
          "province_id": 53,
          "name": "KOTA KUPANG"
        },
        {
          "id": 6101,
          "province_id": 61,
          "name": "KABUPATEN SAMBAS"
        },
        {
          "id": 6102,
          "province_id": 61,
          "name": "KABUPATEN BENGKAYANG"
        },
        {
          "id": 6103,
          "province_id": 61,
          "name": "KABUPATEN LANDAK"
        },
        {
          "id": 6104,
          "province_id": 61,
          "name": "KABUPATEN MEMPAWAH"
        },
        {
          "id": 6105,
          "province_id": 61,
          "name": "KABUPATEN SANGGAU"
        },
        {
          "id": 6106,
          "province_id": 61,
          "name": "KABUPATEN KETAPANG"
        },
        {
          "id": 6107,
          "province_id": 61,
          "name": "KABUPATEN SINTANG"
        },
        {
          "id": 6108,
          "province_id": 61,
          "name": "KABUPATEN KAPUAS HULU"
        },
        {
          "id": 6109,
          "province_id": 61,
          "name": "KABUPATEN SEKADAU"
        },
        {
          "id": 6110,
          "province_id": 61,
          "name": "KABUPATEN MELAWI"
        },
        {
          "id": 6111,
          "province_id": 61,
          "name": "KABUPATEN KAYONG UTARA"
        },
        {
          "id": 6112,
          "province_id": 61,
          "name": "KABUPATEN KUBU RAYA"
        },
        {
          "id": 6171,
          "province_id": 61,
          "name": "KOTA PONTIANAK"
        },
        {
          "id": 6172,
          "province_id": 61,
          "name": "KOTA SINGKAWANG"
        },
        {
          "id": 6201,
          "province_id": 62,
          "name": "KABUPATEN KOTAWARINGIN BARAT"
        },
        {
          "id": 6202,
          "province_id": 62,
          "name": "KABUPATEN KOTAWARINGIN TIMUR"
        },
        {
          "id": 6203,
          "province_id": 62,
          "name": "KABUPATEN KAPUAS"
        },
        {
          "id": 6204,
          "province_id": 62,
          "name": "KABUPATEN BARITO SELATAN"
        },
        {
          "id": 6205,
          "province_id": 62,
          "name": "KABUPATEN BARITO UTARA"
        },
        {
          "id": 6206,
          "province_id": 62,
          "name": "KABUPATEN SUKAMARA"
        },
        {
          "id": 6207,
          "province_id": 62,
          "name": "KABUPATEN LAMANDAU"
        },
        {
          "id": 6208,
          "province_id": 62,
          "name": "KABUPATEN SERUYAN"
        },
        {
          "id": 6209,
          "province_id": 62,
          "name": "KABUPATEN KATINGAN"
        },
        {
          "id": 6210,
          "province_id": 62,
          "name": "KABUPATEN PULANG PISAU"
        },
        {
          "id": 6211,
          "province_id": 62,
          "name": "KABUPATEN GUNUNG MAS"
        },
        {
          "id": 6212,
          "province_id": 62,
          "name": "KABUPATEN BARITO TIMUR"
        },
        {
          "id": 6213,
          "province_id": 62,
          "name": "KABUPATEN MURUNG RAYA"
        },
        {
          "id": 6271,
          "province_id": 62,
          "name": "KOTA PALANGKA RAYA"
        },
        {
          "id": 6301,
          "province_id": 63,
          "name": "KABUPATEN TANAH LAUT"
        },
        {
          "id": 6302,
          "province_id": 63,
          "name": "KABUPATEN KOTA BARU"
        },
        {
          "id": 6303,
          "province_id": 63,
          "name": "KABUPATEN BANJAR"
        },
        {
          "id": 6304,
          "province_id": 63,
          "name": "KABUPATEN BARITO KUALA"
        },
        {
          "id": 6305,
          "province_id": 63,
          "name": "KABUPATEN TAPIN"
        },
        {
          "id": 6306,
          "province_id": 63,
          "name": "KABUPATEN HULU SUNGAI SELATAN"
        },
        {
          "id": 6307,
          "province_id": 63,
          "name": "KABUPATEN HULU SUNGAI TENGAH"
        },
        {
          "id": 6308,
          "province_id": 63,
          "name": "KABUPATEN HULU SUNGAI UTARA"
        },
        {
          "id": 6309,
          "province_id": 63,
          "name": "KABUPATEN TABALONG"
        },
        {
          "id": 6310,
          "province_id": 63,
          "name": "KABUPATEN TANAH BUMBU"
        },
        {
          "id": 6311,
          "province_id": 63,
          "name": "KABUPATEN BALANGAN"
        },
        {
          "id": 6371,
          "province_id": 63,
          "name": "KOTA BANJARMASIN"
        },
        {
          "id": 6372,
          "province_id": 63,
          "name": "KOTA BANJAR BARU"
        },
        {
          "id": 6401,
          "province_id": 64,
          "name": "KABUPATEN PASER"
        },
        {
          "id": 6402,
          "province_id": 64,
          "name": "KABUPATEN KUTAI BARAT"
        },
        {
          "id": 6403,
          "province_id": 64,
          "name": "KABUPATEN KUTAI KARTANEGARA"
        },
        {
          "id": 6404,
          "province_id": 64,
          "name": "KABUPATEN KUTAI TIMUR"
        },
        {
          "id": 6405,
          "province_id": 64,
          "name": "KABUPATEN BERAU"
        },
        {
          "id": 6409,
          "province_id": 64,
          "name": "KABUPATEN PENAJAM PASER UTARA"
        },
        {
          "id": 6411,
          "province_id": 64,
          "name": "KABUPATEN MAHAKAM HULU"
        },
        {
          "id": 6471,
          "province_id": 64,
          "name": "KOTA BALIKPAPAN"
        },
        {
          "id": 6472,
          "province_id": 64,
          "name": "KOTA SAMARINDA"
        },
        {
          "id": 6474,
          "province_id": 64,
          "name": "KOTA BONTANG"
        },
        {
          "id": 6501,
          "province_id": 65,
          "name": "KABUPATEN MALINAU"
        },
        {
          "id": 6502,
          "province_id": 65,
          "name": "KABUPATEN BULUNGAN"
        },
        {
          "id": 6503,
          "province_id": 65,
          "name": "KABUPATEN TANA TIDUNG"
        },
        {
          "id": 6504,
          "province_id": 65,
          "name": "KABUPATEN NUNUKAN"
        },
        {
          "id": 6571,
          "province_id": 65,
          "name": "KOTA TARAKAN"
        },
        {
          "id": 7101,
          "province_id": 71,
          "name": "KABUPATEN BOLAANG MONGONDOW"
        },
        {
          "id": 7102,
          "province_id": 71,
          "name": "KABUPATEN MINAHASA"
        },
        {
          "id": 7103,
          "province_id": 71,
          "name": "KABUPATEN KEPULAUAN SANGIHE"
        },
        {
          "id": 7104,
          "province_id": 71,
          "name": "KABUPATEN KEPULAUAN TALAUD"
        },
        {
          "id": 7105,
          "province_id": 71,
          "name": "KABUPATEN MINAHASA SELATAN"
        },
        {
          "id": 7106,
          "province_id": 71,
          "name": "KABUPATEN MINAHASA UTARA"
        },
        {
          "id": 7107,
          "province_id": 71,
          "name": "KABUPATEN BOLAANG MONGONDOW UTARA"
        },
        {
          "id": 7108,
          "province_id": 71,
          "name": "KABUPATEN SIAU TAGULANDANG BIARO"
        },
        {
          "id": 7109,
          "province_id": 71,
          "name": "KABUPATEN MINAHASA TENGGARA"
        },
        {
          "id": 7110,
          "province_id": 71,
          "name": "KABUPATEN BOLAANG MONGONDOW SELATAN"
        },
        {
          "id": 7111,
          "province_id": 71,
          "name": "KABUPATEN BOLAANG MONGONDOW TIMUR"
        },
        {
          "id": 7171,
          "province_id": 71,
          "name": "KOTA MANADO"
        },
        {
          "id": 7172,
          "province_id": 71,
          "name": "KOTA BITUNG"
        },
        {
          "id": 7173,
          "province_id": 71,
          "name": "KOTA TOMOHON"
        },
        {
          "id": 7174,
          "province_id": 71,
          "name": "KOTA KOTAMOBAGU"
        },
        {
          "id": 7201,
          "province_id": 72,
          "name": "KABUPATEN BANGGAI KEPULAUAN"
        },
        {
          "id": 7202,
          "province_id": 72,
          "name": "KABUPATEN BANGGAI"
        },
        {
          "id": 7203,
          "province_id": 72,
          "name": "KABUPATEN MOROWALI"
        },
        {
          "id": 7204,
          "province_id": 72,
          "name": "KABUPATEN POSO"
        },
        {
          "id": 7205,
          "province_id": 72,
          "name": "KABUPATEN DONGGALA"
        },
        {
          "id": 7206,
          "province_id": 72,
          "name": "KABUPATEN TOLI-TOLI"
        },
        {
          "id": 7207,
          "province_id": 72,
          "name": "KABUPATEN BUOL"
        },
        {
          "id": 7208,
          "province_id": 72,
          "name": "KABUPATEN PARIGI MOUTONG"
        },
        {
          "id": 7209,
          "province_id": 72,
          "name": "KABUPATEN TOJO UNA-UNA"
        },
        {
          "id": 7210,
          "province_id": 72,
          "name": "KABUPATEN SIGI"
        },
        {
          "id": 7211,
          "province_id": 72,
          "name": "KABUPATEN BANGGAI LAUT"
        },
        {
          "id": 7212,
          "province_id": 72,
          "name": "KABUPATEN MOROWALI UTARA"
        },
        {
          "id": 7271,
          "province_id": 72,
          "name": "KOTA PALU"
        },
        {
          "id": 7301,
          "province_id": 73,
          "name": "KABUPATEN KEPULAUAN SELAYAR"
        },
        {
          "id": 7302,
          "province_id": 73,
          "name": "KABUPATEN BULUKUMBA"
        },
        {
          "id": 7303,
          "province_id": 73,
          "name": "KABUPATEN BANTAENG"
        },
        {
          "id": 7304,
          "province_id": 73,
          "name": "KABUPATEN JENEPONTO"
        },
        {
          "id": 7305,
          "province_id": 73,
          "name": "KABUPATEN TAKALAR"
        },
        {
          "id": 7306,
          "province_id": 73,
          "name": "KABUPATEN GOWA"
        },
        {
          "id": 7307,
          "province_id": 73,
          "name": "KABUPATEN SINJAI"
        },
        {
          "id": 7308,
          "province_id": 73,
          "name": "KABUPATEN MAROS"
        },
        {
          "id": 7309,
          "province_id": 73,
          "name": "KABUPATEN PANGKAJENE DAN KEPULAUAN"
        },
        {
          "id": 7310,
          "province_id": 73,
          "name": "KABUPATEN BARRU"
        },
        {
          "id": 7311,
          "province_id": 73,
          "name": "KABUPATEN BONE"
        },
        {
          "id": 7312,
          "province_id": 73,
          "name": "KABUPATEN SOPPENG"
        },
        {
          "id": 7313,
          "province_id": 73,
          "name": "KABUPATEN WAJO"
        },
        {
          "id": 7314,
          "province_id": 73,
          "name": "KABUPATEN SIDENRENG RAPPANG"
        },
        {
          "id": 7315,
          "province_id": 73,
          "name": "KABUPATEN PINRANG"
        },
        {
          "id": 7316,
          "province_id": 73,
          "name": "KABUPATEN ENREKANG"
        },
        {
          "id": 7317,
          "province_id": 73,
          "name": "KABUPATEN LUWU"
        },
        {
          "id": 7318,
          "province_id": 73,
          "name": "KABUPATEN TANA TORAJA"
        },
        {
          "id": 7322,
          "province_id": 73,
          "name": "KABUPATEN LUWU UTARA"
        },
        {
          "id": 7325,
          "province_id": 73,
          "name": "KABUPATEN LUWU TIMUR"
        },
        {
          "id": 7326,
          "province_id": 73,
          "name": "KABUPATEN TORAJA UTARA"
        },
        {
          "id": 7371,
          "province_id": 73,
          "name": "KOTA MAKASSAR"
        },
        {
          "id": 7372,
          "province_id": 73,
          "name": "KOTA PAREPARE"
        },
        {
          "id": 7373,
          "province_id": 73,
          "name": "KOTA PALOPO"
        },
        {
          "id": 7401,
          "province_id": 74,
          "name": "KABUPATEN BUTON"
        },
        {
          "id": 7402,
          "province_id": 74,
          "name": "KABUPATEN MUNA"
        },
        {
          "id": 7403,
          "province_id": 74,
          "name": "KABUPATEN KONAWE"
        },
        {
          "id": 7404,
          "province_id": 74,
          "name": "KABUPATEN KOLAKA"
        },
        {
          "id": 7405,
          "province_id": 74,
          "name": "KABUPATEN KONAWE SELATAN"
        },
        {
          "id": 7406,
          "province_id": 74,
          "name": "KABUPATEN BOMBANA"
        },
        {
          "id": 7407,
          "province_id": 74,
          "name": "KABUPATEN WAKATOBI"
        },
        {
          "id": 7408,
          "province_id": 74,
          "name": "KABUPATEN KOLAKA UTARA"
        },
        {
          "id": 7409,
          "province_id": 74,
          "name": "KABUPATEN BUTON UTARA"
        },
        {
          "id": 7410,
          "province_id": 74,
          "name": "KABUPATEN KONAWE UTARA"
        },
        {
          "id": 7411,
          "province_id": 74,
          "name": "KABUPATEN KOLAKA TIMUR"
        },
        {
          "id": 7412,
          "province_id": 74,
          "name": "KABUPATEN KONAWE KEPULAUAN"
        },
        {
          "id": 7413,
          "province_id": 74,
          "name": "KABUPATEN MUNA BARAT"
        },
        {
          "id": 7414,
          "province_id": 74,
          "name": "KABUPATEN BUTON TENGAH"
        },
        {
          "id": 7415,
          "province_id": 74,
          "name": "KABUPATEN BUTON SELATAN"
        },
        {
          "id": 7471,
          "province_id": 74,
          "name": "KOTA KENDARI"
        },
        {
          "id": 7472,
          "province_id": 74,
          "name": "KOTA BAUBAU"
        },
        {
          "id": 7501,
          "province_id": 75,
          "name": "KABUPATEN BOALEMO"
        },
        {
          "id": 7502,
          "province_id": 75,
          "name": "KABUPATEN GORONTALO"
        },
        {
          "id": 7503,
          "province_id": 75,
          "name": "KABUPATEN POHUWATO"
        },
        {
          "id": 7504,
          "province_id": 75,
          "name": "KABUPATEN BONE BOLANGO"
        },
        {
          "id": 7505,
          "province_id": 75,
          "name": "KABUPATEN GORONTALO UTARA"
        },
        {
          "id": 7571,
          "province_id": 75,
          "name": "KOTA GORONTALO"
        },
        {
          "id": 7601,
          "province_id": 76,
          "name": "KABUPATEN MAJENE"
        },
        {
          "id": 7602,
          "province_id": 76,
          "name": "KABUPATEN POLEWALI MANDAR"
        },
        {
          "id": 7603,
          "province_id": 76,
          "name": "KABUPATEN MAMASA"
        },
        {
          "id": 7604,
          "province_id": 76,
          "name": "KABUPATEN MAMUJU"
        },
        {
          "id": 7605,
          "province_id": 76,
          "name": "KABUPATEN MAMUJU UTARA"
        },
        {
          "id": 7606,
          "province_id": 76,
          "name": "KABUPATEN MAMUJU TENGAH"
        },
        {
          "id": 8101,
          "province_id": 81,
          "name": "KABUPATEN MALUKU TENGGARA BARAT"
        },
        {
          "id": 8102,
          "province_id": 81,
          "name": "KABUPATEN MALUKU TENGGARA"
        },
        {
          "id": 8103,
          "province_id": 81,
          "name": "KABUPATEN MALUKU TENGAH"
        },
        {
          "id": 8104,
          "province_id": 81,
          "name": "KABUPATEN BURU"
        },
        {
          "id": 8105,
          "province_id": 81,
          "name": "KABUPATEN KEPULAUAN ARU"
        },
        {
          "id": 8106,
          "province_id": 81,
          "name": "KABUPATEN SERAM BAGIAN BARAT"
        },
        {
          "id": 8107,
          "province_id": 81,
          "name": "KABUPATEN SERAM BAGIAN TIMUR"
        },
        {
          "id": 8108,
          "province_id": 81,
          "name": "KABUPATEN MALUKU BARAT DAYA"
        },
        {
          "id": 8109,
          "province_id": 81,
          "name": "KABUPATEN BURU SELATAN"
        },
        {
          "id": 8171,
          "province_id": 81,
          "name": "KOTA AMBON"
        },
        {
          "id": 8172,
          "province_id": 81,
          "name": "KOTA TUAL"
        },
        {
          "id": 8201,
          "province_id": 82,
          "name": "KABUPATEN HALMAHERA BARAT"
        },
        {
          "id": 8202,
          "province_id": 82,
          "name": "KABUPATEN HALMAHERA TENGAH"
        },
        {
          "id": 8203,
          "province_id": 82,
          "name": "KABUPATEN KEPULAUAN SULA"
        },
        {
          "id": 8204,
          "province_id": 82,
          "name": "KABUPATEN HALMAHERA SELATAN"
        },
        {
          "id": 8205,
          "province_id": 82,
          "name": "KABUPATEN HALMAHERA UTARA"
        },
        {
          "id": 8206,
          "province_id": 82,
          "name": "KABUPATEN HALMAHERA TIMUR"
        },
        {
          "id": 8207,
          "province_id": 82,
          "name": "KABUPATEN PULAU MOROTAI"
        },
        {
          "id": 8208,
          "province_id": 82,
          "name": "KABUPATEN PULAU TALIABU"
        },
        {
          "id": 8271,
          "province_id": 82,
          "name": "KOTA TERNATE"
        },
        {
          "id": 8272,
          "province_id": 82,
          "name": "KOTA TIDORE KEPULAUAN"
        },
        {
          "id": 9101,
          "province_id": 91,
          "name": "KABUPATEN FAKFAK"
        },
        {
          "id": 9102,
          "province_id": 91,
          "name": "KABUPATEN KAIMANA"
        },
        {
          "id": 9103,
          "province_id": 91,
          "name": "KABUPATEN TELUK WONDAMA"
        },
        {
          "id": 9104,
          "province_id": 91,
          "name": "KABUPATEN TELUK BINTUNI"
        },
        {
          "id": 9105,
          "province_id": 91,
          "name": "KABUPATEN MANOKWARI"
        },
        {
          "id": 9106,
          "province_id": 91,
          "name": "KABUPATEN SORONG SELATAN"
        },
        {
          "id": 9107,
          "province_id": 91,
          "name": "KABUPATEN SORONG"
        },
        {
          "id": 9108,
          "province_id": 91,
          "name": "KABUPATEN RAJA AMPAT"
        },
        {
          "id": 9109,
          "province_id": 91,
          "name": "KABUPATEN TAMBRAUW"
        },
        {
          "id": 9110,
          "province_id": 91,
          "name": "KABUPATEN MAYBRAT"
        },
        {
          "id": 9111,
          "province_id": 91,
          "name": "KABUPATEN MANOKWARI SELATAN"
        },
        {
          "id": 9112,
          "province_id": 91,
          "name": "KABUPATEN PEGUNUNGAN ARFAK"
        },
        {
          "id": 9171,
          "province_id": 91,
          "name": "KOTA SORONG"
        },
        {
          "id": 9401,
          "province_id": 94,
          "name": "KABUPATEN MERAUKE"
        },
        {
          "id": 9402,
          "province_id": 94,
          "name": "KABUPATEN JAYAWIJAYA"
        },
        {
          "id": 9403,
          "province_id": 94,
          "name": "KABUPATEN JAYAPURA"
        },
        {
          "id": 9404,
          "province_id": 94,
          "name": "KABUPATEN NABIRE"
        },
        {
          "id": 9408,
          "province_id": 94,
          "name": "KABUPATEN KEPULAUAN YAPEN"
        },
        {
          "id": 9409,
          "province_id": 94,
          "name": "KABUPATEN BIAK NUMFOR"
        },
        {
          "id": 9410,
          "province_id": 94,
          "name": "KABUPATEN PANIAI"
        },
        {
          "id": 9411,
          "province_id": 94,
          "name": "KABUPATEN PUNCAK JAYA"
        },
        {
          "id": 9412,
          "province_id": 94,
          "name": "KABUPATEN MIMIKA"
        },
        {
          "id": 9413,
          "province_id": 94,
          "name": "KABUPATEN BOVEN DIGOEL"
        },
        {
          "id": 9414,
          "province_id": 94,
          "name": "KABUPATEN MAPPI"
        },
        {
          "id": 9415,
          "province_id": 94,
          "name": "KABUPATEN ASMAT"
        },
        {
          "id": 9416,
          "province_id": 94,
          "name": "KABUPATEN YAHUKIMO"
        },
        {
          "id": 9417,
          "province_id": 94,
          "name": "KABUPATEN PEGUNUNGAN BINTANG"
        },
        {
          "id": 9418,
          "province_id": 94,
          "name": "KABUPATEN TOLIKARA"
        },
        {
          "id": 9419,
          "province_id": 94,
          "name": "KABUPATEN SARMI"
        },
        {
          "id": 9420,
          "province_id": 94,
          "name": "KABUPATEN KEEROM"
        },
        {
          "id": 9426,
          "province_id": 94,
          "name": "KABUPATEN WAROPEN"
        },
        {
          "id": 9427,
          "province_id": 94,
          "name": "KABUPATEN SUPIORI"
        },
        {
          "id": 9428,
          "province_id": 94,
          "name": "KABUPATEN MAMBERAMO RAYA"
        },
        {
          "id": 9429,
          "province_id": 94,
          "name": "KABUPATEN NDUGA"
        },
        {
          "id": 9430,
          "province_id": 94,
          "name": "KABUPATEN LANNY JAYA"
        },
        {
          "id": 9431,
          "province_id": 94,
          "name": "KABUPATEN MAMBERAMO TENGAH"
        },
        {
          "id": 9432,
          "province_id": 94,
          "name": "KABUPATEN YALIMO"
        },
        {
          "id": 9433,
          "province_id": 94,
          "name": "KABUPATEN PUNCAK"
        },
        {
          "id": 9434,
          "province_id": 94,
          "name": "KABUPATEN DOGIYAI"
        },
        {
          "id": 9435,
          "province_id": 94,
          "name": "KABUPATEN INTAN JAYA"
        },
        {
          "id": 9436,
          "province_id": 94,
          "name": "KABUPATEN DEIYAI"
        },
        {
          "id": 9471,
          "province_id": 94,
          "name": "KOTA JAYAPURA"
        }
      ]
    let districts = [
        {
          "id": 3101010,
          "regency_id": "3101",
          "name": "KEPULAUAN SERIBU SELATAN"
        },
        {
          "id": 3101020,
          "regency_id": "3101",
          "name": "KEPULAUAN SERIBU UTARA"
        },
        {
          "id": 3171010,
          "regency_id": "3171",
          "name": "JAGAKARSA"
        },
        {
          "id": 3171020,
          "regency_id": "3171",
          "name": "PASAR MINGGU"
        },
        {
          "id": 3171030,
          "regency_id": "3171",
          "name": "CILANDAK"
        },
        {
          "id": 3171040,
          "regency_id": "3171",
          "name": "PESANGGRAHAN"
        },
        {
          "id": 3171050,
          "regency_id": "3171",
          "name": "KEBAYORAN LAMA"
        },
        {
          "id": 3171060,
          "regency_id": "3171",
          "name": "KEBAYORAN BARU"
        },
        {
          "id": 3171070,
          "regency_id": "3171",
          "name": "MAMPANG PRAPATAN"
        },
        {
          "id": 3171080,
          "regency_id": "3171",
          "name": "PANCORAN"
        },
        {
          "id": 3171090,
          "regency_id": "3171",
          "name": "TEBET"
        },
        {
          "id": 3171100,
          "regency_id": "3171",
          "name": "SETIA BUDI"
        },
        {
          "id": 3172010,
          "regency_id": "3172",
          "name": "PASAR REBO"
        },
        {
          "id": 3172020,
          "regency_id": "3172",
          "name": "CIRACAS"
        },
        {
          "id": 3172030,
          "regency_id": "3172",
          "name": "CIPAYUNG"
        },
        {
          "id": 3172040,
          "regency_id": "3172",
          "name": "MAKASAR"
        },
        {
          "id": 3172050,
          "regency_id": "3172",
          "name": "KRAMAT JATI"
        },
        {
          "id": 3172060,
          "regency_id": "3172",
          "name": "JATINEGARA"
        },
        {
          "id": 3172070,
          "regency_id": "3172",
          "name": "DUREN SAWIT"
        },
        {
          "id": 3172080,
          "regency_id": "3172",
          "name": "CAKUNG"
        },
        {
          "id": 3172090,
          "regency_id": "3172",
          "name": "PULO GADUNG"
        },
        {
          "id": 3172100,
          "regency_id": "3172",
          "name": "MATRAMAN"
        },
        {
          "id": 3173010,
          "regency_id": "3173",
          "name": "TANAH ABANG"
        },
        {
          "id": 3173020,
          "regency_id": "3173",
          "name": "MENTENG"
        },
        {
          "id": 3173030,
          "regency_id": "3173",
          "name": "SENEN"
        },
        {
          "id": 3173040,
          "regency_id": "3173",
          "name": "JOHAR BARU"
        },
        {
          "id": 3173050,
          "regency_id": "3173",
          "name": "CEMPAKA PUTIH"
        },
        {
          "id": 3173060,
          "regency_id": "3173",
          "name": "KEMAYORAN"
        },
        {
          "id": 3173070,
          "regency_id": "3173",
          "name": "SAWAH BESAR"
        },
        {
          "id": 3173080,
          "regency_id": "3173",
          "name": "GAMBIR"
        },
        {
          "id": 3174010,
          "regency_id": "3174",
          "name": "KEMBANGAN"
        },
        {
          "id": 3174020,
          "regency_id": "3174",
          "name": "KEBON JERUK"
        },
        {
          "id": 3174030,
          "regency_id": "3174",
          "name": "PALMERAH"
        },
        {
          "id": 3174040,
          "regency_id": "3174",
          "name": "GROGOL PETAMBURAN"
        },
        {
          "id": 3174050,
          "regency_id": "3174",
          "name": "TAMBORA"
        },
        {
          "id": 3174060,
          "regency_id": "3174",
          "name": "TAMAN SARI"
        },
        {
          "id": 3174070,
          "regency_id": "3174",
          "name": "CENGKARENG"
        },
        {
          "id": 3174080,
          "regency_id": "3174",
          "name": "KALI DERES"
        },
        {
          "id": 3175010,
          "regency_id": "3175",
          "name": "PENJARINGAN"
        },
        {
          "id": 3175020,
          "regency_id": "3175",
          "name": "PADEMANGAN"
        },
        {
          "id": 3175030,
          "regency_id": "3175",
          "name": "TANJUNG PRIOK"
        },
        {
          "id": 3175040,
          "regency_id": "3175",
          "name": "KOJA"
        },
        {
          "id": 3175050,
          "regency_id": "3175",
          "name": "KELAPA GADING"
        },
        {
          "id": 3175060,
          "regency_id": "3175",
          "name": "CILINCING"
        },
        {
          "id": 3201010,
          "regency_id": "3201",
          "name": "NANGGUNG"
        },
        {
          "id": 3201020,
          "regency_id": "3201",
          "name": "LEUWILIANG"
        },
        {
          "id": 3201021,
          "regency_id": "3201",
          "name": "LEUWISADENG"
        },
        {
          "id": 3201030,
          "regency_id": "3201",
          "name": "PAMIJAHAN"
        },
        {
          "id": 3201040,
          "regency_id": "3201",
          "name": "CIBUNGBULANG"
        },
        {
          "id": 3201050,
          "regency_id": "3201",
          "name": "CIAMPEA"
        },
        {
          "id": 3201051,
          "regency_id": "3201",
          "name": "TENJOLAYA"
        },
        {
          "id": 3201060,
          "regency_id": "3201",
          "name": "DRAMAGA"
        },
        {
          "id": 3201070,
          "regency_id": "3201",
          "name": "CIOMAS"
        },
        {
          "id": 3201071,
          "regency_id": "3201",
          "name": "TAMANSARI"
        },
        {
          "id": 3201080,
          "regency_id": "3201",
          "name": "CIJERUK"
        },
        {
          "id": 3201081,
          "regency_id": "3201",
          "name": "CIGOMBONG"
        },
        {
          "id": 3201090,
          "regency_id": "3201",
          "name": "CARINGIN"
        },
        {
          "id": 3201100,
          "regency_id": "3201",
          "name": "CIAWI"
        },
        {
          "id": 3201110,
          "regency_id": "3201",
          "name": "CISARUA"
        },
        {
          "id": 3201120,
          "regency_id": "3201",
          "name": "MEGAMENDUNG"
        },
        {
          "id": 3201130,
          "regency_id": "3201",
          "name": "SUKARAJA"
        },
        {
          "id": 3201140,
          "regency_id": "3201",
          "name": "BABAKAN MADANG"
        },
        {
          "id": 3201150,
          "regency_id": "3201",
          "name": "SUKAMAKMUR"
        },
        {
          "id": 3201160,
          "regency_id": "3201",
          "name": "CARIU"
        },
        {
          "id": 3201161,
          "regency_id": "3201",
          "name": "TANJUNGSARI"
        },
        {
          "id": 3201170,
          "regency_id": "3201",
          "name": "JONGGOL"
        },
        {
          "id": 3201180,
          "regency_id": "3201",
          "name": "CILEUNGSI"
        },
        {
          "id": 3201181,
          "regency_id": "3201",
          "name": "KELAPA NUNGGAL"
        },
        {
          "id": 3201190,
          "regency_id": "3201",
          "name": "GUNUNG PUTRI"
        },
        {
          "id": 3201200,
          "regency_id": "3201",
          "name": "CITEUREUP"
        },
        {
          "id": 3201210,
          "regency_id": "3201",
          "name": "CIBINONG"
        },
        {
          "id": 3201220,
          "regency_id": "3201",
          "name": "BOJONG GEDE"
        },
        {
          "id": 3201221,
          "regency_id": "3201",
          "name": "TAJUR HALANG"
        },
        {
          "id": 3201230,
          "regency_id": "3201",
          "name": "KEMANG"
        },
        {
          "id": 3201231,
          "regency_id": "3201",
          "name": "RANCA BUNGUR"
        },
        {
          "id": 3201240,
          "regency_id": "3201",
          "name": "PARUNG"
        },
        {
          "id": 3201241,
          "regency_id": "3201",
          "name": "CISEENG"
        },
        {
          "id": 3201250,
          "regency_id": "3201",
          "name": "GUNUNG SINDUR"
        },
        {
          "id": 3201260,
          "regency_id": "3201",
          "name": "RUMPIN"
        },
        {
          "id": 3201270,
          "regency_id": "3201",
          "name": "CIGUDEG"
        },
        {
          "id": 3201271,
          "regency_id": "3201",
          "name": "SUKAJAYA"
        },
        {
          "id": 3201280,
          "regency_id": "3201",
          "name": "JASINGA"
        },
        {
          "id": 3201290,
          "regency_id": "3201",
          "name": "TENJO"
        },
        {
          "id": 3201300,
          "regency_id": "3201",
          "name": "PARUNG PANJANG"
        },
        {
          "id": 3202010,
          "regency_id": "3202",
          "name": "CIEMAS"
        },
        {
          "id": 3202020,
          "regency_id": "3202",
          "name": "CIRACAP"
        },
        {
          "id": 3202021,
          "regency_id": "3202",
          "name": "WALURAN"
        },
        {
          "id": 3202030,
          "regency_id": "3202",
          "name": "SURADE"
        },
        {
          "id": 3202031,
          "regency_id": "3202",
          "name": "CIBITUNG"
        },
        {
          "id": 3202040,
          "regency_id": "3202",
          "name": "JAMPANG KULON"
        },
        {
          "id": 3202041,
          "regency_id": "3202",
          "name": "CIMANGGU"
        },
        {
          "id": 3202050,
          "regency_id": "3202",
          "name": "KALI BUNDER"
        },
        {
          "id": 3202060,
          "regency_id": "3202",
          "name": "TEGAL BULEUD"
        },
        {
          "id": 3202070,
          "regency_id": "3202",
          "name": "CIDOLOG"
        },
        {
          "id": 3202080,
          "regency_id": "3202",
          "name": "SAGARANTEN"
        },
        {
          "id": 3202081,
          "regency_id": "3202",
          "name": "CIDADAP"
        },
        {
          "id": 3202082,
          "regency_id": "3202",
          "name": "CURUGKEMBAR"
        },
        {
          "id": 3202090,
          "regency_id": "3202",
          "name": "PABUARAN"
        },
        {
          "id": 3202100,
          "regency_id": "3202",
          "name": "LENGKONG"
        },
        {
          "id": 3202110,
          "regency_id": "3202",
          "name": "PALABUHANRATU"
        },
        {
          "id": 3202111,
          "regency_id": "3202",
          "name": "SIMPENAN"
        },
        {
          "id": 3202120,
          "regency_id": "3202",
          "name": "WARUNG KIARA"
        },
        {
          "id": 3202121,
          "regency_id": "3202",
          "name": "BANTARGADUNG"
        },
        {
          "id": 3202130,
          "regency_id": "3202",
          "name": "JAMPANG TENGAH"
        },
        {
          "id": 3202131,
          "regency_id": "3202",
          "name": "PURABAYA"
        },
        {
          "id": 3202140,
          "regency_id": "3202",
          "name": "CIKEMBAR"
        },
        {
          "id": 3202150,
          "regency_id": "3202",
          "name": "NYALINDUNG"
        },
        {
          "id": 3202160,
          "regency_id": "3202",
          "name": "GEGER BITUNG"
        },
        {
          "id": 3202170,
          "regency_id": "3202",
          "name": "SUKARAJA"
        },
        {
          "id": 3202171,
          "regency_id": "3202",
          "name": "KEBONPEDES"
        },
        {
          "id": 3202172,
          "regency_id": "3202",
          "name": "CIREUNGHAS"
        },
        {
          "id": 3202173,
          "regency_id": "3202",
          "name": "SUKALARANG"
        },
        {
          "id": 3202180,
          "regency_id": "3202",
          "name": "SUKABUMI"
        },
        {
          "id": 3202190,
          "regency_id": "3202",
          "name": "KADUDAMPIT"
        },
        {
          "id": 3202200,
          "regency_id": "3202",
          "name": "CISAAT"
        },
        {
          "id": 3202201,
          "regency_id": "3202",
          "name": "GUNUNGGURUH"
        },
        {
          "id": 3202210,
          "regency_id": "3202",
          "name": "CIBADAK"
        },
        {
          "id": 3202211,
          "regency_id": "3202",
          "name": "CICANTAYAN"
        },
        {
          "id": 3202212,
          "regency_id": "3202",
          "name": "CARINGIN"
        },
        {
          "id": 3202220,
          "regency_id": "3202",
          "name": "NAGRAK"
        },
        {
          "id": 3202221,
          "regency_id": "3202",
          "name": "CIAMBAR"
        },
        {
          "id": 3202230,
          "regency_id": "3202",
          "name": "CICURUG"
        },
        {
          "id": 3202240,
          "regency_id": "3202",
          "name": "CIDAHU"
        },
        {
          "id": 3202250,
          "regency_id": "3202",
          "name": "PARAKAN SALAK"
        },
        {
          "id": 3202260,
          "regency_id": "3202",
          "name": "PARUNG KUDA"
        },
        {
          "id": 3202261,
          "regency_id": "3202",
          "name": "BOJONG GENTENG"
        },
        {
          "id": 3202270,
          "regency_id": "3202",
          "name": "KALAPA NUNGGAL"
        },
        {
          "id": 3202280,
          "regency_id": "3202",
          "name": "CIKIDANG"
        },
        {
          "id": 3202290,
          "regency_id": "3202",
          "name": "CISOLOK"
        },
        {
          "id": 3202291,
          "regency_id": "3202",
          "name": "CIKAKAK"
        },
        {
          "id": 3202300,
          "regency_id": "3202",
          "name": "KABANDUNGAN"
        },
        {
          "id": 3203010,
          "regency_id": "3203",
          "name": "AGRABINTA"
        },
        {
          "id": 3203011,
          "regency_id": "3203",
          "name": "LELES"
        },
        {
          "id": 3203020,
          "regency_id": "3203",
          "name": "SINDANGBARANG"
        },
        {
          "id": 3203030,
          "regency_id": "3203",
          "name": "CIDAUN"
        },
        {
          "id": 3203040,
          "regency_id": "3203",
          "name": "NARINGGUL"
        },
        {
          "id": 3203050,
          "regency_id": "3203",
          "name": "CIBINONG"
        },
        {
          "id": 3203051,
          "regency_id": "3203",
          "name": "CIKADU"
        },
        {
          "id": 3203060,
          "regency_id": "3203",
          "name": "TANGGEUNG"
        },
        {
          "id": 3203061,
          "regency_id": "3203",
          "name": "PASIRKUDA"
        },
        {
          "id": 3203070,
          "regency_id": "3203",
          "name": "KADUPANDAK"
        },
        {
          "id": 3203071,
          "regency_id": "3203",
          "name": "CIJATI"
        },
        {
          "id": 3203080,
          "regency_id": "3203",
          "name": "TAKOKAK"
        },
        {
          "id": 3203090,
          "regency_id": "3203",
          "name": "SUKANAGARA"
        },
        {
          "id": 3203100,
          "regency_id": "3203",
          "name": "PAGELARAN"
        },
        {
          "id": 3203110,
          "regency_id": "3203",
          "name": "CAMPAKA"
        },
        {
          "id": 3203111,
          "regency_id": "3203",
          "name": "CAMPAKA MULYA"
        },
        {
          "id": 3203120,
          "regency_id": "3203",
          "name": "CIBEBER"
        },
        {
          "id": 3203130,
          "regency_id": "3203",
          "name": "WARUNGKONDANG"
        },
        {
          "id": 3203131,
          "regency_id": "3203",
          "name": "GEKBRONG"
        },
        {
          "id": 3203140,
          "regency_id": "3203",
          "name": "CILAKU"
        },
        {
          "id": 3203150,
          "regency_id": "3203",
          "name": "SUKALUYU"
        },
        {
          "id": 3203160,
          "regency_id": "3203",
          "name": "BOJONGPICUNG"
        },
        {
          "id": 3203161,
          "regency_id": "3203",
          "name": "HAURWANGI"
        },
        {
          "id": 3203170,
          "regency_id": "3203",
          "name": "CIRANJANG"
        },
        {
          "id": 3203180,
          "regency_id": "3203",
          "name": "MANDE"
        },
        {
          "id": 3203190,
          "regency_id": "3203",
          "name": "KARANGTENGAH"
        },
        {
          "id": 3203200,
          "regency_id": "3203",
          "name": "CIANJUR"
        },
        {
          "id": 3203210,
          "regency_id": "3203",
          "name": "CUGENANG"
        },
        {
          "id": 3203220,
          "regency_id": "3203",
          "name": "PACET"
        },
        {
          "id": 3203221,
          "regency_id": "3203",
          "name": "CIPANAS"
        },
        {
          "id": 3203230,
          "regency_id": "3203",
          "name": "SUKARESMI"
        },
        {
          "id": 3203240,
          "regency_id": "3203",
          "name": "CIKALONGKULON"
        },
        {
          "id": 3204010,
          "regency_id": "3204",
          "name": "CIWIDEY"
        },
        {
          "id": 3204011,
          "regency_id": "3204",
          "name": "RANCABALI"
        },
        {
          "id": 3204020,
          "regency_id": "3204",
          "name": "PASIRJAMBU"
        },
        {
          "id": 3204030,
          "regency_id": "3204",
          "name": "CIMAUNG"
        },
        {
          "id": 3204040,
          "regency_id": "3204",
          "name": "PANGALENGAN"
        },
        {
          "id": 3204050,
          "regency_id": "3204",
          "name": "KERTASARI"
        },
        {
          "id": 3204060,
          "regency_id": "3204",
          "name": "PACET"
        },
        {
          "id": 3204070,
          "regency_id": "3204",
          "name": "IBUN"
        },
        {
          "id": 3204080,
          "regency_id": "3204",
          "name": "PASEH"
        },
        {
          "id": 3204090,
          "regency_id": "3204",
          "name": "CIKANCUNG"
        },
        {
          "id": 3204100,
          "regency_id": "3204",
          "name": "CICALENGKA"
        },
        {
          "id": 3204101,
          "regency_id": "3204",
          "name": "NAGREG"
        },
        {
          "id": 3204110,
          "regency_id": "3204",
          "name": "RANCAEKEK"
        },
        {
          "id": 3204120,
          "regency_id": "3204",
          "name": "MAJALAYA"
        },
        {
          "id": 3204121,
          "regency_id": "3204",
          "name": "SOLOKAN JERUK"
        },
        {
          "id": 3204130,
          "regency_id": "3204",
          "name": "CIPARAY"
        },
        {
          "id": 3204140,
          "regency_id": "3204",
          "name": "BALEENDAH"
        },
        {
          "id": 3204150,
          "regency_id": "3204",
          "name": "ARJASARI"
        },
        {
          "id": 3204160,
          "regency_id": "3204",
          "name": "BANJARAN"
        },
        {
          "id": 3204161,
          "regency_id": "3204",
          "name": "CANGKUANG"
        },
        {
          "id": 3204170,
          "regency_id": "3204",
          "name": "PAMEUNGPEUK"
        },
        {
          "id": 3204180,
          "regency_id": "3204",
          "name": "KATAPANG"
        },
        {
          "id": 3204190,
          "regency_id": "3204",
          "name": "SOREANG"
        },
        {
          "id": 3204191,
          "regency_id": "3204",
          "name": "KUTAWARINGIN"
        },
        {
          "id": 3204250,
          "regency_id": "3204",
          "name": "MARGAASIH"
        },
        {
          "id": 3204260,
          "regency_id": "3204",
          "name": "MARGAHAYU"
        },
        {
          "id": 3204270,
          "regency_id": "3204",
          "name": "DAYEUHKOLOT"
        },
        {
          "id": 3204280,
          "regency_id": "3204",
          "name": "BOJONGSOANG"
        },
        {
          "id": 3204290,
          "regency_id": "3204",
          "name": "CILEUNYI"
        },
        {
          "id": 3204300,
          "regency_id": "3204",
          "name": "CILENGKRANG"
        },
        {
          "id": 3204310,
          "regency_id": "3204",
          "name": "CIMENYAN"
        },
        {
          "id": 3205010,
          "regency_id": "3205",
          "name": "CISEWU"
        },
        {
          "id": 3205011,
          "regency_id": "3205",
          "name": "CARINGIN"
        },
        {
          "id": 3205020,
          "regency_id": "3205",
          "name": "TALEGONG"
        },
        {
          "id": 3205030,
          "regency_id": "3205",
          "name": "BUNGBULANG"
        },
        {
          "id": 3205031,
          "regency_id": "3205",
          "name": "MEKARMUKTI"
        },
        {
          "id": 3205040,
          "regency_id": "3205",
          "name": "PAMULIHAN"
        },
        {
          "id": 3205050,
          "regency_id": "3205",
          "name": "PAKENJENG"
        },
        {
          "id": 3205060,
          "regency_id": "3205",
          "name": "CIKELET"
        },
        {
          "id": 3205070,
          "regency_id": "3205",
          "name": "PAMEUNGPEUK"
        },
        {
          "id": 3205080,
          "regency_id": "3205",
          "name": "CIBALONG"
        },
        {
          "id": 3205090,
          "regency_id": "3205",
          "name": "CISOMPET"
        },
        {
          "id": 3205100,
          "regency_id": "3205",
          "name": "PEUNDEUY"
        },
        {
          "id": 3205110,
          "regency_id": "3205",
          "name": "SINGAJAYA"
        },
        {
          "id": 3205111,
          "regency_id": "3205",
          "name": "CIHURIP"
        },
        {
          "id": 3205120,
          "regency_id": "3205",
          "name": "CIKAJANG"
        },
        {
          "id": 3205130,
          "regency_id": "3205",
          "name": "BANJARWANGI"
        },
        {
          "id": 3205140,
          "regency_id": "3205",
          "name": "CILAWU"
        },
        {
          "id": 3205150,
          "regency_id": "3205",
          "name": "BAYONGBONG"
        },
        {
          "id": 3205151,
          "regency_id": "3205",
          "name": "CIGEDUG"
        },
        {
          "id": 3205160,
          "regency_id": "3205",
          "name": "CISURUPAN"
        },
        {
          "id": 3205161,
          "regency_id": "3205",
          "name": "SUKARESMI"
        },
        {
          "id": 3205170,
          "regency_id": "3205",
          "name": "SAMARANG"
        },
        {
          "id": 3205171,
          "regency_id": "3205",
          "name": "PASIRWANGI"
        },
        {
          "id": 3205181,
          "regency_id": "3205",
          "name": "TAROGONG KIDUL"
        },
        {
          "id": 3205182,
          "regency_id": "3205",
          "name": "TAROGONG KALER"
        },
        {
          "id": 3205190,
          "regency_id": "3205",
          "name": "GARUT KOTA"
        },
        {
          "id": 3205200,
          "regency_id": "3205",
          "name": "KARANGPAWITAN"
        },
        {
          "id": 3205210,
          "regency_id": "3205",
          "name": "WANARAJA"
        },
        {
          "id": 3205211,
          "regency_id": "3205",
          "name": "SUCINARAJA"
        },
        {
          "id": 3205212,
          "regency_id": "3205",
          "name": "PANGATIKAN"
        },
        {
          "id": 3205220,
          "regency_id": "3205",
          "name": "SUKAWENING"
        },
        {
          "id": 3205221,
          "regency_id": "3205",
          "name": "KARANGTENGAH"
        },
        {
          "id": 3205230,
          "regency_id": "3205",
          "name": "BANYURESMI"
        },
        {
          "id": 3205240,
          "regency_id": "3205",
          "name": "LELES"
        },
        {
          "id": 3205250,
          "regency_id": "3205",
          "name": "LEUWIGOONG"
        },
        {
          "id": 3205260,
          "regency_id": "3205",
          "name": "CIBATU"
        },
        {
          "id": 3205261,
          "regency_id": "3205",
          "name": "KERSAMANAH"
        },
        {
          "id": 3205270,
          "regency_id": "3205",
          "name": "CIBIUK"
        },
        {
          "id": 3205280,
          "regency_id": "3205",
          "name": "KADUNGORA"
        },
        {
          "id": 3205290,
          "regency_id": "3205",
          "name": "BLUBUR LIMBANGAN"
        },
        {
          "id": 3205300,
          "regency_id": "3205",
          "name": "SELAAWI"
        },
        {
          "id": 3205310,
          "regency_id": "3205",
          "name": "MALANGBONG"
        },
        {
          "id": 3206010,
          "regency_id": "3206",
          "name": "CIPATUJAH"
        },
        {
          "id": 3206020,
          "regency_id": "3206",
          "name": "KARANGNUNGGAL"
        },
        {
          "id": 3206030,
          "regency_id": "3206",
          "name": "CIKALONG"
        },
        {
          "id": 3206040,
          "regency_id": "3206",
          "name": "PANCATENGAH"
        },
        {
          "id": 3206050,
          "regency_id": "3206",
          "name": "CIKATOMAS"
        },
        {
          "id": 3206060,
          "regency_id": "3206",
          "name": "CIBALONG"
        },
        {
          "id": 3206061,
          "regency_id": "3206",
          "name": "PARUNGPONTENG"
        },
        {
          "id": 3206070,
          "regency_id": "3206",
          "name": "BANTARKALONG"
        },
        {
          "id": 3206071,
          "regency_id": "3206",
          "name": "BOJONGASIH"
        },
        {
          "id": 3206072,
          "regency_id": "3206",
          "name": "CULAMEGA"
        },
        {
          "id": 3206080,
          "regency_id": "3206",
          "name": "BOJONGGAMBIR"
        },
        {
          "id": 3206090,
          "regency_id": "3206",
          "name": "SODONGHILIR"
        },
        {
          "id": 3206100,
          "regency_id": "3206",
          "name": "TARAJU"
        },
        {
          "id": 3206110,
          "regency_id": "3206",
          "name": "SALAWU"
        },
        {
          "id": 3206111,
          "regency_id": "3206",
          "name": "PUSPAHIANG"
        },
        {
          "id": 3206120,
          "regency_id": "3206",
          "name": "TANJUNGJAYA"
        },
        {
          "id": 3206130,
          "regency_id": "3206",
          "name": "SUKARAJA"
        },
        {
          "id": 3206140,
          "regency_id": "3206",
          "name": "SALOPA"
        },
        {
          "id": 3206141,
          "regency_id": "3206",
          "name": "JATIWARAS"
        },
        {
          "id": 3206150,
          "regency_id": "3206",
          "name": "CINEAM"
        },
        {
          "id": 3206151,
          "regency_id": "3206",
          "name": "KARANGJAYA"
        },
        {
          "id": 3206160,
          "regency_id": "3206",
          "name": "MANONJAYA"
        },
        {
          "id": 3206161,
          "regency_id": "3206",
          "name": "GUNUNGTANJUNG"
        },
        {
          "id": 3206190,
          "regency_id": "3206",
          "name": "SINGAPARNA"
        },
        {
          "id": 3206191,
          "regency_id": "3206",
          "name": "SUKARAME"
        },
        {
          "id": 3206192,
          "regency_id": "3206",
          "name": "MANGUNREJA"
        },
        {
          "id": 3206200,
          "regency_id": "3206",
          "name": "CIGALONTANG"
        },
        {
          "id": 3206210,
          "regency_id": "3206",
          "name": "LEUWISARI"
        },
        {
          "id": 3206211,
          "regency_id": "3206",
          "name": "SARIWANGI"
        },
        {
          "id": 3206212,
          "regency_id": "3206",
          "name": "PADAKEMBANG"
        },
        {
          "id": 3206221,
          "regency_id": "3206",
          "name": "SUKARATU"
        },
        {
          "id": 3206230,
          "regency_id": "3206",
          "name": "CISAYONG"
        },
        {
          "id": 3206231,
          "regency_id": "3206",
          "name": "SUKAHENING"
        },
        {
          "id": 3206240,
          "regency_id": "3206",
          "name": "RAJAPOLAH"
        },
        {
          "id": 3206250,
          "regency_id": "3206",
          "name": "JAMANIS"
        },
        {
          "id": 3206260,
          "regency_id": "3206",
          "name": "CIAWI"
        },
        {
          "id": 3206261,
          "regency_id": "3206",
          "name": "KADIPATEN"
        },
        {
          "id": 3206270,
          "regency_id": "3206",
          "name": "PAGERAGEUNG"
        },
        {
          "id": 3206271,
          "regency_id": "3206",
          "name": "SUKARESIK"
        },
        {
          "id": 3207100,
          "regency_id": "3207",
          "name": "BANJARSARI"
        },
        {
          "id": 3207101,
          "regency_id": "3207",
          "name": "BANJARANYAR"
        },
        {
          "id": 3207110,
          "regency_id": "3207",
          "name": "LAKBOK"
        },
        {
          "id": 3207111,
          "regency_id": "3207",
          "name": "PURWADADI"
        },
        {
          "id": 3207120,
          "regency_id": "3207",
          "name": "PAMARICAN"
        },
        {
          "id": 3207130,
          "regency_id": "3207",
          "name": "CIDOLOG"
        },
        {
          "id": 3207140,
          "regency_id": "3207",
          "name": "CIMARAGAS"
        },
        {
          "id": 3207150,
          "regency_id": "3207",
          "name": "CIJEUNGJING"
        },
        {
          "id": 3207160,
          "regency_id": "3207",
          "name": "CISAGA"
        },
        {
          "id": 3207170,
          "regency_id": "3207",
          "name": "TAMBAKSARI"
        },
        {
          "id": 3207180,
          "regency_id": "3207",
          "name": "RANCAH"
        },
        {
          "id": 3207190,
          "regency_id": "3207",
          "name": "RAJADESA"
        },
        {
          "id": 3207200,
          "regency_id": "3207",
          "name": "SUKADANA"
        },
        {
          "id": 3207210,
          "regency_id": "3207",
          "name": "CIAMIS"
        },
        {
          "id": 3207211,
          "regency_id": "3207",
          "name": "BAREGBEG"
        },
        {
          "id": 3207220,
          "regency_id": "3207",
          "name": "CIKONENG"
        },
        {
          "id": 3207221,
          "regency_id": "3207",
          "name": "SINDANGKASIH"
        },
        {
          "id": 3207230,
          "regency_id": "3207",
          "name": "CIHAURBEUTI"
        },
        {
          "id": 3207240,
          "regency_id": "3207",
          "name": "SADANANYA"
        },
        {
          "id": 3207250,
          "regency_id": "3207",
          "name": "CIPAKU"
        },
        {
          "id": 3207260,
          "regency_id": "3207",
          "name": "JATINAGARA"
        },
        {
          "id": 3207270,
          "regency_id": "3207",
          "name": "PANAWANGAN"
        },
        {
          "id": 3207280,
          "regency_id": "3207",
          "name": "KAWALI"
        },
        {
          "id": 3207281,
          "regency_id": "3207",
          "name": "LUMBUNG"
        },
        {
          "id": 3207290,
          "regency_id": "3207",
          "name": "PANJALU"
        },
        {
          "id": 3207291,
          "regency_id": "3207",
          "name": "SUKAMANTRI"
        },
        {
          "id": 3207300,
          "regency_id": "3207",
          "name": "PANUMBANGAN"
        },
        {
          "id": 3208010,
          "regency_id": "3208",
          "name": "DARMA"
        },
        {
          "id": 3208020,
          "regency_id": "3208",
          "name": "KADUGEDE"
        },
        {
          "id": 3208021,
          "regency_id": "3208",
          "name": "NUSAHERANG"
        },
        {
          "id": 3208030,
          "regency_id": "3208",
          "name": "CINIRU"
        },
        {
          "id": 3208031,
          "regency_id": "3208",
          "name": "HANTARA"
        },
        {
          "id": 3208040,
          "regency_id": "3208",
          "name": "SELAJAMBE"
        },
        {
          "id": 3208050,
          "regency_id": "3208",
          "name": "SUBANG"
        },
        {
          "id": 3208051,
          "regency_id": "3208",
          "name": "CILEBAK"
        },
        {
          "id": 3208060,
          "regency_id": "3208",
          "name": "CIWARU"
        },
        {
          "id": 3208061,
          "regency_id": "3208",
          "name": "KARANGKANCANA"
        },
        {
          "id": 3208070,
          "regency_id": "3208",
          "name": "CIBINGBIN"
        },
        {
          "id": 3208071,
          "regency_id": "3208",
          "name": "CIBEUREUM"
        },
        {
          "id": 3208080,
          "regency_id": "3208",
          "name": "LURAGUNG"
        },
        {
          "id": 3208081,
          "regency_id": "3208",
          "name": "CIMAHI"
        },
        {
          "id": 3208090,
          "regency_id": "3208",
          "name": "CIDAHU"
        },
        {
          "id": 3208091,
          "regency_id": "3208",
          "name": "KALIMANGGIS"
        },
        {
          "id": 3208100,
          "regency_id": "3208",
          "name": "CIAWIGEBANG"
        },
        {
          "id": 3208101,
          "regency_id": "3208",
          "name": "CIPICUNG"
        },
        {
          "id": 3208110,
          "regency_id": "3208",
          "name": "LEBAKWANGI"
        },
        {
          "id": 3208111,
          "regency_id": "3208",
          "name": "MALEBER"
        },
        {
          "id": 3208120,
          "regency_id": "3208",
          "name": "GARAWANGI"
        },
        {
          "id": 3208121,
          "regency_id": "3208",
          "name": "SINDANGAGUNG"
        },
        {
          "id": 3208130,
          "regency_id": "3208",
          "name": "KUNINGAN"
        },
        {
          "id": 3208140,
          "regency_id": "3208",
          "name": "CIGUGUR"
        },
        {
          "id": 3208150,
          "regency_id": "3208",
          "name": "KRAMATMULYA"
        },
        {
          "id": 3208160,
          "regency_id": "3208",
          "name": "JALAKSANA"
        },
        {
          "id": 3208161,
          "regency_id": "3208",
          "name": "JAPARA"
        },
        {
          "id": 3208170,
          "regency_id": "3208",
          "name": "CILIMUS"
        },
        {
          "id": 3208171,
          "regency_id": "3208",
          "name": "CIGANDAMEKAR"
        },
        {
          "id": 3208180,
          "regency_id": "3208",
          "name": "MANDIRANCAN"
        },
        {
          "id": 3208181,
          "regency_id": "3208",
          "name": "PANCALANG"
        },
        {
          "id": 3208190,
          "regency_id": "3208",
          "name": "PASAWAHAN"
        },
        {
          "id": 3209010,
          "regency_id": "3209",
          "name": "WALED"
        },
        {
          "id": 3209011,
          "regency_id": "3209",
          "name": "PASALEMAN"
        },
        {
          "id": 3209020,
          "regency_id": "3209",
          "name": "CILEDUG"
        },
        {
          "id": 3209021,
          "regency_id": "3209",
          "name": "PABUARAN"
        },
        {
          "id": 3209030,
          "regency_id": "3209",
          "name": "LOSARI"
        },
        {
          "id": 3209031,
          "regency_id": "3209",
          "name": "PABEDILAN"
        },
        {
          "id": 3209040,
          "regency_id": "3209",
          "name": "BABAKAN"
        },
        {
          "id": 3209041,
          "regency_id": "3209",
          "name": "GEBANG"
        },
        {
          "id": 3209050,
          "regency_id": "3209",
          "name": "KARANGSEMBUNG"
        },
        {
          "id": 3209051,
          "regency_id": "3209",
          "name": "KARANGWARENG"
        },
        {
          "id": 3209060,
          "regency_id": "3209",
          "name": "LEMAHABANG"
        },
        {
          "id": 3209061,
          "regency_id": "3209",
          "name": "SUSUKANLEBAK"
        },
        {
          "id": 3209070,
          "regency_id": "3209",
          "name": "SEDONG"
        },
        {
          "id": 3209080,
          "regency_id": "3209",
          "name": "ASTANAJAPURA"
        },
        {
          "id": 3209081,
          "regency_id": "3209",
          "name": "PANGENAN"
        },
        {
          "id": 3209090,
          "regency_id": "3209",
          "name": "MUNDU"
        },
        {
          "id": 3209100,
          "regency_id": "3209",
          "name": "BEBER"
        },
        {
          "id": 3209101,
          "regency_id": "3209",
          "name": "GREGED"
        },
        {
          "id": 3209111,
          "regency_id": "3209",
          "name": "TALUN"
        },
        {
          "id": 3209120,
          "regency_id": "3209",
          "name": "SUMBER"
        },
        {
          "id": 3209121,
          "regency_id": "3209",
          "name": "DUKUPUNTANG"
        },
        {
          "id": 3209130,
          "regency_id": "3209",
          "name": "PALIMANAN"
        },
        {
          "id": 3209140,
          "regency_id": "3209",
          "name": "PLUMBON"
        },
        {
          "id": 3209141,
          "regency_id": "3209",
          "name": "DEPOK"
        },
        {
          "id": 3209150,
          "regency_id": "3209",
          "name": "WERU"
        },
        {
          "id": 3209151,
          "regency_id": "3209",
          "name": "PLERED"
        },
        {
          "id": 3209161,
          "regency_id": "3209",
          "name": "TENGAH TANI"
        },
        {
          "id": 3209162,
          "regency_id": "3209",
          "name": "KEDAWUNG"
        },
        {
          "id": 3209171,
          "regency_id": "3209",
          "name": "GUNUNGJATI"
        },
        {
          "id": 3209180,
          "regency_id": "3209",
          "name": "KAPETAKAN"
        },
        {
          "id": 3209181,
          "regency_id": "3209",
          "name": "SURANENGGALA"
        },
        {
          "id": 3209190,
          "regency_id": "3209",
          "name": "KLANGENAN"
        },
        {
          "id": 3209191,
          "regency_id": "3209",
          "name": "JAMBLANG"
        },
        {
          "id": 3209200,
          "regency_id": "3209",
          "name": "ARJAWINANGUN"
        },
        {
          "id": 3209201,
          "regency_id": "3209",
          "name": "PANGURAGAN"
        },
        {
          "id": 3209210,
          "regency_id": "3209",
          "name": "CIWARINGIN"
        },
        {
          "id": 3209211,
          "regency_id": "3209",
          "name": "GEMPOL"
        },
        {
          "id": 3209220,
          "regency_id": "3209",
          "name": "SUSUKAN"
        },
        {
          "id": 3209230,
          "regency_id": "3209",
          "name": "GEGESIK"
        },
        {
          "id": 3209231,
          "regency_id": "3209",
          "name": "KALIWEDI"
        },
        {
          "id": 3210010,
          "regency_id": "3210",
          "name": "LEMAHSUGIH"
        },
        {
          "id": 3210020,
          "regency_id": "3210",
          "name": "BANTARUJEG"
        },
        {
          "id": 3210021,
          "regency_id": "3210",
          "name": "MALAUSMA"
        },
        {
          "id": 3210030,
          "regency_id": "3210",
          "name": "CIKIJING"
        },
        {
          "id": 3210031,
          "regency_id": "3210",
          "name": "CINGAMBUL"
        },
        {
          "id": 3210040,
          "regency_id": "3210",
          "name": "TALAGA"
        },
        {
          "id": 3210041,
          "regency_id": "3210",
          "name": "BANJARAN"
        },
        {
          "id": 3210050,
          "regency_id": "3210",
          "name": "ARGAPURA"
        },
        {
          "id": 3210060,
          "regency_id": "3210",
          "name": "MAJA"
        },
        {
          "id": 3210070,
          "regency_id": "3210",
          "name": "MAJALENGKA"
        },
        {
          "id": 3210080,
          "regency_id": "3210",
          "name": "CIGASONG"
        },
        {
          "id": 3210090,
          "regency_id": "3210",
          "name": "SUKAHAJI"
        },
        {
          "id": 3210091,
          "regency_id": "3210",
          "name": "SINDANG"
        },
        {
          "id": 3210100,
          "regency_id": "3210",
          "name": "RAJAGALUH"
        },
        {
          "id": 3210110,
          "regency_id": "3210",
          "name": "SINDANGWANGI"
        },
        {
          "id": 3210120,
          "regency_id": "3210",
          "name": "LEUWIMUNDING"
        },
        {
          "id": 3210130,
          "regency_id": "3210",
          "name": "PALASAH"
        },
        {
          "id": 3210140,
          "regency_id": "3210",
          "name": "JATIWANGI"
        },
        {
          "id": 3210150,
          "regency_id": "3210",
          "name": "DAWUAN"
        },
        {
          "id": 3210151,
          "regency_id": "3210",
          "name": "KASOKANDEL"
        },
        {
          "id": 3210160,
          "regency_id": "3210",
          "name": "PANYINGKIRAN"
        },
        {
          "id": 3210170,
          "regency_id": "3210",
          "name": "KADIPATEN"
        },
        {
          "id": 3210180,
          "regency_id": "3210",
          "name": "KERTAJATI"
        },
        {
          "id": 3210190,
          "regency_id": "3210",
          "name": "JATITUJUH"
        },
        {
          "id": 3210200,
          "regency_id": "3210",
          "name": "LIGUNG"
        },
        {
          "id": 3210210,
          "regency_id": "3210",
          "name": "SUMBERJAYA"
        },
        {
          "id": 3211010,
          "regency_id": "3211",
          "name": "JATINANGOR"
        },
        {
          "id": 3211020,
          "regency_id": "3211",
          "name": "CIMANGGUNG"
        },
        {
          "id": 3211030,
          "regency_id": "3211",
          "name": "TANJUNGSARI"
        },
        {
          "id": 3211031,
          "regency_id": "3211",
          "name": "SUKASARI"
        },
        {
          "id": 3211032,
          "regency_id": "3211",
          "name": "PAMULIHAN"
        },
        {
          "id": 3211040,
          "regency_id": "3211",
          "name": "RANCAKALONG"
        },
        {
          "id": 3211050,
          "regency_id": "3211",
          "name": "SUMEDANG SELATAN"
        },
        {
          "id": 3211060,
          "regency_id": "3211",
          "name": "SUMEDANG UTARA"
        },
        {
          "id": 3211061,
          "regency_id": "3211",
          "name": "GANEAS"
        },
        {
          "id": 3211070,
          "regency_id": "3211",
          "name": "SITURAJA"
        },
        {
          "id": 3211071,
          "regency_id": "3211",
          "name": "CISITU"
        },
        {
          "id": 3211080,
          "regency_id": "3211",
          "name": "DARMARAJA"
        },
        {
          "id": 3211090,
          "regency_id": "3211",
          "name": "CIBUGEL"
        },
        {
          "id": 3211100,
          "regency_id": "3211",
          "name": "WADO"
        },
        {
          "id": 3211101,
          "regency_id": "3211",
          "name": "JATINUNGGAL"
        },
        {
          "id": 3211111,
          "regency_id": "3211",
          "name": "JATIGEDE"
        },
        {
          "id": 3211120,
          "regency_id": "3211",
          "name": "TOMO"
        },
        {
          "id": 3211130,
          "regency_id": "3211",
          "name": "UJUNG JAYA"
        },
        {
          "id": 3211140,
          "regency_id": "3211",
          "name": "CONGGEANG"
        },
        {
          "id": 3211150,
          "regency_id": "3211",
          "name": "PASEH"
        },
        {
          "id": 3211160,
          "regency_id": "3211",
          "name": "CIMALAKA"
        },
        {
          "id": 3211161,
          "regency_id": "3211",
          "name": "CISARUA"
        },
        {
          "id": 3211170,
          "regency_id": "3211",
          "name": "TANJUNGKERTA"
        },
        {
          "id": 3211171,
          "regency_id": "3211",
          "name": "TANJUNGMEDAR"
        },
        {
          "id": 3211180,
          "regency_id": "3211",
          "name": "BUAHDUA"
        },
        {
          "id": 3211181,
          "regency_id": "3211",
          "name": "SURIAN"
        },
        {
          "id": 3212010,
          "regency_id": "3212",
          "name": "HAURGEULIS"
        },
        {
          "id": 3212011,
          "regency_id": "3212",
          "name": "GANTAR"
        },
        {
          "id": 3212020,
          "regency_id": "3212",
          "name": "KROYA"
        },
        {
          "id": 3212030,
          "regency_id": "3212",
          "name": "GABUSWETAN"
        },
        {
          "id": 3212040,
          "regency_id": "3212",
          "name": "CIKEDUNG"
        },
        {
          "id": 3212041,
          "regency_id": "3212",
          "name": "TERISI"
        },
        {
          "id": 3212050,
          "regency_id": "3212",
          "name": "LELEA"
        },
        {
          "id": 3212060,
          "regency_id": "3212",
          "name": "BANGODUA"
        },
        {
          "id": 3212061,
          "regency_id": "3212",
          "name": "TUKDANA"
        },
        {
          "id": 3212070,
          "regency_id": "3212",
          "name": "WIDASARI"
        },
        {
          "id": 3212080,
          "regency_id": "3212",
          "name": "KERTASEMAYA"
        },
        {
          "id": 3212081,
          "regency_id": "3212",
          "name": "SUKAGUMIWANG"
        },
        {
          "id": 3212090,
          "regency_id": "3212",
          "name": "KRANGKENG"
        },
        {
          "id": 3212100,
          "regency_id": "3212",
          "name": "KARANGAMPEL"
        },
        {
          "id": 3212101,
          "regency_id": "3212",
          "name": "KEDOKAN BUNDER"
        },
        {
          "id": 3212110,
          "regency_id": "3212",
          "name": "JUNTINYUAT"
        },
        {
          "id": 3212120,
          "regency_id": "3212",
          "name": "SLIYEG"
        },
        {
          "id": 3212130,
          "regency_id": "3212",
          "name": "JATIBARANG"
        },
        {
          "id": 3212140,
          "regency_id": "3212",
          "name": "BALONGAN"
        },
        {
          "id": 3212150,
          "regency_id": "3212",
          "name": "INDRAMAYU"
        },
        {
          "id": 3212160,
          "regency_id": "3212",
          "name": "SINDANG"
        },
        {
          "id": 3212161,
          "regency_id": "3212",
          "name": "CANTIGI"
        },
        {
          "id": 3212162,
          "regency_id": "3212",
          "name": "PASEKAN"
        },
        {
          "id": 3212170,
          "regency_id": "3212",
          "name": "LOHBENER"
        },
        {
          "id": 3212171,
          "regency_id": "3212",
          "name": "ARAHAN"
        },
        {
          "id": 3212180,
          "regency_id": "3212",
          "name": "LOSARANG"
        },
        {
          "id": 3212190,
          "regency_id": "3212",
          "name": "KANDANGHAUR"
        },
        {
          "id": 3212200,
          "regency_id": "3212",
          "name": "BONGAS"
        },
        {
          "id": 3212210,
          "regency_id": "3212",
          "name": "ANJATAN"
        },
        {
          "id": 3212220,
          "regency_id": "3212",
          "name": "SUKRA"
        },
        {
          "id": 3212221,
          "regency_id": "3212",
          "name": "PATROL"
        },
        {
          "id": 3213010,
          "regency_id": "3213",
          "name": "SAGALAHERANG"
        },
        {
          "id": 3213011,
          "regency_id": "3213",
          "name": "SERANGPANJANG"
        },
        {
          "id": 3213020,
          "regency_id": "3213",
          "name": "JALANCAGAK"
        },
        {
          "id": 3213021,
          "regency_id": "3213",
          "name": "CIATER"
        },
        {
          "id": 3213030,
          "regency_id": "3213",
          "name": "CISALAK"
        },
        {
          "id": 3213031,
          "regency_id": "3213",
          "name": "KASOMALANG"
        },
        {
          "id": 3213040,
          "regency_id": "3213",
          "name": "TANJUNGSIANG"
        },
        {
          "id": 3213050,
          "regency_id": "3213",
          "name": "CIJAMBE"
        },
        {
          "id": 3213060,
          "regency_id": "3213",
          "name": "CIBOGO"
        },
        {
          "id": 3213070,
          "regency_id": "3213",
          "name": "SUBANG"
        },
        {
          "id": 3213080,
          "regency_id": "3213",
          "name": "KALIJATI"
        },
        {
          "id": 3213081,
          "regency_id": "3213",
          "name": "DAWUAN"
        },
        {
          "id": 3213090,
          "regency_id": "3213",
          "name": "CIPEUNDEUY"
        },
        {
          "id": 3213100,
          "regency_id": "3213",
          "name": "PABUARAN"
        },
        {
          "id": 3213110,
          "regency_id": "3213",
          "name": "PATOKBEUSI"
        },
        {
          "id": 3213120,
          "regency_id": "3213",
          "name": "PURWADADI"
        },
        {
          "id": 3213130,
          "regency_id": "3213",
          "name": "CIKAUM"
        },
        {
          "id": 3213140,
          "regency_id": "3213",
          "name": "PAGADEN"
        },
        {
          "id": 3213141,
          "regency_id": "3213",
          "name": "PAGADEN BARAT"
        },
        {
          "id": 3213150,
          "regency_id": "3213",
          "name": "CIPUNAGARA"
        },
        {
          "id": 3213160,
          "regency_id": "3213",
          "name": "COMPRENG"
        },
        {
          "id": 3213170,
          "regency_id": "3213",
          "name": "BINONG"
        },
        {
          "id": 3213171,
          "regency_id": "3213",
          "name": "TAMBAKDAHAN"
        },
        {
          "id": 3213180,
          "regency_id": "3213",
          "name": "CIASEM"
        },
        {
          "id": 3213190,
          "regency_id": "3213",
          "name": "PAMANUKAN"
        },
        {
          "id": 3213191,
          "regency_id": "3213",
          "name": "SUKASARI"
        },
        {
          "id": 3213200,
          "regency_id": "3213",
          "name": "PUSAKANAGARA"
        },
        {
          "id": 3213201,
          "regency_id": "3213",
          "name": "PUSAKAJAYA"
        },
        {
          "id": 3213210,
          "regency_id": "3213",
          "name": "LEGONKULON"
        },
        {
          "id": 3213220,
          "regency_id": "3213",
          "name": "BLANAKAN"
        },
        {
          "id": 3214010,
          "regency_id": "3214",
          "name": "JATILUHUR"
        },
        {
          "id": 3214011,
          "regency_id": "3214",
          "name": "SUKASARI"
        },
        {
          "id": 3214020,
          "regency_id": "3214",
          "name": "MANIIS"
        },
        {
          "id": 3214030,
          "regency_id": "3214",
          "name": "TEGAL WARU"
        },
        {
          "id": 3214040,
          "regency_id": "3214",
          "name": "PLERED"
        },
        {
          "id": 3214050,
          "regency_id": "3214",
          "name": "SUKATANI"
        },
        {
          "id": 3214060,
          "regency_id": "3214",
          "name": "DARANGDAN"
        },
        {
          "id": 3214070,
          "regency_id": "3214",
          "name": "BOJONG"
        },
        {
          "id": 3214080,
          "regency_id": "3214",
          "name": "WANAYASA"
        },
        {
          "id": 3214081,
          "regency_id": "3214",
          "name": "KIARAPEDES"
        },
        {
          "id": 3214090,
          "regency_id": "3214",
          "name": "PASAWAHAN"
        },
        {
          "id": 3214091,
          "regency_id": "3214",
          "name": "PONDOK SALAM"
        },
        {
          "id": 3214100,
          "regency_id": "3214",
          "name": "PURWAKARTA"
        },
        {
          "id": 3214101,
          "regency_id": "3214",
          "name": "BABAKANCIKAO"
        },
        {
          "id": 3214110,
          "regency_id": "3214",
          "name": "CAMPAKA"
        },
        {
          "id": 3214111,
          "regency_id": "3214",
          "name": "CIBATU"
        },
        {
          "id": 3214112,
          "regency_id": "3214",
          "name": "BUNGURSARI"
        },
        {
          "id": 3215010,
          "regency_id": "3215",
          "name": "PANGKALAN"
        },
        {
          "id": 3215011,
          "regency_id": "3215",
          "name": "TEGALWARU"
        },
        {
          "id": 3215020,
          "regency_id": "3215",
          "name": "CIAMPEL"
        },
        {
          "id": 3215031,
          "regency_id": "3215",
          "name": "TELUKJAMBE TIMUR"
        },
        {
          "id": 3215032,
          "regency_id": "3215",
          "name": "TELUKJAMBE BARAT"
        },
        {
          "id": 3215040,
          "regency_id": "3215",
          "name": "KLARI"
        },
        {
          "id": 3215050,
          "regency_id": "3215",
          "name": "CIKAMPEK"
        },
        {
          "id": 3215051,
          "regency_id": "3215",
          "name": "PURWASARI"
        },
        {
          "id": 3215060,
          "regency_id": "3215",
          "name": "TIRTAMULYA"
        },
        {
          "id": 3215070,
          "regency_id": "3215",
          "name": "JATISARI"
        },
        {
          "id": 3215071,
          "regency_id": "3215",
          "name": "BANYUSARI"
        },
        {
          "id": 3215072,
          "regency_id": "3215",
          "name": "KOTABARU"
        },
        {
          "id": 3215081,
          "regency_id": "3215",
          "name": "CILAMAYA WETAN"
        },
        {
          "id": 3215082,
          "regency_id": "3215",
          "name": "CILAMAYA KULON"
        },
        {
          "id": 3215090,
          "regency_id": "3215",
          "name": "LEMAHABANG"
        },
        {
          "id": 3215100,
          "regency_id": "3215",
          "name": "TALAGASARI"
        },
        {
          "id": 3215111,
          "regency_id": "3215",
          "name": "MAJALAYA"
        },
        {
          "id": 3215112,
          "regency_id": "3215",
          "name": "KARAWANG TIMUR"
        },
        {
          "id": 3215113,
          "regency_id": "3215",
          "name": "KARAWANG BARAT"
        },
        {
          "id": 3215120,
          "regency_id": "3215",
          "name": "RAWAMERTA"
        },
        {
          "id": 3215130,
          "regency_id": "3215",
          "name": "TEMPURAN"
        },
        {
          "id": 3215140,
          "regency_id": "3215",
          "name": "KUTAWALUYA"
        },
        {
          "id": 3215150,
          "regency_id": "3215",
          "name": "RENGASDENGKLOK"
        },
        {
          "id": 3215151,
          "regency_id": "3215",
          "name": "JAYAKERTA"
        },
        {
          "id": 3215160,
          "regency_id": "3215",
          "name": "PEDES"
        },
        {
          "id": 3215161,
          "regency_id": "3215",
          "name": "CILEBAR"
        },
        {
          "id": 3215170,
          "regency_id": "3215",
          "name": "CIBUAYA"
        },
        {
          "id": 3215180,
          "regency_id": "3215",
          "name": "TIRTAJAYA"
        },
        {
          "id": 3215190,
          "regency_id": "3215",
          "name": "BATUJAYA"
        },
        {
          "id": 3215200,
          "regency_id": "3215",
          "name": "PAKISJAYA"
        },
        {
          "id": 3216010,
          "regency_id": "3216",
          "name": "SETU"
        },
        {
          "id": 3216021,
          "regency_id": "3216",
          "name": "SERANG BARU"
        },
        {
          "id": 3216022,
          "regency_id": "3216",
          "name": "CIKARANG PUSAT"
        },
        {
          "id": 3216023,
          "regency_id": "3216",
          "name": "CIKARANG SELATAN"
        },
        {
          "id": 3216030,
          "regency_id": "3216",
          "name": "CIBARUSAH"
        },
        {
          "id": 3216031,
          "regency_id": "3216",
          "name": "BOJONGMANGU"
        },
        {
          "id": 3216041,
          "regency_id": "3216",
          "name": "CIKARANG TIMUR"
        },
        {
          "id": 3216050,
          "regency_id": "3216",
          "name": "KEDUNGWARINGIN"
        },
        {
          "id": 3216061,
          "regency_id": "3216",
          "name": "CIKARANG UTARA"
        },
        {
          "id": 3216062,
          "regency_id": "3216",
          "name": "KARANGBAHAGIA"
        },
        {
          "id": 3216070,
          "regency_id": "3216",
          "name": "CIBITUNG"
        },
        {
          "id": 3216071,
          "regency_id": "3216",
          "name": "CIKARANG BARAT"
        },
        {
          "id": 3216081,
          "regency_id": "3216",
          "name": "TAMBUN SELATAN"
        },
        {
          "id": 3216082,
          "regency_id": "3216",
          "name": "TAMBUN UTARA"
        },
        {
          "id": 3216090,
          "regency_id": "3216",
          "name": "BABELAN"
        },
        {
          "id": 3216100,
          "regency_id": "3216",
          "name": "TARUMAJAYA"
        },
        {
          "id": 3216110,
          "regency_id": "3216",
          "name": "TAMBELANG"
        },
        {
          "id": 3216111,
          "regency_id": "3216",
          "name": "SUKAWANGI"
        },
        {
          "id": 3216120,
          "regency_id": "3216",
          "name": "SUKATANI"
        },
        {
          "id": 3216121,
          "regency_id": "3216",
          "name": "SUKAKARYA"
        },
        {
          "id": 3216130,
          "regency_id": "3216",
          "name": "PEBAYURAN"
        },
        {
          "id": 3216140,
          "regency_id": "3216",
          "name": "CABANGBUNGIN"
        },
        {
          "id": 3216150,
          "regency_id": "3216",
          "name": "MUARA GEMBONG"
        },
        {
          "id": 3217010,
          "regency_id": "3217",
          "name": "RONGGA"
        },
        {
          "id": 3217020,
          "regency_id": "3217",
          "name": "GUNUNGHALU"
        },
        {
          "id": 3217030,
          "regency_id": "3217",
          "name": "SINDANGKERTA"
        },
        {
          "id": 3217040,
          "regency_id": "3217",
          "name": "CILILIN"
        },
        {
          "id": 3217050,
          "regency_id": "3217",
          "name": "CIHAMPELAS"
        },
        {
          "id": 3217060,
          "regency_id": "3217",
          "name": "CIPONGKOR"
        },
        {
          "id": 3217070,
          "regency_id": "3217",
          "name": "BATUJAJAR"
        },
        {
          "id": 3217071,
          "regency_id": "3217",
          "name": "SAGULING"
        },
        {
          "id": 3217080,
          "regency_id": "3217",
          "name": "CIPATAT"
        },
        {
          "id": 3217090,
          "regency_id": "3217",
          "name": "PADALARANG"
        },
        {
          "id": 3217100,
          "regency_id": "3217",
          "name": "NGAMPRAH"
        },
        {
          "id": 3217110,
          "regency_id": "3217",
          "name": "PARONGPONG"
        },
        {
          "id": 3217120,
          "regency_id": "3217",
          "name": "LEMBANG"
        },
        {
          "id": 3217130,
          "regency_id": "3217",
          "name": "CISARUA"
        },
        {
          "id": 3217140,
          "regency_id": "3217",
          "name": "CIKALONG WETAN"
        },
        {
          "id": 3217150,
          "regency_id": "3217",
          "name": "CIPEUNDEUY"
        },
        {
          "id": 3218010,
          "regency_id": "3218",
          "name": "CIMERAK"
        },
        {
          "id": 3218020,
          "regency_id": "3218",
          "name": "CIJULANG"
        },
        {
          "id": 3218030,
          "regency_id": "3218",
          "name": "CIGUGUR"
        },
        {
          "id": 3218040,
          "regency_id": "3218",
          "name": "LANGKAPLANCAR"
        },
        {
          "id": 3218050,
          "regency_id": "3218",
          "name": "PARIGI"
        },
        {
          "id": 3218060,
          "regency_id": "3218",
          "name": "SIDAMULIH"
        },
        {
          "id": 3218070,
          "regency_id": "3218",
          "name": "PANGANDARAN"
        },
        {
          "id": 3218080,
          "regency_id": "3218",
          "name": "KALIPUCANG"
        },
        {
          "id": 3218090,
          "regency_id": "3218",
          "name": "PADAHERANG"
        },
        {
          "id": 3218100,
          "regency_id": "3218",
          "name": "MANGUNJAYA"
        },
        {
          "id": 3271010,
          "regency_id": "3271",
          "name": "BOGOR SELATAN"
        },
        {
          "id": 3271020,
          "regency_id": "3271",
          "name": "BOGOR TIMUR"
        },
        {
          "id": 3271030,
          "regency_id": "3271",
          "name": "BOGOR UTARA"
        },
        {
          "id": 3271040,
          "regency_id": "3271",
          "name": "BOGOR TENGAH"
        },
        {
          "id": 3271050,
          "regency_id": "3271",
          "name": "BOGOR BARAT"
        },
        {
          "id": 3271060,
          "regency_id": "3271",
          "name": "TANAH SEREAL"
        },
        {
          "id": 3272010,
          "regency_id": "3272",
          "name": "BAROS"
        },
        {
          "id": 3272011,
          "regency_id": "3272",
          "name": "LEMBURSITU"
        },
        {
          "id": 3272012,
          "regency_id": "3272",
          "name": "CIBEUREUM"
        },
        {
          "id": 3272020,
          "regency_id": "3272",
          "name": "CITAMIANG"
        },
        {
          "id": 3272030,
          "regency_id": "3272",
          "name": "WARUDOYONG"
        },
        {
          "id": 3272040,
          "regency_id": "3272",
          "name": "GUNUNG PUYUH"
        },
        {
          "id": 3272050,
          "regency_id": "3272",
          "name": "CIKOLE"
        },
        {
          "id": 3273010,
          "regency_id": "3273",
          "name": "BANDUNG KULON"
        },
        {
          "id": 3273020,
          "regency_id": "3273",
          "name": "BABAKAN CIPARAY"
        },
        {
          "id": 3273030,
          "regency_id": "3273",
          "name": "BOJONGLOA KALER"
        },
        {
          "id": 3273040,
          "regency_id": "3273",
          "name": "BOJONGLOA KIDUL"
        },
        {
          "id": 3273050,
          "regency_id": "3273",
          "name": "ASTANAANYAR"
        },
        {
          "id": 3273060,
          "regency_id": "3273",
          "name": "REGOL"
        },
        {
          "id": 3273070,
          "regency_id": "3273",
          "name": "LENGKONG"
        },
        {
          "id": 3273080,
          "regency_id": "3273",
          "name": "BANDUNG KIDUL"
        },
        {
          "id": 3273090,
          "regency_id": "3273",
          "name": "BUAHBATU"
        },
        {
          "id": 3273100,
          "regency_id": "3273",
          "name": "RANCASARI"
        },
        {
          "id": 3273101,
          "regency_id": "3273",
          "name": "GEDEBAGE"
        },
        {
          "id": 3273110,
          "regency_id": "3273",
          "name": "CIBIRU"
        },
        {
          "id": 3273111,
          "regency_id": "3273",
          "name": "PANYILEUKAN"
        },
        {
          "id": 3273120,
          "regency_id": "3273",
          "name": "UJUNG BERUNG"
        },
        {
          "id": 3273121,
          "regency_id": "3273",
          "name": "CINAMBO"
        },
        {
          "id": 3273130,
          "regency_id": "3273",
          "name": "ARCAMANIK"
        },
        {
          "id": 3273141,
          "regency_id": "3273",
          "name": "ANTAPANI"
        },
        {
          "id": 3273142,
          "regency_id": "3273",
          "name": "MANDALAJATI"
        },
        {
          "id": 3273150,
          "regency_id": "3273",
          "name": "KIARACONDONG"
        },
        {
          "id": 3273160,
          "regency_id": "3273",
          "name": "BATUNUNGGAL"
        },
        {
          "id": 3273170,
          "regency_id": "3273",
          "name": "SUMUR BANDUNG"
        },
        {
          "id": 3273180,
          "regency_id": "3273",
          "name": "ANDIR"
        },
        {
          "id": 3273190,
          "regency_id": "3273",
          "name": "CICENDO"
        },
        {
          "id": 3273200,
          "regency_id": "3273",
          "name": "BANDUNG WETAN"
        },
        {
          "id": 3273210,
          "regency_id": "3273",
          "name": "CIBEUNYING KIDUL"
        },
        {
          "id": 3273220,
          "regency_id": "3273",
          "name": "CIBEUNYING KALER"
        },
        {
          "id": 3273230,
          "regency_id": "3273",
          "name": "COBLONG"
        },
        {
          "id": 3273240,
          "regency_id": "3273",
          "name": "SUKAJADI"
        },
        {
          "id": 3273250,
          "regency_id": "3273",
          "name": "SUKASARI"
        },
        {
          "id": 3273260,
          "regency_id": "3273",
          "name": "CIDADAP"
        },
        {
          "id": 3274010,
          "regency_id": "3274",
          "name": "HARJAMUKTI"
        },
        {
          "id": 3274020,
          "regency_id": "3274",
          "name": "LEMAHWUNGKUK"
        },
        {
          "id": 3274030,
          "regency_id": "3274",
          "name": "PEKALIPAN"
        },
        {
          "id": 3274040,
          "regency_id": "3274",
          "name": "KESAMBI"
        },
        {
          "id": 3274050,
          "regency_id": "3274",
          "name": "KEJAKSAN"
        },
        {
          "id": 3275010,
          "regency_id": "3275",
          "name": "PONDOKGEDE"
        },
        {
          "id": 3275011,
          "regency_id": "3275",
          "name": "JATISAMPURNA"
        },
        {
          "id": 3275012,
          "regency_id": "3275",
          "name": "PONDOKMELATI"
        },
        {
          "id": 3275020,
          "regency_id": "3275",
          "name": "JATIASIH"
        },
        {
          "id": 3275030,
          "regency_id": "3275",
          "name": "BANTARGEBANG"
        },
        {
          "id": 3275031,
          "regency_id": "3275",
          "name": "MUSTIKAJAYA"
        },
        {
          "id": 3275040,
          "regency_id": "3275",
          "name": "BEKASI TIMUR"
        },
        {
          "id": 3275041,
          "regency_id": "3275",
          "name": "RAWALUMBU"
        },
        {
          "id": 3275050,
          "regency_id": "3275",
          "name": "BEKASI SELATAN"
        },
        {
          "id": 3275060,
          "regency_id": "3275",
          "name": "BEKASI BARAT"
        },
        {
          "id": 3275061,
          "regency_id": "3275",
          "name": "MEDAN SATRIA"
        },
        {
          "id": 3275070,
          "regency_id": "3275",
          "name": "BEKASI UTARA"
        },
        {
          "id": 3276010,
          "regency_id": "3276",
          "name": "SAWANGAN"
        },
        {
          "id": 3276011,
          "regency_id": "3276",
          "name": "BOJONGSARI"
        },
        {
          "id": 3276020,
          "regency_id": "3276",
          "name": "PANCORAN MAS"
        },
        {
          "id": 3276021,
          "regency_id": "3276",
          "name": "CIPAYUNG"
        },
        {
          "id": 3276030,
          "regency_id": "3276",
          "name": "SUKMA JAYA"
        },
        {
          "id": 3276031,
          "regency_id": "3276",
          "name": "CILODONG"
        },
        {
          "id": 3276040,
          "regency_id": "3276",
          "name": "CIMANGGIS"
        },
        {
          "id": 3276041,
          "regency_id": "3276",
          "name": "TAPOS"
        },
        {
          "id": 3276050,
          "regency_id": "3276",
          "name": "BEJI"
        },
        {
          "id": 3276060,
          "regency_id": "3276",
          "name": "LIMO"
        },
        {
          "id": 3276061,
          "regency_id": "3276",
          "name": "CINERE"
        },
        {
          "id": 3277010,
          "regency_id": "3277",
          "name": "CIMAHI SELATAN"
        },
        {
          "id": 3277020,
          "regency_id": "3277",
          "name": "CIMAHI TENGAH"
        },
        {
          "id": 3277030,
          "regency_id": "3277",
          "name": "CIMAHI UTARA"
        },
        {
          "id": 3278010,
          "regency_id": "3278",
          "name": "KAWALU"
        },
        {
          "id": 3278020,
          "regency_id": "3278",
          "name": "TAMANSARI"
        },
        {
          "id": 3278030,
          "regency_id": "3278",
          "name": "CIBEUREUM"
        },
        {
          "id": 3278031,
          "regency_id": "3278",
          "name": "PURBARATU"
        },
        {
          "id": 3278040,
          "regency_id": "3278",
          "name": "TAWANG"
        },
        {
          "id": 3278050,
          "regency_id": "3278",
          "name": "CIHIDEUNG"
        },
        {
          "id": 3278060,
          "regency_id": "3278",
          "name": "MANGKUBUMI"
        },
        {
          "id": 3278070,
          "regency_id": "3278",
          "name": "INDIHIANG"
        },
        {
          "id": 3278071,
          "regency_id": "3278",
          "name": "BUNGURSARI"
        },
        {
          "id": 3278080,
          "regency_id": "3278",
          "name": "CIPEDES"
        },
        {
          "id": 3279010,
          "regency_id": "3279",
          "name": "BANJAR"
        },
        {
          "id": 3279020,
          "regency_id": "3279",
          "name": "PURWAHARJA"
        },
        {
          "id": 3279030,
          "regency_id": "3279",
          "name": "PATARUMAN"
        },
        {
          "id": 3279040,
          "regency_id": "3279",
          "name": "LANGENSARI"
        },
        {
          "id": 3601010,
          "regency_id": "3601",
          "name": "SUMUR"
        },
        {
          "id": 3601020,
          "regency_id": "3601",
          "name": "CIMANGGU"
        },
        {
          "id": 3601030,
          "regency_id": "3601",
          "name": "CIBALIUNG"
        },
        {
          "id": 3601031,
          "regency_id": "3601",
          "name": "CIBITUNG"
        },
        {
          "id": 3601040,
          "regency_id": "3601",
          "name": "CIKEUSIK"
        },
        {
          "id": 3601050,
          "regency_id": "3601",
          "name": "CIGEULIS"
        },
        {
          "id": 3601060,
          "regency_id": "3601",
          "name": "PANIMBANG"
        },
        {
          "id": 3601061,
          "regency_id": "3601",
          "name": "SOBANG"
        },
        {
          "id": 3601070,
          "regency_id": "3601",
          "name": "MUNJUL"
        },
        {
          "id": 3601071,
          "regency_id": "3601",
          "name": "ANGSANA"
        },
        {
          "id": 3601072,
          "regency_id": "3601",
          "name": "SINDANGRESMI"
        },
        {
          "id": 3601080,
          "regency_id": "3601",
          "name": "PICUNG"
        },
        {
          "id": 3601090,
          "regency_id": "3601",
          "name": "BOJONG"
        },
        {
          "id": 3601100,
          "regency_id": "3601",
          "name": "SAKETI"
        },
        {
          "id": 3601101,
          "regency_id": "3601",
          "name": "CISATA"
        },
        {
          "id": 3601110,
          "regency_id": "3601",
          "name": "PAGELARAN"
        },
        {
          "id": 3601111,
          "regency_id": "3601",
          "name": "PATIA"
        },
        {
          "id": 3601112,
          "regency_id": "3601",
          "name": "SUKARESMI"
        },
        {
          "id": 3601120,
          "regency_id": "3601",
          "name": "LABUAN"
        },
        {
          "id": 3601121,
          "regency_id": "3601",
          "name": "CARITA"
        },
        {
          "id": 3601130,
          "regency_id": "3601",
          "name": "JIPUT"
        },
        {
          "id": 3601131,
          "regency_id": "3601",
          "name": "CIKEDAL"
        },
        {
          "id": 3601140,
          "regency_id": "3601",
          "name": "MENES"
        },
        {
          "id": 3601141,
          "regency_id": "3601",
          "name": "PULOSARI"
        },
        {
          "id": 3601150,
          "regency_id": "3601",
          "name": "MANDALAWANGI"
        },
        {
          "id": 3601160,
          "regency_id": "3601",
          "name": "CIMANUK"
        },
        {
          "id": 3601161,
          "regency_id": "3601",
          "name": "CIPEUCANG"
        },
        {
          "id": 3601170,
          "regency_id": "3601",
          "name": "BANJAR"
        },
        {
          "id": 3601171,
          "regency_id": "3601",
          "name": "KADUHEJO"
        },
        {
          "id": 3601172,
          "regency_id": "3601",
          "name": "MEKARJAYA"
        },
        {
          "id": 3601180,
          "regency_id": "3601",
          "name": "PANDEGLANG"
        },
        {
          "id": 3601181,
          "regency_id": "3601",
          "name": "MAJASARI"
        },
        {
          "id": 3601190,
          "regency_id": "3601",
          "name": "CADASARI"
        },
        {
          "id": 3601191,
          "regency_id": "3601",
          "name": "KARANGTANJUNG"
        },
        {
          "id": 3601192,
          "regency_id": "3601",
          "name": "KORONCONG"
        },
        {
          "id": 3602010,
          "regency_id": "3602",
          "name": "MALINGPING"
        },
        {
          "id": 3602011,
          "regency_id": "3602",
          "name": "WANASALAM"
        },
        {
          "id": 3602020,
          "regency_id": "3602",
          "name": "PANGGARANGAN"
        },
        {
          "id": 3602021,
          "regency_id": "3602",
          "name": "CIHARA"
        },
        {
          "id": 3602030,
          "regency_id": "3602",
          "name": "BAYAH"
        },
        {
          "id": 3602031,
          "regency_id": "3602",
          "name": "CILOGRANG"
        },
        {
          "id": 3602040,
          "regency_id": "3602",
          "name": "CIBEBER"
        },
        {
          "id": 3602050,
          "regency_id": "3602",
          "name": "CIJAKU"
        },
        {
          "id": 3602051,
          "regency_id": "3602",
          "name": "CIGEMBLONG"
        },
        {
          "id": 3602060,
          "regency_id": "3602",
          "name": "BANJARSARI"
        },
        {
          "id": 3602070,
          "regency_id": "3602",
          "name": "CILELES"
        },
        {
          "id": 3602080,
          "regency_id": "3602",
          "name": "GUNUNG KENCANA"
        },
        {
          "id": 3602090,
          "regency_id": "3602",
          "name": "BOJONGMANIK"
        },
        {
          "id": 3602091,
          "regency_id": "3602",
          "name": "CIRINTEN"
        },
        {
          "id": 3602100,
          "regency_id": "3602",
          "name": "LEUWIDAMAR"
        },
        {
          "id": 3602110,
          "regency_id": "3602",
          "name": "MUNCANG"
        },
        {
          "id": 3602111,
          "regency_id": "3602",
          "name": "SOBANG"
        },
        {
          "id": 3602120,
          "regency_id": "3602",
          "name": "CIPANAS"
        },
        {
          "id": 3602121,
          "regency_id": "3602",
          "name": "LEBAKGEDONG"
        },
        {
          "id": 3602130,
          "regency_id": "3602",
          "name": "SAJIRA"
        },
        {
          "id": 3602140,
          "regency_id": "3602",
          "name": "CIMARGA"
        },
        {
          "id": 3602150,
          "regency_id": "3602",
          "name": "CIKULUR"
        },
        {
          "id": 3602160,
          "regency_id": "3602",
          "name": "WARUNGGUNUNG"
        },
        {
          "id": 3602170,
          "regency_id": "3602",
          "name": "CIBADAK"
        },
        {
          "id": 3602180,
          "regency_id": "3602",
          "name": "RANGKASBITUNG"
        },
        {
          "id": 3602181,
          "regency_id": "3602",
          "name": "KALANGANYAR"
        },
        {
          "id": 3602190,
          "regency_id": "3602",
          "name": "MAJA"
        },
        {
          "id": 3602191,
          "regency_id": "3602",
          "name": "CURUGBITUNG"
        },
        {
          "id": 3603010,
          "regency_id": "3603",
          "name": "CISOKA"
        },
        {
          "id": 3603011,
          "regency_id": "3603",
          "name": "SOLEAR"
        },
        {
          "id": 3603020,
          "regency_id": "3603",
          "name": "TIGARAKSA"
        },
        {
          "id": 3603021,
          "regency_id": "3603",
          "name": "JAMBE"
        },
        {
          "id": 3603030,
          "regency_id": "3603",
          "name": "CIKUPA"
        },
        {
          "id": 3603040,
          "regency_id": "3603",
          "name": "PANONGAN"
        },
        {
          "id": 3603050,
          "regency_id": "3603",
          "name": "CURUG"
        },
        {
          "id": 3603051,
          "regency_id": "3603",
          "name": "KELAPA DUA"
        },
        {
          "id": 3603060,
          "regency_id": "3603",
          "name": "LEGOK"
        },
        {
          "id": 3603070,
          "regency_id": "3603",
          "name": "PAGEDANGAN"
        },
        {
          "id": 3603081,
          "regency_id": "3603",
          "name": "CISAUK"
        },
        {
          "id": 3603120,
          "regency_id": "3603",
          "name": "PASARKEMIS"
        },
        {
          "id": 3603121,
          "regency_id": "3603",
          "name": "SINDANG JAYA"
        },
        {
          "id": 3603130,
          "regency_id": "3603",
          "name": "BALARAJA"
        },
        {
          "id": 3603131,
          "regency_id": "3603",
          "name": "JAYANTI"
        },
        {
          "id": 3603132,
          "regency_id": "3603",
          "name": "SUKAMULYA"
        },
        {
          "id": 3603140,
          "regency_id": "3603",
          "name": "KRESEK"
        },
        {
          "id": 3603141,
          "regency_id": "3603",
          "name": "GUNUNG KALER"
        },
        {
          "id": 3603150,
          "regency_id": "3603",
          "name": "KRONJO"
        },
        {
          "id": 3603151,
          "regency_id": "3603",
          "name": "MEKAR BARU"
        },
        {
          "id": 3603160,
          "regency_id": "3603",
          "name": "MAUK"
        },
        {
          "id": 3603161,
          "regency_id": "3603",
          "name": "KEMIRI"
        },
        {
          "id": 3603162,
          "regency_id": "3603",
          "name": "SUKADIRI"
        },
        {
          "id": 3603170,
          "regency_id": "3603",
          "name": "RAJEG"
        },
        {
          "id": 3603180,
          "regency_id": "3603",
          "name": "SEPATAN"
        },
        {
          "id": 3603181,
          "regency_id": "3603",
          "name": "SEPATAN TIMUR"
        },
        {
          "id": 3603190,
          "regency_id": "3603",
          "name": "PAKUHAJI"
        },
        {
          "id": 3603200,
          "regency_id": "3603",
          "name": "TELUKNAGA"
        },
        {
          "id": 3603210,
          "regency_id": "3603",
          "name": "KOSAMBI"
        },
        {
          "id": 3604010,
          "regency_id": "3604",
          "name": "CINANGKA"
        },
        {
          "id": 3604020,
          "regency_id": "3604",
          "name": "PADARINCANG"
        },
        {
          "id": 3604030,
          "regency_id": "3604",
          "name": "CIOMAS"
        },
        {
          "id": 3604040,
          "regency_id": "3604",
          "name": "PABUARAN"
        },
        {
          "id": 3604041,
          "regency_id": "3604",
          "name": "GUNUNG SARI"
        },
        {
          "id": 3604050,
          "regency_id": "3604",
          "name": "BAROS"
        },
        {
          "id": 3604060,
          "regency_id": "3604",
          "name": "PETIR"
        },
        {
          "id": 3604061,
          "regency_id": "3604",
          "name": "TUNJUNG TEJA"
        },
        {
          "id": 3604080,
          "regency_id": "3604",
          "name": "CIKEUSAL"
        },
        {
          "id": 3604090,
          "regency_id": "3604",
          "name": "PAMARAYAN"
        },
        {
          "id": 3604091,
          "regency_id": "3604",
          "name": "BANDUNG"
        },
        {
          "id": 3604100,
          "regency_id": "3604",
          "name": "JAWILAN"
        },
        {
          "id": 3604110,
          "regency_id": "3604",
          "name": "KOPO"
        },
        {
          "id": 3604120,
          "regency_id": "3604",
          "name": "CIKANDE"
        },
        {
          "id": 3604121,
          "regency_id": "3604",
          "name": "KIBIN"
        },
        {
          "id": 3604130,
          "regency_id": "3604",
          "name": "KRAGILAN"
        },
        {
          "id": 3604180,
          "regency_id": "3604",
          "name": "WARINGINKURUNG"
        },
        {
          "id": 3604190,
          "regency_id": "3604",
          "name": "MANCAK"
        },
        {
          "id": 3604200,
          "regency_id": "3604",
          "name": "ANYAR"
        },
        {
          "id": 3604210,
          "regency_id": "3604",
          "name": "BOJONEGARA"
        },
        {
          "id": 3604211,
          "regency_id": "3604",
          "name": "PULO AMPEL"
        },
        {
          "id": 3604220,
          "regency_id": "3604",
          "name": "KRAMATWATU"
        },
        {
          "id": 3604240,
          "regency_id": "3604",
          "name": "CIRUAS"
        },
        {
          "id": 3604250,
          "regency_id": "3604",
          "name": "PONTANG"
        },
        {
          "id": 3604251,
          "regency_id": "3604",
          "name": "LEBAK WANGI"
        },
        {
          "id": 3604260,
          "regency_id": "3604",
          "name": "CARENANG"
        },
        {
          "id": 3604261,
          "regency_id": "3604",
          "name": "BINUANG"
        },
        {
          "id": 3604270,
          "regency_id": "3604",
          "name": "TIRTAYASA"
        },
        {
          "id": 3604271,
          "regency_id": "3604",
          "name": "TANARA"
        },
        {
          "id": 3671010,
          "regency_id": "3671",
          "name": "CILEDUG"
        },
        {
          "id": 3671011,
          "regency_id": "3671",
          "name": "LARANGAN"
        },
        {
          "id": 3671012,
          "regency_id": "3671",
          "name": "KARANG TENGAH"
        },
        {
          "id": 3671020,
          "regency_id": "3671",
          "name": "CIPONDOH"
        },
        {
          "id": 3671021,
          "regency_id": "3671",
          "name": "PINANG"
        },
        {
          "id": 3671030,
          "regency_id": "3671",
          "name": "TANGERANG"
        },
        {
          "id": 3671031,
          "regency_id": "3671",
          "name": "KARAWACI"
        },
        {
          "id": 3671040,
          "regency_id": "3671",
          "name": "JATI UWUNG"
        },
        {
          "id": 3671041,
          "regency_id": "3671",
          "name": "CIBODAS"
        },
        {
          "id": 3671042,
          "regency_id": "3671",
          "name": "PERIUK"
        },
        {
          "id": 3671050,
          "regency_id": "3671",
          "name": "BATUCEPER"
        },
        {
          "id": 3671051,
          "regency_id": "3671",
          "name": "NEGLASARI"
        },
        {
          "id": 3671060,
          "regency_id": "3671",
          "name": "BENDA"
        },
        {
          "id": 3672010,
          "regency_id": "3672",
          "name": "CIWANDAN"
        },
        {
          "id": 3672011,
          "regency_id": "3672",
          "name": "CITANGKIL"
        },
        {
          "id": 3672020,
          "regency_id": "3672",
          "name": "PULOMERAK"
        },
        {
          "id": 3672021,
          "regency_id": "3672",
          "name": "PURWAKARTA"
        },
        {
          "id": 3672022,
          "regency_id": "3672",
          "name": "GROGOL"
        },
        {
          "id": 3672030,
          "regency_id": "3672",
          "name": "CILEGON"
        },
        {
          "id": 3672031,
          "regency_id": "3672",
          "name": "JOMBANG"
        },
        {
          "id": 3672040,
          "regency_id": "3672",
          "name": "CIBEBER"
        },
        {
          "id": 3673010,
          "regency_id": "3673",
          "name": "CURUG"
        },
        {
          "id": 3673020,
          "regency_id": "3673",
          "name": "WALANTAKA"
        },
        {
          "id": 3673030,
          "regency_id": "3673",
          "name": "CIPOCOK JAYA"
        },
        {
          "id": 3673040,
          "regency_id": "3673",
          "name": "SERANG"
        },
        {
          "id": 3673050,
          "regency_id": "3673",
          "name": "TAKTAKAN"
        },
        {
          "id": 3673060,
          "regency_id": "3673",
          "name": "KASEMEN"
        },
        {
          "id": 3674010,
          "regency_id": "3674",
          "name": "SETU"
        },
        {
          "id": 3674020,
          "regency_id": "3674",
          "name": "SERPONG"
        },
        {
          "id": 3674030,
          "regency_id": "3674",
          "name": "PAMULANG"
        },
        {
          "id": 3674040,
          "regency_id": "3674",
          "name": "CIPUTAT"
        },
        {
          "id": 3674050,
          "regency_id": "3674",
          "name": "CIPUTAT TIMUR"
        },
        {
          "id": 3674060,
          "regency_id": "3674",
          "name": "PONDOK AREN"
        },
        {
          "id": 3674070,
          "regency_id": "3674",
          "name": "SERPONG UTARA"
        }
      ]
    let villages = [
        {
          "id": "3674070008",
          "district_id": "3201210",
          "name": "CIKARET"
        },
        {
          "id": "3171010005",
          "district_id": "3171010",
          "name": "LENTENG AGUNG"
        },
        {
          "id": "3171010006",
          "district_id": "3171010",
          "name": "TANJUNG BARAT"
        },
        {
          "id": "3171020001",
          "district_id": "3171020",
          "name": "CILANDAK TIMUR"
        },
        {
          "id": "3171020002",
          "district_id": "3171020",
          "name": "RAGUNAN"
        },
        {
          "id": "3171020003",
          "district_id": "3171020",
          "name": "KEBAGUSAN"
        },
        {
          "id": "3171020004",
          "district_id": "3171020",
          "name": "PASAR MINGGU"
        },
        {
          "id": "3171020005",
          "district_id": "3171020",
          "name": "JATI PADANG"
        },
        {
          "id": "3171020006",
          "district_id": "3171020",
          "name": "PEJATEN BARAT"
        },
        {
          "id": "3171020007",
          "district_id": "3171020",
          "name": "PEJATEN TIMUR"
        },
        {
          "id": "3171030001",
          "district_id": "3171030",
          "name": "LEBAK BULUS"
        },
        {
          "id": "3171030002",
          "district_id": "3171030",
          "name": "PONDOK LABU"
        },
        {
          "id": "3171030003",
          "district_id": "3171030",
          "name": "CILANDAK BARAT"
        },
        {
          "id": "3171030004",
          "district_id": "3171030",
          "name": "GANDARIA SELATAN"
        },
        {
          "id": "3171030005",
          "district_id": "3171030",
          "name": "CIPETE SELATAN"
        },
        {
          "id": "3171040001",
          "district_id": "3171040",
          "name": "BINTARO"
        },
        {
          "id": "3171040002",
          "district_id": "3171040",
          "name": "PESANGGRAHAN"
        },
        {
          "id": "3171040003",
          "district_id": "3171040",
          "name": "ULUJAMI"
        },
        {
          "id": "3171040004",
          "district_id": "3171040",
          "name": "PETUKANGAN SELATAN"
        },
        {
          "id": "3171040005",
          "district_id": "3171040",
          "name": "PETUKANGAN UTARA"
        },
        {
          "id": "3171050001",
          "district_id": "3171050",
          "name": "PONDOK PINANG"
        },
        {
          "id": "3171050002",
          "district_id": "3171050",
          "name": "KEBAYORAN LAMA SELATAN"
        },
        {
          "id": "3171050003",
          "district_id": "3171050",
          "name": "KEBAYORAN LAMA UTARA"
        },
        {
          "id": "3171050004",
          "district_id": "3171050",
          "name": "CIPULIR"
        },
        {
          "id": "3171050005",
          "district_id": "3171050",
          "name": "GROGOL SELATAN"
        },
        {
          "id": "3171050006",
          "district_id": "3171050",
          "name": "GROGOL UTARA"
        },
        {
          "id": "3171060001",
          "district_id": "3171060",
          "name": "GANDARIA UTARA"
        },
        {
          "id": "3171060002",
          "district_id": "3171060",
          "name": "CIPETE UTARA"
        },
        {
          "id": "3171060003",
          "district_id": "3171060",
          "name": "PULO"
        },
        {
          "id": "3171060004",
          "district_id": "3171060",
          "name": "PETOGOGAN"
        },
        {
          "id": "3171060005",
          "district_id": "3171060",
          "name": "MELAWAI"
        },
        {
          "id": "3171060006",
          "district_id": "3171060",
          "name": "KRAMAT PELA"
        },
        {
          "id": "3171060008",
          "district_id": "3171060",
          "name": "SELONG"
        },
        {
          "id": "3171060009",
          "district_id": "3171060",
          "name": "RAWA BARAT"
        },
        {
          "id": "3171060010",
          "district_id": "3171060",
          "name": "SENAYAN"
        },
        {
          "id": "3171070001",
          "district_id": "3171070",
          "name": "BANGKA"
        },
        {
          "id": "3171070002",
          "district_id": "3171070",
          "name": "PELA MAMPANG"
        },
        {
          "id": "3171070003",
          "district_id": "3171070",
          "name": "TEGAL PARANG"
        },
        {
          "id": "3171070004",
          "district_id": "3171070",
          "name": "MAMPANG PRAPATAN"
        },
        {
          "id": "3171070005",
          "district_id": "3171070",
          "name": "KUNINGAN BARAT"
        },
        {
          "id": "3171080001",
          "district_id": "3171080",
          "name": "KALIBATA"
        },
        {
          "id": "3171080002",
          "district_id": "3171080",
          "name": "RAWAJATI"
        },
        {
          "id": "3171080003",
          "district_id": "3171080",
          "name": "DUREN TIGA"
        },
        {
          "id": "3171080004",
          "district_id": "3171080",
          "name": "PANCORAN"
        },
        {
          "id": "3171080005",
          "district_id": "3171080",
          "name": "PENGADEGAN"
        },
        {
          "id": "3171080006",
          "district_id": "3171080",
          "name": "CIKOKO"
        },
        {
          "id": "3171090001",
          "district_id": "3171090",
          "name": "MENTENG DALAM"
        },
        {
          "id": "3171090002",
          "district_id": "3171090",
          "name": "TEBET BARAT"
        },
        {
          "id": "3171090003",
          "district_id": "3171090",
          "name": "TEBET TIMUR"
        },
        {
          "id": "3171090004",
          "district_id": "3171090",
          "name": "KEBON BARU"
        },
        {
          "id": "3171090005",
          "district_id": "3171090",
          "name": "BUKIT DURI"
        },
        {
          "id": "3171090006",
          "district_id": "3171090",
          "name": "MANGGARAI SELATAN"
        },
        {
          "id": "3171090007",
          "district_id": "3171090",
          "name": "MANGGARAI"
        },
        {
          "id": "3171100001",
          "district_id": "3171100",
          "name": "KARET SEMANGGI"
        },
        {
          "id": "3171100002",
          "district_id": "3171100",
          "name": "KUNINGAN TIMUR"
        },
        {
          "id": "3171100003",
          "district_id": "3171100",
          "name": "KARET KUNINGAN"
        },
        {
          "id": "3171100004",
          "district_id": "3171100",
          "name": "KARET"
        },
        {
          "id": "3171100005",
          "district_id": "3171100",
          "name": "MENTENG ATAS"
        },
        {
          "id": "3171100006",
          "district_id": "3171100",
          "name": "PASAR MANGGIS"
        },
        {
          "id": "3171100008",
          "district_id": "3171100",
          "name": "SETIA BUDI"
        },
        {
          "id": "3172010001",
          "district_id": "3172010",
          "name": "PEKAYON"
        },
        {
          "id": "3172010002",
          "district_id": "3172010",
          "name": "KALISARI"
        },
        {
          "id": "3172010003",
          "district_id": "3172010",
          "name": "BARU"
        },
        {
          "id": "3172010004",
          "district_id": "3172010",
          "name": "CIJANTUNG"
        },
        {
          "id": "3172010005",
          "district_id": "3172010",
          "name": "GEDONG"
        },
        {
          "id": "3172020001",
          "district_id": "3172020",
          "name": "CIBUBUR"
        },
        {
          "id": "3172020002",
          "district_id": "3172020",
          "name": "KELAPA DUA WETAN"
        },
        {
          "id": "3172020003",
          "district_id": "3172020",
          "name": "CIRACAS"
        },
        {
          "id": "3172020004",
          "district_id": "3172020",
          "name": "SUSUKAN"
        },
        {
          "id": "3172020005",
          "district_id": "3172020",
          "name": "RAMBUTAN"
        },
        {
          "id": "3172030001",
          "district_id": "3172030",
          "name": "PONDOK RANGGON"
        },
        {
          "id": "3172030002",
          "district_id": "3172030",
          "name": "CILANGKAP"
        },
        {
          "id": "3172030004",
          "district_id": "3172030",
          "name": "CIPAYUNG"
        },
        {
          "id": "3172030006",
          "district_id": "3172030",
          "name": "BAMBU APUS"
        },
        {
          "id": "3172030008",
          "district_id": "3172030",
          "name": "LUBANG BUAYA"
        },
        {
          "id": "3172040001",
          "district_id": "3172040",
          "name": "PINANG RANTI"
        },
        {
          "id": "3172040002",
          "district_id": "3172040",
          "name": "MAKASAR"
        },
        {
          "id": "3172040003",
          "district_id": "3172040",
          "name": "KEBON PALA"
        },
        {
          "id": "3172040004",
          "district_id": "3172040",
          "name": "HALIM PERDANA KUSUMAH"
        },
        {
          "id": "3172040005",
          "district_id": "3172040",
          "name": "CIPINANG MELAYU"
        },
        {
          "id": "3172050001",
          "district_id": "3172050",
          "name": "BALE KAMBANG"
        },
        {
          "id": "3172050002",
          "district_id": "3172050",
          "name": "BATU AMPAR"
        },
        {
          "id": "3172050003",
          "district_id": "3172050",
          "name": "KAMPUNG TENGAH"
        },
        {
          "id": "3172050005",
          "district_id": "3172050",
          "name": "KRAMAT JATI"
        },
        {
          "id": "3172050006",
          "district_id": "3172050",
          "name": "CILILITAN"
        },
        {
          "id": "3172050007",
          "district_id": "3172050",
          "name": "CAWANG"
        },
        {
          "id": "3172060001",
          "district_id": "3172060",
          "name": "BIDARA CINA"
        },
        {
          "id": "3172060002",
          "district_id": "3172060",
          "name": "CIPINANG CEMPEDAK"
        },
        {
          "id": "3172060003",
          "district_id": "3172060",
          "name": "CIPINANG BESAR SELATAN"
        },
        {
          "id": "3172060004",
          "district_id": "3172060",
          "name": "CIPINANG MUARA"
        },
        {
          "id": "3172060005",
          "district_id": "3172060",
          "name": "CIPINANG BESAR UTARA"
        },
        {
          "id": "3172060006",
          "district_id": "3172060",
          "name": "RAWA BUNGA"
        },
        {
          "id": "3172060007",
          "district_id": "3172060",
          "name": "BALI MESTER"
        },
        {
          "id": "3172060008",
          "district_id": "3172060",
          "name": "KAMPUNG MELAYU"
        },
        {
          "id": "3172070001",
          "district_id": "3172070",
          "name": "PONDOK BAMBU"
        },
        {
          "id": "3172070002",
          "district_id": "3172070",
          "name": "DUREN SAWIT"
        },
        {
          "id": "3172070003",
          "district_id": "3172070",
          "name": "PONDOK KELAPA"
        },
        {
          "id": "3172070004",
          "district_id": "3172070",
          "name": "PONDOK KOPI"
        },
        {
          "id": "3172070005",
          "district_id": "3172070",
          "name": "MALAKA JAYA"
        },
        {
          "id": "3172070006",
          "district_id": "3172070",
          "name": "MALAKA SARI"
        },
        {
          "id": "3172080001",
          "district_id": "3172080",
          "name": "JATINEGARA"
        },
        {
          "id": "3172080002",
          "district_id": "3172080",
          "name": "PENGGILINGAN"
        },
        {
          "id": "3172080003",
          "district_id": "3172080",
          "name": "PULO GEBANG"
        },
        {
          "id": "3172080005",
          "district_id": "3172080",
          "name": "CAKUNG TIMUR"
        },
        {
          "id": "3172080006",
          "district_id": "3172080",
          "name": "CAKUNG BARAT"
        },
        {
          "id": "3172080007",
          "district_id": "3172080",
          "name": "RAWA TERATE"
        },
        {
          "id": "3172090001",
          "district_id": "3172090",
          "name": "PISANGAN TIMUR"
        },
        {
          "id": "3172090002",
          "district_id": "3172090",
          "name": "CIPINANG"
        },
        {
          "id": "3172090003",
          "district_id": "3172090",
          "name": "JATINEGARA KAUM"
        },
        {
          "id": "3172090004",
          "district_id": "3172090",
          "name": "JATI"
        },
        {
          "id": "3172090005",
          "district_id": "3172090",
          "name": "RAWAMANGUN"
        },
        {
          "id": "3172090006",
          "district_id": "3172090",
          "name": "KAYU PUTIH"
        },
        {
          "id": "3172090007",
          "district_id": "3172090",
          "name": "PULO GADUNG"
        },
        {
          "id": "3172100001",
          "district_id": "3172100",
          "name": "KEBON MANGGIS"
        },
        {
          "id": "3172100002",
          "district_id": "3172100",
          "name": "PAL MERIEM"
        },
        {
          "id": "3172100003",
          "district_id": "3172100",
          "name": "PISANGAN BARU"
        },
        {
          "id": "3172100004",
          "district_id": "3172100",
          "name": "KAYU MANIS"
        },
        {
          "id": "3172100005",
          "district_id": "3172100",
          "name": "UTAN KAYU SELATAN"
        },
        {
          "id": "3172100006",
          "district_id": "3172100",
          "name": "UTAN KAYU UTARA"
        },
        {
          "id": "3173010001",
          "district_id": "3173010",
          "name": "GELORA"
        },
        {
          "id": "3173010002",
          "district_id": "3173010",
          "name": "BENDUNGAN HILIR"
        },
        {
          "id": "3173010003",
          "district_id": "3173010",
          "name": "KARET TENGSIN"
        },
        {
          "id": "3173010004",
          "district_id": "3173010",
          "name": "KEBON MELATI"
        },
        {
          "id": "3173010005",
          "district_id": "3173010",
          "name": "PETAMBURAN"
        },
        {
          "id": "3173010006",
          "district_id": "3173010",
          "name": "KEBON KACANG"
        },
        {
          "id": "3173010007",
          "district_id": "3173010",
          "name": "KAMPUNG BALI"
        },
        {
          "id": "3173020002",
          "district_id": "3173020",
          "name": "PEGANGSAAN"
        },
        {
          "id": "3173020003",
          "district_id": "3173020",
          "name": "CIKINI"
        },
        {
          "id": "3173020004",
          "district_id": "3173020",
          "name": "GONDANGDIA"
        },
        {
          "id": "3173020005",
          "district_id": "3173020",
          "name": "KEBON SIRIH"
        },
        {
          "id": "3173030001",
          "district_id": "3173030",
          "name": "KENARI"
        },
        {
          "id": "3173030002",
          "district_id": "3173030",
          "name": "PASEBAN"
        },
        {
          "id": "3173030003",
          "district_id": "3173030",
          "name": "KRAMAT"
        },
        {
          "id": "3173030004",
          "district_id": "3173030",
          "name": "KWITANG"
        },
        {
          "id": "3173040001",
          "district_id": "3173040",
          "name": "JOHAR BARU"
        },
        {
          "id": "3173040002",
          "district_id": "3173040",
          "name": "KAMPUNG RAWA"
        },
        {
          "id": "3173040003",
          "district_id": "3173040",
          "name": "TANAH TINGGI"
        },
        {
          "id": "3173040004",
          "district_id": "3173040",
          "name": "GALUR"
        },
        {
          "id": "3173050001",
          "district_id": "3173050",
          "name": "RAWA SARI"
        },
        {
          "id": "3173050002",
          "district_id": "3173050",
          "name": "CEMPAKA PUTIH TIMUR"
        },
        {
          "id": "3173050003",
          "district_id": "3173050",
          "name": "CEMPAKA PUTIH BARAT"
        },
        {
          "id": "3173060001",
          "district_id": "3173060",
          "name": "HARAPAN MULYA"
        },
        {
          "id": "3173060002",
          "district_id": "3173060",
          "name": "CEMPAKA BARU"
        },
        {
          "id": "3173060003",
          "district_id": "3173060",
          "name": "SUMUR BATU"
        },
        {
          "id": "3173060004",
          "district_id": "3173060",
          "name": "SERDANG"
        },
        {
          "id": "3173060005",
          "district_id": "3173060",
          "name": "UTAN PANJANG"
        },
        {
          "id": "3173060006",
          "district_id": "3173060",
          "name": "KEBON KOSONG"
        },
        {
          "id": "3173060007",
          "district_id": "3173060",
          "name": "KEMAYORAN"
        },
        {
          "id": "3173060008",
          "district_id": "3173060",
          "name": "GUNUNG SAHARI SELATAN"
        },
        {
          "id": "3173070001",
          "district_id": "3173070",
          "name": "PASAR BARU"
        },
        {
          "id": "3173070002",
          "district_id": "3173070",
          "name": "GUNUNG SAHARI UTARA"
        },
        {
          "id": "3173070003",
          "district_id": "3173070",
          "name": "KARTINI"
        },
        {
          "id": "3173070004",
          "district_id": "3173070",
          "name": "KARANG ANYAR"
        },
        {
          "id": "3173070005",
          "district_id": "3173070",
          "name": "MANGGA DUA SELATAN"
        },
        {
          "id": "3173080001",
          "district_id": "3173080",
          "name": "CIDENG"
        },
        {
          "id": "3173080002",
          "district_id": "3173080",
          "name": "PETOJO SELATAN"
        },
        {
          "id": "3173080003",
          "district_id": "3173080",
          "name": "GAMBIR"
        },
        {
          "id": "3173080004",
          "district_id": "3173080",
          "name": "KEBON KELAPA"
        },
        {
          "id": "3173080005",
          "district_id": "3173080",
          "name": "PETOJO UTARA"
        },
        {
          "id": "3173080006",
          "district_id": "3173080",
          "name": "DURI PULO"
        },
        {
          "id": "3174010001",
          "district_id": "3174010",
          "name": "JOGLO"
        },
        {
          "id": "3174010003",
          "district_id": "3174010",
          "name": "MERUYA SELATAN"
        },
        {
          "id": "3174010004",
          "district_id": "3174010",
          "name": "MERUYA UTARA"
        },
        {
          "id": "3174010005",
          "district_id": "3174010",
          "name": "KEMBANGAN SELATAN"
        },
        {
          "id": "3174010006",
          "district_id": "3174010",
          "name": "KEMBANGAN UTARA"
        },
        {
          "id": "3174020001",
          "district_id": "3174020",
          "name": "SUKABUMI SELATAN"
        },
        {
          "id": "3174020002",
          "district_id": "3174020",
          "name": "SUKABUMI UTARA"
        },
        {
          "id": "3174020003",
          "district_id": "3174020",
          "name": "KELAPA DUA"
        },
        {
          "id": "3174020004",
          "district_id": "3174020",
          "name": "KEBON JERUK"
        },
        {
          "id": "3174020005",
          "district_id": "3174020",
          "name": "DURI KEPA"
        },
        {
          "id": "3174020006",
          "district_id": "3174020",
          "name": "KEDOYA SELATAN"
        },
        {
          "id": "3174020007",
          "district_id": "3174020",
          "name": "KEDOYA UTARA"
        },
        {
          "id": "3174030001",
          "district_id": "3174030",
          "name": "PALMERAH"
        },
        {
          "id": "3174030002",
          "district_id": "3174030",
          "name": "SLIPI"
        },
        {
          "id": "3174030003",
          "district_id": "3174030",
          "name": "KEMANGGISAN"
        },
        {
          "id": "3174030004",
          "district_id": "3174030",
          "name": "KOTA BAMBU UTARA"
        },
        {
          "id": "3174030005",
          "district_id": "3174030",
          "name": "KOTA BAMBU SELATAN"
        },
        {
          "id": "3174030006",
          "district_id": "3174030",
          "name": "JATI PULO"
        },
        {
          "id": "3174040001",
          "district_id": "3174040",
          "name": "TANJUNG DUREN UTARA"
        },
        {
          "id": "3174040002",
          "district_id": "3174040",
          "name": "TANJUNG DUREN SELATAN"
        },
        {
          "id": "3174040003",
          "district_id": "3174040",
          "name": "TOMANG"
        },
        {
          "id": "3174040004",
          "district_id": "3174040",
          "name": "GROGOL"
        },
        {
          "id": "3174040005",
          "district_id": "3174040",
          "name": "JELAMBAR"
        },
        {
          "id": "3174040006",
          "district_id": "3174040",
          "name": "WIJAYA KESUMA"
        },
        {
          "id": "3174040007",
          "district_id": "3174040",
          "name": "JELAMBAR BARU"
        },
        {
          "id": "3174050001",
          "district_id": "3174050",
          "name": "KALIANYAR"
        },
        {
          "id": "3174050002",
          "district_id": "3174050",
          "name": "DURI SELATAN"
        },
        {
          "id": "3174050003",
          "district_id": "3174050",
          "name": "TANAH SEREAL"
        },
        {
          "id": "3174050004",
          "district_id": "3174050",
          "name": "DURI UTARA"
        },
        {
          "id": "3174050005",
          "district_id": "3174050",
          "name": "KRENDANG"
        },
        {
          "id": "3174050006",
          "district_id": "3174050",
          "name": "JEMBATAN BESI"
        },
        {
          "id": "3174050007",
          "district_id": "3174050",
          "name": "ANGKE"
        },
        {
          "id": "3174050008",
          "district_id": "3174050",
          "name": "JEMBATAN LIMA"
        },
        {
          "id": "3174050009",
          "district_id": "3174050",
          "name": "TAMBORA"
        },
        {
          "id": "3174050010",
          "district_id": "3174050",
          "name": "ROA MALAKA"
        },
        {
          "id": "3174050011",
          "district_id": "3174050",
          "name": "PEKOJAN"
        },
        {
          "id": "3174060002",
          "district_id": "3174060",
          "name": "MAPHAR"
        },
        {
          "id": "3174060003",
          "district_id": "3174060",
          "name": "TAMAN SARI"
        },
        {
          "id": "3174060004",
          "district_id": "3174060",
          "name": "TANGKI"
        },
        {
          "id": "3174060005",
          "district_id": "3174060",
          "name": "MANGGA BESAR"
        },
        {
          "id": "3174060006",
          "district_id": "3174060",
          "name": "KEAGUNGAN"
        },
        {
          "id": "3174060007",
          "district_id": "3174060",
          "name": "GLODOK"
        },
        {
          "id": "3174060008",
          "district_id": "3174060",
          "name": "PINANGSIA"
        },
        {
          "id": "3174070001",
          "district_id": "3174070",
          "name": "DURI KOSAMBI"
        },
        {
          "id": "3174070002",
          "district_id": "3174070",
          "name": "RAWA BUAYA"
        },
        {
          "id": "3174070003",
          "district_id": "3174070",
          "name": "KEDAUNG KALI ANGKE"
        },
        {
          "id": "3174070004",
          "district_id": "3174070",
          "name": "KAPUK"
        },
        {
          "id": "3174070005",
          "district_id": "3174070",
          "name": "CENGKARENG TIMUR"
        },
        {
          "id": "3174070006",
          "district_id": "3174070",
          "name": "CENGKARENG BARAT"
        },
        {
          "id": "3174080001",
          "district_id": "3174080",
          "name": "SEMANAN"
        },
        {
          "id": "3174080002",
          "district_id": "3174080",
          "name": "KALIDERES"
        },
        {
          "id": "3174080003",
          "district_id": "3174080",
          "name": "PEGADUNGAN"
        },
        {
          "id": "3174080004",
          "district_id": "3174080",
          "name": "TEGAL ALUR"
        },
        {
          "id": "3174080005",
          "district_id": "3174080",
          "name": "KAMAL"
        },
        {
          "id": "3175010001",
          "district_id": "3175010",
          "name": "KAMAL MUARA"
        },
        {
          "id": "3175010002",
          "district_id": "3175010",
          "name": "KAPUK MUARA"
        },
        {
          "id": "3175010003",
          "district_id": "3175010",
          "name": "PEJAGALAN"
        },
        {
          "id": "3175010004",
          "district_id": "3175010",
          "name": "PENJARINGAN"
        },
        {
          "id": "3175010005",
          "district_id": "3175010",
          "name": "PLUIT"
        },
        {
          "id": "3175020001",
          "district_id": "3175020",
          "name": "PADEMANGAN BARAT"
        },
        {
          "id": "3175020002",
          "district_id": "3175020",
          "name": "PADEMANGAN TIMUR"
        },
        {
          "id": "3175020003",
          "district_id": "3175020",
          "name": "ANCOL"
        },
        {
          "id": "3175030001",
          "district_id": "3175030",
          "name": "SUNTER AGUNG"
        },
        {
          "id": "3175030002",
          "district_id": "3175030",
          "name": "SUNTER JAYA"
        },
        {
          "id": "3175030003",
          "district_id": "3175030",
          "name": "PAPANGO"
        },
        {
          "id": "3175030004",
          "district_id": "3175030",
          "name": "WARAKAS"
        },
        {
          "id": "3175030005",
          "district_id": "3175030",
          "name": "SUNGAI BAMBU"
        },
        {
          "id": "3175030006",
          "district_id": "3175030",
          "name": "KEBON BAWANG"
        },
        {
          "id": "3175030007",
          "district_id": "3175030",
          "name": "TANJUNG PRIUK"
        },
        {
          "id": "3175040001",
          "district_id": "3175040",
          "name": "RAWABADAK SELATAN"
        },
        {
          "id": "3175040002",
          "district_id": "3175040",
          "name": "TUGU SELATAN"
        },
        {
          "id": "3175040003",
          "district_id": "3175040",
          "name": "TUGU UTARA"
        },
        {
          "id": "3175040004",
          "district_id": "3175040",
          "name": "LAGOA"
        },
        {
          "id": "3175040005",
          "district_id": "3175040",
          "name": "RAWABADAK UTARA"
        },
        {
          "id": "3175040006",
          "district_id": "3175040",
          "name": "KOJA"
        },
        {
          "id": "3175050001",
          "district_id": "3175050",
          "name": "KELAPA GADING BARAT"
        },
        {
          "id": "3175050002",
          "district_id": "3175050",
          "name": "KELAPA GADING TIMUR"
        },
        {
          "id": "3175050003",
          "district_id": "3175050",
          "name": "PEGANGSAAN DUA"
        },
        {
          "id": "3175060001",
          "district_id": "3175060",
          "name": "SUKA PURA"
        },
        {
          "id": "3175060002",
          "district_id": "3175060",
          "name": "ROROTAN"
        },
        {
          "id": "3175060003",
          "district_id": "3175060",
          "name": "MARUNDA"
        },
        {
          "id": "3175060004",
          "district_id": "3175060",
          "name": "CILINCING"
        },
        {
          "id": "3175060005",
          "district_id": "3175060",
          "name": "SEMPER TIMUR"
        },
        {
          "id": "3175060006",
          "district_id": "3175060",
          "name": "SEMPER BARAT"
        },
        {
          "id": "3175060007",
          "district_id": "3175060",
          "name": "KALI BARU"
        },
        {
          "id": "3101010001",
          "district_id": "3101010",
          "name": "PULAU TIDUNG"
        },
        {
          "id": "3101010002",
          "district_id": "3101010",
          "name": "PULAU PARI"
        },
        {
          "id": "3101010003",
          "district_id": "3101010",
          "name": "PULAU UNTUNG JAWA"
        },
        {
          "id": "3101020001",
          "district_id": "3101020",
          "name": "PULAU PANGGANG"
        },
        {
          "id": "3101020002",
          "district_id": "3101020",
          "name": "PULAU KELAPA"
        },
        {
          "id": "3101020003",
          "district_id": "3101020",
          "name": "PULAU HARAPAN"
        },
        {
          "id": "3171010001",
          "district_id": "3171010",
          "name": "CIPEDAK"
        },
        {
          "id": "3171010002",
          "district_id": "3171010",
          "name": "SRENGSENG SAWAH"
        },
        {
          "id": "3171010003",
          "district_id": "3171010",
          "name": "CIGANJUR"
        },
        {
          "id": "3171010004",
          "district_id": "3171010",
          "name": "JAGAKARSA"
        },
        {
          "id": "3201010007",
          "district_id": "3201010",
          "name": "SUKALUYU"
        },
        {
          "id": "3201010008",
          "district_id": "3201010",
          "name": "HAMBARO"
        },
        {
          "id": "3201010009",
          "district_id": "3201010",
          "name": "KALONG LIUD"
        },
        {
          "id": "3201010010",
          "district_id": "3201010",
          "name": "PARAKAN MUNCANG"
        },
        {
          "id": "3201010011",
          "district_id": "3201010",
          "name": "BATU TULIS"
        },
        {
          "id": "3201020001",
          "district_id": "3201020",
          "name": "PURASARI"
        },
        {
          "id": "3201020002",
          "district_id": "3201020",
          "name": "PURASEDA"
        },
        {
          "id": "3201020003",
          "district_id": "3201020",
          "name": "KARYASARI"
        },
        {
          "id": "3201020004",
          "district_id": "3201020",
          "name": "PABANGBON"
        },
        {
          "id": "3201020005",
          "district_id": "3201020",
          "name": "KARACAK"
        },
        {
          "id": "3201020006",
          "district_id": "3201020",
          "name": "BARENGKOK"
        },
        {
          "id": "3201020007",
          "district_id": "3201020",
          "name": "CIBEBER II"
        },
        {
          "id": "3201020016",
          "district_id": "3201020",
          "name": "CIBEBER I"
        },
        {
          "id": "3201020017",
          "district_id": "3201020",
          "name": "LEUWIMEKAR"
        },
        {
          "id": "3201020018",
          "district_id": "3201020",
          "name": "LEUWILIANG"
        },
        {
          "id": "3201020019",
          "district_id": "3201020",
          "name": "KAREHKEL"
        },
        {
          "id": "3201021001",
          "district_id": "3201021",
          "name": "WANGUN JAYA"
        },
        {
          "id": "3201021002",
          "district_id": "3201021",
          "name": "SADENGKOLOT"
        },
        {
          "id": "3201021003",
          "district_id": "3201021",
          "name": "LEUWISADENG"
        },
        {
          "id": "3201021004",
          "district_id": "3201021",
          "name": "SIBANTENG"
        },
        {
          "id": "3201021005",
          "district_id": "3201021",
          "name": "BABAKAN SADENG"
        },
        {
          "id": "3201021006",
          "district_id": "3201021",
          "name": "SADENG"
        },
        {
          "id": "3201021007",
          "district_id": "3201021",
          "name": "KALONG II"
        },
        {
          "id": "3201021008",
          "district_id": "3201021",
          "name": "KALONG I"
        },
        {
          "id": "3201030001",
          "district_id": "3201030",
          "name": "CIBUNIAN"
        },
        {
          "id": "3201030002",
          "district_id": "3201030",
          "name": "PURWABAKTI"
        },
        {
          "id": "3201030003",
          "district_id": "3201030",
          "name": "CIASMARA"
        },
        {
          "id": "3201030004",
          "district_id": "3201030",
          "name": "CIASIHAN"
        },
        {
          "id": "3201030005",
          "district_id": "3201030",
          "name": "GUNUNG SARI"
        },
        {
          "id": "3201030008",
          "district_id": "3201030",
          "name": "CIBENING"
        },
        {
          "id": "3201030009",
          "district_id": "3201030",
          "name": "GUNUNG PICUNG"
        },
        {
          "id": "3201030010",
          "district_id": "3201030",
          "name": "CIBITUNG KULON"
        },
        {
          "id": "3201030011",
          "district_id": "3201030",
          "name": "CIBITUNG WETAN"
        },
        {
          "id": "3201030012",
          "district_id": "3201030",
          "name": "PAMIJAHAN"
        },
        {
          "id": "3201030013",
          "district_id": "3201030",
          "name": "PASAREAN"
        },
        {
          "id": "3201030014",
          "district_id": "3201030",
          "name": "GUNUNG MENYAN"
        },
        {
          "id": "3201030015",
          "district_id": "3201030",
          "name": "CIMAYANG"
        },
        {
          "id": "3201040001",
          "district_id": "3201040",
          "name": "SITU UDIK"
        },
        {
          "id": "3201040002",
          "district_id": "3201040",
          "name": "SITU ILIR"
        },
        {
          "id": "3201040003",
          "district_id": "3201040",
          "name": "CIBATOK 2"
        },
        {
          "id": "3201040004",
          "district_id": "3201040",
          "name": "CIARUTEN UDIK"
        },
        {
          "id": "3201040005",
          "district_id": "3201040",
          "name": "CIBATOK 1"
        },
        {
          "id": "3201040006",
          "district_id": "3201040",
          "name": "SUKAMAJU"
        },
        {
          "id": "3201040007",
          "district_id": "3201040",
          "name": "CEMPLANG"
        },
        {
          "id": "3201040008",
          "district_id": "3201040",
          "name": "GALUGA"
        },
        {
          "id": "3201040010",
          "district_id": "3201040",
          "name": "CIMANGGU 2"
        },
        {
          "id": "3201040011",
          "district_id": "3201040",
          "name": "CIMANGGU 1"
        },
        {
          "id": "3201040012",
          "district_id": "3201040",
          "name": "GIRIMULYA"
        },
        {
          "id": "3201040013",
          "district_id": "3201040",
          "name": "LEUWEUNG KOLOT"
        },
        {
          "id": "3201040014",
          "district_id": "3201040",
          "name": "CIARUTEN ILIR"
        },
        {
          "id": "3201040015",
          "district_id": "3201040",
          "name": "CIJUJUNG"
        },
        {
          "id": "3201050004",
          "district_id": "3201050",
          "name": "CIAMPEA UDIK"
        },
        {
          "id": "3201050008",
          "district_id": "3201050",
          "name": "CINANGKA"
        },
        {
          "id": "3201050009",
          "district_id": "3201050",
          "name": "CIBUNTU"
        },
        {
          "id": "3201050010",
          "district_id": "3201050",
          "name": "CICADAS"
        },
        {
          "id": "3201050011",
          "district_id": "3201050",
          "name": "TEGAL WARU"
        },
        {
          "id": "3201050012",
          "district_id": "3201050",
          "name": "BOJONG JENGKOL"
        },
        {
          "id": "3201050013",
          "district_id": "3201050",
          "name": "CIHIDEUNG UDIK"
        },
        {
          "id": "3201050014",
          "district_id": "3201050",
          "name": "CIHIDEUNG ILIR"
        },
        {
          "id": "3201050015",
          "district_id": "3201050",
          "name": "CIBANTENG"
        },
        {
          "id": "3201050016",
          "district_id": "3201050",
          "name": "BOJONG RANGKAS"
        },
        {
          "id": "3201050017",
          "district_id": "3201050",
          "name": "CIBADAK"
        },
        {
          "id": "3201050019",
          "district_id": "3201050",
          "name": "CIAMPEA"
        },
        {
          "id": "3201051001",
          "district_id": "3201051",
          "name": "TAPOS 1"
        },
        {
          "id": "3201051002",
          "district_id": "3201051",
          "name": "GUNUNG MALANG"
        },
        {
          "id": "3201051003",
          "district_id": "3201051",
          "name": "TAPOS 2"
        },
        {
          "id": "3201051004",
          "district_id": "3201051",
          "name": "SITU DAUN"
        },
        {
          "id": "3201051005",
          "district_id": "3201051",
          "name": "CIBITUNG TENGAH"
        },
        {
          "id": "3201051006",
          "district_id": "3201051",
          "name": "CINANGNENG"
        },
        {
          "id": "3201051007",
          "district_id": "3201051",
          "name": "GUNUNG MULYA"
        },
        {
          "id": "3201060001",
          "district_id": "3201060",
          "name": "PURWASARI"
        },
        {
          "id": "3201060002",
          "district_id": "3201060",
          "name": "PETIR"
        },
        {
          "id": "3201060003",
          "district_id": "3201060",
          "name": "SUKADAMAI"
        },
        {
          "id": "3201060004",
          "district_id": "3201060",
          "name": "SUKAWENING"
        },
        {
          "id": "3201060005",
          "district_id": "3201060",
          "name": "NEGLASARI"
        },
        {
          "id": "3201060006",
          "district_id": "3201060",
          "name": "SINAR SARI"
        },
        {
          "id": "3201060007",
          "district_id": "3201060",
          "name": "CIHERANG"
        },
        {
          "id": "3201060008",
          "district_id": "3201060",
          "name": "DRAMAGA"
        },
        {
          "id": "3201060009",
          "district_id": "3201060",
          "name": "BABAKAN"
        },
        {
          "id": "3201060010",
          "district_id": "3201060",
          "name": "CIKARAWANG"
        },
        {
          "id": "3201070009",
          "district_id": "3201070",
          "name": "KOTA BATU"
        },
        {
          "id": "3201070010",
          "district_id": "3201070",
          "name": "MEKARJAYA"
        },
        {
          "id": "3201070011",
          "district_id": "3201070",
          "name": "PARAKAN"
        },
        {
          "id": "3201070012",
          "district_id": "3201070",
          "name": "CIOMAS"
        },
        {
          "id": "3201070013",
          "district_id": "3201070",
          "name": "PAGELARAN"
        },
        {
          "id": "3201070014",
          "district_id": "3201070",
          "name": "SUKAMAKMUR"
        },
        {
          "id": "3201070015",
          "district_id": "3201070",
          "name": "CIAPUS"
        },
        {
          "id": "3201070016",
          "district_id": "3201070",
          "name": "SUKAHARJA"
        },
        {
          "id": "3201070017",
          "district_id": "3201070",
          "name": "PADASUKA"
        },
        {
          "id": "3201070018",
          "district_id": "3201070",
          "name": "CIOMAS RAHAYU"
        },
        {
          "id": "3201070019",
          "district_id": "3201070",
          "name": "LALADON"
        },
        {
          "id": "3201071001",
          "district_id": "3201071",
          "name": "SUKAJADI"
        },
        {
          "id": "3201071002",
          "district_id": "3201071",
          "name": "SUKALUYU"
        },
        {
          "id": "3201071003",
          "district_id": "3201071",
          "name": "SUKAJAYA"
        },
        {
          "id": "3201071004",
          "district_id": "3201071",
          "name": "SUKARESMI"
        },
        {
          "id": "3201071005",
          "district_id": "3201071",
          "name": "PASIR EURIH"
        },
        {
          "id": "3201071006",
          "district_id": "3201071",
          "name": "TAMAN SARI"
        },
        {
          "id": "3201071007",
          "district_id": "3201071",
          "name": "SUKAMANTRI"
        },
        {
          "id": "3201071008",
          "district_id": "3201071",
          "name": "SIRNAGALIH"
        },
        {
          "id": "3201080010",
          "district_id": "3201080",
          "name": "WARUNG MENTENG"
        },
        {
          "id": "3201080011",
          "district_id": "3201080",
          "name": "CIJERUK"
        },
        {
          "id": "3201080012",
          "district_id": "3201080",
          "name": "CIPELANG"
        },
        {
          "id": "3201080013",
          "district_id": "3201080",
          "name": "CIBALUNG"
        },
        {
          "id": "3201080014",
          "district_id": "3201080",
          "name": "CIPICUNG"
        },
        {
          "id": "3201080015",
          "district_id": "3201080",
          "name": "TANJUNG SARI"
        },
        {
          "id": "3201080016",
          "district_id": "3201080",
          "name": "TAJUR HALANG"
        },
        {
          "id": "3201080017",
          "district_id": "3201080",
          "name": "PALASARI"
        },
        {
          "id": "3201080018",
          "district_id": "3201080",
          "name": "SUKAHARJA"
        },
        {
          "id": "3201081001",
          "district_id": "3201081",
          "name": "TUGU JAYA"
        },
        {
          "id": "3201081002",
          "district_id": "3201081",
          "name": "CIGOMBONG"
        },
        {
          "id": "3201081003",
          "district_id": "3201081",
          "name": "WATES JAYA"
        },
        {
          "id": "3201081004",
          "district_id": "3201081",
          "name": "SROGOL"
        },
        {
          "id": "3201081005",
          "district_id": "3201081",
          "name": "CIBURUY"
        },
        {
          "id": "3201081006",
          "district_id": "3201081",
          "name": "CISALADA"
        },
        {
          "id": "3201081007",
          "district_id": "3201081",
          "name": "PASIR JAYA"
        },
        {
          "id": "3201081008",
          "district_id": "3201081",
          "name": "CIBURAYUT"
        },
        {
          "id": "3201081009",
          "district_id": "3201081",
          "name": "CIADEG"
        },
        {
          "id": "3201090001",
          "district_id": "3201090",
          "name": "PASIR BUNCIR"
        },
        {
          "id": "3201090002",
          "district_id": "3201090",
          "name": "CINAGARA"
        },
        {
          "id": "3201090003",
          "district_id": "3201090",
          "name": "TANGKIL"
        },
        {
          "id": "3201090004",
          "district_id": "3201090",
          "name": "PASIR MUNCANG"
        },
        {
          "id": "3201090005",
          "district_id": "3201090",
          "name": "MUARA JAYA"
        },
        {
          "id": "3201090006",
          "district_id": "3201090",
          "name": "CARINGIN"
        },
        {
          "id": "3201090007",
          "district_id": "3201090",
          "name": "LEMAH DUHUR"
        },
        {
          "id": "3201090008",
          "district_id": "3201090",
          "name": "CIMANDE"
        },
        {
          "id": "3201090009",
          "district_id": "3201090",
          "name": "PANCAWATI"
        },
        {
          "id": "3201090010",
          "district_id": "3201090",
          "name": "CIDERUM"
        },
        {
          "id": "3201090011",
          "district_id": "3201090",
          "name": "CIHERANG PONDOK"
        },
        {
          "id": "3201090012",
          "district_id": "3201090",
          "name": "CIMANDE HILIR"
        },
        {
          "id": "3201100001",
          "district_id": "3201100",
          "name": "CILEUNGSI"
        },
        {
          "id": "3201100002",
          "district_id": "3201100",
          "name": "CITAPEN"
        },
        {
          "id": "3201100003",
          "district_id": "3201100",
          "name": "CIBEDUG"
        },
        {
          "id": "3201100004",
          "district_id": "3201100",
          "name": "BOJONG MURNI"
        },
        {
          "id": "3201100005",
          "district_id": "3201100",
          "name": "JAMBU LUWUK"
        },
        {
          "id": "3201100006",
          "district_id": "3201100",
          "name": "BANJAR SARI"
        },
        {
          "id": "3201100007",
          "district_id": "3201100",
          "name": "BANJAR WANGI"
        },
        {
          "id": "3201100008",
          "district_id": "3201100",
          "name": "BITUNG SARI"
        },
        {
          "id": "3201100009",
          "district_id": "3201100",
          "name": "TELUK PINANG"
        },
        {
          "id": "3201100010",
          "district_id": "3201100",
          "name": "BANJAR WARU"
        },
        {
          "id": "3201100011",
          "district_id": "3201100",
          "name": "CIAWI"
        },
        {
          "id": "3201100012",
          "district_id": "3201100",
          "name": "BENDUNGAN"
        },
        {
          "id": "3201100013",
          "district_id": "3201100",
          "name": "PANDANSARI"
        },
        {
          "id": "3201110001",
          "district_id": "3201110",
          "name": "CITEKO"
        },
        {
          "id": "3201110002",
          "district_id": "3201110",
          "name": "CIBEUREUM"
        },
        {
          "id": "3201110003",
          "district_id": "3201110",
          "name": "TUGU SELATAN"
        },
        {
          "id": "3201110004",
          "district_id": "3201110",
          "name": "TUGU UTARA"
        },
        {
          "id": "3201110005",
          "district_id": "3201110",
          "name": "BATU LAYANG"
        },
        {
          "id": "3201110006",
          "district_id": "3201110",
          "name": "CISARUA"
        },
        {
          "id": "3201110007",
          "district_id": "3201110",
          "name": "KOPO"
        },
        {
          "id": "3201110008",
          "district_id": "3201110",
          "name": "LEUWIMALANG"
        },
        {
          "id": "3201110009",
          "district_id": "3201110",
          "name": "JOGJOGAN"
        },
        {
          "id": "3201110010",
          "district_id": "3201110",
          "name": "CILEMBER"
        },
        {
          "id": "3201120001",
          "district_id": "3201120",
          "name": "SUKARESMI"
        },
        {
          "id": "3201120002",
          "district_id": "3201120",
          "name": "SUKAGALIH"
        },
        {
          "id": "3201120003",
          "district_id": "3201120",
          "name": "KUTA"
        },
        {
          "id": "3201120004",
          "district_id": "3201120",
          "name": "SUKAKARYA"
        },
        {
          "id": "3201120005",
          "district_id": "3201120",
          "name": "SUKAMANAH"
        },
        {
          "id": "3201120006",
          "district_id": "3201120",
          "name": "SUKAMAJU"
        },
        {
          "id": "3201120007",
          "district_id": "3201120",
          "name": "SUKAMAHI"
        },
        {
          "id": "3201120008",
          "district_id": "3201120",
          "name": "GADOG"
        },
        {
          "id": "3201120009",
          "district_id": "3201120",
          "name": "CIPAYUNG"
        },
        {
          "id": "3201120010",
          "district_id": "3201120",
          "name": "CIPAYUNG GIRANG"
        },
        {
          "id": "3201120011",
          "district_id": "3201120",
          "name": "MEGAMENDUNG"
        },
        {
          "id": "3201120012",
          "district_id": "3201120",
          "name": "PASIR ANGIN"
        },
        {
          "id": "3201130001",
          "district_id": "3201130",
          "name": "CIBANON"
        },
        {
          "id": "3201130002",
          "district_id": "3201130",
          "name": "GUNUNG GEULIS"
        },
        {
          "id": "3201130003",
          "district_id": "3201130",
          "name": "NAGRAK"
        },
        {
          "id": "3201130004",
          "district_id": "3201130",
          "name": "SUKATANI"
        },
        {
          "id": "3201130005",
          "district_id": "3201130",
          "name": "SUKARAJA"
        },
        {
          "id": "3201130006",
          "district_id": "3201130",
          "name": "CIKEAS"
        },
        {
          "id": "3201130007",
          "district_id": "3201130",
          "name": "CADAS NGAMPAR"
        },
        {
          "id": "3201130008",
          "district_id": "3201130",
          "name": "PASIRLAJA"
        },
        {
          "id": "3201130009",
          "district_id": "3201130",
          "name": "CIJUJUNG"
        },
        {
          "id": "3201130010",
          "district_id": "3201130",
          "name": "CIMANDALA"
        },
        {
          "id": "3201130011",
          "district_id": "3201130",
          "name": "PASIR JAMBU"
        },
        {
          "id": "3201130012",
          "district_id": "3201130",
          "name": "CILEBUT TIMUR"
        },
        {
          "id": "3201130013",
          "district_id": "3201130",
          "name": "CILEBUT BARAT"
        },
        {
          "id": "3201140001",
          "district_id": "3201140",
          "name": "CIJAYANTI"
        },
        {
          "id": "3201140002",
          "district_id": "3201140",
          "name": "BOJONG KONENG"
        },
        {
          "id": "3201140003",
          "district_id": "3201140",
          "name": "KARANG TENGAH"
        },
        {
          "id": "3201140004",
          "district_id": "3201140",
          "name": "SUMUR BATU"
        },
        {
          "id": "3201140005",
          "district_id": "3201140",
          "name": "BABAKAN MADANG"
        },
        {
          "id": "3201140006",
          "district_id": "3201140",
          "name": "CITARINGGUL"
        },
        {
          "id": "3201140007",
          "district_id": "3201140",
          "name": "CIPAMBUAN"
        },
        {
          "id": "3201140008",
          "district_id": "3201140",
          "name": "KADUMANGU"
        },
        {
          "id": "3201150001",
          "district_id": "3201150",
          "name": "SUKAWANGI"
        },
        {
          "id": "3201150002",
          "district_id": "3201150",
          "name": "SUKAHARJA"
        },
        {
          "id": "3201150003",
          "district_id": "3201150",
          "name": "WARGAJAYA"
        },
        {
          "id": "3201150004",
          "district_id": "3201150",
          "name": "SIRNAJAYA"
        },
        {
          "id": "3201150005",
          "district_id": "3201150",
          "name": "SUKAMULYA"
        },
        {
          "id": "3201150006",
          "district_id": "3201150",
          "name": "SUKAMAKMUR"
        },
        {
          "id": "3201150007",
          "district_id": "3201150",
          "name": "CIBADAK"
        },
        {
          "id": "3201150008",
          "district_id": "3201150",
          "name": "PABUARAN"
        },
        {
          "id": "3201150009",
          "district_id": "3201150",
          "name": "SUKADAMAI"
        },
        {
          "id": "3201150010",
          "district_id": "3201150",
          "name": "SUKARESMI"
        },
        {
          "id": "3201160011",
          "district_id": "3201160",
          "name": "KARYA MEKAR"
        },
        {
          "id": "3201160012",
          "district_id": "3201160",
          "name": "BANTAR KUNING"
        },
        {
          "id": "3201160013",
          "district_id": "3201160",
          "name": "CIKUTAMAHI"
        },
        {
          "id": "3201160014",
          "district_id": "3201160",
          "name": "CIBATU TIGA"
        },
        {
          "id": "3201160015",
          "district_id": "3201160",
          "name": "MEKARWANGI"
        },
        {
          "id": "3201160016",
          "district_id": "3201160",
          "name": "TEGAL PANJANG"
        },
        {
          "id": "3201160017",
          "district_id": "3201160",
          "name": "CARIU"
        },
        {
          "id": "3201160018",
          "district_id": "3201160",
          "name": "KUTA MEKAR"
        },
        {
          "id": "3201160019",
          "district_id": "3201160",
          "name": "SUKAJADI"
        },
        {
          "id": "3201160020",
          "district_id": "3201160",
          "name": "BABAKAN RADEN"
        },
        {
          "id": "3201161001",
          "district_id": "3201161",
          "name": "CIBADAK"
        },
        {
          "id": "3201161002",
          "district_id": "3201161",
          "name": "TANJUNG SARI"
        },
        {
          "id": "3201161003",
          "district_id": "3201161",
          "name": "SINARSARI"
        },
        {
          "id": "3201161004",
          "district_id": "3201161",
          "name": "SINARRASA"
        },
        {
          "id": "3201161005",
          "district_id": "3201161",
          "name": "BUANAJAYA"
        },
        {
          "id": "3201161006",
          "district_id": "3201161",
          "name": "ANTAJAYA"
        },
        {
          "id": "3201161007",
          "district_id": "3201161",
          "name": "PASIR TANJUNG"
        },
        {
          "id": "3201161008",
          "district_id": "3201161",
          "name": "TANJUNG RASA"
        },
        {
          "id": "3201161009",
          "district_id": "3201161",
          "name": "SUKARASA"
        },
        {
          "id": "3201161010",
          "district_id": "3201161",
          "name": "SELAWANGI"
        },
        {
          "id": "3201170001",
          "district_id": "3201170",
          "name": "SUKAJAYA"
        },
        {
          "id": "3201170002",
          "district_id": "3201170",
          "name": "SUKANEGARA"
        },
        {
          "id": "3201170003",
          "district_id": "3201170",
          "name": "CIBODAS"
        },
        {
          "id": "3201170004",
          "district_id": "3201170",
          "name": "SINGASARI"
        },
        {
          "id": "3201170005",
          "district_id": "3201170",
          "name": "SINGAJAYA"
        },
        {
          "id": "3201170006",
          "district_id": "3201170",
          "name": "SUKASIRNA"
        },
        {
          "id": "3201170007",
          "district_id": "3201170",
          "name": "BALEKAMBANG"
        },
        {
          "id": "3201170008",
          "district_id": "3201170",
          "name": "BENDUNGAN"
        },
        {
          "id": "3201170009",
          "district_id": "3201170",
          "name": "SIRNAGALIH"
        },
        {
          "id": "3201170010",
          "district_id": "3201170",
          "name": "JONGGOL"
        },
        {
          "id": "3201170011",
          "district_id": "3201170",
          "name": "SUKAMAJU"
        },
        {
          "id": "3201170012",
          "district_id": "3201170",
          "name": "SUKAMANAH"
        },
        {
          "id": "3201170013",
          "district_id": "3201170",
          "name": "WENINGGALIH"
        },
        {
          "id": "3201170014",
          "district_id": "3201170",
          "name": "SUKAGALIH"
        },
        {
          "id": "3201180010",
          "district_id": "3201180",
          "name": "DAYEUH"
        },
        {
          "id": "3201180011",
          "district_id": "3201180",
          "name": "MAMPIR"
        },
        {
          "id": "3201180012",
          "district_id": "3201180",
          "name": "SETU SARI"
        },
        {
          "id": "3201180013",
          "district_id": "3201180",
          "name": "CIPEUCANG"
        },
        {
          "id": "3201180014",
          "district_id": "3201180",
          "name": "JATISARI"
        },
        {
          "id": "3201180015",
          "district_id": "3201180",
          "name": "GANDOANG"
        },
        {
          "id": "3201180016",
          "district_id": "3201180",
          "name": "MEKARSARI"
        },
        {
          "id": "3201180017",
          "district_id": "3201180",
          "name": "CILEUNGSI KIDUL"
        },
        {
          "id": "3201180018",
          "district_id": "3201180",
          "name": "CILEUNGSI"
        },
        {
          "id": "3201180019",
          "district_id": "3201180",
          "name": "LIMUS NUNGGAL"
        },
        {
          "id": "3201180020",
          "district_id": "3201180",
          "name": "PASIR ANGIN"
        },
        {
          "id": "3201180021",
          "district_id": "3201180",
          "name": "CIPENJO"
        },
        {
          "id": "3201181001",
          "district_id": "3201181",
          "name": "LEUWIKARET"
        },
        {
          "id": "3201181003",
          "district_id": "3201181",
          "name": "BANTAR JATI"
        },
        {
          "id": "3201181004",
          "district_id": "3201181",
          "name": "NAMBO"
        },
        {
          "id": "3201181005",
          "district_id": "3201181",
          "name": "KEMBANG KUNING"
        },
        {
          "id": "3201181006",
          "district_id": "3201181",
          "name": "KELAPA NUNGGAL"
        },
        {
          "id": "3201181007",
          "district_id": "3201181",
          "name": "LIGARMUKTI"
        },
        {
          "id": "3201181008",
          "district_id": "3201181",
          "name": "BOJONG"
        },
        {
          "id": "3201181009",
          "district_id": "3201181",
          "name": "CIKAHURIPAN"
        },
        {
          "id": "3201190001",
          "district_id": "3201190",
          "name": "KARANGGAN"
        },
        {
          "id": "3201190002",
          "district_id": "3201190",
          "name": "GUNUNG PUTRI"
        },
        {
          "id": "3201190003",
          "district_id": "3201190",
          "name": "TLAJUNG UDIK"
        },
        {
          "id": "3201190004",
          "district_id": "3201190",
          "name": "BOJONG NANGKA"
        },
        {
          "id": "3201190005",
          "district_id": "3201190",
          "name": "CICADAS"
        },
        {
          "id": "3201190006",
          "district_id": "3201190",
          "name": "WANAHERANG"
        },
        {
          "id": "3201190007",
          "district_id": "3201190",
          "name": "CIKEAS UDIK"
        },
        {
          "id": "3201190008",
          "district_id": "3201190",
          "name": "NAGRAK"
        },
        {
          "id": "3201190009",
          "district_id": "3201190",
          "name": "CIANGSANA"
        },
        {
          "id": "3201190010",
          "district_id": "3201190",
          "name": "BOJONG KULUR"
        },
        {
          "id": "3201200001",
          "district_id": "3201200",
          "name": "TANGKIL"
        },
        {
          "id": "3201200002",
          "district_id": "3201200",
          "name": "HAMBALANG"
        },
        {
          "id": "3201200003",
          "district_id": "3201200",
          "name": "TAJUR"
        },
        {
          "id": "3201200004",
          "district_id": "3201200",
          "name": "PASIR MUKTI"
        },
        {
          "id": "3201200005",
          "district_id": "3201200",
          "name": "SUKAHATI"
        },
        {
          "id": "3201200006",
          "district_id": "3201200",
          "name": "LEUWINUTUG"
        },
        {
          "id": "3201200007",
          "district_id": "3201200",
          "name": "SANJA"
        },
        {
          "id": "3201200008",
          "district_id": "3201200",
          "name": "KARANG ASEM BARAT"
        },
        {
          "id": "3201200009",
          "district_id": "3201200",
          "name": "KARANG ASEM TIMUR"
        },
        {
          "id": "3201200010",
          "district_id": "3201200",
          "name": "TARIKOLOT"
        },
        {
          "id": "3201200011",
          "district_id": "3201200",
          "name": "GUNUNG SARI"
        },
        {
          "id": "3201200012",
          "district_id": "3201200",
          "name": "CITEUREUP"
        },
        {
          "id": "3201200013",
          "district_id": "3201200",
          "name": "PUSPANEGARA"
        },
        {
          "id": "3201200014",
          "district_id": "3201200",
          "name": "PUSPASARI"
        },
        {
          "id": "3201210001",
          "district_id": "3201210",
          "name": "KARADENAN"
        },
        {
          "id": "3201210002",
          "district_id": "3201210",
          "name": "NANGGEWER"
        },
        {
          "id": "3201210003",
          "district_id": "3201210",
          "name": "NANGGEWER MEKAR"
        },
        {
          "id": "3201210004",
          "district_id": "3201210",
          "name": "CIBINONG"
        },
        {
          "id": "3201210005",
          "district_id": "3201210",
          "name": "PAKANSARI"
        },
        {
          "id": "3201210006",
          "district_id": "3201210",
          "name": "SUKAHATI"
        },
        {
          "id": "3201210007",
          "district_id": "3201210",
          "name": "TENGAH"
        },
        {
          "id": "3201210008",
          "district_id": "3201210",
          "name": "PONDOK RAJEG"
        },
        {
          "id": "3201210009",
          "district_id": "3201210",
          "name": "HARAPAN JAYA"
        },
        {
          "id": "3201210010",
          "district_id": "3201210",
          "name": "PABUARAN"
        },
        {
          "id": "3201210011",
          "district_id": "3201210",
          "name": "CIRIMEKAR"
        },
        {
          "id": "3201210012",
          "district_id": "3201210",
          "name": "CIRIUNG"
        },
        {
          "id": "3201210013",
          "district_id": "3201210",
          "name": "PABUARAN MEKAR"
        },
        {
          "id": "3201220002",
          "district_id": "3201220",
          "name": "CIMANGGIS"
        },
        {
          "id": "3201220003",
          "district_id": "3201220",
          "name": "WARINGIN JAYA"
        },
        {
          "id": "3201220004",
          "district_id": "3201220",
          "name": "KEDUNG WARINGIN"
        },
        {
          "id": "3201220005",
          "district_id": "3201220",
          "name": "BOJONG GEDE"
        },
        {
          "id": "3201220011",
          "district_id": "3201220",
          "name": "SUSUKAN"
        },
        {
          "id": "3201220012",
          "district_id": "3201220",
          "name": "BOJONG BARU"
        },
        {
          "id": "3201220013",
          "district_id": "3201220",
          "name": "RAWA PANJANG"
        },
        {
          "id": "3201220014",
          "district_id": "3201220",
          "name": "PABUARAN"
        },
        {
          "id": "3201220015",
          "district_id": "3201220",
          "name": "RAGAJAYA"
        },
        {
          "id": "3201221001",
          "district_id": "3201221",
          "name": "TONJONG"
        },
        {
          "id": "3201221002",
          "district_id": "3201221",
          "name": "TAJUR HALANG"
        },
        {
          "id": "3201221003",
          "district_id": "3201221",
          "name": "SUKMAJAYA"
        },
        {
          "id": "3201221004",
          "district_id": "3201221",
          "name": "NANGGERANG"
        },
        {
          "id": "3201221005",
          "district_id": "3201221",
          "name": "SASAK PANJANG"
        },
        {
          "id": "3201221006",
          "district_id": "3201221",
          "name": "KALISUREN"
        },
        {
          "id": "3201221007",
          "district_id": "3201221",
          "name": "CITAYAM"
        },
        {
          "id": "3201230006",
          "district_id": "3201230",
          "name": "SEMPLAK BARAT"
        },
        {
          "id": "3201230007",
          "district_id": "3201230",
          "name": "ATANG SENJAYA"
        },
        {
          "id": "3201230008",
          "district_id": "3201230",
          "name": "PARAKAN JAYA"
        },
        {
          "id": "3201230009",
          "district_id": "3201230",
          "name": "BOJONG"
        },
        {
          "id": "3201230010",
          "district_id": "3201230",
          "name": "KEMANG"
        },
        {
          "id": "3201230011",
          "district_id": "3201230",
          "name": "PABUARAN"
        },
        {
          "id": "3201230013",
          "district_id": "3201230",
          "name": "TEGAL"
        },
        {
          "id": "3201230014",
          "district_id": "3201230",
          "name": "PONDOK UDIK"
        },
        {
          "id": "3201230015",
          "district_id": "3201230",
          "name": "JAMPANG"
        },
        {
          "id": "3201231001",
          "district_id": "3201231",
          "name": "MEKARSARI"
        },
        {
          "id": "3201231002",
          "district_id": "3201231",
          "name": "RANCA BUNGUR"
        },
        {
          "id": "3201231003",
          "district_id": "3201231",
          "name": "PASIR GAOK"
        },
        {
          "id": "3201231004",
          "district_id": "3201231",
          "name": "BANTARJAYA"
        },
        {
          "id": "3201231005",
          "district_id": "3201231",
          "name": "BANTAR SARI"
        },
        {
          "id": "3201231006",
          "district_id": "3201231",
          "name": "CANDALI"
        },
        {
          "id": "3201231007",
          "district_id": "3201231",
          "name": "CIMULANG"
        },
        {
          "id": "3201240009",
          "district_id": "3201240",
          "name": "IWUL"
        },
        {
          "id": "3201240010",
          "district_id": "3201240",
          "name": "JABON MEKAR"
        },
        {
          "id": "3201240011",
          "district_id": "3201240",
          "name": "PAMAGER SARI"
        },
        {
          "id": "3201240012",
          "district_id": "3201240",
          "name": "PARUNG"
        },
        {
          "id": "3201240013",
          "district_id": "3201240",
          "name": "WARU"
        },
        {
          "id": "3201240014",
          "district_id": "3201240",
          "name": "WARUJAYA"
        },
        {
          "id": "3201240015",
          "district_id": "3201240",
          "name": "BOJONG SEMPU"
        },
        {
          "id": "3201240016",
          "district_id": "3201240",
          "name": "BOJONG INDAH"
        },
        {
          "id": "3201240017",
          "district_id": "3201240",
          "name": "COGREG"
        },
        {
          "id": "3201241001",
          "district_id": "3201241",
          "name": "KARIHKIL"
        },
        {
          "id": "3201241002",
          "district_id": "3201241",
          "name": "CIBEUTEUNG UDIK"
        },
        {
          "id": "3201241003",
          "district_id": "3201241",
          "name": "BABAKAN"
        },
        {
          "id": "3201241004",
          "district_id": "3201241",
          "name": "PUTAT NUTUG"
        },
        {
          "id": "3201241005",
          "district_id": "3201241",
          "name": "CIBEUTEUNG MUARA"
        },
        {
          "id": "3201241006",
          "district_id": "3201241",
          "name": "CIBENTANG"
        },
        {
          "id": "3201241007",
          "district_id": "3201241",
          "name": "PARIGI MEKAR"
        },
        {
          "id": "3201241008",
          "district_id": "3201241",
          "name": "CISEENG"
        },
        {
          "id": "3201241009",
          "district_id": "3201241",
          "name": "CIHOWE"
        },
        {
          "id": "3201241010",
          "district_id": "3201241",
          "name": "KURIPAN"
        },
        {
          "id": "3201250001",
          "district_id": "3201250",
          "name": "JAMPANG"
        },
        {
          "id": "3201250002",
          "district_id": "3201250",
          "name": "CIBADUNG"
        },
        {
          "id": "3201250003",
          "district_id": "3201250",
          "name": "CIBINONG"
        },
        {
          "id": "3201250004",
          "district_id": "3201250",
          "name": "CIDOKOM"
        },
        {
          "id": "3201250005",
          "district_id": "3201250",
          "name": "PADURENAN"
        },
        {
          "id": "3201250007",
          "district_id": "3201250",
          "name": "RAWAKALONG"
        },
        {
          "id": "3201250008",
          "district_id": "3201250",
          "name": "PENGASINAN"
        },
        {
          "id": "3201250009",
          "district_id": "3201250",
          "name": "GUNUNG SINDUR"
        },
        {
          "id": "3201250010",
          "district_id": "3201250",
          "name": "PABUARAN"
        },
        {
          "id": "3201260001",
          "district_id": "3201260",
          "name": "LEUWIBATU"
        },
        {
          "id": "3201260002",
          "district_id": "3201260",
          "name": "CIDOKOM"
        },
        {
          "id": "3201260003",
          "district_id": "3201260",
          "name": "GOBANG"
        },
        {
          "id": "3201260004",
          "district_id": "3201260",
          "name": "RABAK"
        },
        {
          "id": "3201260005",
          "district_id": "3201260",
          "name": "CIBODAS"
        },
        {
          "id": "3201260006",
          "district_id": "3201260",
          "name": "KAMPUNG SAWAH"
        },
        {
          "id": "3201260007",
          "district_id": "3201260",
          "name": "RUMPIN"
        },
        {
          "id": "3201260008",
          "district_id": "3201260",
          "name": "CIPINANG"
        },
        {
          "id": "3201260009",
          "district_id": "3201260",
          "name": "SUKASARI"
        },
        {
          "id": "3201260010",
          "district_id": "3201260",
          "name": "KERTAJAYA"
        },
        {
          "id": "3201260011",
          "district_id": "3201260",
          "name": "TAMAN SARI"
        },
        {
          "id": "3201260012",
          "district_id": "3201260",
          "name": "SUKAMULYA"
        },
        {
          "id": "3201260013",
          "district_id": "3201260",
          "name": "MEKAR SARI"
        },
        {
          "id": "3201260014",
          "district_id": "3201260",
          "name": "MEKARJAYA"
        },
        {
          "id": "3201270006",
          "district_id": "3201270",
          "name": "SUKARAKSA"
        },
        {
          "id": "3201270009",
          "district_id": "3201270",
          "name": "SUKAMAJU"
        },
        {
          "id": "3201270010",
          "district_id": "3201270",
          "name": "CIGUDEG"
        },
        {
          "id": "3201270011",
          "district_id": "3201270",
          "name": "BANYU RESMI"
        },
        {
          "id": "3201270012",
          "district_id": "3201270",
          "name": "WARGAJAYA"
        },
        {
          "id": "3201270013",
          "district_id": "3201270",
          "name": "BUNAR"
        },
        {
          "id": "3201270014",
          "district_id": "3201270",
          "name": "MEKARJAYA"
        },
        {
          "id": "3201270015",
          "district_id": "3201270",
          "name": "CINTAMANIK"
        },
        {
          "id": "3201270016",
          "district_id": "3201270",
          "name": "BANYU WANGI"
        },
        {
          "id": "3201270017",
          "district_id": "3201270",
          "name": "BANYU ASIH"
        },
        {
          "id": "3201270018",
          "district_id": "3201270",
          "name": "TEGALEGA"
        },
        {
          "id": "3201270019",
          "district_id": "3201270",
          "name": "BATU JAJAR"
        },
        {
          "id": "3201270020",
          "district_id": "3201270",
          "name": "RENGASJAJAR"
        },
        {
          "id": "3201270021",
          "district_id": "3201270",
          "name": "BANGUNJAYA"
        },
        {
          "id": "3201270022",
          "district_id": "3201270",
          "name": "ARGAPURA"
        },
        {
          "id": "3201271001",
          "district_id": "3201271",
          "name": "CISARUA"
        },
        {
          "id": "3201271002",
          "district_id": "3201271",
          "name": "KIARASARI"
        },
        {
          "id": "3201271003",
          "district_id": "3201271",
          "name": "KIARAPANDAK"
        },
        {
          "id": "3201271004",
          "district_id": "3201271",
          "name": "HARKATJAYA"
        },
        {
          "id": "3201271005",
          "district_id": "3201271",
          "name": "SUKAJAYA"
        },
        {
          "id": "3201271006",
          "district_id": "3201271",
          "name": "SIPAYUNG"
        },
        {
          "id": "3201271007",
          "district_id": "3201271",
          "name": "SUKAMULIH"
        },
        {
          "id": "3201271008",
          "district_id": "3201271",
          "name": "PASIR MADANG"
        },
        {
          "id": "3201271009",
          "district_id": "3201271",
          "name": "CILEUKSA"
        },
        {
          "id": "3201271011",
          "district_id": "3201271",
          "name": "JAYARAHARJA"
        },
        {
          "id": "3201280003",
          "district_id": "3201280",
          "name": "PANGRADIN"
        },
        {
          "id": "3201280004",
          "district_id": "3201280",
          "name": "KALONGSAWAH"
        },
        {
          "id": "3201280005",
          "district_id": "3201280",
          "name": "SIPAK"
        },
        {
          "id": "3201280006",
          "district_id": "3201280",
          "name": "PAMAGERSARI"
        },
        {
          "id": "3201280007",
          "district_id": "3201280",
          "name": "JUGALA JAYA"
        },
        {
          "id": "3201280009",
          "district_id": "3201280",
          "name": "TEGAL WANGI"
        },
        {
          "id": "3201280010",
          "district_id": "3201280",
          "name": "KOLEANG"
        },
        {
          "id": "3201280011",
          "district_id": "3201280",
          "name": "JASINGA"
        },
        {
          "id": "3201280013",
          "district_id": "3201280",
          "name": "CIKOPOMAYAK"
        },
        {
          "id": "3201280014",
          "district_id": "3201280",
          "name": "NEGLASARI"
        },
        {
          "id": "3201280015",
          "district_id": "3201280",
          "name": "BAGOANG"
        },
        {
          "id": "3201280016",
          "district_id": "3201280",
          "name": "BARENGKOK"
        },
        {
          "id": "3201280017",
          "district_id": "3201280",
          "name": "PANGAUR"
        },
        {
          "id": "3201280018",
          "district_id": "3201280",
          "name": "WIRAJAYA"
        },
        {
          "id": "3201290001",
          "district_id": "3201290",
          "name": "CIOMAS"
        },
        {
          "id": "3201290002",
          "district_id": "3201290",
          "name": "TAPOS"
        },
        {
          "id": "3201290003",
          "district_id": "3201290",
          "name": "BATOK"
        },
        {
          "id": "3201290004",
          "district_id": "3201290",
          "name": "BABAKAN"
        },
        {
          "id": "3201290005",
          "district_id": "3201290",
          "name": "BOJONG"
        },
        {
          "id": "3201290006",
          "district_id": "3201290",
          "name": "SINGABRAJA"
        },
        {
          "id": "3201290007",
          "district_id": "3201290",
          "name": "TENJO"
        },
        {
          "id": "3201290008",
          "district_id": "3201290",
          "name": "CILAKU"
        },
        {
          "id": "3201290009",
          "district_id": "3201290",
          "name": "SINGABANGSA"
        },
        {
          "id": "3201300001",
          "district_id": "3201300",
          "name": "JAGABAYA"
        },
        {
          "id": "3201300002",
          "district_id": "3201300",
          "name": "GOROWONG"
        },
        {
          "id": "3201300003",
          "district_id": "3201300",
          "name": "DAGO"
        },
        {
          "id": "3201300004",
          "district_id": "3201300",
          "name": "CIKUDA"
        },
        {
          "id": "3201300005",
          "district_id": "3201300",
          "name": "PINGKU"
        },
        {
          "id": "3201300006",
          "district_id": "3201300",
          "name": "LUMPANG"
        },
        {
          "id": "3201300007",
          "district_id": "3201300",
          "name": "GINTUNG CILEJET"
        },
        {
          "id": "3201300008",
          "district_id": "3201300",
          "name": "JAGABITA"
        },
        {
          "id": "3201300009",
          "district_id": "3201300",
          "name": "CIBUNAR"
        },
        {
          "id": "3201300010",
          "district_id": "3201300",
          "name": "PARUNG PANJANG"
        },
        {
          "id": "3201300011",
          "district_id": "3201300",
          "name": "KABASIRAN"
        },
        {
          "id": "3202010001",
          "district_id": "3202010",
          "name": "CIBENDA"
        },
        {
          "id": "3202010002",
          "district_id": "3202010",
          "name": "CIWARU"
        },
        {
          "id": "3202010003",
          "district_id": "3202010",
          "name": "TAMANJAYA"
        },
        {
          "id": "3202010004",
          "district_id": "3202010",
          "name": "MEKARJAYA"
        },
        {
          "id": "3202010005",
          "district_id": "3202010",
          "name": "CIEMAS"
        },
        {
          "id": "3202010006",
          "district_id": "3202010",
          "name": "GIRIMUKTI"
        },
        {
          "id": "3202010007",
          "district_id": "3202010",
          "name": "MANDRAJAYA"
        },
        {
          "id": "3202010008",
          "district_id": "3202010",
          "name": "MEKARSAKTI"
        },
        {
          "id": "3202010009",
          "district_id": "3202010",
          "name": "SIDAMULYA"
        },
        {
          "id": "3202020001",
          "district_id": "3202020",
          "name": "GUNUNGBATU"
        },
        {
          "id": "3202020002",
          "district_id": "3202020",
          "name": "CIKANGKUNG"
        },
        {
          "id": "3202020003",
          "district_id": "3202020",
          "name": "PURWASEDAR"
        },
        {
          "id": "3202020004",
          "district_id": "3202020",
          "name": "CIRACAP"
        },
        {
          "id": "3202020005",
          "district_id": "3202020",
          "name": "PASIRPANJANG"
        },
        {
          "id": "3202020006",
          "district_id": "3202020",
          "name": "MEKARSARI"
        },
        {
          "id": "3202020007",
          "district_id": "3202020",
          "name": "PANGUMBAHAN"
        },
        {
          "id": "3202021001",
          "district_id": "3202021",
          "name": "CARINGIN NUNGGAL"
        },
        {
          "id": "3202021002",
          "district_id": "3202021",
          "name": "WALURAN"
        },
        {
          "id": "3202021003",
          "district_id": "3202021",
          "name": "SUKAMUKTI"
        },
        {
          "id": "3202021004",
          "district_id": "3202021",
          "name": "MEKAR MUKTI"
        },
        {
          "id": "3202021005",
          "district_id": "3202021",
          "name": "WALURAN MANDIRI"
        },
        {
          "id": "3202021006",
          "district_id": "3202021",
          "name": "MANGUNJAYA"
        },
        {
          "id": "3202030001",
          "district_id": "3202030",
          "name": "PASIRIPIS"
        },
        {
          "id": "3202030002",
          "district_id": "3202030",
          "name": "BUNIWANGI"
        },
        {
          "id": "3202030003",
          "district_id": "3202030",
          "name": "CIPEUNDEUY"
        },
        {
          "id": "3202030004",
          "district_id": "3202030",
          "name": "GUNUNG SUNGGING"
        },
        {
          "id": "3202030011",
          "district_id": "3202030",
          "name": "CITANGLAR"
        },
        {
          "id": "3202030012",
          "district_id": "3202030",
          "name": "JAGAMUKTI"
        },
        {
          "id": "3202030013",
          "district_id": "3202030",
          "name": "SURADE"
        },
        {
          "id": "3202030014",
          "district_id": "3202030",
          "name": "KADALEMAN"
        },
        {
          "id": "3202030015",
          "district_id": "3202030",
          "name": "WANASARI"
        },
        {
          "id": "3202030016",
          "district_id": "3202030",
          "name": "SIRNASARI"
        },
        {
          "id": "3202030017",
          "district_id": "3202030",
          "name": "SUKATANI"
        },
        {
          "id": "3202030018",
          "district_id": "3202030",
          "name": "KADEMANGAN"
        },
        {
          "id": "3202031001",
          "district_id": "3202031",
          "name": "CIDAHU"
        },
        {
          "id": "3202031002",
          "district_id": "3202031",
          "name": "CIBITUNG"
        },
        {
          "id": "3202031003",
          "district_id": "3202031",
          "name": "BANYUWANGI"
        },
        {
          "id": "3202031004",
          "district_id": "3202031",
          "name": "CIBODAS"
        },
        {
          "id": "3202031005",
          "district_id": "3202031",
          "name": "BANYUMURNI"
        },
        {
          "id": "3202031006",
          "district_id": "3202031",
          "name": "TALAGAMURNI"
        },
        {
          "id": "3202040001",
          "district_id": "3202040",
          "name": "CIPARAY"
        },
        {
          "id": "3202040002",
          "district_id": "3202040",
          "name": "BOJONG GENTENG"
        },
        {
          "id": "3202040003",
          "district_id": "3202040",
          "name": "BOJONGSARI"
        },
        {
          "id": "3202040004",
          "district_id": "3202040",
          "name": "MEKARJAYA"
        },
        {
          "id": "3202040005",
          "district_id": "3202040",
          "name": "NAGRAKSARI"
        },
        {
          "id": "3202040006",
          "district_id": "3202040",
          "name": "JAMPANG KULON"
        },
        {
          "id": "3202040007",
          "district_id": "3202040",
          "name": "TANJUNG"
        },
        {
          "id": "3202040008",
          "district_id": "3202040",
          "name": "PADAJAYA"
        },
        {
          "id": "3202040013",
          "district_id": "3202040",
          "name": "CIKARANG"
        },
        {
          "id": "3202040014",
          "district_id": "3202040",
          "name": "KARANGANYAR"
        },
        {
          "id": "3202040015",
          "district_id": "3202040",
          "name": "CIKARANGGEUSAN"
        },
        {
          "id": "3202041001",
          "district_id": "3202041",
          "name": "BOREGAH INDAH"
        },
        {
          "id": "3202041002",
          "district_id": "3202041",
          "name": "CIMANGGU"
        },
        {
          "id": "3202041003",
          "district_id": "3202041",
          "name": "SUKAMAJU"
        },
        {
          "id": "3202041004",
          "district_id": "3202041",
          "name": "SUKAJADI"
        },
        {
          "id": "3202041005",
          "district_id": "3202041",
          "name": "KARANGMEKAR"
        },
        {
          "id": "3202041006",
          "district_id": "3202041",
          "name": "SUKAMANAH"
        },
        {
          "id": "3202050001",
          "district_id": "3202050",
          "name": "CIMAHPAR"
        },
        {
          "id": "3202050002",
          "district_id": "3202050",
          "name": "SEKARSARI"
        },
        {
          "id": "3202050003",
          "district_id": "3202050",
          "name": "KALIBUNDER"
        },
        {
          "id": "3202050004",
          "district_id": "3202050",
          "name": "SUKALUYU"
        },
        {
          "id": "3202050005",
          "district_id": "3202050",
          "name": "BOJONG"
        },
        {
          "id": "3202050006",
          "district_id": "3202050",
          "name": "BALEKAMBANG"
        },
        {
          "id": "3202050007",
          "district_id": "3202050",
          "name": "MEKARWANGI"
        },
        {
          "id": "3202060001",
          "district_id": "3202060",
          "name": "SUMBERJAYA"
        },
        {
          "id": "3202060002",
          "district_id": "3202060",
          "name": "BUNIASIH"
        },
        {
          "id": "3202060003",
          "district_id": "3202060",
          "name": "TEGALBULEUD"
        },
        {
          "id": "3202060004",
          "district_id": "3202060",
          "name": "CALINGCING"
        },
        {
          "id": "3202060005",
          "district_id": "3202060",
          "name": "RAMBAY"
        },
        {
          "id": "3202060006",
          "district_id": "3202060",
          "name": "NANGELA"
        },
        {
          "id": "3202060007",
          "district_id": "3202060",
          "name": "BANGBAYANG"
        },
        {
          "id": "3202060008",
          "district_id": "3202060",
          "name": "SIRNAMEKAR"
        },
        {
          "id": "3202070001",
          "district_id": "3202070",
          "name": "CIDOLOG"
        },
        {
          "id": "3202070002",
          "district_id": "3202070",
          "name": "MEKARJAYA"
        },
        {
          "id": "3202070003",
          "district_id": "3202070",
          "name": "CIKARANG"
        },
        {
          "id": "3202070004",
          "district_id": "3202070",
          "name": "CIPAMINGKIS"
        },
        {
          "id": "3202070005",
          "district_id": "3202070",
          "name": "TEGALLEGA"
        },
        {
          "id": "3202080005",
          "district_id": "3202080",
          "name": "SINAR BENTANG"
        },
        {
          "id": "3202080006",
          "district_id": "3202080",
          "name": "GUNUNG BENTANG"
        },
        {
          "id": "3202080007",
          "district_id": "3202080",
          "name": "PASANGGRAHAN"
        },
        {
          "id": "3202080009",
          "district_id": "3202080",
          "name": "DATARNANGKA"
        },
        {
          "id": "3202080010",
          "district_id": "3202080",
          "name": "SAGARANTEN"
        },
        {
          "id": "3202080011",
          "district_id": "3202080",
          "name": "MARGALUYU"
        },
        {
          "id": "3202080012",
          "district_id": "3202080",
          "name": "CIBITUNG"
        },
        {
          "id": "3202080017",
          "district_id": "3202080",
          "name": "HEGARMANAH"
        },
        {
          "id": "3202080018",
          "district_id": "3202080",
          "name": "CIBAREGBEG"
        },
        {
          "id": "3202080019",
          "district_id": "3202080",
          "name": "PUNCAKMANGGIS"
        },
        {
          "id": "3202080020",
          "district_id": "3202080",
          "name": "MEKARSARI"
        },
        {
          "id": "3202081001",
          "district_id": "3202081",
          "name": "HEGARMULYA"
        },
        {
          "id": "3202081002",
          "district_id": "3202081",
          "name": "CIDADAP"
        },
        {
          "id": "3202081003",
          "district_id": "3202081",
          "name": "PADASENANG"
        },
        {
          "id": "3202081004",
          "district_id": "3202081",
          "name": "BANJARSARI"
        },
        {
          "id": "3202081005",
          "district_id": "3202081",
          "name": "TENJOLAUT"
        },
        {
          "id": "3202081006",
          "district_id": "3202081",
          "name": "MEKARTANI"
        },
        {
          "id": "3202082001",
          "district_id": "3202082",
          "name": "CIMENTENG"
        },
        {
          "id": "3202082002",
          "district_id": "3202082",
          "name": "CURUGKEMBAR"
        },
        {
          "id": "3202082003",
          "district_id": "3202082",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3202082004",
          "district_id": "3202082",
          "name": "SINDANGRAJA"
        },
        {
          "id": "3202082005",
          "district_id": "3202082",
          "name": "MEKARTANJUNG"
        },
        {
          "id": "3202082006",
          "district_id": "3202082",
          "name": "BOJONG TUGU"
        },
        {
          "id": "3202082007",
          "district_id": "3202082",
          "name": "NAGRAKJAYA"
        },
        {
          "id": "3202090001",
          "district_id": "3202090",
          "name": "SUKAJAYA"
        },
        {
          "id": "3202090002",
          "district_id": "3202090",
          "name": "CIWALAT"
        },
        {
          "id": "3202090003",
          "district_id": "3202090",
          "name": "PABUARAN"
        },
        {
          "id": "3202090004",
          "district_id": "3202090",
          "name": "CIBADAK"
        },
        {
          "id": "3202090005",
          "district_id": "3202090",
          "name": "SIRNASARI"
        },
        {
          "id": "3202090006",
          "district_id": "3202090",
          "name": "BANTARSARI"
        },
        {
          "id": "3202090007",
          "district_id": "3202090",
          "name": "LEMBURSAWAH"
        },
        {
          "id": "3202100001",
          "district_id": "3202100",
          "name": "LANGKAPJAYA"
        },
        {
          "id": "3202100002",
          "district_id": "3202100",
          "name": "CILANGKAP"
        },
        {
          "id": "3202100003",
          "district_id": "3202100",
          "name": "LENGKONG"
        },
        {
          "id": "3202100004",
          "district_id": "3202100",
          "name": "TEGALLEGA"
        },
        {
          "id": "3202100005",
          "district_id": "3202100",
          "name": "NEGLASARI"
        },
        {
          "id": "3202110005",
          "district_id": "3202110",
          "name": "CITARIK"
        },
        {
          "id": "3202110006",
          "district_id": "3202110",
          "name": "PALABUHANRATU"
        },
        {
          "id": "3202110007",
          "district_id": "3202110",
          "name": "CITEPUS"
        },
        {
          "id": "3202110008",
          "district_id": "3202110",
          "name": "CIBODAS"
        },
        {
          "id": "3202110009",
          "district_id": "3202110",
          "name": "BUNIWANGI"
        },
        {
          "id": "3202110010",
          "district_id": "3202110",
          "name": "CIKADU"
        },
        {
          "id": "3202110011",
          "district_id": "3202110",
          "name": "PASIRSUREN"
        },
        {
          "id": "3202110012",
          "district_id": "3202110",
          "name": "TONJONG"
        },
        {
          "id": "3202110013",
          "district_id": "3202110",
          "name": "JAYANTI"
        },
        {
          "id": "3202110014",
          "district_id": "3202110",
          "name": "CIMANGGU"
        },
        {
          "id": "3202111001",
          "district_id": "3202111",
          "name": "CIHAUR"
        },
        {
          "id": "3202111002",
          "district_id": "3202111",
          "name": "KERTAJAYA"
        },
        {
          "id": "3202111003",
          "district_id": "3202111",
          "name": "LOJI"
        },
        {
          "id": "3202111004",
          "district_id": "3202111",
          "name": "CIDADAP"
        },
        {
          "id": "3202111005",
          "district_id": "3202111",
          "name": "CIBUNTU"
        },
        {
          "id": "3202111006",
          "district_id": "3202111",
          "name": "MEKARASIH"
        },
        {
          "id": "3202111007",
          "district_id": "3202111",
          "name": "SANGRAWAYANG"
        },
        {
          "id": "3202120002",
          "district_id": "3202120",
          "name": "HEGARMANAH"
        },
        {
          "id": "3202120003",
          "district_id": "3202120",
          "name": "BANTARKALONG"
        },
        {
          "id": "3202120004",
          "district_id": "3202120",
          "name": "SIRNAJAYA"
        },
        {
          "id": "3202120005",
          "district_id": "3202120",
          "name": "BOJONGKERTA"
        },
        {
          "id": "3202120007",
          "district_id": "3202120",
          "name": "GIRIJAYA"
        },
        {
          "id": "3202120008",
          "district_id": "3202120",
          "name": "WARUNGKIARA"
        },
        {
          "id": "3202120009",
          "district_id": "3202120",
          "name": "SUKAHARJA"
        },
        {
          "id": "3202120010",
          "district_id": "3202120",
          "name": "TARISI"
        },
        {
          "id": "3202120011",
          "district_id": "3202120",
          "name": "DAMARRAJA"
        },
        {
          "id": "3202120012",
          "district_id": "3202120",
          "name": "MEKARJAYA"
        },
        {
          "id": "3202120013",
          "district_id": "3202120",
          "name": "KERTAMUKTI"
        },
        {
          "id": "3202121001",
          "district_id": "3202121",
          "name": "BANTARGEBANG"
        },
        {
          "id": "3202121002",
          "district_id": "3202121",
          "name": "BOJONGGALING"
        },
        {
          "id": "3202121003",
          "district_id": "3202121",
          "name": "BANTARGADUNG"
        },
        {
          "id": "3202121004",
          "district_id": "3202121",
          "name": "MANGUNJAYA"
        },
        {
          "id": "3202121005",
          "district_id": "3202121",
          "name": "LIMUSNUNGGAL"
        },
        {
          "id": "3202121006",
          "district_id": "3202121",
          "name": "BUANAJAYA"
        },
        {
          "id": "3202121007",
          "district_id": "3202121",
          "name": "BOYONGSARI"
        },
        {
          "id": "3202130001",
          "district_id": "3202130",
          "name": "BANTARPANJANG"
        },
        {
          "id": "3202130009",
          "district_id": "3202130",
          "name": "BOJONGTIPAR"
        },
        {
          "id": "3202130010",
          "district_id": "3202130",
          "name": "CIJULANG"
        },
        {
          "id": "3202130011",
          "district_id": "3202130",
          "name": "NANGERANG"
        },
        {
          "id": "3202130012",
          "district_id": "3202130",
          "name": "BOJONG JENGKOL"
        },
        {
          "id": "3202130013",
          "district_id": "3202130",
          "name": "BANTAR AGUNG"
        },
        {
          "id": "3202130014",
          "district_id": "3202130",
          "name": "JAMPANG TENGAH"
        },
        {
          "id": "3202130015",
          "district_id": "3202130",
          "name": "PANUMBANGAN"
        },
        {
          "id": "3202130016",
          "district_id": "3202130",
          "name": "SINDANGRESMI"
        },
        {
          "id": "3202130017",
          "district_id": "3202130",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3202130018",
          "district_id": "3202130",
          "name": "PADABEUNGHAR"
        },
        {
          "id": "3202131001",
          "district_id": "3202131",
          "name": "NEGLASARI"
        },
        {
          "id": "3202131002",
          "district_id": "3202131",
          "name": "CICUKANG"
        },
        {
          "id": "3202131003",
          "district_id": "3202131",
          "name": "MARGALUYU"
        },
        {
          "id": "3202131004",
          "district_id": "3202131",
          "name": "PURABAYA"
        },
        {
          "id": "3202131005",
          "district_id": "3202131",
          "name": "PAGELARAN"
        },
        {
          "id": "3202131006",
          "district_id": "3202131",
          "name": "CITAMIANG"
        },
        {
          "id": "3202131007",
          "district_id": "3202131",
          "name": "CIMERANG"
        },
        {
          "id": "3202140001",
          "district_id": "3202140",
          "name": "SUKAMAJU"
        },
        {
          "id": "3202140002",
          "district_id": "3202140",
          "name": "CIBATU"
        },
        {
          "id": "3202140003",
          "district_id": "3202140",
          "name": "PARAKANLIMA"
        },
        {
          "id": "3202140004",
          "district_id": "3202140",
          "name": "KERTARAHARJA"
        },
        {
          "id": "3202140005",
          "district_id": "3202140",
          "name": "BOJONG"
        },
        {
          "id": "3202140006",
          "district_id": "3202140",
          "name": "BOJONGKEMBAR"
        },
        {
          "id": "3202140007",
          "district_id": "3202140",
          "name": "CIKEMBAR"
        },
        {
          "id": "3202140008",
          "district_id": "3202140",
          "name": "CIMANGGU"
        },
        {
          "id": "3202140009",
          "district_id": "3202140",
          "name": "SUKAMULYA"
        },
        {
          "id": "3202140010",
          "district_id": "3202140",
          "name": "BOJONGRAHARJA"
        },
        {
          "id": "3202150001",
          "district_id": "3202150",
          "name": "CISITU"
        },
        {
          "id": "3202150002",
          "district_id": "3202150",
          "name": "NYALINDUNG"
        },
        {
          "id": "3202150003",
          "district_id": "3202150",
          "name": "KERTAANGSANA"
        },
        {
          "id": "3202150004",
          "district_id": "3202150",
          "name": "MEKARSARI"
        },
        {
          "id": "3202150005",
          "district_id": "3202150",
          "name": "BOJONGKALONG"
        },
        {
          "id": "3202150006",
          "district_id": "3202150",
          "name": "BOJONGSARI"
        },
        {
          "id": "3202150007",
          "district_id": "3202150",
          "name": "SUKAMAJU"
        },
        {
          "id": "3202150008",
          "district_id": "3202150",
          "name": "WANGUNREJA"
        },
        {
          "id": "3202150009",
          "district_id": "3202150",
          "name": "NEGLASARI"
        },
        {
          "id": "3202150010",
          "district_id": "3202150",
          "name": "CIJANGKAR"
        },
        {
          "id": "3202160001",
          "district_id": "3202160",
          "name": "CIENGANG"
        },
        {
          "id": "3202160002",
          "district_id": "3202160",
          "name": "SUKAMANAH"
        },
        {
          "id": "3202160003",
          "district_id": "3202160",
          "name": "GEGERBITUNG"
        },
        {
          "id": "3202160004",
          "district_id": "3202160",
          "name": "CIJUREY"
        },
        {
          "id": "3202160005",
          "district_id": "3202160",
          "name": "KARANGJAYA"
        },
        {
          "id": "3202160006",
          "district_id": "3202160",
          "name": "BUNIWANGI"
        },
        {
          "id": "3202160007",
          "district_id": "3202160",
          "name": "CARINGIN"
        },
        {
          "id": "3202170011",
          "district_id": "3202170",
          "name": "SELAWANGI"
        },
        {
          "id": "3202170012",
          "district_id": "3202170",
          "name": "PASIRHALANG"
        },
        {
          "id": "3202170013",
          "district_id": "3202170",
          "name": "SUKARAJA"
        },
        {
          "id": "3202170014",
          "district_id": "3202170",
          "name": "SUKAMEKAR"
        },
        {
          "id": "3202170015",
          "district_id": "3202170",
          "name": "CISARUA"
        },
        {
          "id": "3202170016",
          "district_id": "3202170",
          "name": "LIMBANGAN"
        },
        {
          "id": "3202170017",
          "district_id": "3202170",
          "name": "LANGENSARI"
        },
        {
          "id": "3202170018",
          "district_id": "3202170",
          "name": "SELAAWI"
        },
        {
          "id": "3202170021",
          "district_id": "3202170",
          "name": "MARGALUYU"
        },
        {
          "id": "3202171001",
          "district_id": "3202171",
          "name": "SASAGARAN"
        },
        {
          "id": "3202171002",
          "district_id": "3202171",
          "name": "JAMBENENGGANG"
        },
        {
          "id": "3202171003",
          "district_id": "3202171",
          "name": "CIKARET"
        },
        {
          "id": "3202171004",
          "district_id": "3202171",
          "name": "KEBONPEDES"
        },
        {
          "id": "3202171005",
          "district_id": "3202171",
          "name": "BOJONGSAWAH"
        },
        {
          "id": "3202172001",
          "district_id": "3202172",
          "name": "CIPURUT"
        },
        {
          "id": "3202172002",
          "district_id": "3202172",
          "name": "CIREUNGHAS"
        },
        {
          "id": "3202172003",
          "district_id": "3202172",
          "name": "BENCOY"
        },
        {
          "id": "3202172004",
          "district_id": "3202172",
          "name": "CIKURUTUG"
        },
        {
          "id": "3202172005",
          "district_id": "3202172",
          "name": "TEGALPANJANG"
        },
        {
          "id": "3202173001",
          "district_id": "3202173",
          "name": "SEMPLAK"
        },
        {
          "id": "3202173002",
          "district_id": "3202173",
          "name": "PRIANGANJAYA"
        },
        {
          "id": "3202173003",
          "district_id": "3202173",
          "name": "TITISAN"
        },
        {
          "id": "3202173004",
          "district_id": "3202173",
          "name": "CIMANGKOK"
        },
        {
          "id": "3202173005",
          "district_id": "3202173",
          "name": "SUKALARANG"
        },
        {
          "id": "3202173006",
          "district_id": "3202173",
          "name": "SUKAMAJU"
        },
        {
          "id": "3202180001",
          "district_id": "3202180",
          "name": "PARUNGSEAH"
        },
        {
          "id": "3202180002",
          "district_id": "3202180",
          "name": "WARNASARI"
        },
        {
          "id": "3202180003",
          "district_id": "3202180",
          "name": "SUKAJAYA"
        },
        {
          "id": "3202180004",
          "district_id": "3202180",
          "name": "SUDAJAYA GIRANG"
        },
        {
          "id": "3202180005",
          "district_id": "3202180",
          "name": "KARAWANG"
        },
        {
          "id": "3202180006",
          "district_id": "3202180",
          "name": "PERBAWATI"
        },
        {
          "id": "3202190001",
          "district_id": "3202190",
          "name": "MUARADUA"
        },
        {
          "id": "3202190002",
          "district_id": "3202190",
          "name": "CITAMIANG"
        },
        {
          "id": "3202190003",
          "district_id": "3202190",
          "name": "CIKAHURIPAN"
        },
        {
          "id": "3202190004",
          "district_id": "3202190",
          "name": "SUKAMANIS"
        },
        {
          "id": "3202190005",
          "district_id": "3202190",
          "name": "KADUDAMPIT"
        },
        {
          "id": "3202190006",
          "district_id": "3202190",
          "name": "GEDE PANGRANGO"
        },
        {
          "id": "3202190007",
          "district_id": "3202190",
          "name": "SUKAMAJU"
        },
        {
          "id": "3202190008",
          "district_id": "3202190",
          "name": "CIPETIR"
        },
        {
          "id": "3202190009",
          "district_id": "3202190",
          "name": "UNDRUS BINANGUN"
        },
        {
          "id": "3202200007",
          "district_id": "3202200",
          "name": "PADAASIH"
        },
        {
          "id": "3202200008",
          "district_id": "3202200",
          "name": "CISAAT"
        },
        {
          "id": "3202200009",
          "district_id": "3202200",
          "name": "BABAKAN"
        },
        {
          "id": "3202200010",
          "district_id": "3202200",
          "name": "SUKAMANTRI"
        },
        {
          "id": "3202200011",
          "district_id": "3202200",
          "name": "SUKAMANAH"
        },
        {
          "id": "3202200012",
          "district_id": "3202200",
          "name": "NAGRAK"
        },
        {
          "id": "3202200013",
          "district_id": "3202200",
          "name": "CIBATU"
        },
        {
          "id": "3202200014",
          "district_id": "3202200",
          "name": "CIBOLANG KALER"
        },
        {
          "id": "3202200015",
          "district_id": "3202200",
          "name": "KUTASIRNA"
        },
        {
          "id": "3202200016",
          "district_id": "3202200",
          "name": "SELAJAMBE"
        },
        {
          "id": "3202200017",
          "district_id": "3202200",
          "name": "SUKASARI"
        },
        {
          "id": "3202200018",
          "district_id": "3202200",
          "name": "GUNUNGJAYA"
        },
        {
          "id": "3202200019",
          "district_id": "3202200",
          "name": "SUKARESMI"
        },
        {
          "id": "3202201001",
          "district_id": "3202201",
          "name": "SIRNARESMI"
        },
        {
          "id": "3202201002",
          "district_id": "3202201",
          "name": "KEBONMANGGU"
        },
        {
          "id": "3202201004",
          "district_id": "3202201",
          "name": "CIKUJANG"
        },
        {
          "id": "3202201005",
          "district_id": "3202201",
          "name": "CIBENTANG"
        },
        {
          "id": "3202201006",
          "district_id": "3202201",
          "name": "CIBOLANG"
        },
        {
          "id": "3202201007",
          "district_id": "3202201",
          "name": "MANGKALAYA"
        },
        {
          "id": "3202210001",
          "district_id": "3202210",
          "name": "NEGLASARI"
        },
        {
          "id": "3202210002",
          "district_id": "3202210",
          "name": "TENJOJAYA"
        },
        {
          "id": "3202210003",
          "district_id": "3202210",
          "name": "SEKARWANGI"
        },
        {
          "id": "3202210009",
          "district_id": "3202210",
          "name": "BATUNUNGGAL"
        },
        {
          "id": "3202210010",
          "district_id": "3202210",
          "name": "KARANGTENGAH"
        },
        {
          "id": "3202210011",
          "district_id": "3202210",
          "name": "CIBADAK"
        },
        {
          "id": "3202210012",
          "district_id": "3202210",
          "name": "WARNAJATI"
        },
        {
          "id": "3202210013",
          "district_id": "3202210",
          "name": "SUKASIRNA"
        },
        {
          "id": "3202210014",
          "district_id": "3202210",
          "name": "PAMURUYAN"
        },
        {
          "id": "3202210015",
          "district_id": "3202210",
          "name": "CIHEULANG TONGGOH"
        },
        {
          "id": "3202211001",
          "district_id": "3202211",
          "name": "HEGARMANAH"
        },
        {
          "id": "3202211002",
          "district_id": "3202211",
          "name": "CICANTAYAN"
        },
        {
          "id": "3202211003",
          "district_id": "3202211",
          "name": "CIMAHI"
        },
        {
          "id": "3202211004",
          "district_id": "3202211",
          "name": "CISANDE"
        },
        {
          "id": "3202211005",
          "district_id": "3202211",
          "name": "CIJALINGAN"
        },
        {
          "id": "3202211006",
          "district_id": "3202211",
          "name": "LEMBURSAWAH"
        },
        {
          "id": "3202211007",
          "district_id": "3202211",
          "name": "SUKADAMAI"
        },
        {
          "id": "3202211008",
          "district_id": "3202211",
          "name": "CIMANGGIS"
        },
        {
          "id": "3202212001",
          "district_id": "3202212",
          "name": "CIJENGKOL"
        },
        {
          "id": "3202212002",
          "district_id": "3202212",
          "name": "MEKARJAYA"
        },
        {
          "id": "3202212003",
          "district_id": "3202212",
          "name": "TALAGA"
        },
        {
          "id": "3202212004",
          "district_id": "3202212",
          "name": "CARINGIN KULON"
        },
        {
          "id": "3202212005",
          "district_id": "3202212",
          "name": "CARINGIN WETAN"
        },
        {
          "id": "3202212006",
          "district_id": "3202212",
          "name": "SEUSEUPAN"
        },
        {
          "id": "3202212007",
          "district_id": "3202212",
          "name": "SUKAMULYA"
        },
        {
          "id": "3202212008",
          "district_id": "3202212",
          "name": "CIKEMBANG"
        },
        {
          "id": "3202212009",
          "district_id": "3202212",
          "name": "PASIRDATAR INDAH"
        },
        {
          "id": "3202220001",
          "district_id": "3202220",
          "name": "CISARUA"
        },
        {
          "id": "3202220002",
          "district_id": "3202220",
          "name": "BALEKAMBANG"
        },
        {
          "id": "3202220003",
          "district_id": "3202220",
          "name": "NAGRAK SELATAN"
        },
        {
          "id": "3202220004",
          "district_id": "3202220",
          "name": "NAGRAK UTARA"
        },
        {
          "id": "3202220005",
          "district_id": "3202220",
          "name": "KALAPAREA"
        },
        {
          "id": "3202220006",
          "district_id": "3202220",
          "name": "DARMAREJA"
        },
        {
          "id": "3202220007",
          "district_id": "3202220",
          "name": "GIRIJAYA"
        },
        {
          "id": "3202220008",
          "district_id": "3202220",
          "name": "BABAKAN PANJANG"
        },
        {
          "id": "3202220009",
          "district_id": "3202220",
          "name": "PAWENANG"
        },
        {
          "id": "3202220010",
          "district_id": "3202220",
          "name": "CIHANYAWAR"
        },
        {
          "id": "3202221002",
          "district_id": "3202221",
          "name": "CIAMBAR"
        },
        {
          "id": "3202221003",
          "district_id": "3202221",
          "name": "GINANJAR"
        },
        {
          "id": "3202221004",
          "district_id": "3202221",
          "name": "WANGUNJAYA"
        },
        {
          "id": "3202221005",
          "district_id": "3202221",
          "name": "CIBUNARJAYA"
        },
        {
          "id": "3202221006",
          "district_id": "3202221",
          "name": "AMBARJAYA"
        },
        {
          "id": "3202230001",
          "district_id": "3202230",
          "name": "MEKARSARI"
        },
        {
          "id": "3202230002",
          "district_id": "3202230",
          "name": "NYANGKOWEK"
        },
        {
          "id": "3202230003",
          "district_id": "3202230",
          "name": "PURWASARI"
        },
        {
          "id": "3202230004",
          "district_id": "3202230",
          "name": "CARINGIN"
        },
        {
          "id": "3202230005",
          "district_id": "3202230",
          "name": "BANGBAYANG"
        },
        {
          "id": "3202230006",
          "district_id": "3202230",
          "name": "CISAAT"
        },
        {
          "id": "3202230007",
          "district_id": "3202230",
          "name": "TENJOLAYA"
        },
        {
          "id": "3202230008",
          "district_id": "3202230",
          "name": "PASAWAHAN"
        },
        {
          "id": "3202230009",
          "district_id": "3202230",
          "name": "CICURUG"
        },
        {
          "id": "3202230010",
          "district_id": "3202230",
          "name": "NANGGERANG"
        },
        {
          "id": "3202230011",
          "district_id": "3202230",
          "name": "TENJOAYU"
        },
        {
          "id": "3202230012",
          "district_id": "3202230",
          "name": "BENDA"
        },
        {
          "id": "3202230013",
          "district_id": "3202230",
          "name": "KUTAJAYA"
        },
        {
          "id": "3202240001",
          "district_id": "3202240",
          "name": "PONDOK KASO TENGAH"
        },
        {
          "id": "3202240002",
          "district_id": "3202240",
          "name": "PASIRDOTON"
        },
        {
          "id": "3202240003",
          "district_id": "3202240",
          "name": "PONDOK KASO TONGGOH"
        },
        {
          "id": "3202240004",
          "district_id": "3202240",
          "name": "BABAKAN PARI"
        },
        {
          "id": "3202240005",
          "district_id": "3202240",
          "name": "TANGKIL"
        },
        {
          "id": "3202240006",
          "district_id": "3202240",
          "name": "JAYABAKTI"
        },
        {
          "id": "3202240007",
          "district_id": "3202240",
          "name": "CIDAHU"
        },
        {
          "id": "3202240008",
          "district_id": "3202240",
          "name": "GIRIJAYA"
        },
        {
          "id": "3202250001",
          "district_id": "3202250",
          "name": "SUKATANI"
        },
        {
          "id": "3202250002",
          "district_id": "3202250",
          "name": "SUKAKERSA"
        },
        {
          "id": "3202250003",
          "district_id": "3202250",
          "name": "BOJONGLONGOK"
        },
        {
          "id": "3202250004",
          "district_id": "3202250",
          "name": "BOJONGASIH"
        },
        {
          "id": "3202250005",
          "district_id": "3202250",
          "name": "LEBAKSARI"
        },
        {
          "id": "3202250006",
          "district_id": "3202250",
          "name": "PARAKANSALAK"
        },
        {
          "id": "3202260002",
          "district_id": "3202260",
          "name": "PALASARI HILIR"
        },
        {
          "id": "3202260003",
          "district_id": "3202260",
          "name": "SUNDAWENANG"
        },
        {
          "id": "3202260004",
          "district_id": "3202260",
          "name": "PARUNGKUDA"
        },
        {
          "id": "3202260008",
          "district_id": "3202260",
          "name": "LANGENSARI"
        },
        {
          "id": "3202260009",
          "district_id": "3202260",
          "name": "BOJONG KOKOSAN"
        },
        {
          "id": "3202260010",
          "district_id": "3202260",
          "name": "KOMPA"
        },
        {
          "id": "3202260011",
          "district_id": "3202260",
          "name": "PONDOK KASO LANDEUH"
        },
        {
          "id": "3202260012",
          "district_id": "3202260",
          "name": "BABAKANJAYA"
        },
        {
          "id": "3202261001",
          "district_id": "3202261",
          "name": "BEREKAH"
        },
        {
          "id": "3202261002",
          "district_id": "3202261",
          "name": "BOJONG GENTENG"
        },
        {
          "id": "3202261003",
          "district_id": "3202261",
          "name": "BOJONG GALING"
        },
        {
          "id": "3202261004",
          "district_id": "3202261",
          "name": "CIBODAS"
        },
        {
          "id": "3202261005",
          "district_id": "3202261",
          "name": "CIPANENGAH"
        },
        {
          "id": "3202270001",
          "district_id": "3202270",
          "name": "WALANGSARI"
        },
        {
          "id": "3202270002",
          "district_id": "3202270",
          "name": "PALASARI GIRANG"
        },
        {
          "id": "3202270003",
          "district_id": "3202270",
          "name": "KALAPANUNGGAL"
        },
        {
          "id": "3202270004",
          "district_id": "3202270",
          "name": "KADUNUNGGAL"
        },
        {
          "id": "3202270005",
          "district_id": "3202270",
          "name": "MAKASARI"
        },
        {
          "id": "3202270007",
          "district_id": "3202270",
          "name": "PULOSARI"
        },
        {
          "id": "3202280001",
          "district_id": "3202280",
          "name": "SAMPORA"
        },
        {
          "id": "3202280002",
          "district_id": "3202280",
          "name": "CIJAMBE"
        },
        {
          "id": "3202280003",
          "district_id": "3202280",
          "name": "CIKIRAY"
        },
        {
          "id": "3202280004",
          "district_id": "3202280",
          "name": "MEKARNANGKA"
        },
        {
          "id": "3202280005",
          "district_id": "3202280",
          "name": "CIKIDANG"
        },
        {
          "id": "3202280006",
          "district_id": "3202280",
          "name": "GUNUNG MALANG"
        },
        {
          "id": "3202280007",
          "district_id": "3202280",
          "name": "NANGKAKONENG"
        },
        {
          "id": "3202280008",
          "district_id": "3202280",
          "name": "PANGKALAN"
        },
        {
          "id": "3202280009",
          "district_id": "3202280",
          "name": "BUMISARI"
        },
        {
          "id": "3202280010",
          "district_id": "3202280",
          "name": "CICAREUH"
        },
        {
          "id": "3202280011",
          "district_id": "3202280",
          "name": "TAMANSARI"
        },
        {
          "id": "3202280012",
          "district_id": "3202280",
          "name": "CIKARAE THOYIBAH"
        },
        {
          "id": "3202290001",
          "district_id": "3202290",
          "name": "PASIRBARU"
        },
        {
          "id": "3202290002",
          "district_id": "3202290",
          "name": "CIKAHURIPAN"
        },
        {
          "id": "3202290003",
          "district_id": "3202290",
          "name": "CISOLOK"
        },
        {
          "id": "3202290004",
          "district_id": "3202290",
          "name": "KARANGPAPAK"
        },
        {
          "id": "3202290012",
          "district_id": "3202290",
          "name": "SIRNARESMI"
        },
        {
          "id": "3202290013",
          "district_id": "3202290",
          "name": "CICADAS"
        },
        {
          "id": "3202290014",
          "district_id": "3202290",
          "name": "CIKELAT"
        },
        {
          "id": "3202290015",
          "district_id": "3202290",
          "name": "GUNUNG KRAMAT"
        },
        {
          "id": "3202290016",
          "district_id": "3202290",
          "name": "GUNUNG TANJUNG"
        },
        {
          "id": "3202290017",
          "district_id": "3202290",
          "name": "CARINGIN"
        },
        {
          "id": "3202290018",
          "district_id": "3202290",
          "name": "SUKARAME"
        },
        {
          "id": "3202290019",
          "district_id": "3202290",
          "name": "WANGUNSARI"
        },
        {
          "id": "3202290020",
          "district_id": "3202290",
          "name": "WANAJAYA"
        },
        {
          "id": "3202291001",
          "district_id": "3202291",
          "name": "CIMAJA"
        },
        {
          "id": "3202291002",
          "district_id": "3202291",
          "name": "CIKAKAK"
        },
        {
          "id": "3202291003",
          "district_id": "3202291",
          "name": "SUKAMAJU"
        },
        {
          "id": "3202291004",
          "district_id": "3202291",
          "name": "CILEUNGSING"
        },
        {
          "id": "3202291005",
          "district_id": "3202291",
          "name": "RIDOGALIH"
        },
        {
          "id": "3202291006",
          "district_id": "3202291",
          "name": "MARGALAKSANA"
        },
        {
          "id": "3202291007",
          "district_id": "3202291",
          "name": "SIRNARASA"
        },
        {
          "id": "3202291008",
          "district_id": "3202291",
          "name": "GANDASOLI"
        },
        {
          "id": "3202291009",
          "district_id": "3202291",
          "name": "CIRENDANG"
        },
        {
          "id": "3202300001",
          "district_id": "3202300",
          "name": "MEKARJAYA"
        },
        {
          "id": "3202300002",
          "district_id": "3202300",
          "name": "TUGUBANDUNG"
        },
        {
          "id": "3202300003",
          "district_id": "3202300",
          "name": "KABANDUNGAN"
        },
        {
          "id": "3202300004",
          "district_id": "3202300",
          "name": "CIPEUTEUY"
        },
        {
          "id": "3202300005",
          "district_id": "3202300",
          "name": "CIHAMERANG"
        },
        {
          "id": "3202300006",
          "district_id": "3202300",
          "name": "CIANAGA"
        },
        {
          "id": "3203010001",
          "district_id": "3203010",
          "name": "SINARLAUT"
        },
        {
          "id": "3203010002",
          "district_id": "3203010",
          "name": "BOJONGKASO"
        },
        {
          "id": "3203010003",
          "district_id": "3203010",
          "name": "SUKAMANAH"
        },
        {
          "id": "3203010004",
          "district_id": "3203010",
          "name": "WANASARI"
        },
        {
          "id": "3203010007",
          "district_id": "3203010",
          "name": "KARANGSARI"
        },
        {
          "id": "3203010008",
          "district_id": "3203010",
          "name": "NEGLASARI"
        },
        {
          "id": "3203010009",
          "district_id": "3203010",
          "name": "MULYASARI"
        },
        {
          "id": "3203010010",
          "district_id": "3203010",
          "name": "BUNISARI"
        },
        {
          "id": "3203010011",
          "district_id": "3203010",
          "name": "MEKARSARI"
        },
        {
          "id": "3203010012",
          "district_id": "3203010",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3203010013",
          "district_id": "3203010",
          "name": "WANGUNJAYA"
        },
        {
          "id": "3203011001",
          "district_id": "3203011",
          "name": "PUSAKASARI"
        },
        {
          "id": "3203011002",
          "district_id": "3203011",
          "name": "NAGASARI"
        },
        {
          "id": "3203011003",
          "district_id": "3203011",
          "name": "SUKAJAYA"
        },
        {
          "id": "3203011004",
          "district_id": "3203011",
          "name": "SUKAMULYA"
        },
        {
          "id": "3203011005",
          "district_id": "3203011",
          "name": "PURABAYA"
        },
        {
          "id": "3203011006",
          "district_id": "3203011",
          "name": "SUKASIRNA"
        },
        {
          "id": "3203011007",
          "district_id": "3203011",
          "name": "WALAHIR"
        },
        {
          "id": "3203011008",
          "district_id": "3203011",
          "name": "PUNCAKWANGI"
        },
        {
          "id": "3203011009",
          "district_id": "3203011",
          "name": "SIRNASARI"
        },
        {
          "id": "3203011010",
          "district_id": "3203011",
          "name": "KARYAMUKTI"
        },
        {
          "id": "3203011011",
          "district_id": "3203011",
          "name": "MANDALAWANGI"
        },
        {
          "id": "3203011012",
          "district_id": "3203011",
          "name": "SINDANGSARI"
        },
        {
          "id": "3203020001",
          "district_id": "3203020",
          "name": "HEGARSARI"
        },
        {
          "id": "3203020002",
          "district_id": "3203020",
          "name": "JATISARI"
        },
        {
          "id": "3203020003",
          "district_id": "3203020",
          "name": "KERTASARI"
        },
        {
          "id": "3203020004",
          "district_id": "3203020",
          "name": "TALAGASARI"
        },
        {
          "id": "3203020005",
          "district_id": "3203020",
          "name": "SIRNAGALIH"
        },
        {
          "id": "3203020006",
          "district_id": "3203020",
          "name": "SAGANTEN"
        },
        {
          "id": "3203020007",
          "district_id": "3203020",
          "name": "JAYAGIRI"
        },
        {
          "id": "3203020008",
          "district_id": "3203020",
          "name": "MUARACIKADU"
        },
        {
          "id": "3203020009",
          "district_id": "3203020",
          "name": "GIRIMUKTI"
        },
        {
          "id": "3203020010",
          "district_id": "3203020",
          "name": "MEKARLAKSANA"
        },
        {
          "id": "3203020011",
          "district_id": "3203020",
          "name": "KERTAMUKTI"
        },
        {
          "id": "3203030001",
          "district_id": "3203030",
          "name": "KARYABAKTI"
        },
        {
          "id": "3203030002",
          "district_id": "3203030",
          "name": "SUKAPURA"
        },
        {
          "id": "3203030003",
          "district_id": "3203030",
          "name": "CISALAK"
        },
        {
          "id": "3203030004",
          "district_id": "3203030",
          "name": "JAYAPURA"
        },
        {
          "id": "3203030005",
          "district_id": "3203030",
          "name": "KERTAJADI"
        },
        {
          "id": "3203030006",
          "district_id": "3203030",
          "name": "CIDAMAR"
        },
        {
          "id": "3203030007",
          "district_id": "3203030",
          "name": "KARANGWANGI"
        },
        {
          "id": "3203030008",
          "district_id": "3203030",
          "name": "CIMARAGANG"
        },
        {
          "id": "3203030009",
          "district_id": "3203030",
          "name": "GELARPAWITAN"
        },
        {
          "id": "3203030010",
          "district_id": "3203030",
          "name": "NEGLASARI"
        },
        {
          "id": "3203030011",
          "district_id": "3203030",
          "name": "CIBULUH"
        },
        {
          "id": "3203030012",
          "district_id": "3203030",
          "name": "PUNCAKBARU"
        },
        {
          "id": "3203030013",
          "district_id": "3203030",
          "name": "MEKARJAYA"
        },
        {
          "id": "3203030014",
          "district_id": "3203030",
          "name": "GELARWANGI"
        },
        {
          "id": "3203040001",
          "district_id": "3203040",
          "name": "CINERANG"
        },
        {
          "id": "3203040002",
          "district_id": "3203040",
          "name": "WANGUNJAYA"
        },
        {
          "id": "3203040003",
          "district_id": "3203040",
          "name": "MEKARSARI"
        },
        {
          "id": "3203040004",
          "district_id": "3203040",
          "name": "WANGUNSARI"
        },
        {
          "id": "3203040005",
          "district_id": "3203040",
          "name": "MALATI"
        },
        {
          "id": "3203040006",
          "district_id": "3203040",
          "name": "SUKAMULYA"
        },
        {
          "id": "3203040007",
          "district_id": "3203040",
          "name": "NARINGGUL"
        },
        {
          "id": "3203040008",
          "district_id": "3203040",
          "name": "WANASARI"
        },
        {
          "id": "3203040009",
          "district_id": "3203040",
          "name": "SUKABAKTI"
        },
        {
          "id": "3203040010",
          "district_id": "3203040",
          "name": "BALEGEDE"
        },
        {
          "id": "3203040011",
          "district_id": "3203040",
          "name": "MARGASARI"
        },
        {
          "id": "3203050001",
          "district_id": "3203050",
          "name": "PANYINDANGAN"
        },
        {
          "id": "3203050002",
          "district_id": "3203050",
          "name": "WARGALUYU"
        },
        {
          "id": "3203050003",
          "district_id": "3203050",
          "name": "HAMERANG"
        },
        {
          "id": "3203050004",
          "district_id": "3203050",
          "name": "PANANGGAPAN"
        },
        {
          "id": "3203050005",
          "district_id": "3203050",
          "name": "GIRIJAYA"
        },
        {
          "id": "3203050006",
          "district_id": "3203050",
          "name": "SUKAJADI"
        },
        {
          "id": "3203050007",
          "district_id": "3203050",
          "name": "SUKAMEKAR"
        },
        {
          "id": "3203050008",
          "district_id": "3203050",
          "name": "BATULAWANG"
        },
        {
          "id": "3203050009",
          "district_id": "3203050",
          "name": "CIKANGKARENG"
        },
        {
          "id": "3203050015",
          "district_id": "3203050",
          "name": "PAMOYANAN"
        },
        {
          "id": "3203050016",
          "district_id": "3203050",
          "name": "CIMASKARA"
        },
        {
          "id": "3203050020",
          "district_id": "3203050",
          "name": "PADASUKA"
        },
        {
          "id": "3203050021",
          "district_id": "3203050",
          "name": "MEKARMUKTI"
        },
        {
          "id": "3203050022",
          "district_id": "3203050",
          "name": "CIBURIAL"
        },
        {
          "id": "3203051001",
          "district_id": "3203051",
          "name": "PADALUYU"
        },
        {
          "id": "3203051002",
          "district_id": "3203051",
          "name": "SUKALUYU"
        },
        {
          "id": "3203051003",
          "district_id": "3203051",
          "name": "MEKARLAKSANA"
        },
        {
          "id": "3203051004",
          "district_id": "3203051",
          "name": "CIKADU"
        },
        {
          "id": "3203051005",
          "district_id": "3203051",
          "name": "KALAPANUNGGAL"
        },
        {
          "id": "3203051006",
          "district_id": "3203051",
          "name": "MEKARWANGI"
        },
        {
          "id": "3203051007",
          "district_id": "3203051",
          "name": "CISARANTEN"
        },
        {
          "id": "3203051008",
          "district_id": "3203051",
          "name": "SUKAMULYA"
        },
        {
          "id": "3203051009",
          "district_id": "3203051",
          "name": "MEKARJAYA"
        },
        {
          "id": "3203051010",
          "district_id": "3203051",
          "name": "SUKAMANAH"
        },
        {
          "id": "3203060001",
          "district_id": "3203060",
          "name": "KARANGTENGAH"
        },
        {
          "id": "3203060002",
          "district_id": "3203060",
          "name": "RAWAGEDE"
        },
        {
          "id": "3203060003",
          "district_id": "3203060",
          "name": "SUKAJAYA"
        },
        {
          "id": "3203060004",
          "district_id": "3203060",
          "name": "TANGGEUNG"
        },
        {
          "id": "3203060005",
          "district_id": "3203060",
          "name": "KERTAJAYA"
        },
        {
          "id": "3203060006",
          "district_id": "3203060",
          "name": "SIRNAJAYA"
        },
        {
          "id": "3203060007",
          "district_id": "3203060",
          "name": "PASIR JAMBU"
        },
        {
          "id": "3203060008",
          "district_id": "3203060",
          "name": "CILONGSONG"
        },
        {
          "id": "3203060009",
          "district_id": "3203060",
          "name": "MARGALUYU"
        },
        {
          "id": "3203060010",
          "district_id": "3203060",
          "name": "PAGERMANEUH"
        },
        {
          "id": "3203060011",
          "district_id": "3203060",
          "name": "BOJONGPETIR"
        },
        {
          "id": "3203060012",
          "district_id": "3203060",
          "name": "PADALUYU"
        },
        {
          "id": "3203061001",
          "district_id": "3203061",
          "name": "MEKARMULYA"
        },
        {
          "id": "3203061002",
          "district_id": "3203061",
          "name": "KUBANG"
        },
        {
          "id": "3203061003",
          "district_id": "3203061",
          "name": "GIRIJAYA"
        },
        {
          "id": "3203061004",
          "district_id": "3203061",
          "name": "GIRIMUKTI"
        },
        {
          "id": "3203061005",
          "district_id": "3203061",
          "name": "SIMPANG"
        },
        {
          "id": "3203061006",
          "district_id": "3203061",
          "name": "KALIBARU"
        },
        {
          "id": "3203061007",
          "district_id": "3203061",
          "name": "PUSAKAJAYA"
        },
        {
          "id": "3203061008",
          "district_id": "3203061",
          "name": "KARANGJAYA"
        },
        {
          "id": "3203061009",
          "district_id": "3203061",
          "name": "PADAMULYA"
        },
        {
          "id": "3203070010",
          "district_id": "3203070",
          "name": "PASIRDALEM"
        },
        {
          "id": "3203070011",
          "district_id": "3203070",
          "name": "SUKARAJA"
        },
        {
          "id": "3203070012",
          "district_id": "3203070",
          "name": "KADUPANDAK"
        },
        {
          "id": "3203070013",
          "district_id": "3203070",
          "name": "TALAGASARI"
        },
        {
          "id": "3203070014",
          "district_id": "3203070",
          "name": "NEGLASARI"
        },
        {
          "id": "3203070015",
          "district_id": "3203070",
          "name": "SUKAKERTA"
        },
        {
          "id": "3203070016",
          "district_id": "3203070",
          "name": "SUKARAHARJA"
        },
        {
          "id": "3203070017",
          "district_id": "3203070",
          "name": "BOJONGKASIH"
        },
        {
          "id": "3203070018",
          "district_id": "3203070",
          "name": "SUKASARI"
        },
        {
          "id": "3203070019",
          "district_id": "3203070",
          "name": "WARGASARI"
        },
        {
          "id": "3203070020",
          "district_id": "3203070",
          "name": "WARGAASIH"
        },
        {
          "id": "3203070021",
          "district_id": "3203070",
          "name": "SUKARESMI"
        },
        {
          "id": "3203070022",
          "district_id": "3203070",
          "name": "GANDASARI"
        },
        {
          "id": "3203070023",
          "district_id": "3203070",
          "name": "SINDANGSARI"
        },
        {
          "id": "3203071001",
          "district_id": "3203071",
          "name": "PADAASIH"
        },
        {
          "id": "3203071002",
          "district_id": "3203071",
          "name": "SUKALUYU"
        },
        {
          "id": "3203071003",
          "district_id": "3203071",
          "name": "SINARBAKTI"
        },
        {
          "id": "3203071004",
          "district_id": "3203071",
          "name": "BOJONGLARANG"
        },
        {
          "id": "3203071005",
          "district_id": "3203071",
          "name": "SUKAMAHI"
        },
        {
          "id": "3203071006",
          "district_id": "3203071",
          "name": "CIJATI"
        },
        {
          "id": "3203071007",
          "district_id": "3203071",
          "name": "CIBODAS"
        },
        {
          "id": "3203071008",
          "district_id": "3203071",
          "name": "CARINGIN"
        },
        {
          "id": "3203071009",
          "district_id": "3203071",
          "name": "PARAKANTUGU"
        },
        {
          "id": "3203071010",
          "district_id": "3203071",
          "name": "SUKAMAJU"
        },
        {
          "id": "3203080001",
          "district_id": "3203080",
          "name": "WARINGINSARI"
        },
        {
          "id": "3203080002",
          "district_id": "3203080",
          "name": "SUKAGALIH"
        },
        {
          "id": "3203080003",
          "district_id": "3203080",
          "name": "SIMPANG"
        },
        {
          "id": "3203080004",
          "district_id": "3203080",
          "name": "SINDANGHAYU"
        },
        {
          "id": "3203080005",
          "district_id": "3203080",
          "name": "SINDANGRESMI"
        },
        {
          "id": "3203080006",
          "district_id": "3203080",
          "name": "BUNGBANGSARI"
        },
        {
          "id": "3203080007",
          "district_id": "3203080",
          "name": "CISUJEN"
        },
        {
          "id": "3203080008",
          "district_id": "3203080",
          "name": "PASAWAHAN"
        },
        {
          "id": "3203080009",
          "district_id": "3203080",
          "name": "HEGARMANAH"
        },
        {
          "id": "3203090001",
          "district_id": "3203090",
          "name": "JAYAGIRI"
        },
        {
          "id": "3203090002",
          "district_id": "3203090",
          "name": "CIGUHA"
        },
        {
          "id": "3203090003",
          "district_id": "3203090",
          "name": "SUKAKARYA"
        },
        {
          "id": "3203090004",
          "district_id": "3203090",
          "name": "SUKARAME"
        },
        {
          "id": "3203090005",
          "district_id": "3203090",
          "name": "SUKALAKSANA"
        },
        {
          "id": "3203090006",
          "district_id": "3203090",
          "name": "SUKANAGARA"
        },
        {
          "id": "3203090007",
          "district_id": "3203090",
          "name": "GUNUNGSARI"
        },
        {
          "id": "3203090008",
          "district_id": "3203090",
          "name": "SINDANGSARI"
        },
        {
          "id": "3203090009",
          "district_id": "3203090",
          "name": "SUKAJEMBAR"
        },
        {
          "id": "3203090010",
          "district_id": "3203090",
          "name": "SUKAMEKAR"
        },
        {
          "id": "3203100001",
          "district_id": "3203100",
          "name": "KERTARAHARJA"
        },
        {
          "id": "3203100002",
          "district_id": "3203100",
          "name": "PAGELARAN"
        },
        {
          "id": "3203100003",
          "district_id": "3203100",
          "name": "PADAMAJU"
        },
        {
          "id": "3203100008",
          "district_id": "3203100",
          "name": "BUNIWANGI"
        },
        {
          "id": "3203100009",
          "district_id": "3203100",
          "name": "BUNIJAYA"
        },
        {
          "id": "3203100010",
          "district_id": "3203100",
          "name": "PANGADEGAN"
        },
        {
          "id": "3203100011",
          "district_id": "3203100",
          "name": "SITUHIANG"
        },
        {
          "id": "3203100012",
          "district_id": "3203100",
          "name": "PASIRBARU"
        },
        {
          "id": "3203100013",
          "district_id": "3203100",
          "name": "SINDANGKERTA"
        },
        {
          "id": "3203100014",
          "district_id": "3203100",
          "name": "KARANGHARJA"
        },
        {
          "id": "3203100015",
          "district_id": "3203100",
          "name": "SELAGEDANG"
        },
        {
          "id": "3203100016",
          "district_id": "3203100",
          "name": "GELAR ANYAR"
        },
        {
          "id": "3203100017",
          "district_id": "3203100",
          "name": "MEKARSARI"
        },
        {
          "id": "3203100018",
          "district_id": "3203100",
          "name": "SUKAMAJU"
        },
        {
          "id": "3203110001",
          "district_id": "3203110",
          "name": "WANGUNJAYA"
        },
        {
          "id": "3203110002",
          "district_id": "3203110",
          "name": "SUKADANA"
        },
        {
          "id": "3203110003",
          "district_id": "3203110",
          "name": "KARYAMUKTI"
        },
        {
          "id": "3203110004",
          "district_id": "3203110",
          "name": "CIMENTENG"
        },
        {
          "id": "3203110005",
          "district_id": "3203110",
          "name": "GIRIMUKTI"
        },
        {
          "id": "3203110006",
          "district_id": "3203110",
          "name": "SUSUKAN"
        },
        {
          "id": "3203110007",
          "district_id": "3203110",
          "name": "SUKAJADI"
        },
        {
          "id": "3203110008",
          "district_id": "3203110",
          "name": "MARGALUYU"
        },
        {
          "id": "3203110009",
          "district_id": "3203110",
          "name": "MEKARJAYA"
        },
        {
          "id": "3203110010",
          "district_id": "3203110",
          "name": "CIDADAP"
        },
        {
          "id": "3203110011",
          "district_id": "3203110",
          "name": "CAMPAKA"
        },
        {
          "id": "3203111001",
          "district_id": "3203111",
          "name": "CAMPAKAWARNA"
        },
        {
          "id": "3203111002",
          "district_id": "3203111",
          "name": "CAMPAKAMULYA"
        },
        {
          "id": "3203111003",
          "district_id": "3203111",
          "name": "SUKABUNGAH"
        },
        {
          "id": "3203111004",
          "district_id": "3203111",
          "name": "CIBANGGALA"
        },
        {
          "id": "3203111005",
          "district_id": "3203111",
          "name": "SUKASIRNA"
        },
        {
          "id": "3203120001",
          "district_id": "3203120",
          "name": "CIBOKOR"
        },
        {
          "id": "3203120002",
          "district_id": "3203120",
          "name": "KANOMAN"
        },
        {
          "id": "3203120003",
          "district_id": "3203120",
          "name": "CIPETIR"
        },
        {
          "id": "3203120004",
          "district_id": "3203120",
          "name": "CIKONDANG"
        },
        {
          "id": "3203120005",
          "district_id": "3203120",
          "name": "CIHAUR"
        },
        {
          "id": "3203120006",
          "district_id": "3203120",
          "name": "SUKAMANAH"
        },
        {
          "id": "3203120007",
          "district_id": "3203120",
          "name": "SALAGEDANG"
        },
        {
          "id": "3203120008",
          "district_id": "3203120",
          "name": "CIBADAK"
        },
        {
          "id": "3203120009",
          "district_id": "3203120",
          "name": "GIRIMULYA"
        },
        {
          "id": "3203120010",
          "district_id": "3203120",
          "name": "CIMANGGU"
        },
        {
          "id": "3203120011",
          "district_id": "3203120",
          "name": "CISALAK"
        },
        {
          "id": "3203120012",
          "district_id": "3203120",
          "name": "MAYAK"
        },
        {
          "id": "3203120013",
          "district_id": "3203120",
          "name": "PEUTEUYCONDONG"
        },
        {
          "id": "3203120014",
          "district_id": "3203120",
          "name": "SUKARAHARJA"
        },
        {
          "id": "3203120015",
          "district_id": "3203120",
          "name": "SUKAMAJU"
        },
        {
          "id": "3203120016",
          "district_id": "3203120",
          "name": "CIBAREGBEG"
        },
        {
          "id": "3203120017",
          "district_id": "3203120",
          "name": "KARANGNUNGGAL"
        },
        {
          "id": "3203120018",
          "district_id": "3203120",
          "name": "SALAMNUNGGAL"
        },
        {
          "id": "3203130002",
          "district_id": "3203130",
          "name": "CISARANDI"
        },
        {
          "id": "3203130003",
          "district_id": "3203130",
          "name": "SUKAMULYA"
        },
        {
          "id": "3203130004",
          "district_id": "3203130",
          "name": "CIKAROYA"
        },
        {
          "id": "3203130012",
          "district_id": "3203130",
          "name": "JAMBUDIPA"
        },
        {
          "id": "3203130013",
          "district_id": "3203130",
          "name": "MEKARWANGI"
        },
        {
          "id": "3203130014",
          "district_id": "3203130",
          "name": "TEGALLEGA"
        },
        {
          "id": "3203130015",
          "district_id": "3203130",
          "name": "BUNIKASIH"
        },
        {
          "id": "3203130016",
          "district_id": "3203130",
          "name": "BUNISARI"
        },
        {
          "id": "3203130017",
          "district_id": "3203130",
          "name": "CIEUNDEUR"
        },
        {
          "id": "3203130018",
          "district_id": "3203130",
          "name": "CIWALEN"
        },
        {
          "id": "3203130019",
          "district_id": "3203130",
          "name": "SUKAWANGI"
        },
        {
          "id": "3203131001",
          "district_id": "3203131",
          "name": "CINTAASIH"
        },
        {
          "id": "3203131002",
          "district_id": "3203131",
          "name": "CIKANCANA"
        },
        {
          "id": "3203131003",
          "district_id": "3203131",
          "name": "SUKARATU"
        },
        {
          "id": "3203131004",
          "district_id": "3203131",
          "name": "BANGBAYANG"
        },
        {
          "id": "3203131005",
          "district_id": "3203131",
          "name": "SONGGOM"
        },
        {
          "id": "3203131006",
          "district_id": "3203131",
          "name": "CIKAHURIPAN"
        },
        {
          "id": "3203131007",
          "district_id": "3203131",
          "name": "GEKBRONG"
        },
        {
          "id": "3203131008",
          "district_id": "3203131",
          "name": "KEBONPEUTEUY"
        },
        {
          "id": "3203140001",
          "district_id": "3203140",
          "name": "SUKASARI"
        },
        {
          "id": "3203140002",
          "district_id": "3203140",
          "name": "SUKAKERTA"
        },
        {
          "id": "3203140003",
          "district_id": "3203140",
          "name": "SINDANGSARI"
        },
        {
          "id": "3203140004",
          "district_id": "3203140",
          "name": "MULYASARI"
        },
        {
          "id": "3203140005",
          "district_id": "3203140",
          "name": "CIHARASHAS"
        },
        {
          "id": "3203140006",
          "district_id": "3203140",
          "name": "CIBINONG HILIR"
        },
        {
          "id": "3203140007",
          "district_id": "3203140",
          "name": "SIRNAGALIH"
        },
        {
          "id": "3203140008",
          "district_id": "3203140",
          "name": "RAHONG"
        },
        {
          "id": "3203140010",
          "district_id": "3203140",
          "name": "RANCAGOONG"
        },
        {
          "id": "3203150001",
          "district_id": "3203150",
          "name": "MEKARJAYA"
        },
        {
          "id": "3203150002",
          "district_id": "3203150",
          "name": "PANYUSUHAN"
        },
        {
          "id": "3203150003",
          "district_id": "3203150",
          "name": "SUKALUYU"
        },
        {
          "id": "3203150004",
          "district_id": "3203150",
          "name": "SUKAMULYA"
        },
        {
          "id": "3203150005",
          "district_id": "3203150",
          "name": "BABAKANSARI"
        },
        {
          "id": "3203150006",
          "district_id": "3203150",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3203150007",
          "district_id": "3203150",
          "name": "SELAJAMBE"
        },
        {
          "id": "3203150008",
          "district_id": "3203150",
          "name": "HEGARMANAH"
        },
        {
          "id": "3203150009",
          "district_id": "3203150",
          "name": "SUKASIRNA"
        },
        {
          "id": "3203150010",
          "district_id": "3203150",
          "name": "SINDANGRAJA"
        },
        {
          "id": "3203160001",
          "district_id": "3203160",
          "name": "SUKARAMA"
        },
        {
          "id": "3203160002",
          "district_id": "3203160",
          "name": "SUKAJAYA"
        },
        {
          "id": "3203160003",
          "district_id": "3203160",
          "name": "CIKONDANG"
        },
        {
          "id": "3203160004",
          "district_id": "3203160",
          "name": "JATISARI"
        },
        {
          "id": "3203160005",
          "district_id": "3203160",
          "name": "KEMANG"
        },
        {
          "id": "3203160006",
          "district_id": "3203160",
          "name": "CIBARENGKOK"
        },
        {
          "id": "3203160007",
          "district_id": "3203160",
          "name": "JATI"
        },
        {
          "id": "3203160008",
          "district_id": "3203160",
          "name": "BOJONGPICUNG"
        },
        {
          "id": "3203160009",
          "district_id": "3203160",
          "name": "SUKARATU"
        },
        {
          "id": "3203160012",
          "district_id": "3203160",
          "name": "NEGLASARI"
        },
        {
          "id": "3203160013",
          "district_id": "3203160",
          "name": "HEGARMANAH"
        },
        {
          "id": "3203161001",
          "district_id": "3203161",
          "name": "CIHEA"
        },
        {
          "id": "3203161002",
          "district_id": "3203161",
          "name": "SUKATANI"
        },
        {
          "id": "3203161003",
          "district_id": "3203161",
          "name": "RAMASARI"
        },
        {
          "id": "3203161004",
          "district_id": "3203161",
          "name": "HAURWANGI"
        },
        {
          "id": "3203161005",
          "district_id": "3203161",
          "name": "KERTASARI"
        },
        {
          "id": "3203161006",
          "district_id": "3203161",
          "name": "KERTAMUKTI"
        },
        {
          "id": "3203161007",
          "district_id": "3203161",
          "name": "CIPEUYEUM"
        },
        {
          "id": "3203161008",
          "district_id": "3203161",
          "name": "MEKARWANGI"
        },
        {
          "id": "3203170004",
          "district_id": "3203170",
          "name": "KARANGWANGI"
        },
        {
          "id": "3203170005",
          "district_id": "3203170",
          "name": "GUNUNGSARI"
        },
        {
          "id": "3203170006",
          "district_id": "3203170",
          "name": "KERTAJAYA"
        },
        {
          "id": "3203170007",
          "district_id": "3203170",
          "name": "SINDANGJAYA"
        },
        {
          "id": "3203170008",
          "district_id": "3203170",
          "name": "SINDANGSARI"
        },
        {
          "id": "3203170009",
          "district_id": "3203170",
          "name": "CIBIUK"
        },
        {
          "id": "3203170010",
          "district_id": "3203170",
          "name": "MEKARGALIH"
        },
        {
          "id": "3203170011",
          "district_id": "3203170",
          "name": "CIRANJANG"
        },
        {
          "id": "3203170012",
          "district_id": "3203170",
          "name": "NANGGALAMEKAR"
        },
        {
          "id": "3203180001",
          "district_id": "3203180",
          "name": "MEKARJAYA"
        },
        {
          "id": "3203180002",
          "district_id": "3203180",
          "name": "LEUWIKOJA"
        },
        {
          "id": "3203180003",
          "district_id": "3203180",
          "name": "KUTAWARINGIN"
        },
        {
          "id": "3203180004",
          "district_id": "3203180",
          "name": "SUKAMANAH"
        },
        {
          "id": "3203180005",
          "district_id": "3203180",
          "name": "CIANDAM"
        },
        {
          "id": "3203180006",
          "district_id": "3203180",
          "name": "JAMALI"
        },
        {
          "id": "3203180007",
          "district_id": "3203180",
          "name": "KADEMANGAN"
        },
        {
          "id": "3203180008",
          "district_id": "3203180",
          "name": "MULYASARI"
        },
        {
          "id": "3203180009",
          "district_id": "3203180",
          "name": "BOBOJONG"
        },
        {
          "id": "3203180010",
          "district_id": "3203180",
          "name": "CIKIDANGBAYABANG"
        },
        {
          "id": "3203180011",
          "district_id": "3203180",
          "name": "MURNISARI"
        },
        {
          "id": "3203180012",
          "district_id": "3203180",
          "name": "MANDE"
        },
        {
          "id": "3203190001",
          "district_id": "3203190",
          "name": "SUKAMANAH"
        },
        {
          "id": "3203190002",
          "district_id": "3203190",
          "name": "SINDANGASIH"
        },
        {
          "id": "3203190003",
          "district_id": "3203190",
          "name": "LANGENSARI"
        },
        {
          "id": "3203190004",
          "district_id": "3203190",
          "name": "SUKASARI"
        },
        {
          "id": "3203190005",
          "district_id": "3203190",
          "name": "MALEBER"
        },
        {
          "id": "3203190006",
          "district_id": "3203190",
          "name": "SABANDAR"
        },
        {
          "id": "3203190007",
          "district_id": "3203190",
          "name": "BOJONG"
        },
        {
          "id": "3203190008",
          "district_id": "3203190",
          "name": "HEGARMANAH"
        },
        {
          "id": "3203190009",
          "district_id": "3203190",
          "name": "BABAKANCARINGIN"
        },
        {
          "id": "3203190010",
          "district_id": "3203190",
          "name": "CIHERANG"
        },
        {
          "id": "3203190011",
          "district_id": "3203190",
          "name": "SUKAJADI"
        },
        {
          "id": "3203190012",
          "district_id": "3203190",
          "name": "SUKASARANA"
        },
        {
          "id": "3203190013",
          "district_id": "3203190",
          "name": "SUKAMANTRI"
        },
        {
          "id": "3203190014",
          "district_id": "3203190",
          "name": "SUKAMULYA"
        },
        {
          "id": "3203190015",
          "district_id": "3203190",
          "name": "SINDANGLAKA"
        },
        {
          "id": "3203190016",
          "district_id": "3203190",
          "name": "SUKATARIS"
        },
        {
          "id": "3203200001",
          "district_id": "3203200",
          "name": "NAGRAK"
        },
        {
          "id": "3203200002",
          "district_id": "3203200",
          "name": "SUKAMAJU"
        },
        {
          "id": "3203200003",
          "district_id": "3203200",
          "name": "SAYANG"
        },
        {
          "id": "3203200004",
          "district_id": "3203200",
          "name": "SOLOKPANDAN"
        },
        {
          "id": "3203200005",
          "district_id": "3203200",
          "name": "MUKA"
        },
        {
          "id": "3203200006",
          "district_id": "3203200",
          "name": "BOJONGHERANG"
        },
        {
          "id": "3203200007",
          "district_id": "3203200",
          "name": "PAMOYANAN"
        },
        {
          "id": "3203200008",
          "district_id": "3203200",
          "name": "SAWAH GEDE"
        },
        {
          "id": "3203200009",
          "district_id": "3203200",
          "name": "LIMBANGAN SARI"
        },
        {
          "id": "3203200010",
          "district_id": "3203200",
          "name": "MEKARSARI"
        },
        {
          "id": "3203200011",
          "district_id": "3203200",
          "name": "BABAKANKARET"
        },
        {
          "id": "3203210001",
          "district_id": "3203210",
          "name": "PADALUYU"
        },
        {
          "id": "3203210002",
          "district_id": "3203210",
          "name": "SUKAJAYA"
        },
        {
          "id": "3203210003",
          "district_id": "3203210",
          "name": "CIBULAKAN"
        },
        {
          "id": "3203210004",
          "district_id": "3203210",
          "name": "CIRUMPUT"
        },
        {
          "id": "3203210005",
          "district_id": "3203210",
          "name": "TALAGA"
        },
        {
          "id": "3203210006",
          "district_id": "3203210",
          "name": "BENJOT"
        },
        {
          "id": "3203210007",
          "district_id": "3203210",
          "name": "GASOL"
        },
        {
          "id": "3203210008",
          "district_id": "3203210",
          "name": "SARAMPAD"
        },
        {
          "id": "3203210009",
          "district_id": "3203210",
          "name": "MANGUNKERTA"
        },
        {
          "id": "3203210010",
          "district_id": "3203210",
          "name": "SUKAMULYA"
        },
        {
          "id": "3203210011",
          "district_id": "3203210",
          "name": "GALUDRA"
        },
        {
          "id": "3203210012",
          "district_id": "3203210",
          "name": "NYALINDUNG"
        },
        {
          "id": "3203210013",
          "district_id": "3203210",
          "name": "CIBEUREUM"
        },
        {
          "id": "3203210014",
          "district_id": "3203210",
          "name": "CIJEDIL"
        },
        {
          "id": "3203210015",
          "district_id": "3203210",
          "name": "SUKAMANAH"
        },
        {
          "id": "3203210016",
          "district_id": "3203210",
          "name": "WANGUNJAYA"
        },
        {
          "id": "3203220001",
          "district_id": "3203220",
          "name": "CIPUTRI"
        },
        {
          "id": "3203220002",
          "district_id": "3203220",
          "name": "CIHERANG"
        },
        {
          "id": "3203220003",
          "district_id": "3203220",
          "name": "CIPENDAWA"
        },
        {
          "id": "3203220004",
          "district_id": "3203220",
          "name": "CIBODAS"
        },
        {
          "id": "3203220005",
          "district_id": "3203220",
          "name": "GADOG"
        },
        {
          "id": "3203220008",
          "district_id": "3203220",
          "name": "SUKATANI"
        },
        {
          "id": "3203220014",
          "district_id": "3203220",
          "name": "SUKANAGALIH"
        },
        {
          "id": "3203221001",
          "district_id": "3203221",
          "name": "SINDANGJAYA"
        },
        {
          "id": "3203221002",
          "district_id": "3203221",
          "name": "CIPANAS"
        },
        {
          "id": "3203221003",
          "district_id": "3203221",
          "name": "SINDANGLAYA"
        },
        {
          "id": "3203221004",
          "district_id": "3203221",
          "name": "PALASARI"
        },
        {
          "id": "3203221005",
          "district_id": "3203221",
          "name": "CIMACAN"
        },
        {
          "id": "3203221006",
          "district_id": "3203221",
          "name": "CILOTO"
        },
        {
          "id": "3203221007",
          "district_id": "3203221",
          "name": "BATULAWANG"
        },
        {
          "id": "3203230001",
          "district_id": "3203230",
          "name": "PAKUON"
        },
        {
          "id": "3203230002",
          "district_id": "3203230",
          "name": "CIKANYERE"
        },
        {
          "id": "3203230003",
          "district_id": "3203230",
          "name": "SUKARESMI"
        },
        {
          "id": "3203230004",
          "district_id": "3203230",
          "name": "CIWALEN"
        },
        {
          "id": "3203230005",
          "district_id": "3203230",
          "name": "KAWUNGLUWUK"
        },
        {
          "id": "3203230006",
          "district_id": "3203230",
          "name": "CIBADAK"
        },
        {
          "id": "3203230007",
          "district_id": "3203230",
          "name": "RAWABELUT"
        },
        {
          "id": "3203230008",
          "district_id": "3203230",
          "name": "CIBANTENG"
        },
        {
          "id": "3203230009",
          "district_id": "3203230",
          "name": "KUBANG"
        },
        {
          "id": "3203230010",
          "district_id": "3203230",
          "name": "SUKAMAHI"
        },
        {
          "id": "3203230011",
          "district_id": "3203230",
          "name": "CIKANCANA"
        },
        {
          "id": "3203240001",
          "district_id": "3203240",
          "name": "PADAJAYA"
        },
        {
          "id": "3203240002",
          "district_id": "3203240",
          "name": "CINANGSI"
        },
        {
          "id": "3203240003",
          "district_id": "3203240",
          "name": "MENTENGSARI"
        },
        {
          "id": "3203240004",
          "district_id": "3203240",
          "name": "GUDANG"
        },
        {
          "id": "3203240005",
          "district_id": "3203240",
          "name": "SUKAGALIH"
        },
        {
          "id": "3203240006",
          "district_id": "3203240",
          "name": "MAJALAYA"
        },
        {
          "id": "3203240007",
          "district_id": "3203240",
          "name": "CIJAGANG"
        },
        {
          "id": "3203240008",
          "district_id": "3203240",
          "name": "MEKARJAYA"
        },
        {
          "id": "3203240009",
          "district_id": "3203240",
          "name": "MEKAR SARI"
        },
        {
          "id": "3203240010",
          "district_id": "3203240",
          "name": "SUKAMULYA"
        },
        {
          "id": "3203240011",
          "district_id": "3203240",
          "name": "MEKARGALIH"
        },
        {
          "id": "3203240012",
          "district_id": "3203240",
          "name": "NEGLASARI"
        },
        {
          "id": "3203240013",
          "district_id": "3203240",
          "name": "LEMBAHSARI"
        },
        {
          "id": "3203240014",
          "district_id": "3203240",
          "name": "WARUDOYONG"
        },
        {
          "id": "3203240015",
          "district_id": "3203240",
          "name": "KAMURANG"
        },
        {
          "id": "3203240016",
          "district_id": "3203240",
          "name": "CIRAMA GIRANG"
        },
        {
          "id": "3203240017",
          "district_id": "3203240",
          "name": "MEKAR MULYA"
        },
        {
          "id": "3203240018",
          "district_id": "3203240",
          "name": "CIGUNUNGHERANG"
        },
        {
          "id": "3204010001",
          "district_id": "3204010",
          "name": "PANUNDAAN"
        },
        {
          "id": "3204010002",
          "district_id": "3204010",
          "name": "CIWIDEY"
        },
        {
          "id": "3204010003",
          "district_id": "3204010",
          "name": "PANYOCOKAN"
        },
        {
          "id": "3204010004",
          "district_id": "3204010",
          "name": "LEBAKMUNCANG"
        },
        {
          "id": "3204010005",
          "district_id": "3204010",
          "name": "RAWABOGO"
        },
        {
          "id": "3204010006",
          "district_id": "3204010",
          "name": "NENGKELAN"
        },
        {
          "id": "3204010007",
          "district_id": "3204010",
          "name": "SUKAWENING"
        },
        {
          "id": "3204011001",
          "district_id": "3204011",
          "name": "CIPELAH"
        },
        {
          "id": "3204011002",
          "district_id": "3204011",
          "name": "SUKARESMI"
        },
        {
          "id": "3204011003",
          "district_id": "3204011",
          "name": "INDRAGIRI"
        },
        {
          "id": "3204011004",
          "district_id": "3204011",
          "name": "PATENGAN"
        },
        {
          "id": "3204011005",
          "district_id": "3204011",
          "name": "ALAMENDAH"
        },
        {
          "id": "3204020001",
          "district_id": "3204020",
          "name": "SUGIHMUKTI"
        },
        {
          "id": "3204020002",
          "district_id": "3204020",
          "name": "MARGAMULYA"
        },
        {
          "id": "3204020003",
          "district_id": "3204020",
          "name": "TENJOLAYA"
        },
        {
          "id": "3204020004",
          "district_id": "3204020",
          "name": "CISONDARI"
        },
        {
          "id": "3204020005",
          "district_id": "3204020",
          "name": "MEKARSARI"
        },
        {
          "id": "3204020006",
          "district_id": "3204020",
          "name": "CIBODAS"
        },
        {
          "id": "3204020007",
          "district_id": "3204020",
          "name": "CUKANGGENTENG"
        },
        {
          "id": "3204020008",
          "district_id": "3204020",
          "name": "PASIRJAMBU"
        },
        {
          "id": "3204020009",
          "district_id": "3204020",
          "name": "MEKARMAJU"
        },
        {
          "id": "3204020010",
          "district_id": "3204020",
          "name": "CIKONENG"
        },
        {
          "id": "3204030001",
          "district_id": "3204030",
          "name": "CIKALONG"
        },
        {
          "id": "3204030002",
          "district_id": "3204030",
          "name": "MEKARSARI"
        },
        {
          "id": "3204030003",
          "district_id": "3204030",
          "name": "CIPINANG"
        },
        {
          "id": "3204030004",
          "district_id": "3204030",
          "name": "CIMAUNG"
        },
        {
          "id": "3204030005",
          "district_id": "3204030",
          "name": "CAMPAKAMULYA"
        },
        {
          "id": "3204030006",
          "district_id": "3204030",
          "name": "PASIRHUNI"
        },
        {
          "id": "3204030007",
          "district_id": "3204030",
          "name": "JAGABAYA"
        },
        {
          "id": "3204030008",
          "district_id": "3204030",
          "name": "MALASARI"
        },
        {
          "id": "3204030009",
          "district_id": "3204030",
          "name": "SUKAMAJU"
        },
        {
          "id": "3204030010",
          "district_id": "3204030",
          "name": "WARJABAKTI"
        },
        {
          "id": "3204040001",
          "district_id": "3204040",
          "name": "WANASUKA"
        },
        {
          "id": "3204040002",
          "district_id": "3204040",
          "name": "BANJARSARI"
        },
        {
          "id": "3204040003",
          "district_id": "3204040",
          "name": "MARGALUYU"
        },
        {
          "id": "3204040004",
          "district_id": "3204040",
          "name": "SUKALUYU"
        },
        {
          "id": "3204040005",
          "district_id": "3204040",
          "name": "WARNASARI"
        },
        {
          "id": "3204040006",
          "district_id": "3204040",
          "name": "PULOSARI"
        },
        {
          "id": "3204040007",
          "district_id": "3204040",
          "name": "MARGAMEKAR"
        },
        {
          "id": "3204040008",
          "district_id": "3204040",
          "name": "SUKAMANAH"
        },
        {
          "id": "3204040009",
          "district_id": "3204040",
          "name": "MARGAMUKTI"
        },
        {
          "id": "3204040010",
          "district_id": "3204040",
          "name": "PANGALENGAN"
        },
        {
          "id": "3204040011",
          "district_id": "3204040",
          "name": "MARGAMULYA"
        },
        {
          "id": "3204040012",
          "district_id": "3204040",
          "name": "TRIBAKTIMULYA"
        },
        {
          "id": "3204040013",
          "district_id": "3204040",
          "name": "LAMAJANG"
        },
        {
          "id": "3204050001",
          "district_id": "3204050",
          "name": "NEGLAWANGI"
        },
        {
          "id": "3204050002",
          "district_id": "3204050",
          "name": "SANTOSA"
        },
        {
          "id": "3204050003",
          "district_id": "3204050",
          "name": "TARUMAJAYA"
        },
        {
          "id": "3204050004",
          "district_id": "3204050",
          "name": "CIKEMBANG"
        },
        {
          "id": "3204050005",
          "district_id": "3204050",
          "name": "CIBEUREUM"
        },
        {
          "id": "3204050006",
          "district_id": "3204050",
          "name": "CIHAWUK"
        },
        {
          "id": "3204050007",
          "district_id": "3204050",
          "name": "SUKAPURA"
        },
        {
          "id": "3204050008",
          "district_id": "3204050",
          "name": "RESMI TINGGAL"
        },
        {
          "id": "3204060001",
          "district_id": "3204060",
          "name": "CIKITU"
        },
        {
          "id": "3204060002",
          "district_id": "3204060",
          "name": "GIRIMULYA"
        },
        {
          "id": "3204060003",
          "district_id": "3204060",
          "name": "SUKARAME"
        },
        {
          "id": "3204060004",
          "district_id": "3204060",
          "name": "CIKAWAO"
        },
        {
          "id": "3204060005",
          "district_id": "3204060",
          "name": "NAGRAK"
        },
        {
          "id": "3204060006",
          "district_id": "3204060",
          "name": "MANDALAHAJI"
        },
        {
          "id": "3204060007",
          "district_id": "3204060",
          "name": "MARUYUNG"
        },
        {
          "id": "3204060008",
          "district_id": "3204060",
          "name": "PANGAUBAN"
        },
        {
          "id": "3204060009",
          "district_id": "3204060",
          "name": "CINANGGELA"
        },
        {
          "id": "3204060010",
          "district_id": "3204060",
          "name": "MEKARJAYA"
        },
        {
          "id": "3204060011",
          "district_id": "3204060",
          "name": "MEKARSARI"
        },
        {
          "id": "3204060012",
          "district_id": "3204060",
          "name": "CIPEUJEUH"
        },
        {
          "id": "3204060013",
          "district_id": "3204060",
          "name": "TANJUNGWANGI"
        },
        {
          "id": "3204070001",
          "district_id": "3204070",
          "name": "NEGLASARI"
        },
        {
          "id": "3204070003",
          "district_id": "3204070",
          "name": "IBUN"
        },
        {
          "id": "3204070004",
          "district_id": "3204070",
          "name": "LAKSANA"
        },
        {
          "id": "3204070005",
          "district_id": "3204070",
          "name": "MEKARWANGI"
        },
        {
          "id": "3204070006",
          "district_id": "3204070",
          "name": "SUDI"
        },
        {
          "id": "3204070007",
          "district_id": "3204070",
          "name": "CIBEET"
        },
        {
          "id": "3204070008",
          "district_id": "3204070",
          "name": "PANGGUH"
        },
        {
          "id": "3204070009",
          "district_id": "3204070",
          "name": "KARYALAKSANA"
        },
        {
          "id": "3204070010",
          "district_id": "3204070",
          "name": "LAMPEGAN"
        },
        {
          "id": "3204070011",
          "district_id": "3204070",
          "name": "TALUN"
        },
        {
          "id": "3204070012",
          "district_id": "3204070",
          "name": "TANGGULUN"
        },
        {
          "id": "3204080001",
          "district_id": "3204080",
          "name": "LOA"
        },
        {
          "id": "3204080002",
          "district_id": "3204080",
          "name": "DRAWATI"
        },
        {
          "id": "3204080003",
          "district_id": "3204080",
          "name": "CIPAKU"
        },
        {
          "id": "3204080004",
          "district_id": "3204080",
          "name": "SINDANGSARI"
        },
        {
          "id": "3204080005",
          "district_id": "3204080",
          "name": "SUKAMANTRI"
        },
        {
          "id": "3204080006",
          "district_id": "3204080",
          "name": "SUKAMANAH"
        },
        {
          "id": "3204080007",
          "district_id": "3204080",
          "name": "MEKARPAWITAN"
        },
        {
          "id": "3204080008",
          "district_id": "3204080",
          "name": "CIJAGRA"
        },
        {
          "id": "3204080009",
          "district_id": "3204080",
          "name": "TANGSIMEKAR"
        },
        {
          "id": "3204080010",
          "district_id": "3204080",
          "name": "CIPEDES"
        },
        {
          "id": "3204080011",
          "district_id": "3204080",
          "name": "KARANGTUNGGAL"
        },
        {
          "id": "3204080012",
          "district_id": "3204080",
          "name": "CIGENTUR"
        },
        {
          "id": "3204090001",
          "district_id": "3204090",
          "name": "SRIRAHAYU"
        },
        {
          "id": "3204090002",
          "district_id": "3204090",
          "name": "CILULUK"
        },
        {
          "id": "3204090003",
          "district_id": "3204090",
          "name": "MEKARLAKSANA"
        },
        {
          "id": "3204090004",
          "district_id": "3204090",
          "name": "CIHANYIR"
        },
        {
          "id": "3204090005",
          "district_id": "3204090",
          "name": "CIKANCUNG"
        },
        {
          "id": "3204090006",
          "district_id": "3204090",
          "name": "MANDALASARI"
        },
        {
          "id": "3204090007",
          "district_id": "3204090",
          "name": "HEGARMANAH"
        },
        {
          "id": "3204090008",
          "district_id": "3204090",
          "name": "CIKASUNGKA"
        },
        {
          "id": "3204090009",
          "district_id": "3204090",
          "name": "TANJUNGLAYA"
        },
        {
          "id": "3204100001",
          "district_id": "3204100",
          "name": "NAGROG"
        },
        {
          "id": "3204100002",
          "district_id": "3204100",
          "name": "NARAWITA"
        },
        {
          "id": "3204100003",
          "district_id": "3204100",
          "name": "MARGAASIH"
        },
        {
          "id": "3204100004",
          "district_id": "3204100",
          "name": "CICALENGKA WETAN"
        },
        {
          "id": "3204100005",
          "district_id": "3204100",
          "name": "CIKUYA"
        },
        {
          "id": "3204100006",
          "district_id": "3204100",
          "name": "WALUYA"
        },
        {
          "id": "3204100007",
          "district_id": "3204100",
          "name": "PANENJOAN"
        },
        {
          "id": "3204100008",
          "district_id": "3204100",
          "name": "TENJOLAYA"
        },
        {
          "id": "3204100009",
          "district_id": "3204100",
          "name": "CICALENGKA KULON"
        },
        {
          "id": "3204100010",
          "district_id": "3204100",
          "name": "BABAKANPEUTEUY"
        },
        {
          "id": "3204100011",
          "district_id": "3204100",
          "name": "DAMPIT"
        },
        {
          "id": "3204100012",
          "district_id": "3204100",
          "name": "TANJUNGWANGI"
        },
        {
          "id": "3204101001",
          "district_id": "3204101",
          "name": "MANDALAWANGI"
        },
        {
          "id": "3204101002",
          "district_id": "3204101",
          "name": "BOJONG"
        },
        {
          "id": "3204101003",
          "district_id": "3204101",
          "name": "CIHERANG"
        },
        {
          "id": "3204101004",
          "district_id": "3204101",
          "name": "CIARO"
        },
        {
          "id": "3204101005",
          "district_id": "3204101",
          "name": "NAGREG"
        },
        {
          "id": "3204101006",
          "district_id": "3204101",
          "name": "CITAMAN"
        },
        {
          "id": "3204101007",
          "district_id": "3204101",
          "name": "NAGREG KENDAN"
        },
        {
          "id": "3204101008",
          "district_id": "3204101",
          "name": "GANJAR SABAR"
        },
        {
          "id": "3204110001",
          "district_id": "3204110",
          "name": "SUKAMANAH"
        },
        {
          "id": "3204110002",
          "district_id": "3204110",
          "name": "TEGALSUMEDANG"
        },
        {
          "id": "3204110003",
          "district_id": "3204110",
          "name": "RANCAEKEK KULON"
        },
        {
          "id": "3204110004",
          "district_id": "3204110",
          "name": "RANCAEKEK WETAN"
        },
        {
          "id": "3204110005",
          "district_id": "3204110",
          "name": "BOJONGLOA"
        },
        {
          "id": "3204110006",
          "district_id": "3204110",
          "name": "JELEGONG"
        },
        {
          "id": "3204110007",
          "district_id": "3204110",
          "name": "LINGGAR"
        },
        {
          "id": "3204110008",
          "district_id": "3204110",
          "name": "SUKAMULYA"
        },
        {
          "id": "3204110009",
          "district_id": "3204110",
          "name": "HAURPUGUR"
        },
        {
          "id": "3204110010",
          "district_id": "3204110",
          "name": "SANGIANG"
        },
        {
          "id": "3204110011",
          "district_id": "3204110",
          "name": "BOJONGSALAM"
        },
        {
          "id": "3204110012",
          "district_id": "3204110",
          "name": "CANGKUANG"
        },
        {
          "id": "3204110013",
          "district_id": "3204110",
          "name": "NANJUNGMEKAR"
        },
        {
          "id": "3204110014",
          "district_id": "3204110",
          "name": "RANCAEKEK KENCANA"
        },
        {
          "id": "3204120001",
          "district_id": "3204120",
          "name": "NEGLASARI"
        },
        {
          "id": "3204120002",
          "district_id": "3204120",
          "name": "WANGISAGARA"
        },
        {
          "id": "3204120003",
          "district_id": "3204120",
          "name": "PADAMULYA"
        },
        {
          "id": "3204120004",
          "district_id": "3204120",
          "name": "SUKAMUKTI"
        },
        {
          "id": "3204120005",
          "district_id": "3204120",
          "name": "PADAULUN"
        },
        {
          "id": "3204120006",
          "district_id": "3204120",
          "name": "BIRU"
        },
        {
          "id": "3204120007",
          "district_id": "3204120",
          "name": "SUKAMAJU"
        },
        {
          "id": "3204120008",
          "district_id": "3204120",
          "name": "MAJASETRA"
        },
        {
          "id": "3204120009",
          "district_id": "3204120",
          "name": "MAJALAYA"
        },
        {
          "id": "3204120010",
          "district_id": "3204120",
          "name": "MAJAKERTA"
        },
        {
          "id": "3204120011",
          "district_id": "3204120",
          "name": "BOJONG"
        },
        {
          "id": "3204121001",
          "district_id": "3204121",
          "name": "PANYADAP"
        },
        {
          "id": "3204121002",
          "district_id": "3204121",
          "name": "PADAMUKTI"
        },
        {
          "id": "3204121003",
          "district_id": "3204121",
          "name": "CIBODAS"
        },
        {
          "id": "3204121004",
          "district_id": "3204121",
          "name": "LANGENSARI"
        },
        {
          "id": "3204121005",
          "district_id": "3204121",
          "name": "SOLOKANJERUK"
        },
        {
          "id": "3204121006",
          "district_id": "3204121",
          "name": "RANCAKASUMBA"
        },
        {
          "id": "3204121007",
          "district_id": "3204121",
          "name": "BOJONGEMAS"
        },
        {
          "id": "3204130001",
          "district_id": "3204130",
          "name": "BABAKAN"
        },
        {
          "id": "3204130002",
          "district_id": "3204130",
          "name": "CIKONENG"
        },
        {
          "id": "3204130003",
          "district_id": "3204130",
          "name": "SIGARACIPTA"
        },
        {
          "id": "3204130004",
          "district_id": "3204130",
          "name": "PAKUTANDANG"
        },
        {
          "id": "3204130005",
          "district_id": "3204130",
          "name": "MANGGUNGHARJA"
        },
        {
          "id": "3204130006",
          "district_id": "3204130",
          "name": "MEKARSARI"
        },
        {
          "id": "3204130007",
          "district_id": "3204130",
          "name": "CIPARAY"
        },
        {
          "id": "3204130008",
          "district_id": "3204130",
          "name": "SUMBERSARI"
        },
        {
          "id": "3204130009",
          "district_id": "3204130",
          "name": "SARIMAHI"
        },
        {
          "id": "3204130010",
          "district_id": "3204130",
          "name": "SERANGMEKAR"
        },
        {
          "id": "3204130011",
          "district_id": "3204130",
          "name": "GUNUNGLEUTIK"
        },
        {
          "id": "3204130012",
          "district_id": "3204130",
          "name": "CIHEULANG"
        },
        {
          "id": "3204130013",
          "district_id": "3204130",
          "name": "MEKARLAKSANA"
        },
        {
          "id": "3204130014",
          "district_id": "3204130",
          "name": "BUMIWANGI"
        },
        {
          "id": "3204140001",
          "district_id": "3204140",
          "name": "JELEKONG"
        },
        {
          "id": "3204140002",
          "district_id": "3204140",
          "name": "MANGGAHANG"
        },
        {
          "id": "3204140003",
          "district_id": "3204140",
          "name": "BALEENDAH"
        },
        {
          "id": "3204140004",
          "district_id": "3204140",
          "name": "ANDIR"
        },
        {
          "id": "3204140005",
          "district_id": "3204140",
          "name": "MALAKASARI"
        },
        {
          "id": "3204140006",
          "district_id": "3204140",
          "name": "BOJONGMALAKA"
        },
        {
          "id": "3204140007",
          "district_id": "3204140",
          "name": "RANCAMANYAR"
        },
        {
          "id": "3204140008",
          "district_id": "3204140",
          "name": "WARGAMEKAR"
        },
        {
          "id": "3204150001",
          "district_id": "3204150",
          "name": "BATUKARUT"
        },
        {
          "id": "3204150002",
          "district_id": "3204150",
          "name": "MANGUNJAYA"
        },
        {
          "id": "3204150003",
          "district_id": "3204150",
          "name": "MEKARJAYA"
        },
        {
          "id": "3204150004",
          "district_id": "3204150",
          "name": "BAROS"
        },
        {
          "id": "3204150005",
          "district_id": "3204150",
          "name": "LEBAKWANGI"
        },
        {
          "id": "3204150006",
          "district_id": "3204150",
          "name": "WARGALUYU"
        },
        {
          "id": "3204150007",
          "district_id": "3204150",
          "name": "ARJASARI"
        },
        {
          "id": "3204150008",
          "district_id": "3204150",
          "name": "PINGGIRSARI"
        },
        {
          "id": "3204150009",
          "district_id": "3204150",
          "name": "PATROLSARI"
        },
        {
          "id": "3204150010",
          "district_id": "3204150",
          "name": "RANCAKOLE"
        },
        {
          "id": "3204150011",
          "district_id": "3204150",
          "name": "ANCOLMEKAR"
        },
        {
          "id": "3204160001",
          "district_id": "3204160",
          "name": "MEKARJAYA"
        },
        {
          "id": "3204160002",
          "district_id": "3204160",
          "name": "BANJARAN WETAN"
        },
        {
          "id": "3204160003",
          "district_id": "3204160",
          "name": "CIAPUS"
        },
        {
          "id": "3204160004",
          "district_id": "3204160",
          "name": "SINDANGPANON"
        },
        {
          "id": "3204160005",
          "district_id": "3204160",
          "name": "NEGLASARI"
        },
        {
          "id": "3204160006",
          "district_id": "3204160",
          "name": "MARGAHURIP"
        },
        {
          "id": "3204160007",
          "district_id": "3204160",
          "name": "KIANGROKE"
        },
        {
          "id": "3204160008",
          "district_id": "3204160",
          "name": "KAMASAN"
        },
        {
          "id": "3204160009",
          "district_id": "3204160",
          "name": "BANJARAN"
        },
        {
          "id": "3204160010",
          "district_id": "3204160",
          "name": "TARAJUSARI"
        },
        {
          "id": "3204160011",
          "district_id": "3204160",
          "name": "PASIRMULYA"
        },
        {
          "id": "3204161001",
          "district_id": "3204161",
          "name": "JATISARI"
        },
        {
          "id": "3204161002",
          "district_id": "3204161",
          "name": "NAGRAK"
        },
        {
          "id": "3204161003",
          "district_id": "3204161",
          "name": "BANDASARI"
        },
        {
          "id": "3204161004",
          "district_id": "3204161",
          "name": "PANANJUNG"
        },
        {
          "id": "3204161005",
          "district_id": "3204161",
          "name": "CILUNCAT"
        },
        {
          "id": "3204161006",
          "district_id": "3204161",
          "name": "CANGKUANG"
        },
        {
          "id": "3204161007",
          "district_id": "3204161",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3204170001",
          "district_id": "3204170",
          "name": "BOJONGMANGGU"
        },
        {
          "id": "3204170002",
          "district_id": "3204170",
          "name": "LANGONSARI"
        },
        {
          "id": "3204170003",
          "district_id": "3204170",
          "name": "SUKASARI"
        },
        {
          "id": "3204170004",
          "district_id": "3204170",
          "name": "RANCAMULYA"
        },
        {
          "id": "3204170005",
          "district_id": "3204170",
          "name": "RANCATUNGKU"
        },
        {
          "id": "3204170006",
          "district_id": "3204170",
          "name": "BOJONGKUNCI"
        },
        {
          "id": "3204180004",
          "district_id": "3204180",
          "name": "GANDASARI"
        },
        {
          "id": "3204180005",
          "district_id": "3204180",
          "name": "KATAPANG"
        },
        {
          "id": "3204180006",
          "district_id": "3204180",
          "name": "CILAMPENI"
        },
        {
          "id": "3204180007",
          "district_id": "3204180",
          "name": "PANGAUBAN"
        },
        {
          "id": "3204180008",
          "district_id": "3204180",
          "name": "BANYUSARI"
        },
        {
          "id": "3204180009",
          "district_id": "3204180",
          "name": "SANGKANHURIP"
        },
        {
          "id": "3204180010",
          "district_id": "3204180",
          "name": "SUKAMUKTI"
        },
        {
          "id": "3204190002",
          "district_id": "3204190",
          "name": "SADU"
        },
        {
          "id": "3204190003",
          "district_id": "3204190",
          "name": "SUKAJADI"
        },
        {
          "id": "3204190004",
          "district_id": "3204190",
          "name": "SUKANAGARA"
        },
        {
          "id": "3204190005",
          "district_id": "3204190",
          "name": "PANYIRAPAN"
        },
        {
          "id": "3204190006",
          "district_id": "3204190",
          "name": "KARAMATMULYA"
        },
        {
          "id": "3204190007",
          "district_id": "3204190",
          "name": "SOREANG"
        },
        {
          "id": "3204190008",
          "district_id": "3204190",
          "name": "PAMEKARAN"
        },
        {
          "id": "3204190019",
          "district_id": "3204190",
          "name": "PARUNGSERAB"
        },
        {
          "id": "3204190020",
          "district_id": "3204190",
          "name": "SEKARWANGI"
        },
        {
          "id": "3204190021",
          "district_id": "3204190",
          "name": "CINGCIN"
        },
        {
          "id": "3204191001",
          "district_id": "3204191",
          "name": "CILAME"
        },
        {
          "id": "3204191002",
          "district_id": "3204191",
          "name": "BUNINAGARA"
        },
        {
          "id": "3204191003",
          "district_id": "3204191",
          "name": "PADASUKA"
        },
        {
          "id": "3204191004",
          "district_id": "3204191",
          "name": "SUKAMULYA"
        },
        {
          "id": "3204191005",
          "district_id": "3204191",
          "name": "KUTAWARINGIN"
        },
        {
          "id": "3204191006",
          "district_id": "3204191",
          "name": "KOPO"
        },
        {
          "id": "3204191007",
          "district_id": "3204191",
          "name": "CIBODAS"
        },
        {
          "id": "3204191008",
          "district_id": "3204191",
          "name": "JATISARI"
        },
        {
          "id": "3204191009",
          "district_id": "3204191",
          "name": "JELEGONG"
        },
        {
          "id": "3204191010",
          "district_id": "3204191",
          "name": "GAJAHMEKAR"
        },
        {
          "id": "3204191011",
          "district_id": "3204191",
          "name": "PAMEUNTASAN"
        },
        {
          "id": "3204250001",
          "district_id": "3204250",
          "name": "NANJUNG"
        },
        {
          "id": "3204250002",
          "district_id": "3204250",
          "name": "MEKAR RAHAYU"
        },
        {
          "id": "3204250003",
          "district_id": "3204250",
          "name": "RAHAYU"
        },
        {
          "id": "3204250004",
          "district_id": "3204250",
          "name": "CIGONDEWAH HILIR"
        },
        {
          "id": "3204250005",
          "district_id": "3204250",
          "name": "MARGAASIH"
        },
        {
          "id": "3204250006",
          "district_id": "3204250",
          "name": "LAGADAR"
        },
        {
          "id": "3204260001",
          "district_id": "3204260",
          "name": "SULAEMAN"
        },
        {
          "id": "3204260002",
          "district_id": "3204260",
          "name": "SUKAMENAK"
        },
        {
          "id": "3204260003",
          "district_id": "3204260",
          "name": "SAYATI"
        },
        {
          "id": "3204260004",
          "district_id": "3204260",
          "name": "MARGAHAYU SELATAN"
        },
        {
          "id": "3204260005",
          "district_id": "3204260",
          "name": "MARGAHAYU TENGAH"
        },
        {
          "id": "3204270001",
          "district_id": "3204270",
          "name": "CANGKUANG KULON"
        },
        {
          "id": "3204270002",
          "district_id": "3204270",
          "name": "CANGKUANG WETAN"
        },
        {
          "id": "3204270003",
          "district_id": "3204270",
          "name": "PASAWAHAN"
        },
        {
          "id": "3204270004",
          "district_id": "3204270",
          "name": "DAYEUHKOLOT"
        },
        {
          "id": "3204270005",
          "district_id": "3204270",
          "name": "CITEUREUP"
        },
        {
          "id": "3204270006",
          "district_id": "3204270",
          "name": "SUKAPURA"
        },
        {
          "id": "3204280001",
          "district_id": "3204280",
          "name": "BOJONGSARI"
        },
        {
          "id": "3204280002",
          "district_id": "3204280",
          "name": "BOJONGSOANG"
        },
        {
          "id": "3204280003",
          "district_id": "3204280",
          "name": "LENGKONG"
        },
        {
          "id": "3204280004",
          "district_id": "3204280",
          "name": "CIPAGALO"
        },
        {
          "id": "3204280005",
          "district_id": "3204280",
          "name": "BUAHBATU"
        },
        {
          "id": "3204280006",
          "district_id": "3204280",
          "name": "TEGALLUAR"
        },
        {
          "id": "3204290001",
          "district_id": "3204290",
          "name": "CIBIRU HILIR"
        },
        {
          "id": "3204290002",
          "district_id": "3204290",
          "name": "CINUNUK"
        },
        {
          "id": "3204290003",
          "district_id": "3204290",
          "name": "CIMEKAR"
        },
        {
          "id": "3204290004",
          "district_id": "3204290",
          "name": "CILEUNYI KULON"
        },
        {
          "id": "3204290005",
          "district_id": "3204290",
          "name": "CILEUNYI WETAN"
        },
        {
          "id": "3204290006",
          "district_id": "3204290",
          "name": "CIBIRU WETAN"
        },
        {
          "id": "3204300001",
          "district_id": "3204300",
          "name": "GIRIMEKAR"
        },
        {
          "id": "3204300002",
          "district_id": "3204300",
          "name": "JATIENDAH"
        },
        {
          "id": "3204300003",
          "district_id": "3204300",
          "name": "MELATIWANGI"
        },
        {
          "id": "3204300004",
          "district_id": "3204300",
          "name": "CIPANJALU"
        },
        {
          "id": "3204300005",
          "district_id": "3204300",
          "name": "CIPOREAT"
        },
        {
          "id": "3204300006",
          "district_id": "3204300",
          "name": "CILENGKRANG"
        },
        {
          "id": "3204310001",
          "district_id": "3204310",
          "name": "CIBEUNYING"
        },
        {
          "id": "3204310002",
          "district_id": "3204310",
          "name": "PADASUKA"
        },
        {
          "id": "3204310003",
          "district_id": "3204310",
          "name": "MANDALAMEKAR"
        },
        {
          "id": "3204310004",
          "district_id": "3204310",
          "name": "CIKADUT"
        },
        {
          "id": "3204310005",
          "district_id": "3204310",
          "name": "SINDANGLAYA"
        },
        {
          "id": "3204310006",
          "district_id": "3204310",
          "name": "MEKARMANIK"
        },
        {
          "id": "3204310007",
          "district_id": "3204310",
          "name": "CIMENYAN"
        },
        {
          "id": "3204310008",
          "district_id": "3204310",
          "name": "MEKARSALUYU"
        },
        {
          "id": "3204310009",
          "district_id": "3204310",
          "name": "CIBURIAL"
        },
        {
          "id": "3205010006",
          "district_id": "3205010",
          "name": "SUKAJAYA"
        },
        {
          "id": "3205010007",
          "district_id": "3205010",
          "name": "CIKARANG"
        },
        {
          "id": "3205010008",
          "district_id": "3205010",
          "name": "PAMALAYAN"
        },
        {
          "id": "3205010009",
          "district_id": "3205010",
          "name": "CISEWU"
        },
        {
          "id": "3205010010",
          "district_id": "3205010",
          "name": "GIRIMUKTI"
        },
        {
          "id": "3205010011",
          "district_id": "3205010",
          "name": "NYALINDUNG"
        },
        {
          "id": "3205010012",
          "district_id": "3205010",
          "name": "KARANGSEWU"
        },
        {
          "id": "3205010013",
          "district_id": "3205010",
          "name": "MEKARSEWU"
        },
        {
          "id": "3205010014",
          "district_id": "3205010",
          "name": "PANGGALIH"
        },
        {
          "id": "3205011001",
          "district_id": "3205011",
          "name": "CIMAHI"
        },
        {
          "id": "3205011002",
          "district_id": "3205011",
          "name": "INDRALAYANG"
        },
        {
          "id": "3205011003",
          "district_id": "3205011",
          "name": "PURBAYANI"
        },
        {
          "id": "3205011004",
          "district_id": "3205011",
          "name": "CARINGIN"
        },
        {
          "id": "3205011005",
          "district_id": "3205011",
          "name": "SUKARAME"
        },
        {
          "id": "3205011006",
          "district_id": "3205011",
          "name": "SAMUDRAJAYA"
        },
        {
          "id": "3205020001",
          "district_id": "3205020",
          "name": "SELAAWI"
        },
        {
          "id": "3205020002",
          "district_id": "3205020",
          "name": "MEKARMUKTI"
        },
        {
          "id": "3205020003",
          "district_id": "3205020",
          "name": "MEKARMULYA"
        },
        {
          "id": "3205020004",
          "district_id": "3205020",
          "name": "SUKAMULYA"
        },
        {
          "id": "3205020005",
          "district_id": "3205020",
          "name": "SUKAMAJU"
        },
        {
          "id": "3205020006",
          "district_id": "3205020",
          "name": "SUKALAKSANA"
        },
        {
          "id": "3205020007",
          "district_id": "3205020",
          "name": "MEKARWANGI"
        },
        {
          "id": "3205030001",
          "district_id": "3205030",
          "name": "SINARJAYA"
        },
        {
          "id": "3205030005",
          "district_id": "3205030",
          "name": "TEGALEGA"
        },
        {
          "id": "3205030006",
          "district_id": "3205030",
          "name": "WANGUNJAYA"
        },
        {
          "id": "3205030007",
          "district_id": "3205030",
          "name": "MEKARJAYA"
        },
        {
          "id": "3205030008",
          "district_id": "3205030",
          "name": "HANJUANG"
        },
        {
          "id": "3205030009",
          "district_id": "3205030",
          "name": "MARGALAKSANA"
        },
        {
          "id": "3205030010",
          "district_id": "3205030",
          "name": "GUNAMEKAR"
        },
        {
          "id": "3205030011",
          "district_id": "3205030",
          "name": "BUNGBULANG"
        },
        {
          "id": "3205030012",
          "district_id": "3205030",
          "name": "CIHIKEU"
        },
        {
          "id": "3205030013",
          "district_id": "3205030",
          "name": "BOJONG"
        },
        {
          "id": "3205030014",
          "district_id": "3205030",
          "name": "MEKARBAKTI"
        },
        {
          "id": "3205030015",
          "district_id": "3205030",
          "name": "GUNUNG JAMPANG"
        },
        {
          "id": "3205030016",
          "district_id": "3205030",
          "name": "HEGARMANAH"
        },
        {
          "id": "3205031001",
          "district_id": "3205031",
          "name": "KARANGWANGI"
        },
        {
          "id": "3205031002",
          "district_id": "3205031",
          "name": "CIJAYANA"
        },
        {
          "id": "3205031003",
          "district_id": "3205031",
          "name": "MEKARMUKTI"
        },
        {
          "id": "3205031004",
          "district_id": "3205031",
          "name": "JAGABAYA"
        },
        {
          "id": "3205031005",
          "district_id": "3205031",
          "name": "MEKARSARI"
        },
        {
          "id": "3205040001",
          "district_id": "3205040",
          "name": "LINGGARJATI"
        },
        {
          "id": "3205040002",
          "district_id": "3205040",
          "name": "GARUMUKTI"
        },
        {
          "id": "3205040003",
          "district_id": "3205040",
          "name": "PANAWA"
        },
        {
          "id": "3205040004",
          "district_id": "3205040",
          "name": "PAKENJENG"
        },
        {
          "id": "3205040005",
          "district_id": "3205040",
          "name": "PANANJUNG"
        },
        {
          "id": "3205050001",
          "district_id": "3205050",
          "name": "KARANGSARI"
        },
        {
          "id": "3205050002",
          "district_id": "3205050",
          "name": "TANJUNGMULYA"
        },
        {
          "id": "3205050003",
          "district_id": "3205050",
          "name": "TANJUNGJAYA"
        },
        {
          "id": "3205050004",
          "district_id": "3205050",
          "name": "TEGALGEDE"
        },
        {
          "id": "3205050005",
          "district_id": "3205050",
          "name": "NEGLASARI"
        },
        {
          "id": "3205050006",
          "district_id": "3205050",
          "name": "SUKAMULYA"
        },
        {
          "id": "3205050007",
          "district_id": "3205050",
          "name": "DEPOK"
        },
        {
          "id": "3205050008",
          "district_id": "3205050",
          "name": "PASIRLANGU"
        },
        {
          "id": "3205050009",
          "district_id": "3205050",
          "name": "PANYINDANGAN"
        },
        {
          "id": "3205050010",
          "district_id": "3205050",
          "name": "WANGUNJAYA"
        },
        {
          "id": "3205050011",
          "district_id": "3205050",
          "name": "JATIWANGI"
        },
        {
          "id": "3205050012",
          "district_id": "3205050",
          "name": "TALAGAWANGI"
        },
        {
          "id": "3205050013",
          "district_id": "3205050",
          "name": "JAYAMEKAR"
        },
        {
          "id": "3205060001",
          "district_id": "3205060",
          "name": "CIGADOG"
        },
        {
          "id": "3205060002",
          "district_id": "3205060",
          "name": "CIJAMBE"
        },
        {
          "id": "3205060003",
          "district_id": "3205060",
          "name": "CIKELET"
        },
        {
          "id": "3205060004",
          "district_id": "3205060",
          "name": "PAMALAYAN"
        },
        {
          "id": "3205060005",
          "district_id": "3205060",
          "name": "LINGGAMANIK"
        },
        {
          "id": "3205060006",
          "district_id": "3205060",
          "name": "KARANGSARI"
        },
        {
          "id": "3205060007",
          "district_id": "3205060",
          "name": "KERTAMUKTI"
        },
        {
          "id": "3205060008",
          "district_id": "3205060",
          "name": "CIROYOM"
        },
        {
          "id": "3205060009",
          "district_id": "3205060",
          "name": "GIRIMUKTI"
        },
        {
          "id": "3205060010",
          "district_id": "3205060",
          "name": "TIPAR"
        },
        {
          "id": "3205060011",
          "district_id": "3205060",
          "name": "AWASSAGARA"
        },
        {
          "id": "3205070001",
          "district_id": "3205070",
          "name": "MANCAGAHAR"
        },
        {
          "id": "3205070002",
          "district_id": "3205070",
          "name": "PAMEUNGPEUK"
        },
        {
          "id": "3205070003",
          "district_id": "3205070",
          "name": "MANDALAKASIH"
        },
        {
          "id": "3205070004",
          "district_id": "3205070",
          "name": "SIRNABAKTI"
        },
        {
          "id": "3205070005",
          "district_id": "3205070",
          "name": "PAAS"
        },
        {
          "id": "3205070006",
          "district_id": "3205070",
          "name": "BOJONG"
        },
        {
          "id": "3205070007",
          "district_id": "3205070",
          "name": "JATIMULYA"
        },
        {
          "id": "3205070008",
          "district_id": "3205070",
          "name": "BOJONGKIDUL"
        },
        {
          "id": "3205080001",
          "district_id": "3205080",
          "name": "MEKARSARI"
        },
        {
          "id": "3205080002",
          "district_id": "3205080",
          "name": "KARYASARI"
        },
        {
          "id": "3205080003",
          "district_id": "3205080",
          "name": "KARYA MUKTI"
        },
        {
          "id": "3205080004",
          "district_id": "3205080",
          "name": "SAGARA"
        },
        {
          "id": "3205080005",
          "district_id": "3205080",
          "name": "MAROKO"
        },
        {
          "id": "3205080006",
          "district_id": "3205080",
          "name": "SANCANG"
        },
        {
          "id": "3205080007",
          "district_id": "3205080",
          "name": "SIMPANG"
        },
        {
          "id": "3205080008",
          "district_id": "3205080",
          "name": "CIGARONGGONG"
        },
        {
          "id": "3205080009",
          "district_id": "3205080",
          "name": "MEKARMUKTI"
        },
        {
          "id": "3205080010",
          "district_id": "3205080",
          "name": "MEKARWANGI"
        },
        {
          "id": "3205080011",
          "district_id": "3205080",
          "name": "NAJATEN"
        },
        {
          "id": "3205090001",
          "district_id": "3205090",
          "name": "DEPOK"
        },
        {
          "id": "3205090002",
          "district_id": "3205090",
          "name": "SUKANAGARA"
        },
        {
          "id": "3205090003",
          "district_id": "3205090",
          "name": "CIHAURKUNING"
        },
        {
          "id": "3205090004",
          "district_id": "3205090",
          "name": "CIKONDANG"
        },
        {
          "id": "3205090005",
          "district_id": "3205090",
          "name": "JATISARI"
        },
        {
          "id": "3205090006",
          "district_id": "3205090",
          "name": "CISOMPET"
        },
        {
          "id": "3205090007",
          "district_id": "3205090",
          "name": "SUKAMUKTI"
        },
        {
          "id": "3205090008",
          "district_id": "3205090",
          "name": "PANYINDANGAN"
        },
        {
          "id": "3205090009",
          "district_id": "3205090",
          "name": "MARGAMULYA"
        },
        {
          "id": "3205090010",
          "district_id": "3205090",
          "name": "SINDANGSARI"
        },
        {
          "id": "3205090011",
          "district_id": "3205090",
          "name": "NEGLASARI"
        },
        {
          "id": "3205100001",
          "district_id": "3205100",
          "name": "PANGRUMASAN"
        },
        {
          "id": "3205100002",
          "district_id": "3205100",
          "name": "TOBLONG"
        },
        {
          "id": "3205100003",
          "district_id": "3205100",
          "name": "SUKANAGARA"
        },
        {
          "id": "3205100004",
          "district_id": "3205100",
          "name": "SARIBAKTI"
        },
        {
          "id": "3205100006",
          "district_id": "3205100",
          "name": "PURWAJAYA"
        },
        {
          "id": "3205110005",
          "district_id": "3205110",
          "name": "SINGAJAYA"
        },
        {
          "id": "3205110006",
          "district_id": "3205110",
          "name": "KARANGAGUNG"
        },
        {
          "id": "3205110007",
          "district_id": "3205110",
          "name": "MEKARTANI"
        },
        {
          "id": "3205110008",
          "district_id": "3205110",
          "name": "CIGINTUNG"
        },
        {
          "id": "3205110009",
          "district_id": "3205110",
          "name": "SUKAWANGI"
        },
        {
          "id": "3205110010",
          "district_id": "3205110",
          "name": "PANCASURA"
        },
        {
          "id": "3205110011",
          "district_id": "3205110",
          "name": "SUKAMULYA"
        },
        {
          "id": "3205110012",
          "district_id": "3205110",
          "name": "GIRIMUKTI"
        },
        {
          "id": "3205110013",
          "district_id": "3205110",
          "name": "CIUDIAN"
        },
        {
          "id": "3205111001",
          "district_id": "3205111",
          "name": "CISANGKAL"
        },
        {
          "id": "3205111002",
          "district_id": "3205111",
          "name": "JAYAMUKTI"
        },
        {
          "id": "3205111003",
          "district_id": "3205111",
          "name": "CIHURIP"
        },
        {
          "id": "3205111004",
          "district_id": "3205111",
          "name": "MEKARWANGI"
        },
        {
          "id": "3205120001",
          "district_id": "3205120",
          "name": "CIPANGRAMATAN"
        },
        {
          "id": "3205120002",
          "district_id": "3205120",
          "name": "MEKARJAYA"
        },
        {
          "id": "3205120003",
          "district_id": "3205120",
          "name": "GIRIJAYA"
        },
        {
          "id": "3205120004",
          "district_id": "3205120",
          "name": "GIRIAWAS"
        },
        {
          "id": "3205120005",
          "district_id": "3205120",
          "name": "CIBODAS"
        },
        {
          "id": "3205120006",
          "district_id": "3205120",
          "name": "CIKAJANG"
        },
        {
          "id": "3205120007",
          "district_id": "3205120",
          "name": "PADASUKA"
        },
        {
          "id": "3205120008",
          "district_id": "3205120",
          "name": "MEKARSARI"
        },
        {
          "id": "3205120009",
          "district_id": "3205120",
          "name": "SIMPANG"
        },
        {
          "id": "3205120010",
          "district_id": "3205120",
          "name": "CIKANDANG"
        },
        {
          "id": "3205120011",
          "district_id": "3205120",
          "name": "MARGAMULYA"
        },
        {
          "id": "3205120012",
          "district_id": "3205120",
          "name": "KARAMATWANGI"
        },
        {
          "id": "3205130001",
          "district_id": "3205130",
          "name": "DANGIANG"
        },
        {
          "id": "3205130002",
          "district_id": "3205130",
          "name": "JAYABAKTI"
        },
        {
          "id": "3205130003",
          "district_id": "3205130",
          "name": "BOJONG"
        },
        {
          "id": "3205130004",
          "district_id": "3205130",
          "name": "BANJARWANGI"
        },
        {
          "id": "3205130005",
          "district_id": "3205130",
          "name": "TALAGASARI"
        },
        {
          "id": "3205130006",
          "district_id": "3205130",
          "name": "PADAHURIP"
        },
        {
          "id": "3205130007",
          "district_id": "3205130",
          "name": "KADONGDONG"
        },
        {
          "id": "3205130008",
          "district_id": "3205130",
          "name": "WANGUNJAYA"
        },
        {
          "id": "3205130009",
          "district_id": "3205130",
          "name": "TALAGAJAYA"
        },
        {
          "id": "3205130010",
          "district_id": "3205130",
          "name": "TANJUNGJAYA"
        },
        {
          "id": "3205130011",
          "district_id": "3205130",
          "name": "MULYAJAYA"
        },
        {
          "id": "3205140001",
          "district_id": "3205140",
          "name": "SUKAMURNI"
        },
        {
          "id": "3205140002",
          "district_id": "3205140",
          "name": "SUKAMAJU"
        },
        {
          "id": "3205140003",
          "district_id": "3205140",
          "name": "SUKATANI"
        },
        {
          "id": "3205140004",
          "district_id": "3205140",
          "name": "MEKARSARI"
        },
        {
          "id": "3205140005",
          "district_id": "3205140",
          "name": "DAYEUHMANGGUNG"
        },
        {
          "id": "3205140006",
          "district_id": "3205140",
          "name": "KARYAMEKAR"
        },
        {
          "id": "3205140007",
          "district_id": "3205140",
          "name": "CILAWU"
        },
        {
          "id": "3205140008",
          "district_id": "3205140",
          "name": "PASANGGRAHAN"
        },
        {
          "id": "3205140009",
          "district_id": "3205140",
          "name": "MARGALAKSANA"
        },
        {
          "id": "3205140010",
          "district_id": "3205140",
          "name": "DAWUNGSARI"
        },
        {
          "id": "3205140011",
          "district_id": "3205140",
          "name": "DANGIANG"
        },
        {
          "id": "3205140012",
          "district_id": "3205140",
          "name": "SUKAMUKTI"
        },
        {
          "id": "3205140013",
          "district_id": "3205140",
          "name": "MEKARMUKTI"
        },
        {
          "id": "3205140014",
          "district_id": "3205140",
          "name": "SUKAHATI"
        },
        {
          "id": "3205140015",
          "district_id": "3205140",
          "name": "MANGKURAYAT"
        },
        {
          "id": "3205140016",
          "district_id": "3205140",
          "name": "DESAKOLOT"
        },
        {
          "id": "3205140017",
          "district_id": "3205140",
          "name": "NGAMPLANG"
        },
        {
          "id": "3205140018",
          "district_id": "3205140",
          "name": "NGAMPLANGSARI"
        },
        {
          "id": "3205150006",
          "district_id": "3205150",
          "name": "PAMALAYAN"
        },
        {
          "id": "3205150007",
          "district_id": "3205150",
          "name": "CIBURUY"
        },
        {
          "id": "3205150008",
          "district_id": "3205150",
          "name": "MULYASARI"
        },
        {
          "id": "3205150009",
          "district_id": "3205150",
          "name": "BAYONGBONG"
        },
        {
          "id": "3205150010",
          "district_id": "3205150",
          "name": "CINISTI"
        },
        {
          "id": "3205150011",
          "district_id": "3205150",
          "name": "CIELA"
        },
        {
          "id": "3205150012",
          "district_id": "3205150",
          "name": "KARYAJAYA"
        },
        {
          "id": "3205150013",
          "district_id": "3205150",
          "name": "PANEMBONG"
        },
        {
          "id": "3205150014",
          "district_id": "3205150",
          "name": "SUKARAME"
        },
        {
          "id": "3205150015",
          "district_id": "3205150",
          "name": "HEGARMANAH"
        },
        {
          "id": "3205150016",
          "district_id": "3205150",
          "name": "SIRNAGALIH"
        },
        {
          "id": "3205150017",
          "district_id": "3205150",
          "name": "SALAKURAY"
        },
        {
          "id": "3205150018",
          "district_id": "3205150",
          "name": "SUKASENANG"
        },
        {
          "id": "3205150019",
          "district_id": "3205150",
          "name": "CIKEDOKAN"
        },
        {
          "id": "3205150020",
          "district_id": "3205150",
          "name": "MEKARSARI"
        },
        {
          "id": "3205150021",
          "district_id": "3205150",
          "name": "BANJARSARI"
        },
        {
          "id": "3205150022",
          "district_id": "3205150",
          "name": "MEKARJAYA"
        },
        {
          "id": "3205150023",
          "district_id": "3205150",
          "name": "SUKAMANAH"
        },
        {
          "id": "3205151001",
          "district_id": "3205151",
          "name": "BARUSUDA"
        },
        {
          "id": "3205151002",
          "district_id": "3205151",
          "name": "CIGEDUG"
        },
        {
          "id": "3205151003",
          "district_id": "3205151",
          "name": "SUKAHURIP"
        },
        {
          "id": "3205151004",
          "district_id": "3205151",
          "name": "SINDANGSARI"
        },
        {
          "id": "3205151005",
          "district_id": "3205151",
          "name": "CINTANAGARA"
        },
        {
          "id": "3205160001",
          "district_id": "3205160",
          "name": "SUKAWARGI"
        },
        {
          "id": "3205160002",
          "district_id": "3205160",
          "name": "SUKATANI"
        },
        {
          "id": "3205160003",
          "district_id": "3205160",
          "name": "CIDATAR"
        },
        {
          "id": "3205160004",
          "district_id": "3205160",
          "name": "CISERO"
        },
        {
          "id": "3205160005",
          "district_id": "3205160",
          "name": "CISURUPAN"
        },
        {
          "id": "3205160006",
          "district_id": "3205160",
          "name": "KARAMATWANGI"
        },
        {
          "id": "3205160007",
          "district_id": "3205160",
          "name": "BALEWANGI"
        },
        {
          "id": "3205160008",
          "district_id": "3205160",
          "name": "TAMBAKBAYA"
        },
        {
          "id": "3205160009",
          "district_id": "3205160",
          "name": "SIRNAJAYA"
        },
        {
          "id": "3205160010",
          "district_id": "3205160",
          "name": "SIRNAGALIH"
        },
        {
          "id": "3205160011",
          "district_id": "3205160",
          "name": "PAKUWON"
        },
        {
          "id": "3205160012",
          "district_id": "3205160",
          "name": "SIMPANGSARI"
        },
        {
          "id": "3205160013",
          "district_id": "3205160",
          "name": "PANGAUBAN"
        },
        {
          "id": "3205160014",
          "district_id": "3205160",
          "name": "CIPAGANTI"
        },
        {
          "id": "3205160015",
          "district_id": "3205160",
          "name": "PAMULIHAN"
        },
        {
          "id": "3205160016",
          "district_id": "3205160",
          "name": "SITUSARI"
        },
        {
          "id": "3205160017",
          "district_id": "3205160",
          "name": "CINTAASIH"
        },
        {
          "id": "3205161001",
          "district_id": "3205161",
          "name": "SUKAMULYA"
        },
        {
          "id": "3205161002",
          "district_id": "3205161",
          "name": "SUKARESMI"
        },
        {
          "id": "3205161003",
          "district_id": "3205161",
          "name": "PADAMUKTI"
        },
        {
          "id": "3205161004",
          "district_id": "3205161",
          "name": "CINTADAMAI"
        },
        {
          "id": "3205161005",
          "district_id": "3205161",
          "name": "SUKAJAYA"
        },
        {
          "id": "3205161006",
          "district_id": "3205161",
          "name": "MEKARJAYA"
        },
        {
          "id": "3205161007",
          "district_id": "3205161",
          "name": "SUKALILAH"
        },
        {
          "id": "3205170013",
          "district_id": "3205170",
          "name": "CISARUA"
        },
        {
          "id": "3205170014",
          "district_id": "3205170",
          "name": "PARAKAN"
        },
        {
          "id": "3205170015",
          "district_id": "3205170",
          "name": "SUKALAKSANA"
        },
        {
          "id": "3205170016",
          "district_id": "3205170",
          "name": "SIRNASARI"
        },
        {
          "id": "3205170017",
          "district_id": "3205170",
          "name": "CINTAKARYA"
        },
        {
          "id": "3205170018",
          "district_id": "3205170",
          "name": "CINTARASA"
        },
        {
          "id": "3205170019",
          "district_id": "3205170",
          "name": "CINTARAYAT"
        },
        {
          "id": "3205170020",
          "district_id": "3205170",
          "name": "CINTAASIH"
        },
        {
          "id": "3205170021",
          "district_id": "3205170",
          "name": "SAMARANG"
        },
        {
          "id": "3205170022",
          "district_id": "3205170",
          "name": "SUKARASA"
        },
        {
          "id": "3205170023",
          "district_id": "3205170",
          "name": "SUKAKARYA"
        },
        {
          "id": "3205170024",
          "district_id": "3205170",
          "name": "TANJUNG KARYA"
        },
        {
          "id": "3205170025",
          "district_id": "3205170",
          "name": "TANJUNGANOM"
        },
        {
          "id": "3205171001",
          "district_id": "3205171",
          "name": "KARYAMEKAR"
        },
        {
          "id": "3205171002",
          "district_id": "3205171",
          "name": "SARIMUKTI"
        },
        {
          "id": "3205171003",
          "district_id": "3205171",
          "name": "TALAGA"
        },
        {
          "id": "3205171004",
          "district_id": "3205171",
          "name": "PASIRKIAMIS"
        },
        {
          "id": "3205171005",
          "district_id": "3205171",
          "name": "PADAMUKTI"
        },
        {
          "id": "3205171006",
          "district_id": "3205171",
          "name": "PADAASIH"
        },
        {
          "id": "3205171007",
          "district_id": "3205171",
          "name": "PADASUKA"
        },
        {
          "id": "3205171008",
          "district_id": "3205171",
          "name": "SIRNAJAYA"
        },
        {
          "id": "3205171009",
          "district_id": "3205171",
          "name": "PADAMULYA"
        },
        {
          "id": "3205171010",
          "district_id": "3205171",
          "name": "PASIRWANGI"
        },
        {
          "id": "3205171011",
          "district_id": "3205171",
          "name": "PADAAWAS"
        },
        {
          "id": "3205171012",
          "district_id": "3205171",
          "name": "BARUSARI"
        },
        {
          "id": "3205181001",
          "district_id": "3205181",
          "name": "KERSAMENAK"
        },
        {
          "id": "3205181002",
          "district_id": "3205181",
          "name": "CIBUNAR"
        },
        {
          "id": "3205181003",
          "district_id": "3205181",
          "name": "SUKABAKTI"
        },
        {
          "id": "3205181004",
          "district_id": "3205181",
          "name": "SUKAKARYA"
        },
        {
          "id": "3205181005",
          "district_id": "3205181",
          "name": "SUKAJAYA"
        },
        {
          "id": "3205181006",
          "district_id": "3205181",
          "name": "JAYAWARAS"
        },
        {
          "id": "3205181007",
          "district_id": "3205181",
          "name": "HAURPANGGUNG"
        },
        {
          "id": "3205181008",
          "district_id": "3205181",
          "name": "JAYARAGA"
        },
        {
          "id": "3205181009",
          "district_id": "3205181",
          "name": "PATARUMAN"
        },
        {
          "id": "3205181010",
          "district_id": "3205181",
          "name": "SUKAGALIH"
        },
        {
          "id": "3205181011",
          "district_id": "3205181",
          "name": "MEKARGALIH"
        },
        {
          "id": "3205181012",
          "district_id": "3205181",
          "name": "TAROGONG"
        },
        {
          "id": "3205182001",
          "district_id": "3205182",
          "name": "SIRNAJAYA"
        },
        {
          "id": "3205182002",
          "district_id": "3205182",
          "name": "MEKARWANGI"
        },
        {
          "id": "3205182003",
          "district_id": "3205182",
          "name": "SUKAJADI"
        },
        {
          "id": "3205182004",
          "district_id": "3205182",
          "name": "SUKAWANGI"
        },
        {
          "id": "3205182005",
          "district_id": "3205182",
          "name": "PANJIWANGI"
        },
        {
          "id": "3205182006",
          "district_id": "3205182",
          "name": "MEKARJAYA"
        },
        {
          "id": "3205182007",
          "district_id": "3205182",
          "name": "RANCABANGO"
        },
        {
          "id": "3205182008",
          "district_id": "3205182",
          "name": "LANGENSARI"
        },
        {
          "id": "3205182009",
          "district_id": "3205182",
          "name": "CIMANGANTEN"
        },
        {
          "id": "3205182010",
          "district_id": "3205182",
          "name": "JATI"
        },
        {
          "id": "3205182011",
          "district_id": "3205182",
          "name": "TANJUNGKAMUNING"
        },
        {
          "id": "3205182012",
          "district_id": "3205182",
          "name": "PANANJUNG"
        },
        {
          "id": "3205182013",
          "district_id": "3205182",
          "name": "PASAWAHAN"
        },
        {
          "id": "3205190001",
          "district_id": "3205190",
          "name": "MARGAWATI"
        },
        {
          "id": "3205190002",
          "district_id": "3205190",
          "name": "SUKANEGLA"
        },
        {
          "id": "3205190003",
          "district_id": "3205190",
          "name": "CIMUNCANG"
        },
        {
          "id": "3205190004",
          "district_id": "3205190",
          "name": "KOTAWETAN"
        },
        {
          "id": "3205190005",
          "district_id": "3205190",
          "name": "KOTAKULON"
        },
        {
          "id": "3205190006",
          "district_id": "3205190",
          "name": "MUARA SANDING"
        },
        {
          "id": "3205190007",
          "district_id": "3205190",
          "name": "PAMINGGIR"
        },
        {
          "id": "3205190008",
          "district_id": "3205190",
          "name": "REGOL"
        },
        {
          "id": "3205190009",
          "district_id": "3205190",
          "name": "CIWALEN"
        },
        {
          "id": "3205190010",
          "district_id": "3205190",
          "name": "PAKUWON"
        },
        {
          "id": "3205190011",
          "district_id": "3205190",
          "name": "SUKAMANTRI"
        },
        {
          "id": "3205200001",
          "district_id": "3205200",
          "name": "LEBAKAGUNG"
        },
        {
          "id": "3205200002",
          "district_id": "3205200",
          "name": "SUCI"
        },
        {
          "id": "3205200003",
          "district_id": "3205200",
          "name": "LEBAKJAYA"
        },
        {
          "id": "3205200004",
          "district_id": "3205200",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3205200005",
          "district_id": "3205200",
          "name": "GODOG"
        },
        {
          "id": "3205200006",
          "district_id": "3205200",
          "name": "SINDANGGALIH"
        },
        {
          "id": "3205200007",
          "district_id": "3205200",
          "name": "SINDANGPALAY"
        },
        {
          "id": "3205200008",
          "district_id": "3205200",
          "name": "KARANGPAWITAN"
        },
        {
          "id": "3205200009",
          "district_id": "3205200",
          "name": "SITUGEDE"
        },
        {
          "id": "3205200010",
          "district_id": "3205200",
          "name": "SITUSARI"
        },
        {
          "id": "3205200011",
          "district_id": "3205200",
          "name": "SITUJAYA"
        },
        {
          "id": "3205200012",
          "district_id": "3205200",
          "name": "SITUSAEUR"
        },
        {
          "id": "3205200013",
          "district_id": "3205200",
          "name": "KARANGSARI"
        },
        {
          "id": "3205200014",
          "district_id": "3205200",
          "name": "CIMURAH"
        },
        {
          "id": "3205200015",
          "district_id": "3205200",
          "name": "SINDANGLAYA"
        },
        {
          "id": "3205200016",
          "district_id": "3205200",
          "name": "MEKARSARI"
        },
        {
          "id": "3205200017",
          "district_id": "3205200",
          "name": "JATISARI"
        },
        {
          "id": "3205200018",
          "district_id": "3205200",
          "name": "KARANGMULYA"
        },
        {
          "id": "3205200019",
          "district_id": "3205200",
          "name": "SUCIKALER"
        },
        {
          "id": "3205200020",
          "district_id": "3205200",
          "name": "LENGKONGJAYA"
        },
        {
          "id": "3205210005",
          "district_id": "3205210",
          "name": "SUKAMENAK"
        },
        {
          "id": "3205210007",
          "district_id": "3205210",
          "name": "SINDANGMEKAR"
        },
        {
          "id": "3205210008",
          "district_id": "3205210",
          "name": "SINDANGRATU"
        },
        {
          "id": "3205210012",
          "district_id": "3205210",
          "name": "CINUNUK"
        },
        {
          "id": "3205210013",
          "district_id": "3205210",
          "name": "WANAMEKAR"
        },
        {
          "id": "3205210014",
          "district_id": "3205210",
          "name": "WANARAJA"
        },
        {
          "id": "3205210015",
          "district_id": "3205210",
          "name": "WANASARI"
        },
        {
          "id": "3205210016",
          "district_id": "3205210",
          "name": "WANAJAYA"
        },
        {
          "id": "3205210017",
          "district_id": "3205210",
          "name": "SINDANGPRABU"
        },
        {
          "id": "3205211001",
          "district_id": "3205211",
          "name": "LINGGAMUKTI"
        },
        {
          "id": "3205211002",
          "district_id": "3205211",
          "name": "SUKALAKSANA"
        },
        {
          "id": "3205211003",
          "district_id": "3205211",
          "name": "CIGADOG"
        },
        {
          "id": "3205211004",
          "district_id": "3205211",
          "name": "TENJONAGARA"
        },
        {
          "id": "3205211005",
          "district_id": "3205211",
          "name": "SUKARATU"
        },
        {
          "id": "3205211006",
          "district_id": "3205211",
          "name": "TEGALPANJANG"
        },
        {
          "id": "3205211007",
          "district_id": "3205211",
          "name": "SADANG"
        },
        {
          "id": "3205212001",
          "district_id": "3205212",
          "name": "SUKAHURIP"
        },
        {
          "id": "3205212002",
          "district_id": "3205212",
          "name": "BABAKANLOA"
        },
        {
          "id": "3205212003",
          "district_id": "3205212",
          "name": "SUKARASA"
        },
        {
          "id": "3205212004",
          "district_id": "3205212",
          "name": "CIMARAGAS"
        },
        {
          "id": "3205212005",
          "district_id": "3205212",
          "name": "CIHUNI"
        },
        {
          "id": "3205212006",
          "district_id": "3205212",
          "name": "CITANGTU"
        },
        {
          "id": "3205212007",
          "district_id": "3205212",
          "name": "KARANGSARI"
        },
        {
          "id": "3205212008",
          "district_id": "3205212",
          "name": "SUKAMULYA"
        },
        {
          "id": "3205220001",
          "district_id": "3205220",
          "name": "PASANGGRAHAN"
        },
        {
          "id": "3205220006",
          "district_id": "3205220",
          "name": "SUKAMUKTI"
        },
        {
          "id": "3205220007",
          "district_id": "3205220",
          "name": "MEKARLUYU"
        },
        {
          "id": "3205220008",
          "district_id": "3205220",
          "name": "SUKAWENING"
        },
        {
          "id": "3205220009",
          "district_id": "3205220",
          "name": "SUDALARANG"
        },
        {
          "id": "3205220010",
          "district_id": "3205220",
          "name": "MARIPARI"
        },
        {
          "id": "3205220011",
          "district_id": "3205220",
          "name": "SUKAHAJI"
        },
        {
          "id": "3205220012",
          "district_id": "3205220",
          "name": "SUKASONO"
        },
        {
          "id": "3205220013",
          "district_id": "3205220",
          "name": "SUKALUYU"
        },
        {
          "id": "3205220014",
          "district_id": "3205220",
          "name": "MEKARWANGI"
        },
        {
          "id": "3205220015",
          "district_id": "3205220",
          "name": "MEKARHURIP"
        },
        {
          "id": "3205221001",
          "district_id": "3205221",
          "name": "SINDANGGALIH"
        },
        {
          "id": "3205221002",
          "district_id": "3205221",
          "name": "CINTA"
        },
        {
          "id": "3205221003",
          "district_id": "3205221",
          "name": "CINTAMANIK"
        },
        {
          "id": "3205221004",
          "district_id": "3205221",
          "name": "CARINGIN"
        },
        {
          "id": "3205230003",
          "district_id": "3205230",
          "name": "SUKARAJA"
        },
        {
          "id": "3205230004",
          "district_id": "3205230",
          "name": "SUKAKARYA"
        },
        {
          "id": "3205230005",
          "district_id": "3205230",
          "name": "PAMEKARSARI"
        },
        {
          "id": "3205230006",
          "district_id": "3205230",
          "name": "SUKASENANG"
        },
        {
          "id": "3205230007",
          "district_id": "3205230",
          "name": "SUKARATU"
        },
        {
          "id": "3205230008",
          "district_id": "3205230",
          "name": "CIPICUNG"
        },
        {
          "id": "3205230009",
          "district_id": "3205230",
          "name": "BAGENDIT"
        },
        {
          "id": "3205230010",
          "district_id": "3205230",
          "name": "SUKAMUKTI"
        },
        {
          "id": "3205230011",
          "district_id": "3205230",
          "name": "BANYURESMI"
        },
        {
          "id": "3205230012",
          "district_id": "3205230",
          "name": "BINAKARYA"
        },
        {
          "id": "3205230013",
          "district_id": "3205230",
          "name": "DANGDEUR"
        },
        {
          "id": "3205230014",
          "district_id": "3205230",
          "name": "KARYASARI"
        },
        {
          "id": "3205230015",
          "district_id": "3205230",
          "name": "KARYAMUKTI"
        },
        {
          "id": "3205230016",
          "district_id": "3205230",
          "name": "CIMAREME"
        },
        {
          "id": "3205230017",
          "district_id": "3205230",
          "name": "SUKALAKSANA"
        },
        {
          "id": "3205240001",
          "district_id": "3205240",
          "name": "DANO"
        },
        {
          "id": "3205240002",
          "district_id": "3205240",
          "name": "JANGKURANG"
        },
        {
          "id": "3205240003",
          "district_id": "3205240",
          "name": "LEMBANG"
        },
        {
          "id": "3205240004",
          "district_id": "3205240",
          "name": "CIPANCAR"
        },
        {
          "id": "3205240005",
          "district_id": "3205240",
          "name": "KANDANGMUKTI"
        },
        {
          "id": "3205240006",
          "district_id": "3205240",
          "name": "CIBURIAL"
        },
        {
          "id": "3205240007",
          "district_id": "3205240",
          "name": "SALAMNUNGGAL"
        },
        {
          "id": "3205240009",
          "district_id": "3205240",
          "name": "HARUMAN"
        },
        {
          "id": "3205240010",
          "district_id": "3205240",
          "name": "MARGALUYU"
        },
        {
          "id": "3205240011",
          "district_id": "3205240",
          "name": "CANGKUANG"
        },
        {
          "id": "3205240012",
          "district_id": "3205240",
          "name": "SUKARAME"
        },
        {
          "id": "3205250001",
          "district_id": "3205250",
          "name": "MARGAHAYU"
        },
        {
          "id": "3205250002",
          "district_id": "3205250",
          "name": "MARGACINTA"
        },
        {
          "id": "3205250003",
          "district_id": "3205250",
          "name": "DUNGUSIKU"
        },
        {
          "id": "3205250004",
          "district_id": "3205250",
          "name": "TAMBAKSARI"
        },
        {
          "id": "3205250005",
          "district_id": "3205250",
          "name": "KARANGSARI"
        },
        {
          "id": "3205250006",
          "district_id": "3205250",
          "name": "KARANGANYAR"
        },
        {
          "id": "3205250007",
          "district_id": "3205250",
          "name": "SINDANGSARI"
        },
        {
          "id": "3205250008",
          "district_id": "3205250",
          "name": "LEUWIGOONG"
        },
        {
          "id": "3205260001",
          "district_id": "3205260",
          "name": "CIBATU"
        },
        {
          "id": "3205260002",
          "district_id": "3205260",
          "name": "KERTAJAYA"
        },
        {
          "id": "3205260003",
          "district_id": "3205260",
          "name": "WANAKERTA"
        },
        {
          "id": "3205260004",
          "district_id": "3205260",
          "name": "KARYAMUKTI"
        },
        {
          "id": "3205260005",
          "district_id": "3205260",
          "name": "PADASUKA"
        },
        {
          "id": "3205260007",
          "district_id": "3205260",
          "name": "CIBUNAR"
        },
        {
          "id": "3205260008",
          "district_id": "3205260",
          "name": "SUKALILAH"
        },
        {
          "id": "3205260009",
          "district_id": "3205260",
          "name": "GIRIMUKTI"
        },
        {
          "id": "3205260015",
          "district_id": "3205260",
          "name": "MEKARSARI"
        },
        {
          "id": "3205260016",
          "district_id": "3205260",
          "name": "SINDANGSUKA"
        },
        {
          "id": "3205261001",
          "district_id": "3205261",
          "name": "SUKAMAJU"
        },
        {
          "id": "3205261002",
          "district_id": "3205261",
          "name": "GIRIJAYA"
        },
        {
          "id": "3205261003",
          "district_id": "3205261",
          "name": "KERSAMANAH"
        },
        {
          "id": "3205261004",
          "district_id": "3205261",
          "name": "NANJUNGJAYA"
        },
        {
          "id": "3205261005",
          "district_id": "3205261",
          "name": "SUKAMERANG"
        },
        {
          "id": "3205261006",
          "district_id": "3205261",
          "name": "MEKARRAYA"
        },
        {
          "id": "3205270001",
          "district_id": "3205270",
          "name": "CIPAREUAN"
        },
        {
          "id": "3205270002",
          "district_id": "3205270",
          "name": "CIBIUK KIDUL"
        },
        {
          "id": "3205270003",
          "district_id": "3205270",
          "name": "CIBIUK KALER"
        },
        {
          "id": "3205270004",
          "district_id": "3205270",
          "name": "MAJASARI"
        },
        {
          "id": "3205270005",
          "district_id": "3205270",
          "name": "LINGKUNGPASIR"
        },
        {
          "id": "3205280001",
          "district_id": "3205280",
          "name": "RANCASALAK"
        },
        {
          "id": "3205280002",
          "district_id": "3205280",
          "name": "MANDALASARI"
        },
        {
          "id": "3205280003",
          "district_id": "3205280",
          "name": "HEGARSARI"
        },
        {
          "id": "3205280004",
          "district_id": "3205280",
          "name": "TALAGASARI"
        },
        {
          "id": "3205280005",
          "district_id": "3205280",
          "name": "KARANGMULYA"
        },
        {
          "id": "3205280006",
          "district_id": "3205280",
          "name": "KARANGTENGAH"
        },
        {
          "id": "3205280007",
          "district_id": "3205280",
          "name": "GANDAMEKAR"
        },
        {
          "id": "3205280008",
          "district_id": "3205280",
          "name": "KADUNGORA"
        },
        {
          "id": "3205280009",
          "district_id": "3205280",
          "name": "NEGLASARI"
        },
        {
          "id": "3205280010",
          "district_id": "3205280",
          "name": "CIKEMBULAN"
        },
        {
          "id": "3205280011",
          "district_id": "3205280",
          "name": "CISAAT"
        },
        {
          "id": "3205280012",
          "district_id": "3205280",
          "name": "MEKARBAKTI"
        },
        {
          "id": "3205280013",
          "district_id": "3205280",
          "name": "TANGGULUN"
        },
        {
          "id": "3205280014",
          "district_id": "3205280",
          "name": "HARUMANSARI"
        },
        {
          "id": "3205290002",
          "district_id": "3205290",
          "name": "CIJOLANG"
        },
        {
          "id": "3205290003",
          "district_id": "3205290",
          "name": "CIGAGADE"
        },
        {
          "id": "3205290004",
          "district_id": "3205290",
          "name": "PASIRWARU"
        },
        {
          "id": "3205290005",
          "district_id": "3205290",
          "name": "LIMBANGANBARAT"
        },
        {
          "id": "3205290006",
          "district_id": "3205290",
          "name": "LIMBANGANTENGAH"
        },
        {
          "id": "3205290007",
          "district_id": "3205290",
          "name": "LIMBANGANTIMUR"
        },
        {
          "id": "3205290008",
          "district_id": "3205290",
          "name": "DUNGUSWIRU"
        },
        {
          "id": "3205290009",
          "district_id": "3205290",
          "name": "GALIHPAKUWON"
        },
        {
          "id": "3205290010",
          "district_id": "3205290",
          "name": "NEGLASARI"
        },
        {
          "id": "3205290011",
          "district_id": "3205290",
          "name": "SURABAYA"
        },
        {
          "id": "3205290012",
          "district_id": "3205290",
          "name": "CIWANGI"
        },
        {
          "id": "3205290013",
          "district_id": "3205290",
          "name": "PANGEUREUNAN"
        },
        {
          "id": "3205290014",
          "district_id": "3205290",
          "name": "SIMPEN KIDUL"
        },
        {
          "id": "3205290015",
          "district_id": "3205290",
          "name": "SIMPEN KALER"
        },
        {
          "id": "3205300001",
          "district_id": "3205300",
          "name": "CIGAWIR"
        },
        {
          "id": "3205300002",
          "district_id": "3205300",
          "name": "PUTRAJAWA"
        },
        {
          "id": "3205300003",
          "district_id": "3205300",
          "name": "MEKARSARI"
        },
        {
          "id": "3205300004",
          "district_id": "3205300",
          "name": "SELAAWI"
        },
        {
          "id": "3205300005",
          "district_id": "3205300",
          "name": "CIRAPUHAN"
        },
        {
          "id": "3205300006",
          "district_id": "3205300",
          "name": "SAMIDA"
        },
        {
          "id": "3205300007",
          "district_id": "3205300",
          "name": "PELITAASIH"
        },
        {
          "id": "3205310001",
          "district_id": "3205310",
          "name": "KUTANAGARA"
        },
        {
          "id": "3205310002",
          "district_id": "3205310",
          "name": "SANDING"
        },
        {
          "id": "3205310003",
          "district_id": "3205310",
          "name": "KARANGMULYA"
        },
        {
          "id": "3205310004",
          "district_id": "3205310",
          "name": "CINAGARA"
        },
        {
          "id": "3205310005",
          "district_id": "3205310",
          "name": "CIKARAG"
        },
        {
          "id": "3205310006",
          "district_id": "3205310",
          "name": "CAMPAKA"
        },
        {
          "id": "3205310007",
          "district_id": "3205310",
          "name": "SUKAMANAH"
        },
        {
          "id": "3205310008",
          "district_id": "3205310",
          "name": "CIHAURKUNING"
        },
        {
          "id": "3205310009",
          "district_id": "3205310",
          "name": "CITERAS"
        },
        {
          "id": "3205310010",
          "district_id": "3205310",
          "name": "LEWOBARU"
        },
        {
          "id": "3205310011",
          "district_id": "3205310",
          "name": "SUKARATU"
        },
        {
          "id": "3205310012",
          "district_id": "3205310",
          "name": "SUKARASA"
        },
        {
          "id": "3205310013",
          "district_id": "3205310",
          "name": "CIBUNAR"
        },
        {
          "id": "3205310014",
          "district_id": "3205310",
          "name": "MALANGBONG"
        },
        {
          "id": "3205310015",
          "district_id": "3205310",
          "name": "CISITU"
        },
        {
          "id": "3205310016",
          "district_id": "3205310",
          "name": "SAKAWAYANA"
        },
        {
          "id": "3205310017",
          "district_id": "3205310",
          "name": "SEKARWANGI"
        },
        {
          "id": "3205310018",
          "district_id": "3205310",
          "name": "MEKARASIH"
        },
        {
          "id": "3205310019",
          "district_id": "3205310",
          "name": "CILAMPUYANG"
        },
        {
          "id": "3205310020",
          "district_id": "3205310",
          "name": "MEKAR MULYA"
        },
        {
          "id": "3205310021",
          "district_id": "3205310",
          "name": "BARUDUA"
        },
        {
          "id": "3205310022",
          "district_id": "3205310",
          "name": "BUNISARI"
        },
        {
          "id": "3205310023",
          "district_id": "3205310",
          "name": "SUKAJAYA"
        },
        {
          "id": "3205310024",
          "district_id": "3205310",
          "name": "GIRIMAKMUR"
        },
        {
          "id": "3206010001",
          "district_id": "3206010",
          "name": "CIHERAS"
        },
        {
          "id": "3206010002",
          "district_id": "3206010",
          "name": "CIPANAS"
        },
        {
          "id": "3206010003",
          "district_id": "3206010",
          "name": "CIANDUM"
        },
        {
          "id": "3206010004",
          "district_id": "3206010",
          "name": "CIPATUJAH"
        },
        {
          "id": "3206010005",
          "district_id": "3206010",
          "name": "SINDANGKERTA"
        },
        {
          "id": "3206010006",
          "district_id": "3206010",
          "name": "CIKAWUNGADING"
        },
        {
          "id": "3206010007",
          "district_id": "3206010",
          "name": "KERTASARI"
        },
        {
          "id": "3206010008",
          "district_id": "3206010",
          "name": "PADAWARAS"
        },
        {
          "id": "3206010009",
          "district_id": "3206010",
          "name": "DARAWATI"
        },
        {
          "id": "3206010010",
          "district_id": "3206010",
          "name": "BANTARKALONG"
        },
        {
          "id": "3206010011",
          "district_id": "3206010",
          "name": "TOBONGJAYA"
        },
        {
          "id": "3206010012",
          "district_id": "3206010",
          "name": "NANGELASARI"
        },
        {
          "id": "3206010013",
          "district_id": "3206010",
          "name": "NAGROG"
        },
        {
          "id": "3206010014",
          "district_id": "3206010",
          "name": "PAMEUTINGAN"
        },
        {
          "id": "3206010015",
          "district_id": "3206010",
          "name": "SUKAHURIP"
        },
        {
          "id": "3206020001",
          "district_id": "3206020",
          "name": "CIDADAP"
        },
        {
          "id": "3206020002",
          "district_id": "3206020",
          "name": "KUJANG"
        },
        {
          "id": "3206020003",
          "district_id": "3206020",
          "name": "SARIMUKTI"
        },
        {
          "id": "3206020004",
          "district_id": "3206020",
          "name": "CIAWI"
        },
        {
          "id": "3206020005",
          "district_id": "3206020",
          "name": "CIKAPINIS"
        },
        {
          "id": "3206020006",
          "district_id": "3206020",
          "name": "CIKUPA"
        },
        {
          "id": "3206020007",
          "district_id": "3206020",
          "name": "KARANGNUNGGAL"
        },
        {
          "id": "3206020008",
          "district_id": "3206020",
          "name": "KARANGMEKAR"
        },
        {
          "id": "3206020009",
          "district_id": "3206020",
          "name": "CIBATUIRENG"
        },
        {
          "id": "3206020010",
          "district_id": "3206020",
          "name": "CIBATU"
        },
        {
          "id": "3206020011",
          "district_id": "3206020",
          "name": "SUKAWANGUN"
        },
        {
          "id": "3206020012",
          "district_id": "3206020",
          "name": "CINTAWANGI"
        },
        {
          "id": "3206020013",
          "district_id": "3206020",
          "name": "CIKUKULU"
        },
        {
          "id": "3206020014",
          "district_id": "3206020",
          "name": "SARIMANGGU"
        },
        {
          "id": "3206030001",
          "district_id": "3206030",
          "name": "MANDALAJAYA"
        },
        {
          "id": "3206030002",
          "district_id": "3206030",
          "name": "CIKALONG"
        },
        {
          "id": "3206030003",
          "district_id": "3206030",
          "name": "CIKANCRA"
        },
        {
          "id": "3206030004",
          "district_id": "3206030",
          "name": "TONJONGSARI"
        },
        {
          "id": "3206030005",
          "district_id": "3206030",
          "name": "SINGKIR"
        },
        {
          "id": "3206030006",
          "district_id": "3206030",
          "name": "PANYIARAN"
        },
        {
          "id": "3206030007",
          "district_id": "3206030",
          "name": "CIBEBER"
        },
        {
          "id": "3206030008",
          "district_id": "3206030",
          "name": "CIDADALI"
        },
        {
          "id": "3206030009",
          "district_id": "3206030",
          "name": "KUBANGSARI"
        },
        {
          "id": "3206030010",
          "district_id": "3206030",
          "name": "CIKADU"
        },
        {
          "id": "3206030011",
          "district_id": "3206030",
          "name": "SINDANGJAYA"
        },
        {
          "id": "3206030012",
          "district_id": "3206030",
          "name": "KALAPAGENEP"
        },
        {
          "id": "3206030013",
          "district_id": "3206030",
          "name": "CIMANUK"
        },
        {
          "id": "3206040001",
          "district_id": "3206040",
          "name": "MARGALUYU"
        },
        {
          "id": "3206040002",
          "district_id": "3206040",
          "name": "CIBUNIASIH"
        },
        {
          "id": "3206040003",
          "district_id": "3206040",
          "name": "PANGLIARAN"
        },
        {
          "id": "3206040004",
          "district_id": "3206040",
          "name": "PANCAWANGI"
        },
        {
          "id": "3206040005",
          "district_id": "3206040",
          "name": "JAYAMUKTI"
        },
        {
          "id": "3206040006",
          "district_id": "3206040",
          "name": "TONJONG"
        },
        {
          "id": "3206040007",
          "district_id": "3206040",
          "name": "CIBONGAS"
        },
        {
          "id": "3206040008",
          "district_id": "3206040",
          "name": "CIKAWUNG"
        },
        {
          "id": "3206040009",
          "district_id": "3206040",
          "name": "TAWANG"
        },
        {
          "id": "3206040010",
          "district_id": "3206040",
          "name": "MEKARSARI"
        },
        {
          "id": "3206040011",
          "district_id": "3206040",
          "name": "NEGLASARI"
        },
        {
          "id": "3206050001",
          "district_id": "3206050",
          "name": "GUNUNGSARI"
        },
        {
          "id": "3206050002",
          "district_id": "3206050",
          "name": "CILUMBA"
        },
        {
          "id": "3206050003",
          "district_id": "3206050",
          "name": "LINGGALAKSANA"
        },
        {
          "id": "3206050004",
          "district_id": "3206050",
          "name": "PAKEMITAN"
        },
        {
          "id": "3206050005",
          "district_id": "3206050",
          "name": "COGREG"
        },
        {
          "id": "3206050006",
          "district_id": "3206050",
          "name": "LENGKONGBARANG"
        },
        {
          "id": "3206050007",
          "district_id": "3206050",
          "name": "TANJUNGBARANG"
        },
        {
          "id": "3206050008",
          "district_id": "3206050",
          "name": "CAYUR"
        },
        {
          "id": "3206050009",
          "district_id": "3206050",
          "name": "SINDANGASIH"
        },
        {
          "id": "3206060001",
          "district_id": "3206060",
          "name": "EUREUNPALAY"
        },
        {
          "id": "3206060002",
          "district_id": "3206060",
          "name": "SETIAWARAS"
        },
        {
          "id": "3206060003",
          "district_id": "3206060",
          "name": "CISEMPUR"
        },
        {
          "id": "3206060004",
          "district_id": "3206060",
          "name": "PARUNG"
        },
        {
          "id": "3206060005",
          "district_id": "3206060",
          "name": "CIBALONG"
        },
        {
          "id": "3206060014",
          "district_id": "3206060",
          "name": "SINGAJAYA"
        },
        {
          "id": "3206061001",
          "district_id": "3206061",
          "name": "KARYABAKTI"
        },
        {
          "id": "3206061002",
          "district_id": "3206061",
          "name": "CIGUNUNG"
        },
        {
          "id": "3206061003",
          "district_id": "3206061",
          "name": "CIBUNGUR"
        },
        {
          "id": "3206061004",
          "district_id": "3206061",
          "name": "PARUNGPONTENG"
        },
        {
          "id": "3206061005",
          "district_id": "3206061",
          "name": "GIRIKENCANA"
        },
        {
          "id": "3206061006",
          "district_id": "3206061",
          "name": "BARUMEKAR"
        },
        {
          "id": "3206061007",
          "district_id": "3206061",
          "name": "CIBANTENG"
        },
        {
          "id": "3206061008",
          "district_id": "3206061",
          "name": "BURUJULJAYA"
        },
        {
          "id": "3206070002",
          "district_id": "3206070",
          "name": "SIRNAGALIH"
        },
        {
          "id": "3206070003",
          "district_id": "3206070",
          "name": "SIMPANG"
        },
        {
          "id": "3206070004",
          "district_id": "3206070",
          "name": "HEGARWANGI"
        },
        {
          "id": "3206070008",
          "district_id": "3206070",
          "name": "WANGUNSARI"
        },
        {
          "id": "3206070009",
          "district_id": "3206070",
          "name": "PAMIJAHAN"
        },
        {
          "id": "3206070010",
          "district_id": "3206070",
          "name": "PARAKANHONJE"
        },
        {
          "id": "3206070016",
          "district_id": "3206070",
          "name": "SUKAMAJU"
        },
        {
          "id": "3206070017",
          "district_id": "3206070",
          "name": "WAKAP"
        },
        {
          "id": "3206071001",
          "district_id": "3206071",
          "name": "GIRIJAYA"
        },
        {
          "id": "3206071002",
          "district_id": "3206071",
          "name": "BOJONGASIH"
        },
        {
          "id": "3206071003",
          "district_id": "3206071",
          "name": "SINDANGSARI"
        },
        {
          "id": "3206071004",
          "district_id": "3206071",
          "name": "CIKADONGDONG"
        },
        {
          "id": "3206071005",
          "district_id": "3206071",
          "name": "MERTAJAYA"
        },
        {
          "id": "3206071006",
          "district_id": "3206071",
          "name": "TOBLONGAN"
        },
        {
          "id": "3206072001",
          "district_id": "3206072",
          "name": "BOJONGSARI"
        },
        {
          "id": "3206072002",
          "district_id": "3206072",
          "name": "CINTABODAS"
        },
        {
          "id": "3206072003",
          "district_id": "3206072",
          "name": "CIKUYA"
        },
        {
          "id": "3206072004",
          "district_id": "3206072",
          "name": "CIPICUNG"
        },
        {
          "id": "3206072005",
          "district_id": "3206072",
          "name": "MEKARLAKSANA"
        },
        {
          "id": "3206080001",
          "district_id": "3206080",
          "name": "CAMPAKASARI"
        },
        {
          "id": "3206080002",
          "district_id": "3206080",
          "name": "BOJONGKAPOL"
        },
        {
          "id": "3206080003",
          "district_id": "3206080",
          "name": "WANDASARI"
        },
        {
          "id": "3206080004",
          "district_id": "3206080",
          "name": "PEDANGKAMULYAN"
        },
        {
          "id": "3206080005",
          "district_id": "3206080",
          "name": "KERTANEGLA"
        },
        {
          "id": "3206080006",
          "district_id": "3206080",
          "name": "BOJONGGAMBIR"
        },
        {
          "id": "3206080007",
          "district_id": "3206080",
          "name": "MANGKONJAYA"
        },
        {
          "id": "3206080008",
          "district_id": "3206080",
          "name": "CIROYOM"
        },
        {
          "id": "3206080009",
          "district_id": "3206080",
          "name": "PURWARAHARJA"
        },
        {
          "id": "3206080010",
          "district_id": "3206080",
          "name": "GIRIMUKTI"
        },
        {
          "id": "3206090001",
          "district_id": "3206090",
          "name": "CUKANGJAYAGUNA"
        },
        {
          "id": "3206090002",
          "district_id": "3206090",
          "name": "SUKABAKTI"
        },
        {
          "id": "3206090003",
          "district_id": "3206090",
          "name": "PARUMASAN"
        },
        {
          "id": "3206090004",
          "district_id": "3206090",
          "name": "SEPATNUNGGAL"
        },
        {
          "id": "3206090005",
          "district_id": "3206090",
          "name": "RAKSAJAYA"
        },
        {
          "id": "3206090006",
          "district_id": "3206090",
          "name": "SODONGHILIR"
        },
        {
          "id": "3206090007",
          "district_id": "3206090",
          "name": "CUKANGKAWUNG"
        },
        {
          "id": "3206090008",
          "district_id": "3206090",
          "name": "CIKALONG"
        },
        {
          "id": "3206090009",
          "district_id": "3206090",
          "name": "CIPAINGEUN"
        },
        {
          "id": "3206090010",
          "district_id": "3206090",
          "name": "PAKALONGAN"
        },
        {
          "id": "3206090011",
          "district_id": "3206090",
          "name": "LEUWIDULANG"
        },
        {
          "id": "3206090012",
          "district_id": "3206090",
          "name": "MUNCANG"
        },
        {
          "id": "3206100001",
          "district_id": "3206100",
          "name": "BANYUASIH"
        },
        {
          "id": "3206100002",
          "district_id": "3206100",
          "name": "TARAJU"
        },
        {
          "id": "3206100003",
          "district_id": "3206100",
          "name": "RAKSASARI"
        },
        {
          "id": "3206100004",
          "district_id": "3206100",
          "name": "SINGASARI"
        },
        {
          "id": "3206100005",
          "district_id": "3206100",
          "name": "CIKUBANG"
        },
        {
          "id": "3206100007",
          "district_id": "3206100",
          "name": "KERTARAHARJA"
        },
        {
          "id": "3206100008",
          "district_id": "3206100",
          "name": "PURWARAHAYU"
        },
        {
          "id": "3206100009",
          "district_id": "3206100",
          "name": "PAGERALAM"
        },
        {
          "id": "3206110009",
          "district_id": "3206110",
          "name": "SUKARASA"
        },
        {
          "id": "3206110010",
          "district_id": "3206110",
          "name": "JAHIANG"
        },
        {
          "id": "3206110011",
          "district_id": "3206110",
          "name": "SUNDAWENANG"
        },
        {
          "id": "3206110012",
          "district_id": "3206110",
          "name": "KAWUNGSARI"
        },
        {
          "id": "3206110013",
          "district_id": "3206110",
          "name": "TENJOWARINGIN"
        },
        {
          "id": "3206110014",
          "district_id": "3206110",
          "name": "KUTAWARINGIN"
        },
        {
          "id": "3206110015",
          "district_id": "3206110",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3206110016",
          "district_id": "3206110",
          "name": "NEGLASARI"
        },
        {
          "id": "3206110017",
          "district_id": "3206110",
          "name": "KARANGMUKTI"
        },
        {
          "id": "3206110018",
          "district_id": "3206110",
          "name": "SALAWU"
        },
        {
          "id": "3206110019",
          "district_id": "3206110",
          "name": "MARGALAKSANA"
        },
        {
          "id": "3206110020",
          "district_id": "3206110",
          "name": "SERANG"
        },
        {
          "id": "3206111001",
          "district_id": "3206111",
          "name": "MANDALASARI"
        },
        {
          "id": "3206111002",
          "district_id": "3206111",
          "name": "SUKASARI"
        },
        {
          "id": "3206111003",
          "district_id": "3206111",
          "name": "PUSPASARI"
        },
        {
          "id": "3206111004",
          "district_id": "3206111",
          "name": "PUSPAHIANG"
        },
        {
          "id": "3206111005",
          "district_id": "3206111",
          "name": "PUSPARAHAYU"
        },
        {
          "id": "3206111006",
          "district_id": "3206111",
          "name": "LUYUBAKTI"
        },
        {
          "id": "3206111007",
          "district_id": "3206111",
          "name": "CIMANGGU"
        },
        {
          "id": "3206111008",
          "district_id": "3206111",
          "name": "PUSPAJAYA"
        },
        {
          "id": "3206120001",
          "district_id": "3206120",
          "name": "CIKEUSAL"
        },
        {
          "id": "3206120002",
          "district_id": "3206120",
          "name": "SUKANAGARA"
        },
        {
          "id": "3206120003",
          "district_id": "3206120",
          "name": "SUKASENANG"
        },
        {
          "id": "3206120004",
          "district_id": "3206120",
          "name": "TANJUNGJAYA"
        },
        {
          "id": "3206120005",
          "district_id": "3206120",
          "name": "CINTAJAYA"
        },
        {
          "id": "3206120006",
          "district_id": "3206120",
          "name": "CIBALANARIK"
        },
        {
          "id": "3206120007",
          "district_id": "3206120",
          "name": "CILOLOHAN"
        },
        {
          "id": "3206130001",
          "district_id": "3206130",
          "name": "MEKARJAYA"
        },
        {
          "id": "3206130002",
          "district_id": "3206130",
          "name": "SIRNAJAYA"
        },
        {
          "id": "3206130003",
          "district_id": "3206130",
          "name": "JANGGALA"
        },
        {
          "id": "3206130004",
          "district_id": "3206130",
          "name": "SUKAPURA"
        },
        {
          "id": "3206130005",
          "district_id": "3206130",
          "name": "TARUNAJAYA"
        },
        {
          "id": "3206130006",
          "district_id": "3206130",
          "name": "LEUWIBUDAH"
        },
        {
          "id": "3206130007",
          "district_id": "3206130",
          "name": "LINGGARAJA"
        },
        {
          "id": "3206130008",
          "district_id": "3206130",
          "name": "MARGALAKSANA"
        },
        {
          "id": "3206140003",
          "district_id": "3206140",
          "name": "MANDALAHAYU"
        },
        {
          "id": "3206140004",
          "district_id": "3206140",
          "name": "KARYAMANDALA"
        },
        {
          "id": "3206140005",
          "district_id": "3206140",
          "name": "MULYASARI"
        },
        {
          "id": "3206140006",
          "district_id": "3206140",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3206140007",
          "district_id": "3206140",
          "name": "MANDALAGUNA"
        },
        {
          "id": "3206140008",
          "district_id": "3206140",
          "name": "KAWITAN"
        },
        {
          "id": "3206140009",
          "district_id": "3206140",
          "name": "MANDALAWANGI"
        },
        {
          "id": "3206140010",
          "district_id": "3206140",
          "name": "BANJARWARINGIN"
        },
        {
          "id": "3206140018",
          "district_id": "3206140",
          "name": "KARYAWANGI"
        },
        {
          "id": "3206141001",
          "district_id": "3206141",
          "name": "MANDALAMEKAR"
        },
        {
          "id": "3206141002",
          "district_id": "3206141",
          "name": "KERSAGALIH"
        },
        {
          "id": "3206141003",
          "district_id": "3206141",
          "name": "CIWARAK"
        },
        {
          "id": "3206141004",
          "district_id": "3206141",
          "name": "JATIWARAS"
        },
        {
          "id": "3206141005",
          "district_id": "3206141",
          "name": "PAPAYAN"
        },
        {
          "id": "3206141006",
          "district_id": "3206141",
          "name": "NEGLASARI"
        },
        {
          "id": "3206141007",
          "district_id": "3206141",
          "name": "KAPUTIHAN"
        },
        {
          "id": "3206141008",
          "district_id": "3206141",
          "name": "SETIAWANGI"
        },
        {
          "id": "3206141009",
          "district_id": "3206141",
          "name": "SUKAKERTA"
        },
        {
          "id": "3206141010",
          "district_id": "3206141",
          "name": "KERTARAHAYU"
        },
        {
          "id": "3206141011",
          "district_id": "3206141",
          "name": "MANDALA HURIP"
        },
        {
          "id": "3206150001",
          "district_id": "3206150",
          "name": "CISARUA"
        },
        {
          "id": "3206150006",
          "district_id": "3206150",
          "name": "PASIRMUKTI"
        },
        {
          "id": "3206150007",
          "district_id": "3206150",
          "name": "CIKONDANG"
        },
        {
          "id": "3206150008",
          "district_id": "3206150",
          "name": "CIJULANG"
        },
        {
          "id": "3206150009",
          "district_id": "3206150",
          "name": "NAGARATENGAH"
        },
        {
          "id": "3206150010",
          "district_id": "3206150",
          "name": "CIAMPANAN"
        },
        {
          "id": "3206150011",
          "district_id": "3206150",
          "name": "CINEAM"
        },
        {
          "id": "3206150012",
          "district_id": "3206150",
          "name": "MADIASARI"
        },
        {
          "id": "3206150013",
          "district_id": "3206150",
          "name": "RAJADATU"
        },
        {
          "id": "3206150014",
          "district_id": "3206150",
          "name": "ANCOL"
        },
        {
          "id": "3206151001",
          "district_id": "3206151",
          "name": "CITALAHAB"
        },
        {
          "id": "3206151002",
          "district_id": "3206151",
          "name": "SIRNAJAYA"
        },
        {
          "id": "3206151003",
          "district_id": "3206151",
          "name": "KARANGJAYA"
        },
        {
          "id": "3206151004",
          "district_id": "3206151",
          "name": "KARANGLAYUNG"
        },
        {
          "id": "3206160007",
          "district_id": "3206160",
          "name": "BATUSUMUR"
        },
        {
          "id": "3206160008",
          "district_id": "3206160",
          "name": "CIHAUR"
        },
        {
          "id": "3206160009",
          "district_id": "3206160",
          "name": "PASIRPANJANG"
        },
        {
          "id": "3206160010",
          "district_id": "3206160",
          "name": "KALIMANGGIS"
        },
        {
          "id": "3206160011",
          "district_id": "3206160",
          "name": "CIBEBER"
        },
        {
          "id": "3206160012",
          "district_id": "3206160",
          "name": "GUNAJAYA"
        },
        {
          "id": "3206160013",
          "district_id": "3206160",
          "name": "MARGAHAYU"
        },
        {
          "id": "3206160014",
          "district_id": "3206160",
          "name": "KAMULYAN"
        },
        {
          "id": "3206160015",
          "district_id": "3206160",
          "name": "MANONJAYA"
        },
        {
          "id": "3206160016",
          "district_id": "3206160",
          "name": "MARGALUYU"
        },
        {
          "id": "3206160017",
          "district_id": "3206160",
          "name": "CILANGKAP"
        },
        {
          "id": "3206160018",
          "district_id": "3206160",
          "name": "PASIRBATANG"
        },
        {
          "id": "3206161001",
          "district_id": "3206161",
          "name": "CINUNJANG"
        },
        {
          "id": "3206161002",
          "district_id": "3206161",
          "name": "MALATISUKA"
        },
        {
          "id": "3206161003",
          "district_id": "3206161",
          "name": "GIRIWANGI"
        },
        {
          "id": "3206161004",
          "district_id": "3206161",
          "name": "JATIJAYA"
        },
        {
          "id": "3206161005",
          "district_id": "3206161",
          "name": "GUNUNGTANJUNG"
        },
        {
          "id": "3206161006",
          "district_id": "3206161",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3206161007",
          "district_id": "3206161",
          "name": "BOJONGSARI"
        },
        {
          "id": "3206190007",
          "district_id": "3206190",
          "name": "SUKAASIH"
        },
        {
          "id": "3206190008",
          "district_id": "3206190",
          "name": "CIKUNTEN"
        },
        {
          "id": "3206190009",
          "district_id": "3206190",
          "name": "SUKAHERANG"
        },
        {
          "id": "3206190010",
          "district_id": "3206190",
          "name": "SINGASARI"
        },
        {
          "id": "3206190011",
          "district_id": "3206190",
          "name": "SINGAPARNA"
        },
        {
          "id": "3206190012",
          "district_id": "3206190",
          "name": "SUKAMULYA"
        },
        {
          "id": "3206190013",
          "district_id": "3206190",
          "name": "CIPAKAT"
        },
        {
          "id": "3206190020",
          "district_id": "3206190",
          "name": "CINTARAJA"
        },
        {
          "id": "3206190021",
          "district_id": "3206190",
          "name": "CIKUNIR"
        },
        {
          "id": "3206190022",
          "district_id": "3206190",
          "name": "CIKADONGDONG"
        },
        {
          "id": "3206191001",
          "district_id": "3206191",
          "name": "SUKARAPIH"
        },
        {
          "id": "3206191002",
          "district_id": "3206191",
          "name": "WARGAKERTA"
        },
        {
          "id": "3206191003",
          "district_id": "3206191",
          "name": "SUKA MENAK"
        },
        {
          "id": "3206191004",
          "district_id": "3206191",
          "name": "PADASUKA"
        },
        {
          "id": "3206191005",
          "district_id": "3206191",
          "name": "SUKAKARSA"
        },
        {
          "id": "3206191006",
          "district_id": "3206191",
          "name": "SUKARAME"
        },
        {
          "id": "3206192001",
          "district_id": "3206192",
          "name": "PASIRSALAM"
        },
        {
          "id": "3206192002",
          "district_id": "3206192",
          "name": "SUKALUYU"
        },
        {
          "id": "3206192003",
          "district_id": "3206192",
          "name": "SUKASUKUR"
        },
        {
          "id": "3206192004",
          "district_id": "3206192",
          "name": "SALEBU"
        },
        {
          "id": "3206192005",
          "district_id": "3206192",
          "name": "MANGUNREJA"
        },
        {
          "id": "3206192006",
          "district_id": "3206192",
          "name": "MARGAJAYA"
        },
        {
          "id": "3206200001",
          "district_id": "3206200",
          "name": "SIRNAGALIH"
        },
        {
          "id": "3206200002",
          "district_id": "3206200",
          "name": "KERSAMAJU"
        },
        {
          "id": "3206200003",
          "district_id": "3206200",
          "name": "TANJUNGKARANG"
        },
        {
          "id": "3206200004",
          "district_id": "3206200",
          "name": "NANGTANG"
        },
        {
          "id": "3206200005",
          "district_id": "3206200",
          "name": "PUSPARAJA"
        },
        {
          "id": "3206200006",
          "district_id": "3206200",
          "name": "JAYAPURA"
        },
        {
          "id": "3206200007",
          "district_id": "3206200",
          "name": "LENGKONGJAYA"
        },
        {
          "id": "3206200008",
          "district_id": "3206200",
          "name": "TENJONAGARA"
        },
        {
          "id": "3206200009",
          "district_id": "3206200",
          "name": "NANGGERANG"
        },
        {
          "id": "3206200010",
          "district_id": "3206200",
          "name": "SUKAMANAH"
        },
        {
          "id": "3206200011",
          "district_id": "3206200",
          "name": "SIRNAPUTRA"
        },
        {
          "id": "3206200012",
          "district_id": "3206200",
          "name": "SIRNARAJA"
        },
        {
          "id": "3206200013",
          "district_id": "3206200",
          "name": "CIGALONTANG"
        },
        {
          "id": "3206200014",
          "district_id": "3206200",
          "name": "PUSPAMUKTI"
        },
        {
          "id": "3206200015",
          "district_id": "3206200",
          "name": "CIDUGALEUN"
        },
        {
          "id": "3206200016",
          "district_id": "3206200",
          "name": "PARENTAS"
        },
        {
          "id": "3206210003",
          "district_id": "3206210",
          "name": "ARJASARI"
        },
        {
          "id": "3206210007",
          "district_id": "3206210",
          "name": "CIAWANG"
        },
        {
          "id": "3206210008",
          "district_id": "3206210",
          "name": "JAYAMUKTI"
        },
        {
          "id": "3206210015",
          "district_id": "3206210",
          "name": "LINGGAWANGI"
        },
        {
          "id": "3206210016",
          "district_id": "3206210",
          "name": "LINGGAMULYA"
        },
        {
          "id": "3206210017",
          "district_id": "3206210",
          "name": "CIGADOG"
        },
        {
          "id": "3206210018",
          "district_id": "3206210",
          "name": "MANDALAGIRI"
        },
        {
          "id": "3206211001",
          "district_id": "3206211",
          "name": "SIRNASARI"
        },
        {
          "id": "3206211002",
          "district_id": "3206211",
          "name": "LINGGASIRNA"
        },
        {
          "id": "3206211003",
          "district_id": "3206211",
          "name": "SELAWANGI"
        },
        {
          "id": "3206211004",
          "district_id": "3206211",
          "name": "SARIWANGI"
        },
        {
          "id": "3206211005",
          "district_id": "3206211",
          "name": "JAYAPUTRA"
        },
        {
          "id": "3206211006",
          "district_id": "3206211",
          "name": "JAYARATU"
        },
        {
          "id": "3206211007",
          "district_id": "3206211",
          "name": "SUKAMULIH"
        },
        {
          "id": "3206211008",
          "district_id": "3206211",
          "name": "SUKAHARJA"
        },
        {
          "id": "3206212001",
          "district_id": "3206212",
          "name": "CILAMPUNGHILIR"
        },
        {
          "id": "3206212002",
          "district_id": "3206212",
          "name": "RANCAPAKU"
        },
        {
          "id": "3206212003",
          "district_id": "3206212",
          "name": "CISARUNI"
        },
        {
          "id": "3206212004",
          "district_id": "3206212",
          "name": "PADAKEMBANG"
        },
        {
          "id": "3206212005",
          "district_id": "3206212",
          "name": "MEKARJAYA"
        },
        {
          "id": "3206221001",
          "district_id": "3206221",
          "name": "TAWANGBANTENG"
        },
        {
          "id": "3206221002",
          "district_id": "3206221",
          "name": "GUNUNGSARI"
        },
        {
          "id": "3206221003",
          "district_id": "3206221",
          "name": "SUKAMAHI"
        },
        {
          "id": "3206221004",
          "district_id": "3206221",
          "name": "SUKAGALIH"
        },
        {
          "id": "3206221005",
          "district_id": "3206221",
          "name": "INDRAJAYA"
        },
        {
          "id": "3206221006",
          "district_id": "3206221",
          "name": "SUKARATU"
        },
        {
          "id": "3206221007",
          "district_id": "3206221",
          "name": "SINAGAR"
        },
        {
          "id": "3206221008",
          "district_id": "3206221",
          "name": "LINGGAJATI"
        },
        {
          "id": "3206230001",
          "district_id": "3206230",
          "name": "SANTANAMEKAR"
        },
        {
          "id": "3206230002",
          "district_id": "3206230",
          "name": "CISAYONG"
        },
        {
          "id": "3206230003",
          "district_id": "3206230",
          "name": "SUKAJADI"
        },
        {
          "id": "3206230004",
          "district_id": "3206230",
          "name": "SUKASUKUR"
        },
        {
          "id": "3206230005",
          "district_id": "3206230",
          "name": "JATIHURIP"
        },
        {
          "id": "3206230006",
          "district_id": "3206230",
          "name": "SUKARAHARJA"
        },
        {
          "id": "3206230007",
          "district_id": "3206230",
          "name": "MEKARWANGI"
        },
        {
          "id": "3206230008",
          "district_id": "3206230",
          "name": "NUSAWANGI"
        },
        {
          "id": "3206230009",
          "district_id": "3206230",
          "name": "CIKADU"
        },
        {
          "id": "3206230010",
          "district_id": "3206230",
          "name": "PURWASARI"
        },
        {
          "id": "3206230011",
          "district_id": "3206230",
          "name": "CILEULEUS"
        },
        {
          "id": "3206230013",
          "district_id": "3206230",
          "name": "SUKASETIA"
        },
        {
          "id": "3206230014",
          "district_id": "3206230",
          "name": "SUKAMUKTI"
        },
        {
          "id": "3206231001",
          "district_id": "3206231",
          "name": "BANYURASA"
        },
        {
          "id": "3206231002",
          "district_id": "3206231",
          "name": "BANYURESMI"
        },
        {
          "id": "3206231003",
          "district_id": "3206231",
          "name": "CALINGCING"
        },
        {
          "id": "3206231004",
          "district_id": "3206231",
          "name": "KUDADEPA"
        },
        {
          "id": "3206231005",
          "district_id": "3206231",
          "name": "SUNDAKERTA"
        },
        {
          "id": "3206231006",
          "district_id": "3206231",
          "name": "SUKAHENING"
        },
        {
          "id": "3206231007",
          "district_id": "3206231",
          "name": "KIARAJANGKUNG"
        },
        {
          "id": "3206240001",
          "district_id": "3206240",
          "name": "RAJAMANDALA"
        },
        {
          "id": "3206240002",
          "district_id": "3206240",
          "name": "DAWAGUNG"
        },
        {
          "id": "3206240003",
          "district_id": "3206240",
          "name": "RAJAPOLAH"
        },
        {
          "id": "3206240004",
          "district_id": "3206240",
          "name": "MANGGUNGJAYA"
        },
        {
          "id": "3206240005",
          "district_id": "3206240",
          "name": "SUKANAGALIH"
        },
        {
          "id": "3206240006",
          "district_id": "3206240",
          "name": "SUKARAJA"
        },
        {
          "id": "3206240007",
          "district_id": "3206240",
          "name": "MANGGUNGSARI"
        },
        {
          "id": "3206240008",
          "district_id": "3206240",
          "name": "TANJUNGPURA"
        },
        {
          "id": "3206250001",
          "district_id": "3206250",
          "name": "KARANGSEMBUNG"
        },
        {
          "id": "3206250002",
          "district_id": "3206250",
          "name": "CONDONG"
        },
        {
          "id": "3206250003",
          "district_id": "3206250",
          "name": "BOJONGGAOK"
        },
        {
          "id": "3206250004",
          "district_id": "3206250",
          "name": "SINDANGRAJA"
        },
        {
          "id": "3206250005",
          "district_id": "3206250",
          "name": "TANJUNGMEKAR"
        },
        {
          "id": "3206250006",
          "district_id": "3206250",
          "name": "KARANGMULYA"
        },
        {
          "id": "3206250007",
          "district_id": "3206250",
          "name": "KARANGRESIK"
        },
        {
          "id": "3206250008",
          "district_id": "3206250",
          "name": "GERESIK"
        },
        {
          "id": "3206260001",
          "district_id": "3206260",
          "name": "KERTAMUKTI"
        },
        {
          "id": "3206260002",
          "district_id": "3206260",
          "name": "GOMBONG"
        },
        {
          "id": "3206260004",
          "district_id": "3206260",
          "name": "MARGASARI"
        },
        {
          "id": "3206260005",
          "district_id": "3206260",
          "name": "PAKEMITANKIDUL"
        },
        {
          "id": "3206260006",
          "district_id": "3206260",
          "name": "CIAWI"
        },
        {
          "id": "3206260007",
          "district_id": "3206260",
          "name": "CITAMBA"
        },
        {
          "id": "3206260008",
          "district_id": "3206260",
          "name": "KURNIABAKTI"
        },
        {
          "id": "3206260009",
          "district_id": "3206260",
          "name": "PAKEMITAN"
        },
        {
          "id": "3206260010",
          "district_id": "3206260",
          "name": "SUKAMANTRI"
        },
        {
          "id": "3206260011",
          "district_id": "3206260",
          "name": "PASIRHUNI"
        },
        {
          "id": "3206261001",
          "district_id": "3206261",
          "name": "PAMOYANAN"
        },
        {
          "id": "3206261002",
          "district_id": "3206261",
          "name": "BUNIASIH"
        },
        {
          "id": "3206261003",
          "district_id": "3206261",
          "name": "MEKARSARI"
        },
        {
          "id": "3206261004",
          "district_id": "3206261",
          "name": "DIRGAHAYU"
        },
        {
          "id": "3206261005",
          "district_id": "3206261",
          "name": "CIBAHAYU"
        },
        {
          "id": "3206261006",
          "district_id": "3206261",
          "name": "KADIPATEN"
        },
        {
          "id": "3206270007",
          "district_id": "3206270",
          "name": "CIPACING"
        },
        {
          "id": "3206270010",
          "district_id": "3206270",
          "name": "SUKAMAJU"
        },
        {
          "id": "3206270011",
          "district_id": "3206270",
          "name": "PAGERSARI"
        },
        {
          "id": "3206270012",
          "district_id": "3206270",
          "name": "PAGERAGEUNG"
        },
        {
          "id": "3206270013",
          "district_id": "3206270",
          "name": "SUKADANA"
        },
        {
          "id": "3206270014",
          "district_id": "3206270",
          "name": "PUTERAN"
        },
        {
          "id": "3206270015",
          "district_id": "3206270",
          "name": "TANJUNGKERTA"
        },
        {
          "id": "3206270016",
          "district_id": "3206270",
          "name": "GURANTENG"
        },
        {
          "id": "3206270017",
          "district_id": "3206270",
          "name": "NANGGEWER"
        },
        {
          "id": "3206270018",
          "district_id": "3206270",
          "name": "SUKAPADA"
        },
        {
          "id": "3206271001",
          "district_id": "3206271",
          "name": "MARGAMULYA"
        },
        {
          "id": "3206271002",
          "district_id": "3206271",
          "name": "CIPONDOK"
        },
        {
          "id": "3206271003",
          "district_id": "3206271",
          "name": "SUKAMENAK"
        },
        {
          "id": "3206271004",
          "district_id": "3206271",
          "name": "SUKARESIK"
        },
        {
          "id": "3206271005",
          "district_id": "3206271",
          "name": "SUKARATU"
        },
        {
          "id": "3206271006",
          "district_id": "3206271",
          "name": "BANJARSARI"
        },
        {
          "id": "3206271007",
          "district_id": "3206271",
          "name": "SUKAPANCAR"
        },
        {
          "id": "3206271008",
          "district_id": "3206271",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3207100009",
          "district_id": "3207100",
          "name": "SUKASARI"
        },
        {
          "id": "3207100011",
          "district_id": "3207100",
          "name": "KAWASEN"
        },
        {
          "id": "3207100012",
          "district_id": "3207100",
          "name": "CIBADAK"
        },
        {
          "id": "3207100013",
          "district_id": "3207100",
          "name": "BANJARSARI"
        },
        {
          "id": "3207100014",
          "district_id": "3207100",
          "name": "SINDANGSARI"
        },
        {
          "id": "3207100015",
          "district_id": "3207100",
          "name": "SINDANGHAYU"
        },
        {
          "id": "3207100016",
          "district_id": "3207100",
          "name": "SINDANGASIH"
        },
        {
          "id": "3207100017",
          "district_id": "3207100",
          "name": "PURWASARI"
        },
        {
          "id": "3207100018",
          "district_id": "3207100",
          "name": "CIHERANG"
        },
        {
          "id": "3207100019",
          "district_id": "3207100",
          "name": "RATAWANGI"
        },
        {
          "id": "3207100020",
          "district_id": "3207100",
          "name": "CICAPAR"
        },
        {
          "id": "3207100021",
          "district_id": "3207100",
          "name": "CIULU"
        },
        {
          "id": "3207101001",
          "district_id": "3207101",
          "name": "CIKUPA"
        },
        {
          "id": "3207101002",
          "district_id": "3207101",
          "name": "KARYAMUKTI"
        },
        {
          "id": "3207101003",
          "district_id": "3207101",
          "name": "CIGAYAM"
        },
        {
          "id": "3207101004",
          "district_id": "3207101",
          "name": "BANJARANYAR"
        },
        {
          "id": "3207101005",
          "district_id": "3207101",
          "name": "KALIJAYA"
        },
        {
          "id": "3207101006",
          "district_id": "3207101",
          "name": "PASAWAHAN"
        },
        {
          "id": "3207101007",
          "district_id": "3207101",
          "name": "CIKASO"
        },
        {
          "id": "3207101008",
          "district_id": "3207101",
          "name": "SINDANGRASA"
        },
        {
          "id": "3207101009",
          "district_id": "3207101",
          "name": "LANGKAPSARI"
        },
        {
          "id": "3207101010",
          "district_id": "3207101",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3207110001",
          "district_id": "3207110",
          "name": "SINDANGANGIN"
        },
        {
          "id": "3207110002",
          "district_id": "3207110",
          "name": "PULOERANG"
        },
        {
          "id": "3207110003",
          "district_id": "3207110",
          "name": "KALAPASAWIT"
        },
        {
          "id": "3207110004",
          "district_id": "3207110",
          "name": "SUKANAGARA"
        },
        {
          "id": "3207110005",
          "district_id": "3207110",
          "name": "KERTAJAYA"
        },
        {
          "id": "3207110006",
          "district_id": "3207110",
          "name": "SIDAHARJA"
        },
        {
          "id": "3207110007",
          "district_id": "3207110",
          "name": "BAREGBEG"
        },
        {
          "id": "3207110008",
          "district_id": "3207110",
          "name": "CINTAJAYA"
        },
        {
          "id": "3207110009",
          "district_id": "3207110",
          "name": "TAMBAKREJA"
        },
        {
          "id": "3207110010",
          "district_id": "3207110",
          "name": "CINTARATU"
        },
        {
          "id": "3207111001",
          "district_id": "3207111",
          "name": "KUTAWARINGIN"
        },
        {
          "id": "3207111002",
          "district_id": "3207111",
          "name": "BANTARDAWA"
        },
        {
          "id": "3207111003",
          "district_id": "3207111",
          "name": "PASIRLAWANG"
        },
        {
          "id": "3207111004",
          "district_id": "3207111",
          "name": "PURWADADI"
        },
        {
          "id": "3207111005",
          "district_id": "3207111",
          "name": "PURWAJAYA"
        },
        {
          "id": "3207111006",
          "district_id": "3207111",
          "name": "SIDARAHAYU"
        },
        {
          "id": "3207111007",
          "district_id": "3207111",
          "name": "KARANGPANINGAL"
        },
        {
          "id": "3207111008",
          "district_id": "3207111",
          "name": "SUKAMULYA"
        },
        {
          "id": "3207111009",
          "district_id": "3207111",
          "name": "PADARINGAN"
        },
        {
          "id": "3207120001",
          "district_id": "3207120",
          "name": "SIDAMULIH"
        },
        {
          "id": "3207120002",
          "district_id": "3207120",
          "name": "MARGAJAYA"
        },
        {
          "id": "3207120003",
          "district_id": "3207120",
          "name": "NEGLASARI"
        },
        {
          "id": "3207120004",
          "district_id": "3207120",
          "name": "PAMARICAN"
        },
        {
          "id": "3207120005",
          "district_id": "3207120",
          "name": "SUKAHURIP"
        },
        {
          "id": "3207120006",
          "district_id": "3207120",
          "name": "KERTAHAYU"
        },
        {
          "id": "3207120007",
          "district_id": "3207120",
          "name": "SUKAJADI"
        },
        {
          "id": "3207120008",
          "district_id": "3207120",
          "name": "SUKAMUKTI"
        },
        {
          "id": "3207120009",
          "district_id": "3207120",
          "name": "SIDAHARJA"
        },
        {
          "id": "3207120010",
          "district_id": "3207120",
          "name": "BANGUNSARI"
        },
        {
          "id": "3207120011",
          "district_id": "3207120",
          "name": "SUKAJAYA"
        },
        {
          "id": "3207120012",
          "district_id": "3207120",
          "name": "BANTARSARI"
        },
        {
          "id": "3207120013",
          "district_id": "3207120",
          "name": "PASIRNAGARA"
        },
        {
          "id": "3207120014",
          "district_id": "3207120",
          "name": "MEKARMULYA"
        },
        {
          "id": "3207130001",
          "district_id": "3207130",
          "name": "JELEGONG"
        },
        {
          "id": "3207130002",
          "district_id": "3207130",
          "name": "CIPARAY"
        },
        {
          "id": "3207130003",
          "district_id": "3207130",
          "name": "CIDOLOG"
        },
        {
          "id": "3207130004",
          "district_id": "3207130",
          "name": "JANGGALA"
        },
        {
          "id": "3207130005",
          "district_id": "3207130",
          "name": "HEGARMANAH"
        },
        {
          "id": "3207130006",
          "district_id": "3207130",
          "name": "SUKASARI"
        },
        {
          "id": "3207140001",
          "district_id": "3207140",
          "name": "BOJONGMALANG"
        },
        {
          "id": "3207140002",
          "district_id": "3207140",
          "name": "RAKSABAYA"
        },
        {
          "id": "3207140004",
          "district_id": "3207140",
          "name": "CIMARAGAS"
        },
        {
          "id": "3207140005",
          "district_id": "3207140",
          "name": "JAYARAKSA"
        },
        {
          "id": "3207150001",
          "district_id": "3207150",
          "name": "HANDAPHERANG"
        },
        {
          "id": "3207150002",
          "district_id": "3207150",
          "name": "CIHARALANG"
        },
        {
          "id": "3207150003",
          "district_id": "3207150",
          "name": "BOJONGMENGGER"
        },
        {
          "id": "3207150004",
          "district_id": "3207150",
          "name": "KARANGKAMULYAN"
        },
        {
          "id": "3207150005",
          "district_id": "3207150",
          "name": "KERTABUMI"
        },
        {
          "id": "3207150006",
          "district_id": "3207150",
          "name": "CIJEUNGJING"
        },
        {
          "id": "3207150007",
          "district_id": "3207150",
          "name": "PAMALAYAN"
        },
        {
          "id": "3207150008",
          "district_id": "3207150",
          "name": "DEWASARI"
        },
        {
          "id": "3207150009",
          "district_id": "3207150",
          "name": "UTAMA"
        },
        {
          "id": "3207150010",
          "district_id": "3207150",
          "name": "KERTAHARJA"
        },
        {
          "id": "3207150011",
          "district_id": "3207150",
          "name": "KARANGANYAR"
        },
        {
          "id": "3207160001",
          "district_id": "3207160",
          "name": "DANASARI"
        },
        {
          "id": "3207160002",
          "district_id": "3207160",
          "name": "SIDAMULYA"
        },
        {
          "id": "3207160004",
          "district_id": "3207160",
          "name": "MEKARMUKTI"
        },
        {
          "id": "3207160005",
          "district_id": "3207160",
          "name": "CISAGA"
        },
        {
          "id": "3207160006",
          "district_id": "3207160",
          "name": "WANGUNJAYA"
        },
        {
          "id": "3207160007",
          "district_id": "3207160",
          "name": "TANJUNGJAYA"
        },
        {
          "id": "3207160008",
          "district_id": "3207160",
          "name": "SUKAHURIP"
        },
        {
          "id": "3207160009",
          "district_id": "3207160",
          "name": "BANGUNHARJA"
        },
        {
          "id": "3207160010",
          "district_id": "3207160",
          "name": "GIRIMUKTI"
        },
        {
          "id": "3207160011",
          "district_id": "3207160",
          "name": "KARYAMULYA"
        },
        {
          "id": "3207170001",
          "district_id": "3207170",
          "name": "TAMBAKSARI"
        },
        {
          "id": "3207170002",
          "district_id": "3207170",
          "name": "KARANGPANINGAL"
        },
        {
          "id": "3207170003",
          "district_id": "3207170",
          "name": "KASO"
        },
        {
          "id": "3207170004",
          "district_id": "3207170",
          "name": "MEKARSARI"
        },
        {
          "id": "3207170005",
          "district_id": "3207170",
          "name": "SUKASARI"
        },
        {
          "id": "3207170006",
          "district_id": "3207170",
          "name": "KADUPANDAK"
        },
        {
          "id": "3207180001",
          "district_id": "3207180",
          "name": "KARANGPARI"
        },
        {
          "id": "3207180002",
          "district_id": "3207180",
          "name": "BOJONGGEDANG"
        },
        {
          "id": "3207180003",
          "district_id": "3207180",
          "name": "CISONTROL"
        },
        {
          "id": "3207180004",
          "district_id": "3207180",
          "name": "CILEUNGSIR"
        },
        {
          "id": "3207180005",
          "district_id": "3207180",
          "name": "KIARAPAYUNG"
        },
        {
          "id": "3207180006",
          "district_id": "3207180",
          "name": "KAWUNGLARANG"
        },
        {
          "id": "3207180007",
          "district_id": "3207180",
          "name": "RANCAH"
        },
        {
          "id": "3207180008",
          "district_id": "3207180",
          "name": "SITUMANDALA"
        },
        {
          "id": "3207180009",
          "district_id": "3207180",
          "name": "PATAKAHARJA"
        },
        {
          "id": "3207180010",
          "district_id": "3207180",
          "name": "DADIHARJA"
        },
        {
          "id": "3207180011",
          "district_id": "3207180",
          "name": "JANGALAHARJA"
        },
        {
          "id": "3207180012",
          "district_id": "3207180",
          "name": "GIRIHARJA"
        },
        {
          "id": "3207180013",
          "district_id": "3207180",
          "name": "WANGUNSARI"
        },
        {
          "id": "3207190001",
          "district_id": "3207190",
          "name": "TANJUNGSUKUR"
        },
        {
          "id": "3207190002",
          "district_id": "3207190",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3207190003",
          "district_id": "3207190",
          "name": "TANJUNGJAYA"
        },
        {
          "id": "3207190004",
          "district_id": "3207190",
          "name": "RAJADESA"
        },
        {
          "id": "3207190005",
          "district_id": "3207190",
          "name": "SIRNABAYA"
        },
        {
          "id": "3207190006",
          "district_id": "3207190",
          "name": "SIRNAJAYA"
        },
        {
          "id": "3207190007",
          "district_id": "3207190",
          "name": "PURWARAJA"
        },
        {
          "id": "3207190008",
          "district_id": "3207190",
          "name": "ANDAPRAJA"
        },
        {
          "id": "3207190009",
          "district_id": "3207190",
          "name": "SUKAHARJA"
        },
        {
          "id": "3207190010",
          "district_id": "3207190",
          "name": "TIGAHERANG"
        },
        {
          "id": "3207190011",
          "district_id": "3207190",
          "name": "SUKAJAYA"
        },
        {
          "id": "3207200001",
          "district_id": "3207200",
          "name": "CIPARIGI"
        },
        {
          "id": "3207200003",
          "district_id": "3207200",
          "name": "MARGAHARJA"
        },
        {
          "id": "3207200004",
          "district_id": "3207200",
          "name": "MARGAJAYA"
        },
        {
          "id": "3207200005",
          "district_id": "3207200",
          "name": "SUKADANA"
        },
        {
          "id": "3207200006",
          "district_id": "3207200",
          "name": "SALAKARIA"
        },
        {
          "id": "3207210001",
          "district_id": "3207210",
          "name": "IMBANAGARA RAYA"
        },
        {
          "id": "3207210002",
          "district_id": "3207210",
          "name": "CISADAP"
        },
        {
          "id": "3207210003",
          "district_id": "3207210",
          "name": "IMBANAGARA"
        },
        {
          "id": "3207210004",
          "district_id": "3207210",
          "name": "SINDANGRASA"
        },
        {
          "id": "3207210005",
          "district_id": "3207210",
          "name": "PANYINGKIRAN"
        },
        {
          "id": "3207210006",
          "district_id": "3207210",
          "name": "PAWINDAN"
        },
        {
          "id": "3207210007",
          "district_id": "3207210",
          "name": "LINGGASARI"
        },
        {
          "id": "3207210008",
          "district_id": "3207210",
          "name": "CIAMIS"
        },
        {
          "id": "3207210010",
          "district_id": "3207210",
          "name": "CIGEMBOR"
        },
        {
          "id": "3207210011",
          "district_id": "3207210",
          "name": "KERTASARI"
        },
        {
          "id": "3207210012",
          "district_id": "3207210",
          "name": "MALEBER"
        },
        {
          "id": "3207211001",
          "district_id": "3207211",
          "name": "SUKAMAJU"
        },
        {
          "id": "3207211002",
          "district_id": "3207211",
          "name": "MEKARJAYA"
        },
        {
          "id": "3207211003",
          "district_id": "3207211",
          "name": "SAGULING"
        },
        {
          "id": "3207211004",
          "district_id": "3207211",
          "name": "BAREGBEG"
        },
        {
          "id": "3207211005",
          "district_id": "3207211",
          "name": "PETIRHILIR"
        },
        {
          "id": "3207211006",
          "district_id": "3207211",
          "name": "PUSAKANAGARA"
        },
        {
          "id": "3207211007",
          "district_id": "3207211",
          "name": "KARANGAMPEL"
        },
        {
          "id": "3207211008",
          "district_id": "3207211",
          "name": "JELAT"
        },
        {
          "id": "3207211009",
          "district_id": "3207211",
          "name": "SUKAMULYA"
        },
        {
          "id": "3207220001",
          "district_id": "3207220",
          "name": "PANARAGAN"
        },
        {
          "id": "3207220002",
          "district_id": "3207220",
          "name": "MARGALUYU"
        },
        {
          "id": "3207220003",
          "district_id": "3207220",
          "name": "CIKONENG"
        },
        {
          "id": "3207220004",
          "district_id": "3207220",
          "name": "KUJANG"
        },
        {
          "id": "3207220005",
          "district_id": "3207220",
          "name": "DARMACAANG"
        },
        {
          "id": "3207220006",
          "district_id": "3207220",
          "name": "NASOL"
        },
        {
          "id": "3207220007",
          "district_id": "3207220",
          "name": "SINDANGSARI"
        },
        {
          "id": "3207220008",
          "district_id": "3207220",
          "name": "CIMARI"
        },
        {
          "id": "3207220009",
          "district_id": "3207220",
          "name": "GEGEMPALAN"
        },
        {
          "id": "3207221001",
          "district_id": "3207221",
          "name": "SUKAMANAH"
        },
        {
          "id": "3207221002",
          "district_id": "3207221",
          "name": "SUKARAJA"
        },
        {
          "id": "3207221003",
          "district_id": "3207221",
          "name": "BUDIHARJA"
        },
        {
          "id": "3207221004",
          "district_id": "3207221",
          "name": "BUDIASIH"
        },
        {
          "id": "3207221006",
          "district_id": "3207221",
          "name": "SINDANGKASIH"
        },
        {
          "id": "3207221007",
          "district_id": "3207221",
          "name": "SUKASENANG"
        },
        {
          "id": "3207221008",
          "district_id": "3207221",
          "name": "SUKARESIK"
        },
        {
          "id": "3207221009",
          "district_id": "3207221",
          "name": "WANASIGRA"
        },
        {
          "id": "3207230001",
          "district_id": "3207230",
          "name": "SUKAMULYA"
        },
        {
          "id": "3207230002",
          "district_id": "3207230",
          "name": "SUKAHAJI"
        },
        {
          "id": "3207230003",
          "district_id": "3207230",
          "name": "SUKAHURIP"
        },
        {
          "id": "3207230004",
          "district_id": "3207230",
          "name": "SUKAMAJU"
        },
        {
          "id": "3207230005",
          "district_id": "3207230",
          "name": "CIJULANG"
        },
        {
          "id": "3207230006",
          "district_id": "3207230",
          "name": "SUKASETIA"
        },
        {
          "id": "3207230007",
          "district_id": "3207230",
          "name": "SUMBERJAYA"
        },
        {
          "id": "3207230008",
          "district_id": "3207230",
          "name": "CIHAURBEUTI"
        },
        {
          "id": "3207230009",
          "district_id": "3207230",
          "name": "PASIRTAMIANG"
        },
        {
          "id": "3207230010",
          "district_id": "3207230",
          "name": "PADAMULYA"
        },
        {
          "id": "3207230011",
          "district_id": "3207230",
          "name": "PAMOKOLAN"
        },
        {
          "id": "3207240001",
          "district_id": "3207240",
          "name": "MEKARJADI"
        },
        {
          "id": "3207240002",
          "district_id": "3207240",
          "name": "SUKAJADI"
        },
        {
          "id": "3207240003",
          "district_id": "3207240",
          "name": "WERASARI"
        },
        {
          "id": "3207240004",
          "district_id": "3207240",
          "name": "MANGKUBUMI"
        },
        {
          "id": "3207240005",
          "district_id": "3207240",
          "name": "BENDASARI"
        },
        {
          "id": "3207240006",
          "district_id": "3207240",
          "name": "SADANANYA"
        },
        {
          "id": "3207240007",
          "district_id": "3207240",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3207240008",
          "district_id": "3207240",
          "name": "GUNUNGSARI"
        },
        {
          "id": "3207250001",
          "district_id": "3207250",
          "name": "MUKTISARI"
        },
        {
          "id": "3207250002",
          "district_id": "3207250",
          "name": "MEKARSARI"
        },
        {
          "id": "3207250003",
          "district_id": "3207250",
          "name": "BUNISEURI"
        },
        {
          "id": "3207250004",
          "district_id": "3207250",
          "name": "PUSAKASARI"
        },
        {
          "id": "3207250005",
          "district_id": "3207250",
          "name": "JALATRANG"
        },
        {
          "id": "3207250006",
          "district_id": "3207250",
          "name": "SUKAWENING"
        },
        {
          "id": "3207250007",
          "district_id": "3207250",
          "name": "CIPAKU"
        },
        {
          "id": "3207250008",
          "district_id": "3207250",
          "name": "BANGBAYANG"
        },
        {
          "id": "3207250009",
          "district_id": "3207250",
          "name": "CIEURIH"
        },
        {
          "id": "3207250010",
          "district_id": "3207250",
          "name": "SELAMANIK"
        },
        {
          "id": "3207250011",
          "district_id": "3207250",
          "name": "SELACAI"
        },
        {
          "id": "3207250012",
          "district_id": "3207250",
          "name": "GEREBA"
        },
        {
          "id": "3207250013",
          "district_id": "3207250",
          "name": "CIAKAR"
        },
        {
          "id": "3207260001",
          "district_id": "3207260",
          "name": "CINTANAGARA"
        },
        {
          "id": "3207260002",
          "district_id": "3207260",
          "name": "BAYASARI"
        },
        {
          "id": "3207260003",
          "district_id": "3207260",
          "name": "JATINAGARA"
        },
        {
          "id": "3207260004",
          "district_id": "3207260",
          "name": "DAYEUHLUHUR"
        },
        {
          "id": "3207260005",
          "district_id": "3207260",
          "name": "SUKANAGARA"
        },
        {
          "id": "3207260006",
          "district_id": "3207260",
          "name": "MULYASARI"
        },
        {
          "id": "3207270001",
          "district_id": "3207270",
          "name": "KERTAYASA"
        },
        {
          "id": "3207270002",
          "district_id": "3207270",
          "name": "KARANGPANINGAL"
        },
        {
          "id": "3207270003",
          "district_id": "3207270",
          "name": "INDRAGIRI"
        },
        {
          "id": "3207270004",
          "district_id": "3207270",
          "name": "PANAWANGAN"
        },
        {
          "id": "3207270005",
          "district_id": "3207270",
          "name": "SAGALAHERANG"
        },
        {
          "id": "3207270006",
          "district_id": "3207270",
          "name": "NAGARAPAGEUH"
        },
        {
          "id": "3207270007",
          "district_id": "3207270",
          "name": "NAGARAJAYA"
        },
        {
          "id": "3207270008",
          "district_id": "3207270",
          "name": "NAGARAJATI"
        },
        {
          "id": "3207270009",
          "district_id": "3207270",
          "name": "JAGABAYA"
        },
        {
          "id": "3207270010",
          "district_id": "3207270",
          "name": "CINYASAG"
        },
        {
          "id": "3207270011",
          "district_id": "3207270",
          "name": "GARDUJAYA"
        },
        {
          "id": "3207270012",
          "district_id": "3207270",
          "name": "SADAPAINGAN"
        },
        {
          "id": "3207270013",
          "district_id": "3207270",
          "name": "BANGUNJAYA"
        },
        {
          "id": "3207270014",
          "district_id": "3207270",
          "name": "GIRILAYA"
        },
        {
          "id": "3207270015",
          "district_id": "3207270",
          "name": "KERTAJAYA"
        },
        {
          "id": "3207270016",
          "district_id": "3207270",
          "name": "NAGARAWANGI"
        },
        {
          "id": "3207270017",
          "district_id": "3207270",
          "name": "NATANEGARA"
        },
        {
          "id": "3207270018",
          "district_id": "3207270",
          "name": "MEKARBUANA"
        },
        {
          "id": "3207280001",
          "district_id": "3207280",
          "name": "SINDANGSARI"
        },
        {
          "id": "3207280002",
          "district_id": "3207280",
          "name": "KAWALI"
        },
        {
          "id": "3207280003",
          "district_id": "3207280",
          "name": "KARANGPAWITAN"
        },
        {
          "id": "3207280004",
          "district_id": "3207280",
          "name": "KAWALIMUKTI"
        },
        {
          "id": "3207280005",
          "district_id": "3207280",
          "name": "TALAGASARI"
        },
        {
          "id": "3207280006",
          "district_id": "3207280",
          "name": "SELASARI"
        },
        {
          "id": "3207280007",
          "district_id": "3207280",
          "name": "MARGAMULYA"
        },
        {
          "id": "3207280008",
          "district_id": "3207280",
          "name": "WINDURAJA"
        },
        {
          "id": "3207280009",
          "district_id": "3207280",
          "name": "PURWASARI"
        },
        {
          "id": "3207280010",
          "district_id": "3207280",
          "name": "CITEUREUP"
        },
        {
          "id": "3207280011",
          "district_id": "3207280",
          "name": "LINGGAPURA"
        },
        {
          "id": "3207281001",
          "district_id": "3207281",
          "name": "CIKUPA"
        },
        {
          "id": "3207281002",
          "district_id": "3207281",
          "name": "SUKARAHARJA"
        },
        {
          "id": "3207281003",
          "district_id": "3207281",
          "name": "AWILUAR"
        },
        {
          "id": "3207281005",
          "district_id": "3207281",
          "name": "DARMARAJA"
        },
        {
          "id": "3207281006",
          "district_id": "3207281",
          "name": "LUMBUNGSARI"
        },
        {
          "id": "3207281007",
          "district_id": "3207281",
          "name": "RAWA"
        },
        {
          "id": "3207281008",
          "district_id": "3207281",
          "name": "SADEWATA"
        },
        {
          "id": "3207290001",
          "district_id": "3207290",
          "name": "MANDALARE"
        },
        {
          "id": "3207290002",
          "district_id": "3207290",
          "name": "KERTAMANDALA"
        },
        {
          "id": "3207290003",
          "district_id": "3207290",
          "name": "CIOMAS"
        },
        {
          "id": "3207290004",
          "district_id": "3207290",
          "name": "SANDINGTAMAN"
        },
        {
          "id": "3207290005",
          "district_id": "3207290",
          "name": "MAPARAH"
        },
        {
          "id": "3207290006",
          "district_id": "3207290",
          "name": "PANJALU"
        },
        {
          "id": "3207290007",
          "district_id": "3207290",
          "name": "BAHARA"
        },
        {
          "id": "3207290008",
          "district_id": "3207290",
          "name": "HUJUNGTIWU"
        },
        {
          "id": "3207291001",
          "district_id": "3207291",
          "name": "TENGGERRAHARJA"
        },
        {
          "id": "3207291002",
          "district_id": "3207291",
          "name": "SUKAMANTRI"
        },
        {
          "id": "3207291003",
          "district_id": "3207291",
          "name": "CIBEUREUM"
        },
        {
          "id": "3207291004",
          "district_id": "3207291",
          "name": "SINDANGLAYA"
        },
        {
          "id": "3207291005",
          "district_id": "3207291",
          "name": "MEKARWANGI"
        },
        {
          "id": "3207300001",
          "district_id": "3207300",
          "name": "MEDANGLAYANG"
        },
        {
          "id": "3207300002",
          "district_id": "3207300",
          "name": "PANUMBANGAN"
        },
        {
          "id": "3207300003",
          "district_id": "3207300",
          "name": "TANJUNGMULYA"
        },
        {
          "id": "3207300004",
          "district_id": "3207300",
          "name": "KERTARAHARJA"
        },
        {
          "id": "3207300005",
          "district_id": "3207300",
          "name": "SUKAKERTA"
        },
        {
          "id": "3207300006",
          "district_id": "3207300",
          "name": "GOLAT"
        },
        {
          "id": "3207300007",
          "district_id": "3207300",
          "name": "SINDANGHERANG"
        },
        {
          "id": "3207300008",
          "district_id": "3207300",
          "name": "SINDANGMUKTI"
        },
        {
          "id": "3207300009",
          "district_id": "3207300",
          "name": "JAYAGIRI"
        },
        {
          "id": "3207300010",
          "district_id": "3207300",
          "name": "BANJARANGSANA"
        },
        {
          "id": "3207300011",
          "district_id": "3207300",
          "name": "PAYUNGSARI"
        },
        {
          "id": "3207300012",
          "district_id": "3207300",
          "name": "PAYUNGAGUNG"
        },
        {
          "id": "3207300013",
          "district_id": "3207300",
          "name": "SINDANGBARANG"
        },
        {
          "id": "3207300014",
          "district_id": "3207300",
          "name": "BUANAMEKAR"
        },
        {
          "id": "3208010001",
          "district_id": "3208010",
          "name": "CIMENGA"
        },
        {
          "id": "3208010002",
          "district_id": "3208010",
          "name": "TUGUMULYA"
        },
        {
          "id": "3208010003",
          "district_id": "3208010",
          "name": "CAGEUR"
        },
        {
          "id": "3208010004",
          "district_id": "3208010",
          "name": "SAKERTA TIMUR"
        },
        {
          "id": "3208010005",
          "district_id": "3208010",
          "name": "SAKERTA BARAT"
        },
        {
          "id": "3208010006",
          "district_id": "3208010",
          "name": "SUKARASA"
        },
        {
          "id": "3208010007",
          "district_id": "3208010",
          "name": "PANINGGARAN"
        },
        {
          "id": "3208010008",
          "district_id": "3208010",
          "name": "CIPASUNG"
        },
        {
          "id": "3208010009",
          "district_id": "3208010",
          "name": "KAWAHMANUK"
        },
        {
          "id": "3208010010",
          "district_id": "3208010",
          "name": "CIKUPA"
        },
        {
          "id": "3208010011",
          "district_id": "3208010",
          "name": "PARUNG"
        },
        {
          "id": "3208010012",
          "district_id": "3208010",
          "name": "DARMA"
        },
        {
          "id": "3208010013",
          "district_id": "3208010",
          "name": "JAGARA"
        },
        {
          "id": "3208010014",
          "district_id": "3208010",
          "name": "BAKOM"
        },
        {
          "id": "3208010015",
          "district_id": "3208010",
          "name": "KARANGANYAR"
        },
        {
          "id": "3208010016",
          "district_id": "3208010",
          "name": "GUNUNGSIRAH"
        },
        {
          "id": "3208010017",
          "district_id": "3208010",
          "name": "SITUSARI"
        },
        {
          "id": "3208010018",
          "district_id": "3208010",
          "name": "KARANGSARI"
        },
        {
          "id": "3208010019",
          "district_id": "3208010",
          "name": "SAGARAHIANG"
        },
        {
          "id": "3208020003",
          "district_id": "3208020",
          "name": "MARGABAKTI"
        },
        {
          "id": "3208020004",
          "district_id": "3208020",
          "name": "SINDANGJAWA"
        },
        {
          "id": "3208020009",
          "district_id": "3208020",
          "name": "KADUGEDE"
        },
        {
          "id": "3208020010",
          "district_id": "3208020",
          "name": "CIKETAK"
        },
        {
          "id": "3208020011",
          "district_id": "3208020",
          "name": "NANGKA"
        },
        {
          "id": "3208020012",
          "district_id": "3208020",
          "name": "WINDUJANTEN"
        },
        {
          "id": "3208020013",
          "district_id": "3208020",
          "name": "CIPONDOK"
        },
        {
          "id": "3208020014",
          "district_id": "3208020",
          "name": "BABATAN"
        },
        {
          "id": "3208020015",
          "district_id": "3208020",
          "name": "BAYUNING"
        },
        {
          "id": "3208020017",
          "district_id": "3208020",
          "name": "CIHERANG"
        },
        {
          "id": "3208020018",
          "district_id": "3208020",
          "name": "TINGGAR"
        },
        {
          "id": "3208020019",
          "district_id": "3208020",
          "name": "CISUKADANA"
        },
        {
          "id": "3208021001",
          "district_id": "3208021",
          "name": "HAURKUNING"
        },
        {
          "id": "3208021002",
          "district_id": "3208021",
          "name": "KERTAYUGA"
        },
        {
          "id": "3208021003",
          "district_id": "3208021",
          "name": "WINDUSARI"
        },
        {
          "id": "3208021004",
          "district_id": "3208021",
          "name": "NUSAHERANG"
        },
        {
          "id": "3208021005",
          "district_id": "3208021",
          "name": "KERTAWIRAMA"
        },
        {
          "id": "3208021006",
          "district_id": "3208021",
          "name": "CIKADU"
        },
        {
          "id": "3208021007",
          "district_id": "3208021",
          "name": "JAMBAR"
        },
        {
          "id": "3208021008",
          "district_id": "3208021",
          "name": "CIASIH"
        },
        {
          "id": "3208030009",
          "district_id": "3208030",
          "name": "GUNUNGMANIK"
        },
        {
          "id": "3208030010",
          "district_id": "3208030",
          "name": "PINARA"
        },
        {
          "id": "3208030011",
          "district_id": "3208030",
          "name": "CIPEDES"
        },
        {
          "id": "3208030012",
          "district_id": "3208030",
          "name": "PAMUPUKAN"
        },
        {
          "id": "3208030013",
          "district_id": "3208030",
          "name": "CIJEMIT"
        },
        {
          "id": "3208030014",
          "district_id": "3208030",
          "name": "CINIRU"
        },
        {
          "id": "3208030015",
          "district_id": "3208030",
          "name": "RAMBATAN"
        },
        {
          "id": "3208030016",
          "district_id": "3208030",
          "name": "LONGKEWANG"
        },
        {
          "id": "3208030017",
          "district_id": "3208030",
          "name": "MUNGKALDATAR"
        },
        {
          "id": "3208031001",
          "district_id": "3208031",
          "name": "TUNDAGAN"
        },
        {
          "id": "3208031002",
          "district_id": "3208031",
          "name": "BUNIGEULIS"
        },
        {
          "id": "3208031003",
          "district_id": "3208031",
          "name": "HANTARA"
        },
        {
          "id": "3208031004",
          "district_id": "3208031",
          "name": "CIKONDANG"
        },
        {
          "id": "3208031005",
          "district_id": "3208031",
          "name": "PASIRAGUNG"
        },
        {
          "id": "3208031006",
          "district_id": "3208031",
          "name": "CITAPEN"
        },
        {
          "id": "3208031007",
          "district_id": "3208031",
          "name": "PAKAPASAN HILIR"
        },
        {
          "id": "3208031008",
          "district_id": "3208031",
          "name": "PAKAPASAN GIRANG"
        },
        {
          "id": "3208040001",
          "district_id": "3208040",
          "name": "CANTILAN"
        },
        {
          "id": "3208040002",
          "district_id": "3208040",
          "name": "SELAJAMBE"
        },
        {
          "id": "3208040003",
          "district_id": "3208040",
          "name": "CIBERUNG"
        },
        {
          "id": "3208040004",
          "district_id": "3208040",
          "name": "KUTAWARINGIN"
        },
        {
          "id": "3208040005",
          "district_id": "3208040",
          "name": "BAGAWAT"
        },
        {
          "id": "3208040006",
          "district_id": "3208040",
          "name": "JAMBERAMA"
        },
        {
          "id": "3208040007",
          "district_id": "3208040",
          "name": "PADAHURIP"
        },
        {
          "id": "3208050001",
          "district_id": "3208050",
          "name": "TANGKOLO"
        },
        {
          "id": "3208050002",
          "district_id": "3208050",
          "name": "PAMULIHAN"
        },
        {
          "id": "3208050004",
          "district_id": "3208050",
          "name": "JATISARI"
        },
        {
          "id": "3208050009",
          "district_id": "3208050",
          "name": "SUBANG"
        },
        {
          "id": "3208050010",
          "district_id": "3208050",
          "name": "BANGUNJAYA"
        },
        {
          "id": "3208050011",
          "district_id": "3208050",
          "name": "GUNUNGACI"
        },
        {
          "id": "3208050012",
          "district_id": "3208050",
          "name": "SITUGEDE"
        },
        {
          "id": "3208051001",
          "district_id": "3208051",
          "name": "MANDAPAJAYA"
        },
        {
          "id": "3208051002",
          "district_id": "3208051",
          "name": "LEGOKHERANG"
        },
        {
          "id": "3208051004",
          "district_id": "3208051",
          "name": "CILEBAK"
        },
        {
          "id": "3208051005",
          "district_id": "3208051",
          "name": "JALATRANG"
        },
        {
          "id": "3208051006",
          "district_id": "3208051",
          "name": "PATALA"
        },
        {
          "id": "3208051007",
          "district_id": "3208051",
          "name": "CILIMUSARI"
        },
        {
          "id": "3208060001",
          "district_id": "3208060",
          "name": "CITUNDUN"
        },
        {
          "id": "3208060006",
          "district_id": "3208060",
          "name": "SAGARANTEN"
        },
        {
          "id": "3208060007",
          "district_id": "3208060",
          "name": "SUMBERJAYA"
        },
        {
          "id": "3208060008",
          "district_id": "3208060",
          "name": "LEBAKHERANG"
        },
        {
          "id": "3208060009",
          "district_id": "3208060",
          "name": "CILAYUNG"
        },
        {
          "id": "3208060010",
          "district_id": "3208060",
          "name": "CIWARU"
        },
        {
          "id": "3208060011",
          "district_id": "3208060",
          "name": "LINGGA JAYA"
        },
        {
          "id": "3208060013",
          "district_id": "3208060",
          "name": "KARANGBARU"
        },
        {
          "id": "3208060014",
          "district_id": "3208060",
          "name": "GARAJATI"
        },
        {
          "id": "3208060016",
          "district_id": "3208060",
          "name": "BAOK"
        },
        {
          "id": "3208060017",
          "district_id": "3208060",
          "name": "ANDAMUI"
        },
        {
          "id": "3208060018",
          "district_id": "3208060",
          "name": "CITIKUR"
        },
        {
          "id": "3208061001",
          "district_id": "3208061",
          "name": "MARGACINA"
        },
        {
          "id": "3208061002",
          "district_id": "3208061",
          "name": "JABRANTI"
        },
        {
          "id": "3208061003",
          "district_id": "3208061",
          "name": "KARANGKANCANA"
        },
        {
          "id": "3208061004",
          "district_id": "3208061",
          "name": "KADUAGUNG"
        },
        {
          "id": "3208061005",
          "district_id": "3208061",
          "name": "SEGONG"
        },
        {
          "id": "3208061006",
          "district_id": "3208061",
          "name": "TANJUNGKERTA"
        },
        {
          "id": "3208061007",
          "district_id": "3208061",
          "name": "SUKASARI"
        },
        {
          "id": "3208061008",
          "district_id": "3208061",
          "name": "SIMPAY JAYA"
        },
        {
          "id": "3208061009",
          "district_id": "3208061",
          "name": "CIHANJARO"
        },
        {
          "id": "3208070002",
          "district_id": "3208070",
          "name": "CIANGIR"
        },
        {
          "id": "3208070003",
          "district_id": "3208070",
          "name": "CIPONDOK"
        },
        {
          "id": "3208070004",
          "district_id": "3208070",
          "name": "SUKAHARJA"
        },
        {
          "id": "3208070005",
          "district_id": "3208070",
          "name": "SINDANGJAWA"
        },
        {
          "id": "3208070011",
          "district_id": "3208070",
          "name": "SUKAMAJU"
        },
        {
          "id": "3208070012",
          "district_id": "3208070",
          "name": "CIBINGBIN"
        },
        {
          "id": "3208070013",
          "district_id": "3208070",
          "name": "CITENJO"
        },
        {
          "id": "3208070014",
          "district_id": "3208070",
          "name": "DUKUHBADAG"
        },
        {
          "id": "3208070017",
          "district_id": "3208070",
          "name": "CISAAT"
        },
        {
          "id": "3208070018",
          "district_id": "3208070",
          "name": "BANTARPANJANG"
        },
        {
          "id": "3208071001",
          "district_id": "3208071",
          "name": "CIMARA"
        },
        {
          "id": "3208071002",
          "district_id": "3208071",
          "name": "KAWUNGSARI"
        },
        {
          "id": "3208071003",
          "district_id": "3208071",
          "name": "SUKARAPIH"
        },
        {
          "id": "3208071004",
          "district_id": "3208071",
          "name": "SUMURWIRU"
        },
        {
          "id": "3208071005",
          "district_id": "3208071",
          "name": "CIBEUREUM"
        },
        {
          "id": "3208071006",
          "district_id": "3208071",
          "name": "RANDUSARI"
        },
        {
          "id": "3208071007",
          "district_id": "3208071",
          "name": "SUKADANA"
        },
        {
          "id": "3208071008",
          "district_id": "3208071",
          "name": "TARIKOLOT"
        },
        {
          "id": "3208080001",
          "district_id": "3208080",
          "name": "WALAHARCAGEUR"
        },
        {
          "id": "3208080002",
          "district_id": "3208080",
          "name": "DUKUHPICUNG"
        },
        {
          "id": "3208080003",
          "district_id": "3208080",
          "name": "WILANAGARA"
        },
        {
          "id": "3208080004",
          "district_id": "3208080",
          "name": "SINDANGSARI"
        },
        {
          "id": "3208080005",
          "district_id": "3208080",
          "name": "CIGEDANG"
        },
        {
          "id": "3208080006",
          "district_id": "3208080",
          "name": "LURAGUNGTONGGOH"
        },
        {
          "id": "3208080007",
          "district_id": "3208080",
          "name": "MARGASARI"
        },
        {
          "id": "3208080008",
          "district_id": "3208080",
          "name": "CIRAHAYU"
        },
        {
          "id": "3208080009",
          "district_id": "3208080",
          "name": "SINDANGSUKA"
        },
        {
          "id": "3208080010",
          "district_id": "3208080",
          "name": "CIKANDANG"
        },
        {
          "id": "3208080011",
          "district_id": "3208080",
          "name": "PANYOSOGAN"
        },
        {
          "id": "3208080012",
          "district_id": "3208080",
          "name": "GUNUNGKARUNG"
        },
        {
          "id": "3208080013",
          "district_id": "3208080",
          "name": "DUKUHMAJA"
        },
        {
          "id": "3208080014",
          "district_id": "3208080",
          "name": "LURAGUNGLANDEUH"
        },
        {
          "id": "3208080015",
          "district_id": "3208080",
          "name": "CIKADUWETAN"
        },
        {
          "id": "3208080016",
          "district_id": "3208080",
          "name": "BENDA"
        },
        {
          "id": "3208081001",
          "district_id": "3208081",
          "name": "CILEUYA"
        },
        {
          "id": "3208081004",
          "district_id": "3208081",
          "name": "MARGAMUKTI"
        },
        {
          "id": "3208081005",
          "district_id": "3208081",
          "name": "CIMULYA"
        },
        {
          "id": "3208081006",
          "district_id": "3208081",
          "name": "GUNUNGSARI"
        },
        {
          "id": "3208081007",
          "district_id": "3208081",
          "name": "CIMAHI"
        },
        {
          "id": "3208081008",
          "district_id": "3208081",
          "name": "KANANGA"
        },
        {
          "id": "3208081009",
          "district_id": "3208081",
          "name": "CIKEUSAL"
        },
        {
          "id": "3208081010",
          "district_id": "3208081",
          "name": "MULYAJAYA"
        },
        {
          "id": "3208081011",
          "district_id": "3208081",
          "name": "SUKAJAYA"
        },
        {
          "id": "3208081012",
          "district_id": "3208081",
          "name": "MEKARJAYA"
        },
        {
          "id": "3208090001",
          "district_id": "3208090",
          "name": "CIHIDEUNGGIRANG"
        },
        {
          "id": "3208090002",
          "district_id": "3208090",
          "name": "CIHIDEUNGHILIR"
        },
        {
          "id": "3208090003",
          "district_id": "3208090",
          "name": "CIDAHU"
        },
        {
          "id": "3208090004",
          "district_id": "3208090",
          "name": "KERTAWINANGUN"
        },
        {
          "id": "3208090005",
          "district_id": "3208090",
          "name": "DATAR"
        },
        {
          "id": "3208090007",
          "district_id": "3208090",
          "name": "LEGOK"
        },
        {
          "id": "3208090008",
          "district_id": "3208090",
          "name": "CIEURIH"
        },
        {
          "id": "3208090009",
          "district_id": "3208090",
          "name": "NANGGELA"
        },
        {
          "id": "3208090016",
          "district_id": "3208090",
          "name": "CIBULAN"
        },
        {
          "id": "3208090017",
          "district_id": "3208090",
          "name": "CIKEUSIK"
        },
        {
          "id": "3208090018",
          "district_id": "3208090",
          "name": "JATIMULYA"
        },
        {
          "id": "3208091001",
          "district_id": "3208091",
          "name": "KALIMANGGIS KULON"
        },
        {
          "id": "3208091002",
          "district_id": "3208091",
          "name": "KALIMANGGIS WETAN"
        },
        {
          "id": "3208091003",
          "district_id": "3208091",
          "name": "CIPANCUR"
        },
        {
          "id": "3208091004",
          "district_id": "3208091",
          "name": "PARTAWANGUNAN"
        },
        {
          "id": "3208091005",
          "district_id": "3208091",
          "name": "KERTAWANA"
        },
        {
          "id": "3208091006",
          "district_id": "3208091",
          "name": "WANASARAYA"
        },
        {
          "id": "3208100002",
          "district_id": "3208100",
          "name": "CIJAGAMULYA"
        },
        {
          "id": "3208100003",
          "district_id": "3208100",
          "name": "CIOMAS"
        },
        {
          "id": "3208100004",
          "district_id": "3208100",
          "name": "SIDARAJA"
        },
        {
          "id": "3208100005",
          "district_id": "3208100",
          "name": "PAJAWANLOR"
        },
        {
          "id": "3208100006",
          "district_id": "3208100",
          "name": "LEBAKSIUH"
        },
        {
          "id": "3208100007",
          "district_id": "3208100",
          "name": "KERAMATMULYA"
        },
        {
          "id": "3208100008",
          "district_id": "3208100",
          "name": "GERESIK"
        },
        {
          "id": "3208100009",
          "district_id": "3208100",
          "name": "CIPUTAT"
        },
        {
          "id": "3208100010",
          "district_id": "3208100",
          "name": "KAPANDAYAN"
        },
        {
          "id": "3208100011",
          "district_id": "3208100",
          "name": "DUKUHDALEM"
        },
        {
          "id": "3208100014",
          "district_id": "3208100",
          "name": "PANGKALAN"
        },
        {
          "id": "3208100015",
          "district_id": "3208100",
          "name": "CIAWIGEBANG"
        },
        {
          "id": "3208100016",
          "district_id": "3208100",
          "name": "KADURAMA"
        },
        {
          "id": "3208100017",
          "district_id": "3208100",
          "name": "PAMIJAHAN"
        },
        {
          "id": "3208100018",
          "district_id": "3208100",
          "name": "KARANGKAMULYAN"
        },
        {
          "id": "3208100019",
          "district_id": "3208100",
          "name": "CIAWILOR"
        },
        {
          "id": "3208100026",
          "district_id": "3208100",
          "name": "CIHAUR"
        },
        {
          "id": "3208100027",
          "district_id": "3208100",
          "name": "CIGARUKGAK"
        },
        {
          "id": "3208100028",
          "district_id": "3208100",
          "name": "MEKARJAYA"
        },
        {
          "id": "3208100029",
          "district_id": "3208100",
          "name": "SUKARAJA"
        },
        {
          "id": "3208100031",
          "district_id": "3208100",
          "name": "PADARAMA"
        },
        {
          "id": "3208100032",
          "district_id": "3208100",
          "name": "SUKADANA"
        },
        {
          "id": "3208100033",
          "district_id": "3208100",
          "name": "CIKUBANGMULYA"
        },
        {
          "id": "3208100034",
          "district_id": "3208100",
          "name": "CIHIRUP"
        },
        {
          "id": "3208101001",
          "district_id": "3208101",
          "name": "MUNCANGELA"
        },
        {
          "id": "3208101002",
          "district_id": "3208101",
          "name": "KAROYA"
        },
        {
          "id": "3208101003",
          "district_id": "3208101",
          "name": "SUSUKAN"
        },
        {
          "id": "3208101004",
          "district_id": "3208101",
          "name": "SUKAMUKTI"
        },
        {
          "id": "3208101005",
          "district_id": "3208101",
          "name": "CIPICUNG"
        },
        {
          "id": "3208101006",
          "district_id": "3208101",
          "name": "MEKARSARI"
        },
        {
          "id": "3208101007",
          "district_id": "3208101",
          "name": "CIMARANTEN"
        },
        {
          "id": "3208101008",
          "district_id": "3208101",
          "name": "PAMULIHAN"
        },
        {
          "id": "3208101009",
          "district_id": "3208101",
          "name": "SALAREUMA"
        },
        {
          "id": "3208101010",
          "district_id": "3208101",
          "name": "SUGANANGAN"
        },
        {
          "id": "3208110014",
          "district_id": "3208110",
          "name": "CINAGARA"
        },
        {
          "id": "3208110015",
          "district_id": "3208110",
          "name": "CINEUMBEUY"
        },
        {
          "id": "3208110016",
          "district_id": "3208110",
          "name": "CIPETIR"
        },
        {
          "id": "3208110017",
          "district_id": "3208110",
          "name": "LEBAKWANGI"
        },
        {
          "id": "3208110018",
          "district_id": "3208110",
          "name": "MANCAGAR"
        },
        {
          "id": "3208110019",
          "district_id": "3208110",
          "name": "LANGSEB"
        },
        {
          "id": "3208110023",
          "district_id": "3208110",
          "name": "PAJAWANKIDUL"
        },
        {
          "id": "3208110024",
          "district_id": "3208110",
          "name": "PAGUNDAN"
        },
        {
          "id": "3208110025",
          "district_id": "3208110",
          "name": "MANGGARI"
        },
        {
          "id": "3208110026",
          "district_id": "3208110",
          "name": "PASAYANGAN"
        },
        {
          "id": "3208110027",
          "district_id": "3208110",
          "name": "SINDANG"
        },
        {
          "id": "3208110028",
          "district_id": "3208110",
          "name": "BENDUNGAN"
        },
        {
          "id": "3208110029",
          "district_id": "3208110",
          "name": "MEKARWANGI"
        },
        {
          "id": "3208111001",
          "district_id": "3208111",
          "name": "CIPAKEM"
        },
        {
          "id": "3208111002",
          "district_id": "3208111",
          "name": "GIRIWARINGIN"
        },
        {
          "id": "3208111003",
          "district_id": "3208111",
          "name": "GARAHAJI"
        },
        {
          "id": "3208111004",
          "district_id": "3208111",
          "name": "GALAHERANG"
        },
        {
          "id": "3208111005",
          "district_id": "3208111",
          "name": "MEKARSARI"
        },
        {
          "id": "3208111006",
          "district_id": "3208111",
          "name": "PADAMULYA"
        },
        {
          "id": "3208111007",
          "district_id": "3208111",
          "name": "CIKAHURIPAN"
        },
        {
          "id": "3208111008",
          "district_id": "3208111",
          "name": "PARAKAN"
        },
        {
          "id": "3208111009",
          "district_id": "3208111",
          "name": "KUTAMANDARAKAN"
        },
        {
          "id": "3208111010",
          "district_id": "3208111",
          "name": "MALEBER"
        },
        {
          "id": "3208111011",
          "district_id": "3208111",
          "name": "KARANGTENGAH"
        },
        {
          "id": "3208111012",
          "district_id": "3208111",
          "name": "DUKUHTENGAH"
        },
        {
          "id": "3208111013",
          "district_id": "3208111",
          "name": "BUNIASIH"
        },
        {
          "id": "3208111014",
          "district_id": "3208111",
          "name": "CIPORANG"
        },
        {
          "id": "3208111015",
          "district_id": "3208111",
          "name": "KUTARAJA"
        },
        {
          "id": "3208111016",
          "district_id": "3208111",
          "name": "MANDALAJAYA"
        },
        {
          "id": "3208120001",
          "district_id": "3208120",
          "name": "CIRUKEM"
        },
        {
          "id": "3208120002",
          "district_id": "3208120",
          "name": "GEWOK"
        },
        {
          "id": "3208120003",
          "district_id": "3208120",
          "name": "KUTAKEMBARAN"
        },
        {
          "id": "3208120004",
          "district_id": "3208120",
          "name": "PAKEMBANGAN"
        },
        {
          "id": "3208120005",
          "district_id": "3208120",
          "name": "KADATUAN"
        },
        {
          "id": "3208120006",
          "district_id": "3208120",
          "name": "TEMBONG"
        },
        {
          "id": "3208120007",
          "district_id": "3208120",
          "name": "LENGKONG"
        },
        {
          "id": "3208120008",
          "district_id": "3208120",
          "name": "PURWASARI"
        },
        {
          "id": "3208120009",
          "district_id": "3208120",
          "name": "GARAWANGI"
        },
        {
          "id": "3208120010",
          "district_id": "3208120",
          "name": "KARAMATWANGI"
        },
        {
          "id": "3208120011",
          "district_id": "3208120",
          "name": "SUKAIMUT"
        },
        {
          "id": "3208120012",
          "district_id": "3208120",
          "name": "CIKANANGA"
        },
        {
          "id": "3208120013",
          "district_id": "3208120",
          "name": "TAMBAKBAYA"
        },
        {
          "id": "3208120014",
          "district_id": "3208120",
          "name": "MEKARMULYA"
        },
        {
          "id": "3208120015",
          "district_id": "3208120",
          "name": "SUKAMULYA"
        },
        {
          "id": "3208120016",
          "district_id": "3208120",
          "name": "MANCAGAR"
        },
        {
          "id": "3208120017",
          "district_id": "3208120",
          "name": "CITIUSARI"
        },
        {
          "id": "3208121001",
          "district_id": "3208121",
          "name": "KERTAUNGARAN"
        },
        {
          "id": "3208121002",
          "district_id": "3208121",
          "name": "KADUAGUNG"
        },
        {
          "id": "3208121003",
          "district_id": "3208121",
          "name": "SINDANGSARI"
        },
        {
          "id": "3208121004",
          "district_id": "3208121",
          "name": "KERTAWANGUNAN"
        },
        {
          "id": "3208121005",
          "district_id": "3208121",
          "name": "SINDANGAGUNG"
        },
        {
          "id": "3208121006",
          "district_id": "3208121",
          "name": "BALONG"
        },
        {
          "id": "3208121007",
          "district_id": "3208121",
          "name": "MEKARMUKTI"
        },
        {
          "id": "3208121008",
          "district_id": "3208121",
          "name": "KERTAYASA"
        },
        {
          "id": "3208121009",
          "district_id": "3208121",
          "name": "TIRTAWANGUNAN"
        },
        {
          "id": "3208121010",
          "district_id": "3208121",
          "name": "BABAKANREUMA"
        },
        {
          "id": "3208121011",
          "district_id": "3208121",
          "name": "DUKUHLOR"
        },
        {
          "id": "3208121012",
          "district_id": "3208121",
          "name": "TARAJU"
        },
        {
          "id": "3208130001",
          "district_id": "3208130",
          "name": "CIBINUANG"
        },
        {
          "id": "3208130002",
          "district_id": "3208130",
          "name": "CITANGTU"
        },
        {
          "id": "3208130003",
          "district_id": "3208130",
          "name": "KARANGTAWANG"
        },
        {
          "id": "3208130004",
          "district_id": "3208130",
          "name": "WINDUHAJI"
        },
        {
          "id": "3208130005",
          "district_id": "3208130",
          "name": "KUNINGAN"
        },
        {
          "id": "3208130006",
          "district_id": "3208130",
          "name": "PURWAWINANGUN"
        },
        {
          "id": "3208130007",
          "district_id": "3208130",
          "name": "CIGINTUNG"
        },
        {
          "id": "3208130008",
          "district_id": "3208130",
          "name": "CIJOHO"
        },
        {
          "id": "3208130009",
          "district_id": "3208130",
          "name": "WINDUSENGKAHAN"
        },
        {
          "id": "3208130010",
          "district_id": "3208130",
          "name": "CIPORANG"
        },
        {
          "id": "3208130011",
          "district_id": "3208130",
          "name": "ANCARAN"
        },
        {
          "id": "3208130012",
          "district_id": "3208130",
          "name": "KEDUNGARUM"
        },
        {
          "id": "3208130013",
          "district_id": "3208130",
          "name": "CIRENDANG"
        },
        {
          "id": "3208130014",
          "district_id": "3208130",
          "name": "AWIRARANGAN"
        },
        {
          "id": "3208130015",
          "district_id": "3208130",
          "name": "KASTURI"
        },
        {
          "id": "3208130016",
          "district_id": "3208130",
          "name": "PADAREK"
        },
        {
          "id": "3208140001",
          "district_id": "3208140",
          "name": "CIGADUNG"
        },
        {
          "id": "3208140002",
          "district_id": "3208140",
          "name": "SUKAMULYA"
        },
        {
          "id": "3208140003",
          "district_id": "3208140",
          "name": "CILEULEUY"
        },
        {
          "id": "3208140004",
          "district_id": "3208140",
          "name": "PUNCAK"
        },
        {
          "id": "3208140005",
          "district_id": "3208140",
          "name": "BABAKANMULYA"
        },
        {
          "id": "3208140006",
          "district_id": "3208140",
          "name": "CISANTANA"
        },
        {
          "id": "3208140007",
          "district_id": "3208140",
          "name": "CIGUGUR"
        },
        {
          "id": "3208140008",
          "district_id": "3208140",
          "name": "WINDUHERANG"
        },
        {
          "id": "3208140009",
          "district_id": "3208140",
          "name": "GUNUNGKELING"
        },
        {
          "id": "3208140010",
          "district_id": "3208140",
          "name": "CIPARI"
        },
        {
          "id": "3208150001",
          "district_id": "3208150",
          "name": "PAJAMBON"
        },
        {
          "id": "3208150002",
          "district_id": "3208150",
          "name": "RAGAWACANA"
        },
        {
          "id": "3208150003",
          "district_id": "3208150",
          "name": "CILOWA"
        },
        {
          "id": "3208150005",
          "district_id": "3208150",
          "name": "GEREBA"
        },
        {
          "id": "3208150007",
          "district_id": "3208150",
          "name": "CIKUBANGSARI"
        },
        {
          "id": "3208150008",
          "district_id": "3208150",
          "name": "WIDARASARI"
        },
        {
          "id": "3208150009",
          "district_id": "3208150",
          "name": "CILAJA"
        },
        {
          "id": "3208150010",
          "district_id": "3208150",
          "name": "BOJONG"
        },
        {
          "id": "3208150011",
          "district_id": "3208150",
          "name": "CIKASO"
        },
        {
          "id": "3208150012",
          "district_id": "3208150",
          "name": "KRAMATMULYA"
        },
        {
          "id": "3208150013",
          "district_id": "3208150",
          "name": "CIBENTANG"
        },
        {
          "id": "3208150014",
          "district_id": "3208150",
          "name": "GANDASOLI"
        },
        {
          "id": "3208150015",
          "district_id": "3208150",
          "name": "KALAPAGUNUNG"
        },
        {
          "id": "3208150017",
          "district_id": "3208150",
          "name": "KARANGMANGU"
        },
        {
          "id": "3208160001",
          "district_id": "3208160",
          "name": "SUKAMUKTI"
        },
        {
          "id": "3208160002",
          "district_id": "3208160",
          "name": "SIDAMULYA"
        },
        {
          "id": "3208160003",
          "district_id": "3208160",
          "name": "JALAKSANA"
        },
        {
          "id": "3208160004",
          "district_id": "3208160",
          "name": "BABAKANMULYA"
        },
        {
          "id": "3208160005",
          "district_id": "3208160",
          "name": "SANGKANERANG"
        },
        {
          "id": "3208160006",
          "district_id": "3208160",
          "name": "SAYANA"
        },
        {
          "id": "3208160007",
          "district_id": "3208160",
          "name": "PEUSING"
        },
        {
          "id": "3208160008",
          "district_id": "3208160",
          "name": "SEMBAWA"
        },
        {
          "id": "3208160009",
          "district_id": "3208160",
          "name": "SADAMANTRA"
        },
        {
          "id": "3208160010",
          "district_id": "3208160",
          "name": "MANISKIDUL"
        },
        {
          "id": "3208160011",
          "district_id": "3208160",
          "name": "MANISLOR"
        },
        {
          "id": "3208160013",
          "district_id": "3208160",
          "name": "PADAMENAK"
        },
        {
          "id": "3208160014",
          "district_id": "3208160",
          "name": "CINIRU"
        },
        {
          "id": "3208160016",
          "district_id": "3208160",
          "name": "SINDANGBARANG"
        },
        {
          "id": "3208160017",
          "district_id": "3208160",
          "name": "NANGGERANG"
        },
        {
          "id": "3208161001",
          "district_id": "3208161",
          "name": "RAJADANU"
        },
        {
          "id": "3208161002",
          "district_id": "3208161",
          "name": "DUKUHDALEM"
        },
        {
          "id": "3208161003",
          "district_id": "3208161",
          "name": "GARATENGAH"
        },
        {
          "id": "3208161004",
          "district_id": "3208161",
          "name": "JAPARA"
        },
        {
          "id": "3208161005",
          "district_id": "3208161",
          "name": "SINGKUP"
        },
        {
          "id": "3208161006",
          "district_id": "3208161",
          "name": "WANO"
        },
        {
          "id": "3208161007",
          "district_id": "3208161",
          "name": "CITAPEN"
        },
        {
          "id": "3208161008",
          "district_id": "3208161",
          "name": "CENGAL"
        },
        {
          "id": "3208161009",
          "district_id": "3208161",
          "name": "CIKELENG"
        },
        {
          "id": "3208161010",
          "district_id": "3208161",
          "name": "KALIMATI"
        },
        {
          "id": "3208170001",
          "district_id": "3208170",
          "name": "BANDORASA KULON"
        },
        {
          "id": "3208170002",
          "district_id": "3208170",
          "name": "BANDORASA WETAN"
        },
        {
          "id": "3208170012",
          "district_id": "3208170",
          "name": "BOJONG"
        },
        {
          "id": "3208170013",
          "district_id": "3208170",
          "name": "LINGGAMEKAR"
        },
        {
          "id": "3208170014",
          "district_id": "3208170",
          "name": "LINGGASANA"
        },
        {
          "id": "3208170015",
          "district_id": "3208170",
          "name": "LINGGAJATI"
        },
        {
          "id": "3208170016",
          "district_id": "3208170",
          "name": "LINGGAINDAH"
        },
        {
          "id": "3208170017",
          "district_id": "3208170",
          "name": "CILIMUS"
        },
        {
          "id": "3208170020",
          "district_id": "3208170",
          "name": "CARACAS"
        },
        {
          "id": "3208170021",
          "district_id": "3208170",
          "name": "SAMPORA"
        },
        {
          "id": "3208170022",
          "district_id": "3208170",
          "name": "KALIAREN"
        },
        {
          "id": "3208170023",
          "district_id": "3208170",
          "name": "SETIANEGARA"
        },
        {
          "id": "3208170024",
          "district_id": "3208170",
          "name": "CIBEUREUM"
        },
        {
          "id": "3208171001",
          "district_id": "3208171",
          "name": "SANGKANURIP"
        },
        {
          "id": "3208171002",
          "district_id": "3208171",
          "name": "SANGKANMULYA"
        },
        {
          "id": "3208171003",
          "district_id": "3208171",
          "name": "KARANGMUNCANG"
        },
        {
          "id": "3208171004",
          "district_id": "3208171",
          "name": "KOREAK"
        },
        {
          "id": "3208171005",
          "district_id": "3208171",
          "name": "JAMBUGEULIS"
        },
        {
          "id": "3208171006",
          "district_id": "3208171",
          "name": "BUNIGEULIS"
        },
        {
          "id": "3208171007",
          "district_id": "3208171",
          "name": "BABAKANJATI"
        },
        {
          "id": "3208171008",
          "district_id": "3208171",
          "name": "TIMBANG"
        },
        {
          "id": "3208171009",
          "district_id": "3208171",
          "name": "PANAWUAN"
        },
        {
          "id": "3208171010",
          "district_id": "3208171",
          "name": "INDAPATRA"
        },
        {
          "id": "3208171011",
          "district_id": "3208171",
          "name": "CIBUNTU"
        },
        {
          "id": "3208180001",
          "district_id": "3208180",
          "name": "TRIJAYA"
        },
        {
          "id": "3208180002",
          "district_id": "3208180",
          "name": "RANDOBAWAGIRANG"
        },
        {
          "id": "3208180003",
          "district_id": "3208180",
          "name": "SALAKADOMAS"
        },
        {
          "id": "3208180004",
          "district_id": "3208180",
          "name": "PAKEMBANGAN"
        },
        {
          "id": "3208180007",
          "district_id": "3208180",
          "name": "RANDOBAWAILIR"
        },
        {
          "id": "3208180008",
          "district_id": "3208180",
          "name": "KERTAWINANGUN"
        },
        {
          "id": "3208180009",
          "district_id": "3208180",
          "name": "SEDA"
        },
        {
          "id": "3208180010",
          "district_id": "3208180",
          "name": "NANGGERANGJAYA"
        },
        {
          "id": "3208180011",
          "district_id": "3208180",
          "name": "SUKASARI"
        },
        {
          "id": "3208180012",
          "district_id": "3208180",
          "name": "CIREA"
        },
        {
          "id": "3208180013",
          "district_id": "3208180",
          "name": "NANGGELA"
        },
        {
          "id": "3208180014",
          "district_id": "3208180",
          "name": "MANDIRANCAN"
        },
        {
          "id": "3208181001",
          "district_id": "3208181",
          "name": "SILEBU"
        },
        {
          "id": "3208181002",
          "district_id": "3208181",
          "name": "KAHIYANGAN"
        },
        {
          "id": "3208181003",
          "district_id": "3208181",
          "name": "PATALAGAN"
        },
        {
          "id": "3208181004",
          "district_id": "3208181",
          "name": "SUMBAKELING"
        },
        {
          "id": "3208181005",
          "district_id": "3208181",
          "name": "DANALAMPAH"
        },
        {
          "id": "3208181006",
          "district_id": "3208181",
          "name": "SINDANGKEMPENG"
        },
        {
          "id": "3208181007",
          "district_id": "3208181",
          "name": "PANCALANG"
        },
        {
          "id": "3208181008",
          "district_id": "3208181",
          "name": "TAJURBUNTU"
        },
        {
          "id": "3208181009",
          "district_id": "3208181",
          "name": "RAJAWETAN"
        },
        {
          "id": "3208181010",
          "district_id": "3208181",
          "name": "MEKARJAYA"
        },
        {
          "id": "3208181011",
          "district_id": "3208181",
          "name": "TENJOLAYAR"
        },
        {
          "id": "3208181012",
          "district_id": "3208181",
          "name": "TARIKOLOT"
        },
        {
          "id": "3208181013",
          "district_id": "3208181",
          "name": "SAREWU"
        },
        {
          "id": "3208190001",
          "district_id": "3208190",
          "name": "CIBUNTU"
        },
        {
          "id": "3208190002",
          "district_id": "3208190",
          "name": "PANIIS"
        },
        {
          "id": "3208190003",
          "district_id": "3208190",
          "name": "SINGKUP"
        },
        {
          "id": "3208190004",
          "district_id": "3208190",
          "name": "CIDAHU"
        },
        {
          "id": "3208190005",
          "district_id": "3208190",
          "name": "PASAWAHAN"
        },
        {
          "id": "3208190006",
          "district_id": "3208190",
          "name": "PADABEUNGHAR"
        },
        {
          "id": "3208190007",
          "district_id": "3208190",
          "name": "KADUELA"
        },
        {
          "id": "3208190008",
          "district_id": "3208190",
          "name": "PADAMATANG"
        },
        {
          "id": "3208190009",
          "district_id": "3208190",
          "name": "CIWIRU"
        },
        {
          "id": "3208190010",
          "district_id": "3208190",
          "name": "CIMARA"
        },
        {
          "id": "3209010008",
          "district_id": "3209010",
          "name": "WALED DESA"
        },
        {
          "id": "3209010009",
          "district_id": "3209010",
          "name": "WALED KOTA"
        },
        {
          "id": "3209010010",
          "district_id": "3209010",
          "name": "MEKARSARI"
        },
        {
          "id": "3209010011",
          "district_id": "3209010",
          "name": "WALED ASEM"
        },
        {
          "id": "3209010012",
          "district_id": "3209010",
          "name": "AMBIT"
        },
        {
          "id": "3209010013",
          "district_id": "3209010",
          "name": "CIUYAH"
        },
        {
          "id": "3209010014",
          "district_id": "3209010",
          "name": "GUNUNGSARI"
        },
        {
          "id": "3209010015",
          "district_id": "3209010",
          "name": "KARANGSARI"
        },
        {
          "id": "3209010016",
          "district_id": "3209010",
          "name": "CIKULAK KIDUL"
        },
        {
          "id": "3209010017",
          "district_id": "3209010",
          "name": "CIKULAK"
        },
        {
          "id": "3209010018",
          "district_id": "3209010",
          "name": "CIBOGO"
        },
        {
          "id": "3209010019",
          "district_id": "3209010",
          "name": "CISAAT"
        },
        {
          "id": "3209011001",
          "district_id": "3209011",
          "name": "TONJONG"
        },
        {
          "id": "3209011002",
          "district_id": "3209011",
          "name": "TANJUNG ANOM"
        },
        {
          "id": "3209011003",
          "district_id": "3209011",
          "name": "CILENGKRANG GIRANG"
        },
        {
          "id": "3209011004",
          "district_id": "3209011",
          "name": "CILENGKRANG"
        },
        {
          "id": "3209011005",
          "district_id": "3209011",
          "name": "PASALEMAN"
        },
        {
          "id": "3209011006",
          "district_id": "3209011",
          "name": "CIGOBANGWANGI"
        },
        {
          "id": "3209011007",
          "district_id": "3209011",
          "name": "CIGOBANG"
        },
        {
          "id": "3209020008",
          "district_id": "3209020",
          "name": "LEUWEUNGGAJAH"
        },
        {
          "id": "3209020009",
          "district_id": "3209020",
          "name": "TENJOMAYA"
        },
        {
          "id": "3209020010",
          "district_id": "3209020",
          "name": "DAMARGUNA"
        },
        {
          "id": "3209020011",
          "district_id": "3209020",
          "name": "JATISEENG"
        },
        {
          "id": "3209020012",
          "district_id": "3209020",
          "name": "JATISEENG KIDUL"
        },
        {
          "id": "3209020013",
          "district_id": "3209020",
          "name": "CILEDUG KULON"
        },
        {
          "id": "3209020014",
          "district_id": "3209020",
          "name": "CILEDUG WETAN"
        },
        {
          "id": "3209020015",
          "district_id": "3209020",
          "name": "CILEDUG LOR"
        },
        {
          "id": "3209020016",
          "district_id": "3209020",
          "name": "CILEDUG TENGAH"
        },
        {
          "id": "3209020017",
          "district_id": "3209020",
          "name": "BOJONGNEGARA"
        },
        {
          "id": "3209021001",
          "district_id": "3209021",
          "name": "SUKADANA"
        },
        {
          "id": "3209021002",
          "district_id": "3209021",
          "name": "PABUARAN KIDUL"
        },
        {
          "id": "3209021003",
          "district_id": "3209021",
          "name": "PABUARAN WETAN"
        },
        {
          "id": "3209021004",
          "district_id": "3209021",
          "name": "PABUARAN LOR"
        },
        {
          "id": "3209021005",
          "district_id": "3209021",
          "name": "JATIRENGGANG"
        },
        {
          "id": "3209021006",
          "district_id": "3209021",
          "name": "HULUBANTENG"
        },
        {
          "id": "3209021007",
          "district_id": "3209021",
          "name": "HULUBANTENG LOR"
        },
        {
          "id": "3209030009",
          "district_id": "3209030",
          "name": "ASTANALANGGAR"
        },
        {
          "id": "3209030010",
          "district_id": "3209030",
          "name": "BARISAN"
        },
        {
          "id": "3209030011",
          "district_id": "3209030",
          "name": "LOSARI KIDUL"
        },
        {
          "id": "3209030012",
          "district_id": "3209030",
          "name": "PANGGANGSARI"
        },
        {
          "id": "3209030013",
          "district_id": "3209030",
          "name": "LOSARI LOR"
        },
        {
          "id": "3209030014",
          "district_id": "3209030",
          "name": "MULYASARI"
        },
        {
          "id": "3209030015",
          "district_id": "3209030",
          "name": "KALIRAHAYU"
        },
        {
          "id": "3209030016",
          "district_id": "3209030",
          "name": "KALISARI"
        },
        {
          "id": "3209030017",
          "district_id": "3209030",
          "name": "AMBULU"
        },
        {
          "id": "3209030018",
          "district_id": "3209030",
          "name": "TAWANGSARI"
        },
        {
          "id": "3209031001",
          "district_id": "3209031",
          "name": "BABAKAN LOSARI"
        },
        {
          "id": "3209031002",
          "district_id": "3209031",
          "name": "SIDARESMI"
        },
        {
          "id": "3209031003",
          "district_id": "3209031",
          "name": "KALIBUNTU"
        },
        {
          "id": "3209031004",
          "district_id": "3209031",
          "name": "DUKUHWIDARA"
        },
        {
          "id": "3209031005",
          "district_id": "3209031",
          "name": "PASURUAN"
        },
        {
          "id": "3209031006",
          "district_id": "3209031",
          "name": "KALIMUKTI"
        },
        {
          "id": "3209031007",
          "district_id": "3209031",
          "name": "PABEDILAN KALER"
        },
        {
          "id": "3209031008",
          "district_id": "3209031",
          "name": "BABAKAN LOSARI LOR"
        },
        {
          "id": "3209031009",
          "district_id": "3209031",
          "name": "PABEDILAN KIDUL"
        },
        {
          "id": "3209031010",
          "district_id": "3209031",
          "name": "PABEDILAN KULON"
        },
        {
          "id": "3209031011",
          "district_id": "3209031",
          "name": "PABEDILAN WETAN"
        },
        {
          "id": "3209031012",
          "district_id": "3209031",
          "name": "TERSANA"
        },
        {
          "id": "3209031013",
          "district_id": "3209031",
          "name": "SILIHASIH"
        },
        {
          "id": "3209040001",
          "district_id": "3209040",
          "name": "CANGKUANG"
        },
        {
          "id": "3209040002",
          "district_id": "3209040",
          "name": "SERANG WETAN"
        },
        {
          "id": "3209040003",
          "district_id": "3209040",
          "name": "BOJONGGEBANG"
        },
        {
          "id": "3209040004",
          "district_id": "3209040",
          "name": "KUDUKERAS"
        },
        {
          "id": "3209040005",
          "district_id": "3209040",
          "name": "SUMBER KIDUL"
        },
        {
          "id": "3209040006",
          "district_id": "3209040",
          "name": "KUDUMULYA"
        },
        {
          "id": "3209040007",
          "district_id": "3209040",
          "name": "SUMBER LOR"
        },
        {
          "id": "3209040008",
          "district_id": "3209040",
          "name": "BABAKAN"
        },
        {
          "id": "3209040009",
          "district_id": "3209040",
          "name": "PAKUSAMBEN"
        },
        {
          "id": "3209040010",
          "district_id": "3209040",
          "name": "KARANGWANGUN"
        },
        {
          "id": "3209040011",
          "district_id": "3209040",
          "name": "BABAKAN GEBANG"
        },
        {
          "id": "3209040012",
          "district_id": "3209040",
          "name": "GEMBONGAN"
        },
        {
          "id": "3209040013",
          "district_id": "3209040",
          "name": "SERANG KULON"
        },
        {
          "id": "3209040014",
          "district_id": "3209040",
          "name": "GEMBONGAN MEKAR"
        },
        {
          "id": "3209041001",
          "district_id": "3209041",
          "name": "DOMPYONG KULON"
        },
        {
          "id": "3209041002",
          "district_id": "3209041",
          "name": "DOMPYONG WETAN"
        },
        {
          "id": "3209041003",
          "district_id": "3209041",
          "name": "KALIMEKAR"
        },
        {
          "id": "3209041004",
          "district_id": "3209041",
          "name": "KALIMARO"
        },
        {
          "id": "3209041005",
          "district_id": "3209041",
          "name": "GAGASARI"
        },
        {
          "id": "3209041006",
          "district_id": "3209041",
          "name": "KALIPASUNG"
        },
        {
          "id": "3209041007",
          "district_id": "3209041",
          "name": "GEBANG KULON"
        },
        {
          "id": "3209041008",
          "district_id": "3209041",
          "name": "GEBANG"
        },
        {
          "id": "3209041009",
          "district_id": "3209041",
          "name": "GEBANG UDIK"
        },
        {
          "id": "3209041010",
          "district_id": "3209041",
          "name": "GEBANG ILIR"
        },
        {
          "id": "3209041011",
          "district_id": "3209041",
          "name": "GEBANG MEKAR"
        },
        {
          "id": "3209041012",
          "district_id": "3209041",
          "name": "PELAYANGAN"
        },
        {
          "id": "3209041013",
          "district_id": "3209041",
          "name": "MELAKASARI"
        },
        {
          "id": "3209050008",
          "district_id": "3209050",
          "name": "TAMBELANG"
        },
        {
          "id": "3209050015",
          "district_id": "3209050",
          "name": "KARANGSUWUNG"
        },
        {
          "id": "3209050016",
          "district_id": "3209050",
          "name": "KARANGTENGAH"
        },
        {
          "id": "3209050017",
          "district_id": "3209050",
          "name": "KALIMEANG"
        },
        {
          "id": "3209050018",
          "district_id": "3209050",
          "name": "KARANGMALANG"
        },
        {
          "id": "3209050019",
          "district_id": "3209050",
          "name": "KARANGMEKAR"
        },
        {
          "id": "3209050020",
          "district_id": "3209050",
          "name": "KUBANGKARANG"
        },
        {
          "id": "3209050021",
          "district_id": "3209050",
          "name": "KARANGSEMBUNG"
        },
        {
          "id": "3209051001",
          "district_id": "3209051",
          "name": "SEUSEUPAN"
        },
        {
          "id": "3209051002",
          "district_id": "3209051",
          "name": "SUMURKONDANG"
        },
        {
          "id": "3209051003",
          "district_id": "3209051",
          "name": "JATIPIRING"
        },
        {
          "id": "3209051004",
          "district_id": "3209051",
          "name": "KUBANGDELEG"
        },
        {
          "id": "3209051005",
          "district_id": "3209051",
          "name": "KARANGANYAR"
        },
        {
          "id": "3209051006",
          "district_id": "3209051",
          "name": "KARANGWANGI"
        },
        {
          "id": "3209051008",
          "district_id": "3209051",
          "name": "KARANGWARENG"
        },
        {
          "id": "3209051009",
          "district_id": "3209051",
          "name": "KARANGASEM"
        },
        {
          "id": "3209060005",
          "district_id": "3209060",
          "name": "PICUNGPUGUR"
        },
        {
          "id": "3209060006",
          "district_id": "3209060",
          "name": "LEUWIDINGDING"
        },
        {
          "id": "3209060007",
          "district_id": "3209060",
          "name": "ASEM"
        },
        {
          "id": "3209060013",
          "district_id": "3209060",
          "name": "CIPEUJEUH KULON"
        },
        {
          "id": "3209060014",
          "district_id": "3209060",
          "name": "SINDANGLAUT"
        },
        {
          "id": "3209060015",
          "district_id": "3209060",
          "name": "CIPEUJEUH WETAN"
        },
        {
          "id": "3209060016",
          "district_id": "3209060",
          "name": "LEMAHABANG KULON"
        },
        {
          "id": "3209060017",
          "district_id": "3209060",
          "name": "LEMAHABANG"
        },
        {
          "id": "3209060018",
          "district_id": "3209060",
          "name": "SIGONG"
        },
        {
          "id": "3209060019",
          "district_id": "3209060",
          "name": "SARAJAYA"
        },
        {
          "id": "3209060020",
          "district_id": "3209060",
          "name": "TUK KARANGSUWUNG"
        },
        {
          "id": "3209060021",
          "district_id": "3209060",
          "name": "BELAWA"
        },
        {
          "id": "3209060022",
          "district_id": "3209060",
          "name": "WANGKELANG"
        },
        {
          "id": "3209061001",
          "district_id": "3209061",
          "name": "KARANGMANGU"
        },
        {
          "id": "3209061002",
          "district_id": "3209061",
          "name": "KALIGAWE"
        },
        {
          "id": "3209061003",
          "district_id": "3209061",
          "name": "KALIGAWE WETAN"
        },
        {
          "id": "3209061004",
          "district_id": "3209061",
          "name": "CURUG WETAN"
        },
        {
          "id": "3209061006",
          "district_id": "3209061",
          "name": "WILULANG"
        },
        {
          "id": "3209061007",
          "district_id": "3209061",
          "name": "SUSUKAN AGUNG"
        },
        {
          "id": "3209061008",
          "district_id": "3209061",
          "name": "SUSUKAN LEBAK"
        },
        {
          "id": "3209061009",
          "district_id": "3209061",
          "name": "SUSUKAN TONGGOH"
        },
        {
          "id": "3209061010",
          "district_id": "3209061",
          "name": "SAMPIH"
        },
        {
          "id": "3209061011",
          "district_id": "3209061",
          "name": "PASAWAHAN"
        },
        {
          "id": "3209061012",
          "district_id": "3209061",
          "name": "CIAWI ASIH"
        },
        {
          "id": "3209061013",
          "district_id": "3209061",
          "name": "CIAWIJAPURA"
        },
        {
          "id": "3209070001",
          "district_id": "3209070",
          "name": "KARANGWUNI"
        },
        {
          "id": "3209070002",
          "district_id": "3209070",
          "name": "SEDONG KIDUL"
        },
        {
          "id": "3209070003",
          "district_id": "3209070",
          "name": "SEDONG LOR"
        },
        {
          "id": "3209070004",
          "district_id": "3209070",
          "name": "WINDUJAYA"
        },
        {
          "id": "3209070005",
          "district_id": "3209070",
          "name": "WINDUHAJI"
        },
        {
          "id": "3209070006",
          "district_id": "3209070",
          "name": "KERTAWANGUN"
        },
        {
          "id": "3209070007",
          "district_id": "3209070",
          "name": "PANAMBANGAN"
        },
        {
          "id": "3209070008",
          "district_id": "3209070",
          "name": "PUTAT"
        },
        {
          "id": "3209070009",
          "district_id": "3209070",
          "name": "PANONGAN"
        },
        {
          "id": "3209070010",
          "district_id": "3209070",
          "name": "PANONGAN LOR"
        },
        {
          "id": "3209080005",
          "district_id": "3209080",
          "name": "SIDAMULYA"
        },
        {
          "id": "3209080006",
          "district_id": "3209080",
          "name": "MERTAPADA KULON"
        },
        {
          "id": "3209080007",
          "district_id": "3209080",
          "name": "MERTAPADA WETAN"
        },
        {
          "id": "3209080009",
          "district_id": "3209080",
          "name": "KANCI KULON"
        },
        {
          "id": "3209080010",
          "district_id": "3209080",
          "name": "KANCI"
        },
        {
          "id": "3209080011",
          "district_id": "3209080",
          "name": "ASTANAJAPURA"
        },
        {
          "id": "3209080015",
          "district_id": "3209080",
          "name": "KENDAL"
        },
        {
          "id": "3209080016",
          "district_id": "3209080",
          "name": "JAPURA KIDUL"
        },
        {
          "id": "3209080017",
          "district_id": "3209080",
          "name": "JAPURABAKTI"
        },
        {
          "id": "3209081001",
          "district_id": "3209081",
          "name": "ASTANAMUKTI"
        },
        {
          "id": "3209081002",
          "district_id": "3209081",
          "name": "PENGARENGAN"
        },
        {
          "id": "3209081003",
          "district_id": "3209081",
          "name": "JAPURA LOR"
        },
        {
          "id": "3209081004",
          "district_id": "3209081",
          "name": "BERINGIN"
        },
        {
          "id": "3209081005",
          "district_id": "3209081",
          "name": "RAWAURIP"
        },
        {
          "id": "3209081006",
          "district_id": "3209081",
          "name": "BENDUNGAN"
        },
        {
          "id": "3209081007",
          "district_id": "3209081",
          "name": "PANGENAN"
        },
        {
          "id": "3209081008",
          "district_id": "3209081",
          "name": "GETRAKMOYAN"
        },
        {
          "id": "3209090001",
          "district_id": "3209090",
          "name": "SETUPATOK"
        },
        {
          "id": "3209090003",
          "district_id": "3209090",
          "name": "MUNDU MESIGIT"
        },
        {
          "id": "3209090005",
          "district_id": "3209090",
          "name": "WARUDUWUR"
        },
        {
          "id": "3209090006",
          "district_id": "3209090",
          "name": "CITEMU"
        },
        {
          "id": "3209090007",
          "district_id": "3209090",
          "name": "BANDENGAN"
        },
        {
          "id": "3209090008",
          "district_id": "3209090",
          "name": "MUNDU PESISIR"
        },
        {
          "id": "3209090009",
          "district_id": "3209090",
          "name": "SUCI"
        },
        {
          "id": "3209090010",
          "district_id": "3209090",
          "name": "BANJARWANGUNAN"
        },
        {
          "id": "3209090011",
          "district_id": "3209090",
          "name": "PAMENGKANG"
        },
        {
          "id": "3209090012",
          "district_id": "3209090",
          "name": "SINARANCANG"
        },
        {
          "id": "3209100001",
          "district_id": "3209100",
          "name": "WANAYASA"
        },
        {
          "id": "3209100002",
          "district_id": "3209100",
          "name": "SINDANGKASIH"
        },
        {
          "id": "3209100003",
          "district_id": "3209100",
          "name": "SINDANGHAYU"
        },
        {
          "id": "3209100004",
          "district_id": "3209100",
          "name": "CIAWIGAJAH"
        },
        {
          "id": "3209100008",
          "district_id": "3209100",
          "name": "CIKANCAS"
        },
        {
          "id": "3209100009",
          "district_id": "3209100",
          "name": "HALIMPU"
        },
        {
          "id": "3209100010",
          "district_id": "3209100",
          "name": "CIPINANG"
        },
        {
          "id": "3209100012",
          "district_id": "3209100",
          "name": "PATAPAN"
        },
        {
          "id": "3209100013",
          "district_id": "3209100",
          "name": "KONDANGSARI"
        },
        {
          "id": "3209101001",
          "district_id": "3209101",
          "name": "GUMULUNG LEBAK"
        },
        {
          "id": "3209101002",
          "district_id": "3209101",
          "name": "LEBAK MEKAR"
        },
        {
          "id": "3209101003",
          "district_id": "3209101",
          "name": "GUMULUNG TONGGOH"
        },
        {
          "id": "3209101005",
          "district_id": "3209101",
          "name": "KAMARANG"
        },
        {
          "id": "3209101007",
          "district_id": "3209101",
          "name": "KAMARANG LEBAK"
        },
        {
          "id": "3209101014",
          "district_id": "3209101",
          "name": "DURAJAYA"
        },
        {
          "id": "3209101015",
          "district_id": "3209101",
          "name": "JATIPANCUR"
        },
        {
          "id": "3209101016",
          "district_id": "3209101",
          "name": "SINDANG KEMPENG"
        },
        {
          "id": "3209101017",
          "district_id": "3209101",
          "name": "NANGGELA"
        },
        {
          "id": "3209111001",
          "district_id": "3209111",
          "name": "SAMPIRAN"
        },
        {
          "id": "3209111002",
          "district_id": "3209111",
          "name": "CIPERNA"
        },
        {
          "id": "3209111003",
          "district_id": "3209111",
          "name": "KECOMBERAN"
        },
        {
          "id": "3209111004",
          "district_id": "3209111",
          "name": "CIREBON GIRANG"
        },
        {
          "id": "3209111005",
          "district_id": "3209111",
          "name": "KERANDON"
        },
        {
          "id": "3209111006",
          "district_id": "3209111",
          "name": "WANASABA KIDUL"
        },
        {
          "id": "3209111007",
          "district_id": "3209111",
          "name": "WANASABA LOR"
        },
        {
          "id": "3209111012",
          "district_id": "3209111",
          "name": "CEMPAKA"
        },
        {
          "id": "3209111013",
          "district_id": "3209111",
          "name": "KEPONGPONGAN"
        },
        {
          "id": "3209111014",
          "district_id": "3209111",
          "name": "KUBANG"
        },
        {
          "id": "3209111015",
          "district_id": "3209111",
          "name": "SARWADADI"
        },
        {
          "id": "3209120010",
          "district_id": "3209120",
          "name": "MATANGAJI"
        },
        {
          "id": "3209120011",
          "district_id": "3209120",
          "name": "SIDAWANGI"
        },
        {
          "id": "3209120014",
          "district_id": "3209120",
          "name": "BABAKAN"
        },
        {
          "id": "3209120016",
          "district_id": "3209120",
          "name": "PERBUTULAN"
        },
        {
          "id": "3209120017",
          "district_id": "3209120",
          "name": "KEMANTREN"
        },
        {
          "id": "3209120018",
          "district_id": "3209120",
          "name": "SENDANG"
        },
        {
          "id": "3209120020",
          "district_id": "3209120",
          "name": "PEJAMBON"
        },
        {
          "id": "3209120021",
          "district_id": "3209120",
          "name": "WATUBELAH"
        },
        {
          "id": "3209120022",
          "district_id": "3209120",
          "name": "PASALAKAN"
        },
        {
          "id": "3209120023",
          "district_id": "3209120",
          "name": "KALIWADAS"
        },
        {
          "id": "3209120024",
          "district_id": "3209120",
          "name": "TUKMUDAL"
        },
        {
          "id": "3209120025",
          "district_id": "3209120",
          "name": "KENANGA"
        },
        {
          "id": "3209121001",
          "district_id": "3209121",
          "name": "BOBOS"
        },
        {
          "id": "3209121002",
          "district_id": "3209121",
          "name": "CIKALAHANG"
        },
        {
          "id": "3209121003",
          "district_id": "3209121",
          "name": "MANDALA"
        },
        {
          "id": "3209121004",
          "district_id": "3209121",
          "name": "CISAAT"
        },
        {
          "id": "3209121005",
          "district_id": "3209121",
          "name": "SINDANGJAWA"
        },
        {
          "id": "3209121006",
          "district_id": "3209121",
          "name": "SINDANGMEKAR"
        },
        {
          "id": "3209121007",
          "district_id": "3209121",
          "name": "CANGKOAK"
        },
        {
          "id": "3209121008",
          "district_id": "3209121",
          "name": "KEPUNDUAN"
        },
        {
          "id": "3209121009",
          "district_id": "3209121",
          "name": "BALAD"
        },
        {
          "id": "3209121010",
          "district_id": "3209121",
          "name": "DUKUPUNTANG"
        },
        {
          "id": "3209121011",
          "district_id": "3209121",
          "name": "CIPANAS"
        },
        {
          "id": "3209121012",
          "district_id": "3209121",
          "name": "GIRINATA"
        },
        {
          "id": "3209121013",
          "district_id": "3209121",
          "name": "KEDONGDONG KIDUL"
        },
        {
          "id": "3209130006",
          "district_id": "3209130",
          "name": "CILUKRAK"
        },
        {
          "id": "3209130007",
          "district_id": "3209130",
          "name": "BALERANTE"
        },
        {
          "id": "3209130008",
          "district_id": "3209130",
          "name": "PANONGAN"
        },
        {
          "id": "3209130009",
          "district_id": "3209130",
          "name": "BEBERAN"
        },
        {
          "id": "3209130010",
          "district_id": "3209130",
          "name": "SEMPLO"
        },
        {
          "id": "3209130011",
          "district_id": "3209130",
          "name": "PALIMANAN TIMUR"
        },
        {
          "id": "3209130014",
          "district_id": "3209130",
          "name": "PEGAGAN"
        },
        {
          "id": "3209130015",
          "district_id": "3209130",
          "name": "LUNGBENDA"
        },
        {
          "id": "3209130016",
          "district_id": "3209130",
          "name": "CIAWI"
        },
        {
          "id": "3209130017",
          "district_id": "3209130",
          "name": "CENGKUANG"
        },
        {
          "id": "3209130018",
          "district_id": "3209130",
          "name": "TEGALKARANG"
        },
        {
          "id": "3209140006",
          "district_id": "3209140",
          "name": "CEMPAKA"
        },
        {
          "id": "3209140007",
          "district_id": "3209140",
          "name": "PAMIJAHAN"
        },
        {
          "id": "3209140017",
          "district_id": "3209140",
          "name": "LURAH"
        },
        {
          "id": "3209140018",
          "district_id": "3209140",
          "name": "MARIKANGEN"
        },
        {
          "id": "3209140019",
          "district_id": "3209140",
          "name": "BODE LOR"
        },
        {
          "id": "3209140020",
          "district_id": "3209140",
          "name": "BODESARI"
        },
        {
          "id": "3209140021",
          "district_id": "3209140",
          "name": "GOMBANG"
        },
        {
          "id": "3209140022",
          "district_id": "3209140",
          "name": "KARANGMULYA"
        },
        {
          "id": "3209140023",
          "district_id": "3209140",
          "name": "KARANGASEM"
        },
        {
          "id": "3209140024",
          "district_id": "3209140",
          "name": "PLUMBON"
        },
        {
          "id": "3209140025",
          "district_id": "3209140",
          "name": "PURBAWINANGUN"
        },
        {
          "id": "3209140026",
          "district_id": "3209140",
          "name": "KEBAREPAN"
        },
        {
          "id": "3209140027",
          "district_id": "3209140",
          "name": "PASANGGRAHAN"
        },
        {
          "id": "3209140028",
          "district_id": "3209140",
          "name": "KEDUNGSANA"
        },
        {
          "id": "3209140029",
          "district_id": "3209140",
          "name": "DANAMULYA"
        },
        {
          "id": "3209141001",
          "district_id": "3209141",
          "name": "CIKEDUK"
        },
        {
          "id": "3209141002",
          "district_id": "3209141",
          "name": "WARUGEDE"
        },
        {
          "id": "3209141003",
          "district_id": "3209141",
          "name": "KARANGWANGI"
        },
        {
          "id": "3209141004",
          "district_id": "3209141",
          "name": "GETASAN"
        },
        {
          "id": "3209141006",
          "district_id": "3209141",
          "name": "KEDUANAN"
        },
        {
          "id": "3209141007",
          "district_id": "3209141",
          "name": "WARUROYOM"
        },
        {
          "id": "3209141008",
          "district_id": "3209141",
          "name": "WARUKAWUNG"
        },
        {
          "id": "3209141009",
          "district_id": "3209141",
          "name": "WARUJAYA"
        },
        {
          "id": "3209141010",
          "district_id": "3209141",
          "name": "DEPOK"
        },
        {
          "id": "3209141011",
          "district_id": "3209141",
          "name": "KASUGENGAN KIDUL"
        },
        {
          "id": "3209141012",
          "district_id": "3209141",
          "name": "KASUGENGAN LOR"
        },
        {
          "id": "3209150005",
          "district_id": "3209150",
          "name": "KARANGSARI"
        },
        {
          "id": "3209150006",
          "district_id": "3209150",
          "name": "KERTASARI"
        },
        {
          "id": "3209150007",
          "district_id": "3209150",
          "name": "MEGU CILIK"
        },
        {
          "id": "3209150009",
          "district_id": "3209150",
          "name": "SETU WETAN"
        },
        {
          "id": "3209150010",
          "district_id": "3209150",
          "name": "WERU KIDUL"
        },
        {
          "id": "3209150011",
          "district_id": "3209150",
          "name": "SETU KULON"
        },
        {
          "id": "3209150012",
          "district_id": "3209150",
          "name": "TEGALWANGI"
        },
        {
          "id": "3209150015",
          "district_id": "3209150",
          "name": "WERU LOR"
        },
        {
          "id": "3209151001",
          "district_id": "3209151",
          "name": "PANEMBAHAN"
        },
        {
          "id": "3209151002",
          "district_id": "3209151",
          "name": "TRUSMI WETAN"
        },
        {
          "id": "3209151003",
          "district_id": "3209151",
          "name": "TRUSMI KULON"
        },
        {
          "id": "3209151004",
          "district_id": "3209151",
          "name": "SARABAU"
        },
        {
          "id": "3209151005",
          "district_id": "3209151",
          "name": "GAMEL"
        },
        {
          "id": "3209151006",
          "district_id": "3209151",
          "name": "WOTGALI"
        },
        {
          "id": "3209151007",
          "district_id": "3209151",
          "name": "KALIWULU"
        },
        {
          "id": "3209151008",
          "district_id": "3209151",
          "name": "TEGALSARI"
        },
        {
          "id": "3209151009",
          "district_id": "3209151",
          "name": "CANGKRING"
        },
        {
          "id": "3209151010",
          "district_id": "3209151",
          "name": "PANGKALAN"
        },
        {
          "id": "3209161001",
          "district_id": "3209161",
          "name": "PALIR"
        },
        {
          "id": "3209161002",
          "district_id": "3209161",
          "name": "ASTAPADA"
        },
        {
          "id": "3209161003",
          "district_id": "3209161",
          "name": "GESIK"
        },
        {
          "id": "3209161004",
          "district_id": "3209161",
          "name": "KEMLAKAGEDE"
        },
        {
          "id": "3209161005",
          "district_id": "3209161",
          "name": "DAWUAN"
        },
        {
          "id": "3209161006",
          "district_id": "3209161",
          "name": "BATEMBAT"
        },
        {
          "id": "3209161007",
          "district_id": "3209161",
          "name": "KALIBARU"
        },
        {
          "id": "3209161008",
          "district_id": "3209161",
          "name": "KALITENGAH"
        },
        {
          "id": "3209162001",
          "district_id": "3209162",
          "name": "KALIKOA"
        },
        {
          "id": "3209162002",
          "district_id": "3209162",
          "name": "KEDUNGDAWA"
        },
        {
          "id": "3209162004",
          "district_id": "3209162",
          "name": "KEDUNGJAYA"
        },
        {
          "id": "3209162005",
          "district_id": "3209162",
          "name": "KEDAWUNG"
        },
        {
          "id": "3209162006",
          "district_id": "3209162",
          "name": "KERTAWINANGUN"
        },
        {
          "id": "3209162007",
          "district_id": "3209162",
          "name": "SUTAWINANGUN"
        },
        {
          "id": "3209162008",
          "district_id": "3209162",
          "name": "PILANGSARI"
        },
        {
          "id": "3209171001",
          "district_id": "3209171",
          "name": "ADIDHARMA"
        },
        {
          "id": "3209171002",
          "district_id": "3209171",
          "name": "PASINDANGAN"
        },
        {
          "id": "3209171003",
          "district_id": "3209171",
          "name": "JADIMULYA"
        },
        {
          "id": "3209171004",
          "district_id": "3209171",
          "name": "KLAYAN"
        },
        {
          "id": "3209171005",
          "district_id": "3209171",
          "name": "JATIMERTA"
        },
        {
          "id": "3209171006",
          "district_id": "3209171",
          "name": "ASTANA"
        },
        {
          "id": "3209171007",
          "district_id": "3209171",
          "name": "KALISAPU"
        },
        {
          "id": "3209171008",
          "district_id": "3209171",
          "name": "WANAKAYA"
        },
        {
          "id": "3209171009",
          "district_id": "3209171",
          "name": "GROGOL"
        },
        {
          "id": "3209171010",
          "district_id": "3209171",
          "name": "BABADAN"
        },
        {
          "id": "3209171012",
          "district_id": "3209171",
          "name": "MAYUNG"
        },
        {
          "id": "3209171013",
          "district_id": "3209171",
          "name": "SAMBENG"
        },
        {
          "id": "3209171014",
          "district_id": "3209171",
          "name": "SIRNABAYA"
        },
        {
          "id": "3209171015",
          "district_id": "3209171",
          "name": "MERTASINGA"
        },
        {
          "id": "3209180010",
          "district_id": "3209180",
          "name": "BUNGKO"
        },
        {
          "id": "3209180011",
          "district_id": "3209180",
          "name": "KERTASURA"
        },
        {
          "id": "3209180012",
          "district_id": "3209180",
          "name": "PEGAGAN KIDUL"
        },
        {
          "id": "3209180015",
          "district_id": "3209180",
          "name": "PEGAGAN LOR"
        },
        {
          "id": "3209180017",
          "district_id": "3209180",
          "name": "KARANGKENDAL"
        },
        {
          "id": "3209180018",
          "district_id": "3209180",
          "name": "GROGOL"
        },
        {
          "id": "3209180020",
          "district_id": "3209180",
          "name": "KAPETAKAN"
        },
        {
          "id": "3209180021",
          "district_id": "3209180",
          "name": "BUNGKO LOR"
        },
        {
          "id": "3209181001",
          "district_id": "3209181",
          "name": "SURANENGGALA KULON"
        },
        {
          "id": "3209181002",
          "district_id": "3209181",
          "name": "SURAKARTA"
        },
        {
          "id": "3209181003",
          "district_id": "3209181",
          "name": "KERATON"
        },
        {
          "id": "3209181004",
          "district_id": "3209181",
          "name": "PURWAWINANGUN"
        },
        {
          "id": "3209181005",
          "district_id": "3209181",
          "name": "MUARA"
        },
        {
          "id": "3209181006",
          "district_id": "3209181",
          "name": "KARANGREJA"
        },
        {
          "id": "3209181007",
          "district_id": "3209181",
          "name": "SURANENGGALA KIDUL"
        },
        {
          "id": "3209181008",
          "district_id": "3209181",
          "name": "SURANENGGALA LOR"
        },
        {
          "id": "3209181009",
          "district_id": "3209181",
          "name": "SURANENGGALA"
        },
        {
          "id": "3209190001",
          "district_id": "3209190",
          "name": "SERANG"
        },
        {
          "id": "3209190002",
          "district_id": "3209190",
          "name": "KLANGENAN"
        },
        {
          "id": "3209190003",
          "district_id": "3209190",
          "name": "DANAWINANGUN"
        },
        {
          "id": "3209190007",
          "district_id": "3209190",
          "name": "PEKANTINGAN"
        },
        {
          "id": "3209190008",
          "district_id": "3209190",
          "name": "JEMARAS KIDUL"
        },
        {
          "id": "3209190009",
          "district_id": "3209190",
          "name": "JEMARAS LOR"
        },
        {
          "id": "3209190011",
          "district_id": "3209190",
          "name": "SLANGIT"
        },
        {
          "id": "3209190012",
          "district_id": "3209190",
          "name": "KREYO"
        },
        {
          "id": "3209190013",
          "district_id": "3209190",
          "name": "BANGODUA"
        },
        {
          "id": "3209191004",
          "district_id": "3209191",
          "name": "JAMBLANG"
        },
        {
          "id": "3209191005",
          "district_id": "3209191",
          "name": "SITIWINANGUN"
        },
        {
          "id": "3209191006",
          "district_id": "3209191",
          "name": "WANGUNHARJA"
        },
        {
          "id": "3209191014",
          "district_id": "3209191",
          "name": "BOJONG WETAN"
        },
        {
          "id": "3209191015",
          "district_id": "3209191",
          "name": "BOJONG LOR"
        },
        {
          "id": "3209191016",
          "district_id": "3209191",
          "name": "ORIMALANG"
        },
        {
          "id": "3209191017",
          "district_id": "3209191",
          "name": "BAKUNG KIDUL"
        },
        {
          "id": "3209191018",
          "district_id": "3209191",
          "name": "BAKUNG LOR"
        },
        {
          "id": "3209200002",
          "district_id": "3209200",
          "name": "JUNGJANG WETAN"
        },
        {
          "id": "3209200010",
          "district_id": "3209200",
          "name": "JUNGJANG"
        },
        {
          "id": "3209200011",
          "district_id": "3209200",
          "name": "ARJAWINANGUN"
        },
        {
          "id": "3209200012",
          "district_id": "3209200",
          "name": "TEGALGUBUG"
        },
        {
          "id": "3209200013",
          "district_id": "3209200",
          "name": "RAWAGATEL"
        },
        {
          "id": "3209200014",
          "district_id": "3209200",
          "name": "TEGALGUBUG LOR"
        },
        {
          "id": "3209200015",
          "district_id": "3209200",
          "name": "KARANGSAMBUNG"
        },
        {
          "id": "3209200016",
          "district_id": "3209200",
          "name": "BULAK"
        },
        {
          "id": "3209200017",
          "district_id": "3209200",
          "name": "GEYONGAN"
        },
        {
          "id": "3209200018",
          "district_id": "3209200",
          "name": "KEBONTURI"
        },
        {
          "id": "3209201001",
          "district_id": "3209201",
          "name": "KALIANYAR"
        },
        {
          "id": "3209201002",
          "district_id": "3209201",
          "name": "PANGURAGAN KULON"
        },
        {
          "id": "3209201003",
          "district_id": "3209201",
          "name": "PANGURAGAN WETAN"
        },
        {
          "id": "3209201004",
          "district_id": "3209201",
          "name": "PANGURAGAN LOR"
        },
        {
          "id": "3209201005",
          "district_id": "3209201",
          "name": "PANGURAGAN"
        },
        {
          "id": "3209201007",
          "district_id": "3209201",
          "name": "LEMAHTAMBA"
        },
        {
          "id": "3209201008",
          "district_id": "3209201",
          "name": "KARANGANYAR"
        },
        {
          "id": "3209201009",
          "district_id": "3209201",
          "name": "KROYA"
        },
        {
          "id": "3209210004",
          "district_id": "3209210",
          "name": "CIWARINGIN"
        },
        {
          "id": "3209210005",
          "district_id": "3209210",
          "name": "BABAKAN"
        },
        {
          "id": "3209210007",
          "district_id": "3209210",
          "name": "GINTUNGRANJENG"
        },
        {
          "id": "3209210008",
          "district_id": "3209210",
          "name": "GINTUNG KIDUL"
        },
        {
          "id": "3209210009",
          "district_id": "3209210",
          "name": "GINTUNG TENGAH"
        },
        {
          "id": "3209210010",
          "district_id": "3209210",
          "name": "BRINGIN"
        },
        {
          "id": "3209210011",
          "district_id": "3209210",
          "name": "GALAGAMBA"
        },
        {
          "id": "3209211001",
          "district_id": "3209211",
          "name": "CUPANG"
        },
        {
          "id": "3209211002",
          "district_id": "3209211",
          "name": "CIKEUSAL"
        },
        {
          "id": "3209211003",
          "district_id": "3209211",
          "name": "WALAHAR"
        },
        {
          "id": "3209211004",
          "district_id": "3209211",
          "name": "PALIMANAN BARAT"
        },
        {
          "id": "3209211005",
          "district_id": "3209211",
          "name": "GEMPOL"
        },
        {
          "id": "3209211008",
          "district_id": "3209211",
          "name": "WINONG"
        },
        {
          "id": "3209220001",
          "district_id": "3209220",
          "name": "TANGKIL"
        },
        {
          "id": "3209220002",
          "district_id": "3209220",
          "name": "WIYONG"
        },
        {
          "id": "3209220003",
          "district_id": "3209220",
          "name": "KEDONGDONG"
        },
        {
          "id": "3209220004",
          "district_id": "3209220",
          "name": "GINTUNG LOR"
        },
        {
          "id": "3209220005",
          "district_id": "3209220",
          "name": "BOJONG KULON"
        },
        {
          "id": "3209220006",
          "district_id": "3209220",
          "name": "KEJIWAN"
        },
        {
          "id": "3209220007",
          "district_id": "3209220",
          "name": "SUSUKAN"
        },
        {
          "id": "3209220008",
          "district_id": "3209220",
          "name": "LUWUNG KENCANA"
        },
        {
          "id": "3209220010",
          "district_id": "3209220",
          "name": "JATIPURA"
        },
        {
          "id": "3209220011",
          "district_id": "3209220",
          "name": "UJUNGGEBANG"
        },
        {
          "id": "3209220012",
          "district_id": "3209220",
          "name": "JATIANOM"
        },
        {
          "id": "3209230007",
          "district_id": "3209230",
          "name": "BAYALANGU KIDUL"
        },
        {
          "id": "3209230008",
          "district_id": "3209230",
          "name": "BAYALANGU LOR"
        },
        {
          "id": "3209230009",
          "district_id": "3209230",
          "name": "KEDUNGDALEM"
        },
        {
          "id": "3209230010",
          "district_id": "3209230",
          "name": "PANUNGGUL"
        },
        {
          "id": "3209230011",
          "district_id": "3209230",
          "name": "GEGESIK WETAN"
        },
        {
          "id": "3209230012",
          "district_id": "3209230",
          "name": "GEGESIK KIDUL"
        },
        {
          "id": "3209230016",
          "district_id": "3209230",
          "name": "SLENDRA"
        },
        {
          "id": "3209230017",
          "district_id": "3209230",
          "name": "JAGAPURA KIDUL"
        },
        {
          "id": "3209230018",
          "district_id": "3209230",
          "name": "GEGESIK LOR"
        },
        {
          "id": "3209230019",
          "district_id": "3209230",
          "name": "GEGESIK KULON"
        },
        {
          "id": "3209230020",
          "district_id": "3209230",
          "name": "JAGAPURA WETAN"
        },
        {
          "id": "3209230021",
          "district_id": "3209230",
          "name": "JAGAPURA KULON"
        },
        {
          "id": "3209230022",
          "district_id": "3209230",
          "name": "JAGAPURA LOR"
        },
        {
          "id": "3209230023",
          "district_id": "3209230",
          "name": "SIBUBUT"
        },
        {
          "id": "3209231001",
          "district_id": "3209231",
          "name": "KALIDERES"
        },
        {
          "id": "3209231002",
          "district_id": "3209231",
          "name": "PRAJAWINANGUN WETAN"
        },
        {
          "id": "3209231003",
          "district_id": "3209231",
          "name": "PRAJAWINANGUN KULON"
        },
        {
          "id": "3209231004",
          "district_id": "3209231",
          "name": "KALIWEDI KIDUL"
        },
        {
          "id": "3209231005",
          "district_id": "3209231",
          "name": "KALIWEDI LOR"
        },
        {
          "id": "3209231006",
          "district_id": "3209231",
          "name": "UJUNGSEMI"
        },
        {
          "id": "3209231007",
          "district_id": "3209231",
          "name": "WARGABINANGUN"
        },
        {
          "id": "3209231008",
          "district_id": "3209231",
          "name": "GUWA KIDUL"
        },
        {
          "id": "3209231009",
          "district_id": "3209231",
          "name": "GUWA LOR"
        },
        {
          "id": "3210010001",
          "district_id": "3210010",
          "name": "CIPASUNG"
        },
        {
          "id": "3210010002",
          "district_id": "3210010",
          "name": "BOROGOJOL"
        },
        {
          "id": "3210010003",
          "district_id": "3210010",
          "name": "BANGBAYANG"
        },
        {
          "id": "3210010004",
          "district_id": "3210010",
          "name": "CIBULAN"
        },
        {
          "id": "3210010005",
          "district_id": "3210010",
          "name": "LEMAH PUTIH"
        },
        {
          "id": "3210010006",
          "district_id": "3210010",
          "name": "SADAWANGI"
        },
        {
          "id": "3210010007",
          "district_id": "3210010",
          "name": "MARGAJAYA"
        },
        {
          "id": "3210010008",
          "district_id": "3210010",
          "name": "KALAPADUA"
        },
        {
          "id": "3210010009",
          "district_id": "3210010",
          "name": "CIGALEUH"
        },
        {
          "id": "3210010010",
          "district_id": "3210010",
          "name": "MEKARMULYA"
        },
        {
          "id": "3210010011",
          "district_id": "3210010",
          "name": "SINARGALIH"
        },
        {
          "id": "3210010012",
          "district_id": "3210010",
          "name": "PADAREK"
        },
        {
          "id": "3210010013",
          "district_id": "3210010",
          "name": "SUKAJADI"
        },
        {
          "id": "3210010015",
          "district_id": "3210010",
          "name": "MEKAR WANGI"
        },
        {
          "id": "3210010016",
          "district_id": "3210010",
          "name": "SUKAMAJU"
        },
        {
          "id": "3210010017",
          "district_id": "3210010",
          "name": "LEMAHSUGIH"
        },
        {
          "id": "3210010018",
          "district_id": "3210010",
          "name": "DAYEUHWANGI"
        },
        {
          "id": "3210010019",
          "district_id": "3210010",
          "name": "CISALAK"
        },
        {
          "id": "3210020009",
          "district_id": "3210020",
          "name": "SINDANGHURIP"
        },
        {
          "id": "3210020010",
          "district_id": "3210020",
          "name": "CIPEUNDEUY"
        },
        {
          "id": "3210020012",
          "district_id": "3210020",
          "name": "CIMANGGUHILIR"
        },
        {
          "id": "3210020013",
          "district_id": "3210020",
          "name": "SALAWANGI"
        },
        {
          "id": "3210020014",
          "district_id": "3210020",
          "name": "SILIHWANGI"
        },
        {
          "id": "3210020015",
          "district_id": "3210020",
          "name": "WADOWETAN"
        },
        {
          "id": "3210020016",
          "district_id": "3210020",
          "name": "BANTARUJEG"
        },
        {
          "id": "3210020017",
          "district_id": "3210020",
          "name": "BABAKANSARI"
        },
        {
          "id": "3210020018",
          "district_id": "3210020",
          "name": "GUNUNGLARANG"
        },
        {
          "id": "3210020019",
          "district_id": "3210020",
          "name": "CIKIDANG"
        },
        {
          "id": "3210020020",
          "district_id": "3210020",
          "name": "CINAMBO"
        },
        {
          "id": "3210020021",
          "district_id": "3210020",
          "name": "HAURGEULIS"
        },
        {
          "id": "3210020022",
          "district_id": "3210020",
          "name": "SUKAMENAK"
        },
        {
          "id": "3210021001",
          "district_id": "3210021",
          "name": "BUNINAGARA"
        },
        {
          "id": "3210021002",
          "district_id": "3210021",
          "name": "WERASARI"
        },
        {
          "id": "3210021003",
          "district_id": "3210021",
          "name": "MALAUSMA"
        },
        {
          "id": "3210021004",
          "district_id": "3210021",
          "name": "BANYUSARI"
        },
        {
          "id": "3210021005",
          "district_id": "3210021",
          "name": "JAGAMULYA"
        },
        {
          "id": "3210021006",
          "district_id": "3210021",
          "name": "CIMUNCANG"
        },
        {
          "id": "3210021007",
          "district_id": "3210021",
          "name": "CIRANCA"
        },
        {
          "id": "3210021008",
          "district_id": "3210021",
          "name": "LEBAKWANGI"
        },
        {
          "id": "3210021009",
          "district_id": "3210021",
          "name": "SUKADANA"
        },
        {
          "id": "3210021010",
          "district_id": "3210021",
          "name": "GIRIMUKTI"
        },
        {
          "id": "3210021011",
          "district_id": "3210021",
          "name": "KRAMATJAYA"
        },
        {
          "id": "3210030014",
          "district_id": "3210030",
          "name": "CISOKA"
        },
        {
          "id": "3210030015",
          "district_id": "3210030",
          "name": "SINDANGPANJI"
        },
        {
          "id": "3210030016",
          "district_id": "3210030",
          "name": "CIKIJING"
        },
        {
          "id": "3210030017",
          "district_id": "3210030",
          "name": "CIDULANG"
        },
        {
          "id": "3210030018",
          "district_id": "3210030",
          "name": "SUKAMUKTI"
        },
        {
          "id": "3210030019",
          "district_id": "3210030",
          "name": "KASTURI"
        },
        {
          "id": "3210030020",
          "district_id": "3210030",
          "name": "BANJARANSARI"
        },
        {
          "id": "3210030021",
          "district_id": "3210030",
          "name": "SINDANG"
        },
        {
          "id": "3210030022",
          "district_id": "3210030",
          "name": "SUKASARI"
        },
        {
          "id": "3210030023",
          "district_id": "3210030",
          "name": "SUNALARI"
        },
        {
          "id": "3210030024",
          "district_id": "3210030",
          "name": "BAGJASARI"
        },
        {
          "id": "3210030025",
          "district_id": "3210030",
          "name": "JAGASARI"
        },
        {
          "id": "3210030026",
          "district_id": "3210030",
          "name": "CILANGCANG"
        },
        {
          "id": "3210030027",
          "district_id": "3210030",
          "name": "KANCANA"
        },
        {
          "id": "3210030028",
          "district_id": "3210030",
          "name": "CIPULUS"
        },
        {
          "id": "3210031001",
          "district_id": "3210031",
          "name": "SEDARAJA"
        },
        {
          "id": "3210031002",
          "district_id": "3210031",
          "name": "CINTAASIH"
        },
        {
          "id": "3210031003",
          "district_id": "3210031",
          "name": "CIDADAP"
        },
        {
          "id": "3210031004",
          "district_id": "3210031",
          "name": "MANIIS"
        },
        {
          "id": "3210031005",
          "district_id": "3210031",
          "name": "RAWA"
        },
        {
          "id": "3210031006",
          "district_id": "3210031",
          "name": "NAGARAKEMBANG"
        },
        {
          "id": "3210031007",
          "district_id": "3210031",
          "name": "WANGKELANG"
        },
        {
          "id": "3210031008",
          "district_id": "3210031",
          "name": "CIMANGGUGIRANG"
        },
        {
          "id": "3210031009",
          "district_id": "3210031",
          "name": "CIRANJENG"
        },
        {
          "id": "3210031010",
          "district_id": "3210031",
          "name": "CINGAMBUL"
        },
        {
          "id": "3210031011",
          "district_id": "3210031",
          "name": "MUKTISARI"
        },
        {
          "id": "3210031012",
          "district_id": "3210031",
          "name": "CIKONDANG"
        },
        {
          "id": "3210031013",
          "district_id": "3210031",
          "name": "KONDANGMEKAR"
        },
        {
          "id": "3210040001",
          "district_id": "3210040",
          "name": "MARGAMUKTI"
        },
        {
          "id": "3210040002",
          "district_id": "3210040",
          "name": "CIBEUREUM"
        },
        {
          "id": "3210040003",
          "district_id": "3210040",
          "name": "CIKEUSAL"
        },
        {
          "id": "3210040004",
          "district_id": "3210040",
          "name": "JATIPAMOR"
        },
        {
          "id": "3210040005",
          "district_id": "3210040",
          "name": "CICANIR"
        },
        {
          "id": "3210040006",
          "district_id": "3210040",
          "name": "CAMPAGA"
        },
        {
          "id": "3210040007",
          "district_id": "3210040",
          "name": "LAMPUYANG"
        },
        {
          "id": "3210040008",
          "district_id": "3210040",
          "name": "MEKARRAHARJA"
        },
        {
          "id": "3210040009",
          "district_id": "3210040",
          "name": "TALAGAKULON"
        },
        {
          "id": "3210040010",
          "district_id": "3210040",
          "name": "TALAGAWETAN"
        },
        {
          "id": "3210040011",
          "district_id": "3210040",
          "name": "SALADO"
        },
        {
          "id": "3210040012",
          "district_id": "3210040",
          "name": "ARGASARI"
        },
        {
          "id": "3210040013",
          "district_id": "3210040",
          "name": "GUNUNGMANIK"
        },
        {
          "id": "3210040014",
          "district_id": "3210040",
          "name": "GANEAS"
        },
        {
          "id": "3210040015",
          "district_id": "3210040",
          "name": "SUKAPERNA"
        },
        {
          "id": "3210040016",
          "district_id": "3210040",
          "name": "KERTARAHAYU"
        },
        {
          "id": "3210040017",
          "district_id": "3210040",
          "name": "MEKARHURIP"
        },
        {
          "id": "3210041002",
          "district_id": "3210041",
          "name": "HEGARMANAH"
        },
        {
          "id": "3210041003",
          "district_id": "3210041",
          "name": "SINDANGPALA"
        },
        {
          "id": "3210041004",
          "district_id": "3210041",
          "name": "DARMALARANG"
        },
        {
          "id": "3210041005",
          "district_id": "3210041",
          "name": "SUNIABARU"
        },
        {
          "id": "3210041006",
          "district_id": "3210041",
          "name": "SANGIANG"
        },
        {
          "id": "3210041007",
          "district_id": "3210041",
          "name": "SUNIA"
        },
        {
          "id": "3210041008",
          "district_id": "3210041",
          "name": "KAREO"
        },
        {
          "id": "3210041009",
          "district_id": "3210041",
          "name": "BANJARAN"
        },
        {
          "id": "3210041010",
          "district_id": "3210041",
          "name": "KAGOK"
        },
        {
          "id": "3210041011",
          "district_id": "3210041",
          "name": "CIMEONG"
        },
        {
          "id": "3210041012",
          "district_id": "3210041",
          "name": "PANYINDANGAN"
        },
        {
          "id": "3210041013",
          "district_id": "3210041",
          "name": "GIRIMULYA"
        },
        {
          "id": "3210050001",
          "district_id": "3210050",
          "name": "SAGARA"
        },
        {
          "id": "3210050002",
          "district_id": "3210050",
          "name": "CIBUNUT"
        },
        {
          "id": "3210050003",
          "district_id": "3210050",
          "name": "TEJAMULYA"
        },
        {
          "id": "3210050004",
          "district_id": "3210050",
          "name": "SUKASARI KIDUL"
        },
        {
          "id": "3210050005",
          "district_id": "3210050",
          "name": "SUKASARI KALER"
        },
        {
          "id": "3210050006",
          "district_id": "3210050",
          "name": "SADASARI"
        },
        {
          "id": "3210050007",
          "district_id": "3210050",
          "name": "SUKADANA"
        },
        {
          "id": "3210050008",
          "district_id": "3210050",
          "name": "ARGAMUKTI"
        },
        {
          "id": "3210050009",
          "district_id": "3210050",
          "name": "ARGALINGGA"
        },
        {
          "id": "3210050010",
          "district_id": "3210050",
          "name": "HAURSEAH"
        },
        {
          "id": "3210050011",
          "district_id": "3210050",
          "name": "GUNUNGWANGI"
        },
        {
          "id": "3210050012",
          "district_id": "3210050",
          "name": "MEKARWANGI"
        },
        {
          "id": "3210050013",
          "district_id": "3210050",
          "name": "HEUBEULISUK"
        },
        {
          "id": "3210050014",
          "district_id": "3210050",
          "name": "CIKARACAK"
        },
        {
          "id": "3210060001",
          "district_id": "3210060",
          "name": "CIHAUR"
        },
        {
          "id": "3210060002",
          "district_id": "3210060",
          "name": "WANAHAYU"
        },
        {
          "id": "3210060003",
          "district_id": "3210060",
          "name": "CENGAL"
        },
        {
          "id": "3210060004",
          "district_id": "3210060",
          "name": "ANGGRAWATI"
        },
        {
          "id": "3210060005",
          "district_id": "3210060",
          "name": "CIPICUNG"
        },
        {
          "id": "3210060006",
          "district_id": "3210060",
          "name": "MALONGPONG"
        },
        {
          "id": "3210060007",
          "district_id": "3210060",
          "name": "TEGALSARI"
        },
        {
          "id": "3210060008",
          "district_id": "3210060",
          "name": "MAJA SELATAN"
        },
        {
          "id": "3210060009",
          "district_id": "3210060",
          "name": "MAJA UTARA"
        },
        {
          "id": "3210060010",
          "district_id": "3210060",
          "name": "PASANGGRAHAN"
        },
        {
          "id": "3210060011",
          "district_id": "3210060",
          "name": "CIEURIH"
        },
        {
          "id": "3210060012",
          "district_id": "3210060",
          "name": "KERTABASUKI"
        },
        {
          "id": "3210060013",
          "district_id": "3210060",
          "name": "SINDANGKERTA"
        },
        {
          "id": "3210060014",
          "district_id": "3210060",
          "name": "BANJARAN"
        },
        {
          "id": "3210060015",
          "district_id": "3210060",
          "name": "PANIIS"
        },
        {
          "id": "3210060016",
          "district_id": "3210060",
          "name": "CICALUNG"
        },
        {
          "id": "3210060017",
          "district_id": "3210060",
          "name": "PAGERAJI"
        },
        {
          "id": "3210060018",
          "district_id": "3210060",
          "name": "NUNUK BARU"
        },
        {
          "id": "3210070001",
          "district_id": "3210070",
          "name": "BABAKAN JAWA"
        },
        {
          "id": "3210070002",
          "district_id": "3210070",
          "name": "CIBODAS"
        },
        {
          "id": "3210070004",
          "district_id": "3210070",
          "name": "KAWUNGGIRANG"
        },
        {
          "id": "3210070005",
          "district_id": "3210070",
          "name": "SINDANGKASIH"
        },
        {
          "id": "3210070006",
          "district_id": "3210070",
          "name": "CICURUG"
        },
        {
          "id": "3210070007",
          "district_id": "3210070",
          "name": "MAJALENGKA WETAN"
        },
        {
          "id": "3210070008",
          "district_id": "3210070",
          "name": "TONJONG"
        },
        {
          "id": "3210070009",
          "district_id": "3210070",
          "name": "TARIKOLOT"
        },
        {
          "id": "3210070010",
          "district_id": "3210070",
          "name": "CIKASARUNG"
        },
        {
          "id": "3210070011",
          "district_id": "3210070",
          "name": "CIJATI"
        },
        {
          "id": "3210070012",
          "district_id": "3210070",
          "name": "MAJALENGKA KULON"
        },
        {
          "id": "3210070014",
          "district_id": "3210070",
          "name": "SIDAMUKTI"
        },
        {
          "id": "3210080001",
          "district_id": "3210080",
          "name": "KAWUNGHILIR"
        },
        {
          "id": "3210080002",
          "district_id": "3210080",
          "name": "TAJUR"
        },
        {
          "id": "3210080003",
          "district_id": "3210080",
          "name": "CIGASONG"
        },
        {
          "id": "3210080004",
          "district_id": "3210080",
          "name": "SIMPEUREUM"
        },
        {
          "id": "3210080005",
          "district_id": "3210080",
          "name": "TENJOLAYAR"
        },
        {
          "id": "3210080006",
          "district_id": "3210080",
          "name": "KUTAMANGGU"
        },
        {
          "id": "3210080007",
          "district_id": "3210080",
          "name": "CICENANG"
        },
        {
          "id": "3210080008",
          "district_id": "3210080",
          "name": "BARIBIS"
        },
        {
          "id": "3210080009",
          "district_id": "3210080",
          "name": "BATUJAYA"
        },
        {
          "id": "3210080010",
          "district_id": "3210080",
          "name": "KARAYUNAN"
        },
        {
          "id": "3210090001",
          "district_id": "3210090",
          "name": "CANDRAJAYA"
        },
        {
          "id": "3210090002",
          "district_id": "3210090",
          "name": "CIOMAS"
        },
        {
          "id": "3210090010",
          "district_id": "3210090",
          "name": "PADAHANTEN"
        },
        {
          "id": "3210090011",
          "district_id": "3210090",
          "name": "SUKAHAJI"
        },
        {
          "id": "3210090012",
          "district_id": "3210090",
          "name": "CIKALONG"
        },
        {
          "id": "3210090013",
          "district_id": "3210090",
          "name": "BABAKAN MANJETI"
        },
        {
          "id": "3210090014",
          "district_id": "3210090",
          "name": "CIKONENG"
        },
        {
          "id": "3210090015",
          "district_id": "3210090",
          "name": "PALABUAN"
        },
        {
          "id": "3210090016",
          "district_id": "3210090",
          "name": "CIKEUSIK"
        },
        {
          "id": "3210090017",
          "district_id": "3210090",
          "name": "SALAGEDANG"
        },
        {
          "id": "3210090018",
          "district_id": "3210090",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3210090019",
          "district_id": "3210090",
          "name": "JAYI"
        },
        {
          "id": "3210090020",
          "district_id": "3210090",
          "name": "NANGGEWER"
        },
        {
          "id": "3210091001",
          "district_id": "3210091",
          "name": "PASIRAYU"
        },
        {
          "id": "3210091002",
          "district_id": "3210091",
          "name": "GARAWASTU"
        },
        {
          "id": "3210091003",
          "district_id": "3210091",
          "name": "SANGKANHURIP"
        },
        {
          "id": "3210091004",
          "district_id": "3210091",
          "name": "INDRAKILA"
        },
        {
          "id": "3210091005",
          "district_id": "3210091",
          "name": "SINDANG"
        },
        {
          "id": "3210091006",
          "district_id": "3210091",
          "name": "GUNUNG KUNING"
        },
        {
          "id": "3210091007",
          "district_id": "3210091",
          "name": "BAYUREJA"
        },
        {
          "id": "3210100001",
          "district_id": "3210100",
          "name": "PAJAJAR"
        },
        {
          "id": "3210100002",
          "district_id": "3210100",
          "name": "TEJA"
        },
        {
          "id": "3210100003",
          "district_id": "3210100",
          "name": "PAYUNG"
        },
        {
          "id": "3210100004",
          "district_id": "3210100",
          "name": "SINDANGPANO"
        },
        {
          "id": "3210100005",
          "district_id": "3210100",
          "name": "BABAKANKAREO"
        },
        {
          "id": "3210100006",
          "district_id": "3210100",
          "name": "SADOMAS"
        },
        {
          "id": "3210100008",
          "district_id": "3210100",
          "name": "RAJAGALUH KIDUL"
        },
        {
          "id": "3210100009",
          "district_id": "3210100",
          "name": "SINGAWADA"
        },
        {
          "id": "3210100010",
          "district_id": "3210100",
          "name": "RAJAGALUH"
        },
        {
          "id": "3210100011",
          "district_id": "3210100",
          "name": "RAJAGALUH LOR"
        },
        {
          "id": "3210100012",
          "district_id": "3210100",
          "name": "CIPINANG"
        },
        {
          "id": "3210100013",
          "district_id": "3210100",
          "name": "CISETU"
        },
        {
          "id": "3210110001",
          "district_id": "3210110",
          "name": "BANTAR AGUNG"
        },
        {
          "id": "3210110002",
          "district_id": "3210110",
          "name": "PADAHERANG"
        },
        {
          "id": "3210110003",
          "district_id": "3210110",
          "name": "LENGKONGWETAN"
        },
        {
          "id": "3210110004",
          "district_id": "3210110",
          "name": "LENGKONGKULON"
        },
        {
          "id": "3210110006",
          "district_id": "3210110",
          "name": "SINDANGWANGI"
        },
        {
          "id": "3210110007",
          "district_id": "3210110",
          "name": "BUAHKAPAS"
        },
        {
          "id": "3210110009",
          "district_id": "3210110",
          "name": "LEUWILAJA"
        },
        {
          "id": "3210110010",
          "district_id": "3210110",
          "name": "BALAGEDOG"
        },
        {
          "id": "3210120001",
          "district_id": "3210120",
          "name": "PARAKAN"
        },
        {
          "id": "3210120002",
          "district_id": "3210120",
          "name": "LAME"
        },
        {
          "id": "3210120003",
          "district_id": "3210120",
          "name": "MINDI"
        },
        {
          "id": "3210120004",
          "district_id": "3210120",
          "name": "RAJAWANGI"
        },
        {
          "id": "3210120005",
          "district_id": "3210120",
          "name": "LEUWIKUJANG"
        },
        {
          "id": "3210120006",
          "district_id": "3210120",
          "name": "NANGGERANG"
        },
        {
          "id": "3210120007",
          "district_id": "3210120",
          "name": "PATUANAN"
        },
        {
          "id": "3210120008",
          "district_id": "3210120",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3210120009",
          "district_id": "3210120",
          "name": "KARANGASEM"
        },
        {
          "id": "3210120011",
          "district_id": "3210120",
          "name": "CIPARAY"
        },
        {
          "id": "3210120012",
          "district_id": "3210120",
          "name": "LEUWIMUNDING"
        },
        {
          "id": "3210120013",
          "district_id": "3210120",
          "name": "MIRAT"
        },
        {
          "id": "3210120014",
          "district_id": "3210120",
          "name": "PARUNGJAYA"
        },
        {
          "id": "3210130001",
          "district_id": "3210130",
          "name": "WERAGATI"
        },
        {
          "id": "3210130002",
          "district_id": "3210130",
          "name": "TRAJAYA"
        },
        {
          "id": "3210130003",
          "district_id": "3210130",
          "name": "TARIKOLOT"
        },
        {
          "id": "3210130004",
          "district_id": "3210130",
          "name": "SINDANGHAJI"
        },
        {
          "id": "3210130005",
          "district_id": "3210130",
          "name": "ENGGALWANGI"
        },
        {
          "id": "3210130006",
          "district_id": "3210130",
          "name": "BUNIWANGI"
        },
        {
          "id": "3210130007",
          "district_id": "3210130",
          "name": "PALASAH"
        },
        {
          "id": "3210130008",
          "district_id": "3210130",
          "name": "PASIR"
        },
        {
          "id": "3210130009",
          "district_id": "3210130",
          "name": "WARINGIN"
        },
        {
          "id": "3210130010",
          "district_id": "3210130",
          "name": "KARAMAT"
        },
        {
          "id": "3210130011",
          "district_id": "3210130",
          "name": "SINDANGWASA"
        },
        {
          "id": "3210130012",
          "district_id": "3210130",
          "name": "CISAMBENG"
        },
        {
          "id": "3210130013",
          "district_id": "3210130",
          "name": "MAJASUKA"
        },
        {
          "id": "3210140001",
          "district_id": "3210140",
          "name": "PINANGRAJA"
        },
        {
          "id": "3210140002",
          "district_id": "3210140",
          "name": "SUKARAJA KULON"
        },
        {
          "id": "3210140003",
          "district_id": "3210140",
          "name": "SUKARAJA WETAN"
        },
        {
          "id": "3210140004",
          "district_id": "3210140",
          "name": "CIBENTAR"
        },
        {
          "id": "3210140006",
          "district_id": "3210140",
          "name": "ANDIR"
        },
        {
          "id": "3210140007",
          "district_id": "3210140",
          "name": "CICADAS"
        },
        {
          "id": "3210140008",
          "district_id": "3210140",
          "name": "BURUJUL WETAN"
        },
        {
          "id": "3210140009",
          "district_id": "3210140",
          "name": "BURUJUL KULON"
        },
        {
          "id": "3210140010",
          "district_id": "3210140",
          "name": "MEKARSARI"
        },
        {
          "id": "3210140011",
          "district_id": "3210140",
          "name": "JATIWANGI"
        },
        {
          "id": "3210140012",
          "district_id": "3210140",
          "name": "SURAWANGI"
        },
        {
          "id": "3210140013",
          "district_id": "3210140",
          "name": "JATISURA"
        },
        {
          "id": "3210140014",
          "district_id": "3210140",
          "name": "SUTAWANGI"
        },
        {
          "id": "3210140015",
          "district_id": "3210140",
          "name": "CIBORELANG"
        },
        {
          "id": "3210140016",
          "district_id": "3210140",
          "name": "LOJI"
        },
        {
          "id": "3210150011",
          "district_id": "3210150",
          "name": "BOJONGCIDERES"
        },
        {
          "id": "3210150012",
          "district_id": "3210150",
          "name": "DAWUAN"
        },
        {
          "id": "3210150014",
          "district_id": "3210150",
          "name": "GANDU"
        },
        {
          "id": "3210150015",
          "district_id": "3210150",
          "name": "SINARJATI"
        },
        {
          "id": "3210150016",
          "district_id": "3210150",
          "name": "BATURUYUK"
        },
        {
          "id": "3210150017",
          "district_id": "3210150",
          "name": "MANDAPA"
        },
        {
          "id": "3210150018",
          "district_id": "3210150",
          "name": "BALIDA"
        },
        {
          "id": "3210150019",
          "district_id": "3210150",
          "name": "PASIRMALATI"
        },
        {
          "id": "3210150020",
          "district_id": "3210150",
          "name": "KARANGANYAR"
        },
        {
          "id": "3210150021",
          "district_id": "3210150",
          "name": "SALAWANA"
        },
        {
          "id": "3210151001",
          "district_id": "3210151",
          "name": "JATISAWIT"
        },
        {
          "id": "3210151002",
          "district_id": "3210151",
          "name": "LEUWIKIDANG"
        },
        {
          "id": "3210151003",
          "district_id": "3210151",
          "name": "GIRIMUKTI"
        },
        {
          "id": "3210151004",
          "district_id": "3210151",
          "name": "JATIMULYA"
        },
        {
          "id": "3210151005",
          "district_id": "3210151",
          "name": "WANAJAYA"
        },
        {
          "id": "3210151006",
          "district_id": "3210151",
          "name": "RANJIWETAN"
        },
        {
          "id": "3210151007",
          "district_id": "3210151",
          "name": "RANJIKULON"
        },
        {
          "id": "3210151008",
          "district_id": "3210151",
          "name": "GUNUNGSARI"
        },
        {
          "id": "3210151009",
          "district_id": "3210151",
          "name": "KASOKANDEL"
        },
        {
          "id": "3210151010",
          "district_id": "3210151",
          "name": "GANDASARI"
        },
        {
          "id": "3210160001",
          "district_id": "3210160",
          "name": "CIJUREY"
        },
        {
          "id": "3210160002",
          "district_id": "3210160",
          "name": "PASIRMUNCANG"
        },
        {
          "id": "3210160003",
          "district_id": "3210160",
          "name": "JATIPAMOR"
        },
        {
          "id": "3210160004",
          "district_id": "3210160",
          "name": "BANTRANGSANA"
        },
        {
          "id": "3210160005",
          "district_id": "3210160",
          "name": "JATISERANG"
        },
        {
          "id": "3210160006",
          "district_id": "3210160",
          "name": "BONANG"
        },
        {
          "id": "3210160007",
          "district_id": "3210160",
          "name": "LEUWISEENG"
        },
        {
          "id": "3210160008",
          "district_id": "3210160",
          "name": "KARYAMUKTI"
        },
        {
          "id": "3210160009",
          "district_id": "3210160",
          "name": "PANYINGKIRAN"
        },
        {
          "id": "3210170001",
          "district_id": "3210170",
          "name": "LIANGJULANG"
        },
        {
          "id": "3210170003",
          "district_id": "3210170",
          "name": "CIPAKU"
        },
        {
          "id": "3210170004",
          "district_id": "3210170",
          "name": "KADIPATEN"
        },
        {
          "id": "3210170005",
          "district_id": "3210170",
          "name": "BABAKAN ANYAR"
        },
        {
          "id": "3210170006",
          "district_id": "3210170",
          "name": "KARANGSAMBUNG"
        },
        {
          "id": "3210170007",
          "district_id": "3210170",
          "name": "PAGANDON"
        },
        {
          "id": "3210180001",
          "district_id": "3210180",
          "name": "MEKARJAYA"
        },
        {
          "id": "3210180002",
          "district_id": "3210180",
          "name": "PALASAH"
        },
        {
          "id": "3210180003",
          "district_id": "3210180",
          "name": "PAKUBEUREUM"
        },
        {
          "id": "3210180004",
          "district_id": "3210180",
          "name": "SUKAWANA"
        },
        {
          "id": "3210180005",
          "district_id": "3210180",
          "name": "KERTAWINANGUN"
        },
        {
          "id": "3210180006",
          "district_id": "3210180",
          "name": "BABAKAN"
        },
        {
          "id": "3210180007",
          "district_id": "3210180",
          "name": "KERTAJATI"
        },
        {
          "id": "3210180008",
          "district_id": "3210180",
          "name": "KERTASARI"
        },
        {
          "id": "3210180009",
          "district_id": "3210180",
          "name": "MEKARMULYA"
        },
        {
          "id": "3210180010",
          "district_id": "3210180",
          "name": "SUKAMULYA"
        },
        {
          "id": "3210180011",
          "district_id": "3210180",
          "name": "BANTARJATI"
        },
        {
          "id": "3210180012",
          "district_id": "3210180",
          "name": "PASIRIPIS"
        },
        {
          "id": "3210180013",
          "district_id": "3210180",
          "name": "SUKAKERTA"
        },
        {
          "id": "3210180014",
          "district_id": "3210180",
          "name": "SAHBANDAR"
        },
        {
          "id": "3210190001",
          "district_id": "3210190",
          "name": "BIYAWAK"
        },
        {
          "id": "3210190002",
          "district_id": "3210190",
          "name": "PASINDANGAN"
        },
        {
          "id": "3210190003",
          "district_id": "3210190",
          "name": "PANONGAN"
        },
        {
          "id": "3210190004",
          "district_id": "3210190",
          "name": "PANYINGKIRAN"
        },
        {
          "id": "3210190005",
          "district_id": "3210190",
          "name": "RANDEGAN KULON"
        },
        {
          "id": "3210190006",
          "district_id": "3210190",
          "name": "RANDEGAN WETAN"
        },
        {
          "id": "3210190007",
          "district_id": "3210190",
          "name": "PUTRIDALEM"
        },
        {
          "id": "3210190008",
          "district_id": "3210190",
          "name": "JATITENGAH"
        },
        {
          "id": "3210190009",
          "district_id": "3210190",
          "name": "JATITUJUH"
        },
        {
          "id": "3210190010",
          "district_id": "3210190",
          "name": "BABAJURANG"
        },
        {
          "id": "3210190011",
          "district_id": "3210190",
          "name": "PILANGSARI"
        },
        {
          "id": "3210190012",
          "district_id": "3210190",
          "name": "JATIRAGA"
        },
        {
          "id": "3210190013",
          "district_id": "3210190",
          "name": "SUMBER KULON"
        },
        {
          "id": "3210190014",
          "district_id": "3210190",
          "name": "SUMBER WETAN"
        },
        {
          "id": "3210190015",
          "district_id": "3210190",
          "name": "PANGKALANPARI"
        },
        {
          "id": "3210200001",
          "district_id": "3210200",
          "name": "CIBOGOR"
        },
        {
          "id": "3210200002",
          "district_id": "3210200",
          "name": "KERTASARI"
        },
        {
          "id": "3210200003",
          "district_id": "3210200",
          "name": "GANDAWESI"
        },
        {
          "id": "3210200004",
          "district_id": "3210200",
          "name": "BEUSI"
        },
        {
          "id": "3210200005",
          "district_id": "3210200",
          "name": "TEGALAREN"
        },
        {
          "id": "3210200008",
          "district_id": "3210200",
          "name": "WANASALAM"
        },
        {
          "id": "3210200009",
          "district_id": "3210200",
          "name": "LIGUNG LOR"
        },
        {
          "id": "3210200010",
          "district_id": "3210200",
          "name": "LIGUNG"
        },
        {
          "id": "3210200011",
          "district_id": "3210200",
          "name": "MAJASARI"
        },
        {
          "id": "3210200012",
          "district_id": "3210200",
          "name": "SUKAWERA"
        },
        {
          "id": "3210200013",
          "district_id": "3210200",
          "name": "BANTARWARU"
        },
        {
          "id": "3210200014",
          "district_id": "3210200",
          "name": "AMPEL"
        },
        {
          "id": "3210200015",
          "district_id": "3210200",
          "name": "LEUWEUNGHAPIT"
        },
        {
          "id": "3210200016",
          "district_id": "3210200",
          "name": "KODASARI"
        },
        {
          "id": "3210200017",
          "district_id": "3210200",
          "name": "KEDUNGKANCANA"
        },
        {
          "id": "3210200018",
          "district_id": "3210200",
          "name": "LEUWILIANG BARU"
        },
        {
          "id": "3210200019",
          "district_id": "3210200",
          "name": "KEDUNGSARI"
        },
        {
          "id": "3210210001",
          "district_id": "3210210",
          "name": "SUMBERJAYA"
        },
        {
          "id": "3210210002",
          "district_id": "3210210",
          "name": "GARAWANGI"
        },
        {
          "id": "3210210003",
          "district_id": "3210210",
          "name": "BANJARAN"
        },
        {
          "id": "3210210004",
          "district_id": "3210210",
          "name": "SEPAT"
        },
        {
          "id": "3210210005",
          "district_id": "3210210",
          "name": "PANINGKIRAN"
        },
        {
          "id": "3210210006",
          "district_id": "3210210",
          "name": "PARAPATAN"
        },
        {
          "id": "3210210007",
          "district_id": "3210210",
          "name": "PANJALIN KIDUL"
        },
        {
          "id": "3210210008",
          "district_id": "3210210",
          "name": "RANCAPUTAT"
        },
        {
          "id": "3210210009",
          "district_id": "3210210",
          "name": "BONGAS WETAN"
        },
        {
          "id": "3210210010",
          "district_id": "3210210",
          "name": "BONGAS KULON"
        },
        {
          "id": "3210210011",
          "district_id": "3210210",
          "name": "PANJALIN LOR"
        },
        {
          "id": "3210210012",
          "district_id": "3210210",
          "name": "CIDENOK"
        },
        {
          "id": "3210210013",
          "district_id": "3210210",
          "name": "LOJI KOBONG"
        },
        {
          "id": "3210210014",
          "district_id": "3210210",
          "name": "GELOKMULYA"
        },
        {
          "id": "3210210015",
          "district_id": "3210210",
          "name": "PANCAKSUJI"
        },
        {
          "id": "3211010001",
          "district_id": "3211010",
          "name": "CIPACING"
        },
        {
          "id": "3211010002",
          "district_id": "3211010",
          "name": "SAYANG"
        },
        {
          "id": "3211010003",
          "district_id": "3211010",
          "name": "MEKARGALIH"
        },
        {
          "id": "3211010004",
          "district_id": "3211010",
          "name": "CINTA MULYA"
        },
        {
          "id": "3211010005",
          "district_id": "3211010",
          "name": "CISEMPUR"
        },
        {
          "id": "3211010006",
          "district_id": "3211010",
          "name": "JATIMUKTI"
        },
        {
          "id": "3211010007",
          "district_id": "3211010",
          "name": "JATIROKE"
        },
        {
          "id": "3211010008",
          "district_id": "3211010",
          "name": "HEGARMANAH"
        },
        {
          "id": "3211010009",
          "district_id": "3211010",
          "name": "CIKERUH"
        },
        {
          "id": "3211010010",
          "district_id": "3211010",
          "name": "CIBEUSI"
        },
        {
          "id": "3211010011",
          "district_id": "3211010",
          "name": "CILELES"
        },
        {
          "id": "3211010012",
          "district_id": "3211010",
          "name": "CILAYUNG"
        },
        {
          "id": "3211020001",
          "district_id": "3211020",
          "name": "MANGUNARGA"
        },
        {
          "id": "3211020002",
          "district_id": "3211020",
          "name": "SAWAHDADAP"
        },
        {
          "id": "3211020003",
          "district_id": "3211020",
          "name": "SUKADANA"
        },
        {
          "id": "3211020004",
          "district_id": "3211020",
          "name": "CIHANJUANG"
        },
        {
          "id": "3211020005",
          "district_id": "3211020",
          "name": "CIKAHURIPAN"
        },
        {
          "id": "3211020006",
          "district_id": "3211020",
          "name": "SINDANGGALIH"
        },
        {
          "id": "3211020007",
          "district_id": "3211020",
          "name": "SINDANGPAKUON"
        },
        {
          "id": "3211020008",
          "district_id": "3211020",
          "name": "CIMANGGUNG"
        },
        {
          "id": "3211020009",
          "district_id": "3211020",
          "name": "TEGALMANGGUNG"
        },
        {
          "id": "3211020010",
          "district_id": "3211020",
          "name": "SINDULANG"
        },
        {
          "id": "3211020011",
          "district_id": "3211020",
          "name": "PASIRNANJUNG"
        },
        {
          "id": "3211030001",
          "district_id": "3211030",
          "name": "CINANJUNG"
        },
        {
          "id": "3211030002",
          "district_id": "3211030",
          "name": "RAHARJA"
        },
        {
          "id": "3211030008",
          "district_id": "3211030",
          "name": "GUNUNGMANIK"
        },
        {
          "id": "3211030009",
          "district_id": "3211030",
          "name": "MARGA JAYA"
        },
        {
          "id": "3211030010",
          "district_id": "3211030",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3211030011",
          "district_id": "3211030",
          "name": "JATISARI"
        },
        {
          "id": "3211030012",
          "district_id": "3211030",
          "name": "KUTAMANDIRI"
        },
        {
          "id": "3211030014",
          "district_id": "3211030",
          "name": "MARGALUYU"
        },
        {
          "id": "3211030015",
          "district_id": "3211030",
          "name": "GUDANG"
        },
        {
          "id": "3211030018",
          "district_id": "3211030",
          "name": "PASIGARAN"
        },
        {
          "id": "3211030025",
          "district_id": "3211030",
          "name": "KADAKAJAYA"
        },
        {
          "id": "3211030027",
          "district_id": "3211030",
          "name": "CIJAMBU"
        },
        {
          "id": "3211031001",
          "district_id": "3211031",
          "name": "SUKARAPIH"
        },
        {
          "id": "3211031002",
          "district_id": "3211031",
          "name": "SUKASARI"
        },
        {
          "id": "3211031003",
          "district_id": "3211031",
          "name": "MEKARSARI"
        },
        {
          "id": "3211031004",
          "district_id": "3211031",
          "name": "SINDANGSARI"
        },
        {
          "id": "3211031005",
          "district_id": "3211031",
          "name": "NANGGERANG"
        },
        {
          "id": "3211031006",
          "district_id": "3211031",
          "name": "BANYURESMI"
        },
        {
          "id": "3211032001",
          "district_id": "3211032",
          "name": "MEKARBAKTI"
        },
        {
          "id": "3211032002",
          "district_id": "3211032",
          "name": "CILEMBU"
        },
        {
          "id": "3211032003",
          "district_id": "3211032",
          "name": "CIMARIAS"
        },
        {
          "id": "3211032004",
          "district_id": "3211032",
          "name": "CINANGGERANG"
        },
        {
          "id": "3211032005",
          "district_id": "3211032",
          "name": "CIJERUK"
        },
        {
          "id": "3211032006",
          "district_id": "3211032",
          "name": "CIGENDEL"
        },
        {
          "id": "3211032007",
          "district_id": "3211032",
          "name": "HAURNGOMBONG"
        },
        {
          "id": "3211032008",
          "district_id": "3211032",
          "name": "CIPTASARI"
        },
        {
          "id": "3211032009",
          "district_id": "3211032",
          "name": "CITALI"
        },
        {
          "id": "3211032010",
          "district_id": "3211032",
          "name": "PAMULIHAN"
        },
        {
          "id": "3211032011",
          "district_id": "3211032",
          "name": "SUKAWANGI"
        },
        {
          "id": "3211040002",
          "district_id": "3211040",
          "name": "SUKASIRNARASA"
        },
        {
          "id": "3211040006",
          "district_id": "3211040",
          "name": "PASIR BIRU"
        },
        {
          "id": "3211040007",
          "district_id": "3211040",
          "name": "RANCAKALONG"
        },
        {
          "id": "3211040008",
          "district_id": "3211040",
          "name": "PAMEKARAN"
        },
        {
          "id": "3211040009",
          "district_id": "3211040",
          "name": "SUKAMAJU"
        },
        {
          "id": "3211040010",
          "district_id": "3211040",
          "name": "SUKAHAYU"
        },
        {
          "id": "3211040011",
          "district_id": "3211040",
          "name": "NAGARAWANGI"
        },
        {
          "id": "3211040012",
          "district_id": "3211040",
          "name": "CIBUNAR"
        },
        {
          "id": "3211040013",
          "district_id": "3211040",
          "name": "PANGADEGAN"
        },
        {
          "id": "3211040014",
          "district_id": "3211040",
          "name": "CIBUNGUR"
        },
        {
          "id": "3211050001",
          "district_id": "3211050",
          "name": "SUKAJAYA"
        },
        {
          "id": "3211050002",
          "district_id": "3211050",
          "name": "MARGAMEKAR"
        },
        {
          "id": "3211050003",
          "district_id": "3211050",
          "name": "CIPANCAR"
        },
        {
          "id": "3211050004",
          "district_id": "3211050",
          "name": "CITENGAH"
        },
        {
          "id": "3211050007",
          "district_id": "3211050",
          "name": "GUNASARI"
        },
        {
          "id": "3211050008",
          "district_id": "3211050",
          "name": "BAGINDA"
        },
        {
          "id": "3211050009",
          "district_id": "3211050",
          "name": "SUKAGALIH"
        },
        {
          "id": "3211050010",
          "district_id": "3211050",
          "name": "CIPAMEUNGPEUK"
        },
        {
          "id": "3211050011",
          "district_id": "3211050",
          "name": "REGOL WETAN"
        },
        {
          "id": "3211050012",
          "district_id": "3211050",
          "name": "KOTAKULON"
        },
        {
          "id": "3211050013",
          "district_id": "3211050",
          "name": "PASANGGRAHAN BARU"
        },
        {
          "id": "3211050014",
          "district_id": "3211050",
          "name": "CIHERANG"
        },
        {
          "id": "3211050015",
          "district_id": "3211050",
          "name": "MEKARRAHAYU"
        },
        {
          "id": "3211050016",
          "district_id": "3211050",
          "name": "MARGALAKSANA"
        },
        {
          "id": "3211060001",
          "district_id": "3211060",
          "name": "SIRNAMULYA"
        },
        {
          "id": "3211060002",
          "district_id": "3211060",
          "name": "GIRIMUKTI"
        },
        {
          "id": "3211060003",
          "district_id": "3211060",
          "name": "MULYASARI"
        },
        {
          "id": "3211060004",
          "district_id": "3211060",
          "name": "PADASUKA"
        },
        {
          "id": "3211060005",
          "district_id": "3211060",
          "name": "MARGAMUKTI"
        },
        {
          "id": "3211060006",
          "district_id": "3211060",
          "name": "MEKARJAYA"
        },
        {
          "id": "3211060007",
          "district_id": "3211060",
          "name": "JATIMULYA"
        },
        {
          "id": "3211060008",
          "district_id": "3211060",
          "name": "JATIHURIP"
        },
        {
          "id": "3211060009",
          "district_id": "3211060",
          "name": "KEBONJATI"
        },
        {
          "id": "3211060010",
          "district_id": "3211060",
          "name": "SITU"
        },
        {
          "id": "3211060011",
          "district_id": "3211060",
          "name": "KOTAKALER"
        },
        {
          "id": "3211060012",
          "district_id": "3211060",
          "name": "TALUN"
        },
        {
          "id": "3211060013",
          "district_id": "3211060",
          "name": "RANCAMULYA"
        },
        {
          "id": "3211061001",
          "district_id": "3211061",
          "name": "CIKONDANG"
        },
        {
          "id": "3211061002",
          "district_id": "3211061",
          "name": "TANJUNGHURIP"
        },
        {
          "id": "3211061003",
          "district_id": "3211061",
          "name": "DAYEUH LUHUR"
        },
        {
          "id": "3211061004",
          "district_id": "3211061",
          "name": "CIKONENG"
        },
        {
          "id": "3211061005",
          "district_id": "3211061",
          "name": "SUKAWENING"
        },
        {
          "id": "3211061006",
          "district_id": "3211061",
          "name": "GANEAS"
        },
        {
          "id": "3211061007",
          "district_id": "3211061",
          "name": "SUKALUYU"
        },
        {
          "id": "3211061008",
          "district_id": "3211061",
          "name": "CIKONENG KULON"
        },
        {
          "id": "3211070001",
          "district_id": "3211070",
          "name": "BANGBAYANG"
        },
        {
          "id": "3211070002",
          "district_id": "3211070",
          "name": "KADUWULUNG"
        },
        {
          "id": "3211070011",
          "district_id": "3211070",
          "name": "CIJATI"
        },
        {
          "id": "3211070012",
          "district_id": "3211070",
          "name": "MEKARMULYA"
        },
        {
          "id": "3211070013",
          "district_id": "3211070",
          "name": "CIKADU"
        },
        {
          "id": "3211070014",
          "district_id": "3211070",
          "name": "KARANGHEULEUT"
        },
        {
          "id": "3211070015",
          "district_id": "3211070",
          "name": "CIJELER"
        },
        {
          "id": "3211070016",
          "district_id": "3211070",
          "name": "AMBIT"
        },
        {
          "id": "3211070017",
          "district_id": "3211070",
          "name": "SUKATALI"
        },
        {
          "id": "3211070018",
          "district_id": "3211070",
          "name": "SITURAJA"
        },
        {
          "id": "3211070019",
          "district_id": "3211070",
          "name": "JATIMEKAR"
        },
        {
          "id": "3211070020",
          "district_id": "3211070",
          "name": "SITURAJA UTARA"
        },
        {
          "id": "3211070021",
          "district_id": "3211070",
          "name": "MALAKA"
        },
        {
          "id": "3211070022",
          "district_id": "3211070",
          "name": "PAMULIHAN"
        },
        {
          "id": "3211070023",
          "district_id": "3211070",
          "name": "CICARIMANAH"
        },
        {
          "id": "3211071001",
          "district_id": "3211071",
          "name": "SUNDAMEKAR"
        },
        {
          "id": "3211071002",
          "district_id": "3211071",
          "name": "CIMARGA"
        },
        {
          "id": "3211071003",
          "district_id": "3211071",
          "name": "CINANGSI"
        },
        {
          "id": "3211071004",
          "district_id": "3211071",
          "name": "LINGGAJAYA"
        },
        {
          "id": "3211071005",
          "district_id": "3211071",
          "name": "SITUMEKAR"
        },
        {
          "id": "3211071006",
          "district_id": "3211071",
          "name": "CISITU"
        },
        {
          "id": "3211071007",
          "district_id": "3211071",
          "name": "CIGINTUNG"
        },
        {
          "id": "3211071008",
          "district_id": "3211071",
          "name": "RANJENG"
        },
        {
          "id": "3211071009",
          "district_id": "3211071",
          "name": "CILOPANG"
        },
        {
          "id": "3211071010",
          "district_id": "3211071",
          "name": "PAJAGAN"
        },
        {
          "id": "3211080003",
          "district_id": "3211080",
          "name": "NEGLASARI"
        },
        {
          "id": "3211080004",
          "district_id": "3211080",
          "name": "SUKAMENAK"
        },
        {
          "id": "3211080005",
          "district_id": "3211080",
          "name": "JATIBUNGUR"
        },
        {
          "id": "3211080006",
          "district_id": "3211080",
          "name": "DARMAJAYA"
        },
        {
          "id": "3211080007",
          "district_id": "3211080",
          "name": "DARMARAJA"
        },
        {
          "id": "3211080008",
          "district_id": "3211080",
          "name": "CIPEUTEUY"
        },
        {
          "id": "3211080011",
          "district_id": "3211080",
          "name": "CIKEUSI"
        },
        {
          "id": "3211080012",
          "district_id": "3211080",
          "name": "CIEUNTEUNG"
        },
        {
          "id": "3211080013",
          "district_id": "3211080",
          "name": "TARUNAJAYA"
        },
        {
          "id": "3211080014",
          "district_id": "3211080",
          "name": "SUKARATU"
        },
        {
          "id": "3211080015",
          "district_id": "3211080",
          "name": "LEUWIHIDEUNG"
        },
        {
          "id": "3211080016",
          "district_id": "3211080",
          "name": "CIBOGO"
        },
        {
          "id": "3211080017",
          "district_id": "3211080",
          "name": "CIPAKU"
        },
        {
          "id": "3211080018",
          "district_id": "3211080",
          "name": "KARANG PAKUAN"
        },
        {
          "id": "3211080019",
          "district_id": "3211080",
          "name": "PAKU ALAM"
        },
        {
          "id": "3211080020",
          "district_id": "3211080",
          "name": "RANGGON"
        },
        {
          "id": "3211090001",
          "district_id": "3211090",
          "name": "BUANA MEKAR"
        },
        {
          "id": "3211090002",
          "district_id": "3211090",
          "name": "JAYA MEKAR"
        },
        {
          "id": "3211090003",
          "district_id": "3211090",
          "name": "CIBUGEL"
        },
        {
          "id": "3211090004",
          "district_id": "3211090",
          "name": "TAMANSARI"
        },
        {
          "id": "3211090005",
          "district_id": "3211090",
          "name": "SUKARAJA"
        },
        {
          "id": "3211090006",
          "district_id": "3211090",
          "name": "CIPASANG"
        },
        {
          "id": "3211090007",
          "district_id": "3211090",
          "name": "JAYAMANDIRI"
        },
        {
          "id": "3211100001",
          "district_id": "3211100",
          "name": "CILENGKRANG"
        },
        {
          "id": "3211100002",
          "district_id": "3211100",
          "name": "SUKAJADI"
        },
        {
          "id": "3211100003",
          "district_id": "3211100",
          "name": "GANJARESIK"
        },
        {
          "id": "3211100004",
          "district_id": "3211100",
          "name": "CIMUNGKAL"
        },
        {
          "id": "3211100011",
          "district_id": "3211100",
          "name": "MULYAJAYA"
        },
        {
          "id": "3211100012",
          "district_id": "3211100",
          "name": "CIKAREO SELATAN"
        },
        {
          "id": "3211100013",
          "district_id": "3211100",
          "name": "CIKAREO UTARA"
        },
        {
          "id": "3211100014",
          "district_id": "3211100",
          "name": "WADO"
        },
        {
          "id": "3211100015",
          "district_id": "3211100",
          "name": "PADAJAYA"
        },
        {
          "id": "3211100016",
          "district_id": "3211100",
          "name": "SUKAPURA"
        },
        {
          "id": "3211100017",
          "district_id": "3211100",
          "name": "CISURAT"
        },
        {
          "id": "3211101001",
          "district_id": "3211101",
          "name": "KIRISIK"
        },
        {
          "id": "3211101002",
          "district_id": "3211101",
          "name": "CIPEUNDEUY"
        },
        {
          "id": "3211101003",
          "district_id": "3211101",
          "name": "CIMANINTIN"
        },
        {
          "id": "3211101004",
          "district_id": "3211101",
          "name": "SUKAMANAH"
        },
        {
          "id": "3211101005",
          "district_id": "3211101",
          "name": "BANJARSARI"
        },
        {
          "id": "3211101006",
          "district_id": "3211101",
          "name": "SARIMEKAR"
        },
        {
          "id": "3211101007",
          "district_id": "3211101",
          "name": "TARIKOLOT"
        },
        {
          "id": "3211101008",
          "district_id": "3211101",
          "name": "SIRNASARI"
        },
        {
          "id": "3211101009",
          "district_id": "3211101",
          "name": "PAWENANG"
        },
        {
          "id": "3211111001",
          "district_id": "3211111",
          "name": "SUKAKERSA"
        },
        {
          "id": "3211111002",
          "district_id": "3211111",
          "name": "MEKARASIH"
        },
        {
          "id": "3211111003",
          "district_id": "3211111",
          "name": "CIRANGGEM"
        },
        {
          "id": "3211111004",
          "district_id": "3211111",
          "name": "CISAMPIH"
        },
        {
          "id": "3211111006",
          "district_id": "3211111",
          "name": "KADU"
        },
        {
          "id": "3211111007",
          "district_id": "3211111",
          "name": "LEBAKSIUH"
        },
        {
          "id": "3211111008",
          "district_id": "3211111",
          "name": "CINTAJAYA"
        },
        {
          "id": "3211111009",
          "district_id": "3211111",
          "name": "CIPICUNG"
        },
        {
          "id": "3211111010",
          "district_id": "3211111",
          "name": "JEMAH"
        },
        {
          "id": "3211111011",
          "district_id": "3211111",
          "name": "CIJEUNGJING"
        },
        {
          "id": "3211111012",
          "district_id": "3211111",
          "name": "KADUJAYA"
        },
        {
          "id": "3211111013",
          "district_id": "3211111",
          "name": "KAREDOK"
        },
        {
          "id": "3211120003",
          "district_id": "3211120",
          "name": "CIPELES"
        },
        {
          "id": "3211120004",
          "district_id": "3211120",
          "name": "DARMAWANGI"
        },
        {
          "id": "3211120005",
          "district_id": "3211120",
          "name": "JEMBARWANGI"
        },
        {
          "id": "3211120006",
          "district_id": "3211120",
          "name": "MARONGGE"
        },
        {
          "id": "3211120007",
          "district_id": "3211120",
          "name": "TOLENGAS"
        },
        {
          "id": "3211120008",
          "district_id": "3211120",
          "name": "TOMO"
        },
        {
          "id": "3211120009",
          "district_id": "3211120",
          "name": "KARYAMUKTI"
        },
        {
          "id": "3211120011",
          "district_id": "3211120",
          "name": "MEKARWANGI"
        },
        {
          "id": "3211130001",
          "district_id": "3211130",
          "name": "CIPELANG"
        },
        {
          "id": "3211130002",
          "district_id": "3211130",
          "name": "PALABUAN"
        },
        {
          "id": "3211130003",
          "district_id": "3211130",
          "name": "KEBON CAU"
        },
        {
          "id": "3211130004",
          "district_id": "3211130",
          "name": "KUDANGWANGI"
        },
        {
          "id": "3211130005",
          "district_id": "3211130",
          "name": "SUKAMULYA"
        },
        {
          "id": "3211130006",
          "district_id": "3211130",
          "name": "PALASARI"
        },
        {
          "id": "3211130007",
          "district_id": "3211130",
          "name": "UJUNGJAYA"
        },
        {
          "id": "3211130008",
          "district_id": "3211130",
          "name": "SAKURJAYA"
        },
        {
          "id": "3211130009",
          "district_id": "3211130",
          "name": "CIBULUH"
        },
        {
          "id": "3211140001",
          "district_id": "3211140",
          "name": "NARIMBANG"
        },
        {
          "id": "3211140002",
          "district_id": "3211140",
          "name": "JAMBU"
        },
        {
          "id": "3211140003",
          "district_id": "3211140",
          "name": "CIPAMEKAR"
        },
        {
          "id": "3211140004",
          "district_id": "3211140",
          "name": "CONGGEANG KULON"
        },
        {
          "id": "3211140005",
          "district_id": "3211140",
          "name": "CONGGEANG WETAN"
        },
        {
          "id": "3211140006",
          "district_id": "3211140",
          "name": "CIBEUREUYEUH"
        },
        {
          "id": "3211140007",
          "district_id": "3211140",
          "name": "PADAASIH"
        },
        {
          "id": "3211140008",
          "district_id": "3211140",
          "name": "BABAKAN ASEM"
        },
        {
          "id": "3211140009",
          "district_id": "3211140",
          "name": "UNGKAL"
        },
        {
          "id": "3211140010",
          "district_id": "3211140",
          "name": "CACABAN"
        },
        {
          "id": "3211140011",
          "district_id": "3211140",
          "name": "KARANGLAYUNG"
        },
        {
          "id": "3211140012",
          "district_id": "3211140",
          "name": "CIBUBUAN"
        },
        {
          "id": "3211150001",
          "district_id": "3211150",
          "name": "LEGOK KALER"
        },
        {
          "id": "3211150002",
          "district_id": "3211150",
          "name": "LEGOK KIDUL"
        },
        {
          "id": "3211150003",
          "district_id": "3211150",
          "name": "PASEH KIDUL"
        },
        {
          "id": "3211150004",
          "district_id": "3211150",
          "name": "CIJAMBE"
        },
        {
          "id": "3211150005",
          "district_id": "3211150",
          "name": "PASIREUNGIT"
        },
        {
          "id": "3211150006",
          "district_id": "3211150",
          "name": "PADANAAN"
        },
        {
          "id": "3211150007",
          "district_id": "3211150",
          "name": "BONGKOK"
        },
        {
          "id": "3211150008",
          "district_id": "3211150",
          "name": "PASEH KALER"
        },
        {
          "id": "3211150009",
          "district_id": "3211150",
          "name": "HAURKUNING"
        },
        {
          "id": "3211150010",
          "district_id": "3211150",
          "name": "CITEPOK"
        },
        {
          "id": "3211160001",
          "district_id": "3211160",
          "name": "CIMUJA"
        },
        {
          "id": "3211160011",
          "district_id": "3211160",
          "name": "CIBEUREUM WETAN"
        },
        {
          "id": "3211160012",
          "district_id": "3211160",
          "name": "CIBEUREUM KULON"
        },
        {
          "id": "3211160013",
          "district_id": "3211160",
          "name": "MANDALAHERANG"
        },
        {
          "id": "3211160014",
          "district_id": "3211160",
          "name": "CIMALAKA"
        },
        {
          "id": "3211160015",
          "district_id": "3211160",
          "name": "SERANG"
        },
        {
          "id": "3211160016",
          "district_id": "3211160",
          "name": "GALUDRA"
        },
        {
          "id": "3211160017",
          "district_id": "3211160",
          "name": "CIKOLE"
        },
        {
          "id": "3211160018",
          "district_id": "3211160",
          "name": "TRUNAMANGGALA"
        },
        {
          "id": "3211160019",
          "district_id": "3211160",
          "name": "NYALINDUNG"
        },
        {
          "id": "3211160020",
          "district_id": "3211160",
          "name": "NALUK"
        },
        {
          "id": "3211160021",
          "district_id": "3211160",
          "name": "CITIMUN"
        },
        {
          "id": "3211160022",
          "district_id": "3211160",
          "name": "LICIN"
        },
        {
          "id": "3211160023",
          "district_id": "3211160",
          "name": "PADASARI"
        },
        {
          "id": "3211161001",
          "district_id": "3211161",
          "name": "CISALAK"
        },
        {
          "id": "3211161002",
          "district_id": "3211161",
          "name": "KEBONKALAPA"
        },
        {
          "id": "3211161003",
          "district_id": "3211161",
          "name": "CISARUA"
        },
        {
          "id": "3211161004",
          "district_id": "3211161",
          "name": "BANTARMARA"
        },
        {
          "id": "3211161005",
          "district_id": "3211161",
          "name": "CIUYAH"
        },
        {
          "id": "3211161006",
          "district_id": "3211161",
          "name": "CIMARA"
        },
        {
          "id": "3211161007",
          "district_id": "3211161",
          "name": "CIPANDANWANGI"
        },
        {
          "id": "3211170003",
          "district_id": "3211170",
          "name": "TANJUNGMEKAR"
        },
        {
          "id": "3211170004",
          "district_id": "3211170",
          "name": "CIGENTUR"
        },
        {
          "id": "3211170005",
          "district_id": "3211170",
          "name": "GUNTURMEKAR"
        },
        {
          "id": "3211170006",
          "district_id": "3211170",
          "name": "CIPANAS"
        },
        {
          "id": "3211170007",
          "district_id": "3211170",
          "name": "BANYUASIH"
        },
        {
          "id": "3211170008",
          "district_id": "3211170",
          "name": "MULYAMEKAR"
        },
        {
          "id": "3211170009",
          "district_id": "3211170",
          "name": "SUKAMANTRI"
        },
        {
          "id": "3211170016",
          "district_id": "3211170",
          "name": "KERTAHARJA"
        },
        {
          "id": "3211170017",
          "district_id": "3211170",
          "name": "KERTAMEKAR"
        },
        {
          "id": "3211170018",
          "district_id": "3211170",
          "name": "TANJUNGMULYA"
        },
        {
          "id": "3211170019",
          "district_id": "3211170",
          "name": "BOROS"
        },
        {
          "id": "3211170020",
          "district_id": "3211170",
          "name": "AWILEGA"
        },
        {
          "id": "3211171001",
          "district_id": "3211171",
          "name": "WARGALUYU"
        },
        {
          "id": "3211171002",
          "district_id": "3211171",
          "name": "TANJUNGWANGI"
        },
        {
          "id": "3211171003",
          "district_id": "3211171",
          "name": "SUKAMUKTI"
        },
        {
          "id": "3211171004",
          "district_id": "3211171",
          "name": "CIKARAMAS"
        },
        {
          "id": "3211171005",
          "district_id": "3211171",
          "name": "KERTAMUKTI"
        },
        {
          "id": "3211171006",
          "district_id": "3211171",
          "name": "SUKATANI"
        },
        {
          "id": "3211171007",
          "district_id": "3211171",
          "name": "KAMAL"
        },
        {
          "id": "3211171008",
          "district_id": "3211171",
          "name": "JINGKANG"
        },
        {
          "id": "3211171009",
          "district_id": "3211171",
          "name": "TANJUNGMEDAR"
        },
        {
          "id": "3211180001",
          "district_id": "3211180",
          "name": "SEKARWANGI"
        },
        {
          "id": "3211180002",
          "district_id": "3211180",
          "name": "CILANGKAP"
        },
        {
          "id": "3211180003",
          "district_id": "3211180",
          "name": "CIBITUNG"
        },
        {
          "id": "3211180004",
          "district_id": "3211180",
          "name": "CIKURUBUK"
        },
        {
          "id": "3211180005",
          "district_id": "3211180",
          "name": "BOJONGLOA"
        },
        {
          "id": "3211180006",
          "district_id": "3211180",
          "name": "NAGRAK"
        },
        {
          "id": "3211180007",
          "district_id": "3211180",
          "name": "PANYINDANGAN"
        },
        {
          "id": "3211180008",
          "district_id": "3211180",
          "name": "BUAHDUA"
        },
        {
          "id": "3211180010",
          "district_id": "3211180",
          "name": "CITALEUS"
        },
        {
          "id": "3211180011",
          "district_id": "3211180",
          "name": "MEKARMUKTI"
        },
        {
          "id": "3211180012",
          "district_id": "3211180",
          "name": "HARIANG"
        },
        {
          "id": "3211180014",
          "district_id": "3211180",
          "name": "KARANGBUNGUR"
        },
        {
          "id": "3211180015",
          "district_id": "3211180",
          "name": "CIAWITALI"
        },
        {
          "id": "3211181001",
          "district_id": "3211181",
          "name": "WANAJAYA"
        },
        {
          "id": "3211181002",
          "district_id": "3211181",
          "name": "WANASARI"
        },
        {
          "id": "3211181003",
          "district_id": "3211181",
          "name": "PAMEKARSARI"
        },
        {
          "id": "3211181004",
          "district_id": "3211181",
          "name": "SURIAN"
        },
        {
          "id": "3211181005",
          "district_id": "3211181",
          "name": "TANJUNG"
        },
        {
          "id": "3211181006",
          "district_id": "3211181",
          "name": "RANGGASARI"
        },
        {
          "id": "3211181007",
          "district_id": "3211181",
          "name": "SURIAMEDAL"
        },
        {
          "id": "3211181008",
          "district_id": "3211181",
          "name": "SURIAMUKTI"
        },
        {
          "id": "3211181009",
          "district_id": "3211181",
          "name": "NANJUNGWANGI"
        },
        {
          "id": "3212010007",
          "district_id": "3212010",
          "name": "HAURKOLOT"
        },
        {
          "id": "3212010008",
          "district_id": "3212010",
          "name": "HAURGEULIS"
        },
        {
          "id": "3212010009",
          "district_id": "3212010",
          "name": "SUKAJATI"
        },
        {
          "id": "3212010010",
          "district_id": "3212010",
          "name": "WANAKAYA"
        },
        {
          "id": "3212010011",
          "district_id": "3212010",
          "name": "KARANGTUMARITIS"
        },
        {
          "id": "3212010012",
          "district_id": "3212010",
          "name": "KERTANEGARA"
        },
        {
          "id": "3212010013",
          "district_id": "3212010",
          "name": "CIPANCUH"
        },
        {
          "id": "3212010014",
          "district_id": "3212010",
          "name": "MEKARJATI"
        },
        {
          "id": "3212010015",
          "district_id": "3212010",
          "name": "SIDADADI"
        },
        {
          "id": "3212010016",
          "district_id": "3212010",
          "name": "SUMBERMULYA"
        },
        {
          "id": "3212011001",
          "district_id": "3212011",
          "name": "BANTARWARU"
        },
        {
          "id": "3212011002",
          "district_id": "3212011",
          "name": "SANCA"
        },
        {
          "id": "3212011003",
          "district_id": "3212011",
          "name": "MEKARJAYA"
        },
        {
          "id": "3212011004",
          "district_id": "3212011",
          "name": "GANTAR"
        },
        {
          "id": "3212011005",
          "district_id": "3212011",
          "name": "SITURAJA"
        },
        {
          "id": "3212011006",
          "district_id": "3212011",
          "name": "BALERAJA"
        },
        {
          "id": "3212011007",
          "district_id": "3212011",
          "name": "MEKARWARU"
        },
        {
          "id": "3212020001",
          "district_id": "3212020",
          "name": "SUKASLAMET"
        },
        {
          "id": "3212020002",
          "district_id": "3212020",
          "name": "TANJUNGKERTA"
        },
        {
          "id": "3212020003",
          "district_id": "3212020",
          "name": "KROYA"
        },
        {
          "id": "3212020004",
          "district_id": "3212020",
          "name": "SUMBON"
        },
        {
          "id": "3212020005",
          "district_id": "3212020",
          "name": "SUKAMELANG"
        },
        {
          "id": "3212020006",
          "district_id": "3212020",
          "name": "TEMIYANG"
        },
        {
          "id": "3212020007",
          "district_id": "3212020",
          "name": "TEMIYANGSARI"
        },
        {
          "id": "3212020008",
          "district_id": "3212020",
          "name": "JAYAMULYA"
        },
        {
          "id": "3212020009",
          "district_id": "3212020",
          "name": "SUMBERJAYA"
        },
        {
          "id": "3212030001",
          "district_id": "3212030",
          "name": "KEDUNGDAWA"
        },
        {
          "id": "3212030002",
          "district_id": "3212030",
          "name": "BABAKANJAYA"
        },
        {
          "id": "3212030003",
          "district_id": "3212030",
          "name": "GABUSKULON"
        },
        {
          "id": "3212030004",
          "district_id": "3212030",
          "name": "SEKARMULYA"
        },
        {
          "id": "3212030005",
          "district_id": "3212030",
          "name": "KEDOKANGABUS"
        },
        {
          "id": "3212030006",
          "district_id": "3212030",
          "name": "RANCAMULYA"
        },
        {
          "id": "3212030007",
          "district_id": "3212030",
          "name": "RANCAHAN"
        },
        {
          "id": "3212030008",
          "district_id": "3212030",
          "name": "GABUSWETAN"
        },
        {
          "id": "3212030009",
          "district_id": "3212030",
          "name": "DRUNTEN WETAN"
        },
        {
          "id": "3212030010",
          "district_id": "3212030",
          "name": "DRUNTEN KULON"
        },
        {
          "id": "3212040003",
          "district_id": "3212040",
          "name": "LOYANG"
        },
        {
          "id": "3212040004",
          "district_id": "3212040",
          "name": "AMIS"
        },
        {
          "id": "3212040005",
          "district_id": "3212040",
          "name": "JATISURA"
        },
        {
          "id": "3212040006",
          "district_id": "3212040",
          "name": "JAMBAK"
        },
        {
          "id": "3212040007",
          "district_id": "3212040",
          "name": "CIKEDUNG"
        },
        {
          "id": "3212040012",
          "district_id": "3212040",
          "name": "CIKEDUNG LOR"
        },
        {
          "id": "3212040013",
          "district_id": "3212040",
          "name": "MUNDAKJAYA"
        },
        {
          "id": "3212041001",
          "district_id": "3212041",
          "name": "CIKAWUNG"
        },
        {
          "id": "3212041002",
          "district_id": "3212041",
          "name": "JATIMUNGGUL"
        },
        {
          "id": "3212041003",
          "district_id": "3212041",
          "name": "JATIMULYA"
        },
        {
          "id": "3212041004",
          "district_id": "3212041",
          "name": "PLOSOKEREP"
        },
        {
          "id": "3212041005",
          "district_id": "3212041",
          "name": "RAJASINGA"
        },
        {
          "id": "3212041006",
          "district_id": "3212041",
          "name": "KARANGASEM"
        },
        {
          "id": "3212041007",
          "district_id": "3212041",
          "name": "CIBERENG"
        },
        {
          "id": "3212041008",
          "district_id": "3212041",
          "name": "KENDAYAKAN"
        },
        {
          "id": "3212041009",
          "district_id": "3212041",
          "name": "MANGGUNGAN"
        },
        {
          "id": "3212050001",
          "district_id": "3212050",
          "name": "TUNGGULPAYUNG"
        },
        {
          "id": "3212050005",
          "district_id": "3212050",
          "name": "PANGAUBAN"
        },
        {
          "id": "3212050006",
          "district_id": "3212050",
          "name": "TELAGASARI"
        },
        {
          "id": "3212050007",
          "district_id": "3212050",
          "name": "LANGGENGSARI"
        },
        {
          "id": "3212050008",
          "district_id": "3212050",
          "name": "TAMANSARI"
        },
        {
          "id": "3212050009",
          "district_id": "3212050",
          "name": "LELEA"
        },
        {
          "id": "3212050011",
          "district_id": "3212050",
          "name": "TEMPELKULON"
        },
        {
          "id": "3212060008",
          "district_id": "3212060",
          "name": "MULYASARI"
        },
        {
          "id": "3212060015",
          "district_id": "3212060",
          "name": "BANGODUA"
        },
        {
          "id": "3212060017",
          "district_id": "3212060",
          "name": "KARANGGETAS"
        },
        {
          "id": "3212060018",
          "district_id": "3212060",
          "name": "TEGALGIRANG"
        },
        {
          "id": "3212060019",
          "district_id": "3212060",
          "name": "WANASARI"
        },
        {
          "id": "3212060020",
          "district_id": "3212060",
          "name": "MALANGSARI"
        },
        {
          "id": "3212060021",
          "district_id": "3212060",
          "name": "RANCASARI"
        },
        {
          "id": "3212061001",
          "district_id": "3212061",
          "name": "BODAS"
        },
        {
          "id": "3212061002",
          "district_id": "3212061",
          "name": "GADEL"
        },
        {
          "id": "3212061003",
          "district_id": "3212061",
          "name": "RANCAJAWAT"
        },
        {
          "id": "3212061004",
          "district_id": "3212061",
          "name": "KERTICALA"
        },
        {
          "id": "3212061005",
          "district_id": "3212061",
          "name": "SUKAMULYA"
        },
        {
          "id": "3212061006",
          "district_id": "3212061",
          "name": "KARANGKERTA"
        },
        {
          "id": "3212061007",
          "district_id": "3212061",
          "name": "CANGKO"
        },
        {
          "id": "3212061008",
          "district_id": "3212061",
          "name": "PAGEDANGAN"
        },
        {
          "id": "3212061009",
          "district_id": "3212061",
          "name": "SUKAPERNA"
        },
        {
          "id": "3212061010",
          "district_id": "3212061",
          "name": "SUKADANA"
        },
        {
          "id": "3212061011",
          "district_id": "3212061",
          "name": "TUKDANA"
        },
        {
          "id": "3212061012",
          "district_id": "3212061",
          "name": "LAJER"
        },
        {
          "id": "3212061013",
          "district_id": "3212061",
          "name": "MEKARSARI"
        },
        {
          "id": "3212070006",
          "district_id": "3212070",
          "name": "BANGKALOA ILIR"
        },
        {
          "id": "3212070007",
          "district_id": "3212070",
          "name": "WIDASARI"
        },
        {
          "id": "3212070008",
          "district_id": "3212070",
          "name": "KALENSARI"
        },
        {
          "id": "3212070011",
          "district_id": "3212070",
          "name": "UJUNGARIS"
        },
        {
          "id": "3212070012",
          "district_id": "3212070",
          "name": "KONGSIJAYA"
        },
        {
          "id": "3212070013",
          "district_id": "3212070",
          "name": "UJUNGJAYA"
        },
        {
          "id": "3212070014",
          "district_id": "3212070",
          "name": "UJUNGPENDOKJAYA"
        },
        {
          "id": "3212070015",
          "district_id": "3212070",
          "name": "LEUWIGEDE"
        },
        {
          "id": "3212070016",
          "district_id": "3212070",
          "name": "KASMARAN"
        },
        {
          "id": "3212080007",
          "district_id": "3212080",
          "name": "TULUNGAGUNG"
        },
        {
          "id": "3212080008",
          "district_id": "3212080",
          "name": "JENGKOK"
        },
        {
          "id": "3212080009",
          "district_id": "3212080",
          "name": "TEGALWIRANGRONG"
        },
        {
          "id": "3212080010",
          "district_id": "3212080",
          "name": "MANGUNTARA"
        },
        {
          "id": "3212080011",
          "district_id": "3212080",
          "name": "JAMBE"
        },
        {
          "id": "3212080012",
          "district_id": "3212080",
          "name": "LEMAHAYU"
        },
        {
          "id": "3212080013",
          "district_id": "3212080",
          "name": "TENAJAR KIDUL"
        },
        {
          "id": "3212080014",
          "district_id": "3212080",
          "name": "KERTASEMAYA"
        },
        {
          "id": "3212080015",
          "district_id": "3212080",
          "name": "KLIWED"
        },
        {
          "id": "3212080016",
          "district_id": "3212080",
          "name": "TENAJAR"
        },
        {
          "id": "3212080017",
          "district_id": "3212080",
          "name": "LARANGANJAMBE"
        },
        {
          "id": "3212080018",
          "district_id": "3212080",
          "name": "TENAJAR LOR"
        },
        {
          "id": "3212080019",
          "district_id": "3212080",
          "name": "SUKAWERA"
        },
        {
          "id": "3212081001",
          "district_id": "3212081",
          "name": "CIBEBER"
        },
        {
          "id": "3212081002",
          "district_id": "3212081",
          "name": "BONDAN"
        },
        {
          "id": "3212081003",
          "district_id": "3212081",
          "name": "GUNUNGSARI"
        },
        {
          "id": "3212081004",
          "district_id": "3212081",
          "name": "SUKAGUMIWANG"
        },
        {
          "id": "3212081005",
          "district_id": "3212081",
          "name": "TERSANA"
        },
        {
          "id": "3212081006",
          "district_id": "3212081",
          "name": "CADANGPINGGAN"
        },
        {
          "id": "3212081007",
          "district_id": "3212081",
          "name": "GEDANGAN"
        },
        {
          "id": "3212090001",
          "district_id": "3212090",
          "name": "PURWAJAYA"
        },
        {
          "id": "3212090002",
          "district_id": "3212090",
          "name": "KAPRINGAN"
        },
        {
          "id": "3212090003",
          "district_id": "3212090",
          "name": "SINGAKERTA"
        },
        {
          "id": "3212090004",
          "district_id": "3212090",
          "name": "DUKUHJATI"
        },
        {
          "id": "3212090005",
          "district_id": "3212090",
          "name": "TEGALMULYA"
        },
        {
          "id": "3212090008",
          "district_id": "3212090",
          "name": "LUWUNGGESIK"
        },
        {
          "id": "3212090009",
          "district_id": "3212090",
          "name": "KALIANYAR"
        },
        {
          "id": "3212090010",
          "district_id": "3212090",
          "name": "KRANGKENG"
        },
        {
          "id": "3212090011",
          "district_id": "3212090",
          "name": "TANJAKAN"
        },
        {
          "id": "3212100007",
          "district_id": "3212100",
          "name": "KAPLONGANLOR"
        },
        {
          "id": "3212100008",
          "district_id": "3212100",
          "name": "TANJUNGPURA"
        },
        {
          "id": "3212100009",
          "district_id": "3212100",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3212100010",
          "district_id": "3212100",
          "name": "PRINGGACALA"
        },
        {
          "id": "3212100011",
          "district_id": "3212100",
          "name": "BENDA"
        },
        {
          "id": "3212100012",
          "district_id": "3212100",
          "name": "SENDANG"
        },
        {
          "id": "3212100013",
          "district_id": "3212100",
          "name": "KARANGAMPEL KIDUL"
        },
        {
          "id": "3212100014",
          "district_id": "3212100",
          "name": "KARANGAMPEL"
        },
        {
          "id": "3212100016",
          "district_id": "3212100",
          "name": "DUKUHTENGAH"
        },
        {
          "id": "3212101001",
          "district_id": "3212101",
          "name": "KEDOKANBUNDER WETAN"
        },
        {
          "id": "3212101002",
          "district_id": "3212101",
          "name": "KAPLONGAN"
        },
        {
          "id": "3212101003",
          "district_id": "3212101",
          "name": "KEDOKAN AGUNG"
        },
        {
          "id": "3212101004",
          "district_id": "3212101",
          "name": "KEDOKANBUNDER"
        },
        {
          "id": "3212101005",
          "district_id": "3212101",
          "name": "JAYAWINANGUN"
        },
        {
          "id": "3212101006",
          "district_id": "3212101",
          "name": "CANGKINGAN"
        },
        {
          "id": "3212101007",
          "district_id": "3212101",
          "name": "JAYALAKSANA"
        },
        {
          "id": "3212110001",
          "district_id": "3212110",
          "name": "SEGERAN KIDUL"
        },
        {
          "id": "3212110002",
          "district_id": "3212110",
          "name": "SEGERAN"
        },
        {
          "id": "3212110003",
          "district_id": "3212110",
          "name": "JUNTIWEDEN"
        },
        {
          "id": "3212110004",
          "district_id": "3212110",
          "name": "JUNTIKEBON"
        },
        {
          "id": "3212110005",
          "district_id": "3212110",
          "name": "DADAP"
        },
        {
          "id": "3212110006",
          "district_id": "3212110",
          "name": "JUNTINYUAT"
        },
        {
          "id": "3212110007",
          "district_id": "3212110",
          "name": "JUNTIKEDOKAN"
        },
        {
          "id": "3212110008",
          "district_id": "3212110",
          "name": "PONDOH"
        },
        {
          "id": "3212110009",
          "district_id": "3212110",
          "name": "SAMBIMAYA"
        },
        {
          "id": "3212110010",
          "district_id": "3212110",
          "name": "TINUMPUK"
        },
        {
          "id": "3212110011",
          "district_id": "3212110",
          "name": "LOMBANG"
        },
        {
          "id": "3212110012",
          "district_id": "3212110",
          "name": "LIMBANGAN"
        },
        {
          "id": "3212120001",
          "district_id": "3212120",
          "name": "SLEMAN"
        },
        {
          "id": "3212120002",
          "district_id": "3212120",
          "name": "TAMBI"
        },
        {
          "id": "3212120003",
          "district_id": "3212120",
          "name": "SUDIKAMPIRAN"
        },
        {
          "id": "3212120004",
          "district_id": "3212120",
          "name": "TAMBI LOR"
        },
        {
          "id": "3212120005",
          "district_id": "3212120",
          "name": "SLEMAN LOR"
        },
        {
          "id": "3212120006",
          "district_id": "3212120",
          "name": "MAJASARI"
        },
        {
          "id": "3212120007",
          "district_id": "3212120",
          "name": "MAJASIH"
        },
        {
          "id": "3212120008",
          "district_id": "3212120",
          "name": "SLIYEG"
        },
        {
          "id": "3212120009",
          "district_id": "3212120",
          "name": "GADINGAN"
        },
        {
          "id": "3212120010",
          "district_id": "3212120",
          "name": "MEKARGADING"
        },
        {
          "id": "3212120011",
          "district_id": "3212120",
          "name": "SLIYEGLOR"
        },
        {
          "id": "3212120012",
          "district_id": "3212120",
          "name": "TUGU KIDUL"
        },
        {
          "id": "3212120016",
          "district_id": "3212120",
          "name": "LONGOK"
        },
        {
          "id": "3212130001",
          "district_id": "3212130",
          "name": "SUKALILA"
        },
        {
          "id": "3212130002",
          "district_id": "3212130",
          "name": "PILANGSARI"
        },
        {
          "id": "3212130003",
          "district_id": "3212130",
          "name": "JATIBARANG BARU"
        },
        {
          "id": "3212130004",
          "district_id": "3212130",
          "name": "BULAK"
        },
        {
          "id": "3212130005",
          "district_id": "3212130",
          "name": "BULAK LOR"
        },
        {
          "id": "3212130006",
          "district_id": "3212130",
          "name": "JATIBARANG"
        },
        {
          "id": "3212130008",
          "district_id": "3212130",
          "name": "PAWIDEAN"
        },
        {
          "id": "3212130009",
          "district_id": "3212130",
          "name": "JATISAWIT"
        },
        {
          "id": "3212130010",
          "district_id": "3212130",
          "name": "JATISAWIT LOR"
        },
        {
          "id": "3212130011",
          "district_id": "3212130",
          "name": "KRASAK"
        },
        {
          "id": "3212130012",
          "district_id": "3212130",
          "name": "KALIMATI"
        },
        {
          "id": "3212130013",
          "district_id": "3212130",
          "name": "MALANGSEMIRANG"
        },
        {
          "id": "3212130014",
          "district_id": "3212130",
          "name": "LOBENER"
        },
        {
          "id": "3212130015",
          "district_id": "3212130",
          "name": "LOBENER LOR"
        },
        {
          "id": "3212140001",
          "district_id": "3212140",
          "name": "TEGALSEMBADRA"
        },
        {
          "id": "3212140002",
          "district_id": "3212140",
          "name": "SUKAREJA"
        },
        {
          "id": "3212140003",
          "district_id": "3212140",
          "name": "SUKAURIP"
        },
        {
          "id": "3212140004",
          "district_id": "3212140",
          "name": "RAWADALEM"
        },
        {
          "id": "3212140005",
          "district_id": "3212140",
          "name": "GELARMENDALA"
        },
        {
          "id": "3212140006",
          "district_id": "3212140",
          "name": "TEGALURUNG"
        },
        {
          "id": "3212140007",
          "district_id": "3212140",
          "name": "BALONGAN"
        },
        {
          "id": "3212140008",
          "district_id": "3212140",
          "name": "SUDIMAMPIR"
        },
        {
          "id": "3212140009",
          "district_id": "3212140",
          "name": "SUDIMAMPIRLOR"
        },
        {
          "id": "3212140010",
          "district_id": "3212140",
          "name": "MAJAKERTA"
        },
        {
          "id": "3212150001",
          "district_id": "3212150",
          "name": "TELUKAGUNG"
        },
        {
          "id": "3212150002",
          "district_id": "3212150",
          "name": "PLUMBON"
        },
        {
          "id": "3212150004",
          "district_id": "3212150",
          "name": "PEKANDANGAN JAYA"
        },
        {
          "id": "3212150005",
          "district_id": "3212150",
          "name": "SINGARAJA"
        },
        {
          "id": "3212150006",
          "district_id": "3212150",
          "name": "SINGAJAYA"
        },
        {
          "id": "3212150007",
          "district_id": "3212150",
          "name": "PEKANDANGAN"
        },
        {
          "id": "3212150008",
          "district_id": "3212150",
          "name": "BOJONGSARI"
        },
        {
          "id": "3212150009",
          "district_id": "3212150",
          "name": "KEPANDEAN"
        },
        {
          "id": "3212150010",
          "district_id": "3212150",
          "name": "KARANGMALANG"
        },
        {
          "id": "3212150011",
          "district_id": "3212150",
          "name": "KARANGANYAR"
        },
        {
          "id": "3212150012",
          "district_id": "3212150",
          "name": "LEMAHMEKAR"
        },
        {
          "id": "3212150013",
          "district_id": "3212150",
          "name": "LEMAHABANG"
        },
        {
          "id": "3212150014",
          "district_id": "3212150",
          "name": "MARGADADI"
        },
        {
          "id": "3212150015",
          "district_id": "3212150",
          "name": "PAOMAN"
        },
        {
          "id": "3212150016",
          "district_id": "3212150",
          "name": "KARANGSONG"
        },
        {
          "id": "3212150017",
          "district_id": "3212150",
          "name": "PABEANUDIK"
        },
        {
          "id": "3212150018",
          "district_id": "3212150",
          "name": "TAMBAK"
        },
        {
          "id": "3212160007",
          "district_id": "3212160",
          "name": "PANYINDANGAN KULON"
        },
        {
          "id": "3212160008",
          "district_id": "3212160",
          "name": "RAMBATAN WETAN"
        },
        {
          "id": "3212160009",
          "district_id": "3212160",
          "name": "PANYINDANGAN WETAN"
        },
        {
          "id": "3212160010",
          "district_id": "3212160",
          "name": "KENANGA"
        },
        {
          "id": "3212160011",
          "district_id": "3212160",
          "name": "TERUSAN"
        },
        {
          "id": "3212160012",
          "district_id": "3212160",
          "name": "DERMAYU"
        },
        {
          "id": "3212160013",
          "district_id": "3212160",
          "name": "SINDANG"
        },
        {
          "id": "3212160014",
          "district_id": "3212160",
          "name": "PENGANJANG"
        },
        {
          "id": "3212160015",
          "district_id": "3212160",
          "name": "BABADAN"
        },
        {
          "id": "3212160016",
          "district_id": "3212160",
          "name": "WANANTARA"
        },
        {
          "id": "3212161001",
          "district_id": "3212161",
          "name": "CANGKRING"
        },
        {
          "id": "3212161002",
          "district_id": "3212161",
          "name": "CANTIGI KULON"
        },
        {
          "id": "3212161003",
          "district_id": "3212161",
          "name": "CANTIGI WETAN"
        },
        {
          "id": "3212161004",
          "district_id": "3212161",
          "name": "PANYINGKIRAN LOR"
        },
        {
          "id": "3212161005",
          "district_id": "3212161",
          "name": "PANYINGKIRAN KIDUL"
        },
        {
          "id": "3212161006",
          "district_id": "3212161",
          "name": "LAMARANTARUNG"
        },
        {
          "id": "3212161007",
          "district_id": "3212161",
          "name": "CEMARA"
        },
        {
          "id": "3212162001",
          "district_id": "3212162",
          "name": "PAGIRIKAN"
        },
        {
          "id": "3212162002",
          "district_id": "3212162",
          "name": "PASEKAN"
        },
        {
          "id": "3212162003",
          "district_id": "3212162",
          "name": "BRONDONG"
        },
        {
          "id": "3212162004",
          "district_id": "3212162",
          "name": "PABEANILIR"
        },
        {
          "id": "3212162005",
          "district_id": "3212162",
          "name": "TOTORAN"
        },
        {
          "id": "3212162006",
          "district_id": "3212162",
          "name": "KARANGANYAR"
        },
        {
          "id": "3212170001",
          "district_id": "3212170",
          "name": "KIAJARAN KULON"
        },
        {
          "id": "3212170002",
          "district_id": "3212170",
          "name": "KIJARAN WETAN"
        },
        {
          "id": "3212170003",
          "district_id": "3212170",
          "name": "LANJAN"
        },
        {
          "id": "3212170004",
          "district_id": "3212170",
          "name": "LANGUT"
        },
        {
          "id": "3212170005",
          "district_id": "3212170",
          "name": "LARANGAN"
        },
        {
          "id": "3212170006",
          "district_id": "3212170",
          "name": "WARU"
        },
        {
          "id": "3212170007",
          "district_id": "3212170",
          "name": "LEGOK"
        },
        {
          "id": "3212170008",
          "district_id": "3212170",
          "name": "BOJONGSLAWI"
        },
        {
          "id": "3212170009",
          "district_id": "3212170",
          "name": "LOHBENER"
        },
        {
          "id": "3212170010",
          "district_id": "3212170",
          "name": "PAMAYAHAN"
        },
        {
          "id": "3212170011",
          "district_id": "3212170",
          "name": "SINDANGKERTA"
        },
        {
          "id": "3212170015",
          "district_id": "3212170",
          "name": "RAMBATAN KULON"
        },
        {
          "id": "3212171001",
          "district_id": "3212171",
          "name": "SUKASARI"
        },
        {
          "id": "3212171002",
          "district_id": "3212171",
          "name": "ARAHAN KIDUL"
        },
        {
          "id": "3212171003",
          "district_id": "3212171",
          "name": "ARAHAN LOR"
        },
        {
          "id": "3212171004",
          "district_id": "3212171",
          "name": "LINGGAJATI"
        },
        {
          "id": "3212171005",
          "district_id": "3212171",
          "name": "TAWANGSARI"
        },
        {
          "id": "3212171006",
          "district_id": "3212171",
          "name": "SUKADADI"
        },
        {
          "id": "3212171007",
          "district_id": "3212171",
          "name": "PRANGGONG"
        },
        {
          "id": "3212171008",
          "district_id": "3212171",
          "name": "CIDEMPET"
        },
        {
          "id": "3212180001",
          "district_id": "3212180",
          "name": "RANJENG"
        },
        {
          "id": "3212180002",
          "district_id": "3212180",
          "name": "KRIMUN"
        },
        {
          "id": "3212180003",
          "district_id": "3212180",
          "name": "PUNTANG"
        },
        {
          "id": "3212180004",
          "district_id": "3212180",
          "name": "PEGAGAN"
        },
        {
          "id": "3212180005",
          "district_id": "3212180",
          "name": "RAJAIYANG"
        },
        {
          "id": "3212180006",
          "district_id": "3212180",
          "name": "JANGGA"
        },
        {
          "id": "3212180008",
          "district_id": "3212180",
          "name": "PANGKALAN"
        },
        {
          "id": "3212180010",
          "district_id": "3212180",
          "name": "LOSARANG"
        },
        {
          "id": "3212180012",
          "district_id": "3212180",
          "name": "SANTING"
        },
        {
          "id": "3212180013",
          "district_id": "3212180",
          "name": "CEMARA KULON"
        },
        {
          "id": "3212190002",
          "district_id": "3212190",
          "name": "PRANTI"
        },
        {
          "id": "3212190003",
          "district_id": "3212190",
          "name": "WIRAKANAN"
        },
        {
          "id": "3212190004",
          "district_id": "3212190",
          "name": "KARANGMULYA"
        },
        {
          "id": "3212190005",
          "district_id": "3212190",
          "name": "KARANGANYAR"
        },
        {
          "id": "3212190006",
          "district_id": "3212190",
          "name": "WIRAPANJUNAN"
        },
        {
          "id": "3212190007",
          "district_id": "3212190",
          "name": "PAREAN GIRANG"
        },
        {
          "id": "3212190008",
          "district_id": "3212190",
          "name": "BULAK"
        },
        {
          "id": "3212190009",
          "district_id": "3212190",
          "name": "ILIR"
        },
        {
          "id": "3212190010",
          "district_id": "3212190",
          "name": "SOGE"
        },
        {
          "id": "3212190011",
          "district_id": "3212190",
          "name": "ERETAN WETAN"
        },
        {
          "id": "3212190012",
          "district_id": "3212190",
          "name": "ERETAN KULON"
        },
        {
          "id": "3212190013",
          "district_id": "3212190",
          "name": "KERTAWINANGUN"
        },
        {
          "id": "3212200001",
          "district_id": "3212200",
          "name": "CIPEDANG"
        },
        {
          "id": "3212200002",
          "district_id": "3212200",
          "name": "SIDAMULYA"
        },
        {
          "id": "3212200003",
          "district_id": "3212200",
          "name": "MARGAMULYA"
        },
        {
          "id": "3212200004",
          "district_id": "3212200",
          "name": "KERTAJAYA"
        },
        {
          "id": "3212200005",
          "district_id": "3212200",
          "name": "BONGAS"
        },
        {
          "id": "3212200006",
          "district_id": "3212200",
          "name": "CIPAAT"
        },
        {
          "id": "3212200007",
          "district_id": "3212200",
          "name": "KERTAMULYA"
        },
        {
          "id": "3212200008",
          "district_id": "3212200",
          "name": "PLAWANGAN"
        },
        {
          "id": "3212210001",
          "district_id": "3212210",
          "name": "MANGUNJAYA"
        },
        {
          "id": "3212210002",
          "district_id": "3212210",
          "name": "BUGISTUA"
        },
        {
          "id": "3212210003",
          "district_id": "3212210",
          "name": "BUGIS"
        },
        {
          "id": "3212210004",
          "district_id": "3212210",
          "name": "SALAMDARMA"
        },
        {
          "id": "3212210006",
          "district_id": "3212210",
          "name": "WANGUK"
        },
        {
          "id": "3212210007",
          "district_id": "3212210",
          "name": "LEMPUYANG"
        },
        {
          "id": "3212210008",
          "district_id": "3212210",
          "name": "KOPYAH"
        },
        {
          "id": "3212210009",
          "district_id": "3212210",
          "name": "ANJATAN BARU"
        },
        {
          "id": "3212210010",
          "district_id": "3212210",
          "name": "ANJATAN"
        },
        {
          "id": "3212210011",
          "district_id": "3212210",
          "name": "CILANDAK"
        },
        {
          "id": "3212210012",
          "district_id": "3212210",
          "name": "CILANDAK LOR"
        },
        {
          "id": "3212210013",
          "district_id": "3212210",
          "name": "ANJATAN UTARA"
        },
        {
          "id": "3212220001",
          "district_id": "3212220",
          "name": "BOGOR"
        },
        {
          "id": "3212220002",
          "district_id": "3212220",
          "name": "SUKRA"
        },
        {
          "id": "3212220003",
          "district_id": "3212220",
          "name": "UJUNGGEBANG"
        },
        {
          "id": "3212220004",
          "district_id": "3212220",
          "name": "TEGALTAMAN"
        },
        {
          "id": "3212220005",
          "district_id": "3212220",
          "name": "SUKRAWETAN"
        },
        {
          "id": "3212220006",
          "district_id": "3212220",
          "name": "SUMURADEM"
        },
        {
          "id": "3212220007",
          "district_id": "3212220",
          "name": "SUMURADEM TIMUR"
        },
        {
          "id": "3212220008",
          "district_id": "3212220",
          "name": "KARANGLAYUNG"
        },
        {
          "id": "3212221001",
          "district_id": "3212221",
          "name": "LIMPAS"
        },
        {
          "id": "3212221002",
          "district_id": "3212221",
          "name": "PATROL"
        },
        {
          "id": "3212221003",
          "district_id": "3212221",
          "name": "ARJASARI"
        },
        {
          "id": "3212221004",
          "district_id": "3212221",
          "name": "SUKAHAJI"
        },
        {
          "id": "3212221006",
          "district_id": "3212221",
          "name": "PATROLLOR"
        },
        {
          "id": "3212221007",
          "district_id": "3212221",
          "name": "PATROL BARU"
        },
        {
          "id": "3212221008",
          "district_id": "3212221",
          "name": "MEKARSARI"
        },
        {
          "id": "3213010003",
          "district_id": "3213010",
          "name": "SUKAMANDI"
        },
        {
          "id": "3213010004",
          "district_id": "3213010",
          "name": "CICADAS"
        },
        {
          "id": "3213010005",
          "district_id": "3213010",
          "name": "SAGALAHERANG"
        },
        {
          "id": "3213010006",
          "district_id": "3213010",
          "name": "DAYEUHKOLOT"
        },
        {
          "id": "3213010012",
          "district_id": "3213010",
          "name": "SAGALAHERANG KALER"
        },
        {
          "id": "3213010013",
          "district_id": "3213010",
          "name": "CURUGAGUNG"
        },
        {
          "id": "3213011001",
          "district_id": "3213011",
          "name": "CIPANCAR"
        },
        {
          "id": "3213011002",
          "district_id": "3213011",
          "name": "CIKUJANG"
        },
        {
          "id": "3213011003",
          "district_id": "3213011",
          "name": "CIJENGKOL"
        },
        {
          "id": "3213011004",
          "district_id": "3213011",
          "name": "CINTAMEKAR"
        },
        {
          "id": "3213011005",
          "district_id": "3213011",
          "name": "PONGGANG"
        },
        {
          "id": "3213011006",
          "district_id": "3213011",
          "name": "TALAGASARI"
        },
        {
          "id": "3213020008",
          "district_id": "3213020",
          "name": "SARIREJA"
        },
        {
          "id": "3213020013",
          "district_id": "3213020",
          "name": "KUMPAY"
        },
        {
          "id": "3213020014",
          "district_id": "3213020",
          "name": "TAMBAKAN"
        },
        {
          "id": "3213020015",
          "district_id": "3213020",
          "name": "JALANCAGAK"
        },
        {
          "id": "3213020016",
          "district_id": "3213020",
          "name": "BUNIHAYU"
        },
        {
          "id": "3213020017",
          "district_id": "3213020",
          "name": "TAMBAKMEKAR"
        },
        {
          "id": "3213021001",
          "district_id": "3213021",
          "name": "CIATER"
        },
        {
          "id": "3213021002",
          "district_id": "3213021",
          "name": "NAGRAK"
        },
        {
          "id": "3213021003",
          "district_id": "3213021",
          "name": "CIBEUSI"
        },
        {
          "id": "3213021004",
          "district_id": "3213021",
          "name": "CIBITUNG"
        },
        {
          "id": "3213021005",
          "district_id": "3213021",
          "name": "SANCA"
        },
        {
          "id": "3213021006",
          "district_id": "3213021",
          "name": "PALASARI"
        },
        {
          "id": "3213021007",
          "district_id": "3213021",
          "name": "CISAAT"
        },
        {
          "id": "3213030001",
          "district_id": "3213030",
          "name": "CUPUNAGARA"
        },
        {
          "id": "3213030002",
          "district_id": "3213030",
          "name": "CIMANGGU"
        },
        {
          "id": "3213030003",
          "district_id": "3213030",
          "name": "GARDUSAYANG"
        },
        {
          "id": "3213030004",
          "district_id": "3213030",
          "name": "MAYANG"
        },
        {
          "id": "3213030005",
          "district_id": "3213030",
          "name": "SUKAKERTI"
        },
        {
          "id": "3213030008",
          "district_id": "3213030",
          "name": "DARMAGA"
        },
        {
          "id": "3213030009",
          "district_id": "3213030",
          "name": "CISALAK"
        },
        {
          "id": "3213030010",
          "district_id": "3213030",
          "name": "CIGADOG"
        },
        {
          "id": "3213030014",
          "district_id": "3213030",
          "name": "PAKUHAJI"
        },
        {
          "id": "3213031001",
          "district_id": "3213031",
          "name": "PASANGGRAHAN"
        },
        {
          "id": "3213031002",
          "district_id": "3213031",
          "name": "SINDANGSARI"
        },
        {
          "id": "3213031003",
          "district_id": "3213031",
          "name": "BOJONGLOA"
        },
        {
          "id": "3213031004",
          "district_id": "3213031",
          "name": "TENJOLAYA"
        },
        {
          "id": "3213031005",
          "district_id": "3213031",
          "name": "SUKAMELANG"
        },
        {
          "id": "3213031006",
          "district_id": "3213031",
          "name": "KASOMALANG WETAN"
        },
        {
          "id": "3213031007",
          "district_id": "3213031",
          "name": "KASOMALANG KULON"
        },
        {
          "id": "3213031008",
          "district_id": "3213031",
          "name": "CIMANGLID"
        },
        {
          "id": "3213040001",
          "district_id": "3213040",
          "name": "BUNIARA"
        },
        {
          "id": "3213040002",
          "district_id": "3213040",
          "name": "TANJUNGSIANG"
        },
        {
          "id": "3213040003",
          "district_id": "3213040",
          "name": "CIKAWUNG"
        },
        {
          "id": "3213040004",
          "district_id": "3213040",
          "name": "CIMEUHMAL"
        },
        {
          "id": "3213040005",
          "district_id": "3213040",
          "name": "SIRAP"
        },
        {
          "id": "3213040006",
          "district_id": "3213040",
          "name": "KAWUNGLUWUK"
        },
        {
          "id": "3213040008",
          "district_id": "3213040",
          "name": "CIBULUH"
        },
        {
          "id": "3213040009",
          "district_id": "3213040",
          "name": "SINDANGLAYA"
        },
        {
          "id": "3213040010",
          "district_id": "3213040",
          "name": "RANCAMANGGUNG"
        },
        {
          "id": "3213040011",
          "district_id": "3213040",
          "name": "GANDASOLI"
        },
        {
          "id": "3213050001",
          "district_id": "3213050",
          "name": "GUNUNGTUA"
        },
        {
          "id": "3213050002",
          "district_id": "3213050",
          "name": "CIJAMBE"
        },
        {
          "id": "3213050003",
          "district_id": "3213050",
          "name": "CIRANGKONG"
        },
        {
          "id": "3213050004",
          "district_id": "3213050",
          "name": "CIMENTENG"
        },
        {
          "id": "3213050005",
          "district_id": "3213050",
          "name": "CIKADU"
        },
        {
          "id": "3213050006",
          "district_id": "3213050",
          "name": "SUKAHURIP"
        },
        {
          "id": "3213050007",
          "district_id": "3213050",
          "name": "BANTARSARI"
        },
        {
          "id": "3213050008",
          "district_id": "3213050",
          "name": "TANJUNGWANGI"
        },
        {
          "id": "3213060002",
          "district_id": "3213060",
          "name": "SADAWARNA"
        },
        {
          "id": "3213060003",
          "district_id": "3213060",
          "name": "SUMURBARANG"
        },
        {
          "id": "3213060004",
          "district_id": "3213060",
          "name": "PADAASIH"
        },
        {
          "id": "3213060005",
          "district_id": "3213060",
          "name": "CIBOGO"
        },
        {
          "id": "3213060006",
          "district_id": "3213060",
          "name": "CINANGSI"
        },
        {
          "id": "3213060007",
          "district_id": "3213060",
          "name": "MAJASARI"
        },
        {
          "id": "3213060008",
          "district_id": "3213060",
          "name": "CIBALANDONGJAYA"
        },
        {
          "id": "3213060010",
          "district_id": "3213060",
          "name": "CISAGA"
        },
        {
          "id": "3213070001",
          "district_id": "3213070",
          "name": "PARUNG"
        },
        {
          "id": "3213070002",
          "district_id": "3213070",
          "name": "PASIRKAREUMBI"
        },
        {
          "id": "3213070003",
          "district_id": "3213070",
          "name": "SOKLAT"
        },
        {
          "id": "3213070004",
          "district_id": "3213070",
          "name": "KARANGANYAR"
        },
        {
          "id": "3213070005",
          "district_id": "3213070",
          "name": "CIGADUNG"
        },
        {
          "id": "3213070006",
          "district_id": "3213070",
          "name": "DANGDEUR"
        },
        {
          "id": "3213070007",
          "district_id": "3213070",
          "name": "SUKAMELANG"
        },
        {
          "id": "3213070012",
          "district_id": "3213070",
          "name": "WANAREJA"
        },
        {
          "id": "3213080004",
          "district_id": "3213080",
          "name": "TANGGULUN TIMUR"
        },
        {
          "id": "3213080005",
          "district_id": "3213080",
          "name": "TANGGULUN BARAT"
        },
        {
          "id": "3213080006",
          "district_id": "3213080",
          "name": "MARENGMANG"
        },
        {
          "id": "3213080007",
          "district_id": "3213080",
          "name": "KALIJATI BARAT"
        },
        {
          "id": "3213080008",
          "district_id": "3213080",
          "name": "KALIJATI TIMUR"
        },
        {
          "id": "3213080016",
          "district_id": "3213080",
          "name": "KALIANGSANA"
        },
        {
          "id": "3213080017",
          "district_id": "3213080",
          "name": "BANGGALAMULYA"
        },
        {
          "id": "3213080018",
          "district_id": "3213080",
          "name": "JALUPANG"
        },
        {
          "id": "3213080019",
          "district_id": "3213080",
          "name": "CARACAS"
        },
        {
          "id": "3213080020",
          "district_id": "3213080",
          "name": "CIRULUK"
        },
        {
          "id": "3213081001",
          "district_id": "3213081",
          "name": "JAMBELAER"
        },
        {
          "id": "3213081002",
          "district_id": "3213081",
          "name": "MARGASARI"
        },
        {
          "id": "3213081003",
          "district_id": "3213081",
          "name": "CISAMPIH"
        },
        {
          "id": "3213081004",
          "district_id": "3213081",
          "name": "SITUSARI"
        },
        {
          "id": "3213081005",
          "district_id": "3213081",
          "name": "SUKASARI"
        },
        {
          "id": "3213081006",
          "district_id": "3213081",
          "name": "RAWALELE"
        },
        {
          "id": "3213081007",
          "district_id": "3213081",
          "name": "DAWUAN KIDUL"
        },
        {
          "id": "3213081008",
          "district_id": "3213081",
          "name": "DAWUAN KALER"
        },
        {
          "id": "3213081009",
          "district_id": "3213081",
          "name": "MANYETI"
        },
        {
          "id": "3213081010",
          "district_id": "3213081",
          "name": "BATUSARI"
        },
        {
          "id": "3213090003",
          "district_id": "3213090",
          "name": "CIMAYASARI"
        },
        {
          "id": "3213090004",
          "district_id": "3213090",
          "name": "LENGKONG"
        },
        {
          "id": "3213090005",
          "district_id": "3213090",
          "name": "CIPEUNDEUY"
        },
        {
          "id": "3213090006",
          "district_id": "3213090",
          "name": "WANTILAN"
        },
        {
          "id": "3213090007",
          "district_id": "3213090",
          "name": "SAWANGAN"
        },
        {
          "id": "3213090008",
          "district_id": "3213090",
          "name": "KARANGMUKTI"
        },
        {
          "id": "3213090009",
          "district_id": "3213090",
          "name": "KOSAR"
        },
        {
          "id": "3213100002",
          "district_id": "3213100",
          "name": "CIHAMBULU"
        },
        {
          "id": "3213100003",
          "district_id": "3213100",
          "name": "SILUMAN"
        },
        {
          "id": "3213100004",
          "district_id": "3213100",
          "name": "PABUARAN"
        },
        {
          "id": "3213100005",
          "district_id": "3213100",
          "name": "SALAMJAYA"
        },
        {
          "id": "3213100006",
          "district_id": "3213100",
          "name": "KADAWUNG"
        },
        {
          "id": "3213100007",
          "district_id": "3213100",
          "name": "PRINGKASAP"
        },
        {
          "id": "3213100008",
          "district_id": "3213100",
          "name": "KARANGHEGAR"
        },
        {
          "id": "3213100011",
          "district_id": "3213100",
          "name": "BALEBANDUNG JAYA"
        },
        {
          "id": "3213110001",
          "district_id": "3213110",
          "name": "TANJUNGRASA KIDUL"
        },
        {
          "id": "3213110002",
          "district_id": "3213110",
          "name": "TANJUNGRASA"
        },
        {
          "id": "3213110003",
          "district_id": "3213110",
          "name": "JATIRAGAS HILIR"
        },
        {
          "id": "3213110004",
          "district_id": "3213110",
          "name": "TAMBAKJATI"
        },
        {
          "id": "3213110005",
          "district_id": "3213110",
          "name": "CIBERES"
        },
        {
          "id": "3213110006",
          "district_id": "3213110",
          "name": "GEMPOLSARI"
        },
        {
          "id": "3213110007",
          "district_id": "3213110",
          "name": "RANCAMULYA"
        },
        {
          "id": "3213110008",
          "district_id": "3213110",
          "name": "RANCAJAYA"
        },
        {
          "id": "3213110009",
          "district_id": "3213110",
          "name": "RANCABANGO"
        },
        {
          "id": "3213110010",
          "district_id": "3213110",
          "name": "RANCAASIH"
        },
        {
          "id": "3213120001",
          "district_id": "3213120",
          "name": "KORANJI"
        },
        {
          "id": "3213120003",
          "district_id": "3213120",
          "name": "WANAKERTA"
        },
        {
          "id": "3213120005",
          "district_id": "3213120",
          "name": "PAGON"
        },
        {
          "id": "3213120006",
          "district_id": "3213120",
          "name": "PARAPATAN"
        },
        {
          "id": "3213120007",
          "district_id": "3213120",
          "name": "PURWADADI TIMUR"
        },
        {
          "id": "3213120008",
          "district_id": "3213120",
          "name": "PURWADADI BARAT"
        },
        {
          "id": "3213120009",
          "district_id": "3213120",
          "name": "PASIRBUNGUR"
        },
        {
          "id": "3213120010",
          "district_id": "3213120",
          "name": "RANCAMAHI"
        },
        {
          "id": "3213120011",
          "district_id": "3213120",
          "name": "PANYINGKIRAN"
        },
        {
          "id": "3213130001",
          "district_id": "3213130",
          "name": "KAUNGANTEN"
        },
        {
          "id": "3213130002",
          "district_id": "3213130",
          "name": "SINDANGSARI"
        },
        {
          "id": "3213130003",
          "district_id": "3213130",
          "name": "CIKAUM TIMUR"
        },
        {
          "id": "3213130004",
          "district_id": "3213130",
          "name": "CIKAUM BARAT"
        },
        {
          "id": "3213130005",
          "district_id": "3213130",
          "name": "PASIRMUNCANG"
        },
        {
          "id": "3213130006",
          "district_id": "3213130",
          "name": "GANDASARI"
        },
        {
          "id": "3213130007",
          "district_id": "3213130",
          "name": "MEKARSARI"
        },
        {
          "id": "3213130008",
          "district_id": "3213130",
          "name": "TANJUNGSARI BARAT"
        },
        {
          "id": "3213130009",
          "district_id": "3213130",
          "name": "TANJUNGSARI TIMUR"
        },
        {
          "id": "3213140005",
          "district_id": "3213140",
          "name": "GEMBOR"
        },
        {
          "id": "3213140006",
          "district_id": "3213140",
          "name": "GUNUNGSARI"
        },
        {
          "id": "3213140007",
          "district_id": "3213140",
          "name": "SUKAMULYA"
        },
        {
          "id": "3213140013",
          "district_id": "3213140",
          "name": "SUMBERSARI"
        },
        {
          "id": "3213140014",
          "district_id": "3213140",
          "name": "GAMBARSARI"
        },
        {
          "id": "3213140015",
          "district_id": "3213140",
          "name": "NEGLASARI"
        },
        {
          "id": "3213140016",
          "district_id": "3213140",
          "name": "PAGADEN"
        },
        {
          "id": "3213140017",
          "district_id": "3213140",
          "name": "KAMARUNG"
        },
        {
          "id": "3213140018",
          "district_id": "3213140",
          "name": "JABONG"
        },
        {
          "id": "3213141001",
          "district_id": "3213141",
          "name": "BALINGBING"
        },
        {
          "id": "3213141002",
          "district_id": "3213141",
          "name": "CIDADAP"
        },
        {
          "id": "3213141003",
          "district_id": "3213141",
          "name": "CIDAHU"
        },
        {
          "id": "3213141004",
          "district_id": "3213141",
          "name": "PANGSOR"
        },
        {
          "id": "3213141006",
          "district_id": "3213141",
          "name": "MARGAHAYU"
        },
        {
          "id": "3213141007",
          "district_id": "3213141",
          "name": "BENDUNGAN"
        },
        {
          "id": "3213141008",
          "district_id": "3213141",
          "name": "MEKARWANGI"
        },
        {
          "id": "3213141009",
          "district_id": "3213141",
          "name": "SUMURGINTUNG"
        },
        {
          "id": "3213150001",
          "district_id": "3213150",
          "name": "WANASARI"
        },
        {
          "id": "3213150002",
          "district_id": "3213150",
          "name": "SIDAMULYA"
        },
        {
          "id": "3213150003",
          "district_id": "3213150",
          "name": "SIDAJAYA"
        },
        {
          "id": "3213150004",
          "district_id": "3213150",
          "name": "TANJUNG"
        },
        {
          "id": "3213150005",
          "district_id": "3213150",
          "name": "PARIGIMULYA"
        },
        {
          "id": "3213150006",
          "district_id": "3213150",
          "name": "MANYINGSAL"
        },
        {
          "id": "3213150007",
          "district_id": "3213150",
          "name": "PADAMULYA"
        },
        {
          "id": "3213150008",
          "district_id": "3213150",
          "name": "JATI"
        },
        {
          "id": "3213150009",
          "district_id": "3213150",
          "name": "KOSAMBI"
        },
        {
          "id": "3213150010",
          "district_id": "3213150",
          "name": "SIMPAR"
        },
        {
          "id": "3213160001",
          "district_id": "3213160",
          "name": "SUKADANA"
        },
        {
          "id": "3213160002",
          "district_id": "3213160",
          "name": "SUKATANI"
        },
        {
          "id": "3213160003",
          "district_id": "3213160",
          "name": "KIARASARI"
        },
        {
          "id": "3213160004",
          "district_id": "3213160",
          "name": "JATIREJA"
        },
        {
          "id": "3213160005",
          "district_id": "3213160",
          "name": "MEKARJAYA"
        },
        {
          "id": "3213160006",
          "district_id": "3213160",
          "name": "KALENSARI"
        },
        {
          "id": "3213160007",
          "district_id": "3213160",
          "name": "COMPRENG"
        },
        {
          "id": "3213160008",
          "district_id": "3213160",
          "name": "JATIMULYA"
        },
        {
          "id": "3213170001",
          "district_id": "3213170",
          "name": "NANGERANG"
        },
        {
          "id": "3213170002",
          "district_id": "3213170",
          "name": "CICADAS"
        },
        {
          "id": "3213170003",
          "district_id": "3213170",
          "name": "KIHIYANG"
        },
        {
          "id": "3213170004",
          "district_id": "3213170",
          "name": "KARANGSARI"
        },
        {
          "id": "3213170005",
          "district_id": "3213170",
          "name": "CITRAJAYA"
        },
        {
          "id": "3213170006",
          "district_id": "3213170",
          "name": "BINONG"
        },
        {
          "id": "3213170007",
          "district_id": "3213170",
          "name": "KEDIRI"
        },
        {
          "id": "3213170008",
          "district_id": "3213170",
          "name": "MULYASARI"
        },
        {
          "id": "3213170018",
          "district_id": "3213170",
          "name": "KARANGWANGI"
        },
        {
          "id": "3213171001",
          "district_id": "3213171",
          "name": "TANJUNGRASA"
        },
        {
          "id": "3213171002",
          "district_id": "3213171",
          "name": "WANAJAYA"
        },
        {
          "id": "3213171003",
          "district_id": "3213171",
          "name": "GARDUMUKTI"
        },
        {
          "id": "3213171004",
          "district_id": "3213171",
          "name": "MARIUK"
        },
        {
          "id": "3213171005",
          "district_id": "3213171",
          "name": "KERTAJAYA"
        },
        {
          "id": "3213171006",
          "district_id": "3213171",
          "name": "TAMBAKDAHAN"
        },
        {
          "id": "3213171007",
          "district_id": "3213171",
          "name": "BOJONGKEDING"
        },
        {
          "id": "3213171008",
          "district_id": "3213171",
          "name": "BOJONEGARA"
        },
        {
          "id": "3213171009",
          "district_id": "3213171",
          "name": "RANCAUDIK"
        },
        {
          "id": "3213180001",
          "district_id": "3213180",
          "name": "SUKAMANDIJAYA"
        },
        {
          "id": "3213180002",
          "district_id": "3213180",
          "name": "CIASEM TENGAH"
        },
        {
          "id": "3213180004",
          "district_id": "3213180",
          "name": "JATIBARU"
        },
        {
          "id": "3213180006",
          "district_id": "3213180",
          "name": "CIASEM HILIR"
        },
        {
          "id": "3213180007",
          "district_id": "3213180",
          "name": "CIASEM BARU"
        },
        {
          "id": "3213180008",
          "district_id": "3213180",
          "name": "CIASEM GIRANG"
        },
        {
          "id": "3213180009",
          "district_id": "3213180",
          "name": "SUKAHAJI"
        },
        {
          "id": "3213180010",
          "district_id": "3213180",
          "name": "PINANGSARI"
        },
        {
          "id": "3213190006",
          "district_id": "3213190",
          "name": "RANCASARI"
        },
        {
          "id": "3213190007",
          "district_id": "3213190",
          "name": "PAMANUKAN"
        },
        {
          "id": "3213190008",
          "district_id": "3213190",
          "name": "PAMANUKAN HILIR"
        },
        {
          "id": "3213190009",
          "district_id": "3213190",
          "name": "LENGKONGJAYA"
        },
        {
          "id": "3213190010",
          "district_id": "3213190",
          "name": "PAMANUKAN SEBRANG"
        },
        {
          "id": "3213190012",
          "district_id": "3213190",
          "name": "MULYASARI"
        },
        {
          "id": "3213190013",
          "district_id": "3213190",
          "name": "RANCAHILIR"
        },
        {
          "id": "3213190014",
          "district_id": "3213190",
          "name": "BONGAS"
        },
        {
          "id": "3213191001",
          "district_id": "3213191",
          "name": "MANDALAWANGI"
        },
        {
          "id": "3213191002",
          "district_id": "3213191",
          "name": "CURUGREJA"
        },
        {
          "id": "3213191003",
          "district_id": "3213191",
          "name": "BATANGSARI"
        },
        {
          "id": "3213191004",
          "district_id": "3213191",
          "name": "SUKASARI"
        },
        {
          "id": "3213191005",
          "district_id": "3213191",
          "name": "SUKAREJA"
        },
        {
          "id": "3213191006",
          "district_id": "3213191",
          "name": "ANGGASARI"
        },
        {
          "id": "3213191007",
          "district_id": "3213191",
          "name": "SUKAMAJU"
        },
        {
          "id": "3213200009",
          "district_id": "3213200",
          "name": "PUSAKARATU"
        },
        {
          "id": "3213200010",
          "district_id": "3213200",
          "name": "GEMPOL"
        },
        {
          "id": "3213200011",
          "district_id": "3213200",
          "name": "KALENTAMBO"
        },
        {
          "id": "3213200012",
          "district_id": "3213200",
          "name": "KOTASARI"
        },
        {
          "id": "3213200013",
          "district_id": "3213200",
          "name": "RANCADAKA"
        },
        {
          "id": "3213200014",
          "district_id": "3213200",
          "name": "PATIMBAN"
        },
        {
          "id": "3213200015",
          "district_id": "3213200",
          "name": "MUNDUSARI"
        },
        {
          "id": "3213201001",
          "district_id": "3213201",
          "name": "BOJONG TENGAH"
        },
        {
          "id": "3213201002",
          "district_id": "3213201",
          "name": "BOJONGJAYA"
        },
        {
          "id": "3213201003",
          "district_id": "3213201",
          "name": "CIGUGUR"
        },
        {
          "id": "3213201004",
          "district_id": "3213201",
          "name": "CIGUGUR KALER"
        },
        {
          "id": "3213201005",
          "district_id": "3213201",
          "name": "RANGDU"
        },
        {
          "id": "3213201006",
          "district_id": "3213201",
          "name": "KARANGANYAR"
        },
        {
          "id": "3213201007",
          "district_id": "3213201",
          "name": "KEBONDANAS"
        },
        {
          "id": "3213201008",
          "district_id": "3213201",
          "name": "PUSAKAJAYA"
        },
        {
          "id": "3213210002",
          "district_id": "3213210",
          "name": "TEGALURUNG"
        },
        {
          "id": "3213210003",
          "district_id": "3213210",
          "name": "MAYANGAN"
        },
        {
          "id": "3213210004",
          "district_id": "3213210",
          "name": "LEGONWETAN"
        },
        {
          "id": "3213210005",
          "district_id": "3213210",
          "name": "LEGONKULON"
        },
        {
          "id": "3213210006",
          "district_id": "3213210",
          "name": "PANGARENGAN"
        },
        {
          "id": "3213210007",
          "district_id": "3213210",
          "name": "BOBOS"
        },
        {
          "id": "3213210008",
          "district_id": "3213210",
          "name": "KARANGMULYA"
        },
        {
          "id": "3213220001",
          "district_id": "3213220",
          "name": "CILAMAYA HILIR"
        },
        {
          "id": "3213220002",
          "district_id": "3213220",
          "name": "CILAMAYA GIRANG"
        },
        {
          "id": "3213220003",
          "district_id": "3213220",
          "name": "RAWAMEKAR"
        },
        {
          "id": "3213220004",
          "district_id": "3213220",
          "name": "RAWAMENENG"
        },
        {
          "id": "3213220005",
          "district_id": "3213220",
          "name": "JAYAMUKTI"
        },
        {
          "id": "3213220006",
          "district_id": "3213220",
          "name": "BLANAKAN"
        },
        {
          "id": "3213220007",
          "district_id": "3213220",
          "name": "LANGENSARI"
        },
        {
          "id": "3213220008",
          "district_id": "3213220",
          "name": "MUARA"
        },
        {
          "id": "3213220009",
          "district_id": "3213220",
          "name": "TANJUNGTIGA"
        },
        {
          "id": "3214010006",
          "district_id": "3214010",
          "name": "JATIMEKAR"
        },
        {
          "id": "3214010007",
          "district_id": "3214010",
          "name": "CIKAOBANDUNG"
        },
        {
          "id": "3214010008",
          "district_id": "3214010",
          "name": "JATILUHUR"
        },
        {
          "id": "3214010009",
          "district_id": "3214010",
          "name": "CILEGONG"
        },
        {
          "id": "3214010010",
          "district_id": "3214010",
          "name": "KEMBANGKUNING"
        },
        {
          "id": "3214010011",
          "district_id": "3214010",
          "name": "CIBINONG"
        },
        {
          "id": "3214010015",
          "district_id": "3214010",
          "name": "PARAKANLIMA"
        },
        {
          "id": "3214010016",
          "district_id": "3214010",
          "name": "CISALADA"
        },
        {
          "id": "3214010017",
          "district_id": "3214010",
          "name": "MEKARGALIH"
        },
        {
          "id": "3214011001",
          "district_id": "3214011",
          "name": "PARUNGBANTENG"
        },
        {
          "id": "3214011002",
          "district_id": "3214011",
          "name": "SUKASARI"
        },
        {
          "id": "3214011003",
          "district_id": "3214011",
          "name": "CIRIRIP"
        },
        {
          "id": "3214011004",
          "district_id": "3214011",
          "name": "KERTAMANAH"
        },
        {
          "id": "3214011005",
          "district_id": "3214011",
          "name": "KUTAMANAH"
        },
        {
          "id": "3214020001",
          "district_id": "3214020",
          "name": "TEGALDATAR"
        },
        {
          "id": "3214020002",
          "district_id": "3214020",
          "name": "SINARGALIH"
        },
        {
          "id": "3214020003",
          "district_id": "3214020",
          "name": "CITAMIANG"
        },
        {
          "id": "3214020004",
          "district_id": "3214020",
          "name": "CIJATI"
        },
        {
          "id": "3214020005",
          "district_id": "3214020",
          "name": "GUNUNGKARUNG"
        },
        {
          "id": "3214020006",
          "district_id": "3214020",
          "name": "PASIRJAMBU"
        },
        {
          "id": "3214020007",
          "district_id": "3214020",
          "name": "CIRAMAHILIR"
        },
        {
          "id": "3214020008",
          "district_id": "3214020",
          "name": "SUKAMUKTI"
        },
        {
          "id": "3214030001",
          "district_id": "3214030",
          "name": "SUKAHAJI"
        },
        {
          "id": "3214030002",
          "district_id": "3214030",
          "name": "KAROYA"
        },
        {
          "id": "3214030003",
          "district_id": "3214030",
          "name": "CADASSARI"
        },
        {
          "id": "3214030004",
          "district_id": "3214030",
          "name": "CADASMEKAR"
        },
        {
          "id": "3214030005",
          "district_id": "3214030",
          "name": "CITALANG"
        },
        {
          "id": "3214030006",
          "district_id": "3214030",
          "name": "BATUTUMPANG"
        },
        {
          "id": "3214030007",
          "district_id": "3214030",
          "name": "TEGALWARU"
        },
        {
          "id": "3214030008",
          "district_id": "3214030",
          "name": "TEGALSARI"
        },
        {
          "id": "3214030009",
          "district_id": "3214030",
          "name": "WARUNGJERUK"
        },
        {
          "id": "3214030010",
          "district_id": "3214030",
          "name": "GALUMPIT"
        },
        {
          "id": "3214030011",
          "district_id": "3214030",
          "name": "CISARUA"
        },
        {
          "id": "3214030012",
          "district_id": "3214030",
          "name": "SUKAMULYA"
        },
        {
          "id": "3214030013",
          "district_id": "3214030",
          "name": "PASANGGRAHAN"
        },
        {
          "id": "3214040001",
          "district_id": "3214040",
          "name": "RAWASARI"
        },
        {
          "id": "3214040002",
          "district_id": "3214040",
          "name": "GANDASOLI"
        },
        {
          "id": "3214040003",
          "district_id": "3214040",
          "name": "GANDAMEKAR"
        },
        {
          "id": "3214040004",
          "district_id": "3214040",
          "name": "CIBOGOHILIR"
        },
        {
          "id": "3214040005",
          "district_id": "3214040",
          "name": "PALINGGIHAN"
        },
        {
          "id": "3214040006",
          "district_id": "3214040",
          "name": "BABAKAN SARI"
        },
        {
          "id": "3214040008",
          "district_id": "3214040",
          "name": "SINDANGSARI"
        },
        {
          "id": "3214040009",
          "district_id": "3214040",
          "name": "CITEKO"
        },
        {
          "id": "3214040010",
          "district_id": "3214040",
          "name": "CITEKOKALER"
        },
        {
          "id": "3214040011",
          "district_id": "3214040",
          "name": "LINGGARSARI"
        },
        {
          "id": "3214040012",
          "district_id": "3214040",
          "name": "PAMOYANAN"
        },
        {
          "id": "3214040013",
          "district_id": "3214040",
          "name": "LIUNGGUNUNG"
        },
        {
          "id": "3214040014",
          "district_id": "3214040",
          "name": "ANJUN"
        },
        {
          "id": "3214040015",
          "district_id": "3214040",
          "name": "CIBOGO GIRANG"
        },
        {
          "id": "3214050002",
          "district_id": "3214050",
          "name": "CIANTING"
        },
        {
          "id": "3214050003",
          "district_id": "3214050",
          "name": "PASIRMUNJUL"
        },
        {
          "id": "3214050004",
          "district_id": "3214050",
          "name": "CIBODAS"
        },
        {
          "id": "3214050005",
          "district_id": "3214050",
          "name": "CIANTING UTARA"
        },
        {
          "id": "3214050006",
          "district_id": "3214050",
          "name": "SUKATANI"
        },
        {
          "id": "3214050007",
          "district_id": "3214050",
          "name": "MALANGNENGAH"
        },
        {
          "id": "3214050008",
          "district_id": "3214050",
          "name": "CILALAWI"
        },
        {
          "id": "3214050009",
          "district_id": "3214050",
          "name": "SUKAMAJU"
        },
        {
          "id": "3214050010",
          "district_id": "3214050",
          "name": "CIPICUNG"
        },
        {
          "id": "3214050011",
          "district_id": "3214050",
          "name": "TAJURSINDANG"
        },
        {
          "id": "3214050012",
          "district_id": "3214050",
          "name": "SINDANGLAYA"
        },
        {
          "id": "3214050013",
          "district_id": "3214050",
          "name": "PANYINDANGAN"
        },
        {
          "id": "3214050014",
          "district_id": "3214050",
          "name": "SUKAJAYA"
        },
        {
          "id": "3214050015",
          "district_id": "3214050",
          "name": "CIJANTUNG"
        },
        {
          "id": "3214060001",
          "district_id": "3214060",
          "name": "PASIRANGIN"
        },
        {
          "id": "3214060002",
          "district_id": "3214060",
          "name": "NANGEWER"
        },
        {
          "id": "3214060003",
          "district_id": "3214060",
          "name": "NEGLASARI"
        },
        {
          "id": "3214060004",
          "district_id": "3214060",
          "name": "LINGGASARI"
        },
        {
          "id": "3214060005",
          "district_id": "3214060",
          "name": "SAWIT"
        },
        {
          "id": "3214060006",
          "district_id": "3214060",
          "name": "SIRNAMANAH"
        },
        {
          "id": "3214060007",
          "district_id": "3214060",
          "name": "DEPOK"
        },
        {
          "id": "3214060008",
          "district_id": "3214060",
          "name": "LEGOKSARI"
        },
        {
          "id": "3214060009",
          "district_id": "3214060",
          "name": "MEKARSARI"
        },
        {
          "id": "3214060012",
          "district_id": "3214060",
          "name": "GUNUNGHEJO"
        },
        {
          "id": "3214060013",
          "district_id": "3214060",
          "name": "DARANGDAN"
        },
        {
          "id": "3214060014",
          "district_id": "3214060",
          "name": "SADARKARYA"
        },
        {
          "id": "3214060015",
          "district_id": "3214060",
          "name": "LINGGAMUKTI"
        },
        {
          "id": "3214060016",
          "district_id": "3214060",
          "name": "CILINGGA"
        },
        {
          "id": "3214060017",
          "district_id": "3214060",
          "name": "NAGRAK"
        },
        {
          "id": "3214070001",
          "district_id": "3214070",
          "name": "CIBINGBIN"
        },
        {
          "id": "3214070002",
          "district_id": "3214070",
          "name": "BOJONG TIMUR"
        },
        {
          "id": "3214070003",
          "district_id": "3214070",
          "name": "PASANGGRAHAN"
        },
        {
          "id": "3214070004",
          "district_id": "3214070",
          "name": "CIHANJAWAR"
        },
        {
          "id": "3214070005",
          "district_id": "3214070",
          "name": "CIKERIS"
        },
        {
          "id": "3214070006",
          "district_id": "3214070",
          "name": "BOJONG BARAT"
        },
        {
          "id": "3214070007",
          "district_id": "3214070",
          "name": "PANGKALAN"
        },
        {
          "id": "3214070008",
          "district_id": "3214070",
          "name": "SUKAMANAH"
        },
        {
          "id": "3214070009",
          "district_id": "3214070",
          "name": "PAWENANG"
        },
        {
          "id": "3214070010",
          "district_id": "3214070",
          "name": "SINDANGSARI"
        },
        {
          "id": "3214070011",
          "district_id": "3214070",
          "name": "SINDANGPANON"
        },
        {
          "id": "3214070012",
          "district_id": "3214070",
          "name": "CIPEUNDEUY"
        },
        {
          "id": "3214070013",
          "district_id": "3214070",
          "name": "CILEUNCA"
        },
        {
          "id": "3214070014",
          "district_id": "3214070",
          "name": "KERTASARI"
        },
        {
          "id": "3214080001",
          "district_id": "3214080",
          "name": "NANGERANG"
        },
        {
          "id": "3214080002",
          "district_id": "3214080",
          "name": "SIMPANG"
        },
        {
          "id": "3214080003",
          "district_id": "3214080",
          "name": "SAKAMBANG"
        },
        {
          "id": "3214080004",
          "district_id": "3214080",
          "name": "NAGROG"
        },
        {
          "id": "3214080005",
          "district_id": "3214080",
          "name": "CIBUNTU"
        },
        {
          "id": "3214080007",
          "district_id": "3214080",
          "name": "RAHARJA"
        },
        {
          "id": "3214080008",
          "district_id": "3214080",
          "name": "WANAYASA"
        },
        {
          "id": "3214080009",
          "district_id": "3214080",
          "name": "BABAKAN"
        },
        {
          "id": "3214080016",
          "district_id": "3214080",
          "name": "WANASARI"
        },
        {
          "id": "3214080017",
          "district_id": "3214080",
          "name": "LEGOKHUNI"
        },
        {
          "id": "3214080018",
          "district_id": "3214080",
          "name": "CIAWI"
        },
        {
          "id": "3214080019",
          "district_id": "3214080",
          "name": "SUKADAMI"
        },
        {
          "id": "3214080020",
          "district_id": "3214080",
          "name": "TARINGGUL TONGGOH"
        },
        {
          "id": "3214080021",
          "district_id": "3214080",
          "name": "TARINGGUL TENGAH"
        },
        {
          "id": "3214081001",
          "district_id": "3214081",
          "name": "PUSAKAMULYA"
        },
        {
          "id": "3214081002",
          "district_id": "3214081",
          "name": "PARAKAN GAROKGEK"
        },
        {
          "id": "3214081003",
          "district_id": "3214081",
          "name": "CIRACAS"
        },
        {
          "id": "3214081004",
          "district_id": "3214081",
          "name": "KIARAPEDES"
        },
        {
          "id": "3214081005",
          "district_id": "3214081",
          "name": "CIBEBER"
        },
        {
          "id": "3214081006",
          "district_id": "3214081",
          "name": "SUMBERSARI"
        },
        {
          "id": "3214081007",
          "district_id": "3214081",
          "name": "MEKARJAYA"
        },
        {
          "id": "3214081008",
          "district_id": "3214081",
          "name": "MARGALUYU"
        },
        {
          "id": "3214081009",
          "district_id": "3214081",
          "name": "GARDU"
        },
        {
          "id": "3214081010",
          "district_id": "3214081",
          "name": "TARINGGUL LANDEUH"
        },
        {
          "id": "3214090009",
          "district_id": "3214090",
          "name": "CIHERANG"
        },
        {
          "id": "3214090010",
          "district_id": "3214090",
          "name": "CIDAHU"
        },
        {
          "id": "3214090011",
          "district_id": "3214090",
          "name": "PASAWAHAN ANYAR"
        },
        {
          "id": "3214090012",
          "district_id": "3214090",
          "name": "PASAWAHANKIDUL"
        },
        {
          "id": "3214090013",
          "district_id": "3214090",
          "name": "SAWAH KULON"
        },
        {
          "id": "3214090014",
          "district_id": "3214090",
          "name": "KERTAJAYA"
        },
        {
          "id": "3214090015",
          "district_id": "3214090",
          "name": "LEBAKANYAR"
        },
        {
          "id": "3214090016",
          "district_id": "3214090",
          "name": "CIHUNI"
        },
        {
          "id": "3214090017",
          "district_id": "3214090",
          "name": "WARUNGKADU"
        },
        {
          "id": "3214090018",
          "district_id": "3214090",
          "name": "SELAAWI"
        },
        {
          "id": "3214090019",
          "district_id": "3214090",
          "name": "MARGASARI"
        },
        {
          "id": "3214090020",
          "district_id": "3214090",
          "name": "PASAWAHAN"
        },
        {
          "id": "3214091001",
          "district_id": "3214091",
          "name": "BUNGUR JAYA"
        },
        {
          "id": "3214091002",
          "district_id": "3214091",
          "name": "PONDOKBUNGUR"
        },
        {
          "id": "3214091003",
          "district_id": "3214091",
          "name": "SALEM"
        },
        {
          "id": "3214091004",
          "district_id": "3214091",
          "name": "GALUDRA"
        },
        {
          "id": "3214091005",
          "district_id": "3214091",
          "name": "SUKAJADI"
        },
        {
          "id": "3214091006",
          "district_id": "3214091",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3214091007",
          "district_id": "3214091",
          "name": "SALAM JAYA"
        },
        {
          "id": "3214091008",
          "district_id": "3214091",
          "name": "SITU"
        },
        {
          "id": "3214091009",
          "district_id": "3214091",
          "name": "PARAKANSALAM"
        },
        {
          "id": "3214091010",
          "district_id": "3214091",
          "name": "SALAM MULYA"
        },
        {
          "id": "3214100005",
          "district_id": "3214100",
          "name": "SINDANGKASIH"
        },
        {
          "id": "3214100006",
          "district_id": "3214100",
          "name": "NAGERI KIDUL"
        },
        {
          "id": "3214100007",
          "district_id": "3214100",
          "name": "NAGERI TENGAH"
        },
        {
          "id": "3214100008",
          "district_id": "3214100",
          "name": "CIPAISAN"
        },
        {
          "id": "3214100009",
          "district_id": "3214100",
          "name": "NAGERI KALER"
        },
        {
          "id": "3214100010",
          "district_id": "3214100",
          "name": "TEGALMUNJUL"
        },
        {
          "id": "3214100011",
          "district_id": "3214100",
          "name": "CITALANG"
        },
        {
          "id": "3214100012",
          "district_id": "3214100",
          "name": "MUNJULJAYA"
        },
        {
          "id": "3214100013",
          "district_id": "3214100",
          "name": "CISEUREUH"
        },
        {
          "id": "3214100016",
          "district_id": "3214100",
          "name": "PURWAMEKAR"
        },
        {
          "id": "3214101001",
          "district_id": "3214101",
          "name": "MARACANG"
        },
        {
          "id": "3214101002",
          "district_id": "3214101",
          "name": "CIWARENG"
        },
        {
          "id": "3214101003",
          "district_id": "3214101",
          "name": "MULYAMEKAR"
        },
        {
          "id": "3214101004",
          "district_id": "3214101",
          "name": "CIGELAM"
        },
        {
          "id": "3214101005",
          "district_id": "3214101",
          "name": "BABAKANCIKAO"
        },
        {
          "id": "3214101006",
          "district_id": "3214101",
          "name": "KADUMEKAR"
        },
        {
          "id": "3214101007",
          "district_id": "3214101",
          "name": "HEGARMANAH"
        },
        {
          "id": "3214101008",
          "district_id": "3214101",
          "name": "CICADAS"
        },
        {
          "id": "3214101009",
          "district_id": "3214101",
          "name": "CILANGKAP"
        },
        {
          "id": "3214110001",
          "district_id": "3214110",
          "name": "CIRENDE"
        },
        {
          "id": "3214110011",
          "district_id": "3214110",
          "name": "CAMPAKA"
        },
        {
          "id": "3214110012",
          "district_id": "3214110",
          "name": "CAMPAKASARI"
        },
        {
          "id": "3214110023",
          "district_id": "3214110",
          "name": "CIJUNTI"
        },
        {
          "id": "3214110024",
          "district_id": "3214110",
          "name": "CISAAT"
        },
        {
          "id": "3214110025",
          "district_id": "3214110",
          "name": "CIMAHI"
        },
        {
          "id": "3214110026",
          "district_id": "3214110",
          "name": "CIKUMPAY"
        },
        {
          "id": "3214110027",
          "district_id": "3214110",
          "name": "CIJAYA"
        },
        {
          "id": "3214110028",
          "district_id": "3214110",
          "name": "KERTAMUKTI"
        },
        {
          "id": "3214111001",
          "district_id": "3214111",
          "name": "WANAWALI"
        },
        {
          "id": "3214111002",
          "district_id": "3214111",
          "name": "CIKADU"
        },
        {
          "id": "3214111003",
          "district_id": "3214111",
          "name": "CIBUKAMANAH"
        },
        {
          "id": "3214111004",
          "district_id": "3214111",
          "name": "CIRANGKONG"
        },
        {
          "id": "3214111005",
          "district_id": "3214111",
          "name": "CIPANCUR"
        },
        {
          "id": "3214111006",
          "district_id": "3214111",
          "name": "CIPINANG"
        },
        {
          "id": "3214111007",
          "district_id": "3214111",
          "name": "CIPARUNGSARI"
        },
        {
          "id": "3214111008",
          "district_id": "3214111",
          "name": "KARYAMEKAR"
        },
        {
          "id": "3214111009",
          "district_id": "3214111",
          "name": "CIBATU"
        },
        {
          "id": "3214111010",
          "district_id": "3214111",
          "name": "CILANDAK"
        },
        {
          "id": "3214112001",
          "district_id": "3214112",
          "name": "CIWANGI"
        },
        {
          "id": "3214112002",
          "district_id": "3214112",
          "name": "CIBENING"
        },
        {
          "id": "3214112003",
          "district_id": "3214112",
          "name": "BUNGURSARI"
        },
        {
          "id": "3214112004",
          "district_id": "3214112",
          "name": "CIBUNGUR"
        },
        {
          "id": "3214112005",
          "district_id": "3214112",
          "name": "DANGDEUR"
        },
        {
          "id": "3214112006",
          "district_id": "3214112",
          "name": "WANAKERTA"
        },
        {
          "id": "3214112007",
          "district_id": "3214112",
          "name": "CINANGKA"
        },
        {
          "id": "3214112008",
          "district_id": "3214112",
          "name": "CIKOPO"
        },
        {
          "id": "3214112009",
          "district_id": "3214112",
          "name": "KARANGMUKTI"
        },
        {
          "id": "3214112010",
          "district_id": "3214112",
          "name": "CIBODAS"
        },
        {
          "id": "3215010001",
          "district_id": "3215010",
          "name": "MEDALSARI"
        },
        {
          "id": "3215010011",
          "district_id": "3215010",
          "name": "KERTASARI"
        },
        {
          "id": "3215010012",
          "district_id": "3215010",
          "name": "CINTAASIH"
        },
        {
          "id": "3215010013",
          "district_id": "3215010",
          "name": "MULANGSARI"
        },
        {
          "id": "3215010014",
          "district_id": "3215010",
          "name": "JATILAKSANA"
        },
        {
          "id": "3215010015",
          "district_id": "3215010",
          "name": "CIPTASARI"
        },
        {
          "id": "3215010016",
          "district_id": "3215010",
          "name": "TAMANSARI"
        },
        {
          "id": "3215010017",
          "district_id": "3215010",
          "name": "TAMANMEKAR"
        },
        {
          "id": "3215011001",
          "district_id": "3215011",
          "name": "CIPURWASARI"
        },
        {
          "id": "3215011002",
          "district_id": "3215011",
          "name": "CIGUNUNGSARI"
        },
        {
          "id": "3215011003",
          "district_id": "3215011",
          "name": "MEKARBUANA"
        },
        {
          "id": "3215011004",
          "district_id": "3215011",
          "name": "WARGASETRA"
        },
        {
          "id": "3215011005",
          "district_id": "3215011",
          "name": "CINTALAKSANA"
        },
        {
          "id": "3215011006",
          "district_id": "3215011",
          "name": "CINTAWARGI"
        },
        {
          "id": "3215011007",
          "district_id": "3215011",
          "name": "CINTALANGGENG"
        },
        {
          "id": "3215011008",
          "district_id": "3215011",
          "name": "KUTAMANEUH"
        },
        {
          "id": "3215011009",
          "district_id": "3215011",
          "name": "KUTALANGGENG"
        },
        {
          "id": "3215020001",
          "district_id": "3215020",
          "name": "TEGALEGA"
        },
        {
          "id": "3215020002",
          "district_id": "3215020",
          "name": "MULYASEJATI"
        },
        {
          "id": "3215020003",
          "district_id": "3215020",
          "name": "MULYASARI"
        },
        {
          "id": "3215020004",
          "district_id": "3215020",
          "name": "KUTANEGARA"
        },
        {
          "id": "3215020005",
          "district_id": "3215020",
          "name": "KUTAPOHACI"
        },
        {
          "id": "3215020006",
          "district_id": "3215020",
          "name": "KUTAMEKAR"
        },
        {
          "id": "3215020007",
          "district_id": "3215020",
          "name": "PARUNGMULYA"
        },
        {
          "id": "3215031001",
          "district_id": "3215031",
          "name": "SIRNABAYA"
        },
        {
          "id": "3215031002",
          "district_id": "3215031",
          "name": "PINAYUNGAN"
        },
        {
          "id": "3215031003",
          "district_id": "3215031",
          "name": "TELUKJAMBE"
        },
        {
          "id": "3215031004",
          "district_id": "3215031",
          "name": "PUSEURJAYA"
        },
        {
          "id": "3215031005",
          "district_id": "3215031",
          "name": "SUKALUYU"
        },
        {
          "id": "3215031006",
          "district_id": "3215031",
          "name": "SUKAHARJA"
        },
        {
          "id": "3215031007",
          "district_id": "3215031",
          "name": "WADAS"
        },
        {
          "id": "3215031008",
          "district_id": "3215031",
          "name": "SUKAMAKMUR"
        },
        {
          "id": "3215031009",
          "district_id": "3215031",
          "name": "PURWADANA"
        },
        {
          "id": "3215032001",
          "district_id": "3215032",
          "name": "WANAJAYA"
        },
        {
          "id": "3215032002",
          "district_id": "3215032",
          "name": "WANAKERTA"
        },
        {
          "id": "3215032003",
          "district_id": "3215032",
          "name": "WANASARI"
        },
        {
          "id": "3215032004",
          "district_id": "3215032",
          "name": "MARGAMULYA"
        },
        {
          "id": "3215032005",
          "district_id": "3215032",
          "name": "MARGAKAYA"
        },
        {
          "id": "3215032006",
          "district_id": "3215032",
          "name": "KARANGLIGAR"
        },
        {
          "id": "3215032007",
          "district_id": "3215032",
          "name": "KARANGMULYA"
        },
        {
          "id": "3215032008",
          "district_id": "3215032",
          "name": "MULYAJAYA"
        },
        {
          "id": "3215032009",
          "district_id": "3215032",
          "name": "MEKARMULYA"
        },
        {
          "id": "3215032010",
          "district_id": "3215032",
          "name": "PARUNGSARI"
        },
        {
          "id": "3215040001",
          "district_id": "3215040",
          "name": "KARANGANYAR"
        },
        {
          "id": "3215040003",
          "district_id": "3215040",
          "name": "CIMAHI"
        },
        {
          "id": "3215040004",
          "district_id": "3215040",
          "name": "SUMURKONDANG"
        },
        {
          "id": "3215040005",
          "district_id": "3215040",
          "name": "WALAHAR"
        },
        {
          "id": "3215040006",
          "district_id": "3215040",
          "name": "KIARAPAYUNG"
        },
        {
          "id": "3215040007",
          "district_id": "3215040",
          "name": "GINTUNGKERTA"
        },
        {
          "id": "3215040008",
          "district_id": "3215040",
          "name": "ANGGADITA"
        },
        {
          "id": "3215040011",
          "district_id": "3215040",
          "name": "KLARI"
        },
        {
          "id": "3215040012",
          "district_id": "3215040",
          "name": "CIBALONGSARI"
        },
        {
          "id": "3215040014",
          "district_id": "3215040",
          "name": "PANCAWATI"
        },
        {
          "id": "3215050001",
          "district_id": "3215050",
          "name": "KALIHURIP"
        },
        {
          "id": "3215050002",
          "district_id": "3215050",
          "name": "KAMOJING"
        },
        {
          "id": "3215050006",
          "district_id": "3215050",
          "name": "CIKAMPEK TIMUR"
        },
        {
          "id": "3215050007",
          "district_id": "3215050",
          "name": "CIKAMPEK PUSAKA"
        },
        {
          "id": "3215050008",
          "district_id": "3215050",
          "name": "CIKAMPEK SELATAN"
        },
        {
          "id": "3215050009",
          "district_id": "3215050",
          "name": "CIKAMPEK KOTA"
        },
        {
          "id": "3215050011",
          "district_id": "3215050",
          "name": "CIKAMPEK BARAT"
        },
        {
          "id": "3215050012",
          "district_id": "3215050",
          "name": "DAWUAN TIMUR"
        },
        {
          "id": "3215050013",
          "district_id": "3215050",
          "name": "DAWUAN TENGAH"
        },
        {
          "id": "3215050014",
          "district_id": "3215050",
          "name": "DAWUAN BARAT"
        },
        {
          "id": "3215051001",
          "district_id": "3215051",
          "name": "MEKARJAYA"
        },
        {
          "id": "3215051002",
          "district_id": "3215051",
          "name": "TAMELANG"
        },
        {
          "id": "3215051003",
          "district_id": "3215051",
          "name": "PURWASARI"
        },
        {
          "id": "3215051004",
          "district_id": "3215051",
          "name": "SUKASARI"
        },
        {
          "id": "3215051005",
          "district_id": "3215051",
          "name": "TEGALSARI"
        },
        {
          "id": "3215051006",
          "district_id": "3215051",
          "name": "KARANGSARI"
        },
        {
          "id": "3215051007",
          "district_id": "3215051",
          "name": "DARAWOLONG"
        },
        {
          "id": "3215051008",
          "district_id": "3215051",
          "name": "CENGKONG"
        },
        {
          "id": "3215060002",
          "district_id": "3215060",
          "name": "KARANGSINOM"
        },
        {
          "id": "3215060003",
          "district_id": "3215060",
          "name": "KARANGJAYA"
        },
        {
          "id": "3215060004",
          "district_id": "3215060",
          "name": "PARAKANMULYA"
        },
        {
          "id": "3215060005",
          "district_id": "3215060",
          "name": "CITARIK"
        },
        {
          "id": "3215060006",
          "district_id": "3215060",
          "name": "TIRTASARI"
        },
        {
          "id": "3215060007",
          "district_id": "3215060",
          "name": "PARAKAN"
        },
        {
          "id": "3215060008",
          "district_id": "3215060",
          "name": "KAMURANG"
        },
        {
          "id": "3215060009",
          "district_id": "3215060",
          "name": "BOJONGSARI"
        },
        {
          "id": "3215060010",
          "district_id": "3215060",
          "name": "CIPONDOH"
        },
        {
          "id": "3215060011",
          "district_id": "3215060",
          "name": "KERTAWALUYA"
        },
        {
          "id": "3215070004",
          "district_id": "3215070",
          "name": "BARUGBUG"
        },
        {
          "id": "3215070005",
          "district_id": "3215070",
          "name": "SITUDAM"
        },
        {
          "id": "3215070006",
          "district_id": "3215070",
          "name": "BALONGGANDU"
        },
        {
          "id": "3215070009",
          "district_id": "3215070",
          "name": "KALIJATI"
        },
        {
          "id": "3215070010",
          "district_id": "3215070",
          "name": "MEKARSARI"
        },
        {
          "id": "3215070011",
          "district_id": "3215070",
          "name": "JATISARI"
        },
        {
          "id": "3215070012",
          "district_id": "3215070",
          "name": "CIREJAG"
        },
        {
          "id": "3215070013",
          "district_id": "3215070",
          "name": "CIKALONGSARI"
        },
        {
          "id": "3215070014",
          "district_id": "3215070",
          "name": "JATIRAGAS"
        },
        {
          "id": "3215070015",
          "district_id": "3215070",
          "name": "JATIWANGI"
        },
        {
          "id": "3215070016",
          "district_id": "3215070",
          "name": "JATIBARU"
        },
        {
          "id": "3215070017",
          "district_id": "3215070",
          "name": "TELARSARI"
        },
        {
          "id": "3215070018",
          "district_id": "3215070",
          "name": "SUKAMEKAR"
        },
        {
          "id": "3215070019",
          "district_id": "3215070",
          "name": "PACING"
        },
        {
          "id": "3215071001",
          "district_id": "3215071",
          "name": "PAMEKARAN"
        },
        {
          "id": "3215071002",
          "district_id": "3215071",
          "name": "CICINDE SELATAN"
        },
        {
          "id": "3215071003",
          "district_id": "3215071",
          "name": "CICINDE UTARA"
        },
        {
          "id": "3215071004",
          "district_id": "3215071",
          "name": "JAYAMUKTI"
        },
        {
          "id": "3215071005",
          "district_id": "3215071",
          "name": "GEMPOLKOLOT"
        },
        {
          "id": "3215071006",
          "district_id": "3215071",
          "name": "GEMPOL"
        },
        {
          "id": "3215071007",
          "district_id": "3215071",
          "name": "GEMBONGAN"
        },
        {
          "id": "3215071008",
          "district_id": "3215071",
          "name": "MEKARASIH"
        },
        {
          "id": "3215071009",
          "district_id": "3215071",
          "name": "BANYUASIH"
        },
        {
          "id": "3215071010",
          "district_id": "3215071",
          "name": "TALUNJAYA"
        },
        {
          "id": "3215071011",
          "district_id": "3215071",
          "name": "TANJUNG"
        },
        {
          "id": "3215071012",
          "district_id": "3215071",
          "name": "KUTARAHARJA"
        },
        {
          "id": "3215072001",
          "district_id": "3215072",
          "name": "SARIMULYA"
        },
        {
          "id": "3215072002",
          "district_id": "3215072",
          "name": "JOMIN BARAT"
        },
        {
          "id": "3215072003",
          "district_id": "3215072",
          "name": "JOMIN TIMUR"
        },
        {
          "id": "3215072004",
          "district_id": "3215072",
          "name": "PANGULAH SELATAN"
        },
        {
          "id": "3215072005",
          "district_id": "3215072",
          "name": "PANGULAH UTARA"
        },
        {
          "id": "3215072006",
          "district_id": "3215072",
          "name": "PANGULAH BARU"
        },
        {
          "id": "3215072007",
          "district_id": "3215072",
          "name": "WANCIMEKAR"
        },
        {
          "id": "3215072009",
          "district_id": "3215072",
          "name": "CIKAMPEK UTARA"
        },
        {
          "id": "3215081001",
          "district_id": "3215081",
          "name": "CIKARANG"
        },
        {
          "id": "3215081002",
          "district_id": "3215081",
          "name": "CIKALONG"
        },
        {
          "id": "3215081003",
          "district_id": "3215081",
          "name": "TEGALSARI"
        },
        {
          "id": "3215081004",
          "district_id": "3215081",
          "name": "TEGALWARU"
        },
        {
          "id": "3215081005",
          "district_id": "3215081",
          "name": "MEKARMAYA"
        },
        {
          "id": "3215081006",
          "district_id": "3215081",
          "name": "CILAMAYA"
        },
        {
          "id": "3215081007",
          "district_id": "3215081",
          "name": "SUKATANI"
        },
        {
          "id": "3215081008",
          "district_id": "3215081",
          "name": "SUKAKERTA"
        },
        {
          "id": "3215081009",
          "district_id": "3215081",
          "name": "RAWAGEMPOL KULON"
        },
        {
          "id": "3215081010",
          "district_id": "3215081",
          "name": "RAWAGEMPOL WETAN"
        },
        {
          "id": "3215081011",
          "district_id": "3215081",
          "name": "MUARABARU"
        },
        {
          "id": "3215081012",
          "district_id": "3215081",
          "name": "MUARA"
        },
        {
          "id": "3215082001",
          "district_id": "3215082",
          "name": "KIARA"
        },
        {
          "id": "3215082002",
          "district_id": "3215082",
          "name": "BAYUR KIDUL"
        },
        {
          "id": "3215082003",
          "district_id": "3215082",
          "name": "BAYUR LOR"
        },
        {
          "id": "3215082004",
          "district_id": "3215082",
          "name": "LANGENSARI"
        },
        {
          "id": "3215082005",
          "district_id": "3215082",
          "name": "SUKAMULYA"
        },
        {
          "id": "3215082006",
          "district_id": "3215082",
          "name": "PASIRUKEM"
        },
        {
          "id": "3215082007",
          "district_id": "3215082",
          "name": "SUKAJAYA"
        },
        {
          "id": "3215082008",
          "district_id": "3215082",
          "name": "PASIRJAYA"
        },
        {
          "id": "3215082009",
          "district_id": "3215082",
          "name": "MUKTIJAYA"
        },
        {
          "id": "3215082010",
          "district_id": "3215082",
          "name": "TEGALURUNG"
        },
        {
          "id": "3215082011",
          "district_id": "3215082",
          "name": "MANGGUNGJAYA"
        },
        {
          "id": "3215090001",
          "district_id": "3215090",
          "name": "CIWARINGIN"
        },
        {
          "id": "3215090002",
          "district_id": "3215090",
          "name": "WARINGINKARYA"
        },
        {
          "id": "3215090003",
          "district_id": "3215090",
          "name": "PASIRTANJUNG"
        },
        {
          "id": "3215090004",
          "district_id": "3215090",
          "name": "KARANGTANJUNG"
        },
        {
          "id": "3215090005",
          "district_id": "3215090",
          "name": "KEDAWUNG"
        },
        {
          "id": "3215090006",
          "district_id": "3215090",
          "name": "LEMAHABANG"
        },
        {
          "id": "3215090007",
          "district_id": "3215090",
          "name": "KARYAMUKTI"
        },
        {
          "id": "3215090008",
          "district_id": "3215090",
          "name": "PULOJAYA"
        },
        {
          "id": "3215090009",
          "district_id": "3215090",
          "name": "PULOKELAPA"
        },
        {
          "id": "3215090010",
          "district_id": "3215090",
          "name": "LEMAHMUKTI"
        },
        {
          "id": "3215090011",
          "district_id": "3215090",
          "name": "PULOMULYA"
        },
        {
          "id": "3215100001",
          "district_id": "3215100",
          "name": "PASIRTALAGA"
        },
        {
          "id": "3215100002",
          "district_id": "3215100",
          "name": "TALAGAMULYA"
        },
        {
          "id": "3215100003",
          "district_id": "3215100",
          "name": "CARIUMULYA"
        },
        {
          "id": "3215100004",
          "district_id": "3215100",
          "name": "CILEWO"
        },
        {
          "id": "3215100005",
          "district_id": "3215100",
          "name": "LINGGARSARI"
        },
        {
          "id": "3215100006",
          "district_id": "3215100",
          "name": "PULOSARI"
        },
        {
          "id": "3215100007",
          "district_id": "3215100",
          "name": "CIWULAN"
        },
        {
          "id": "3215100008",
          "district_id": "3215100",
          "name": "KALIJAYA"
        },
        {
          "id": "3215100009",
          "district_id": "3215100",
          "name": "CADASKERTAJAYA"
        },
        {
          "id": "3215100010",
          "district_id": "3215100",
          "name": "KALIBUAYA"
        },
        {
          "id": "3215100011",
          "district_id": "3215100",
          "name": "TALAGASARI"
        },
        {
          "id": "3215100012",
          "district_id": "3215100",
          "name": "PASIRMUKTI"
        },
        {
          "id": "3215100013",
          "district_id": "3215100",
          "name": "PASIRKAMUNING"
        },
        {
          "id": "3215100014",
          "district_id": "3215100",
          "name": "KALISARI"
        },
        {
          "id": "3215111001",
          "district_id": "3215111",
          "name": "PASIRJENGKOL"
        },
        {
          "id": "3215111002",
          "district_id": "3215111",
          "name": "MAJALAYA"
        },
        {
          "id": "3215111003",
          "district_id": "3215111",
          "name": "CIRANGGON"
        },
        {
          "id": "3215111004",
          "district_id": "3215111",
          "name": "SARIJAYA"
        },
        {
          "id": "3215111006",
          "district_id": "3215111",
          "name": "LEMAHMULYA"
        },
        {
          "id": "3215111007",
          "district_id": "3215111",
          "name": "PASIRMULYA"
        },
        {
          "id": "3215112001",
          "district_id": "3215112",
          "name": "ADIARSA TIMUR"
        },
        {
          "id": "3215112002",
          "district_id": "3215112",
          "name": "WARUNGBAMBU"
        },
        {
          "id": "3215112003",
          "district_id": "3215112",
          "name": "KONDANGJAYA"
        },
        {
          "id": "3215112004",
          "district_id": "3215112",
          "name": "MARGASARI"
        },
        {
          "id": "3215112005",
          "district_id": "3215112",
          "name": "KARAWANG WETAN"
        },
        {
          "id": "3215112006",
          "district_id": "3215112",
          "name": "PALUMBONSARI"
        },
        {
          "id": "3215112007",
          "district_id": "3215112",
          "name": "PALAWAD"
        },
        {
          "id": "3215112008",
          "district_id": "3215112",
          "name": "TEGALSAWAH"
        },
        {
          "id": "3215113001",
          "district_id": "3215113",
          "name": "ADIARSA BARAT"
        },
        {
          "id": "3215113002",
          "district_id": "3215113",
          "name": "NAGASARI"
        },
        {
          "id": "3215113003",
          "district_id": "3215113",
          "name": "KARAWANG KULON"
        },
        {
          "id": "3215113004",
          "district_id": "3215113",
          "name": "TANJUNGPURA"
        },
        {
          "id": "3215113005",
          "district_id": "3215113",
          "name": "TANJUNGMEKAR"
        },
        {
          "id": "3215113006",
          "district_id": "3215113",
          "name": "KARANGPAWITAN"
        },
        {
          "id": "3215113007",
          "district_id": "3215113",
          "name": "MEKARJATI"
        },
        {
          "id": "3215113008",
          "district_id": "3215113",
          "name": "TUNGGAKJATI"
        },
        {
          "id": "3215120001",
          "district_id": "3215120",
          "name": "PASIRKALIKI"
        },
        {
          "id": "3215120002",
          "district_id": "3215120",
          "name": "KUTAWARGI"
        },
        {
          "id": "3215120003",
          "district_id": "3215120",
          "name": "CIBADAK"
        },
        {
          "id": "3215120004",
          "district_id": "3215120",
          "name": "SUKAMERTA"
        },
        {
          "id": "3215120005",
          "district_id": "3215120",
          "name": "PASIRAWI"
        },
        {
          "id": "3215120006",
          "district_id": "3215120",
          "name": "BALONGSARI"
        },
        {
          "id": "3215120007",
          "district_id": "3215120",
          "name": "PURWAMEKAR"
        },
        {
          "id": "3215120008",
          "district_id": "3215120",
          "name": "MEKARJAYA"
        },
        {
          "id": "3215120009",
          "district_id": "3215120",
          "name": "SEKARWANGI"
        },
        {
          "id": "3215120010",
          "district_id": "3215120",
          "name": "PANYINGKIRAN"
        },
        {
          "id": "3215120011",
          "district_id": "3215120",
          "name": "SUKAPURA"
        },
        {
          "id": "3215120012",
          "district_id": "3215120",
          "name": "GOMBONGSARI"
        },
        {
          "id": "3215120013",
          "district_id": "3215120",
          "name": "SUKARAJA"
        },
        {
          "id": "3215130001",
          "district_id": "3215130",
          "name": "DAYEUHLUHUR"
        },
        {
          "id": "3215130002",
          "district_id": "3215130",
          "name": "LEMAHKARYA"
        },
        {
          "id": "3215130003",
          "district_id": "3215130",
          "name": "LEMAHDUHUR"
        },
        {
          "id": "3215130004",
          "district_id": "3215130",
          "name": "LEMAHSUBUR"
        },
        {
          "id": "3215130005",
          "district_id": "3215130",
          "name": "LEMAHMAKMUR"
        },
        {
          "id": "3215130006",
          "district_id": "3215130",
          "name": "PAGADUNGAN"
        },
        {
          "id": "3215130007",
          "district_id": "3215130",
          "name": "PURWAJAYA"
        },
        {
          "id": "3215130008",
          "district_id": "3215130",
          "name": "JAYANAGARA"
        },
        {
          "id": "3215130009",
          "district_id": "3215130",
          "name": "TEMPURAN"
        },
        {
          "id": "3215130010",
          "district_id": "3215130",
          "name": "CIPARAGEJAYA"
        },
        {
          "id": "3215130011",
          "district_id": "3215130",
          "name": "CIKUNTUL"
        },
        {
          "id": "3215130012",
          "district_id": "3215130",
          "name": "SUMBERJAYA"
        },
        {
          "id": "3215130013",
          "district_id": "3215130",
          "name": "PANCAKARYA"
        },
        {
          "id": "3215130014",
          "district_id": "3215130",
          "name": "TANJUNGJAYA"
        },
        {
          "id": "3215140001",
          "district_id": "3215140",
          "name": "SINDANGKARYA"
        },
        {
          "id": "3215140002",
          "district_id": "3215140",
          "name": "SINDANGMUKTI"
        },
        {
          "id": "3215140003",
          "district_id": "3215140",
          "name": "SINDANGMULYA"
        },
        {
          "id": "3215140004",
          "district_id": "3215140",
          "name": "MULYAJAYA"
        },
        {
          "id": "3215140005",
          "district_id": "3215140",
          "name": "KUTAKARYA"
        },
        {
          "id": "3215140006",
          "district_id": "3215140",
          "name": "KUTAGANDOK"
        },
        {
          "id": "3215140007",
          "district_id": "3215140",
          "name": "WALUYA"
        },
        {
          "id": "3215140008",
          "district_id": "3215140",
          "name": "SAMPALAN"
        },
        {
          "id": "3215140009",
          "district_id": "3215140",
          "name": "SINDANGSARI"
        },
        {
          "id": "3215140010",
          "district_id": "3215140",
          "name": "KUTARAJA"
        },
        {
          "id": "3215140011",
          "district_id": "3215140",
          "name": "KUTAMUKTI"
        },
        {
          "id": "3215140012",
          "district_id": "3215140",
          "name": "KUTAJAYA"
        },
        {
          "id": "3215150001",
          "district_id": "3215150",
          "name": "KALANGSARI"
        },
        {
          "id": "3215150002",
          "district_id": "3215150",
          "name": "KALANGSURIA"
        },
        {
          "id": "3215150003",
          "district_id": "3215150",
          "name": "KARYASARI"
        },
        {
          "id": "3215150004",
          "district_id": "3215150",
          "name": "DUKUHKARYA"
        },
        {
          "id": "3215150005",
          "district_id": "3215150",
          "name": "AMANSARI"
        },
        {
          "id": "3215150006",
          "district_id": "3215150",
          "name": "RENGASDENGKLOK SELATAN"
        },
        {
          "id": "3215150007",
          "district_id": "3215150",
          "name": "RENGASDENGKLOK UTARA"
        },
        {
          "id": "3215150008",
          "district_id": "3215150",
          "name": "KERTASARI"
        },
        {
          "id": "3215150009",
          "district_id": "3215150",
          "name": "DEWISARI"
        },
        {
          "id": "3215151001",
          "district_id": "3215151",
          "name": "KEMIRI"
        },
        {
          "id": "3215151002",
          "district_id": "3215151",
          "name": "MAKMURJAYA"
        },
        {
          "id": "3215151003",
          "district_id": "3215151",
          "name": "JAYAMAKMUR"
        },
        {
          "id": "3215151004",
          "district_id": "3215151",
          "name": "JAYAKERTA"
        },
        {
          "id": "3215151005",
          "district_id": "3215151",
          "name": "KERTAJAYA"
        },
        {
          "id": "3215151006",
          "district_id": "3215151",
          "name": "CIPTAMARGA"
        },
        {
          "id": "3215151007",
          "district_id": "3215151",
          "name": "MEDANGASEM"
        },
        {
          "id": "3215151008",
          "district_id": "3215151",
          "name": "KAMPUNGSAWAH"
        },
        {
          "id": "3215160001",
          "district_id": "3215160",
          "name": "JATIMULYA"
        },
        {
          "id": "3215160006",
          "district_id": "3215160",
          "name": "KERTARAHARJA"
        },
        {
          "id": "3215160007",
          "district_id": "3215160",
          "name": "KARANGJAYA"
        },
        {
          "id": "3215160008",
          "district_id": "3215160",
          "name": "MALANGSARI"
        },
        {
          "id": "3215160009",
          "district_id": "3215160",
          "name": "KERTAMULYA"
        },
        {
          "id": "3215160010",
          "district_id": "3215160",
          "name": "PAYUNGSARI"
        },
        {
          "id": "3215160011",
          "district_id": "3215160",
          "name": "RANDUMULYA"
        },
        {
          "id": "3215160012",
          "district_id": "3215160",
          "name": "LABANJAYA"
        },
        {
          "id": "3215160015",
          "district_id": "3215160",
          "name": "SUNGAIBUNTU"
        },
        {
          "id": "3215160016",
          "district_id": "3215160",
          "name": "KEDALJAYA"
        },
        {
          "id": "3215160017",
          "district_id": "3215160",
          "name": "DONGKAL"
        },
        {
          "id": "3215160018",
          "district_id": "3215160",
          "name": "PUSPASARI"
        },
        {
          "id": "3215161001",
          "district_id": "3215161",
          "name": "SUKARATU"
        },
        {
          "id": "3215161002",
          "district_id": "3215161",
          "name": "CIPTAMARGI"
        },
        {
          "id": "3215161003",
          "district_id": "3215161",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3215161004",
          "district_id": "3215161",
          "name": "MEKARPOHACI"
        },
        {
          "id": "3215161005",
          "district_id": "3215161",
          "name": "KERTAMUKTI"
        },
        {
          "id": "3215161006",
          "district_id": "3215161",
          "name": "CIKANDE"
        },
        {
          "id": "3215161007",
          "district_id": "3215161",
          "name": "RAWASARI"
        },
        {
          "id": "3215161008",
          "district_id": "3215161",
          "name": "KOSAMBIBATU"
        },
        {
          "id": "3215161009",
          "district_id": "3215161",
          "name": "PUSAKAJAYA SELATAN"
        },
        {
          "id": "3215161010",
          "district_id": "3215161",
          "name": "PUSAKAJAYA UTARA"
        },
        {
          "id": "3215170001",
          "district_id": "3215170",
          "name": "SUKASARI"
        },
        {
          "id": "3215170002",
          "district_id": "3215170",
          "name": "KERTARAHAYU"
        },
        {
          "id": "3215170003",
          "district_id": "3215170",
          "name": "CIBUAYA"
        },
        {
          "id": "3215170004",
          "district_id": "3215170",
          "name": "PEJATEN"
        },
        {
          "id": "3215170006",
          "district_id": "3215170",
          "name": "KALIDUNGJAYA"
        },
        {
          "id": "3215170007",
          "district_id": "3215170",
          "name": "KEDUNGJAYA"
        },
        {
          "id": "3215170008",
          "district_id": "3215170",
          "name": "JAYAMULYA"
        },
        {
          "id": "3215170009",
          "district_id": "3215170",
          "name": "GEBANGJAYA"
        },
        {
          "id": "3215170010",
          "district_id": "3215170",
          "name": "CEMARAJAYA"
        },
        {
          "id": "3215170011",
          "district_id": "3215170",
          "name": "SEDARI"
        },
        {
          "id": "3215180001",
          "district_id": "3215180",
          "name": "MEDANKARYA"
        },
        {
          "id": "3215180002",
          "district_id": "3215180",
          "name": "PISANGSAMBO"
        },
        {
          "id": "3215180003",
          "district_id": "3215180",
          "name": "SABAJAYA"
        },
        {
          "id": "3215180004",
          "district_id": "3215180",
          "name": "GEMPOLKARYA"
        },
        {
          "id": "3215180005",
          "district_id": "3215180",
          "name": "SRIJAYA"
        },
        {
          "id": "3215180006",
          "district_id": "3215180",
          "name": "KUTAMAKMUR"
        },
        {
          "id": "3215180007",
          "district_id": "3215180",
          "name": "BOLANG"
        },
        {
          "id": "3215180008",
          "district_id": "3215180",
          "name": "SRIKAMULYAN"
        },
        {
          "id": "3215180009",
          "district_id": "3215180",
          "name": "SUMURLABAN"
        },
        {
          "id": "3215180010",
          "district_id": "3215180",
          "name": "TAMBAKSUMUR"
        },
        {
          "id": "3215180011",
          "district_id": "3215180",
          "name": "TAMBAKSARI"
        },
        {
          "id": "3215190001",
          "district_id": "3215190",
          "name": "KUTAAMPEL"
        },
        {
          "id": "3215190002",
          "district_id": "3215190",
          "name": "KARYAMAKMUR"
        },
        {
          "id": "3215190003",
          "district_id": "3215190",
          "name": "KARYAMULYA"
        },
        {
          "id": "3215190004",
          "district_id": "3215190",
          "name": "TELUKBANGO"
        },
        {
          "id": "3215190005",
          "district_id": "3215190",
          "name": "TELUKAMBULU"
        },
        {
          "id": "3215190006",
          "district_id": "3215190",
          "name": "KARYABAKTI"
        },
        {
          "id": "3215190007",
          "district_id": "3215190",
          "name": "BATURADEN"
        },
        {
          "id": "3215190008",
          "district_id": "3215190",
          "name": "BATUJAYA"
        },
        {
          "id": "3215190009",
          "district_id": "3215190",
          "name": "SEGARAN"
        },
        {
          "id": "3215190010",
          "district_id": "3215190",
          "name": "SEGARJAYA"
        },
        {
          "id": "3215200001",
          "district_id": "3215200",
          "name": "TALAGAJAYA"
        },
        {
          "id": "3215200003",
          "district_id": "3215200",
          "name": "TANAHBARU"
        },
        {
          "id": "3215200004",
          "district_id": "3215200",
          "name": "SOLOKAN"
        },
        {
          "id": "3215200005",
          "district_id": "3215200",
          "name": "TANJUNGBUNGIN"
        },
        {
          "id": "3215200006",
          "district_id": "3215200",
          "name": "TANJUNGMEKAR"
        },
        {
          "id": "3215200007",
          "district_id": "3215200",
          "name": "TANJUNGPAKIS"
        },
        {
          "id": "3215200008",
          "district_id": "3215200",
          "name": "TELUKJAYA"
        },
        {
          "id": "3216010001",
          "district_id": "3216010",
          "name": "RAGEMANUNGGAL"
        },
        {
          "id": "3216010002",
          "district_id": "3216010",
          "name": "MUKTIJAYA"
        },
        {
          "id": "3216010003",
          "district_id": "3216010",
          "name": "KERTARAHAYU"
        },
        {
          "id": "3216010004",
          "district_id": "3216010",
          "name": "CIKARAGEMAN"
        },
        {
          "id": "3216010005",
          "district_id": "3216010",
          "name": "TAMAN SARI"
        },
        {
          "id": "3216010006",
          "district_id": "3216010",
          "name": "TAMAN RAHAYU"
        },
        {
          "id": "3216010007",
          "district_id": "3216010",
          "name": "BURANGKENG"
        },
        {
          "id": "3216010008",
          "district_id": "3216010",
          "name": "CILEDUK"
        },
        {
          "id": "3216010009",
          "district_id": "3216010",
          "name": "CIBENING"
        },
        {
          "id": "3216010012",
          "district_id": "3216010",
          "name": "CIJENGKOL"
        },
        {
          "id": "3216010013",
          "district_id": "3216010",
          "name": "LUBANGBUAYA"
        },
        {
          "id": "3216021001",
          "district_id": "3216021",
          "name": "JAYAMULYA"
        },
        {
          "id": "3216021002",
          "district_id": "3216021",
          "name": "SUKARAGAM"
        },
        {
          "id": "3216021003",
          "district_id": "3216021",
          "name": "SIRNAJAYA"
        },
        {
          "id": "3216021004",
          "district_id": "3216021",
          "name": "NAGACIPTA"
        },
        {
          "id": "3216021005",
          "district_id": "3216021",
          "name": "NAGASARI"
        },
        {
          "id": "3216021006",
          "district_id": "3216021",
          "name": "CILANGKARA"
        },
        {
          "id": "3216021007",
          "district_id": "3216021",
          "name": "SUKASARI"
        },
        {
          "id": "3216021008",
          "district_id": "3216021",
          "name": "JAYASAMPURNA"
        },
        {
          "id": "3216022001",
          "district_id": "3216022",
          "name": "CICAU"
        },
        {
          "id": "3216022002",
          "district_id": "3216022",
          "name": "SUKAMAHI"
        },
        {
          "id": "3216022003",
          "district_id": "3216022",
          "name": "PASIRRANJI"
        },
        {
          "id": "3216022004",
          "district_id": "3216022",
          "name": "PASIRTANJUNG"
        },
        {
          "id": "3216022005",
          "district_id": "3216022",
          "name": "HEGARMUKTI"
        },
        {
          "id": "3216022006",
          "district_id": "3216022",
          "name": "JAYAMUKTI"
        },
        {
          "id": "3216023001",
          "district_id": "3216023",
          "name": "SUKASEJATI"
        },
        {
          "id": "3216023002",
          "district_id": "3216023",
          "name": "CIANTRA"
        },
        {
          "id": "3216023003",
          "district_id": "3216023",
          "name": "SUKADAMI"
        },
        {
          "id": "3216023004",
          "district_id": "3216023",
          "name": "SERANG"
        },
        {
          "id": "3216023005",
          "district_id": "3216023",
          "name": "SUKARESMI"
        },
        {
          "id": "3216023006",
          "district_id": "3216023",
          "name": "CIBATU"
        },
        {
          "id": "3216023007",
          "district_id": "3216023",
          "name": "PASIRSARI"
        },
        {
          "id": "3216030001",
          "district_id": "3216030",
          "name": "CIBARUSAHJAYA"
        },
        {
          "id": "3216030002",
          "district_id": "3216030",
          "name": "CIBARUSAHKOTA"
        },
        {
          "id": "3216030003",
          "district_id": "3216030",
          "name": "SINDANGMULYA"
        },
        {
          "id": "3216030004",
          "district_id": "3216030",
          "name": "WIBAWAMULYA"
        },
        {
          "id": "3216030005",
          "district_id": "3216030",
          "name": "SIRNAJATI"
        },
        {
          "id": "3216030006",
          "district_id": "3216030",
          "name": "RIDOGALIH"
        },
        {
          "id": "3216030007",
          "district_id": "3216030",
          "name": "RIDOMANAH"
        },
        {
          "id": "3216031001",
          "district_id": "3216031",
          "name": "KARANGINDAH"
        },
        {
          "id": "3216031002",
          "district_id": "3216031",
          "name": "KARANGMULYA"
        },
        {
          "id": "3216031003",
          "district_id": "3216031",
          "name": "BOJONGMANGU"
        },
        {
          "id": "3216031004",
          "district_id": "3216031",
          "name": "MEDALKRISNA"
        },
        {
          "id": "3216031005",
          "district_id": "3216031",
          "name": "SUKAMUKTI"
        },
        {
          "id": "3216031006",
          "district_id": "3216031",
          "name": "SUKABUNGAH"
        },
        {
          "id": "3216041001",
          "district_id": "3216041",
          "name": "SERTAJAYA"
        },
        {
          "id": "3216041002",
          "district_id": "3216041",
          "name": "HEGARMANAH"
        },
        {
          "id": "3216041003",
          "district_id": "3216041",
          "name": "CIPAYUNG"
        },
        {
          "id": "3216041004",
          "district_id": "3216041",
          "name": "JATIREJA"
        },
        {
          "id": "3216041005",
          "district_id": "3216041",
          "name": "JATIBARU"
        },
        {
          "id": "3216041006",
          "district_id": "3216041",
          "name": "TANJUNGBARU"
        },
        {
          "id": "3216041007",
          "district_id": "3216041",
          "name": "LABANSARI"
        },
        {
          "id": "3216041008",
          "district_id": "3216041",
          "name": "KARANGSARI"
        },
        {
          "id": "3216050002",
          "district_id": "3216050",
          "name": "BOJONGSARI"
        },
        {
          "id": "3216050003",
          "district_id": "3216050",
          "name": "KEDUNGWARINGIN"
        },
        {
          "id": "3216050004",
          "district_id": "3216050",
          "name": "WARINGINJAYA"
        },
        {
          "id": "3216050005",
          "district_id": "3216050",
          "name": "KARANGSAMBUNG"
        },
        {
          "id": "3216050007",
          "district_id": "3216050",
          "name": "KARANGHARUM"
        },
        {
          "id": "3216050008",
          "district_id": "3216050",
          "name": "MEKARJAYA"
        },
        {
          "id": "3216050009",
          "district_id": "3216050",
          "name": "KARANGMEKAR"
        },
        {
          "id": "3216061001",
          "district_id": "3216061",
          "name": "WANGUNHARJA"
        },
        {
          "id": "3216061002",
          "district_id": "3216061",
          "name": "HARJAMEKAR"
        },
        {
          "id": "3216061003",
          "district_id": "3216061",
          "name": "PASIRGOMBONG"
        },
        {
          "id": "3216061004",
          "district_id": "3216061",
          "name": "MEKARMUKTI"
        },
        {
          "id": "3216061005",
          "district_id": "3216061",
          "name": "SIMPANGAN"
        },
        {
          "id": "3216061006",
          "district_id": "3216061",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3216061007",
          "district_id": "3216061",
          "name": "CIKARANG KOTA"
        },
        {
          "id": "3216061008",
          "district_id": "3216061",
          "name": "KARANGBARU"
        },
        {
          "id": "3216061009",
          "district_id": "3216061",
          "name": "KARANGASIH"
        },
        {
          "id": "3216061010",
          "district_id": "3216061",
          "name": "KARANGRAHARJA"
        },
        {
          "id": "3216061011",
          "district_id": "3216061",
          "name": "WALUYA"
        },
        {
          "id": "3216062001",
          "district_id": "3216062",
          "name": "SUKARAYA"
        },
        {
          "id": "3216062002",
          "district_id": "3216062",
          "name": "KARANGRAHAYU"
        },
        {
          "id": "3216062003",
          "district_id": "3216062",
          "name": "KARANGSETIA"
        },
        {
          "id": "3216062004",
          "district_id": "3216062",
          "name": "KARANGSATU"
        },
        {
          "id": "3216062005",
          "district_id": "3216062",
          "name": "KARANGMUKTI"
        },
        {
          "id": "3216062006",
          "district_id": "3216062",
          "name": "KARANGANYAR"
        },
        {
          "id": "3216062007",
          "district_id": "3216062",
          "name": "KARANGBAHAGIA"
        },
        {
          "id": "3216062008",
          "district_id": "3216062",
          "name": "KARANGSENTOSA"
        },
        {
          "id": "3216070006",
          "district_id": "3216070",
          "name": "CIBUNTU"
        },
        {
          "id": "3216070013",
          "district_id": "3216070",
          "name": "WANASARI"
        },
        {
          "id": "3216070014",
          "district_id": "3216070",
          "name": "WANAJAYA"
        },
        {
          "id": "3216070015",
          "district_id": "3216070",
          "name": "SUKAJAYA"
        },
        {
          "id": "3216070016",
          "district_id": "3216070",
          "name": "KERTAMUKTI"
        },
        {
          "id": "3216070017",
          "district_id": "3216070",
          "name": "MUKTIWARI"
        },
        {
          "id": "3216070018",
          "district_id": "3216070",
          "name": "SARIMUKTI"
        },
        {
          "id": "3216071001",
          "district_id": "3216071",
          "name": "TELAJUNG"
        },
        {
          "id": "3216071002",
          "district_id": "3216071",
          "name": "CIKEDOKAN"
        },
        {
          "id": "3216071003",
          "district_id": "3216071",
          "name": "JATIWANGI"
        },
        {
          "id": "3216071004",
          "district_id": "3216071",
          "name": "MEKARWANGI"
        },
        {
          "id": "3216071005",
          "district_id": "3216071",
          "name": "GANDAMEKAR"
        },
        {
          "id": "3216071006",
          "district_id": "3216071",
          "name": "DANAUINDAH"
        },
        {
          "id": "3216071007",
          "district_id": "3216071",
          "name": "GANDASARI"
        },
        {
          "id": "3216071008",
          "district_id": "3216071",
          "name": "SUKADANAU"
        },
        {
          "id": "3216071009",
          "district_id": "3216071",
          "name": "TELAGA ASIH"
        },
        {
          "id": "3216071010",
          "district_id": "3216071",
          "name": "TELAGAMURNI"
        },
        {
          "id": "3216071011",
          "district_id": "3216071",
          "name": "KALIJAYA"
        },
        {
          "id": "3216081001",
          "district_id": "3216081",
          "name": "JATIMULYA"
        },
        {
          "id": "3216081002",
          "district_id": "3216081",
          "name": "LAMBANGSARI"
        },
        {
          "id": "3216081003",
          "district_id": "3216081",
          "name": "LAMBANGJAYA"
        },
        {
          "id": "3216081004",
          "district_id": "3216081",
          "name": "TAMBUN"
        },
        {
          "id": "3216081005",
          "district_id": "3216081",
          "name": "SETIADARMA"
        },
        {
          "id": "3216081006",
          "district_id": "3216081",
          "name": "SETIAMEKAR"
        },
        {
          "id": "3216081007",
          "district_id": "3216081",
          "name": "MEKARSARI"
        },
        {
          "id": "3216081008",
          "district_id": "3216081",
          "name": "TRIDAYASAKTI"
        },
        {
          "id": "3216081009",
          "district_id": "3216081",
          "name": "MANGUNJAYA"
        },
        {
          "id": "3216081010",
          "district_id": "3216081",
          "name": "SUMBERJAYA"
        },
        {
          "id": "3216082001",
          "district_id": "3216082",
          "name": "KARANGSATRIA"
        },
        {
          "id": "3216082002",
          "district_id": "3216082",
          "name": "SATRIAJAYA"
        },
        {
          "id": "3216082003",
          "district_id": "3216082",
          "name": "JEJALENJAYA"
        },
        {
          "id": "3216082004",
          "district_id": "3216082",
          "name": "SATRIAMEKAR"
        },
        {
          "id": "3216082005",
          "district_id": "3216082",
          "name": "SRIAMUR"
        },
        {
          "id": "3216082006",
          "district_id": "3216082",
          "name": "SRIMUKTI"
        },
        {
          "id": "3216082007",
          "district_id": "3216082",
          "name": "SRIJAYA"
        },
        {
          "id": "3216082008",
          "district_id": "3216082",
          "name": "SRIMAHI"
        },
        {
          "id": "3216090001",
          "district_id": "3216090",
          "name": "BAHAGIA"
        },
        {
          "id": "3216090002",
          "district_id": "3216090",
          "name": "KEBALEN"
        },
        {
          "id": "3216090003",
          "district_id": "3216090",
          "name": "BABELAN KOTA"
        },
        {
          "id": "3216090004",
          "district_id": "3216090",
          "name": "KEDUNGPENGAWAS"
        },
        {
          "id": "3216090005",
          "district_id": "3216090",
          "name": "KEDUNGJAYA"
        },
        {
          "id": "3216090006",
          "district_id": "3216090",
          "name": "BUNIBAKTI"
        },
        {
          "id": "3216090007",
          "district_id": "3216090",
          "name": "MUARABAKTI"
        },
        {
          "id": "3216090008",
          "district_id": "3216090",
          "name": "PANTAI HURIP"
        },
        {
          "id": "3216090009",
          "district_id": "3216090",
          "name": "HURIPJAYA"
        },
        {
          "id": "3216100001",
          "district_id": "3216100",
          "name": "PUSAKARAKYAT"
        },
        {
          "id": "3216100002",
          "district_id": "3216100",
          "name": "SETIA ASIH"
        },
        {
          "id": "3216100003",
          "district_id": "3216100",
          "name": "PAHLAWAN SETIA"
        },
        {
          "id": "3216100004",
          "district_id": "3216100",
          "name": "SETIAMULYA"
        },
        {
          "id": "3216100005",
          "district_id": "3216100",
          "name": "SEGARAMAKMUR"
        },
        {
          "id": "3216100006",
          "district_id": "3216100",
          "name": "PANTAIMAKMUR"
        },
        {
          "id": "3216100007",
          "district_id": "3216100",
          "name": "SEGARAJAYA"
        },
        {
          "id": "3216100008",
          "district_id": "3216100",
          "name": "SAMUDRAJAYA"
        },
        {
          "id": "3216110003",
          "district_id": "3216110",
          "name": "SUKAMAJU"
        },
        {
          "id": "3216110004",
          "district_id": "3216110",
          "name": "SUKARAJA"
        },
        {
          "id": "3216110005",
          "district_id": "3216110",
          "name": "SUKARAPIH"
        },
        {
          "id": "3216110006",
          "district_id": "3216110",
          "name": "SUKARAHAYU"
        },
        {
          "id": "3216110007",
          "district_id": "3216110",
          "name": "SUKABAKTI"
        },
        {
          "id": "3216110008",
          "district_id": "3216110",
          "name": "SUKAWIJAYA"
        },
        {
          "id": "3216110009",
          "district_id": "3216110",
          "name": "SUKAMANTRI"
        },
        {
          "id": "3216111001",
          "district_id": "3216111",
          "name": "SUKAMEKAR"
        },
        {
          "id": "3216111002",
          "district_id": "3216111",
          "name": "SUKADAYA"
        },
        {
          "id": "3216111003",
          "district_id": "3216111",
          "name": "SUKABUDI"
        },
        {
          "id": "3216111004",
          "district_id": "3216111",
          "name": "SUKAWANGI"
        },
        {
          "id": "3216111005",
          "district_id": "3216111",
          "name": "SUKAKERTA"
        },
        {
          "id": "3216111006",
          "district_id": "3216111",
          "name": "SUKARINGIN"
        },
        {
          "id": "3216111007",
          "district_id": "3216111",
          "name": "SUKATENANG"
        },
        {
          "id": "3216120001",
          "district_id": "3216120",
          "name": "SUKAASIH"
        },
        {
          "id": "3216120002",
          "district_id": "3216120",
          "name": "SUKARUKUN"
        },
        {
          "id": "3216120003",
          "district_id": "3216120",
          "name": "BANJARSARI"
        },
        {
          "id": "3216120004",
          "district_id": "3216120",
          "name": "SUKAHURIP"
        },
        {
          "id": "3216120005",
          "district_id": "3216120",
          "name": "SUKAMANAH"
        },
        {
          "id": "3216120006",
          "district_id": "3216120",
          "name": "SUKAMULYA"
        },
        {
          "id": "3216120007",
          "district_id": "3216120",
          "name": "SUKADARMA"
        },
        {
          "id": "3216121001",
          "district_id": "3216121",
          "name": "SUKAJADI"
        },
        {
          "id": "3216121002",
          "district_id": "3216121",
          "name": "SUKAMAKMUR"
        },
        {
          "id": "3216121003",
          "district_id": "3216121",
          "name": "SUKALAKSANA"
        },
        {
          "id": "3216121004",
          "district_id": "3216121",
          "name": "SUKAKARSA"
        },
        {
          "id": "3216121005",
          "district_id": "3216121",
          "name": "SUKAKARYA"
        },
        {
          "id": "3216121006",
          "district_id": "3216121",
          "name": "SUKAINDAH"
        },
        {
          "id": "3216121007",
          "district_id": "3216121",
          "name": "SUKAMURNI"
        },
        {
          "id": "3216130001",
          "district_id": "3216130",
          "name": "BANTARSARI"
        },
        {
          "id": "3216130002",
          "district_id": "3216130",
          "name": "BANTARJAYA"
        },
        {
          "id": "3216130003",
          "district_id": "3216130",
          "name": "KERTASARI"
        },
        {
          "id": "3216130004",
          "district_id": "3216130",
          "name": "KERTAJAYA"
        },
        {
          "id": "3216130005",
          "district_id": "3216130",
          "name": "KARANGHAUR"
        },
        {
          "id": "3216130006",
          "district_id": "3216130",
          "name": "KARANGPATRI"
        },
        {
          "id": "3216130007",
          "district_id": "3216130",
          "name": "KARANGREJA"
        },
        {
          "id": "3216130008",
          "district_id": "3216130",
          "name": "KARANGJAYA"
        },
        {
          "id": "3216130009",
          "district_id": "3216130",
          "name": "SUMBERSARI"
        },
        {
          "id": "3216130010",
          "district_id": "3216130",
          "name": "SUMBERREJA"
        },
        {
          "id": "3216130011",
          "district_id": "3216130",
          "name": "SUMBERURIP"
        },
        {
          "id": "3216130012",
          "district_id": "3216130",
          "name": "KARANGSEGAR"
        },
        {
          "id": "3216130013",
          "district_id": "3216130",
          "name": "KARANGHARJA"
        },
        {
          "id": "3216140001",
          "district_id": "3216140",
          "name": "JAYABAKTI"
        },
        {
          "id": "3216140002",
          "district_id": "3216140",
          "name": "SINDANGJAYA"
        },
        {
          "id": "3216140003",
          "district_id": "3216140",
          "name": "SINDANGSARI"
        },
        {
          "id": "3216140004",
          "district_id": "3216140",
          "name": "JAYALAKSANA"
        },
        {
          "id": "3216140005",
          "district_id": "3216140",
          "name": "SETIALAKSANA"
        },
        {
          "id": "3216140006",
          "district_id": "3216140",
          "name": "LENGGAHJAYA"
        },
        {
          "id": "3216140007",
          "district_id": "3216140",
          "name": "SETIAJAYA"
        },
        {
          "id": "3216140008",
          "district_id": "3216140",
          "name": "LENGGAHSARI"
        },
        {
          "id": "3216150001",
          "district_id": "3216150",
          "name": "PANTAI HARAPANJAYA"
        },
        {
          "id": "3216150002",
          "district_id": "3216150",
          "name": "PANTAIMEKAR"
        },
        {
          "id": "3216150003",
          "district_id": "3216150",
          "name": "PANTAI SEDERHANA"
        },
        {
          "id": "3216150004",
          "district_id": "3216150",
          "name": "PANTAIBAKTI"
        },
        {
          "id": "3216150005",
          "district_id": "3216150",
          "name": "PANTAIBAHAGIA"
        },
        {
          "id": "3216150006",
          "district_id": "3216150",
          "name": "JAYASAKTI"
        },
        {
          "id": "3217010001",
          "district_id": "3217010",
          "name": "CICADAS"
        },
        {
          "id": "3217010002",
          "district_id": "3217010",
          "name": "CIBEDUG"
        },
        {
          "id": "3217010003",
          "district_id": "3217010",
          "name": "SUKAMANAH"
        },
        {
          "id": "3217010004",
          "district_id": "3217010",
          "name": "BOJONG"
        },
        {
          "id": "3217010005",
          "district_id": "3217010",
          "name": "BOJONGSALAM"
        },
        {
          "id": "3217010006",
          "district_id": "3217010",
          "name": "CINENGAH"
        },
        {
          "id": "3217010007",
          "district_id": "3217010",
          "name": "SUKARESMI"
        },
        {
          "id": "3217010008",
          "district_id": "3217010",
          "name": "CIBITUNG"
        },
        {
          "id": "3217020001",
          "district_id": "3217020",
          "name": "CILANGARI"
        },
        {
          "id": "3217020002",
          "district_id": "3217020",
          "name": "SINDANGJAYA"
        },
        {
          "id": "3217020003",
          "district_id": "3217020",
          "name": "BUNIJAYA"
        },
        {
          "id": "3217020004",
          "district_id": "3217020",
          "name": "SIRNAJAYA"
        },
        {
          "id": "3217020005",
          "district_id": "3217020",
          "name": "GUNUNGHALU"
        },
        {
          "id": "3217020006",
          "district_id": "3217020",
          "name": "CELAK"
        },
        {
          "id": "3217020007",
          "district_id": "3217020",
          "name": "WARGASALUYU"
        },
        {
          "id": "3217020008",
          "district_id": "3217020",
          "name": "SUKASARI"
        },
        {
          "id": "3217020009",
          "district_id": "3217020",
          "name": "TAMANJAYA"
        },
        {
          "id": "3217030001",
          "district_id": "3217030",
          "name": "MEKARWANGI"
        },
        {
          "id": "3217030002",
          "district_id": "3217030",
          "name": "WENINGGALIH"
        },
        {
          "id": "3217030003",
          "district_id": "3217030",
          "name": "WANGUNSARI"
        },
        {
          "id": "3217030004",
          "district_id": "3217030",
          "name": "BUNINAGARA"
        },
        {
          "id": "3217030005",
          "district_id": "3217030",
          "name": "CIKADU"
        },
        {
          "id": "3217030006",
          "district_id": "3217030",
          "name": "RANCA SENGGANG"
        },
        {
          "id": "3217030007",
          "district_id": "3217030",
          "name": "CINTAKARYA"
        },
        {
          "id": "3217030008",
          "district_id": "3217030",
          "name": "CICANGKANG GIRANG"
        },
        {
          "id": "3217030009",
          "district_id": "3217030",
          "name": "PUNCAKSARI"
        },
        {
          "id": "3217030010",
          "district_id": "3217030",
          "name": "PASIRPOGOR"
        },
        {
          "id": "3217030011",
          "district_id": "3217030",
          "name": "SINDANGKERTA"
        },
        {
          "id": "3217040001",
          "district_id": "3217040",
          "name": "KARYAMUKTI"
        },
        {
          "id": "3217040002",
          "district_id": "3217040",
          "name": "NANGGERANG"
        },
        {
          "id": "3217040003",
          "district_id": "3217040",
          "name": "MUKAPAYUNG"
        },
        {
          "id": "3217040004",
          "district_id": "3217040",
          "name": "RANCAPANGGUNG"
        },
        {
          "id": "3217040005",
          "district_id": "3217040",
          "name": "BONGAS"
        },
        {
          "id": "3217040006",
          "district_id": "3217040",
          "name": "BATULAYANG"
        },
        {
          "id": "3217040007",
          "district_id": "3217040",
          "name": "CILILIN"
        },
        {
          "id": "3217040008",
          "district_id": "3217040",
          "name": "KARANGTANJUNG"
        },
        {
          "id": "3217040010",
          "district_id": "3217040",
          "name": "KIDANGPANANJUNG"
        },
        {
          "id": "3217040018",
          "district_id": "3217040",
          "name": "BUDIHARJA"
        },
        {
          "id": "3217040019",
          "district_id": "3217040",
          "name": "KARANGANYAR"
        },
        {
          "id": "3217050009",
          "district_id": "3217050",
          "name": "SINGAJAYA"
        },
        {
          "id": "3217050011",
          "district_id": "3217050",
          "name": "TANJUNGWANGI"
        },
        {
          "id": "3217050012",
          "district_id": "3217050",
          "name": "SITUWANGI"
        },
        {
          "id": "3217050013",
          "district_id": "3217050",
          "name": "PATARUMAN"
        },
        {
          "id": "3217050014",
          "district_id": "3217050",
          "name": "CIPATIK"
        },
        {
          "id": "3217050015",
          "district_id": "3217050",
          "name": "CITAPEN"
        },
        {
          "id": "3217050016",
          "district_id": "3217050",
          "name": "CIHAMPELAS"
        },
        {
          "id": "3217050017",
          "district_id": "3217050",
          "name": "MEKARMUKTI"
        },
        {
          "id": "3217050020",
          "district_id": "3217050",
          "name": "TANJUNGJAYA"
        },
        {
          "id": "3217050021",
          "district_id": "3217050",
          "name": "MEKARJAYA"
        },
        {
          "id": "3217060001",
          "district_id": "3217060",
          "name": "CINTAASIH"
        },
        {
          "id": "3217060002",
          "district_id": "3217060",
          "name": "KARANGSARI"
        },
        {
          "id": "3217060003",
          "district_id": "3217060",
          "name": "NEGLASARI"
        },
        {
          "id": "3217060004",
          "district_id": "3217060",
          "name": "GIRIMUKTI"
        },
        {
          "id": "3217060005",
          "district_id": "3217060",
          "name": "CIJENUK"
        },
        {
          "id": "3217060006",
          "district_id": "3217060",
          "name": "CICANGKANG HILIR"
        },
        {
          "id": "3217060007",
          "district_id": "3217060",
          "name": "SUKAMULYA"
        },
        {
          "id": "3217060008",
          "district_id": "3217060",
          "name": "CITALEM"
        },
        {
          "id": "3217060009",
          "district_id": "3217060",
          "name": "MEKARSARI"
        },
        {
          "id": "3217060010",
          "district_id": "3217060",
          "name": "SARINAGEN"
        },
        {
          "id": "3217060011",
          "district_id": "3217060",
          "name": "CIBENDA"
        },
        {
          "id": "3217060012",
          "district_id": "3217060",
          "name": "CIJAMBU"
        },
        {
          "id": "3217060013",
          "district_id": "3217060",
          "name": "SIRNAGALIH"
        },
        {
          "id": "3217060014",
          "district_id": "3217060",
          "name": "BARANANGSIANG"
        },
        {
          "id": "3217070001",
          "district_id": "3217070",
          "name": "SELACAU"
        },
        {
          "id": "3217070002",
          "district_id": "3217070",
          "name": "BATUJAJAR BARAT"
        },
        {
          "id": "3217070003",
          "district_id": "3217070",
          "name": "BATUJAJAR TIMUR"
        },
        {
          "id": "3217070004",
          "district_id": "3217070",
          "name": "GIRIASIH"
        },
        {
          "id": "3217070005",
          "district_id": "3217070",
          "name": "GALANGGANG"
        },
        {
          "id": "3217070006",
          "district_id": "3217070",
          "name": "PANGAUBAN"
        },
        {
          "id": "3217070007",
          "district_id": "3217070",
          "name": "CANGKORAH"
        },
        {
          "id": "3217071001",
          "district_id": "3217071",
          "name": "BOJONGHALEUANG"
        },
        {
          "id": "3217071002",
          "district_id": "3217071",
          "name": "CIKANDE"
        },
        {
          "id": "3217071003",
          "district_id": "3217071",
          "name": "GIRIMUKTI"
        },
        {
          "id": "3217071004",
          "district_id": "3217071",
          "name": "CIPANGERAN"
        },
        {
          "id": "3217071005",
          "district_id": "3217071",
          "name": "JATI"
        },
        {
          "id": "3217071006",
          "district_id": "3217071",
          "name": "SAGULING"
        },
        {
          "id": "3217080001",
          "district_id": "3217080",
          "name": "RAJAMANDALA KULON"
        },
        {
          "id": "3217080002",
          "district_id": "3217080",
          "name": "CIPTAHARJA"
        },
        {
          "id": "3217080003",
          "district_id": "3217080",
          "name": "CIPATAT"
        },
        {
          "id": "3217080004",
          "district_id": "3217080",
          "name": "CITATAH"
        },
        {
          "id": "3217080005",
          "district_id": "3217080",
          "name": "GUNUNGMASIGIT"
        },
        {
          "id": "3217080006",
          "district_id": "3217080",
          "name": "CIRAWAMEKAR"
        },
        {
          "id": "3217080007",
          "district_id": "3217080",
          "name": "NYALINDUNG"
        },
        {
          "id": "3217080008",
          "district_id": "3217080",
          "name": "SUMURBANDUNG"
        },
        {
          "id": "3217080009",
          "district_id": "3217080",
          "name": "KERTAMUKTI"
        },
        {
          "id": "3217080010",
          "district_id": "3217080",
          "name": "SARIMUKTI"
        },
        {
          "id": "3217080011",
          "district_id": "3217080",
          "name": "MANDALASARI"
        },
        {
          "id": "3217080012",
          "district_id": "3217080",
          "name": "MANDALAWANGI"
        },
        {
          "id": "3217090001",
          "district_id": "3217090",
          "name": "LAKSANAMEKAR"
        },
        {
          "id": "3217090002",
          "district_id": "3217090",
          "name": "CIMERANG"
        },
        {
          "id": "3217090003",
          "district_id": "3217090",
          "name": "CIPEUNDEUY"
        },
        {
          "id": "3217090004",
          "district_id": "3217090",
          "name": "KERTAJAYA"
        },
        {
          "id": "3217090005",
          "district_id": "3217090",
          "name": "JAYAMEKAR"
        },
        {
          "id": "3217090006",
          "district_id": "3217090",
          "name": "PADALARANG"
        },
        {
          "id": "3217090007",
          "district_id": "3217090",
          "name": "KERTAMULYA"
        },
        {
          "id": "3217090008",
          "district_id": "3217090",
          "name": "CIBURUY"
        },
        {
          "id": "3217090009",
          "district_id": "3217090",
          "name": "TAGOGAPU"
        },
        {
          "id": "3217090010",
          "district_id": "3217090",
          "name": "CEMPAKAMEKAR"
        },
        {
          "id": "3217100001",
          "district_id": "3217100",
          "name": "CIMAREME"
        },
        {
          "id": "3217100002",
          "district_id": "3217100",
          "name": "GADOBANGKONG"
        },
        {
          "id": "3217100003",
          "district_id": "3217100",
          "name": "TANIMULYA"
        },
        {
          "id": "3217100004",
          "district_id": "3217100",
          "name": "PAKUHAJI"
        },
        {
          "id": "3217100005",
          "district_id": "3217100",
          "name": "CILAME"
        },
        {
          "id": "3217100006",
          "district_id": "3217100",
          "name": "MARGAJAYA"
        },
        {
          "id": "3217100007",
          "district_id": "3217100",
          "name": "MEKARSARI"
        },
        {
          "id": "3217100008",
          "district_id": "3217100",
          "name": "NGAMPRAH"
        },
        {
          "id": "3217100009",
          "district_id": "3217100",
          "name": "SUKATANI"
        },
        {
          "id": "3217100010",
          "district_id": "3217100",
          "name": "CIMANGGU"
        },
        {
          "id": "3217100011",
          "district_id": "3217100",
          "name": "BOJONGKONENG"
        },
        {
          "id": "3217110001",
          "district_id": "3217110",
          "name": "CIWARUGA"
        },
        {
          "id": "3217110002",
          "district_id": "3217110",
          "name": "CIHIDEUNG"
        },
        {
          "id": "3217110003",
          "district_id": "3217110",
          "name": "CIGUGUR GIRANG"
        },
        {
          "id": "3217110004",
          "district_id": "3217110",
          "name": "SARIWANGI"
        },
        {
          "id": "3217110005",
          "district_id": "3217110",
          "name": "CIHANJUANG"
        },
        {
          "id": "3217110006",
          "district_id": "3217110",
          "name": "CIHANJUANG RAHAYU"
        },
        {
          "id": "3217110007",
          "district_id": "3217110",
          "name": "KARYAWANGI"
        },
        {
          "id": "3217120001",
          "district_id": "3217120",
          "name": "GUDANGKAHURIPAN"
        },
        {
          "id": "3217120002",
          "district_id": "3217120",
          "name": "WANGUNSARI"
        },
        {
          "id": "3217120003",
          "district_id": "3217120",
          "name": "PAGERWANGI"
        },
        {
          "id": "3217120004",
          "district_id": "3217120",
          "name": "MEKARWANGI"
        },
        {
          "id": "3217120005",
          "district_id": "3217120",
          "name": "LANGENSARI"
        },
        {
          "id": "3217120006",
          "district_id": "3217120",
          "name": "KAYUAMBON"
        },
        {
          "id": "3217120007",
          "district_id": "3217120",
          "name": "LEMBANG"
        },
        {
          "id": "3217120008",
          "district_id": "3217120",
          "name": "CIKAHURIPAN"
        },
        {
          "id": "3217120009",
          "district_id": "3217120",
          "name": "SUKAJAYA"
        },
        {
          "id": "3217120010",
          "district_id": "3217120",
          "name": "JAYAGIRI"
        },
        {
          "id": "3217120011",
          "district_id": "3217120",
          "name": "CIBOGO"
        },
        {
          "id": "3217120012",
          "district_id": "3217120",
          "name": "CIKOLE"
        },
        {
          "id": "3217120013",
          "district_id": "3217120",
          "name": "CIKIDANG"
        },
        {
          "id": "3217120014",
          "district_id": "3217120",
          "name": "WANGUNHARJA"
        },
        {
          "id": "3217120015",
          "district_id": "3217120",
          "name": "CIBODAS"
        },
        {
          "id": "3217120016",
          "district_id": "3217120",
          "name": "SUNTENJAYA"
        },
        {
          "id": "3217130001",
          "district_id": "3217130",
          "name": "PASIRHALANG"
        },
        {
          "id": "3217130002",
          "district_id": "3217130",
          "name": "JAMBUDIPA"
        },
        {
          "id": "3217130003",
          "district_id": "3217130",
          "name": "PADAASIH"
        },
        {
          "id": "3217130004",
          "district_id": "3217130",
          "name": "KERTAWANGI"
        },
        {
          "id": "3217130005",
          "district_id": "3217130",
          "name": "TUGUMUKTI"
        },
        {
          "id": "3217130006",
          "district_id": "3217130",
          "name": "PASIRLANGU"
        },
        {
          "id": "3217130007",
          "district_id": "3217130",
          "name": "CIPADA"
        },
        {
          "id": "3217130008",
          "district_id": "3217130",
          "name": "SADANGMEKAR"
        },
        {
          "id": "3217140001",
          "district_id": "3217140",
          "name": "KANANGASARI"
        },
        {
          "id": "3217140002",
          "district_id": "3217140",
          "name": "MANDALASARI"
        },
        {
          "id": "3217140003",
          "district_id": "3217140",
          "name": "MEKARJAYA"
        },
        {
          "id": "3217140004",
          "district_id": "3217140",
          "name": "CIPADA"
        },
        {
          "id": "3217140005",
          "district_id": "3217140",
          "name": "GANJARSARI"
        },
        {
          "id": "3217140006",
          "district_id": "3217140",
          "name": "MANDALAMUKTI"
        },
        {
          "id": "3217140007",
          "district_id": "3217140",
          "name": "CIPTAGUMATI"
        },
        {
          "id": "3217140008",
          "district_id": "3217140",
          "name": "CIKALONG"
        },
        {
          "id": "3217140010",
          "district_id": "3217140",
          "name": "PUTERAN"
        },
        {
          "id": "3217140011",
          "district_id": "3217140",
          "name": "TENJOLAUT"
        },
        {
          "id": "3217140012",
          "district_id": "3217140",
          "name": "CISOMANG BARAT"
        },
        {
          "id": "3217140013",
          "district_id": "3217140",
          "name": "WANGUNJAYA"
        },
        {
          "id": "3217150001",
          "district_id": "3217150",
          "name": "MARGALUYU"
        },
        {
          "id": "3217150002",
          "district_id": "3217150",
          "name": "NANGGELENG"
        },
        {
          "id": "3217150003",
          "district_id": "3217150",
          "name": "SIRNARAJA"
        },
        {
          "id": "3217150004",
          "district_id": "3217150",
          "name": "JATIMEKAR"
        },
        {
          "id": "3217150005",
          "district_id": "3217150",
          "name": "BOJONGMEKAR"
        },
        {
          "id": "3217150006",
          "district_id": "3217150",
          "name": "NYENANG"
        },
        {
          "id": "3217150007",
          "district_id": "3217150",
          "name": "CIPEUNDEUY"
        },
        {
          "id": "3217150008",
          "district_id": "3217150",
          "name": "MARGALAKSANA"
        },
        {
          "id": "3217150009",
          "district_id": "3217150",
          "name": "SUKAHAJI"
        },
        {
          "id": "3217150010",
          "district_id": "3217150",
          "name": "CIHARASHAS"
        },
        {
          "id": "3217150011",
          "district_id": "3217150",
          "name": "SIRNAGALIH"
        },
        {
          "id": "3217150012",
          "district_id": "3217150",
          "name": "CIROYOM"
        },
        {
          "id": "3218010001",
          "district_id": "3218010",
          "name": "KERTAMUKTI"
        },
        {
          "id": "3218010002",
          "district_id": "3218010",
          "name": "CIPARANTI"
        },
        {
          "id": "3218010003",
          "district_id": "3218010",
          "name": "LEGOKJAWA"
        },
        {
          "id": "3218010004",
          "district_id": "3218010",
          "name": "MASAWAH"
        },
        {
          "id": "3218010005",
          "district_id": "3218010",
          "name": "BATUMALANG"
        },
        {
          "id": "3218010006",
          "district_id": "3218010",
          "name": "CIMERAK"
        },
        {
          "id": "3218010007",
          "district_id": "3218010",
          "name": "LIMUSGEDE"
        },
        {
          "id": "3218010008",
          "district_id": "3218010",
          "name": "KERTAHARJA"
        },
        {
          "id": "3218010009",
          "district_id": "3218010",
          "name": "MEKARSARI"
        },
        {
          "id": "3218010010",
          "district_id": "3218010",
          "name": "SINDANGSARI"
        },
        {
          "id": "3218010011",
          "district_id": "3218010",
          "name": "SUKAJAYA"
        },
        {
          "id": "3218020001",
          "district_id": "3218020",
          "name": "CIAKAR"
        },
        {
          "id": "3218020002",
          "district_id": "3218020",
          "name": "CIBANTEN"
        },
        {
          "id": "3218020003",
          "district_id": "3218020",
          "name": "KERTAYASA"
        },
        {
          "id": "3218020004",
          "district_id": "3218020",
          "name": "BATUKARAS"
        },
        {
          "id": "3218020005",
          "district_id": "3218020",
          "name": "CIJULANG"
        },
        {
          "id": "3218020006",
          "district_id": "3218020",
          "name": "KONDANGJAJAR"
        },
        {
          "id": "3218020007",
          "district_id": "3218020",
          "name": "MARGACINTA"
        },
        {
          "id": "3218030001",
          "district_id": "3218030",
          "name": "KERTAJAYA"
        },
        {
          "id": "3218030002",
          "district_id": "3218030",
          "name": "BUNISARI"
        },
        {
          "id": "3218030003",
          "district_id": "3218030",
          "name": "CIMINDI"
        },
        {
          "id": "3218030004",
          "district_id": "3218030",
          "name": "CIGUGUR"
        },
        {
          "id": "3218030005",
          "district_id": "3218030",
          "name": "CAMPAKA"
        },
        {
          "id": "3218030006",
          "district_id": "3218030",
          "name": "PAGERBUMI"
        },
        {
          "id": "3218030007",
          "district_id": "3218030",
          "name": "HARUM MANDALA"
        },
        {
          "id": "3218040001",
          "district_id": "3218040",
          "name": "JADIMULYA"
        },
        {
          "id": "3218040002",
          "district_id": "3218040",
          "name": "BANGUNKARYA"
        },
        {
          "id": "3218040003",
          "district_id": "3218040",
          "name": "SUKAMULYA"
        },
        {
          "id": "3218040004",
          "district_id": "3218040",
          "name": "JADIKARYA"
        },
        {
          "id": "3218040005",
          "district_id": "3218040",
          "name": "BUNGURAYA"
        },
        {
          "id": "3218040006",
          "district_id": "3218040",
          "name": "BOJONG"
        },
        {
          "id": "3218040007",
          "district_id": "3218040",
          "name": "KARANGKAMIRI"
        },
        {
          "id": "3218040008",
          "district_id": "3218040",
          "name": "CISARUA"
        },
        {
          "id": "3218040009",
          "district_id": "3218040",
          "name": "CIMANGGU"
        },
        {
          "id": "3218040010",
          "district_id": "3218040",
          "name": "BANGUNJAYA"
        },
        {
          "id": "3218040011",
          "district_id": "3218040",
          "name": "LANGKAPLANCAR"
        },
        {
          "id": "3218040012",
          "district_id": "3218040",
          "name": "JAYASARI"
        },
        {
          "id": "3218040013",
          "district_id": "3218040",
          "name": "PANGKALAN"
        },
        {
          "id": "3218040014",
          "district_id": "3218040",
          "name": "BOJONGKONDANG"
        },
        {
          "id": "3218040015",
          "district_id": "3218040",
          "name": "MEKARWANGI"
        },
        {
          "id": "3218050001",
          "district_id": "3218050",
          "name": "KARANGJALADRI"
        },
        {
          "id": "3218050002",
          "district_id": "3218050",
          "name": "PARIGI"
        },
        {
          "id": "3218050003",
          "district_id": "3218050",
          "name": "KARANGBENDA"
        },
        {
          "id": "3218050004",
          "district_id": "3218050",
          "name": "CILIANG"
        },
        {
          "id": "3218050005",
          "district_id": "3218050",
          "name": "CIBENDA"
        },
        {
          "id": "3218050006",
          "district_id": "3218050",
          "name": "BOJONG"
        },
        {
          "id": "3218050007",
          "district_id": "3218050",
          "name": "SELASARI"
        },
        {
          "id": "3218050008",
          "district_id": "3218050",
          "name": "CINTARATU"
        },
        {
          "id": "3218050009",
          "district_id": "3218050",
          "name": "CINTAKARYA"
        },
        {
          "id": "3218050010",
          "district_id": "3218050",
          "name": "PARAKANMANGGU"
        },
        {
          "id": "3218060001",
          "district_id": "3218060",
          "name": "SUKARESIK"
        },
        {
          "id": "3218060002",
          "district_id": "3218060",
          "name": "CIKEMBULAN"
        },
        {
          "id": "3218060003",
          "district_id": "3218060",
          "name": "PAJATEN"
        },
        {
          "id": "3218060004",
          "district_id": "3218060",
          "name": "SIDAMULIH"
        },
        {
          "id": "3218060005",
          "district_id": "3218060",
          "name": "CIKALONG"
        },
        {
          "id": "3218060006",
          "district_id": "3218060",
          "name": "KERSARATU"
        },
        {
          "id": "3218060007",
          "district_id": "3218060",
          "name": "KALIJATI"
        },
        {
          "id": "3218070001",
          "district_id": "3218070",
          "name": "WONOHARJO"
        },
        {
          "id": "3218070002",
          "district_id": "3218070",
          "name": "PANANJUNG"
        },
        {
          "id": "3218070003",
          "district_id": "3218070",
          "name": "PANGANDARAN"
        },
        {
          "id": "3218070004",
          "district_id": "3218070",
          "name": "BABAKAN"
        },
        {
          "id": "3218070005",
          "district_id": "3218070",
          "name": "SUKAHURIP"
        },
        {
          "id": "3218070006",
          "district_id": "3218070",
          "name": "PURBAHAYU"
        },
        {
          "id": "3218070007",
          "district_id": "3218070",
          "name": "SIDOMULYO"
        },
        {
          "id": "3218070008",
          "district_id": "3218070",
          "name": "PAGERGUNUNG"
        },
        {
          "id": "3218080001",
          "district_id": "3218080",
          "name": "PUTRAPINGGAN"
        },
        {
          "id": "3218080002",
          "district_id": "3218080",
          "name": "EMPLAK"
        },
        {
          "id": "3218080003",
          "district_id": "3218080",
          "name": "BAGOLO"
        },
        {
          "id": "3218080004",
          "district_id": "3218080",
          "name": "PAMOTAN"
        },
        {
          "id": "3218080005",
          "district_id": "3218080",
          "name": "KALIPUCANG"
        },
        {
          "id": "3218080006",
          "district_id": "3218080",
          "name": "CIBULUH"
        },
        {
          "id": "3218080007",
          "district_id": "3218080",
          "name": "BANJARHARJA"
        },
        {
          "id": "3218080008",
          "district_id": "3218080",
          "name": "TUNGGILIS"
        },
        {
          "id": "3218080009",
          "district_id": "3218080",
          "name": "CIPARAKAN"
        },
        {
          "id": "3218090001",
          "district_id": "3218090",
          "name": "PANYUTRAN"
        },
        {
          "id": "3218090002",
          "district_id": "3218090",
          "name": "BOJONGSARI"
        },
        {
          "id": "3218090003",
          "district_id": "3218090",
          "name": "CIGANJENG"
        },
        {
          "id": "3218090004",
          "district_id": "3218090",
          "name": "SUKANAGARA"
        },
        {
          "id": "3218090005",
          "district_id": "3218090",
          "name": "SINDANGWANGI"
        },
        {
          "id": "3218090006",
          "district_id": "3218090",
          "name": "KARANGSARI"
        },
        {
          "id": "3218090007",
          "district_id": "3218090",
          "name": "PALEDAH"
        },
        {
          "id": "3218090008",
          "district_id": "3218090",
          "name": "MARUYUNG SARI"
        },
        {
          "id": "3218090009",
          "district_id": "3218090",
          "name": "KARANGPAWITAN"
        },
        {
          "id": "3218090010",
          "district_id": "3218090",
          "name": "PADAHERANG"
        },
        {
          "id": "3218090012",
          "district_id": "3218090",
          "name": "KARANGMULYA"
        },
        {
          "id": "3218090013",
          "district_id": "3218090",
          "name": "CIBOGO"
        },
        {
          "id": "3218090014",
          "district_id": "3218090",
          "name": "PASIRGEULIS"
        },
        {
          "id": "3218100001",
          "district_id": "3218100",
          "name": "SUKAMAJU"
        },
        {
          "id": "3218100002",
          "district_id": "3218100",
          "name": "KERTAJAYA"
        },
        {
          "id": "3218100003",
          "district_id": "3218100",
          "name": "MANGUNJAYA"
        },
        {
          "id": "3218100004",
          "district_id": "3218100",
          "name": "JANGRAGA"
        },
        {
          "id": "3218100005",
          "district_id": "3218100",
          "name": "SINDANG JAYA"
        },
        {
          "id": "3271010001",
          "district_id": "3271010",
          "name": "MULYAHARJA"
        },
        {
          "id": "3271010002",
          "district_id": "3271010",
          "name": "PAMOYANAN"
        },
        {
          "id": "3271010003",
          "district_id": "3271010",
          "name": "RANGGAMEKAR"
        },
        {
          "id": "3271010005",
          "district_id": "3271010",
          "name": "KERTAMAYA"
        },
        {
          "id": "3271010006",
          "district_id": "3271010",
          "name": "RANCAMAYA"
        },
        {
          "id": "3271010007",
          "district_id": "3271010",
          "name": "BOJONGKERTA"
        },
        {
          "id": "3271010008",
          "district_id": "3271010",
          "name": "HARJASARI"
        },
        {
          "id": "3271010009",
          "district_id": "3271010",
          "name": "MUARASARI"
        },
        {
          "id": "3271010010",
          "district_id": "3271010",
          "name": "PAKUAN"
        },
        {
          "id": "3271010011",
          "district_id": "3271010",
          "name": "CIPAKU"
        },
        {
          "id": "3271010012",
          "district_id": "3271010",
          "name": "LAWANGGINTUNG"
        },
        {
          "id": "3271010013",
          "district_id": "3271010",
          "name": "BATUTULIS"
        },
        {
          "id": "3271010014",
          "district_id": "3271010",
          "name": "BONDONGAN"
        },
        {
          "id": "3271010015",
          "district_id": "3271010",
          "name": "EMPANG"
        },
        {
          "id": "3271010016",
          "district_id": "3271010",
          "name": "CIKARET"
        },
        {
          "id": "3271020001",
          "district_id": "3271020",
          "name": "SINDANGSARI"
        },
        {
          "id": "3271020002",
          "district_id": "3271020",
          "name": "SINDANGRASA"
        },
        {
          "id": "3271020003",
          "district_id": "3271020",
          "name": "TAJUR"
        },
        {
          "id": "3271020004",
          "district_id": "3271020",
          "name": "KATULAMPA"
        },
        {
          "id": "3271020005",
          "district_id": "3271020",
          "name": "BARANANGSIANG"
        },
        {
          "id": "3271020006",
          "district_id": "3271020",
          "name": "SUKASARI"
        },
        {
          "id": "3271030001",
          "district_id": "3271030",
          "name": "BANTARJATI"
        },
        {
          "id": "3271030002",
          "district_id": "3271030",
          "name": "TEGALGUNDIL"
        },
        {
          "id": "3271030003",
          "district_id": "3271030",
          "name": "TANAHBARU"
        },
        {
          "id": "3271030004",
          "district_id": "3271030",
          "name": "CIMAHPAR"
        },
        {
          "id": "3271030005",
          "district_id": "3271030",
          "name": "CILUAR"
        },
        {
          "id": "3271030006",
          "district_id": "3271030",
          "name": "CIBULUH"
        },
        {
          "id": "3271030007",
          "district_id": "3271030",
          "name": "KEDUNGHALANG"
        },
        {
          "id": "3271030008",
          "district_id": "3271030",
          "name": "CIPARIGI"
        },
        {
          "id": "3271040001",
          "district_id": "3271040",
          "name": "PALEDANG"
        },
        {
          "id": "3271040002",
          "district_id": "3271040",
          "name": "GUDANG"
        },
        {
          "id": "3271040003",
          "district_id": "3271040",
          "name": "BABAKANPASAR"
        },
        {
          "id": "3271040004",
          "district_id": "3271040",
          "name": "TEGALLEGA"
        },
        {
          "id": "3271040005",
          "district_id": "3271040",
          "name": "BABAKAN"
        },
        {
          "id": "3271040007",
          "district_id": "3271040",
          "name": "PABATON"
        },
        {
          "id": "3271040008",
          "district_id": "3271040",
          "name": "CIBOGOR"
        },
        {
          "id": "3271040009",
          "district_id": "3271040",
          "name": "PANARAGAN"
        },
        {
          "id": "3271040010",
          "district_id": "3271040",
          "name": "KEBONKELAPA"
        },
        {
          "id": "3271040011",
          "district_id": "3271040",
          "name": "CIWARINGIN"
        },
        {
          "id": "3271050001",
          "district_id": "3271050",
          "name": "PASIRMULYA"
        },
        {
          "id": "3271050002",
          "district_id": "3271050",
          "name": "PASIRKUDA"
        },
        {
          "id": "3271050003",
          "district_id": "3271050",
          "name": "PASIRJAYA"
        },
        {
          "id": "3271050004",
          "district_id": "3271050",
          "name": "GUNUNGBATU"
        },
        {
          "id": "3271050005",
          "district_id": "3271050",
          "name": "LOJI"
        },
        {
          "id": "3271050007",
          "district_id": "3271050",
          "name": "CILENDEK TIMUR"
        },
        {
          "id": "3271050008",
          "district_id": "3271050",
          "name": "CILENDEK BARAT"
        },
        {
          "id": "3271050009",
          "district_id": "3271050",
          "name": "SINDANGBARANG"
        },
        {
          "id": "3271050010",
          "district_id": "3271050",
          "name": "MARGAJAYA"
        },
        {
          "id": "3271050011",
          "district_id": "3271050",
          "name": "BALUNGBANGJAYA"
        },
        {
          "id": "3271050012",
          "district_id": "3271050",
          "name": "SITUGEDE"
        },
        {
          "id": "3271050013",
          "district_id": "3271050",
          "name": "BUBULAK"
        },
        {
          "id": "3271050014",
          "district_id": "3271050",
          "name": "SEMPLAK"
        },
        {
          "id": "3271050015",
          "district_id": "3271050",
          "name": "CURUGMEKAR"
        },
        {
          "id": "3271060001",
          "district_id": "3271060",
          "name": "KEDUNGWARINGIN"
        },
        {
          "id": "3271060002",
          "district_id": "3271060",
          "name": "KEDUNGJAYA"
        },
        {
          "id": "3271060003",
          "district_id": "3271060",
          "name": "KEBONPEDES"
        },
        {
          "id": "3271060004",
          "district_id": "3271060",
          "name": "TANAHSAREAL"
        },
        {
          "id": "3271060005",
          "district_id": "3271060",
          "name": "KEDUNGBADAK"
        },
        {
          "id": "3271060006",
          "district_id": "3271060",
          "name": "SUKARESMI"
        },
        {
          "id": "3271060007",
          "district_id": "3271060",
          "name": "SUKADAMAI"
        },
        {
          "id": "3271060008",
          "district_id": "3271060",
          "name": "CIBADAK"
        },
        {
          "id": "3271060009",
          "district_id": "3271060",
          "name": "KAYUMANIS"
        },
        {
          "id": "3271060010",
          "district_id": "3271060",
          "name": "MEKARWANGI"
        },
        {
          "id": "3271060011",
          "district_id": "3271060",
          "name": "KENCANA"
        },
        {
          "id": "3272010006",
          "district_id": "3272010",
          "name": "SUDAJAYA HILIR"
        },
        {
          "id": "3272010007",
          "district_id": "3272010",
          "name": "JAYAMEKAR"
        },
        {
          "id": "3272010008",
          "district_id": "3272010",
          "name": "JAYARAKSA"
        },
        {
          "id": "3272010009",
          "district_id": "3272010",
          "name": "BAROS"
        },
        {
          "id": "3272011001",
          "district_id": "3272011",
          "name": "LEMBURSITU"
        },
        {
          "id": "3272011002",
          "district_id": "3272011",
          "name": "SITUMEKAR"
        },
        {
          "id": "3272011003",
          "district_id": "3272011",
          "name": "CIPANENGAH"
        },
        {
          "id": "3272011004",
          "district_id": "3272011",
          "name": "CIKUNDUL"
        },
        {
          "id": "3272011005",
          "district_id": "3272011",
          "name": "SINDANGSARI"
        },
        {
          "id": "3272012001",
          "district_id": "3272012",
          "name": "SINDANGPALAY"
        },
        {
          "id": "3272012002",
          "district_id": "3272012",
          "name": "LIMUSNUNGGAL"
        },
        {
          "id": "3272012003",
          "district_id": "3272012",
          "name": "BABAKAN"
        },
        {
          "id": "3272012004",
          "district_id": "3272012",
          "name": "CIBEUREUM HILIR"
        },
        {
          "id": "3272020001",
          "district_id": "3272020",
          "name": "CIKONDANG"
        },
        {
          "id": "3272020002",
          "district_id": "3272020",
          "name": "GEDONG PANJANG"
        },
        {
          "id": "3272020003",
          "district_id": "3272020",
          "name": "CITAMIANG"
        },
        {
          "id": "3272020004",
          "district_id": "3272020",
          "name": "NANGGELENG"
        },
        {
          "id": "3272020005",
          "district_id": "3272020",
          "name": "TIPAR"
        },
        {
          "id": "3272030001",
          "district_id": "3272030",
          "name": "DAYEUHLUHUR"
        },
        {
          "id": "3272030002",
          "district_id": "3272030",
          "name": "WARUDOYONG"
        },
        {
          "id": "3272030003",
          "district_id": "3272030",
          "name": "NYOMPLONG"
        },
        {
          "id": "3272030005",
          "district_id": "3272030",
          "name": "SUKAKARYA"
        },
        {
          "id": "3272040001",
          "district_id": "3272040",
          "name": "KARANG TENGAH"
        },
        {
          "id": "3272040003",
          "district_id": "3272040",
          "name": "SRIWIDARI"
        },
        {
          "id": "3272040004",
          "district_id": "3272040",
          "name": "KARAMAT"
        },
        {
          "id": "3272050001",
          "district_id": "3272050",
          "name": "GUNUNG PARANG"
        },
        {
          "id": "3272050002",
          "district_id": "3272050",
          "name": "KEBONJATI"
        },
        {
          "id": "3272050003",
          "district_id": "3272050",
          "name": "CIKOLE"
        },
        {
          "id": "3272050004",
          "district_id": "3272050",
          "name": "SELABATU"
        },
        {
          "id": "3272050005",
          "district_id": "3272050",
          "name": "CISARUA"
        },
        {
          "id": "3272050006",
          "district_id": "3272050",
          "name": "SUBANGJAYA"
        },
        {
          "id": "3273010001",
          "district_id": "3273010",
          "name": "GEMPOL SARI"
        },
        {
          "id": "3273010002",
          "district_id": "3273010",
          "name": "CIGONDEWAH KALER"
        },
        {
          "id": "3273010003",
          "district_id": "3273010",
          "name": "CIGONDEWAH KIDUL"
        },
        {
          "id": "3273010004",
          "district_id": "3273010",
          "name": "CIGONDEWAH RAHAYU"
        },
        {
          "id": "3273010005",
          "district_id": "3273010",
          "name": "CARINGIN"
        },
        {
          "id": "3273010006",
          "district_id": "3273010",
          "name": "WARUNG MUNCANG"
        },
        {
          "id": "3273010007",
          "district_id": "3273010",
          "name": "CIBUNTU"
        },
        {
          "id": "3273010008",
          "district_id": "3273010",
          "name": "CIJERAH"
        },
        {
          "id": "3273020001",
          "district_id": "3273020",
          "name": "MARGASUKA"
        },
        {
          "id": "3273020002",
          "district_id": "3273020",
          "name": "CIRANGRANG"
        },
        {
          "id": "3273020003",
          "district_id": "3273020",
          "name": "MARGAHAYU UTARA"
        },
        {
          "id": "3273020004",
          "district_id": "3273020",
          "name": "BABAKAN CIPARAY"
        },
        {
          "id": "3273020005",
          "district_id": "3273020",
          "name": "BABAKAN"
        },
        {
          "id": "3273020006",
          "district_id": "3273020",
          "name": "SUKAHAJI"
        },
        {
          "id": "3273030001",
          "district_id": "3273030",
          "name": "KOPO"
        },
        {
          "id": "3273030002",
          "district_id": "3273030",
          "name": "SUKA ASIH"
        },
        {
          "id": "3273030003",
          "district_id": "3273030",
          "name": "BABAKAN ASIH"
        },
        {
          "id": "3273030004",
          "district_id": "3273030",
          "name": "BABAKAN TAROGONG"
        },
        {
          "id": "3273030005",
          "district_id": "3273030",
          "name": "JAMIKA"
        },
        {
          "id": "3273040001",
          "district_id": "3273040",
          "name": "CIBADUYUT KIDUL"
        },
        {
          "id": "3273040002",
          "district_id": "3273040",
          "name": "CIBADUYUT WETAN"
        },
        {
          "id": "3273040003",
          "district_id": "3273040",
          "name": "MEKAR WANGI"
        },
        {
          "id": "3273040004",
          "district_id": "3273040",
          "name": "CIBADUYUT"
        },
        {
          "id": "3273040005",
          "district_id": "3273040",
          "name": "KEBON LEGA"
        },
        {
          "id": "3273040006",
          "district_id": "3273040",
          "name": "SITUSAEUR"
        },
        {
          "id": "3273050001",
          "district_id": "3273050",
          "name": "KARASAK"
        },
        {
          "id": "3273050002",
          "district_id": "3273050",
          "name": "PELINDUNG HEWAN"
        },
        {
          "id": "3273050004",
          "district_id": "3273050",
          "name": "PANJUNAN"
        },
        {
          "id": "3273050005",
          "district_id": "3273050",
          "name": "CIBADAK"
        },
        {
          "id": "3273050006",
          "district_id": "3273050",
          "name": "KARANG ANYAR"
        },
        {
          "id": "3273060001",
          "district_id": "3273060",
          "name": "CISEUREUH"
        },
        {
          "id": "3273060002",
          "district_id": "3273060",
          "name": "PASIRLUYU"
        },
        {
          "id": "3273060003",
          "district_id": "3273060",
          "name": "ANCOL"
        },
        {
          "id": "3273060004",
          "district_id": "3273060",
          "name": "CIGERELENG"
        },
        {
          "id": "3273060005",
          "district_id": "3273060",
          "name": "CIATEUL"
        },
        {
          "id": "3273060007",
          "district_id": "3273060",
          "name": "BALONG GEDE"
        },
        {
          "id": "3273070001",
          "district_id": "3273070",
          "name": "CIJAGRA"
        },
        {
          "id": "3273070002",
          "district_id": "3273070",
          "name": "TURANGGA"
        },
        {
          "id": "3273070003",
          "district_id": "3273070",
          "name": "LINGKAR SELATAN"
        },
        {
          "id": "3273070004",
          "district_id": "3273070",
          "name": "MALABAR"
        },
        {
          "id": "3273070005",
          "district_id": "3273070",
          "name": "BURANGRANG"
        },
        {
          "id": "3273070006",
          "district_id": "3273070",
          "name": "CIKAWAO"
        },
        {
          "id": "3273070007",
          "district_id": "3273070",
          "name": "PALEDANG"
        },
        {
          "id": "3273080001",
          "district_id": "3273080",
          "name": "WATES"
        },
        {
          "id": "3273080003",
          "district_id": "3273080",
          "name": "BATUNUNGGAL"
        },
        {
          "id": "3273080004",
          "district_id": "3273080",
          "name": "KUJANGSARI"
        },
        {
          "id": "3273090001",
          "district_id": "3273090",
          "name": "CIJAURA"
        },
        {
          "id": "3273090002",
          "district_id": "3273090",
          "name": "MARGASARI"
        },
        {
          "id": "3273090003",
          "district_id": "3273090",
          "name": "SEKEJATI"
        },
        {
          "id": "3273090004",
          "district_id": "3273090",
          "name": "JATI SARI"
        },
        {
          "id": "3273100001",
          "district_id": "3273100",
          "name": "DERWATI"
        },
        {
          "id": "3273100002",
          "district_id": "3273100",
          "name": "CIPAMOKOLAN"
        },
        {
          "id": "3273100005",
          "district_id": "3273100",
          "name": "MANJAHLEGA"
        },
        {
          "id": "3273100006",
          "district_id": "3273100",
          "name": "MEKARJAYA"
        },
        {
          "id": "3273101001",
          "district_id": "3273101",
          "name": "RANCABOLANG"
        },
        {
          "id": "3273101002",
          "district_id": "3273101",
          "name": "RANCANUMPANG"
        },
        {
          "id": "3273101003",
          "district_id": "3273101",
          "name": "CISARANTEN KIDUL"
        },
        {
          "id": "3273101004",
          "district_id": "3273101",
          "name": "CIMINCRANG"
        },
        {
          "id": "3273110003",
          "district_id": "3273110",
          "name": "PASIR BIRU"
        },
        {
          "id": "3273110004",
          "district_id": "3273110",
          "name": "CIPADUNG"
        },
        {
          "id": "3273110005",
          "district_id": "3273110",
          "name": "PALASARI"
        },
        {
          "id": "3273110006",
          "district_id": "3273110",
          "name": "CISURUPAN"
        },
        {
          "id": "3273111001",
          "district_id": "3273111",
          "name": "MEKAR MULYA"
        },
        {
          "id": "3273111002",
          "district_id": "3273111",
          "name": "CIPADUNG KIDUL"
        },
        {
          "id": "3273111003",
          "district_id": "3273111",
          "name": "CIPADUNG WETAN"
        },
        {
          "id": "3273111004",
          "district_id": "3273111",
          "name": "CIPADUNG KULON"
        },
        {
          "id": "3273120003",
          "district_id": "3273120",
          "name": "PASANGGRAHAN"
        },
        {
          "id": "3273120004",
          "district_id": "3273120",
          "name": "PASIRJATI"
        },
        {
          "id": "3273120005",
          "district_id": "3273120",
          "name": "PASIR WANGI"
        },
        {
          "id": "3273120006",
          "district_id": "3273120",
          "name": "CIGENDING"
        },
        {
          "id": "3273120007",
          "district_id": "3273120",
          "name": "PASIR ENDAH"
        },
        {
          "id": "3273121001",
          "district_id": "3273121",
          "name": "CISARANTEN WETAN"
        },
        {
          "id": "3273121002",
          "district_id": "3273121",
          "name": "BABAKAN PENGHULU"
        },
        {
          "id": "3273121003",
          "district_id": "3273121",
          "name": "PAKEMITAN"
        },
        {
          "id": "3273121004",
          "district_id": "3273121",
          "name": "SUKAMULYA"
        },
        {
          "id": "3273130001",
          "district_id": "3273130",
          "name": "CISARANTEN KULON"
        },
        {
          "id": "3273130002",
          "district_id": "3273130",
          "name": "CISARANTEN BINA HARAPAN"
        },
        {
          "id": "3273130003",
          "district_id": "3273130",
          "name": "SUKAMISKIN"
        },
        {
          "id": "3273130005",
          "district_id": "3273130",
          "name": "CISARANTEN ENDAH"
        },
        {
          "id": "3273141001",
          "district_id": "3273141",
          "name": "ANTAPANI KIDUL"
        },
        {
          "id": "3273141002",
          "district_id": "3273141",
          "name": "ANTAPANI TENGAH"
        },
        {
          "id": "3273141003",
          "district_id": "3273141",
          "name": "ANTAPANI WETAN"
        },
        {
          "id": "3273141004",
          "district_id": "3273141",
          "name": "ANTAPANI KULON"
        },
        {
          "id": "3273142001",
          "district_id": "3273142",
          "name": "JATIHANDAP"
        },
        {
          "id": "3273142002",
          "district_id": "3273142",
          "name": "KARANG PAMULANG"
        },
        {
          "id": "3273142003",
          "district_id": "3273142",
          "name": "SINDANG JAYA"
        },
        {
          "id": "3273142004",
          "district_id": "3273142",
          "name": "PASIR IMPUN"
        },
        {
          "id": "3273150001",
          "district_id": "3273150",
          "name": "KEBON KANGKUNG"
        },
        {
          "id": "3273150002",
          "district_id": "3273150",
          "name": "SUKAPURA"
        },
        {
          "id": "3273150003",
          "district_id": "3273150",
          "name": "KEBUN JAYANTI"
        },
        {
          "id": "3273150004",
          "district_id": "3273150",
          "name": "BABAKAN SARI"
        },
        {
          "id": "3273150005",
          "district_id": "3273150",
          "name": "BABAKAN SURABAYA"
        },
        {
          "id": "3273150006",
          "district_id": "3273150",
          "name": "CICAHEUM"
        },
        {
          "id": "3273160002",
          "district_id": "3273160",
          "name": "BINONG"
        },
        {
          "id": "3273160003",
          "district_id": "3273160",
          "name": "KEBON GEDANG"
        },
        {
          "id": "3273160004",
          "district_id": "3273160",
          "name": "MALEER"
        },
        {
          "id": "3273160005",
          "district_id": "3273160",
          "name": "CIBANGKONG"
        },
        {
          "id": "3273160006",
          "district_id": "3273160",
          "name": "SAMOJA"
        },
        {
          "id": "3273160007",
          "district_id": "3273160",
          "name": "KACAPIRING"
        },
        {
          "id": "3273160008",
          "district_id": "3273160",
          "name": "KEBON WARU"
        },
        {
          "id": "3273170001",
          "district_id": "3273170",
          "name": "BRAGA"
        },
        {
          "id": "3273170002",
          "district_id": "3273170",
          "name": "KEBON PISANG"
        },
        {
          "id": "3273170003",
          "district_id": "3273170",
          "name": "MERDEKA"
        },
        {
          "id": "3273170004",
          "district_id": "3273170",
          "name": "BABAKAN CIAMIS"
        },
        {
          "id": "3273180001",
          "district_id": "3273180",
          "name": "CAMPAKA"
        },
        {
          "id": "3273180002",
          "district_id": "3273180",
          "name": "MALEBER"
        },
        {
          "id": "3273180003",
          "district_id": "3273180",
          "name": "GARUDA"
        },
        {
          "id": "3273180004",
          "district_id": "3273180",
          "name": "DUNGUS CARIANG"
        },
        {
          "id": "3273180005",
          "district_id": "3273180",
          "name": "CIROYOM"
        },
        {
          "id": "3273180006",
          "district_id": "3273180",
          "name": "KEBON JERUK"
        },
        {
          "id": "3273190001",
          "district_id": "3273190",
          "name": "ARJUNA"
        },
        {
          "id": "3273190002",
          "district_id": "3273190",
          "name": "PASIRKALIKI"
        },
        {
          "id": "3273190003",
          "district_id": "3273190",
          "name": "PAMOYANAN"
        },
        {
          "id": "3273190004",
          "district_id": "3273190",
          "name": "PAJAJARAN"
        },
        {
          "id": "3273190005",
          "district_id": "3273190",
          "name": "HUSEN SASTRANEGARA"
        },
        {
          "id": "3273190006",
          "district_id": "3273190",
          "name": "SUKARAJA"
        },
        {
          "id": "3273200001",
          "district_id": "3273200",
          "name": "TAMAN SARI"
        },
        {
          "id": "3273200002",
          "district_id": "3273200",
          "name": "CITARUM"
        },
        {
          "id": "3273200003",
          "district_id": "3273200",
          "name": "CIHAPIT"
        },
        {
          "id": "3273210001",
          "district_id": "3273210",
          "name": "SUKAMAJU"
        },
        {
          "id": "3273210002",
          "district_id": "3273210",
          "name": "CICADAS"
        },
        {
          "id": "3273210003",
          "district_id": "3273210",
          "name": "CIKUTRA"
        },
        {
          "id": "3273210004",
          "district_id": "3273210",
          "name": "PADASUKA"
        },
        {
          "id": "3273210005",
          "district_id": "3273210",
          "name": "PASIRLAYUNG"
        },
        {
          "id": "3273210006",
          "district_id": "3273210",
          "name": "SUKAPADA"
        },
        {
          "id": "3273220001",
          "district_id": "3273220",
          "name": "CIHAURGEULIS"
        },
        {
          "id": "3273220002",
          "district_id": "3273220",
          "name": "SUKALUYU"
        },
        {
          "id": "3273220003",
          "district_id": "3273220",
          "name": "NEGLASARI"
        },
        {
          "id": "3273220004",
          "district_id": "3273220",
          "name": "CIGADUNG"
        },
        {
          "id": "3273230001",
          "district_id": "3273230",
          "name": "CIPAGANTI"
        },
        {
          "id": "3273230002",
          "district_id": "3273230",
          "name": "LEBAK SILIWANGI"
        },
        {
          "id": "3273230003",
          "district_id": "3273230",
          "name": "LEBAK GEDE"
        },
        {
          "id": "3273230004",
          "district_id": "3273230",
          "name": "SADANG SERANG"
        },
        {
          "id": "3273230005",
          "district_id": "3273230",
          "name": "SEKELOA"
        },
        {
          "id": "3273230006",
          "district_id": "3273230",
          "name": "DAGO"
        },
        {
          "id": "3273240001",
          "district_id": "3273240",
          "name": "SUKAWARNA"
        },
        {
          "id": "3273240002",
          "district_id": "3273240",
          "name": "SUKAGALIH"
        },
        {
          "id": "3273240003",
          "district_id": "3273240",
          "name": "SUKABUNGAH"
        },
        {
          "id": "3273240004",
          "district_id": "3273240",
          "name": "CIPEDES"
        },
        {
          "id": "3273240005",
          "district_id": "3273240",
          "name": "PASTEUR"
        },
        {
          "id": "3273250001",
          "district_id": "3273250",
          "name": "SARIJADI"
        },
        {
          "id": "3273250002",
          "district_id": "3273250",
          "name": "SUKARASA"
        },
        {
          "id": "3273250003",
          "district_id": "3273250",
          "name": "GEGERKALONG"
        },
        {
          "id": "3273250004",
          "district_id": "3273250",
          "name": "ISOLA"
        },
        {
          "id": "3273260001",
          "district_id": "3273260",
          "name": "HEGARMANAH"
        },
        {
          "id": "3273260002",
          "district_id": "3273260",
          "name": "CIUMBULEUIT"
        },
        {
          "id": "3274010001",
          "district_id": "3274010",
          "name": "ARGASUNYA"
        },
        {
          "id": "3274010002",
          "district_id": "3274010",
          "name": "KALIJAGA"
        },
        {
          "id": "3274010003",
          "district_id": "3274010",
          "name": "HARJAMUKTI"
        },
        {
          "id": "3274010004",
          "district_id": "3274010",
          "name": "KECAPI"
        },
        {
          "id": "3274010005",
          "district_id": "3274010",
          "name": "LARANGAN"
        },
        {
          "id": "3274020001",
          "district_id": "3274020",
          "name": "PEGAMBIRAN"
        },
        {
          "id": "3274020002",
          "district_id": "3274020",
          "name": "KESEPUHAN"
        },
        {
          "id": "3274020003",
          "district_id": "3274020",
          "name": "LEMAHWUNGKUK"
        },
        {
          "id": "3274020004",
          "district_id": "3274020",
          "name": "PANJUNAN"
        },
        {
          "id": "3274030001",
          "district_id": "3274030",
          "name": "JAGASATRU"
        },
        {
          "id": "3274030002",
          "district_id": "3274030",
          "name": "PULASAREN"
        },
        {
          "id": "3274030003",
          "district_id": "3274030",
          "name": "PEKALIPAN"
        },
        {
          "id": "3274030004",
          "district_id": "3274030",
          "name": "PEKALANGAN"
        },
        {
          "id": "3274040001",
          "district_id": "3274040",
          "name": "KARYAMULYA"
        },
        {
          "id": "3274040002",
          "district_id": "3274040",
          "name": "SUNYARAGI"
        },
        {
          "id": "3274040003",
          "district_id": "3274040",
          "name": "DRAJAT"
        },
        {
          "id": "3274040004",
          "district_id": "3274040",
          "name": "KESAMBI"
        },
        {
          "id": "3274040005",
          "district_id": "3274040",
          "name": "PEKIRINGAN"
        },
        {
          "id": "3274050001",
          "district_id": "3274050",
          "name": "KEJAKSAN"
        },
        {
          "id": "3274050002",
          "district_id": "3274050",
          "name": "KEBONBARU"
        },
        {
          "id": "3274050003",
          "district_id": "3274050",
          "name": "SUKAPURA"
        },
        {
          "id": "3275010008",
          "district_id": "3275010",
          "name": "JATIMAKMUR"
        },
        {
          "id": "3275010009",
          "district_id": "3275010",
          "name": "JATIWARINGIN"
        },
        {
          "id": "3275010010",
          "district_id": "3275010",
          "name": "JATIBENING"
        },
        {
          "id": "3275010011",
          "district_id": "3275010",
          "name": "JATICEMPAKA"
        },
        {
          "id": "3275010012",
          "district_id": "3275010",
          "name": "JATIBENING BARU"
        },
        {
          "id": "3275011001",
          "district_id": "3275011",
          "name": "JATIKARYA"
        },
        {
          "id": "3275011002",
          "district_id": "3275011",
          "name": "JATISAMPURNA"
        },
        {
          "id": "3275011003",
          "district_id": "3275011",
          "name": "JATIRANGGA"
        },
        {
          "id": "3275011004",
          "district_id": "3275011",
          "name": "JATIRANGGON"
        },
        {
          "id": "3275011006",
          "district_id": "3275011",
          "name": "JATIRADEN"
        },
        {
          "id": "3275012001",
          "district_id": "3275012",
          "name": "JATIMURNI"
        },
        {
          "id": "3275012002",
          "district_id": "3275012",
          "name": "JATIMELATI"
        },
        {
          "id": "3275012003",
          "district_id": "3275012",
          "name": "JATIWARNA"
        },
        {
          "id": "3275012004",
          "district_id": "3275012",
          "name": "JATIRAHAYU"
        },
        {
          "id": "3275020001",
          "district_id": "3275020",
          "name": "JATISARI"
        },
        {
          "id": "3275020002",
          "district_id": "3275020",
          "name": "JATILUHUR"
        },
        {
          "id": "3275020003",
          "district_id": "3275020",
          "name": "JATIRASA"
        },
        {
          "id": "3275020004",
          "district_id": "3275020",
          "name": "JATIASIH"
        },
        {
          "id": "3275020005",
          "district_id": "3275020",
          "name": "JATIMEKAR"
        },
        {
          "id": "3275020006",
          "district_id": "3275020",
          "name": "JATIKRAMAT"
        },
        {
          "id": "3275030001",
          "district_id": "3275030",
          "name": "CIKETINGUDIK"
        },
        {
          "id": "3275030002",
          "district_id": "3275030",
          "name": "SUMUR BATU"
        },
        {
          "id": "3275030003",
          "district_id": "3275030",
          "name": "CIKIWUL"
        },
        {
          "id": "3275030004",
          "district_id": "3275030",
          "name": "BANTARGEBANG"
        },
        {
          "id": "3275031001",
          "district_id": "3275031",
          "name": "PADURENAN"
        },
        {
          "id": "3275031002",
          "district_id": "3275031",
          "name": "CIMUNING"
        },
        {
          "id": "3275031003",
          "district_id": "3275031",
          "name": "MUSTIKA JAYA"
        },
        {
          "id": "3275031004",
          "district_id": "3275031",
          "name": "MUSTIKA SARI"
        },
        {
          "id": "3275040005",
          "district_id": "3275040",
          "name": "MARGAHAYU"
        },
        {
          "id": "3275040006",
          "district_id": "3275040",
          "name": "BEKASI JAYA"
        },
        {
          "id": "3275040007",
          "district_id": "3275040",
          "name": "DUREN JAYA"
        },
        {
          "id": "3275040008",
          "district_id": "3275040",
          "name": "AREN JAYA"
        },
        {
          "id": "3275041001",
          "district_id": "3275041",
          "name": "BOJONG MENTENG"
        },
        {
          "id": "3275041002",
          "district_id": "3275041",
          "name": "BOJONG RAWALUMBU"
        },
        {
          "id": "3275041003",
          "district_id": "3275041",
          "name": "SEPANJANG JAYA"
        },
        {
          "id": "3275041004",
          "district_id": "3275041",
          "name": "PENGASINAN"
        },
        {
          "id": "3275050001",
          "district_id": "3275050",
          "name": "JAKA MULYA"
        },
        {
          "id": "3275050002",
          "district_id": "3275050",
          "name": "JAKA SETIA"
        },
        {
          "id": "3275050003",
          "district_id": "3275050",
          "name": "PEKAYON JAYA"
        },
        {
          "id": "3275050004",
          "district_id": "3275050",
          "name": "MARGA JAYA"
        },
        {
          "id": "3275050007",
          "district_id": "3275050",
          "name": "KAYURINGIN JAYA"
        },
        {
          "id": "3275060001",
          "district_id": "3275060",
          "name": "BINTARA JAYA"
        },
        {
          "id": "3275060002",
          "district_id": "3275060",
          "name": "BINTARA"
        },
        {
          "id": "3275060003",
          "district_id": "3275060",
          "name": "KRANJI"
        },
        {
          "id": "3275060005",
          "district_id": "3275060",
          "name": "KOTA BARU"
        },
        {
          "id": "3275060008",
          "district_id": "3275060",
          "name": "JAKA SAMPURNA"
        },
        {
          "id": "3275061001",
          "district_id": "3275061",
          "name": "HARAPAN MULYA"
        },
        {
          "id": "3275061002",
          "district_id": "3275061",
          "name": "KALI BARU"
        },
        {
          "id": "3275061003",
          "district_id": "3275061",
          "name": "MEDAN SATRIA"
        },
        {
          "id": "3275061004",
          "district_id": "3275061",
          "name": "PEJUANG"
        },
        {
          "id": "3275070001",
          "district_id": "3275070",
          "name": "HARAPAN JAYA"
        },
        {
          "id": "3275070002",
          "district_id": "3275070",
          "name": "KALIABANG TENGAH"
        },
        {
          "id": "3275070003",
          "district_id": "3275070",
          "name": "PERWIRA"
        },
        {
          "id": "3275070004",
          "district_id": "3275070",
          "name": "HARAPAN BARU"
        },
        {
          "id": "3275070006",
          "district_id": "3275070",
          "name": "MARGA MULYA"
        },
        {
          "id": "3276010003",
          "district_id": "3276010",
          "name": "PENGASINAN"
        },
        {
          "id": "3276010004",
          "district_id": "3276010",
          "name": "BEDAHAN"
        },
        {
          "id": "3276010005",
          "district_id": "3276010",
          "name": "PASIR PUTIH"
        },
        {
          "id": "3276010006",
          "district_id": "3276010",
          "name": "SAWANGAN BARU"
        },
        {
          "id": "3276010007",
          "district_id": "3276010",
          "name": "SAWANGAN LAMA"
        },
        {
          "id": "3276010013",
          "district_id": "3276010",
          "name": "KEDAUNG"
        },
        {
          "id": "3276010014",
          "district_id": "3276010",
          "name": "CINANGKA"
        },
        {
          "id": "3276011001",
          "district_id": "3276011",
          "name": "DUREN SERIBU"
        },
        {
          "id": "3276011002",
          "district_id": "3276011",
          "name": "DUREN MEKAR"
        },
        {
          "id": "3276011003",
          "district_id": "3276011",
          "name": "BOJONGSARI LAMA"
        },
        {
          "id": "3276011004",
          "district_id": "3276011",
          "name": "BOJONGSARI BARU"
        },
        {
          "id": "3276011006",
          "district_id": "3276011",
          "name": "PONDOK PETIR"
        },
        {
          "id": "3276011007",
          "district_id": "3276011",
          "name": "SERUA"
        },
        {
          "id": "3276020006",
          "district_id": "3276020",
          "name": "RANGKAPAN JAYA BARU"
        },
        {
          "id": "3276020007",
          "district_id": "3276020",
          "name": "RANGKAPAN JAYA"
        },
        {
          "id": "3276020008",
          "district_id": "3276020",
          "name": "MAMPANG"
        },
        {
          "id": "3276020009",
          "district_id": "3276020",
          "name": "PANCORAN MAS"
        },
        {
          "id": "3276020010",
          "district_id": "3276020",
          "name": "DEPOK JAYA"
        },
        {
          "id": "3276020011",
          "district_id": "3276020",
          "name": "DEPOK"
        },
        {
          "id": "3276021001",
          "district_id": "3276021",
          "name": "CIPAYUNG JAYA"
        },
        {
          "id": "3276021002",
          "district_id": "3276021",
          "name": "BOJONG PONDOK TERONG"
        },
        {
          "id": "3276021003",
          "district_id": "3276021",
          "name": "PONDOK JAYA"
        },
        {
          "id": "3276021004",
          "district_id": "3276021",
          "name": "RATUJAYA"
        },
        {
          "id": "3276021005",
          "district_id": "3276021",
          "name": "CIPAYUNG"
        },
        {
          "id": "3276030006",
          "district_id": "3276030",
          "name": "SUKMAJAYA"
        },
        {
          "id": "3276030007",
          "district_id": "3276030",
          "name": "TIRTAJAYA"
        },
        {
          "id": "3276030008",
          "district_id": "3276030",
          "name": "MEKAR JAYA"
        },
        {
          "id": "3276030009",
          "district_id": "3276030",
          "name": "ABADIJAYA"
        },
        {
          "id": "3276030010",
          "district_id": "3276030",
          "name": "BAKTI JAYA"
        },
        {
          "id": "3276030011",
          "district_id": "3276030",
          "name": "CISALAK"
        },
        {
          "id": "3276031001",
          "district_id": "3276031",
          "name": "KALIMULYA"
        },
        {
          "id": "3276031002",
          "district_id": "3276031",
          "name": "JATIMULYA"
        },
        {
          "id": "3276031003",
          "district_id": "3276031",
          "name": "KALIBARU"
        },
        {
          "id": "3276031004",
          "district_id": "3276031",
          "name": "CILODONG"
        },
        {
          "id": "3276031005",
          "district_id": "3276031",
          "name": "SUKAMAJU"
        },
        {
          "id": "3276040009",
          "district_id": "3276040",
          "name": "HARJAMUKTI"
        },
        {
          "id": "3276040010",
          "district_id": "3276040",
          "name": "CISALAK PASAR"
        },
        {
          "id": "3276040011",
          "district_id": "3276040",
          "name": "MEKARSARI"
        },
        {
          "id": "3276040013",
          "district_id": "3276040",
          "name": "PASIR GUNUNG SELATAN"
        },
        {
          "id": "3276041001",
          "district_id": "3276041",
          "name": "CILANGKAP"
        },
        {
          "id": "3276041002",
          "district_id": "3276041",
          "name": "CIMPAEUN"
        },
        {
          "id": "3276041003",
          "district_id": "3276041",
          "name": "TAPOS"
        },
        {
          "id": "3276041004",
          "district_id": "3276041",
          "name": "LEUWINAGGUNG"
        },
        {
          "id": "3276041005",
          "district_id": "3276041",
          "name": "JATIJAJAR"
        },
        {
          "id": "3276041006",
          "district_id": "3276041",
          "name": "SUKAMAJU BARU"
        },
        {
          "id": "3276041007",
          "district_id": "3276041",
          "name": "SUKATANI"
        },
        {
          "id": "3276050001",
          "district_id": "3276050",
          "name": "BEJI"
        },
        {
          "id": "3276050002",
          "district_id": "3276050",
          "name": "BEJI TIMUR"
        },
        {
          "id": "3276050003",
          "district_id": "3276050",
          "name": "KEMIRIMUKA"
        },
        {
          "id": "3276050004",
          "district_id": "3276050",
          "name": "PONDOK CINA"
        },
        {
          "id": "3276050005",
          "district_id": "3276050",
          "name": "KUKUSAN"
        },
        {
          "id": "3276050006",
          "district_id": "3276050",
          "name": "TANAH BARU"
        },
        {
          "id": "3276060002",
          "district_id": "3276060",
          "name": "GROGOL"
        },
        {
          "id": "3276060004",
          "district_id": "3276060",
          "name": "LIMO"
        },
        {
          "id": "3276061001",
          "district_id": "3276061",
          "name": "CINERE"
        },
        {
          "id": "3276061002",
          "district_id": "3276061",
          "name": "GANDUL"
        },
        {
          "id": "3276061003",
          "district_id": "3276061",
          "name": "PANGKALANJATI BARU"
        },
        {
          "id": "3276061004",
          "district_id": "3276061",
          "name": "PANGKALANJATI"
        },
        {
          "id": "3277010001",
          "district_id": "3277010",
          "name": "MELONG"
        },
        {
          "id": "3277010002",
          "district_id": "3277010",
          "name": "CIBEUREUM"
        },
        {
          "id": "3277010003",
          "district_id": "3277010",
          "name": "UTAMA"
        },
        {
          "id": "3277010004",
          "district_id": "3277010",
          "name": "LEUWIGAJAH"
        },
        {
          "id": "3277010005",
          "district_id": "3277010",
          "name": "CIBEBER"
        },
        {
          "id": "3277020001",
          "district_id": "3277020",
          "name": "BAROS"
        },
        {
          "id": "3277020002",
          "district_id": "3277020",
          "name": "CIGUGUR TENGAH"
        },
        {
          "id": "3277020003",
          "district_id": "3277020",
          "name": "KARANGMEKAR"
        },
        {
          "id": "3277020004",
          "district_id": "3277020",
          "name": "SETIAMANAH"
        },
        {
          "id": "3277020005",
          "district_id": "3277020",
          "name": "PADASUKA"
        },
        {
          "id": "3277020006",
          "district_id": "3277020",
          "name": "CIMAHI"
        },
        {
          "id": "3277030001",
          "district_id": "3277030",
          "name": "PASIRKALIKI"
        },
        {
          "id": "3277030002",
          "district_id": "3277030",
          "name": "CIBABAT"
        },
        {
          "id": "3277030003",
          "district_id": "3277030",
          "name": "CITEUREUP"
        },
        {
          "id": "3277030004",
          "district_id": "3277030",
          "name": "CIPAGERAN"
        },
        {
          "id": "3278010001",
          "district_id": "3278010",
          "name": "LEUWILIANG"
        },
        {
          "id": "3278010003",
          "district_id": "3278010",
          "name": "GUNUNGTANDALA"
        },
        {
          "id": "3278010005",
          "district_id": "3278010",
          "name": "TALAGASARI"
        },
        {
          "id": "3278010006",
          "district_id": "3278010",
          "name": "TANJUNG"
        },
        {
          "id": "3278010007",
          "district_id": "3278010",
          "name": "CIBEUTI"
        },
        {
          "id": "3278010008",
          "district_id": "3278010",
          "name": "KARANGANYAR"
        },
        {
          "id": "3278010009",
          "district_id": "3278010",
          "name": "CILAMAJANG"
        },
        {
          "id": "3278010010",
          "district_id": "3278010",
          "name": "KARSAMENAK"
        },
        {
          "id": "3278020001",
          "district_id": "3278020",
          "name": "SETIAMULYA"
        },
        {
          "id": "3278020002",
          "district_id": "3278020",
          "name": "SETIAWARGI"
        },
        {
          "id": "3278020003",
          "district_id": "3278020",
          "name": "TAMANSARI"
        },
        {
          "id": "3278020004",
          "district_id": "3278020",
          "name": "TAMANJAYA"
        },
        {
          "id": "3278020005",
          "district_id": "3278020",
          "name": "MULYASARI"
        },
        {
          "id": "3278020006",
          "district_id": "3278020",
          "name": "SUKAHURIP"
        },
        {
          "id": "3278020007",
          "district_id": "3278020",
          "name": "MUGARSARI"
        },
        {
          "id": "3278020008",
          "district_id": "3278020",
          "name": "SUMELAP"
        },
        {
          "id": "3278030001",
          "district_id": "3278030",
          "name": "CIHERANG"
        },
        {
          "id": "3278030002",
          "district_id": "3278030",
          "name": "CIAKAR"
        },
        {
          "id": "3278030003",
          "district_id": "3278030",
          "name": "MARGABAKTI"
        },
        {
          "id": "3278030004",
          "district_id": "3278030",
          "name": "AWIPARI"
        },
        {
          "id": "3278030005",
          "district_id": "3278030",
          "name": "KOTABARU"
        },
        {
          "id": "3278030006",
          "district_id": "3278030",
          "name": "KERSANAGARA"
        },
        {
          "id": "3278030007",
          "district_id": "3278030",
          "name": "SETIAJAYA"
        },
        {
          "id": "3278030008",
          "district_id": "3278030",
          "name": "SETIARATU"
        },
        {
          "id": "3278030009",
          "district_id": "3278030",
          "name": "SETIANAGARA"
        },
        {
          "id": "3278031001",
          "district_id": "3278031",
          "name": "SUKANAGARA"
        },
        {
          "id": "3278031002",
          "district_id": "3278031",
          "name": "SUKAMENAK"
        },
        {
          "id": "3278031003",
          "district_id": "3278031",
          "name": "PURBARATU"
        },
        {
          "id": "3278031004",
          "district_id": "3278031",
          "name": "SUKAASIH"
        },
        {
          "id": "3278031005",
          "district_id": "3278031",
          "name": "SUKAJAYA"
        },
        {
          "id": "3278031006",
          "district_id": "3278031",
          "name": "SINGKUP"
        },
        {
          "id": "3278040001",
          "district_id": "3278040",
          "name": "KAHURIPAN"
        },
        {
          "id": "3278040002",
          "district_id": "3278040",
          "name": "CIKALANG"
        },
        {
          "id": "3278040003",
          "district_id": "3278040",
          "name": "EMPANGSARI"
        },
        {
          "id": "3278040004",
          "district_id": "3278040",
          "name": "TAWANGSARI"
        },
        {
          "id": "3278040005",
          "district_id": "3278040",
          "name": "LENGKONGSARI"
        },
        {
          "id": "3278050001",
          "district_id": "3278050",
          "name": "TUGUJAYA"
        },
        {
          "id": "3278050002",
          "district_id": "3278050",
          "name": "TUGURAJA"
        },
        {
          "id": "3278050003",
          "district_id": "3278050",
          "name": "NAGARAWANGI"
        },
        {
          "id": "3278050004",
          "district_id": "3278050",
          "name": "YUDANAGARA"
        },
        {
          "id": "3278050005",
          "district_id": "3278050",
          "name": "CILEMBANG"
        },
        {
          "id": "3278050006",
          "district_id": "3278050",
          "name": "ARGASARI"
        },
        {
          "id": "3278060001",
          "district_id": "3278060",
          "name": "KARIKIL"
        },
        {
          "id": "3278060002",
          "district_id": "3278060",
          "name": "CIGANTANG"
        },
        {
          "id": "3278060003",
          "district_id": "3278060",
          "name": "SAMBONGJAYA"
        },
        {
          "id": "3278060004",
          "district_id": "3278060",
          "name": "SAMBONGPARI"
        },
        {
          "id": "3278060005",
          "district_id": "3278060",
          "name": "LINGGAJAYA"
        },
        {
          "id": "3278060006",
          "district_id": "3278060",
          "name": "MANGKUBUMI"
        },
        {
          "id": "3278060007",
          "district_id": "3278060",
          "name": "CIPARI"
        },
        {
          "id": "3278060008",
          "district_id": "3278060",
          "name": "CIPAWITRA"
        },
        {
          "id": "3278070005",
          "district_id": "3278070",
          "name": "PANYINGKIRAN"
        },
        {
          "id": "3278070006",
          "district_id": "3278070",
          "name": "PARAKANNYASAG"
        },
        {
          "id": "3278070007",
          "district_id": "3278070",
          "name": "SIRNAGALIH"
        },
        {
          "id": "3278070008",
          "district_id": "3278070",
          "name": "INDIHIANG"
        },
        {
          "id": "3278070012",
          "district_id": "3278070",
          "name": "SUKAMAJUKIDUL"
        },
        {
          "id": "3278070013",
          "district_id": "3278070",
          "name": "SUKAMAJUKALER"
        },
        {
          "id": "3278071001",
          "district_id": "3278071",
          "name": "SUKAMULYA"
        },
        {
          "id": "3278071002",
          "district_id": "3278071",
          "name": "SUKARINDIK"
        },
        {
          "id": "3278071003",
          "district_id": "3278071",
          "name": "BUNGURSARI"
        },
        {
          "id": "3278071004",
          "district_id": "3278071",
          "name": "SUKAJAYA"
        },
        {
          "id": "3278071005",
          "district_id": "3278071",
          "name": "CIBUNIGEULIS"
        },
        {
          "id": "3278071006",
          "district_id": "3278071",
          "name": "BANTARSARI"
        },
        {
          "id": "3278071007",
          "district_id": "3278071",
          "name": "SUKALAKSANA"
        },
        {
          "id": "3278080001",
          "district_id": "3278080",
          "name": "PANGLAYUNGAN"
        },
        {
          "id": "3278080002",
          "district_id": "3278080",
          "name": "CIPEDES"
        },
        {
          "id": "3278080003",
          "district_id": "3278080",
          "name": "NAGARASARI"
        },
        {
          "id": "3278080004",
          "district_id": "3278080",
          "name": "SUKAMANAH"
        },
        {
          "id": "3279010001",
          "district_id": "3279010",
          "name": "SITUBATU"
        },
        {
          "id": "3279010002",
          "district_id": "3279010",
          "name": "NEGLASARI"
        },
        {
          "id": "3279010003",
          "district_id": "3279010",
          "name": "CIBEUREUM"
        },
        {
          "id": "3279010004",
          "district_id": "3279010",
          "name": "BALOKANG"
        },
        {
          "id": "3279010005",
          "district_id": "3279010",
          "name": "BANJAR"
        },
        {
          "id": "3279010006",
          "district_id": "3279010",
          "name": "MEKARSARI"
        },
        {
          "id": "3279010007",
          "district_id": "3279010",
          "name": "JAJAWAR"
        },
        {
          "id": "3279020001",
          "district_id": "3279020",
          "name": "PURWAHARJA"
        },
        {
          "id": "3279020002",
          "district_id": "3279020",
          "name": "KARANGPANIMBAL"
        },
        {
          "id": "3279020003",
          "district_id": "3279020",
          "name": "RAHARJA"
        },
        {
          "id": "3279020004",
          "district_id": "3279020",
          "name": "MEKARHARJA"
        },
        {
          "id": "3279030001",
          "district_id": "3279030",
          "name": "BINANGUN"
        },
        {
          "id": "3279030002",
          "district_id": "3279030",
          "name": "BATULAWANG"
        },
        {
          "id": "3279030003",
          "district_id": "3279030",
          "name": "KARYAMUKTI"
        },
        {
          "id": "3279030004",
          "district_id": "3279030",
          "name": "MULYASARI"
        },
        {
          "id": "3279030005",
          "district_id": "3279030",
          "name": "PATARUMAN"
        },
        {
          "id": "3279030006",
          "district_id": "3279030",
          "name": "HEGARSARI"
        },
        {
          "id": "3279030007",
          "district_id": "3279030",
          "name": "SUKAMUKTI"
        },
        {
          "id": "3279030008",
          "district_id": "3279030",
          "name": "SINARTANJUNG"
        },
        {
          "id": "3279040001",
          "district_id": "3279040",
          "name": "KUJANGSARI"
        },
        {
          "id": "3279040002",
          "district_id": "3279040",
          "name": "BOJONGKANTONG"
        },
        {
          "id": "3279040003",
          "district_id": "3279040",
          "name": "REJASARI"
        },
        {
          "id": "3279040004",
          "district_id": "3279040",
          "name": "LANGENSARI"
        },
        {
          "id": "3279040005",
          "district_id": "3279040",
          "name": "MUKTISARI"
        },
        {
          "id": "3279040006",
          "district_id": "3279040",
          "name": "WARINGINSARI"
        },
        {
          "id": "3201010001",
          "district_id": "3201010",
          "name": "MALASARI"
        },
        {
          "id": "3201010002",
          "district_id": "3201010",
          "name": "BANTAR KARET"
        },
        {
          "id": "3201010003",
          "district_id": "3201010",
          "name": "CISARUA"
        },
        {
          "id": "3201010004",
          "district_id": "3201010",
          "name": "CURUG BITUNG"
        },
        {
          "id": "3201010005",
          "district_id": "3201010",
          "name": "NANGGUNG"
        },
        {
          "id": "3201010006",
          "district_id": "3201010",
          "name": "PANGKAL JAYA"
        },
        {
          "id": "3674070003",
          "district_id": "3674070",
          "name": "PONDOK JAGUNG"
        },
        {
          "id": "3674070004",
          "district_id": "3674070",
          "name": "PONDOK JAGUNG TIMUR"
        },
        {
          "id": "3674070005",
          "district_id": "3674070",
          "name": "PAKULONAN"
        },
        {
          "id": "3674070006",
          "district_id": "3674070",
          "name": "PAKU ALAM"
        },
        {
          "id": "3674070007",
          "district_id": "3674070",
          "name": "PAKU JAYA"
        },
        {
          "id": "3601010001",
          "district_id": "3601010",
          "name": "UJUNGJAYA"
        },
        {
          "id": "3601010002",
          "district_id": "3601010",
          "name": "TAMANJAYA"
        },
        {
          "id": "3601010003",
          "district_id": "3601010",
          "name": "CIGORONDONG"
        },
        {
          "id": "3601010004",
          "district_id": "3601010",
          "name": "TUNGGALJAYA"
        },
        {
          "id": "3601010005",
          "district_id": "3601010",
          "name": "KERTAMUKTI"
        },
        {
          "id": "3601010006",
          "district_id": "3601010",
          "name": "KERTAJAYA"
        },
        {
          "id": "3601010007",
          "district_id": "3601010",
          "name": "SUMBERJAYA"
        },
        {
          "id": "3601020001",
          "district_id": "3601020",
          "name": "RANCAPINANG"
        },
        {
          "id": "3601020002",
          "district_id": "3601020",
          "name": "CIBADAK"
        },
        {
          "id": "3601020003",
          "district_id": "3601020",
          "name": "BATUHIDEUNG"
        },
        {
          "id": "3601020005",
          "district_id": "3601020",
          "name": "KRAMATJAYA"
        },
        {
          "id": "3601020006",
          "district_id": "3601020",
          "name": "MANGKUALAM"
        },
        {
          "id": "3601020007",
          "district_id": "3601020",
          "name": "PADASUKA"
        },
        {
          "id": "3601020008",
          "district_id": "3601020",
          "name": "CIBURIAL"
        },
        {
          "id": "3601020009",
          "district_id": "3601020",
          "name": "WARINGINKURUNG"
        },
        {
          "id": "3601020010",
          "district_id": "3601020",
          "name": "CIJARALANG"
        },
        {
          "id": "3601020011",
          "district_id": "3601020",
          "name": "CIMANGGU"
        },
        {
          "id": "3601020012",
          "district_id": "3601020",
          "name": "TANGKILSARI"
        },
        {
          "id": "3601030011",
          "district_id": "3601030",
          "name": "SUKAJADI"
        },
        {
          "id": "3601030012",
          "district_id": "3601030",
          "name": "SUDIMANIK"
        },
        {
          "id": "3601030013",
          "district_id": "3601030",
          "name": "SORONGAN"
        },
        {
          "id": "3601030015",
          "district_id": "3601030",
          "name": "CIHANJUANG"
        },
        {
          "id": "3601030016",
          "district_id": "3601030",
          "name": "CIBINGBIN"
        },
        {
          "id": "3601030017",
          "district_id": "3601030",
          "name": "CIBALIUNG"
        },
        {
          "id": "3601030019",
          "district_id": "3601030",
          "name": "MAHENDRA"
        },
        {
          "id": "3601031001",
          "district_id": "3601031",
          "name": "CITELUK"
        },
        {
          "id": "3601031002",
          "district_id": "3601031",
          "name": "SINDANGKERTA"
        },
        {
          "id": "3601031003",
          "district_id": "3601031",
          "name": "KIARAJANGKUNG"
        },
        {
          "id": "3601031004",
          "district_id": "3601031",
          "name": "KUTAKARANG"
        },
        {
          "id": "3601031005",
          "district_id": "3601031",
          "name": "CIKIRUH"
        },
        {
          "id": "3601031006",
          "district_id": "3601031",
          "name": "MALANGNENGAH"
        },
        {
          "id": "3601031007",
          "district_id": "3601031",
          "name": "CIKADU"
        },
        {
          "id": "3601031008",
          "district_id": "3601031",
          "name": "MANGLID"
        },
        {
          "id": "3601031009",
          "district_id": "3601031",
          "name": "KIARAPAYUNG"
        },
        {
          "id": "3601031010",
          "district_id": "3601031",
          "name": "CIKALONG"
        },
        {
          "id": "3601040001",
          "district_id": "3601040",
          "name": "TANJUNGAN"
        },
        {
          "id": "3601040002",
          "district_id": "3601040",
          "name": "CIKIRUHWETAN"
        },
        {
          "id": "3601040003",
          "district_id": "3601040",
          "name": "SUKAWARIS"
        },
        {
          "id": "3601040004",
          "district_id": "3601040",
          "name": "SUMURBATU"
        },
        {
          "id": "3601040005",
          "district_id": "3601040",
          "name": "UMBULAN"
        },
        {
          "id": "3601040006",
          "district_id": "3601040",
          "name": "SUKAMULYA"
        },
        {
          "id": "3601040007",
          "district_id": "3601040",
          "name": "PARUNGKOKOSAN"
        },
        {
          "id": "3601040008",
          "district_id": "3601040",
          "name": "NANGGALA"
        },
        {
          "id": "3601040009",
          "district_id": "3601040",
          "name": "RANCASENENG"
        },
        {
          "id": "3601040010",
          "district_id": "3601040",
          "name": "SUKASENENG"
        },
        {
          "id": "3601040011",
          "district_id": "3601040",
          "name": "CIKEUSIK"
        },
        {
          "id": "3601040012",
          "district_id": "3601040",
          "name": "LEUWIBALANG"
        },
        {
          "id": "3601040013",
          "district_id": "3601040",
          "name": "CURUGCIUNG"
        },
        {
          "id": "3601040014",
          "district_id": "3601040",
          "name": "CIKADONGDONG"
        },
        {
          "id": "3601050001",
          "district_id": "3601050",
          "name": "BANYUASIH"
        },
        {
          "id": "3601050002",
          "district_id": "3601050",
          "name": "KARANGBOLONG"
        },
        {
          "id": "3601050003",
          "district_id": "3601050",
          "name": "KARYABUANA"
        },
        {
          "id": "3601050004",
          "district_id": "3601050",
          "name": "KATUMBIRI"
        },
        {
          "id": "3601050005",
          "district_id": "3601050",
          "name": "WARINGINJAYA"
        },
        {
          "id": "3601050006",
          "district_id": "3601050",
          "name": "SINARJAYA"
        },
        {
          "id": "3601050007",
          "district_id": "3601050",
          "name": "CISEUREUHEUN"
        },
        {
          "id": "3601050009",
          "district_id": "3601050",
          "name": "CIGEULIS"
        },
        {
          "id": "3601050010",
          "district_id": "3601050",
          "name": "TARUMANAGARA"
        },
        {
          "id": "3601060007",
          "district_id": "3601060",
          "name": "MEKARJAYA"
        },
        {
          "id": "3601060008",
          "district_id": "3601060",
          "name": "GOMBONG"
        },
        {
          "id": "3601060009",
          "district_id": "3601060",
          "name": "PANIMBANGJAYA"
        },
        {
          "id": "3601060010",
          "district_id": "3601060",
          "name": "MEKARSARI"
        },
        {
          "id": "3601060011",
          "district_id": "3601060",
          "name": "CITEUREUP"
        },
        {
          "id": "3601060012",
          "district_id": "3601060",
          "name": "TANJUNGJAYA"
        },
        {
          "id": "3601061001",
          "district_id": "3601061",
          "name": "CIMANIS"
        },
        {
          "id": "3601061002",
          "district_id": "3601061",
          "name": "PANGKALAN"
        },
        {
          "id": "3601061003",
          "district_id": "3601061",
          "name": "SOBANG"
        },
        {
          "id": "3601061004",
          "district_id": "3601061",
          "name": "KUTAMEKAR"
        },
        {
          "id": "3601061005",
          "district_id": "3601061",
          "name": "BOJEN"
        },
        {
          "id": "3601061006",
          "district_id": "3601061",
          "name": "KERTARAHARJA"
        },
        {
          "id": "3601061007",
          "district_id": "3601061",
          "name": "TELUKLADA"
        },
        {
          "id": "3601061008",
          "district_id": "3601061",
          "name": "BOJENWETAN"
        },
        {
          "id": "3601070003",
          "district_id": "3601070",
          "name": "LEBAK"
        },
        {
          "id": "3601070004",
          "district_id": "3601070",
          "name": "GUNUNGBATU"
        },
        {
          "id": "3601070005",
          "district_id": "3601070",
          "name": "PANACARAN"
        },
        {
          "id": "3601070006",
          "district_id": "3601070",
          "name": "CURUGLANGLANG"
        },
        {
          "id": "3601070008",
          "district_id": "3601070",
          "name": "CIBITUNG"
        },
        {
          "id": "3601070018",
          "district_id": "3601070",
          "name": "KOTADUKUH"
        },
        {
          "id": "3601070019",
          "district_id": "3601070",
          "name": "PASANGGRAHAN"
        },
        {
          "id": "3601070020",
          "district_id": "3601070",
          "name": "SUKASABA"
        },
        {
          "id": "3601071001",
          "district_id": "3601071",
          "name": "CIPINANG"
        },
        {
          "id": "3601071002",
          "district_id": "3601071",
          "name": "KADUBADAK"
        },
        {
          "id": "3601071004",
          "district_id": "3601071",
          "name": "CIKAYAS"
        },
        {
          "id": "3601071005",
          "district_id": "3601071",
          "name": "SUMURLABAN"
        },
        {
          "id": "3601071006",
          "district_id": "3601071",
          "name": "PADAMULYA"
        },
        {
          "id": "3601071007",
          "district_id": "3601071",
          "name": "PADAHERANG"
        },
        {
          "id": "3601071008",
          "district_id": "3601071",
          "name": "KARANGSARI"
        },
        {
          "id": "3601071009",
          "district_id": "3601071",
          "name": "ANGSANA"
        },
        {
          "id": "3601071010",
          "district_id": "3601071",
          "name": "KRAMATMANIK"
        },
        {
          "id": "3601072001",
          "district_id": "3601072",
          "name": "PASIRLOA"
        },
        {
          "id": "3601072002",
          "district_id": "3601072",
          "name": "BOJONGMANIK"
        },
        {
          "id": "3601072003",
          "district_id": "3601072",
          "name": "CAMPAKAWARNA"
        },
        {
          "id": "3601072004",
          "district_id": "3601072",
          "name": "CIODENG"
        },
        {
          "id": "3601072005",
          "district_id": "3601072",
          "name": "PASIRTENJO"
        },
        {
          "id": "3601072006",
          "district_id": "3601072",
          "name": "SINDANGRESMI"
        },
        {
          "id": "3601072007",
          "district_id": "3601072",
          "name": "PASIRLANCAR"
        },
        {
          "id": "3601072008",
          "district_id": "3601072",
          "name": "PASIRDURUNG"
        },
        {
          "id": "3601072009",
          "district_id": "3601072",
          "name": "KADUMALATI"
        },
        {
          "id": "3601080001",
          "district_id": "3601080",
          "name": "CIHERANG"
        },
        {
          "id": "3601080002",
          "district_id": "3601080",
          "name": "KOLELET"
        },
        {
          "id": "3601080003",
          "district_id": "3601080",
          "name": "CILILITAN"
        },
        {
          "id": "3601080004",
          "district_id": "3601080",
          "name": "KADUPANDAK"
        },
        {
          "id": "3601080005",
          "district_id": "3601080",
          "name": "BUNGURCOPONG"
        },
        {
          "id": "3601080006",
          "district_id": "3601080",
          "name": "PASIRSEDANG"
        },
        {
          "id": "3601080007",
          "district_id": "3601080",
          "name": "PASIRPANJANG"
        },
        {
          "id": "3601080008",
          "district_id": "3601080",
          "name": "KADUBERA"
        },
        {
          "id": "3601080009",
          "district_id": "3601080",
          "name": "GANGGAENG"
        },
        {
          "id": "3601090002",
          "district_id": "3601090",
          "name": "MEKARSARI"
        },
        {
          "id": "3601090003",
          "district_id": "3601090",
          "name": "CIJAKAN"
        },
        {
          "id": "3601090004",
          "district_id": "3601090",
          "name": "CITUMENGGUNG"
        },
        {
          "id": "3601090005",
          "district_id": "3601090",
          "name": "CAHAYAMEKAR"
        },
        {
          "id": "3601090006",
          "district_id": "3601090",
          "name": "BOJONG"
        },
        {
          "id": "3601090007",
          "district_id": "3601090",
          "name": "BANYUMAS"
        },
        {
          "id": "3601090008",
          "district_id": "3601090",
          "name": "MANGGUNGJAYA"
        },
        {
          "id": "3601100005",
          "district_id": "3601100",
          "name": "SUKALANGU"
        },
        {
          "id": "3601100006",
          "district_id": "3601100",
          "name": "LANGENSARI"
        },
        {
          "id": "3601100008",
          "district_id": "3601100",
          "name": "MAJAU"
        },
        {
          "id": "3601100009",
          "district_id": "3601100",
          "name": "MEDALSARI"
        },
        {
          "id": "3601100010",
          "district_id": "3601100",
          "name": "SODONG"
        },
        {
          "id": "3601100011",
          "district_id": "3601100",
          "name": "MEKARWANGI"
        },
        {
          "id": "3601100012",
          "district_id": "3601100",
          "name": "CIANDUR"
        },
        {
          "id": "3601100013",
          "district_id": "3601100",
          "name": "SAKETI"
        },
        {
          "id": "3601100014",
          "district_id": "3601100",
          "name": "KADUDAMPIT"
        },
        {
          "id": "3601100015",
          "district_id": "3601100",
          "name": "GIRIJAYA"
        },
        {
          "id": "3601100016",
          "district_id": "3601100",
          "name": "WANAGIRI"
        },
        {
          "id": "3601100017",
          "district_id": "3601100",
          "name": "PARIGI"
        },
        {
          "id": "3601100018",
          "district_id": "3601100",
          "name": "TALAGASARI"
        },
        {
          "id": "3601100019",
          "district_id": "3601100",
          "name": "SINDANGHAYU"
        },
        {
          "id": "3601101001",
          "district_id": "3601101",
          "name": "KONDANGJAYA"
        },
        {
          "id": "3601101002",
          "district_id": "3601101",
          "name": "KUBANGKONDANG"
        },
        {
          "id": "3601101003",
          "district_id": "3601101",
          "name": "CISEREH"
        },
        {
          "id": "3601101004",
          "district_id": "3601101",
          "name": "CIBARANI"
        },
        {
          "id": "3601101005",
          "district_id": "3601101",
          "name": "RAWASARI"
        },
        {
          "id": "3601101006",
          "district_id": "3601101",
          "name": "PASIREURIH"
        },
        {
          "id": "3601101007",
          "district_id": "3601101",
          "name": "KADURONYOK"
        },
        {
          "id": "3601101008",
          "district_id": "3601101",
          "name": "CIHERANGJAYA"
        },
        {
          "id": "3601101009",
          "district_id": "3601101",
          "name": "PALEMBANG"
        },
        {
          "id": "3601110020",
          "district_id": "3601110",
          "name": "TEGALPAPAK"
        },
        {
          "id": "3601110021",
          "district_id": "3601110",
          "name": "MARGAGIRI"
        },
        {
          "id": "3601110022",
          "district_id": "3601110",
          "name": "BAMA"
        },
        {
          "id": "3601110023",
          "district_id": "3601110",
          "name": "PAGELARAN"
        },
        {
          "id": "3601110024",
          "district_id": "3601110",
          "name": "SUKADAME"
        },
        {
          "id": "3601110025",
          "district_id": "3601110",
          "name": "BULAGOR"
        },
        {
          "id": "3601110026",
          "district_id": "3601110",
          "name": "SURAKARTA"
        },
        {
          "id": "3601110027",
          "district_id": "3601110",
          "name": "HARAPANKARYA"
        },
        {
          "id": "3601110029",
          "district_id": "3601110",
          "name": "MONTOR"
        },
        {
          "id": "3601110030",
          "district_id": "3601110",
          "name": "KARTASANA"
        },
        {
          "id": "3601110031",
          "district_id": "3601110",
          "name": "SENANGSARI"
        },
        {
          "id": "3601110032",
          "district_id": "3601110",
          "name": "SINDANGLAYA"
        },
        {
          "id": "3601110033",
          "district_id": "3601110",
          "name": "MARGASANA"
        },
        {
          "id": "3601111009",
          "district_id": "3601111",
          "name": "PASIRGADUNG"
        },
        {
          "id": "3601111010",
          "district_id": "3601111",
          "name": "PATIA"
        },
        {
          "id": "3601111011",
          "district_id": "3601111",
          "name": "BABAKANKEUSIK"
        },
        {
          "id": "3601111012",
          "district_id": "3601111",
          "name": "CIMOYAN"
        },
        {
          "id": "3601111013",
          "district_id": "3601111",
          "name": "IDAMAN"
        },
        {
          "id": "3601111014",
          "district_id": "3601111",
          "name": "CIAWI"
        },
        {
          "id": "3601111015",
          "district_id": "3601111",
          "name": "SURIANEUN"
        },
        {
          "id": "3601111016",
          "district_id": "3601111",
          "name": "RAHAYU"
        },
        {
          "id": "3601111017",
          "district_id": "3601111",
          "name": "SIMPANGTIGA"
        },
        {
          "id": "3601112001",
          "district_id": "3601112",
          "name": "SEUSEUPAN"
        },
        {
          "id": "3601112002",
          "district_id": "3601112",
          "name": "KARYASARI"
        },
        {
          "id": "3601112003",
          "district_id": "3601112",
          "name": "PASIRKADU"
        },
        {
          "id": "3601112004",
          "district_id": "3601112",
          "name": "PERDANA"
        },
        {
          "id": "3601112005",
          "district_id": "3601112",
          "name": "SUKARESMI"
        },
        {
          "id": "3601112006",
          "district_id": "3601112",
          "name": "KUBANGKAMPIL"
        },
        {
          "id": "3601112007",
          "district_id": "3601112",
          "name": "SIDAMUKTI"
        },
        {
          "id": "3601112008",
          "district_id": "3601112",
          "name": "CIBUNGUR"
        },
        {
          "id": "3601112010",
          "district_id": "3601112",
          "name": "CIKUYA"
        },
        {
          "id": "3601120001",
          "district_id": "3601120",
          "name": "CIGONDANG"
        },
        {
          "id": "3601120002",
          "district_id": "3601120",
          "name": "SUKAMAJU"
        },
        {
          "id": "3601120003",
          "district_id": "3601120",
          "name": "RANCATEUREUP"
        },
        {
          "id": "3601120004",
          "district_id": "3601120",
          "name": "KALANGANYAR"
        },
        {
          "id": "3601120005",
          "district_id": "3601120",
          "name": "LABUAN"
        },
        {
          "id": "3601120007",
          "district_id": "3601120",
          "name": "BANYUMEKAR"
        },
        {
          "id": "3601120008",
          "district_id": "3601120",
          "name": "BANYUBIRU"
        },
        {
          "id": "3601120009",
          "district_id": "3601120",
          "name": "CARINGIN"
        },
        {
          "id": "3601121001",
          "district_id": "3601121",
          "name": "PEJAMBEN"
        },
        {
          "id": "3601121002",
          "district_id": "3601121",
          "name": "BANJARMASIN"
        },
        {
          "id": "3601121003",
          "district_id": "3601121",
          "name": "TEMBONG"
        },
        {
          "id": "3601121004",
          "district_id": "3601121",
          "name": "SINDANGLAUT"
        },
        {
          "id": "3601121005",
          "district_id": "3601121",
          "name": "CARITA"
        },
        {
          "id": "3601121006",
          "district_id": "3601121",
          "name": "SUKAJADI"
        },
        {
          "id": "3601121007",
          "district_id": "3601121",
          "name": "SUKARAME"
        },
        {
          "id": "3601121008",
          "district_id": "3601121",
          "name": "SUKANAGARA"
        },
        {
          "id": "3601121009",
          "district_id": "3601121",
          "name": "KAWOYANG"
        },
        {
          "id": "3601121010",
          "district_id": "3601121",
          "name": "CINOYONG"
        },
        {
          "id": "3601130007",
          "district_id": "3601130",
          "name": "BANYURESMI"
        },
        {
          "id": "3601130008",
          "district_id": "3601130",
          "name": "SALAPRAYA"
        },
        {
          "id": "3601130009",
          "district_id": "3601130",
          "name": "PAMARAYAN"
        },
        {
          "id": "3601130010",
          "district_id": "3601130",
          "name": "SAMPANGBITUNG"
        },
        {
          "id": "3601130011",
          "district_id": "3601130",
          "name": "JIPUT"
        },
        {
          "id": "3601130012",
          "district_id": "3601130",
          "name": "SUKACAI"
        },
        {
          "id": "3601130013",
          "district_id": "3601130",
          "name": "TENJOLAHANG"
        },
        {
          "id": "3601130014",
          "district_id": "3601130",
          "name": "BABADSARI"
        },
        {
          "id": "3601130015",
          "district_id": "3601130",
          "name": "JANAKA"
        },
        {
          "id": "3601130016",
          "district_id": "3601130",
          "name": "SUKAMANAH"
        },
        {
          "id": "3601130018",
          "district_id": "3601130",
          "name": "SIKULAN"
        },
        {
          "id": "3601130019",
          "district_id": "3601130",
          "name": "CITAMAN"
        },
        {
          "id": "3601130020",
          "district_id": "3601130",
          "name": "JAYAMEKAR"
        },
        {
          "id": "3601131001",
          "district_id": "3601131",
          "name": "KARYAUTAMA"
        },
        {
          "id": "3601131002",
          "district_id": "3601131",
          "name": "TEGAL"
        },
        {
          "id": "3601131003",
          "district_id": "3601131",
          "name": "CIPICUNG"
        },
        {
          "id": "3601131004",
          "district_id": "3601131",
          "name": "KARYASARI"
        },
        {
          "id": "3601131005",
          "district_id": "3601131",
          "name": "DAHU"
        },
        {
          "id": "3601131006",
          "district_id": "3601131",
          "name": "BABAKANLOR"
        },
        {
          "id": "3601131007",
          "district_id": "3601131",
          "name": "MEKARJAYA"
        },
        {
          "id": "3601131008",
          "district_id": "3601131",
          "name": "PADAHAYU"
        },
        {
          "id": "3601131009",
          "district_id": "3601131",
          "name": "BANGKUYUNG"
        },
        {
          "id": "3601131010",
          "district_id": "3601131",
          "name": "CENING"
        },
        {
          "id": "3601140003",
          "district_id": "3601140",
          "name": "ALASWANGI"
        },
        {
          "id": "3601140004",
          "district_id": "3601140",
          "name": "TEGALWANGI"
        },
        {
          "id": "3601140006",
          "district_id": "3601140",
          "name": "KANANGA"
        },
        {
          "id": "3601140007",
          "district_id": "3601140",
          "name": "CILABANBULAN"
        },
        {
          "id": "3601140008",
          "district_id": "3601140",
          "name": "SINDANGKARYA"
        },
        {
          "id": "3601140009",
          "district_id": "3601140",
          "name": "CIGANDENG"
        },
        {
          "id": "3601140010",
          "district_id": "3601140",
          "name": "PURWARAJA"
        },
        {
          "id": "3601140013",
          "district_id": "3601140",
          "name": "KADUPAYUNG"
        },
        {
          "id": "3601140014",
          "district_id": "3601140",
          "name": "SUKAMANAH"
        },
        {
          "id": "3601140015",
          "district_id": "3601140",
          "name": "RAMAYA"
        },
        {
          "id": "3601141001",
          "district_id": "3601141",
          "name": "BANJARNEGARA"
        },
        {
          "id": "3601141002",
          "district_id": "3601141",
          "name": "KADUHEJO"
        },
        {
          "id": "3601141003",
          "district_id": "3601141",
          "name": "KORANJI"
        },
        {
          "id": "3601141004",
          "district_id": "3601141",
          "name": "SANGHIANGDENGDEK"
        },
        {
          "id": "3601141005",
          "district_id": "3601141",
          "name": "CILENTUNG"
        },
        {
          "id": "3601141006",
          "district_id": "3601141",
          "name": "KARYAWANGI"
        },
        {
          "id": "3601141007",
          "district_id": "3601141",
          "name": "BANJARWANGI"
        },
        {
          "id": "3601141008",
          "district_id": "3601141",
          "name": "SUKASARI"
        },
        {
          "id": "3601141009",
          "district_id": "3601141",
          "name": "SUKARAJA"
        },
        {
          "id": "3601150001",
          "district_id": "3601150",
          "name": "PANDAT"
        },
        {
          "id": "3601150002",
          "district_id": "3601150",
          "name": "CIKONENG"
        },
        {
          "id": "3601150003",
          "district_id": "3601150",
          "name": "GIRIPAWANA"
        },
        {
          "id": "3601150004",
          "district_id": "3601150",
          "name": "NEMBOL"
        },
        {
          "id": "3601150005",
          "district_id": "3601150",
          "name": "GUNUNGSARI"
        },
        {
          "id": "3601150006",
          "district_id": "3601150",
          "name": "KURUNGKAMBING"
        },
        {
          "id": "3601150007",
          "district_id": "3601150",
          "name": "MANDALASARI"
        },
        {
          "id": "3601150008",
          "district_id": "3601150",
          "name": "MANDALAWANGI"
        },
        {
          "id": "3601150009",
          "district_id": "3601150",
          "name": "PARI"
        },
        {
          "id": "3601150010",
          "district_id": "3601150",
          "name": "SINARJAYA"
        },
        {
          "id": "3601150011",
          "district_id": "3601150",
          "name": "SIRNAGALIH"
        },
        {
          "id": "3601150012",
          "district_id": "3601150",
          "name": "CURUGLEMO"
        },
        {
          "id": "3601150013",
          "district_id": "3601150",
          "name": "PANJANGJAYA"
        },
        {
          "id": "3601150014",
          "district_id": "3601150",
          "name": "CIKUMBUEUN"
        },
        {
          "id": "3601150015",
          "district_id": "3601150",
          "name": "RAMEA"
        },
        {
          "id": "3601160008",
          "district_id": "3601160",
          "name": "KADUDODOL"
        },
        {
          "id": "3601160009",
          "district_id": "3601160",
          "name": "GUNUNGDATAR"
        },
        {
          "id": "3601160011",
          "district_id": "3601160",
          "name": "SEKONG"
        },
        {
          "id": "3601160019",
          "district_id": "3601160",
          "name": "CIMANUK"
        },
        {
          "id": "3601160020",
          "district_id": "3601160",
          "name": "BATUBANTAR"
        },
        {
          "id": "3601160021",
          "district_id": "3601160",
          "name": "ROCEK"
        },
        {
          "id": "3601160022",
          "district_id": "3601160",
          "name": "KADUMADANG"
        },
        {
          "id": "3601160023",
          "district_id": "3601160",
          "name": "DALEMBALAR"
        },
        {
          "id": "3601160024",
          "district_id": "3601160",
          "name": "KUPAHANDAP"
        },
        {
          "id": "3601160025",
          "district_id": "3601160",
          "name": "KADUBUNGBANG"
        },
        {
          "id": "3601161001",
          "district_id": "3601161",
          "name": "CIKADUEUN"
        },
        {
          "id": "3601161002",
          "district_id": "3601161",
          "name": "KONCANG"
        },
        {
          "id": "3601161003",
          "district_id": "3601161",
          "name": "PASIRMAE"
        },
        {
          "id": "3601161004",
          "district_id": "3601161",
          "name": "PARUMASAN"
        },
        {
          "id": "3601161005",
          "district_id": "3601161",
          "name": "KADUGADUNG"
        },
        {
          "id": "3601161006",
          "district_id": "3601161",
          "name": "PALANYAR"
        },
        {
          "id": "3601161007",
          "district_id": "3601161",
          "name": "BATURANJANG"
        },
        {
          "id": "3601161008",
          "district_id": "3601161",
          "name": "KALANGGUNUNG"
        },
        {
          "id": "3601161009",
          "district_id": "3601161",
          "name": "CURUGBARANG"
        },
        {
          "id": "3601161010",
          "district_id": "3601161",
          "name": "PASIREURIH"
        },
        {
          "id": "3601170004",
          "district_id": "3601170",
          "name": "CIBEUREUM"
        },
        {
          "id": "3601170005",
          "district_id": "3601170",
          "name": "CIBODAS"
        },
        {
          "id": "3601170007",
          "district_id": "3601170",
          "name": "KADULIMUS"
        },
        {
          "id": "3601170008",
          "district_id": "3601170",
          "name": "BANDUNG"
        },
        {
          "id": "3601170009",
          "district_id": "3601170",
          "name": "KADUMANEUH"
        },
        {
          "id": "3601170010",
          "district_id": "3601170",
          "name": "CITALAHAB"
        },
        {
          "id": "3601170011",
          "district_id": "3601170",
          "name": "PASIRAWI"
        },
        {
          "id": "3601170012",
          "district_id": "3601170",
          "name": "MOGANA"
        },
        {
          "id": "3601170013",
          "district_id": "3601170",
          "name": "KADUBALE"
        },
        {
          "id": "3601170014",
          "district_id": "3601170",
          "name": "BANJAR"
        },
        {
          "id": "3601170015",
          "district_id": "3601170",
          "name": "GUNUNGPUTRI"
        },
        {
          "id": "3601171001",
          "district_id": "3601171",
          "name": "BANJARSARI"
        },
        {
          "id": "3601171002",
          "district_id": "3601171",
          "name": "SUKAMANAH"
        },
        {
          "id": "3601171003",
          "district_id": "3601171",
          "name": "PALURAHAN"
        },
        {
          "id": "3601171004",
          "district_id": "3601171",
          "name": "KADUGEMBLO"
        },
        {
          "id": "3601171005",
          "district_id": "3601171",
          "name": "SUKASARI"
        },
        {
          "id": "3601171006",
          "district_id": "3601171",
          "name": "MANDALASARI"
        },
        {
          "id": "3601171007",
          "district_id": "3601171",
          "name": "SANINTEN"
        },
        {
          "id": "3601171008",
          "district_id": "3601171",
          "name": "BAYUMUNDU"
        },
        {
          "id": "3601171009",
          "district_id": "3601171",
          "name": "CAMPAKA"
        },
        {
          "id": "3601171010",
          "district_id": "3601171",
          "name": "CIPUTRI"
        },
        {
          "id": "3601172001",
          "district_id": "3601172",
          "name": "RANCABUGEL"
        },
        {
          "id": "3601172002",
          "district_id": "3601172",
          "name": "WIRASINGA"
        },
        {
          "id": "3601172003",
          "district_id": "3601172",
          "name": "PAREANG"
        },
        {
          "id": "3601172004",
          "district_id": "3601172",
          "name": "KADUBELANG"
        },
        {
          "id": "3601172005",
          "district_id": "3601172",
          "name": "MEKARJAYA"
        },
        {
          "id": "3601172006",
          "district_id": "3601172",
          "name": "KADUJANGKUNG"
        },
        {
          "id": "3601172007",
          "district_id": "3601172",
          "name": "MEDONG"
        },
        {
          "id": "3601172008",
          "district_id": "3601172",
          "name": "SUKAMULYA"
        },
        {
          "id": "3601180003",
          "district_id": "3601180",
          "name": "KADOMAS"
        },
        {
          "id": "3601180004",
          "district_id": "3601180",
          "name": "BABAKAN KALANGANYAR"
        },
        {
          "id": "3601180005",
          "district_id": "3601180",
          "name": "KABAYAN"
        },
        {
          "id": "3601180006",
          "district_id": "3601180",
          "name": "PANDEGLANG"
        },
        {
          "id": "3601181001",
          "district_id": "3601181",
          "name": "SUKARATU"
        },
        {
          "id": "3601181002",
          "district_id": "3601181",
          "name": "KARATON"
        },
        {
          "id": "3601181003",
          "district_id": "3601181",
          "name": "CILAJA"
        },
        {
          "id": "3601181004",
          "district_id": "3601181",
          "name": "SARUNI"
        },
        {
          "id": "3601181005",
          "district_id": "3601181",
          "name": "PAGERBATU"
        },
        {
          "id": "3601190017",
          "district_id": "3601190",
          "name": "CIKENTRUNG"
        },
        {
          "id": "3601190018",
          "district_id": "3601190",
          "name": "KAUNGCAANG"
        },
        {
          "id": "3601190019",
          "district_id": "3601190",
          "name": "CIINJUK"
        },
        {
          "id": "3601190020",
          "district_id": "3601190",
          "name": "CADASARI"
        },
        {
          "id": "3601190021",
          "district_id": "3601190",
          "name": "TAPOS"
        },
        {
          "id": "3601190022",
          "district_id": "3601190",
          "name": "TANAGARA"
        },
        {
          "id": "3601190023",
          "district_id": "3601190",
          "name": "KURUNGDAHU"
        },
        {
          "id": "3601190024",
          "district_id": "3601190",
          "name": "PASIRPEUTEUY"
        },
        {
          "id": "3601190025",
          "district_id": "3601190",
          "name": "KADUENGANG"
        },
        {
          "id": "3601190026",
          "district_id": "3601190",
          "name": "KADUELA"
        },
        {
          "id": "3601190027",
          "district_id": "3601190",
          "name": "KORANJI"
        },
        {
          "id": "3601191005",
          "district_id": "3601191",
          "name": "KADUMERAK"
        },
        {
          "id": "3601191006",
          "district_id": "3601191",
          "name": "PAGADUNGAN"
        },
        {
          "id": "3601191007",
          "district_id": "3601191",
          "name": "CIGADUNG"
        },
        {
          "id": "3601192001",
          "district_id": "3601192",
          "name": "PASIRJAKSA"
        },
        {
          "id": "3601192002",
          "district_id": "3601192",
          "name": "BANGKONOL"
        },
        {
          "id": "3601192003",
          "district_id": "3601192",
          "name": "TEGALONGOK"
        },
        {
          "id": "3601192004",
          "district_id": "3601192",
          "name": "PASIRKARAG"
        },
        {
          "id": "3601192005",
          "district_id": "3601192",
          "name": "PANIIS"
        },
        {
          "id": "3601192006",
          "district_id": "3601192",
          "name": "SETRAJAYA"
        },
        {
          "id": "3601192007",
          "district_id": "3601192",
          "name": "KARANGSETRA"
        },
        {
          "id": "3601192008",
          "district_id": "3601192",
          "name": "PAKULURAN"
        },
        {
          "id": "3601192009",
          "district_id": "3601192",
          "name": "KORONCONG"
        },
        {
          "id": "3601192010",
          "district_id": "3601192",
          "name": "GERENDONG"
        },
        {
          "id": "3601192011",
          "district_id": "3601192",
          "name": "AWILEGA"
        },
        {
          "id": "3601192012",
          "district_id": "3601192",
          "name": "SUKAJAYA"
        },
        {
          "id": "3602010004",
          "district_id": "3602010",
          "name": "SUKAMANAH"
        },
        {
          "id": "3602010005",
          "district_id": "3602010",
          "name": "MALINGPING SELATAN"
        },
        {
          "id": "3602010006",
          "district_id": "3602010",
          "name": "CILANGKAHAN"
        },
        {
          "id": "3602010007",
          "district_id": "3602010",
          "name": "PAGELARAN"
        },
        {
          "id": "3602010008",
          "district_id": "3602010",
          "name": "KERSARATU"
        },
        {
          "id": "3602010009",
          "district_id": "3602010",
          "name": "SUKARAJA"
        },
        {
          "id": "3602010010",
          "district_id": "3602010",
          "name": "KADUJAJAR"
        },
        {
          "id": "3602010011",
          "district_id": "3602010",
          "name": "MALINGPING UTARA"
        },
        {
          "id": "3602010012",
          "district_id": "3602010",
          "name": "RAHONG"
        },
        {
          "id": "3602010013",
          "district_id": "3602010",
          "name": "SANGHIANG"
        },
        {
          "id": "3602010014",
          "district_id": "3602010",
          "name": "BOLANG"
        },
        {
          "id": "3602010023",
          "district_id": "3602010",
          "name": "SUMBER WARAS"
        },
        {
          "id": "3602010024",
          "district_id": "3602010",
          "name": "CIPEUNDEUY"
        },
        {
          "id": "3602010025",
          "district_id": "3602010",
          "name": "SENANGHATI"
        },
        {
          "id": "3602011001",
          "district_id": "3602011",
          "name": "MUARA"
        },
        {
          "id": "3602011002",
          "district_id": "3602011",
          "name": "WANASALAM"
        },
        {
          "id": "3602011003",
          "district_id": "3602011",
          "name": "SUKATANI"
        },
        {
          "id": "3602011004",
          "district_id": "3602011",
          "name": "CIKEUSIK"
        },
        {
          "id": "3602011005",
          "district_id": "3602011",
          "name": "BEJOD"
        },
        {
          "id": "3602011006",
          "district_id": "3602011",
          "name": "CIPEDANG"
        },
        {
          "id": "3602011007",
          "district_id": "3602011",
          "name": "CISARAP"
        },
        {
          "id": "3602011008",
          "district_id": "3602011",
          "name": "PARUNGSARI"
        },
        {
          "id": "3602011009",
          "district_id": "3602011",
          "name": "CIPEUCANG"
        },
        {
          "id": "3602011010",
          "district_id": "3602011",
          "name": "PARUNGPANJANG"
        },
        {
          "id": "3602011011",
          "district_id": "3602011",
          "name": "KETAPANG"
        },
        {
          "id": "3602011012",
          "district_id": "3602011",
          "name": "CILANGKAP"
        },
        {
          "id": "3602011013",
          "district_id": "3602011",
          "name": "KARANG PAMINDANGAN"
        },
        {
          "id": "3602020006",
          "district_id": "3602020",
          "name": "SITUREGEN"
        },
        {
          "id": "3602020007",
          "district_id": "3602020",
          "name": "SUKAJADI"
        },
        {
          "id": "3602020008",
          "district_id": "3602020",
          "name": "HEGARMANAH"
        },
        {
          "id": "3602020009",
          "district_id": "3602020",
          "name": "PANGGARANGAN"
        },
        {
          "id": "3602020010",
          "district_id": "3602020",
          "name": "MEKARJAYA"
        },
        {
          "id": "3602020011",
          "district_id": "3602020",
          "name": "SINDANGRATU"
        },
        {
          "id": "3602020012",
          "district_id": "3602020",
          "name": "CIMANDIRI"
        },
        {
          "id": "3602020017",
          "district_id": "3602020",
          "name": "SOGONG"
        },
        {
          "id": "3602020018",
          "district_id": "3602020",
          "name": "JATAKE"
        },
        {
          "id": "3602020019",
          "district_id": "3602020",
          "name": "CIBARENGKOK"
        },
        {
          "id": "3602021001",
          "district_id": "3602021",
          "name": "PONDOKPANJANG"
        },
        {
          "id": "3602021002",
          "district_id": "3602021",
          "name": "CIPARAHU"
        },
        {
          "id": "3602021003",
          "district_id": "3602021",
          "name": "CIHARA"
        },
        {
          "id": "3602021004",
          "district_id": "3602021",
          "name": "KARANGKAMULYAN"
        },
        {
          "id": "3602021005",
          "district_id": "3602021",
          "name": "PANYAUNGAN"
        },
        {
          "id": "3602021006",
          "district_id": "3602021",
          "name": "MEKARSARI"
        },
        {
          "id": "3602021007",
          "district_id": "3602021",
          "name": "LEBAK PEUNDEUY"
        },
        {
          "id": "3602021008",
          "district_id": "3602021",
          "name": "CITEPUSEUN"
        },
        {
          "id": "3602021009",
          "district_id": "3602021",
          "name": "BARUNAI"
        },
        {
          "id": "3602030001",
          "district_id": "3602030",
          "name": "BAYAH BARAT"
        },
        {
          "id": "3602030002",
          "district_id": "3602030",
          "name": "DARMASARI"
        },
        {
          "id": "3602030003",
          "district_id": "3602030",
          "name": "SAWARNA"
        },
        {
          "id": "3602030011",
          "district_id": "3602030",
          "name": "CIDIKIT"
        },
        {
          "id": "3602030012",
          "district_id": "3602030",
          "name": "BAYAH TIMUR"
        },
        {
          "id": "3602030013",
          "district_id": "3602030",
          "name": "CIMANCAK"
        },
        {
          "id": "3602030014",
          "district_id": "3602030",
          "name": "SUWAKAN"
        },
        {
          "id": "3602030015",
          "district_id": "3602030",
          "name": "PASIRGOMBONG"
        },
        {
          "id": "3602030016",
          "district_id": "3602030",
          "name": "CISUREN"
        },
        {
          "id": "3602030017",
          "district_id": "3602030",
          "name": "PAMUBULAN"
        },
        {
          "id": "3602030018",
          "district_id": "3602030",
          "name": "SAWARNA TIMUR"
        },
        {
          "id": "3602031001",
          "district_id": "3602031",
          "name": "CIBARENO"
        },
        {
          "id": "3602031002",
          "district_id": "3602031",
          "name": "CILOGRANG"
        },
        {
          "id": "3602031003",
          "district_id": "3602031",
          "name": "LEBAKTIPAR"
        },
        {
          "id": "3602031004",
          "district_id": "3602031",
          "name": "CIKATOMAS"
        },
        {
          "id": "3602031005",
          "district_id": "3602031",
          "name": "CIJENGKOL"
        },
        {
          "id": "3602031006",
          "district_id": "3602031",
          "name": "PASIRBUNGUR"
        },
        {
          "id": "3602031007",
          "district_id": "3602031",
          "name": "CIKAMUNDING"
        },
        {
          "id": "3602031008",
          "district_id": "3602031",
          "name": "GIRIMUKTI"
        },
        {
          "id": "3602031009",
          "district_id": "3602031",
          "name": "CIREUNDEU"
        },
        {
          "id": "3602031010",
          "district_id": "3602031",
          "name": "GUNUNGBATU"
        },
        {
          "id": "3602040001",
          "district_id": "3602040",
          "name": "CIKOTOK"
        },
        {
          "id": "3602040002",
          "district_id": "3602040",
          "name": "CIBEBER"
        },
        {
          "id": "3602040003",
          "district_id": "3602040",
          "name": "WARUNGBANTEN"
        },
        {
          "id": "3602040004",
          "district_id": "3602040",
          "name": "NEGLASARI"
        },
        {
          "id": "3602040005",
          "district_id": "3602040",
          "name": "MEKARSARI"
        },
        {
          "id": "3602040007",
          "district_id": "3602040",
          "name": "CIKADU"
        },
        {
          "id": "3602040008",
          "district_id": "3602040",
          "name": "KUJANGJAYA"
        },
        {
          "id": "3602040009",
          "district_id": "3602040",
          "name": "CISUNGSANG"
        },
        {
          "id": "3602040010",
          "district_id": "3602040",
          "name": "HEGARMANAH"
        },
        {
          "id": "3602040011",
          "district_id": "3602040",
          "name": "CIHAMBALI"
        },
        {
          "id": "3602040012",
          "district_id": "3602040",
          "name": "SUKAMULYA"
        },
        {
          "id": "3602040013",
          "district_id": "3602040",
          "name": "CITOREK TENGAH"
        },
        {
          "id": "3602040014",
          "district_id": "3602040",
          "name": "CITOREK TIMUR"
        },
        {
          "id": "3602040015",
          "district_id": "3602040",
          "name": "CITOREK KIDUL"
        },
        {
          "id": "3602040016",
          "district_id": "3602040",
          "name": "KUJANGSARI"
        },
        {
          "id": "3602040017",
          "district_id": "3602040",
          "name": "SITUMULYA"
        },
        {
          "id": "3602040018",
          "district_id": "3602040",
          "name": "SINARGALIH"
        },
        {
          "id": "3602040019",
          "district_id": "3602040",
          "name": "WANASARI"
        },
        {
          "id": "3602040020",
          "district_id": "3602040",
          "name": "GUNUNG WANGUN"
        },
        {
          "id": "3602040021",
          "district_id": "3602040",
          "name": "CITOREK BARAT"
        },
        {
          "id": "3602040022",
          "district_id": "3602040",
          "name": "CIHERANG"
        },
        {
          "id": "3602040023",
          "district_id": "3602040",
          "name": "CITOREK SABRANG"
        },
        {
          "id": "3602050001",
          "district_id": "3602050",
          "name": "KANDANGSAPI"
        },
        {
          "id": "3602050002",
          "district_id": "3602050",
          "name": "CIHUJAN"
        },
        {
          "id": "3602050003",
          "district_id": "3602050",
          "name": "CIAPUS"
        },
        {
          "id": "3602050005",
          "district_id": "3602050",
          "name": "CIJAKU"
        },
        {
          "id": "3602050006",
          "district_id": "3602050",
          "name": "MEKARJAYA"
        },
        {
          "id": "3602050007",
          "district_id": "3602050",
          "name": "CIPALABUH"
        },
        {
          "id": "3602050008",
          "district_id": "3602050",
          "name": "CIBEUREUM"
        },
        {
          "id": "3602050009",
          "district_id": "3602050",
          "name": "CIMENGA"
        },
        {
          "id": "3602050010",
          "district_id": "3602050",
          "name": "SUKASENANG"
        },
        {
          "id": "3602050011",
          "district_id": "3602050",
          "name": "KAPUNDUHAN"
        },
        {
          "id": "3602051001",
          "district_id": "3602051",
          "name": "PEUCANGPARI"
        },
        {
          "id": "3602051002",
          "district_id": "3602051",
          "name": "CIBUNGUR"
        },
        {
          "id": "3602051003",
          "district_id": "3602051",
          "name": "CIKARET"
        },
        {
          "id": "3602051004",
          "district_id": "3602051",
          "name": "CIKADONGDONG"
        },
        {
          "id": "3602051005",
          "district_id": "3602051",
          "name": "CIKARATUAN"
        },
        {
          "id": "3602051006",
          "district_id": "3602051",
          "name": "MUGIJAYA"
        },
        {
          "id": "3602051007",
          "district_id": "3602051",
          "name": "CIGEMBLONG"
        },
        {
          "id": "3602051008",
          "district_id": "3602051",
          "name": "CIKATE"
        },
        {
          "id": "3602051009",
          "district_id": "3602051",
          "name": "WANGUNJAYA"
        },
        {
          "id": "3602060001",
          "district_id": "3602060",
          "name": "KERTARAHARJA"
        },
        {
          "id": "3602060002",
          "district_id": "3602060",
          "name": "KERTA"
        },
        {
          "id": "3602060003",
          "district_id": "3602060",
          "name": "BOJONGJURUH"
        },
        {
          "id": "3602060004",
          "district_id": "3602060",
          "name": "LEBAKKEUSIK"
        },
        {
          "id": "3602060005",
          "district_id": "3602060",
          "name": "LEUWIIPUH"
        },
        {
          "id": "3602060006",
          "district_id": "3602060",
          "name": "TAMANSARI"
        },
        {
          "id": "3602060007",
          "district_id": "3602060",
          "name": "CILEGONG ILIR"
        },
        {
          "id": "3602060008",
          "district_id": "3602060",
          "name": "CISAMPIH"
        },
        {
          "id": "3602060009",
          "district_id": "3602060",
          "name": "JALUPANG GIRANG"
        },
        {
          "id": "3602060010",
          "district_id": "3602060",
          "name": "CIDAHU"
        },
        {
          "id": "3602060011",
          "district_id": "3602060",
          "name": "KEUSIK"
        },
        {
          "id": "3602060012",
          "district_id": "3602060",
          "name": "CIRUJI"
        },
        {
          "id": "3602060013",
          "district_id": "3602060",
          "name": "CIBATURKEUSIK"
        },
        {
          "id": "3602060014",
          "district_id": "3602060",
          "name": "BENDUNGAN"
        },
        {
          "id": "3602060015",
          "district_id": "3602060",
          "name": "KUMPAY"
        },
        {
          "id": "3602060016",
          "district_id": "3602060",
          "name": "GUNUNGSARI"
        },
        {
          "id": "3602060017",
          "district_id": "3602060",
          "name": "KADUHAUK"
        },
        {
          "id": "3602060018",
          "district_id": "3602060",
          "name": "LABANJAYA"
        },
        {
          "id": "3602060019",
          "district_id": "3602060",
          "name": "UMBULJAYA"
        },
        {
          "id": "3602060020",
          "district_id": "3602060",
          "name": "KERTARAHAYU"
        },
        {
          "id": "3602070001",
          "district_id": "3602070",
          "name": "MEKARJAYA"
        },
        {
          "id": "3602070002",
          "district_id": "3602070",
          "name": "PASINDANGAN"
        },
        {
          "id": "3602070003",
          "district_id": "3602070",
          "name": "KUJANGSARI"
        },
        {
          "id": "3602070004",
          "district_id": "3602070",
          "name": "PARUNGKUJANG"
        },
        {
          "id": "3602070005",
          "district_id": "3602070",
          "name": "CIKAREO"
        },
        {
          "id": "3602070006",
          "district_id": "3602070",
          "name": "CILELES"
        },
        {
          "id": "3602070007",
          "district_id": "3602070",
          "name": "MARGAMULYA"
        },
        {
          "id": "3602070008",
          "district_id": "3602070",
          "name": "CIPADANG"
        },
        {
          "id": "3602070009",
          "district_id": "3602070",
          "name": "DAROYON"
        },
        {
          "id": "3602070010",
          "district_id": "3602070",
          "name": "PRABUGANTUNGAN"
        },
        {
          "id": "3602070012",
          "district_id": "3602070",
          "name": "BANJARSARI"
        },
        {
          "id": "3602080002",
          "district_id": "3602080",
          "name": "CIMANYANGRAY"
        },
        {
          "id": "3602080003",
          "district_id": "3602080",
          "name": "KERAMATJAYA"
        },
        {
          "id": "3602080004",
          "district_id": "3602080",
          "name": "BULAKAN"
        },
        {
          "id": "3602080005",
          "district_id": "3602080",
          "name": "CICARINGIN"
        },
        {
          "id": "3602080006",
          "district_id": "3602080",
          "name": "CIAKAR"
        },
        {
          "id": "3602080007",
          "district_id": "3602080",
          "name": "CISAMPANG"
        },
        {
          "id": "3602080008",
          "district_id": "3602080",
          "name": "BOJONG KONENG"
        },
        {
          "id": "3602080009",
          "district_id": "3602080",
          "name": "CIGINGGANG"
        },
        {
          "id": "3602080010",
          "district_id": "3602080",
          "name": "GUNUNG KENCANA"
        },
        {
          "id": "3602080011",
          "district_id": "3602080",
          "name": "SUKANEGARA"
        },
        {
          "id": "3602080012",
          "district_id": "3602080",
          "name": "TANJUNGSARI INDAH"
        },
        {
          "id": "3602090007",
          "district_id": "3602090",
          "name": "KEBONCAU"
        },
        {
          "id": "3602090008",
          "district_id": "3602090",
          "name": "CIMAYANG"
        },
        {
          "id": "3602090009",
          "district_id": "3602090",
          "name": "PARAKANBEUSI"
        },
        {
          "id": "3602090010",
          "district_id": "3602090",
          "name": "BOJONGMANIK"
        },
        {
          "id": "3602090011",
          "district_id": "3602090",
          "name": "MEKARMANIK"
        },
        {
          "id": "3602090014",
          "district_id": "3602090",
          "name": "KADURAHAYU"
        },
        {
          "id": "3602090015",
          "district_id": "3602090",
          "name": "HARJAWANA"
        },
        {
          "id": "3602090016",
          "district_id": "3602090",
          "name": "MEKAR RAHAYU"
        },
        {
          "id": "3602090017",
          "district_id": "3602090",
          "name": "PASIR BITUNG"
        },
        {
          "id": "3602091001",
          "district_id": "3602091",
          "name": "PARAKANLIMA"
        },
        {
          "id": "3602091002",
          "district_id": "3602091",
          "name": "KADUDAMAS"
        },
        {
          "id": "3602091003",
          "district_id": "3602091",
          "name": "DATARCAE"
        },
        {
          "id": "3602091004",
          "district_id": "3602091",
          "name": "KAROYA"
        },
        {
          "id": "3602091005",
          "district_id": "3602091",
          "name": "NANGERANG"
        },
        {
          "id": "3602091006",
          "district_id": "3602091",
          "name": "CIRINTEN"
        },
        {
          "id": "3602091007",
          "district_id": "3602091",
          "name": "KARANGNUNGGAL"
        },
        {
          "id": "3602091008",
          "district_id": "3602091",
          "name": "CEMPAKA"
        },
        {
          "id": "3602091009",
          "district_id": "3602091",
          "name": "BADUR"
        },
        {
          "id": "3602091010",
          "district_id": "3602091",
          "name": "CIBARANI"
        },
        {
          "id": "3602100001",
          "district_id": "3602100",
          "name": "KANEKES"
        },
        {
          "id": "3602100002",
          "district_id": "3602100",
          "name": "NAYAGATI"
        },
        {
          "id": "3602100003",
          "district_id": "3602100",
          "name": "BOJONG MENTENG"
        },
        {
          "id": "3602100004",
          "district_id": "3602100",
          "name": "CISIMEUT"
        },
        {
          "id": "3602100005",
          "district_id": "3602100",
          "name": "MARGAWANGI"
        },
        {
          "id": "3602100006",
          "district_id": "3602100",
          "name": "SANGKANWANGI"
        },
        {
          "id": "3602100007",
          "district_id": "3602100",
          "name": "JALUPANG MULYA"
        },
        {
          "id": "3602100008",
          "district_id": "3602100",
          "name": "LEUWIDAMAR"
        },
        {
          "id": "3602100009",
          "district_id": "3602100",
          "name": "CIBUNGUR"
        },
        {
          "id": "3602100010",
          "district_id": "3602100",
          "name": "LEBAK PARAHIANG"
        },
        {
          "id": "3602100011",
          "district_id": "3602100",
          "name": "WANTISARI"
        },
        {
          "id": "3602100012",
          "district_id": "3602100",
          "name": "CISIMEUT RAYA"
        },
        {
          "id": "3602110010",
          "district_id": "3602110",
          "name": "PASIREURIH"
        },
        {
          "id": "3602110011",
          "district_id": "3602110",
          "name": "PASIRNANGKA"
        },
        {
          "id": "3602110012",
          "district_id": "3602110",
          "name": "CIKARANG"
        },
        {
          "id": "3602110013",
          "district_id": "3602110",
          "name": "CIMINYAK"
        },
        {
          "id": "3602110014",
          "district_id": "3602110",
          "name": "LEUWICOO"
        },
        {
          "id": "3602110015",
          "district_id": "3602110",
          "name": "MUNCANG"
        },
        {
          "id": "3602110016",
          "district_id": "3602110",
          "name": "SUKANAGARA"
        },
        {
          "id": "3602110017",
          "district_id": "3602110",
          "name": "SINDANGWANGI"
        },
        {
          "id": "3602110018",
          "district_id": "3602110",
          "name": "JAGARAKSA"
        },
        {
          "id": "3602110019",
          "district_id": "3602110",
          "name": "TANJUNGWANGI"
        },
        {
          "id": "3602110020",
          "district_id": "3602110",
          "name": "MEKARWANGI"
        },
        {
          "id": "3602110021",
          "district_id": "3602110",
          "name": "GIRI JAGABAYA"
        },
        {
          "id": "3602111001",
          "district_id": "3602111",
          "name": "SINARJAYA"
        },
        {
          "id": "3602111002",
          "district_id": "3602111",
          "name": "CIROMPANG"
        },
        {
          "id": "3602111003",
          "district_id": "3602111",
          "name": "SUKAMAJU"
        },
        {
          "id": "3602111004",
          "district_id": "3602111",
          "name": "MAJASARI"
        },
        {
          "id": "3602111005",
          "district_id": "3602111",
          "name": "CIPARASI"
        },
        {
          "id": "3602111006",
          "district_id": "3602111",
          "name": "SINDANGLAYA"
        },
        {
          "id": "3602111007",
          "district_id": "3602111",
          "name": "SOBANG"
        },
        {
          "id": "3602111008",
          "district_id": "3602111",
          "name": "SUKAJAYA"
        },
        {
          "id": "3602111009",
          "district_id": "3602111",
          "name": "HARIANG"
        },
        {
          "id": "3602111010",
          "district_id": "3602111",
          "name": "SUKARESMI"
        },
        {
          "id": "3602120006",
          "district_id": "3602120",
          "name": "PASIRHAUR"
        },
        {
          "id": "3602120007",
          "district_id": "3602120",
          "name": "GIRILAYA"
        },
        {
          "id": "3602120008",
          "district_id": "3602120",
          "name": "JAYAPURA"
        },
        {
          "id": "3602120009",
          "district_id": "3602120",
          "name": "GIRIHARJA"
        },
        {
          "id": "3602120010",
          "district_id": "3602120",
          "name": "BINTANGSARI"
        },
        {
          "id": "3602120011",
          "district_id": "3602120",
          "name": "CIPANAS"
        },
        {
          "id": "3602120013",
          "district_id": "3602120",
          "name": "LUHURJAYA"
        },
        {
          "id": "3602120014",
          "district_id": "3602120",
          "name": "SIPAYUNG"
        },
        {
          "id": "3602120015",
          "district_id": "3602120",
          "name": "BINTANGRESMI"
        },
        {
          "id": "3602120016",
          "district_id": "3602120",
          "name": "MALANGSARI"
        },
        {
          "id": "3602120017",
          "district_id": "3602120",
          "name": "SUKASARI"
        },
        {
          "id": "3602120018",
          "district_id": "3602120",
          "name": "HAURGAJRUG"
        },
        {
          "id": "3602120019",
          "district_id": "3602120",
          "name": "TALAGAHIANG"
        },
        {
          "id": "3602120020",
          "district_id": "3602120",
          "name": "HARUMSARI"
        },
        {
          "id": "3602121001",
          "district_id": "3602121",
          "name": "LEBAKGEDONG"
        },
        {
          "id": "3602121002",
          "district_id": "3602121",
          "name": "LEBAKSITU"
        },
        {
          "id": "3602121003",
          "district_id": "3602121",
          "name": "CILADAEUN"
        },
        {
          "id": "3602121004",
          "district_id": "3602121",
          "name": "BANJARSARI"
        },
        {
          "id": "3602121005",
          "district_id": "3602121",
          "name": "LEBAKSANGKA"
        },
        {
          "id": "3602121006",
          "district_id": "3602121",
          "name": "BANJAR IRIGASI"
        },
        {
          "id": "3602130001",
          "district_id": "3602130",
          "name": "MARAYA"
        },
        {
          "id": "3602130002",
          "district_id": "3602130",
          "name": "MARGALUYU"
        },
        {
          "id": "3602130003",
          "district_id": "3602130",
          "name": "SUKAMARGA"
        },
        {
          "id": "3602130004",
          "district_id": "3602130",
          "name": "SINDANGSARI"
        },
        {
          "id": "3602130005",
          "district_id": "3602130",
          "name": "SAJIRAMEKAR"
        },
        {
          "id": "3602130006",
          "district_id": "3602130",
          "name": "SAJIRA"
        },
        {
          "id": "3602130007",
          "district_id": "3602130",
          "name": "SUKARAME"
        },
        {
          "id": "3602130008",
          "district_id": "3602130",
          "name": "CALUNGBUNGUR"
        },
        {
          "id": "3602130009",
          "district_id": "3602130",
          "name": "SUKAJAYA"
        },
        {
          "id": "3602130010",
          "district_id": "3602130",
          "name": "PAJA"
        },
        {
          "id": "3602130011",
          "district_id": "3602130",
          "name": "MEKARSARI"
        },
        {
          "id": "3602130012",
          "district_id": "3602130",
          "name": "PAJAGAN"
        },
        {
          "id": "3602130013",
          "district_id": "3602130",
          "name": "PARUNGSARI"
        },
        {
          "id": "3602130014",
          "district_id": "3602130",
          "name": "BUNGUR MEKAR"
        },
        {
          "id": "3602130015",
          "district_id": "3602130",
          "name": "CIUYAH"
        },
        {
          "id": "3602140001",
          "district_id": "3602140",
          "name": "SARAGENI"
        },
        {
          "id": "3602140002",
          "district_id": "3602140",
          "name": "JAYASARI"
        },
        {
          "id": "3602140003",
          "district_id": "3602140",
          "name": "MARGATIRTA"
        },
        {
          "id": "3602140004",
          "district_id": "3602140",
          "name": "GUNUNG ANTEN"
        },
        {
          "id": "3602140005",
          "district_id": "3602140",
          "name": "SANGKAN MANIK"
        },
        {
          "id": "3602140006",
          "district_id": "3602140",
          "name": "SUDAMANIK"
        },
        {
          "id": "3602140007",
          "district_id": "3602140",
          "name": "GIRIMUKTI"
        },
        {
          "id": "3602140008",
          "district_id": "3602140",
          "name": "JAYAMANIK"
        },
        {
          "id": "3602140009",
          "district_id": "3602140",
          "name": "MARGALUYU"
        },
        {
          "id": "3602140010",
          "district_id": "3602140",
          "name": "SANGIANG JAYA"
        },
        {
          "id": "3602140011",
          "district_id": "3602140",
          "name": "TAMBAK"
        },
        {
          "id": "3602140012",
          "district_id": "3602140",
          "name": "MARGA JAYA"
        },
        {
          "id": "3602140013",
          "district_id": "3602140",
          "name": "CIMARGA"
        },
        {
          "id": "3602140014",
          "district_id": "3602140",
          "name": "MEKAR JAYA"
        },
        {
          "id": "3602140015",
          "district_id": "3602140",
          "name": "INTEN JAYA"
        },
        {
          "id": "3602140016",
          "district_id": "3602140",
          "name": "KARYA JAYA"
        },
        {
          "id": "3602140017",
          "district_id": "3602140",
          "name": "MEKARMULYA"
        },
        {
          "id": "3602150001",
          "district_id": "3602150",
          "name": "ANGGALAN"
        },
        {
          "id": "3602150002",
          "district_id": "3602150",
          "name": "MUARADUA"
        },
        {
          "id": "3602150003",
          "district_id": "3602150",
          "name": "MUNCANGKOPONG"
        },
        {
          "id": "3602150004",
          "district_id": "3602150",
          "name": "TAMAN JAYA"
        },
        {
          "id": "3602150005",
          "district_id": "3602150",
          "name": "CURUGPANJANG"
        },
        {
          "id": "3602150006",
          "district_id": "3602150",
          "name": "CIKULUR"
        },
        {
          "id": "3602150007",
          "district_id": "3602150",
          "name": "CIGOONG SELATAN"
        },
        {
          "id": "3602150008",
          "district_id": "3602150",
          "name": "CIGOONG UTARA"
        },
        {
          "id": "3602150009",
          "district_id": "3602150",
          "name": "SUMURBANDUNG"
        },
        {
          "id": "3602150010",
          "district_id": "3602150",
          "name": "SUKAHARJA"
        },
        {
          "id": "3602150011",
          "district_id": "3602150",
          "name": "SUKADAYA"
        },
        {
          "id": "3602150012",
          "district_id": "3602150",
          "name": "PARAGE"
        },
        {
          "id": "3602150013",
          "district_id": "3602150",
          "name": "PASIR GINTUNG"
        },
        {
          "id": "3602160001",
          "district_id": "3602160",
          "name": "PASIRTANGKIL"
        },
        {
          "id": "3602160002",
          "district_id": "3602160",
          "name": "SUKARENDAH"
        },
        {
          "id": "3602160003",
          "district_id": "3602160",
          "name": "SELARAJA"
        },
        {
          "id": "3602160004",
          "district_id": "3602160",
          "name": "WARUNGGUNUNG"
        },
        {
          "id": "3602160005",
          "district_id": "3602160",
          "name": "CIBUAH"
        },
        {
          "id": "3602160006",
          "district_id": "3602160",
          "name": "BAROS"
        },
        {
          "id": "3602160007",
          "district_id": "3602160",
          "name": "SINDANGSARI"
        },
        {
          "id": "3602160008",
          "district_id": "3602160",
          "name": "BANJARSARI"
        },
        {
          "id": "3602160009",
          "district_id": "3602160",
          "name": "CEMPAKA"
        },
        {
          "id": "3602160010",
          "district_id": "3602160",
          "name": "PADASUKA"
        },
        {
          "id": "3602160011",
          "district_id": "3602160",
          "name": "SUKARAJA"
        },
        {
          "id": "3602160012",
          "district_id": "3602160",
          "name": "JAGABAYA"
        },
        {
          "id": "3602170001",
          "district_id": "3602170",
          "name": "TAMBAKBAYA"
        },
        {
          "id": "3602170002",
          "district_id": "3602170",
          "name": "BOJONGLELES"
        },
        {
          "id": "3602170003",
          "district_id": "3602170",
          "name": "KADUAGUNG TIMUR"
        },
        {
          "id": "3602170004",
          "district_id": "3602170",
          "name": "KADUAGUNG BARAT"
        },
        {
          "id": "3602170005",
          "district_id": "3602170",
          "name": "MALABAR"
        },
        {
          "id": "3602170006",
          "district_id": "3602170",
          "name": "PASAR KEONG"
        },
        {
          "id": "3602170007",
          "district_id": "3602170",
          "name": "CIBADAK"
        },
        {
          "id": "3602170008",
          "district_id": "3602170",
          "name": "PANANCANGAN"
        },
        {
          "id": "3602170009",
          "district_id": "3602170",
          "name": "ASEM"
        },
        {
          "id": "3602170010",
          "district_id": "3602170",
          "name": "CISANGU"
        },
        {
          "id": "3602170011",
          "district_id": "3602170",
          "name": "BOJONGCAE"
        },
        {
          "id": "3602170012",
          "district_id": "3602170",
          "name": "KADUAGUNG TENGAH"
        },
        {
          "id": "3602170013",
          "district_id": "3602170",
          "name": "MEKAR AGUNG"
        },
        {
          "id": "3602170014",
          "district_id": "3602170",
          "name": "ASEM MARGALUYU"
        },
        {
          "id": "3602170015",
          "district_id": "3602170",
          "name": "CIMENTENG JAYA"
        },
        {
          "id": "3602180007",
          "district_id": "3602180",
          "name": "PASIR TANJUNG"
        },
        {
          "id": "3602180008",
          "district_id": "3602180",
          "name": "RANGKASBITUNG TIMUR"
        },
        {
          "id": "3602180009",
          "district_id": "3602180",
          "name": "RANGKASBITUNG BARAT"
        },
        {
          "id": "3602180010",
          "district_id": "3602180",
          "name": "MUARA CIUJUNG TIMUR"
        },
        {
          "id": "3602180011",
          "district_id": "3602180",
          "name": "JATIMULYA"
        },
        {
          "id": "3602180012",
          "district_id": "3602180",
          "name": "CIMANGEUNGTEUNG"
        },
        {
          "id": "3602180013",
          "district_id": "3602180",
          "name": "CITERAS"
        },
        {
          "id": "3602180014",
          "district_id": "3602180",
          "name": "MEKARSARI"
        },
        {
          "id": "3602180015",
          "district_id": "3602180",
          "name": "NAMENG"
        },
        {
          "id": "3602180016",
          "district_id": "3602180",
          "name": "KOLELET WETAN"
        },
        {
          "id": "3602180017",
          "district_id": "3602180",
          "name": "SUKAMANAH"
        },
        {
          "id": "3602180018",
          "district_id": "3602180",
          "name": "PABUARAN"
        },
        {
          "id": "3602180019",
          "district_id": "3602180",
          "name": "CIJORO PASIR"
        },
        {
          "id": "3602180020",
          "district_id": "3602180",
          "name": "CIJORO LEBAK"
        },
        {
          "id": "3602180021",
          "district_id": "3602180",
          "name": "MUARA CIUJUNG BARAT"
        },
        {
          "id": "3602180022",
          "district_id": "3602180",
          "name": "NARIMBANG MULIA"
        },
        {
          "id": "3602181001",
          "district_id": "3602181",
          "name": "CILANGKAP"
        },
        {
          "id": "3602181002",
          "district_id": "3602181",
          "name": "PASIR KUPA"
        },
        {
          "id": "3602181003",
          "district_id": "3602181",
          "name": "AWEH"
        },
        {
          "id": "3602181004",
          "district_id": "3602181",
          "name": "SUKAMEKARSARI"
        },
        {
          "id": "3602181005",
          "district_id": "3602181",
          "name": "KALANGANYAR"
        },
        {
          "id": "3602181006",
          "district_id": "3602181",
          "name": "SANGIANG TANJUNG"
        },
        {
          "id": "3602181007",
          "district_id": "3602181",
          "name": "CIKATAPIS"
        },
        {
          "id": "3602190008",
          "district_id": "3602190",
          "name": "CILANGKAP"
        },
        {
          "id": "3602190009",
          "district_id": "3602190",
          "name": "PASIR KECAPI"
        },
        {
          "id": "3602190012",
          "district_id": "3602190",
          "name": "MEKARSARI"
        },
        {
          "id": "3602190013",
          "district_id": "3602190",
          "name": "SANGIANG"
        },
        {
          "id": "3602190014",
          "district_id": "3602190",
          "name": "TANJUNG SARI"
        },
        {
          "id": "3602190015",
          "district_id": "3602190",
          "name": "MAJA"
        },
        {
          "id": "3602190016",
          "district_id": "3602190",
          "name": "CURUG BADAK"
        },
        {
          "id": "3602190017",
          "district_id": "3602190",
          "name": "PASIR KEMBANG"
        },
        {
          "id": "3602190018",
          "district_id": "3602190",
          "name": "PADASUKA"
        },
        {
          "id": "3602190019",
          "district_id": "3602190",
          "name": "GUBUGANCIBEUREUM"
        },
        {
          "id": "3602190020",
          "district_id": "3602190",
          "name": "BINONG"
        },
        {
          "id": "3602190021",
          "district_id": "3602190",
          "name": "SINDANGMULYA"
        },
        {
          "id": "3602190022",
          "district_id": "3602190",
          "name": "BUYUT MEKAR"
        },
        {
          "id": "3602190023",
          "district_id": "3602190",
          "name": "MAJA BARU"
        },
        {
          "id": "3602191001",
          "district_id": "3602191",
          "name": "GURADOG"
        },
        {
          "id": "3602191002",
          "district_id": "3602191",
          "name": "CANDI"
        },
        {
          "id": "3602191003",
          "district_id": "3602191",
          "name": "SEKARWANGI"
        },
        {
          "id": "3602191004",
          "district_id": "3602191",
          "name": "CURUGBITUNG"
        },
        {
          "id": "3602191005",
          "district_id": "3602191",
          "name": "CIBURUY"
        },
        {
          "id": "3602191006",
          "district_id": "3602191",
          "name": "MAYAK"
        },
        {
          "id": "3602191007",
          "district_id": "3602191",
          "name": "CILAYANG"
        },
        {
          "id": "3602191008",
          "district_id": "3602191",
          "name": "CIPINING"
        },
        {
          "id": "3602191009",
          "district_id": "3602191",
          "name": "CIDADAP"
        },
        {
          "id": "3602191010",
          "district_id": "3602191",
          "name": "LEBAKASIH"
        },
        {
          "id": "3603010008",
          "district_id": "3603010",
          "name": "JEUNG JING"
        },
        {
          "id": "3603010009",
          "district_id": "3603010",
          "name": "CISOKA"
        },
        {
          "id": "3603010010",
          "district_id": "3603010",
          "name": "SUKATANI"
        },
        {
          "id": "3603010011",
          "district_id": "3603010",
          "name": "CEMPAKA"
        },
        {
          "id": "3603010012",
          "district_id": "3603010",
          "name": "KARANGHARJA"
        },
        {
          "id": "3603010013",
          "district_id": "3603010",
          "name": "CARENANG"
        },
        {
          "id": "3603010014",
          "district_id": "3603010",
          "name": "BOJONGLOA"
        },
        {
          "id": "3603010015",
          "district_id": "3603010",
          "name": "CARINGIN"
        },
        {
          "id": "3603010016",
          "district_id": "3603010",
          "name": "SELAPAJANG"
        },
        {
          "id": "3603010017",
          "district_id": "3603010",
          "name": "CIBUGEL"
        },
        {
          "id": "3603011001",
          "district_id": "3603011",
          "name": "CIKASUNGKA"
        },
        {
          "id": "3603011002",
          "district_id": "3603011",
          "name": "CIKUYA"
        },
        {
          "id": "3603011003",
          "district_id": "3603011",
          "name": "CIKAREO"
        },
        {
          "id": "3603011004",
          "district_id": "3603011",
          "name": "CIREUNDEU"
        },
        {
          "id": "3603011005",
          "district_id": "3603011",
          "name": "SOLEAR"
        },
        {
          "id": "3603011006",
          "district_id": "3603011",
          "name": "PASANGGRAHAN"
        },
        {
          "id": "3603020001",
          "district_id": "3603020",
          "name": "CILELES"
        },
        {
          "id": "3603020002",
          "district_id": "3603020",
          "name": "BANTAR PANJANG"
        },
        {
          "id": "3603020003",
          "district_id": "3603020",
          "name": "SODONG"
        },
        {
          "id": "3603020004",
          "district_id": "3603020",
          "name": "TAPOS"
        },
        {
          "id": "3603020013",
          "district_id": "3603020",
          "name": "MARGA SARI"
        },
        {
          "id": "3603020014",
          "district_id": "3603020",
          "name": "KADU AGUNG"
        },
        {
          "id": "3603020015",
          "district_id": "3603020",
          "name": "MATA GARA"
        },
        {
          "id": "3603020016",
          "district_id": "3603020",
          "name": "TIGARAKSA"
        },
        {
          "id": "3603020018",
          "district_id": "3603020",
          "name": "TEGALSARI"
        },
        {
          "id": "3603020019",
          "district_id": "3603020",
          "name": "PEMATANG"
        },
        {
          "id": "3603020020",
          "district_id": "3603020",
          "name": "PASIR NANGKA"
        },
        {
          "id": "3603020021",
          "district_id": "3603020",
          "name": "CISEREH"
        },
        {
          "id": "3603020022",
          "district_id": "3603020",
          "name": "PASIR BOLANG"
        },
        {
          "id": "3603021001",
          "district_id": "3603021",
          "name": "MEKARSARI"
        },
        {
          "id": "3603021002",
          "district_id": "3603021",
          "name": "DARU"
        },
        {
          "id": "3603021003",
          "district_id": "3603021",
          "name": "SUKA MANAH"
        },
        {
          "id": "3603021004",
          "district_id": "3603021",
          "name": "TABAN"
        },
        {
          "id": "3603021005",
          "district_id": "3603021",
          "name": "ANCOL PASIR"
        },
        {
          "id": "3603021006",
          "district_id": "3603021",
          "name": "RANCABUAYA"
        },
        {
          "id": "3603021007",
          "district_id": "3603021",
          "name": "TIPARRAYA"
        },
        {
          "id": "3603021008",
          "district_id": "3603021",
          "name": "JAMBE"
        },
        {
          "id": "3603021010",
          "district_id": "3603021",
          "name": "PASIR BARAT"
        },
        {
          "id": "3603030001",
          "district_id": "3603030",
          "name": "BUDI MULYA"
        },
        {
          "id": "3603030002",
          "district_id": "3603030",
          "name": "BOJONG"
        },
        {
          "id": "3603030003",
          "district_id": "3603030",
          "name": "SUKA MULYA"
        },
        {
          "id": "3603030004",
          "district_id": "3603030",
          "name": "CIKUPA"
        },
        {
          "id": "3603030006",
          "district_id": "3603030",
          "name": "BITUNG JAYA"
        },
        {
          "id": "3603030008",
          "district_id": "3603030",
          "name": "SUKA DAMAI"
        },
        {
          "id": "3603030009",
          "district_id": "3603030",
          "name": "PASIR JAYA"
        },
        {
          "id": "3603030010",
          "district_id": "3603030",
          "name": "PASIR GADUNG"
        },
        {
          "id": "3603030011",
          "district_id": "3603030",
          "name": "TALAGA SARI"
        },
        {
          "id": "3603030012",
          "district_id": "3603030",
          "name": "TALAGA"
        },
        {
          "id": "3603030013",
          "district_id": "3603030",
          "name": "SUKA NAGARA"
        },
        {
          "id": "3603030014",
          "district_id": "3603030",
          "name": "CIBADAK"
        },
        {
          "id": "3603040001",
          "district_id": "3603040",
          "name": "RANCA IYUH"
        },
        {
          "id": "3603040002",
          "district_id": "3603040",
          "name": "MEKAR JAYA"
        },
        {
          "id": "3603040003",
          "district_id": "3603040",
          "name": "RANCA KALAPA"
        },
        {
          "id": "3603040004",
          "district_id": "3603040",
          "name": "PANONGAN"
        },
        {
          "id": "3603040005",
          "district_id": "3603040",
          "name": "SERDANG KULON"
        },
        {
          "id": "3603040006",
          "district_id": "3603040",
          "name": "CIAKAR"
        },
        {
          "id": "3603040007",
          "district_id": "3603040",
          "name": "MEKAR BAKTI"
        },
        {
          "id": "3603040008",
          "district_id": "3603040",
          "name": "PEUSAR"
        },
        {
          "id": "3603050001",
          "district_id": "3603050",
          "name": "CURUG KULON"
        },
        {
          "id": "3603050002",
          "district_id": "3603050",
          "name": "CURUG WETAN"
        },
        {
          "id": "3603050003",
          "district_id": "3603050",
          "name": "SUKA BAKTI"
        },
        {
          "id": "3603050004",
          "district_id": "3603050",
          "name": "CUKANG GALIH"
        },
        {
          "id": "3603050005",
          "district_id": "3603050",
          "name": "KADU JAYA"
        },
        {
          "id": "3603050006",
          "district_id": "3603050",
          "name": "KADU"
        },
        {
          "id": "3603050007",
          "district_id": "3603050",
          "name": "BINONG"
        },
        {
          "id": "3603051001",
          "district_id": "3603051",
          "name": "BOJONG NANGKA"
        },
        {
          "id": "3603051002",
          "district_id": "3603051",
          "name": "CURUG SANGERENG"
        },
        {
          "id": "3603051003",
          "district_id": "3603051",
          "name": "PAKULONAN BARAT"
        },
        {
          "id": "3603051004",
          "district_id": "3603051",
          "name": "KELAPA DUA"
        },
        {
          "id": "3603051005",
          "district_id": "3603051",
          "name": "BENCONGAN INDAH"
        },
        {
          "id": "3603051006",
          "district_id": "3603051",
          "name": "BENCONGAN"
        },
        {
          "id": "3603060001",
          "district_id": "3603060",
          "name": "CIANGIR"
        },
        {
          "id": "3603060002",
          "district_id": "3603060",
          "name": "BABAT"
        },
        {
          "id": "3603060003",
          "district_id": "3603060",
          "name": "BOJONG KAMAL"
        },
        {
          "id": "3603060004",
          "district_id": "3603060",
          "name": "CIRARAB"
        },
        {
          "id": "3603060005",
          "district_id": "3603060",
          "name": "CARINGIN"
        },
        {
          "id": "3603060006",
          "district_id": "3603060",
          "name": "BABAKAN"
        },
        {
          "id": "3603060007",
          "district_id": "3603060",
          "name": "KAMUNING"
        },
        {
          "id": "3603060008",
          "district_id": "3603060",
          "name": "PALA SARI"
        },
        {
          "id": "3603060009",
          "district_id": "3603060",
          "name": "SERDANG WETAN"
        },
        {
          "id": "3603060010",
          "district_id": "3603060",
          "name": "RANCAGONG"
        },
        {
          "id": "3603060011",
          "district_id": "3603060",
          "name": "LEGOK"
        },
        {
          "id": "3603070001",
          "district_id": "3603070",
          "name": "KARANG TENGAH"
        },
        {
          "id": "3603070002",
          "district_id": "3603070",
          "name": "MALANG NENGAH"
        },
        {
          "id": "3603070003",
          "district_id": "3603070",
          "name": "JATAKE"
        },
        {
          "id": "3603070004",
          "district_id": "3603070",
          "name": "KADU SIRUNG"
        },
        {
          "id": "3603070005",
          "district_id": "3603070",
          "name": "SITU GADUNG"
        },
        {
          "id": "3603070006",
          "district_id": "3603070",
          "name": "PAGEDANGAN"
        },
        {
          "id": "3603070007",
          "district_id": "3603070",
          "name": "CICALENGKA"
        },
        {
          "id": "3603070008",
          "district_id": "3603070",
          "name": "LENGKONG KULON"
        },
        {
          "id": "3603070009",
          "district_id": "3603070",
          "name": "CIJANTRA"
        },
        {
          "id": "3603070010",
          "district_id": "3603070",
          "name": "MEDANG"
        },
        {
          "id": "3603070012",
          "district_id": "3603070",
          "name": "CIHUNI"
        },
        {
          "id": "3603081001",
          "district_id": "3603081",
          "name": "MEKARWANGI"
        },
        {
          "id": "3603081002",
          "district_id": "3603081",
          "name": "DANGDANG"
        },
        {
          "id": "3603081003",
          "district_id": "3603081",
          "name": "SURADITA"
        },
        {
          "id": "3603081004",
          "district_id": "3603081",
          "name": "CISAUK"
        },
        {
          "id": "3603081005",
          "district_id": "3603081",
          "name": "SAMPORA"
        },
        {
          "id": "3603081006",
          "district_id": "3603081",
          "name": "CIBOGO"
        },
        {
          "id": "3603120004",
          "district_id": "3603120",
          "name": "SUKAASIH"
        },
        {
          "id": "3603120005",
          "district_id": "3603120",
          "name": "PASAR KEMIS"
        },
        {
          "id": "3603120006",
          "district_id": "3603120",
          "name": "SUKAMANTRI"
        },
        {
          "id": "3603120007",
          "district_id": "3603120",
          "name": "KUTA JAYA"
        },
        {
          "id": "3603120008",
          "district_id": "3603120",
          "name": "GELAM JAYA"
        },
        {
          "id": "3603120009",
          "district_id": "3603120",
          "name": "KUTA BARU"
        },
        {
          "id": "3603120010",
          "district_id": "3603120",
          "name": "KUTA BUMI"
        },
        {
          "id": "3603120011",
          "district_id": "3603120",
          "name": "PANGADEGAN"
        },
        {
          "id": "3603120012",
          "district_id": "3603120",
          "name": "SINDANG SARI"
        },
        {
          "id": "3603121001",
          "district_id": "3603121",
          "name": "WANA KERTA"
        },
        {
          "id": "3603121002",
          "district_id": "3603121",
          "name": "SUKA HARJA"
        },
        {
          "id": "3603121003",
          "district_id": "3603121",
          "name": "SINDANG PANON"
        },
        {
          "id": "3603121004",
          "district_id": "3603121",
          "name": "SINDANG JAYA"
        },
        {
          "id": "3603121005",
          "district_id": "3603121",
          "name": "SINDANG ASIH"
        },
        {
          "id": "3603121006",
          "district_id": "3603121",
          "name": "SINDANG SONO"
        },
        {
          "id": "3603121007",
          "district_id": "3603121",
          "name": "BADAK ANOM"
        },
        {
          "id": "3603130001",
          "district_id": "3603130",
          "name": "GEMBONG"
        },
        {
          "id": "3603130002",
          "district_id": "3603130",
          "name": "CANGKUDU"
        },
        {
          "id": "3603130004",
          "district_id": "3603130",
          "name": "SENTUL JAYA"
        },
        {
          "id": "3603130005",
          "district_id": "3603130",
          "name": "TALAGASARI"
        },
        {
          "id": "3603130006",
          "district_id": "3603130",
          "name": "BALA RAJA"
        },
        {
          "id": "3603130007",
          "district_id": "3603130",
          "name": "TOBAT"
        },
        {
          "id": "3603130008",
          "district_id": "3603130",
          "name": "SUKA MURNI"
        },
        {
          "id": "3603130015",
          "district_id": "3603130",
          "name": "SAGA"
        },
        {
          "id": "3603131001",
          "district_id": "3603131",
          "name": "JAYANTI"
        },
        {
          "id": "3603131002",
          "district_id": "3603131",
          "name": "PASIR MUNCANG"
        },
        {
          "id": "3603131003",
          "district_id": "3603131",
          "name": "SUMUR BANDUNG"
        },
        {
          "id": "3603131004",
          "district_id": "3603131",
          "name": "CIKANDE"
        },
        {
          "id": "3603131005",
          "district_id": "3603131",
          "name": "PASIR GINTUNG"
        },
        {
          "id": "3603131006",
          "district_id": "3603131",
          "name": "PANGKAT"
        },
        {
          "id": "3603131007",
          "district_id": "3603131",
          "name": "DANG DEUR"
        },
        {
          "id": "3603131008",
          "district_id": "3603131",
          "name": "PABUARAN"
        },
        {
          "id": "3603132001",
          "district_id": "3603132",
          "name": "KUBANG"
        },
        {
          "id": "3603132002",
          "district_id": "3603132",
          "name": "PARAHU"
        },
        {
          "id": "3603132003",
          "district_id": "3603132",
          "name": "SUKA MULYA"
        },
        {
          "id": "3603132004",
          "district_id": "3603132",
          "name": "KALI ASIN"
        },
        {
          "id": "3603132005",
          "district_id": "3603132",
          "name": "MERAK"
        },
        {
          "id": "3603132006",
          "district_id": "3603132",
          "name": "BUNAR"
        },
        {
          "id": "3603132007",
          "district_id": "3603132",
          "name": "BENDA"
        },
        {
          "id": "3603132008",
          "district_id": "3603132",
          "name": "BUNI AYU"
        },
        {
          "id": "3603140001",
          "district_id": "3603140",
          "name": "KOPER"
        },
        {
          "id": "3603140002",
          "district_id": "3603140",
          "name": "PASIR AMPO"
        },
        {
          "id": "3603140003",
          "district_id": "3603140",
          "name": "PATRA SANA"
        },
        {
          "id": "3603140005",
          "district_id": "3603140",
          "name": "TALOK"
        },
        {
          "id": "3603140006",
          "district_id": "3603140",
          "name": "JENGKOL"
        },
        {
          "id": "3603140007",
          "district_id": "3603140",
          "name": "KEMUNING"
        },
        {
          "id": "3603140008",
          "district_id": "3603140",
          "name": "RANCA ILAT"
        },
        {
          "id": "3603141001",
          "district_id": "3603141",
          "name": "KANDA WATI"
        },
        {
          "id": "3603141002",
          "district_id": "3603141",
          "name": "CIBETOK"
        },
        {
          "id": "3603141003",
          "district_id": "3603141",
          "name": "TAMIANG"
        },
        {
          "id": "3603141004",
          "district_id": "3603141",
          "name": "CIPAEH"
        },
        {
          "id": "3603141006",
          "district_id": "3603141",
          "name": "ONYAM"
        },
        {
          "id": "3603141007",
          "district_id": "3603141",
          "name": "GUNUNG KALER"
        },
        {
          "id": "3603141008",
          "district_id": "3603141",
          "name": "SIDOKO"
        },
        {
          "id": "3603141009",
          "district_id": "3603141",
          "name": "RANCA GEDE"
        },
        {
          "id": "3603150003",
          "district_id": "3603150",
          "name": "BAKUNG"
        },
        {
          "id": "3603150004",
          "district_id": "3603150",
          "name": "PASIR"
        },
        {
          "id": "3603150005",
          "district_id": "3603150",
          "name": "CIRUMPAK"
        },
        {
          "id": "3603150006",
          "district_id": "3603150",
          "name": "PAGEDANGAN UDIK"
        },
        {
          "id": "3603150007",
          "district_id": "3603150",
          "name": "PASILIAN"
        },
        {
          "id": "3603150008",
          "district_id": "3603150",
          "name": "PAGENJAHAN"
        },
        {
          "id": "3603150017",
          "district_id": "3603150",
          "name": "KRONJO"
        },
        {
          "id": "3603150018",
          "district_id": "3603150",
          "name": "PAGEDANGAN ILIR"
        },
        {
          "id": "3603151001",
          "district_id": "3603151",
          "name": "GANDA RIA"
        },
        {
          "id": "3603151002",
          "district_id": "3603151",
          "name": "KOSAMBI DALAM"
        },
        {
          "id": "3603151004",
          "district_id": "3603151",
          "name": "MEKAR BARU"
        },
        {
          "id": "3603151005",
          "district_id": "3603151",
          "name": "WALIWIS"
        },
        {
          "id": "3603151006",
          "district_id": "3603151",
          "name": "CIJERUK"
        },
        {
          "id": "3603151007",
          "district_id": "3603151",
          "name": "KEDAUNG"
        },
        {
          "id": "3603151008",
          "district_id": "3603151",
          "name": "JENGGOT"
        },
        {
          "id": "3603160007",
          "district_id": "3603160",
          "name": "GUNUNG SARI"
        },
        {
          "id": "3603160008",
          "district_id": "3603160",
          "name": "SASAK"
        },
        {
          "id": "3603160009",
          "district_id": "3603160",
          "name": "KEDUNG DALEM"
        },
        {
          "id": "3603160010",
          "district_id": "3603160",
          "name": "TEGAL KUNIR KIDUL"
        },
        {
          "id": "3603160011",
          "district_id": "3603160",
          "name": "JATI WARINGIN"
        },
        {
          "id": "3603160019",
          "district_id": "3603160",
          "name": "TEGAL KUNIR LOR"
        },
        {
          "id": "3603160020",
          "district_id": "3603160",
          "name": "BANYU ASIH"
        },
        {
          "id": "3603160021",
          "district_id": "3603160",
          "name": "MAUK TIMUR"
        },
        {
          "id": "3603160022",
          "district_id": "3603160",
          "name": "MAUK BARAT"
        },
        {
          "id": "3603160023",
          "district_id": "3603160",
          "name": "KETAPANG"
        },
        {
          "id": "3603160024",
          "district_id": "3603160",
          "name": "MARGA MULYA"
        },
        {
          "id": "3603160025",
          "district_id": "3603160",
          "name": "TANJUNG ANOM"
        },
        {
          "id": "3603161001",
          "district_id": "3603161",
          "name": "LEGOK SUKAMAJU"
        },
        {
          "id": "3603161002",
          "district_id": "3603161",
          "name": "RANCA LABUH"
        },
        {
          "id": "3603161003",
          "district_id": "3603161",
          "name": "KEMIRI"
        },
        {
          "id": "3603161005",
          "district_id": "3603161",
          "name": "PATRA MANGGALA"
        },
        {
          "id": "3603161006",
          "district_id": "3603161",
          "name": "KARANG ANYAR"
        },
        {
          "id": "3603161007",
          "district_id": "3603161",
          "name": "LONTAR"
        },
        {
          "id": "3603162001",
          "district_id": "3603162",
          "name": "BUARAN JATI"
        },
        {
          "id": "3603162002",
          "district_id": "3603162",
          "name": "GINTUNG"
        },
        {
          "id": "3603162003",
          "district_id": "3603162",
          "name": "KOSAMBI"
        },
        {
          "id": "3603162004",
          "district_id": "3603162",
          "name": "MEKAR KONDANG"
        },
        {
          "id": "3603162005",
          "district_id": "3603162",
          "name": "PEKAYON"
        },
        {
          "id": "3603162006",
          "district_id": "3603162",
          "name": "SUKADIRI"
        },
        {
          "id": "3603162007",
          "district_id": "3603162",
          "name": "RAWA KIDANG"
        },
        {
          "id": "3603162008",
          "district_id": "3603162",
          "name": "KARANG SERANG"
        },
        {
          "id": "3603170002",
          "district_id": "3603170",
          "name": "JAMBU KARYA"
        },
        {
          "id": "3603170003",
          "district_id": "3603170",
          "name": "DAON"
        },
        {
          "id": "3603170004",
          "district_id": "3603170",
          "name": "SUKA TANI"
        },
        {
          "id": "3603170005",
          "district_id": "3603170",
          "name": "MEKARSARI"
        },
        {
          "id": "3603170006",
          "district_id": "3603170",
          "name": "SUKA SARI"
        },
        {
          "id": "3603170007",
          "district_id": "3603170",
          "name": "RAJEGMULYA"
        },
        {
          "id": "3603170008",
          "district_id": "3603170",
          "name": "RAJEG"
        },
        {
          "id": "3603170009",
          "district_id": "3603170",
          "name": "SUKA MANAH"
        },
        {
          "id": "3603170010",
          "district_id": "3603170",
          "name": "PANGARENGAN"
        },
        {
          "id": "3603170011",
          "district_id": "3603170",
          "name": "RANCA BANGO"
        },
        {
          "id": "3603170012",
          "district_id": "3603170",
          "name": "LEMBANG SARI"
        },
        {
          "id": "3603170013",
          "district_id": "3603170",
          "name": "TANJAKAN"
        },
        {
          "id": "3603170014",
          "district_id": "3603170",
          "name": "TANJAKAN MEKAR"
        },
        {
          "id": "3603180001",
          "district_id": "3603180",
          "name": "MEKAR JAYA"
        },
        {
          "id": "3603180002",
          "district_id": "3603180",
          "name": "KARET"
        },
        {
          "id": "3603180007",
          "district_id": "3603180",
          "name": "PONDOK JAYA"
        },
        {
          "id": "3603180008",
          "district_id": "3603180",
          "name": "SEPATAN"
        },
        {
          "id": "3603180009",
          "district_id": "3603180",
          "name": "PISANGAN JAYA"
        },
        {
          "id": "3603180010",
          "district_id": "3603180",
          "name": "SARAKAN"
        },
        {
          "id": "3603180011",
          "district_id": "3603180",
          "name": "KAYU BONGKOK"
        },
        {
          "id": "3603180012",
          "district_id": "3603180",
          "name": "KAYU AGUNG"
        },
        {
          "id": "3603181001",
          "district_id": "3603181",
          "name": "LEBAK WANGI"
        },
        {
          "id": "3603181002",
          "district_id": "3603181",
          "name": "KEDAUNG BARAT"
        },
        {
          "id": "3603181003",
          "district_id": "3603181",
          "name": "JATI MULYA"
        },
        {
          "id": "3603181004",
          "district_id": "3603181",
          "name": "TANAH MERAH"
        },
        {
          "id": "3603181005",
          "district_id": "3603181",
          "name": "SANGIANG"
        },
        {
          "id": "3603181006",
          "district_id": "3603181",
          "name": "GEMPOL SARI"
        },
        {
          "id": "3603181007",
          "district_id": "3603181",
          "name": "PONDOK KELOR"
        },
        {
          "id": "3603181008",
          "district_id": "3603181",
          "name": "KAMPUNG KELOR"
        },
        {
          "id": "3603190001",
          "district_id": "3603190",
          "name": "BUNISARI"
        },
        {
          "id": "3603190002",
          "district_id": "3603190",
          "name": "RAWA BONI"
        },
        {
          "id": "3603190003",
          "district_id": "3603190",
          "name": "KIARA PAYUNG"
        },
        {
          "id": "3603190004",
          "district_id": "3603190",
          "name": "GAGA"
        },
        {
          "id": "3603190005",
          "district_id": "3603190",
          "name": "LAKSANA"
        },
        {
          "id": "3603190006",
          "district_id": "3603190",
          "name": "BUARAN BAMBU"
        },
        {
          "id": "3603190007",
          "district_id": "3603190",
          "name": "PAKU HAJI"
        },
        {
          "id": "3603190008",
          "district_id": "3603190",
          "name": "PAKU ALAM"
        },
        {
          "id": "3603190009",
          "district_id": "3603190",
          "name": "BUARAN MANGGA"
        },
        {
          "id": "3603190010",
          "district_id": "3603190",
          "name": "SURYA BAHARI"
        },
        {
          "id": "3603190011",
          "district_id": "3603190",
          "name": "SUKAWALI"
        },
        {
          "id": "3603190012",
          "district_id": "3603190",
          "name": "KRAMAT"
        },
        {
          "id": "3603190013",
          "district_id": "3603190",
          "name": "KALIBARU"
        },
        {
          "id": "3603190014",
          "district_id": "3603190",
          "name": "KOHOD"
        },
        {
          "id": "3603200001",
          "district_id": "3603200",
          "name": "BOJONG RENGED"
        },
        {
          "id": "3603200002",
          "district_id": "3603200",
          "name": "KEBON CAU"
        },
        {
          "id": "3603200003",
          "district_id": "3603200",
          "name": "TELUK NAGA"
        },
        {
          "id": "3603200004",
          "district_id": "3603200",
          "name": "BABAKAN ASEM"
        },
        {
          "id": "3603200005",
          "district_id": "3603200",
          "name": "KAMPUNG MELAYU TIMUR"
        },
        {
          "id": "3603200006",
          "district_id": "3603200",
          "name": "KAMPUNG MELAYU BARAT"
        },
        {
          "id": "3603200007",
          "district_id": "3603200",
          "name": "KAMPUNG BESAR"
        },
        {
          "id": "3603200008",
          "district_id": "3603200",
          "name": "L E M O"
        },
        {
          "id": "3603200009",
          "district_id": "3603200",
          "name": "TEGAL ANGUS"
        },
        {
          "id": "3603200010",
          "district_id": "3603200",
          "name": "PANGKALAN"
        },
        {
          "id": "3603200011",
          "district_id": "3603200",
          "name": "TANJUNG BURUNG"
        },
        {
          "id": "3603200012",
          "district_id": "3603200",
          "name": "TANJUNG PASIR"
        },
        {
          "id": "3603200013",
          "district_id": "3603200",
          "name": "MUARA"
        },
        {
          "id": "3603210001",
          "district_id": "3603210",
          "name": "RAWA RENGAS"
        },
        {
          "id": "3603210002",
          "district_id": "3603210",
          "name": "RAWA BURUNG"
        },
        {
          "id": "3603210003",
          "district_id": "3603210",
          "name": "BELIMBING"
        },
        {
          "id": "3603210004",
          "district_id": "3603210",
          "name": "JATIMULYA"
        },
        {
          "id": "3603210005",
          "district_id": "3603210",
          "name": "D A D A P"
        },
        {
          "id": "3603210006",
          "district_id": "3603210",
          "name": "KOSAMBI TIMUR"
        },
        {
          "id": "3603210007",
          "district_id": "3603210",
          "name": "KOSAMBI BARAT"
        },
        {
          "id": "3603210008",
          "district_id": "3603210",
          "name": "CENGKLONG"
        },
        {
          "id": "3603210009",
          "district_id": "3603210",
          "name": "SALEMBARAN JATI"
        },
        {
          "id": "3603210010",
          "district_id": "3603210",
          "name": "SALEMBARAN"
        },
        {
          "id": "3604010001",
          "district_id": "3604010",
          "name": "UMBUL TANJUNG"
        },
        {
          "id": "3604010002",
          "district_id": "3604010",
          "name": "PASAURAN"
        },
        {
          "id": "3604010003",
          "district_id": "3604010",
          "name": "BANTARWANGI"
        },
        {
          "id": "3604010004",
          "district_id": "3604010",
          "name": "BANTARWARU"
        },
        {
          "id": "3604010005",
          "district_id": "3604010",
          "name": "BULAKAN"
        },
        {
          "id": "3604010006",
          "district_id": "3604010",
          "name": "KARANG SURAGA"
        },
        {
          "id": "3604010007",
          "district_id": "3604010",
          "name": "CINANGKA"
        },
        {
          "id": "3604010008",
          "district_id": "3604010",
          "name": "KUBANG BAROS"
        },
        {
          "id": "3604010009",
          "district_id": "3604010",
          "name": "RANCASANGGAL"
        },
        {
          "id": "3604010010",
          "district_id": "3604010",
          "name": "CIKOLELET"
        },
        {
          "id": "3604010011",
          "district_id": "3604010",
          "name": "MEKARSARI"
        },
        {
          "id": "3604010012",
          "district_id": "3604010",
          "name": "SINDANGLAYA"
        },
        {
          "id": "3604010013",
          "district_id": "3604010",
          "name": "KAMASAN"
        },
        {
          "id": "3604010014",
          "district_id": "3604010",
          "name": "BAROS JAYA"
        },
        {
          "id": "3604020001",
          "district_id": "3604020",
          "name": "CIBOJONG"
        },
        {
          "id": "3604020002",
          "district_id": "3604020",
          "name": "KRAMATLABAN"
        },
        {
          "id": "3604020003",
          "district_id": "3604020",
          "name": "KADUBEUREUM"
        },
        {
          "id": "3604020004",
          "district_id": "3604020",
          "name": "PADARINCANG"
        },
        {
          "id": "3604020006",
          "district_id": "3604020",
          "name": "KALUMPANG"
        },
        {
          "id": "3604020007",
          "district_id": "3604020",
          "name": "CITASUK"
        },
        {
          "id": "3604020008",
          "district_id": "3604020",
          "name": "BATUKUWUNG"
        },
        {
          "id": "3604020009",
          "district_id": "3604020",
          "name": "CURUG GOONG"
        },
        {
          "id": "3604020010",
          "district_id": "3604020",
          "name": "CISAAT"
        },
        {
          "id": "3604020011",
          "district_id": "3604020",
          "name": "CIPAYUNG"
        },
        {
          "id": "3604020012",
          "district_id": "3604020",
          "name": "CIOMAS"
        },
        {
          "id": "3604020013",
          "district_id": "3604020",
          "name": "BARUGBUG"
        },
        {
          "id": "3604020014",
          "district_id": "3604020",
          "name": "KADUKEMPONG"
        },
        {
          "id": "3604030002",
          "district_id": "3604030",
          "name": "CISITU"
        },
        {
          "id": "3604030003",
          "district_id": "3604030",
          "name": "SIKETUG"
        },
        {
          "id": "3604030004",
          "district_id": "3604030",
          "name": "LEBAK"
        },
        {
          "id": "3604030005",
          "district_id": "3604030",
          "name": "CITAMAN"
        },
        {
          "id": "3604030006",
          "district_id": "3604030",
          "name": "PONDOK KAHURU"
        },
        {
          "id": "3604030007",
          "district_id": "3604030",
          "name": "SUKADANA"
        },
        {
          "id": "3604030008",
          "district_id": "3604030",
          "name": "SUKABARES"
        },
        {
          "id": "3604030009",
          "district_id": "3604030",
          "name": "SUKARENA"
        },
        {
          "id": "3604030010",
          "district_id": "3604030",
          "name": "CEMPLANG"
        },
        {
          "id": "3604030011",
          "district_id": "3604030",
          "name": "PANYAUNGAN JAYA"
        },
        {
          "id": "3604040001",
          "district_id": "3604040",
          "name": "TANJUNGSARI"
        },
        {
          "id": "3604040002",
          "district_id": "3604040",
          "name": "KADUBEUREUM"
        },
        {
          "id": "3604040003",
          "district_id": "3604040",
          "name": "PASANGGRAHAN"
        },
        {
          "id": "3604040004",
          "district_id": "3604040",
          "name": "PABUARAN"
        },
        {
          "id": "3604040005",
          "district_id": "3604040",
          "name": "PANCANEGARA"
        },
        {
          "id": "3604040006",
          "district_id": "3604040",
          "name": "SINDANGHEULA"
        },
        {
          "id": "3604040007",
          "district_id": "3604040",
          "name": "SINDANGSARI"
        },
        {
          "id": "3604040008",
          "district_id": "3604040",
          "name": "TALAGA WARNA"
        },
        {
          "id": "3604041001",
          "district_id": "3604041",
          "name": "CIHERANG"
        },
        {
          "id": "3604041002",
          "district_id": "3604041",
          "name": "GUNUNGSARI"
        },
        {
          "id": "3604041003",
          "district_id": "3604041",
          "name": "TAMIANG"
        },
        {
          "id": "3604041004",
          "district_id": "3604041",
          "name": "SUKALABA"
        },
        {
          "id": "3604041005",
          "district_id": "3604041",
          "name": "KADU AGUNG"
        },
        {
          "id": "3604041007",
          "district_id": "3604041",
          "name": "CURUG SULANJANA"
        },
        {
          "id": "3604050001",
          "district_id": "3604050",
          "name": "SUKACAI"
        },
        {
          "id": "3604050002",
          "district_id": "3604050",
          "name": "SUKAMENAK"
        },
        {
          "id": "3604050003",
          "district_id": "3604050",
          "name": "TEJAMARI"
        },
        {
          "id": "3604050004",
          "district_id": "3604050",
          "name": "PANYIRAPAN"
        },
        {
          "id": "3604050005",
          "district_id": "3604050",
          "name": "TAMANSARI"
        },
        {
          "id": "3604050006",
          "district_id": "3604050",
          "name": "SINDANGMANDI"
        },
        {
          "id": "3604050007",
          "district_id": "3604050",
          "name": "CURUG AGUNG"
        },
        {
          "id": "3604050008",
          "district_id": "3604050",
          "name": "SUKAMANAH"
        },
        {
          "id": "3604050009",
          "district_id": "3604050",
          "name": "PADASUKA"
        },
        {
          "id": "3604050010",
          "district_id": "3604050",
          "name": "SINARMUKTI"
        },
        {
          "id": "3604050011",
          "district_id": "3604050",
          "name": "SIDAMUKTI"
        },
        {
          "id": "3604050012",
          "district_id": "3604050",
          "name": "BAROS"
        },
        {
          "id": "3604050013",
          "district_id": "3604050",
          "name": "CISALAM"
        },
        {
          "id": "3604050014",
          "district_id": "3604050",
          "name": "SUKA INDAH"
        },
        {
          "id": "3604060009",
          "district_id": "3604060",
          "name": "KADUGENEP"
        },
        {
          "id": "3604060010",
          "district_id": "3604060",
          "name": "PADASUKA"
        },
        {
          "id": "3604060011",
          "district_id": "3604060",
          "name": "SANDING"
        },
        {
          "id": "3604060012",
          "district_id": "3604060",
          "name": "SINDANGSARI"
        },
        {
          "id": "3604060013",
          "district_id": "3604060",
          "name": "CIREUNDEU"
        },
        {
          "id": "3604060014",
          "district_id": "3604060",
          "name": "CIRANGKONG"
        },
        {
          "id": "3604060015",
          "district_id": "3604060",
          "name": "TAMBILUK"
        },
        {
          "id": "3604060016",
          "district_id": "3604060",
          "name": "MEKARBARU"
        },
        {
          "id": "3604060017",
          "district_id": "3604060",
          "name": "PETIR"
        },
        {
          "id": "3604060018",
          "district_id": "3604060",
          "name": "NAGARA PADANG"
        },
        {
          "id": "3604060019",
          "district_id": "3604060",
          "name": "KAMPUNG BARU"
        },
        {
          "id": "3604060020",
          "district_id": "3604060",
          "name": "SEUAT"
        },
        {
          "id": "3604060021",
          "district_id": "3604060",
          "name": "SEUAT JAYA"
        },
        {
          "id": "3604060022",
          "district_id": "3604060",
          "name": "KUBANG JAYA"
        },
        {
          "id": "3604060023",
          "district_id": "3604060",
          "name": "BOJONG NANGKA"
        },
        {
          "id": "3604061001",
          "district_id": "3604061",
          "name": "PANUNGGULAN"
        },
        {
          "id": "3604061002",
          "district_id": "3604061",
          "name": "SUKASARI"
        },
        {
          "id": "3604061003",
          "district_id": "3604061",
          "name": "BOJONG MENTENG"
        },
        {
          "id": "3604061004",
          "district_id": "3604061",
          "name": "KAMUNING"
        },
        {
          "id": "3604061005",
          "district_id": "3604061",
          "name": "BOJONG PANDAN"
        },
        {
          "id": "3604061006",
          "district_id": "3604061",
          "name": "BOJONG CATANG"
        },
        {
          "id": "3604061007",
          "district_id": "3604061",
          "name": "MALANGGAH"
        },
        {
          "id": "3604061008",
          "district_id": "3604061",
          "name": "TUNJUNG JAYA"
        },
        {
          "id": "3604061009",
          "district_id": "3604061",
          "name": "PANCAREGANG"
        },
        {
          "id": "3604080001",
          "district_id": "3604080",
          "name": "PANYABRANGAN"
        },
        {
          "id": "3604080002",
          "district_id": "3604080",
          "name": "DAHU"
        },
        {
          "id": "3604080003",
          "district_id": "3604080",
          "name": "BANTAR PANJANG"
        },
        {
          "id": "3604080004",
          "district_id": "3604080",
          "name": "KATULISAN"
        },
        {
          "id": "3604080005",
          "district_id": "3604080",
          "name": "PANOSOGAN"
        },
        {
          "id": "3604080006",
          "district_id": "3604080",
          "name": "CIKEUSAL"
        },
        {
          "id": "3604080007",
          "district_id": "3604080",
          "name": "SUKAMAJU"
        },
        {
          "id": "3604080008",
          "district_id": "3604080",
          "name": "HARUNDANG"
        },
        {
          "id": "3604080009",
          "district_id": "3604080",
          "name": "GANDAYASA"
        },
        {
          "id": "3604080010",
          "district_id": "3604080",
          "name": "MONGPOK"
        },
        {
          "id": "3604080011",
          "district_id": "3604080",
          "name": "SUKARAME"
        },
        {
          "id": "3604080012",
          "district_id": "3604080",
          "name": "CILAYANG"
        },
        {
          "id": "3604080013",
          "district_id": "3604080",
          "name": "SUKARATU"
        },
        {
          "id": "3604080014",
          "district_id": "3604080",
          "name": "SUKAMENAK"
        },
        {
          "id": "3604080015",
          "district_id": "3604080",
          "name": "CIMAUNG"
        },
        {
          "id": "3604080016",
          "district_id": "3604080",
          "name": "SUKARAJA"
        },
        {
          "id": "3604080017",
          "district_id": "3604080",
          "name": "CILAYANG GUHA"
        },
        {
          "id": "3604090001",
          "district_id": "3604090",
          "name": "WIRANA"
        },
        {
          "id": "3604090002",
          "district_id": "3604090",
          "name": "SANGIANG"
        },
        {
          "id": "3604090003",
          "district_id": "3604090",
          "name": "DAMPING"
        },
        {
          "id": "3604090004",
          "district_id": "3604090",
          "name": "KEBON CAU"
        },
        {
          "id": "3604090005",
          "district_id": "3604090",
          "name": "PUDAR"
        },
        {
          "id": "3604090006",
          "district_id": "3604090",
          "name": "BINONG"
        },
        {
          "id": "3604090007",
          "district_id": "3604090",
          "name": "PAMARAYAN"
        },
        {
          "id": "3604090008",
          "district_id": "3604090",
          "name": "KAMPUNG BARU"
        },
        {
          "id": "3604090010",
          "district_id": "3604090",
          "name": "PASIRLIMUS"
        },
        {
          "id": "3604090011",
          "district_id": "3604090",
          "name": "PASIR KEMBANG"
        },
        {
          "id": "3604091001",
          "district_id": "3604091",
          "name": "PANGAWINAN"
        },
        {
          "id": "3604091002",
          "district_id": "3604091",
          "name": "MANDER"
        },
        {
          "id": "3604091003",
          "district_id": "3604091",
          "name": "PANAMPING"
        },
        {
          "id": "3604091004",
          "district_id": "3604091",
          "name": "BANDUNG"
        },
        {
          "id": "3604091005",
          "district_id": "3604091",
          "name": "MALABAR"
        },
        {
          "id": "3604091006",
          "district_id": "3604091",
          "name": "BLOKANG"
        },
        {
          "id": "3604091007",
          "district_id": "3604091",
          "name": "BABAKAN"
        },
        {
          "id": "3604091008",
          "district_id": "3604091",
          "name": "PRINGWULUNG"
        },
        {
          "id": "3604100001",
          "district_id": "3604100",
          "name": "PAGINTUNGAN"
        },
        {
          "id": "3604100002",
          "district_id": "3604100",
          "name": "CEMPLANG"
        },
        {
          "id": "3604100003",
          "district_id": "3604100",
          "name": "BOJOT"
        },
        {
          "id": "3604100004",
          "district_id": "3604100",
          "name": "JAWILAN"
        },
        {
          "id": "3604100005",
          "district_id": "3604100",
          "name": "PASIRBUYUT"
        },
        {
          "id": "3604100006",
          "district_id": "3604100",
          "name": "MAJASARI"
        },
        {
          "id": "3604100007",
          "district_id": "3604100",
          "name": "PARAKAN"
        },
        {
          "id": "3604100008",
          "district_id": "3604100",
          "name": "KAREO"
        },
        {
          "id": "3604100009",
          "district_id": "3604100",
          "name": "JUNTI"
        },
        {
          "id": "3604110001",
          "district_id": "3604110",
          "name": "NANGGUNG"
        },
        {
          "id": "3604110002",
          "district_id": "3604110",
          "name": "KOPO"
        },
        {
          "id": "3604110003",
          "district_id": "3604110",
          "name": "MEKARBARU"
        },
        {
          "id": "3604110004",
          "district_id": "3604110",
          "name": "GARUT"
        },
        {
          "id": "3604110005",
          "district_id": "3604110",
          "name": "RANCASUMUR"
        },
        {
          "id": "3604110006",
          "district_id": "3604110",
          "name": "CIDAHU"
        },
        {
          "id": "3604110007",
          "district_id": "3604110",
          "name": "NYOMPOK"
        },
        {
          "id": "3604110008",
          "district_id": "3604110",
          "name": "CARENANG UDIK"
        },
        {
          "id": "3604110009",
          "district_id": "3604110",
          "name": "BABAKAN JAYA"
        },
        {
          "id": "3604110010",
          "district_id": "3604110",
          "name": "GABUS"
        },
        {
          "id": "3604120001",
          "district_id": "3604120",
          "name": "NAMBO UDIK"
        },
        {
          "id": "3604120002",
          "district_id": "3604120",
          "name": "SITUTERATE"
        },
        {
          "id": "3604120003",
          "district_id": "3604120",
          "name": "CIKANDE"
        },
        {
          "id": "3604120004",
          "district_id": "3604120",
          "name": "LEUWILIMUS"
        },
        {
          "id": "3604120005",
          "district_id": "3604120",
          "name": "PARIGI"
        },
        {
          "id": "3604120006",
          "district_id": "3604120",
          "name": "SONGGOM JAYA"
        },
        {
          "id": "3604120007",
          "district_id": "3604120",
          "name": "KOPER"
        },
        {
          "id": "3604120008",
          "district_id": "3604120",
          "name": "KAMURANG"
        },
        {
          "id": "3604120009",
          "district_id": "3604120",
          "name": "BAKUNG"
        },
        {
          "id": "3604120010",
          "district_id": "3604120",
          "name": "GEMBOR UDIK"
        },
        {
          "id": "3604120011",
          "district_id": "3604120",
          "name": "JULANG"
        },
        {
          "id": "3604120013",
          "district_id": "3604120",
          "name": "SUKATANI"
        },
        {
          "id": "3604120014",
          "district_id": "3604120",
          "name": "CIKANDE PERMAI"
        },
        {
          "id": "3604121001",
          "district_id": "3604121",
          "name": "NAGARA"
        },
        {
          "id": "3604121002",
          "district_id": "3604121",
          "name": "CIJERUK"
        },
        {
          "id": "3604121003",
          "district_id": "3604121",
          "name": "BARENGKOK"
        },
        {
          "id": "3604121004",
          "district_id": "3604121",
          "name": "NAMBO ILIR"
        },
        {
          "id": "3604121005",
          "district_id": "3604121",
          "name": "KIBIN"
        },
        {
          "id": "3604121006",
          "district_id": "3604121",
          "name": "TAMBAK"
        },
        {
          "id": "3604121007",
          "district_id": "3604121",
          "name": "CIAGEL"
        },
        {
          "id": "3604121008",
          "district_id": "3604121",
          "name": "KETOS"
        },
        {
          "id": "3604121009",
          "district_id": "3604121",
          "name": "SUKAMAJU"
        },
        {
          "id": "3604130001",
          "district_id": "3604130",
          "name": "SILEBU"
        },
        {
          "id": "3604130002",
          "district_id": "3604130",
          "name": "SUKAJADI"
        },
        {
          "id": "3604130003",
          "district_id": "3604130",
          "name": "PEMATANG"
        },
        {
          "id": "3604130004",
          "district_id": "3604130",
          "name": "KRAMATJATI"
        },
        {
          "id": "3604130006",
          "district_id": "3604130",
          "name": "UNDAR ANDIR"
        },
        {
          "id": "3604130007",
          "district_id": "3604130",
          "name": "KENDAYAKAN"
        },
        {
          "id": "3604130008",
          "district_id": "3604130",
          "name": "CISAIT"
        },
        {
          "id": "3604130010",
          "district_id": "3604130",
          "name": "KRAGILAN"
        },
        {
          "id": "3604130011",
          "district_id": "3604130",
          "name": "TEGALMAJA"
        },
        {
          "id": "3604130012",
          "district_id": "3604130",
          "name": "JERUKTIPIS"
        },
        {
          "id": "3604180001",
          "district_id": "3604180",
          "name": "SASAHAN"
        },
        {
          "id": "3604180002",
          "district_id": "3604180",
          "name": "COKOPSULANJANA"
        },
        {
          "id": "3604180003",
          "district_id": "3604180",
          "name": "TELAGA LUHUR"
        },
        {
          "id": "3604180004",
          "district_id": "3604180",
          "name": "BINANGUN"
        },
        {
          "id": "3604180005",
          "district_id": "3604180",
          "name": "KEMUNING"
        },
        {
          "id": "3604180006",
          "district_id": "3604180",
          "name": "SUKABARES"
        },
        {
          "id": "3604180007",
          "district_id": "3604180",
          "name": "SAMBILAWANG"
        },
        {
          "id": "3604180008",
          "district_id": "3604180",
          "name": "MELATI"
        },
        {
          "id": "3604180009",
          "district_id": "3604180",
          "name": "SAMPIR"
        },
        {
          "id": "3604180010",
          "district_id": "3604180",
          "name": "WARINGINKURUNG"
        },
        {
          "id": "3604180011",
          "district_id": "3604180",
          "name": "SUKADALEM"
        },
        {
          "id": "3604190001",
          "district_id": "3604190",
          "name": "CIKEDUNG"
        },
        {
          "id": "3604190002",
          "district_id": "3604190",
          "name": "CIWARNA"
        },
        {
          "id": "3604190003",
          "district_id": "3604190",
          "name": "ANGSANA"
        },
        {
          "id": "3604190004",
          "district_id": "3604190",
          "name": "TALAGA"
        },
        {
          "id": "3604190005",
          "district_id": "3604190",
          "name": "BALEKAMBANG"
        },
        {
          "id": "3604190006",
          "district_id": "3604190",
          "name": "LABUHAN"
        },
        {
          "id": "3604190007",
          "district_id": "3604190",
          "name": "SANGIANG"
        },
        {
          "id": "3604190008",
          "district_id": "3604190",
          "name": "PASIRWARU"
        },
        {
          "id": "3604190009",
          "district_id": "3604190",
          "name": "WARINGIN"
        },
        {
          "id": "3604190010",
          "district_id": "3604190",
          "name": "MANCAK"
        },
        {
          "id": "3604190011",
          "district_id": "3604190",
          "name": "SIGEDONG"
        },
        {
          "id": "3604190012",
          "district_id": "3604190",
          "name": "BATUKUDA"
        },
        {
          "id": "3604190013",
          "district_id": "3604190",
          "name": "WINONG"
        },
        {
          "id": "3604190014",
          "district_id": "3604190",
          "name": "BALE KENCANA"
        },
        {
          "id": "3604200001",
          "district_id": "3604200",
          "name": "BANDULU"
        },
        {
          "id": "3604200002",
          "district_id": "3604200",
          "name": "SINDANGMANDI"
        },
        {
          "id": "3604200003",
          "district_id": "3604200",
          "name": "BANJARSARI"
        },
        {
          "id": "3604200004",
          "district_id": "3604200",
          "name": "BUNIHARA"
        },
        {
          "id": "3604200005",
          "district_id": "3604200",
          "name": "TANJUNGMANIS"
        },
        {
          "id": "3604200006",
          "district_id": "3604200",
          "name": "CIKONENG"
        },
        {
          "id": "3604200007",
          "district_id": "3604200",
          "name": "ANYAR"
        },
        {
          "id": "3604200008",
          "district_id": "3604200",
          "name": "KOSAMBI RONYOK"
        },
        {
          "id": "3604200009",
          "district_id": "3604200",
          "name": "SINDANGKARYA"
        },
        {
          "id": "3604200010",
          "district_id": "3604200",
          "name": "MEKARSARI"
        },
        {
          "id": "3604200011",
          "district_id": "3604200",
          "name": "TAMBANG AYAM"
        },
        {
          "id": "3604200012",
          "district_id": "3604200",
          "name": "GROGOL INDAH"
        },
        {
          "id": "3604210001",
          "district_id": "3604210",
          "name": "WANAKARTA"
        },
        {
          "id": "3604210002",
          "district_id": "3604210",
          "name": "KERTASANA"
        },
        {
          "id": "3604210003",
          "district_id": "3604210",
          "name": "MANGKUNEGARA"
        },
        {
          "id": "3604210004",
          "district_id": "3604210",
          "name": "KARANGKEPUH"
        },
        {
          "id": "3604210005",
          "district_id": "3604210",
          "name": "LAMBANGSARI"
        },
        {
          "id": "3604210006",
          "district_id": "3604210",
          "name": "BOJONEGARA"
        },
        {
          "id": "3604210007",
          "district_id": "3604210",
          "name": "MARGAGIRI"
        },
        {
          "id": "3604210008",
          "district_id": "3604210",
          "name": "UKIRSARI"
        },
        {
          "id": "3604210009",
          "district_id": "3604210",
          "name": "PAKUNCEN"
        },
        {
          "id": "3604210010",
          "district_id": "3604210",
          "name": "PENGARENGAN"
        },
        {
          "id": "3604210011",
          "district_id": "3604210",
          "name": "MEKAR  JAYA"
        },
        {
          "id": "3604211001",
          "district_id": "3604211",
          "name": "ARGAWANA"
        },
        {
          "id": "3604211002",
          "district_id": "3604211",
          "name": "BANYUWANGI"
        },
        {
          "id": "3604211003",
          "district_id": "3604211",
          "name": "MARGASARI"
        },
        {
          "id": "3604211004",
          "district_id": "3604211",
          "name": "PULOAMPEL"
        },
        {
          "id": "3604211005",
          "district_id": "3604211",
          "name": "SUMURANJA"
        },
        {
          "id": "3604211006",
          "district_id": "3604211",
          "name": "KEDUNG SOKA"
        },
        {
          "id": "3604211007",
          "district_id": "3604211",
          "name": "MANGUNREJA"
        },
        {
          "id": "3604211008",
          "district_id": "3604211",
          "name": "SALIRA"
        },
        {
          "id": "3604211009",
          "district_id": "3604211",
          "name": "PULO PANJANG"
        },
        {
          "id": "3604220001",
          "district_id": "3604220",
          "name": "LEBAKWANA"
        },
        {
          "id": "3604220002",
          "district_id": "3604220",
          "name": "PELAMUNAN"
        },
        {
          "id": "3604220003",
          "district_id": "3604220",
          "name": "MARGASANA"
        },
        {
          "id": "3604220004",
          "district_id": "3604220",
          "name": "KRAMATWATU"
        },
        {
          "id": "3604220005",
          "district_id": "3604220",
          "name": "PEJATEN"
        },
        {
          "id": "3604220006",
          "district_id": "3604220",
          "name": "WANAYASA"
        },
        {
          "id": "3604220007",
          "district_id": "3604220",
          "name": "HARJATANI"
        },
        {
          "id": "3604220008",
          "district_id": "3604220",
          "name": "SERDANG"
        },
        {
          "id": "3604220009",
          "district_id": "3604220",
          "name": "TOYOMERTO"
        },
        {
          "id": "3604220010",
          "district_id": "3604220",
          "name": "PEGADINGAN"
        },
        {
          "id": "3604220011",
          "district_id": "3604220",
          "name": "PAMENGKANG"
        },
        {
          "id": "3604220012",
          "district_id": "3604220",
          "name": "TONJONG"
        },
        {
          "id": "3604220013",
          "district_id": "3604220",
          "name": "TERATE"
        },
        {
          "id": "3604220014",
          "district_id": "3604220",
          "name": "TELUK TERATE"
        },
        {
          "id": "3604220015",
          "district_id": "3604220",
          "name": "MARGATANI"
        },
        {
          "id": "3604240001",
          "district_id": "3604240",
          "name": "CITEREP"
        },
        {
          "id": "3604240002",
          "district_id": "3604240",
          "name": "RANJENG"
        },
        {
          "id": "3604240003",
          "district_id": "3604240",
          "name": "CIRUAS"
        },
        {
          "id": "3604240004",
          "district_id": "3604240",
          "name": "KADIKARAN"
        },
        {
          "id": "3604240005",
          "district_id": "3604240",
          "name": "SINGAMERTA"
        },
        {
          "id": "3604240006",
          "district_id": "3604240",
          "name": "PULO"
        },
        {
          "id": "3604240008",
          "district_id": "3604240",
          "name": "GOSARA"
        },
        {
          "id": "3604240009",
          "district_id": "3604240",
          "name": "KEPANDEAN"
        },
        {
          "id": "3604240010",
          "district_id": "3604240",
          "name": "PAMONG"
        },
        {
          "id": "3604240011",
          "district_id": "3604240",
          "name": "CIGELAM"
        },
        {
          "id": "3604240012",
          "district_id": "3604240",
          "name": "PENGGALANG"
        },
        {
          "id": "3604240013",
          "district_id": "3604240",
          "name": "BUMIJAYA"
        },
        {
          "id": "3604240015",
          "district_id": "3604240",
          "name": "KESERANGAN"
        },
        {
          "id": "3604240016",
          "district_id": "3604240",
          "name": "BEBERAN"
        },
        {
          "id": "3604240017",
          "district_id": "3604240",
          "name": "PELAWAD"
        },
        {
          "id": "3604250001",
          "district_id": "3604250",
          "name": "SUKAJAYA"
        },
        {
          "id": "3604250002",
          "district_id": "3604250",
          "name": "SUKANEGARA"
        },
        {
          "id": "3604250007",
          "district_id": "3604250",
          "name": "KALAPIAN"
        },
        {
          "id": "3604250008",
          "district_id": "3604250",
          "name": "KESERANGAN"
        },
        {
          "id": "3604250009",
          "district_id": "3604250",
          "name": "PULO KENCANA"
        },
        {
          "id": "3604250010",
          "district_id": "3604250",
          "name": "LINDUK"
        },
        {
          "id": "3604250011",
          "district_id": "3604250",
          "name": "KUBANG PUJI"
        },
        {
          "id": "3604250012",
          "district_id": "3604250",
          "name": "SINGARAJAN"
        },
        {
          "id": "3604250013",
          "district_id": "3604250",
          "name": "PONTANG"
        },
        {
          "id": "3604250014",
          "district_id": "3604250",
          "name": "WANAYASA"
        },
        {
          "id": "3604250015",
          "district_id": "3604250",
          "name": "DOMAS"
        },
        {
          "id": "3604251001",
          "district_id": "3604251",
          "name": "KEBONRATU"
        },
        {
          "id": "3604251002",
          "district_id": "3604251",
          "name": "TERASBENDUNG"
        },
        {
          "id": "3604251003",
          "district_id": "3604251",
          "name": "KAMARUTON"
        },
        {
          "id": "3604251004",
          "district_id": "3604251",
          "name": "PURWADADI"
        },
        {
          "id": "3604251005",
          "district_id": "3604251",
          "name": "LEBAKWANGI"
        },
        {
          "id": "3604251006",
          "district_id": "3604251",
          "name": "TIREM"
        },
        {
          "id": "3604251007",
          "district_id": "3604251",
          "name": "LEBAK KEPUH"
        },
        {
          "id": "3604251008",
          "district_id": "3604251",
          "name": "KENCANA HARAPAN"
        },
        {
          "id": "3604251009",
          "district_id": "3604251",
          "name": "BOLANG"
        },
        {
          "id": "3604251010",
          "district_id": "3604251",
          "name": "PEGANDIKAN"
        },
        {
          "id": "3604260008",
          "district_id": "3604260",
          "name": "MANDAYA"
        },
        {
          "id": "3604260009",
          "district_id": "3604260",
          "name": "TERAS"
        },
        {
          "id": "3604260010",
          "district_id": "3604260",
          "name": "WALIKUKUN"
        },
        {
          "id": "3604260011",
          "district_id": "3604260",
          "name": "PANENJOAN"
        },
        {
          "id": "3604260012",
          "district_id": "3604260",
          "name": "MEKARSARI"
        },
        {
          "id": "3604260013",
          "district_id": "3604260",
          "name": "PAMANUK"
        },
        {
          "id": "3604260014",
          "district_id": "3604260",
          "name": "CARENANG"
        },
        {
          "id": "3604260015",
          "district_id": "3604260",
          "name": "RAGASMESIGIT"
        },
        {
          "id": "3604261001",
          "district_id": "3604261",
          "name": "GEMBOR"
        },
        {
          "id": "3604261003",
          "district_id": "3604261",
          "name": "CAKUNG"
        },
        {
          "id": "3604261004",
          "district_id": "3604261",
          "name": "LAMARAN"
        },
        {
          "id": "3604261005",
          "district_id": "3604261",
          "name": "WARAKAS"
        },
        {
          "id": "3604261006",
          "district_id": "3604261",
          "name": "BINUANG"
        },
        {
          "id": "3604261007",
          "district_id": "3604261",
          "name": "SUKAMAMPIR"
        },
        {
          "id": "3604270010",
          "district_id": "3604270",
          "name": "TENGKURAK"
        },
        {
          "id": "3604270011",
          "district_id": "3604270",
          "name": "TIRTAYASA"
        },
        {
          "id": "3604270012",
          "district_id": "3604270",
          "name": "LABAN"
        },
        {
          "id": "3604270014",
          "district_id": "3604270",
          "name": "SAMPARWADI"
        },
        {
          "id": "3604270016",
          "district_id": "3604270",
          "name": "KEBON"
        },
        {
          "id": "3604270017",
          "district_id": "3604270",
          "name": "KEBUYUTAN"
        },
        {
          "id": "3604270018",
          "district_id": "3604270",
          "name": "KEMANISAN"
        },
        {
          "id": "3604270019",
          "district_id": "3604270",
          "name": "PONTANG LEGON"
        },
        {
          "id": "3604270020",
          "district_id": "3604270",
          "name": "SUSUKAN"
        },
        {
          "id": "3604270021",
          "district_id": "3604270",
          "name": "ALANG ALANG"
        },
        {
          "id": "3604270022",
          "district_id": "3604270",
          "name": "LONTAR"
        },
        {
          "id": "3604270023",
          "district_id": "3604270",
          "name": "WARGASARA"
        },
        {
          "id": "3604271001",
          "district_id": "3604271",
          "name": "SIREMEN"
        },
        {
          "id": "3604271002",
          "district_id": "3604271",
          "name": "CIBODAS"
        },
        {
          "id": "3604271004",
          "district_id": "3604271",
          "name": "LEMPUYANG"
        },
        {
          "id": "3604271006",
          "district_id": "3604271",
          "name": "SUKAMANAH"
        },
        {
          "id": "3604271007",
          "district_id": "3604271",
          "name": "TANARA"
        },
        {
          "id": "3604271008",
          "district_id": "3604271",
          "name": "PEDALEMAN"
        },
        {
          "id": "3604271009",
          "district_id": "3604271",
          "name": "TENJO AYU"
        },
        {
          "id": "3671010001",
          "district_id": "3671010",
          "name": "TAJUR"
        },
        {
          "id": "3671010002",
          "district_id": "3671010",
          "name": "PARUNG SERAB"
        },
        {
          "id": "3671010003",
          "district_id": "3671010",
          "name": "PANINGGILAN"
        },
        {
          "id": "3671010012",
          "district_id": "3671010",
          "name": "PANINGGILAN UTARA"
        },
        {
          "id": "3671010013",
          "district_id": "3671010",
          "name": "SUDIMARA SELATAN"
        },
        {
          "id": "3671010014",
          "district_id": "3671010",
          "name": "SUDIMARA BARAT"
        },
        {
          "id": "3671010015",
          "district_id": "3671010",
          "name": "SUDIMARA JAYA"
        },
        {
          "id": "3671010016",
          "district_id": "3671010",
          "name": "SUDIMARA TIMUR"
        },
        {
          "id": "3671011001",
          "district_id": "3671011",
          "name": "LARANGAN SELATAN"
        },
        {
          "id": "3671011002",
          "district_id": "3671011",
          "name": "GAGA"
        },
        {
          "id": "3671011003",
          "district_id": "3671011",
          "name": "CIPADU JAYA"
        },
        {
          "id": "3671011004",
          "district_id": "3671011",
          "name": "KEREO SELATAN"
        },
        {
          "id": "3671011005",
          "district_id": "3671011",
          "name": "CIPADU"
        },
        {
          "id": "3671011006",
          "district_id": "3671011",
          "name": "KEREO"
        },
        {
          "id": "3671011007",
          "district_id": "3671011",
          "name": "LARANGAN INDAH"
        },
        {
          "id": "3671011008",
          "district_id": "3671011",
          "name": "LARANGAN UTARA"
        },
        {
          "id": "3671012001",
          "district_id": "3671012",
          "name": "PEDURENAN"
        },
        {
          "id": "3671012002",
          "district_id": "3671012",
          "name": "PONDOK PUCUNG"
        },
        {
          "id": "3671012003",
          "district_id": "3671012",
          "name": "KARANG TENGAH"
        },
        {
          "id": "3671012004",
          "district_id": "3671012",
          "name": "KARANG TIMUR"
        },
        {
          "id": "3671012005",
          "district_id": "3671012",
          "name": "KARANG MULYA"
        },
        {
          "id": "3671012006",
          "district_id": "3671012",
          "name": "PARUNG JAYA"
        },
        {
          "id": "3671012007",
          "district_id": "3671012",
          "name": "PONDOK BAHAR"
        },
        {
          "id": "3671020012",
          "district_id": "3671020",
          "name": "PORIS PLAWAD INDAH"
        },
        {
          "id": "3671020013",
          "district_id": "3671020",
          "name": "CIPONDOH"
        },
        {
          "id": "3671020014",
          "district_id": "3671020",
          "name": "KENANGA"
        },
        {
          "id": "3671020015",
          "district_id": "3671020",
          "name": "GONDRONG"
        },
        {
          "id": "3671020016",
          "district_id": "3671020",
          "name": "PETIR"
        },
        {
          "id": "3671020017",
          "district_id": "3671020",
          "name": "KETAPANG"
        },
        {
          "id": "3671020018",
          "district_id": "3671020",
          "name": "CIPONDOH INDAH"
        },
        {
          "id": "3671020019",
          "district_id": "3671020",
          "name": "CIPONDOH MAKMUR"
        },
        {
          "id": "3671020020",
          "district_id": "3671020",
          "name": "PORIS PLAWAD UTARA"
        },
        {
          "id": "3671020021",
          "district_id": "3671020",
          "name": "PORIS PLAWAD"
        },
        {
          "id": "3671021001",
          "district_id": "3671021",
          "name": "PANUNGGANGAN UTARA"
        },
        {
          "id": "3671021002",
          "district_id": "3671021",
          "name": "PANUNGGANGAN"
        },
        {
          "id": "3671021003",
          "district_id": "3671021",
          "name": "PANUNGGANGAN TIMUR"
        },
        {
          "id": "3671021004",
          "district_id": "3671021",
          "name": "KUNCIRAN"
        },
        {
          "id": "3671021005",
          "district_id": "3671021",
          "name": "KUNCIRAN INDAH"
        },
        {
          "id": "3671021006",
          "district_id": "3671021",
          "name": "SUDIMARA PINANG"
        },
        {
          "id": "3671021007",
          "district_id": "3671021",
          "name": "PINANG"
        },
        {
          "id": "3671021008",
          "district_id": "3671021",
          "name": "NEROKTOG"
        },
        {
          "id": "3671021009",
          "district_id": "3671021",
          "name": "KUNCIRAN JAYA"
        },
        {
          "id": "3671021010",
          "district_id": "3671021",
          "name": "PAKOJAN"
        },
        {
          "id": "3671021011",
          "district_id": "3671021",
          "name": "CIPETE"
        },
        {
          "id": "3671030004",
          "district_id": "3671030",
          "name": "CIKOKOL"
        },
        {
          "id": "3671030005",
          "district_id": "3671030",
          "name": "KELAPA INDAH"
        },
        {
          "id": "3671030006",
          "district_id": "3671030",
          "name": "BABAKAN"
        },
        {
          "id": "3671030014",
          "district_id": "3671030",
          "name": "SUKASARI"
        },
        {
          "id": "3671030015",
          "district_id": "3671030",
          "name": "BUARAN INDAH"
        },
        {
          "id": "3671030016",
          "district_id": "3671030",
          "name": "TANAH TINGGI"
        },
        {
          "id": "3671030017",
          "district_id": "3671030",
          "name": "SUKAASIH"
        },
        {
          "id": "3671030018",
          "district_id": "3671030",
          "name": "SUKARASA"
        },
        {
          "id": "3671031001",
          "district_id": "3671031",
          "name": "KARAWACI BARU"
        },
        {
          "id": "3671031002",
          "district_id": "3671031",
          "name": "NUSAJAYA"
        },
        {
          "id": "3671031003",
          "district_id": "3671031",
          "name": "BOJONGJAYA"
        },
        {
          "id": "3671031004",
          "district_id": "3671031",
          "name": "KARAWACI"
        },
        {
          "id": "3671031005",
          "district_id": "3671031",
          "name": "CIMONE JAYA"
        },
        {
          "id": "3671031006",
          "district_id": "3671031",
          "name": "CIMONE"
        },
        {
          "id": "3671031008",
          "district_id": "3671031",
          "name": "MARGASARI"
        },
        {
          "id": "3671031009",
          "district_id": "3671031",
          "name": "PABUARAN"
        },
        {
          "id": "3671031010",
          "district_id": "3671031",
          "name": "SUKAJADI"
        },
        {
          "id": "3671031012",
          "district_id": "3671031",
          "name": "KOANGJAYA"
        },
        {
          "id": "3671031013",
          "district_id": "3671031",
          "name": "PASARBARU"
        },
        {
          "id": "3671031014",
          "district_id": "3671031",
          "name": "SUMUR PACING"
        },
        {
          "id": "3671031015",
          "district_id": "3671031",
          "name": "PABUARAN TUMPENG"
        },
        {
          "id": "3671031016",
          "district_id": "3671031",
          "name": "NAMBOJAYA"
        },
        {
          "id": "3671040001",
          "district_id": "3671040",
          "name": "MANIS JAYA"
        },
        {
          "id": "3671040002",
          "district_id": "3671040",
          "name": "JATAKE"
        },
        {
          "id": "3671040003",
          "district_id": "3671040",
          "name": "GANDASARI"
        },
        {
          "id": "3671040010",
          "district_id": "3671040",
          "name": "KRONCONG"
        },
        {
          "id": "3671040011",
          "district_id": "3671040",
          "name": "ALAM JAYA"
        },
        {
          "id": "3671040012",
          "district_id": "3671040",
          "name": "PASIR JAYA"
        },
        {
          "id": "3671041001",
          "district_id": "3671041",
          "name": "PANUNGGANGAN BARAT"
        },
        {
          "id": "3671041002",
          "district_id": "3671041",
          "name": "CIBODASARI"
        },
        {
          "id": "3671041003",
          "district_id": "3671041",
          "name": "CIBODAS BARU"
        },
        {
          "id": "3671041004",
          "district_id": "3671041",
          "name": "CIBODAS"
        },
        {
          "id": "3671041005",
          "district_id": "3671041",
          "name": "UWUNG JAYA"
        },
        {
          "id": "3671041006",
          "district_id": "3671041",
          "name": "JATIUWUNG"
        },
        {
          "id": "3671042001",
          "district_id": "3671042",
          "name": "GEMBOR"
        },
        {
          "id": "3671042002",
          "district_id": "3671042",
          "name": "GEBANG RAYA"
        },
        {
          "id": "3671042003",
          "district_id": "3671042",
          "name": "SANGIANG JAYA"
        },
        {
          "id": "3671042004",
          "district_id": "3671042",
          "name": "PERIUK"
        },
        {
          "id": "3671042005",
          "district_id": "3671042",
          "name": "PERIUK JAYA"
        },
        {
          "id": "3671050001",
          "district_id": "3671050",
          "name": "PORISGAGA BARU"
        },
        {
          "id": "3671050002",
          "district_id": "3671050",
          "name": "PORIS JAYA"
        },
        {
          "id": "3671050003",
          "district_id": "3671050",
          "name": "PORISGAGA"
        },
        {
          "id": "3671050004",
          "district_id": "3671050",
          "name": "KEBON BESAR"
        },
        {
          "id": "3671050005",
          "district_id": "3671050",
          "name": "BATUCEPER"
        },
        {
          "id": "3671050006",
          "district_id": "3671050",
          "name": "BATUJAYA"
        },
        {
          "id": "3671050007",
          "district_id": "3671050",
          "name": "BATUSARI"
        },
        {
          "id": "3671051001",
          "district_id": "3671051",
          "name": "KARANG ANYAR"
        },
        {
          "id": "3671051002",
          "district_id": "3671051",
          "name": "KARANG SARI"
        },
        {
          "id": "3671051003",
          "district_id": "3671051",
          "name": "NEGLASARI"
        },
        {
          "id": "3671051004",
          "district_id": "3671051",
          "name": "MEKARSARI"
        },
        {
          "id": "3671051005",
          "district_id": "3671051",
          "name": "KEDAUNG BARU"
        },
        {
          "id": "3671051006",
          "district_id": "3671051",
          "name": "KEDAUNG WETAN"
        },
        {
          "id": "3671051007",
          "district_id": "3671051",
          "name": "SELAPAJANG JAYA"
        },
        {
          "id": "3671060002",
          "district_id": "3671060",
          "name": "JURUMUDI BARU"
        },
        {
          "id": "3671060003",
          "district_id": "3671060",
          "name": "JURUMUDI"
        },
        {
          "id": "3671060004",
          "district_id": "3671060",
          "name": "PAJANG"
        },
        {
          "id": "3671060005",
          "district_id": "3671060",
          "name": "BENDA"
        },
        {
          "id": "3672010001",
          "district_id": "3672010",
          "name": "GUNUNGSUGIH"
        },
        {
          "id": "3672010003",
          "district_id": "3672010",
          "name": "RANDAKARI"
        },
        {
          "id": "3672010004",
          "district_id": "3672010",
          "name": "TEGALRATU"
        },
        {
          "id": "3672010005",
          "district_id": "3672010",
          "name": "BANJAR NEGARA"
        },
        {
          "id": "3672010013",
          "district_id": "3672010",
          "name": "KUBANGSARI"
        },
        {
          "id": "3672011006",
          "district_id": "3672011",
          "name": "DERINGO"
        },
        {
          "id": "3672011007",
          "district_id": "3672011",
          "name": "LEBAKDENOK"
        },
        {
          "id": "3672011008",
          "district_id": "3672011",
          "name": "TAMANBARU"
        },
        {
          "id": "3672011009",
          "district_id": "3672011",
          "name": "CITANGKIL"
        },
        {
          "id": "3672011010",
          "district_id": "3672011",
          "name": "KEBONSARI"
        },
        {
          "id": "3672011011",
          "district_id": "3672011",
          "name": "WARNASARI"
        },
        {
          "id": "3672011012",
          "district_id": "3672011",
          "name": "SAMANGRAYA"
        },
        {
          "id": "3672020011",
          "district_id": "3672020",
          "name": "MEKARSARI"
        },
        {
          "id": "3672020012",
          "district_id": "3672020",
          "name": "TAMANSARI"
        },
        {
          "id": "3672020013",
          "district_id": "3672020",
          "name": "LEBAK GEDE"
        },
        {
          "id": "3672020014",
          "district_id": "3672020",
          "name": "SURALAYA"
        },
        {
          "id": "3672021001",
          "district_id": "3672021",
          "name": "RAMANUJU"
        },
        {
          "id": "3672021002",
          "district_id": "3672021",
          "name": "KEBONDALEM"
        },
        {
          "id": "3672021003",
          "district_id": "3672021",
          "name": "PURWAKARTA"
        },
        {
          "id": "3672021004",
          "district_id": "3672021",
          "name": "TEGAL BUNDER"
        },
        {
          "id": "3672021005",
          "district_id": "3672021",
          "name": "PABEAN"
        },
        {
          "id": "3672021006",
          "district_id": "3672021",
          "name": "KOTABUMI"
        },
        {
          "id": "3672022007",
          "district_id": "3672022",
          "name": "KOTASARI"
        },
        {
          "id": "3672022008",
          "district_id": "3672022",
          "name": "GROGOL"
        },
        {
          "id": "3672022009",
          "district_id": "3672022",
          "name": "RAWA ARUM"
        },
        {
          "id": "3672030001",
          "district_id": "3672030",
          "name": "BAGENDUNG"
        },
        {
          "id": "3672030002",
          "district_id": "3672030",
          "name": "CIWEDUS"
        },
        {
          "id": "3672030003",
          "district_id": "3672030",
          "name": "BENDUNGAN"
        },
        {
          "id": "3672030004",
          "district_id": "3672030",
          "name": "CIWADUK"
        },
        {
          "id": "3672030005",
          "district_id": "3672030",
          "name": "KETILENG"
        },
        {
          "id": "3672031001",
          "district_id": "3672031",
          "name": "JOMBANG WETAN"
        },
        {
          "id": "3672031002",
          "district_id": "3672031",
          "name": "MASIGIT"
        },
        {
          "id": "3672031003",
          "district_id": "3672031",
          "name": "PANGGUNG RAWI"
        },
        {
          "id": "3672031004",
          "district_id": "3672031",
          "name": "GEDONG DALEM"
        },
        {
          "id": "3672031005",
          "district_id": "3672031",
          "name": "SUKMAJAYA"
        },
        {
          "id": "3672040001",
          "district_id": "3672040",
          "name": "BULAKAN"
        },
        {
          "id": "3672040002",
          "district_id": "3672040",
          "name": "CIKERAI"
        },
        {
          "id": "3672040003",
          "district_id": "3672040",
          "name": "KALITIMBANG"
        },
        {
          "id": "3672040004",
          "district_id": "3672040",
          "name": "KARANGASEM"
        },
        {
          "id": "3672040005",
          "district_id": "3672040",
          "name": "CIBEBER"
        },
        {
          "id": "3672040006",
          "district_id": "3672040",
          "name": "KEDALEMAN"
        },
        {
          "id": "3673010001",
          "district_id": "3673010",
          "name": "KAMANISAN"
        },
        {
          "id": "3673010002",
          "district_id": "3673010",
          "name": "PANCALAKSANA"
        },
        {
          "id": "3673010003",
          "district_id": "3673010",
          "name": "TINGGAR"
        },
        {
          "id": "3673010004",
          "district_id": "3673010",
          "name": "CIPETE"
        },
        {
          "id": "3673010005",
          "district_id": "3673010",
          "name": "CURUGMANIS"
        },
        {
          "id": "3673010006",
          "district_id": "3673010",
          "name": "SUKALAKSANA"
        },
        {
          "id": "3673010007",
          "district_id": "3673010",
          "name": "SUKAWANA"
        },
        {
          "id": "3673010009",
          "district_id": "3673010",
          "name": "SUKAJAYA"
        },
        {
          "id": "3673010010",
          "district_id": "3673010",
          "name": "CILAKU"
        },
        {
          "id": "3673020001",
          "district_id": "3673020",
          "name": "NYAPAH"
        },
        {
          "id": "3673020002",
          "district_id": "3673020",
          "name": "LEBAKWANGI"
        },
        {
          "id": "3673020003",
          "district_id": "3673020",
          "name": "CIGOONG"
        },
        {
          "id": "3673020004",
          "district_id": "3673020",
          "name": "TEGALSARI"
        },
        {
          "id": "3673020005",
          "district_id": "3673020",
          "name": "PASULUHAN"
        },
        {
          "id": "3673020006",
          "district_id": "3673020",
          "name": "PABUARAN"
        },
        {
          "id": "3673020007",
          "district_id": "3673020",
          "name": "WALANTAKA"
        },
        {
          "id": "3673020008",
          "district_id": "3673020",
          "name": "PENGAMPELAN"
        },
        {
          "id": "3673020009",
          "district_id": "3673020",
          "name": "PIPITAN"
        },
        {
          "id": "3673020010",
          "district_id": "3673020",
          "name": "KIARA"
        },
        {
          "id": "3673020011",
          "district_id": "3673020",
          "name": "PAGERAGUNG"
        },
        {
          "id": "3673020012",
          "district_id": "3673020",
          "name": "KALODRAN"
        },
        {
          "id": "3673020014",
          "district_id": "3673020",
          "name": "TERITIH"
        },
        {
          "id": "3673030001",
          "district_id": "3673030",
          "name": "GELAM"
        },
        {
          "id": "3673030002",
          "district_id": "3673030",
          "name": "DALUNG"
        },
        {
          "id": "3673030003",
          "district_id": "3673030",
          "name": "TEMBONG"
        },
        {
          "id": "3673030004",
          "district_id": "3673030",
          "name": "KARUNDANG"
        },
        {
          "id": "3673030005",
          "district_id": "3673030",
          "name": "CIPOCOK JAYA"
        },
        {
          "id": "3673030006",
          "district_id": "3673030",
          "name": "BANJARSARI"
        },
        {
          "id": "3673030007",
          "district_id": "3673030",
          "name": "BANJARAGUNG"
        },
        {
          "id": "3673030008",
          "district_id": "3673030",
          "name": "PANANCANGAN"
        },
        {
          "id": "3673040001",
          "district_id": "3673040",
          "name": "SERANG"
        },
        {
          "id": "3673040002",
          "district_id": "3673040",
          "name": "CIPARE"
        },
        {
          "id": "3673040004",
          "district_id": "3673040",
          "name": "CIMUNCANG"
        },
        {
          "id": "3673040005",
          "district_id": "3673040",
          "name": "KOTABARU"
        },
        {
          "id": "3673040006",
          "district_id": "3673040",
          "name": "LONTARBARU"
        },
        {
          "id": "3673040007",
          "district_id": "3673040",
          "name": "KAGUNGAN"
        },
        {
          "id": "3673040008",
          "district_id": "3673040",
          "name": "LOPANG"
        },
        {
          "id": "3673040010",
          "district_id": "3673040",
          "name": "KALIGANDU"
        },
        {
          "id": "3673040011",
          "district_id": "3673040",
          "name": "TERONDOL"
        },
        {
          "id": "3673040012",
          "district_id": "3673040",
          "name": "SUKAWANA"
        },
        {
          "id": "3673050001",
          "district_id": "3673050",
          "name": "CILOWONG"
        },
        {
          "id": "3673050002",
          "district_id": "3673050",
          "name": "SAYAR"
        },
        {
          "id": "3673050003",
          "district_id": "3673050",
          "name": "SEPANG"
        },
        {
          "id": "3673050004",
          "district_id": "3673050",
          "name": "PANCUR"
        },
        {
          "id": "3673050005",
          "district_id": "3673050",
          "name": "KALANG ANYAR"
        },
        {
          "id": "3673050006",
          "district_id": "3673050",
          "name": "KURANJI"
        },
        {
          "id": "3673050007",
          "district_id": "3673050",
          "name": "PANGGUNGJATI"
        },
        {
          "id": "3673050008",
          "district_id": "3673050",
          "name": "DRANGONG"
        },
        {
          "id": "3673050009",
          "district_id": "3673050",
          "name": "TAKTAKAN"
        },
        {
          "id": "3673050010",
          "district_id": "3673050",
          "name": "UMBUL TENGAH"
        },
        {
          "id": "3673050011",
          "district_id": "3673050",
          "name": "LIALANG"
        },
        {
          "id": "3673050012",
          "district_id": "3673050",
          "name": "TAMANBARU"
        },
        {
          "id": "3673050013",
          "district_id": "3673050",
          "name": "CIBENDUNG"
        },
        {
          "id": "3673060001",
          "district_id": "3673060",
          "name": "KASEMEN"
        },
        {
          "id": "3673060002",
          "district_id": "3673060",
          "name": "WARUNG JAUD"
        },
        {
          "id": "3673060003",
          "district_id": "3673060",
          "name": "MESJID PRIYAYI"
        },
        {
          "id": "3673060006",
          "district_id": "3673060",
          "name": "SAWAH LUHUR"
        },
        {
          "id": "3673060007",
          "district_id": "3673060",
          "name": "KILASAH"
        },
        {
          "id": "3673060008",
          "district_id": "3673060",
          "name": "MARGALUYU"
        },
        {
          "id": "3673060009",
          "district_id": "3673060",
          "name": "KASUNYATAN"
        },
        {
          "id": "3673060010",
          "district_id": "3673060",
          "name": "BANTEN"
        },
        {
          "id": "3674010001",
          "district_id": "3674010",
          "name": "KRANGGAN"
        },
        {
          "id": "3674010003",
          "district_id": "3674010",
          "name": "KADEMANGAN"
        },
        {
          "id": "3674010005",
          "district_id": "3674010",
          "name": "BABAKAN"
        },
        {
          "id": "3674010006",
          "district_id": "3674010",
          "name": "BAKTI JAYA"
        },
        {
          "id": "3674020009",
          "district_id": "3674020",
          "name": "BUARAN"
        },
        {
          "id": "3674020010",
          "district_id": "3674020",
          "name": "CIATER"
        },
        {
          "id": "3674020011",
          "district_id": "3674020",
          "name": "RAWA MEKAR JAYA"
        },
        {
          "id": "3674020012",
          "district_id": "3674020",
          "name": "RAWA BUNTU"
        },
        {
          "id": "3674020013",
          "district_id": "3674020",
          "name": "SERPONG"
        },
        {
          "id": "3674020018",
          "district_id": "3674020",
          "name": "CILENGGANG"
        },
        {
          "id": "3674020019",
          "district_id": "3674020",
          "name": "LENGKONG GUDANG"
        },
        {
          "id": "3674020020",
          "district_id": "3674020",
          "name": "LENGKONG GUDANG TIMUR"
        },
        {
          "id": "3674020021",
          "district_id": "3674020",
          "name": "LENGKONG WETAN"
        },
        {
          "id": "3674030001",
          "district_id": "3674030",
          "name": "PONDOK BENDA"
        },
        {
          "id": "3674030002",
          "district_id": "3674030",
          "name": "PAMULANG BARAT"
        },
        {
          "id": "3674030003",
          "district_id": "3674030",
          "name": "PAMULANG TIMUR"
        },
        {
          "id": "3674030004",
          "district_id": "3674030",
          "name": "PONDOK CABE UDIK"
        },
        {
          "id": "3674030005",
          "district_id": "3674030",
          "name": "PONDOK CABE ILIR"
        },
        {
          "id": "3674030006",
          "district_id": "3674030",
          "name": "KEDAUNG"
        },
        {
          "id": "3674030007",
          "district_id": "3674030",
          "name": "BAMBU APUS"
        },
        {
          "id": "3674030008",
          "district_id": "3674030",
          "name": "BENDA BARU"
        },
        {
          "id": "3674040001",
          "district_id": "3674040",
          "name": "SARUA"
        },
        {
          "id": "3674040002",
          "district_id": "3674040",
          "name": "JOMBANG"
        },
        {
          "id": "3674040003",
          "district_id": "3674040",
          "name": "SAWAH BARU"
        },
        {
          "id": "3674040004",
          "district_id": "3674040",
          "name": "SARUA INDAH"
        },
        {
          "id": "3674040005",
          "district_id": "3674040",
          "name": "SAWAH"
        },
        {
          "id": "3674040006",
          "district_id": "3674040",
          "name": "CIPUTAT"
        },
        {
          "id": "3674040007",
          "district_id": "3674040",
          "name": "CIPAYUNG"
        },
        {
          "id": "3674050001",
          "district_id": "3674050",
          "name": "PISANGAN"
        },
        {
          "id": "3674050002",
          "district_id": "3674050",
          "name": "CIREUNDEU"
        },
        {
          "id": "3674050003",
          "district_id": "3674050",
          "name": "CEMPAKA PUTIH"
        },
        {
          "id": "3674050004",
          "district_id": "3674050",
          "name": "REMPOA"
        },
        {
          "id": "3674050005",
          "district_id": "3674050",
          "name": "RENGAS"
        },
        {
          "id": "3674050006",
          "district_id": "3674050",
          "name": "PONDOK RANJI"
        },
        {
          "id": "3674060001",
          "district_id": "3674060",
          "name": "PERIGI BARU"
        },
        {
          "id": "3674060002",
          "district_id": "3674060",
          "name": "PONDOK KACANG BARAT"
        },
        {
          "id": "3674060003",
          "district_id": "3674060",
          "name": "PONDOK KACANG TIMUR"
        },
        {
          "id": "3674060004",
          "district_id": "3674060",
          "name": "PERIGI"
        },
        {
          "id": "3674060005",
          "district_id": "3674060",
          "name": "PONDOK PUCUNG"
        },
        {
          "id": "3674060006",
          "district_id": "3674060",
          "name": "PONDOK JAYA"
        },
        {
          "id": "3674060007",
          "district_id": "3674060",
          "name": "PONDOK AREN"
        },
        {
          "id": "3674060008",
          "district_id": "3674060",
          "name": "JURANG MANGGU BARAT"
        },
        {
          "id": "3674060009",
          "district_id": "3674060",
          "name": "JURANG MANGGU TIMUR"
        },
        {
          "id": "3674060010",
          "district_id": "3674060",
          "name": "PONDOK KARYA"
        },
        {
          "id": "3674060011",
          "district_id": "3674060",
          "name": "PONDOK BETUNG"
        },
        {
          "id": "3674070001",
          "district_id": "3674070",
          "name": "LENGKONG KARYA"
        },
        {
          "id": "3674070002",
          "district_id": "3674070",
          "name": "JELUPANG"
        }
      ]
    let adminData = {provinces,regencies,districts,villages}
    return adminData
}