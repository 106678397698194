import { Background } from "../components/Background";

function Testing() {
    return (
      <Background>
        <div className="bg-white lg:w-1/2 w-full rounded-lg self-center p-10">
          <p className="text-3xl font-bold">Test</p>
        </div>
      </Background>
    );
  }
  
  export default Testing;
  