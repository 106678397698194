import { useState } from "react";

function VerifyKTP({handleVerifikasi,title}){
    let [nik,setNik] = useState('')
    console.log(nik.length);
    return (
        <div className="w-full h-full flex align-center justify-center">
          <div className="bg-white lg:w-1/2 w-full rounded-lg self-center p-10">
            <p className="text-xl font-bold mb-5">Verifikasi {title}</p>
            <label className="text-gray-700" htmlFor="nik">Nomor Induk Kependudukan (NIK) Sesuai KTP</label>
            <input name="nik" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" type="text" value={nik} onChange={(e)=> setNik(e.target.value)} />
            <button className="w-full bg-primary text-white rounded-md text-xl mt-5 p-2" onClick={()=>handleVerifikasi(nik)}>Lanjut</button>
          </div>
        </div>
    )
}

export default VerifyKTP