import {useState } from "react";
import { Background } from "../components/Background";
import axios from "axios";
import env from "../config";
import swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import FormData from 'form-data'
import CollapseAll from "../components/Accordion";
import { Table } from "flowbite-react";
import VerifyKTP from "../components/VerifyKTP";
function RepeatOrder() {
  const [step, setStep] = useState('')
  const [name, setName] = useState('')
  const [nik, setNik] = useState('')
  const [phone, setPhone] = useState('')
  const [whatsapp, setWhatsapp] = useState('')
  const [alasan, setAlasan] = useState('')
  const [goban, setGoban] = useState(false)
  const [statusGoCar, setStatusGoCar] = useState('')
  const [lainnya, setLainnya] = useState('')
  const [statusSim,setStatusSim]= useState('')
  const [tanggalSIM, setTanggalSIM] = useState()
  const [fotoSIM, setFotoSIM] = useState()
  const [bayarOS, setBayarOS] = useState(true)
  const [product, setProduct] = useState('')
  const [tagihan, setTagihan] = useState(0)
  const [totalOS, setTotalOS] = useState(0)
  const [totalNonOS, setTotalNonOS] = useState(0)
  const [nonOS, setNonOS] = useState([])
  const [isRepeatOrder, setIsRepeatOrder] = useState(true)
  // const [verified, setVerified] = useState(false)
  const [nopol, setNopol] = useState('')
  let reasons = [
    "PENDAPATAN GOCAR TIDAK CUKUP",
    "URUSAN KELUARGA",
    "ISTIRAHAT/SAKIT",
    "OS / HUTANG SETORAN TINGGI",
    "AKUN GOCAR TERKENA SUSPEND",
    "MENGALAMI KECELAKAAN",
    "UNIT PROBLEM",
    "ORDER OFFLINE KELUAR KOTA",
    "HP HILANG / RUSAK",
    "MEMAKAI MOBIL DILUAR GOFLEET",
    "BERHENTI MENJADI DRIVER ONLINE",
    "TUKAR UNIT",
    "PULANG KAMPUNG",
    "LIBUR",
    "MENDAPATKAN PEKERJAAN LAIN"
  ]
  let navigate = useNavigate()

  // useEffect(()=>{
  //   let today = new Date()
  //   // console.log(today.toLocaleDateString('id-ID',{}));
  //   let day=today.getDate()
  //   let month =("0" + (today.getMonth() + 1)).slice(-2)
  //   let year = today.getFullYear()
  //   setTanggalSIM(`${year}-${month}-${day}`) 
  // },[])
  async function submitRepeatOrder(e){
    e.preventDefault()
    swal.showLoading()
    let rawData = {
      nik,
      name,
      phone,
      alasan,
      goban,
      nopol,
      statusGoCar : statusGoCar === 'Lainnya' ? statusGoCar + '-' + lainnya :statusGoCar,
      statusSim,
      tanggalSIM,
      bayarOS,
      product,
      totalOS,
      totalNonOS,
      whatsapp
    }
    console.log(rawData);
    let data = await new FormData()

    await data.append('fotoSIM', fotoSIM, fotoSIM.name)
    for (const key in rawData) {
      await data.append(key, rawData[key])
    }
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: env.backendEndpoint+'/gofleet-forms/repeat-order/submit',
      data : data
    };
    
    axios.request(config)
      .then(res => {
        if(res.status === 201){
          swal.hideLoading()
          swal.fire(
            'Pengajuan Repeat Order Anda',
            'sudah berhasil di submit!',
            'success'
          )

          swal.fire({
            title: 'Pengajuan Berhasil',
            timer: 2000,
            timerProgressBar: true,
          }).then((result) => {
            /* Read more about handling dismissals below */
            navigate("/success?prev=Repeat%20Order")
          })

        }
      })
      .catch(err=>{
        swal.hideLoading()
        swal.fire(
          'Pengajuan Repeat Order Anda',
          'gagal di submit, mohon coba kembali beberapa saat lagi',
          'error'
        )
      })


    
  }

  function handleGoban(e,val){
    e.preventDefault()
    setGoban(val)
  }

  function handleBayarOS(e,val){
    e.preventDefault()
    setBayarOS(val)
  }

  function handleAlasan(e,val){
    e.preventDefault()
    setAlasan(val)
  }

  function handleStatusGoCar(e,val){
    e.preventDefault()
    console.log(val);
    setStatusGoCar(val)
  }

  function handleStatusSim(e,val){
    e.preventDefault()
    setStatusSim(val)
  }

  function handleVerifikasi(noik){
    setNik(noik)
    console.log(noik);
    if(noik.length !== 16){
      // errors.push('NIK Harus 16 digit')
      swal.fire('ERROR', 'NIK Harus 16 digit')
    }else {
      axios.post(`${env.backendEndpoint}/gofleet-forms/repeat-order/verify`,{nik:noik})
        .then(res =>{
          console.log(res.data);
          if(res){
            setName(res.data.name)
            setPhone(res.data.phone)
            setWhatsapp(res.data.phone)
            // setVerified(true)
            setStep('first')
            // setTagihan(res.data.tagihan.toLocaleString('id-ID'))
            setTotalOS(res.data.total_outstanding)
            setTotalNonOS(res.data.total_non_os)
            setNonOS(res.data.non_os)
            setTagihan(Number(res.data.total_outstanding) + Number(res.data.total_non_os))
            if(!res.data.isRepeatOrder) {
              setIsRepeatOrder(false)
              let tagihan = Number(res.data.total_outstanding) + Number(res.data.total_non_os) + 750000
              let newNonOS = [...res.data.non_os, {jenis:"Joining Fee", amount:750000, paid_amount:0}]
              console.log(newNonOS);
              setNonOS(newNonOS)
              setTagihan(tagihan)
            }
          } 
          // swal.fire('success', `${res.data.name}, ${res.data.phone}`)
        })
        .catch(err =>{
          console.log(err?.response?.status);
          let errorMessage = err?.response?.data?.message || err?.message
          swal.fire('ERROR',errorMessage)
          
        })
    }
  }

  function verifyFirstPage(e){
    e.preventDefault()
    let errors=[]
    if(phone[0] === '0'){
      setPhone('62'+phone.split(1))
    }
    if(nopol){
      let rawNopol = nopol

      rawNopol = rawNopol.trim().replace(/ /g, "").toUpperCase()
      console.log(rawNopol);
      if(!rawNopol.match(/^([A-Z]{1,2})([1-9][0-9]{0,3})([A-Z]{1,3})$/i)){
        console.log('FAIL');
        errors.push('Nomor Polisi Tidak Valid')
      }else{
        setNopol(rawNopol)
      }
    }

    if(!name) errors.push('Nama Tidak Boleh Kosong')
    if(!phone) errors.push('Nomor telpon Tidak Boleh Kosong')
    if(!alasan) errors.push('Alasan Mengembalikan Unit Tidak Boleh Kosong')
    if(goban && !nopol)errors.push('Nomor polisi Tidak Boleh Kosong')
    if(!statusGoCar || (statusGoCar === 'Lainnya' && !lainnya))errors.push('Status Gocar Tidak Boleh Kosong')
    if(goban && !nopol)errors.push('Nomor polisi Tidak Boleh Kosong')
    if(!tanggalSIM) errors.push('Tanggal Kadaluarsa SIM Tidak Boleh Kosong')
    if(!fotoSIM) errors.push('Foto SIM A tidak Boleh Kosong')

    if(errors.length){
      swal.fire({
        icon:'error',
        title:'ERROR',
        text: errors.join(', ')
      })
    }else {
      setStep('second')
    }
  }

  function handleFile(e){
    console.log(e.target.files[0]);
    setFotoSIM(e.target.files[0])
  }
  
  let detailTable = (
    <Table>
      <Table.Row>
        <Table.HeadCell className="text-xs">
          Jenis
        </Table.HeadCell>
        <Table.HeadCell className="text-xs">
          Jumlah
        </Table.HeadCell>

      </Table.Row>
      <Table.Body>
        {
          totalOS ?
          <Table.Row>
            <Table.Cell className="text-xs px-2">Tagihan OS</Table.Cell>
            <Table.Cell className="text-xs px-2 text-right">Rp {totalOS.toLocaleString('id-ID')}</Table.Cell>
          </Table.Row>
          : null
        }
        {
          nonOS.length > 0 ? (nonOS.map((item,i) =>{
            return (
              <Table.Row key={i}>
                <Table.Cell className="text-xs px-2">{item.jenis}</Table.Cell>
                <Table.Cell className="text-xs px-2 text-right">Rp {(item.amount-item.paid_amount).toLocaleString('id-ID')}</Table.Cell>
              </Table.Row>
            )
          })): null
        }
      </Table.Body>
    </Table>
  )
  let output;
  switch(step) {
    case 'first':
      output = (
        <div className="bg-white lg:w-1/2 w-full rounded-lg self-center p-10">
          <p className="text-3xl font-bold mb-5">Form {isRepeatOrder ? 'Repeat Order': 'Renew'}</p>
          <form className="flex flex-col lg:text-xl text-md">
            <label className="text-gray-700" htmlFor="name">Nama sesuai KTP</label>
            <input disabled name="name" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" type="text" value={name} />
            <label className="text-gray-700" htmlFor="phone">Nomor Telepon yang Terdaftar di Gofleet</label>
            <input name="phone" className="
                    mt-1 mb-3
                    block
                    w-full
                    rounded-md
                    bg-gray-100
                    border-transparent
                    focus:border-gray-500 focus:bg-white focus:ring-0
                  " type="text" value={phone} disabled/>
                  <label className="text-gray-700" htmlFor="phone">Nomor Whatsapp Aktif</label>
            <input name="phone" className="
                    mt-1 mb-3
                    block
                    w-full
                    rounded-md
                    bg-gray-100
                    border-transparent
                    focus:border-gray-500 focus:bg-white focus:ring-0
                  " type="text" value={whatsapp} onChange={(e)=> setWhatsapp(e.target.value)}/>

            <label className="text-gray-700 flex" htmlFor="alasan">Alasan Mengembalikan Unit Sebelumnya <p className="text-red-600 text-sm self-baseline px-1">*pilih salah satu</p></label>
            <div className="flex flex-col mt-1 mb-3 w-full rounded-md bg-gray-100 border-transparent">
              {
                reasons.map((item,i)=>{
                  return (<button key={i} className={'w-full p-2 text-start ' + (alasan === item ? 'bg-secondary text-primary rounded-md' : 'text-gray-600 font-light')} onClick={(e)=> handleAlasan(e,item)}>{item[0]+item.substring(1).toLocaleLowerCase()}</button>)
                })
              }
              {/* <button className={'w-full p-2 text-start ' + (alasan === 'Titip Mobil' ? 'bg-secondary text-primary rounded-md' : 'text-gray-600 font-light')} onClick={(e)=> handleAlasan(e,'Titip Mobil')}>Titip Mobil</button>
              <button className={'w-full p-2 text-start ' + (alasan === 'Unit Accident (Sebagai korban)' ? 'bg-secondary text-primary rounded-md' : 'text-gray-600 font-light')} onClick={(e)=> handleAlasan(e,'Unit Accident (Sebagai korban)')}>Unit Accident (Sebagai korban)</button>
              <button className={'w-full p-2 text-start ' + (alasan === 'Unit Accident (Tunggal / pelaku)' ? 'bg-secondary text-primary rounded-md' : 'text-gray-600 font-light')} onClick={(e)=> handleAlasan(e,'Unit Accident (Tunggal / pelaku)')}>Unit Accident (Tunggal / pelaku)</button>
              <button className={'w-full p-2 text-start ' + (alasan === 'Unit Bermasalah' ? 'bg-secondary text-primary rounded-md' : 'text-gray-600 font-light')} onClick={(e)=> handleAlasan(e,'Unit Bermasalah')}>Unit Bermasalah</button> */}

            </div>

            <label className="text-gray-700" htmlFor="goban">Apakah Unit Terdaftar GOBAN?</label>
            <div className="flex mt-1 mb-3 w-full rounded-md bg-gray-100 border-transparent">

              <button className={'w-1/2 py-2 ' + (goban ? 'bg-secondary text-primary rounded-md' : '')} onClick={(e)=> handleGoban(e,true)}>Ya</button>
              <button className={'w-1/2 py-2 ' + (!goban ? 'bg-secondary text-primary rounded-md' : '')} onClick={(e)=> handleGoban(e,false)}>Tidak</button>
            </div>
            {
              goban ?
              (<div>
                <label className="text-gray-700" htmlFor="nopol">Masukkan NOPOL Unit Anda</label>
                <input name="nopol" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" type="text" value={nopol} onChange={(e)=> setNopol(e.target.value)}/>
              </div>) : null
            }
            
            {/* STATUS GOPARTNER */}
            <label className="text-gray-700 flex" htmlFor="statusGoCar">Status Akun GoCar <p className="text-red-600 text-sm self-baseline px-1">*pilih salah satu</p></label>
            <div className="flex flex-col mt-1 mb-3 w-full rounded-md bg-gray-100 border-transparent">

              <button className={'w-full p-2 text-start ' + (statusGoCar === 'Aktif' ? 'bg-secondary text-primary rounded-md' : 'text-gray-600 font-light')} onClick={(e)=> handleStatusGoCar(e,'Aktif')}>Aktif</button>
              <button className={'w-full p-2 text-start ' + (statusGoCar === 'Suspend' ? 'bg-secondary text-primary rounded-md' : 'text-gray-600 font-light')} onClick={(e)=> handleStatusGoCar(e,'Suspend')}>Suspend</button>
              <button className={'w-full p-2 text-start ' + (statusGoCar === 'Putus Mitra (PM)' ? 'bg-secondary text-primary rounded-md' : 'text-gray-600 font-light')} onClick={(e)=> handleStatusGoCar(e,'Putus Mitra (PM)')}>Putus Mitra (PM)</button>
              <button className={'w-full p-2 text-start ' + (statusGoCar === 'Lainnya' ? 'bg-secondary text-primary rounded-md' : 'text-gray-600 font-light')} onClick={(e)=> handleStatusGoCar(e,'Lainnya')}>Lainnya</button>
            </div>
            {
              statusGoCar === 'Lainnya' ? (<div>
                <label className="text-gray-700" htmlFor="nopol">Status Akun GoCar Lainnya</label>
                <input name="nopol" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" type="text" value={lainnya} onChange={(e)=> setLainnya(e.target.value)}/>
              </div>) : null
            }

            {/* STATUS SIM */}
            <label className="text-gray-700 flex" htmlFor="statusSim">Status SIM <p className="text-red-600 text-sm self-baseline px-1">*pilih salah satu</p></label>
            <div className="flex flex-col mt-1 mb-3 w-full rounded-md bg-gray-100 border-transparent">

              <button className={'w-full p-2 text-start ' + (statusSim === 'Aktif' ? 'bg-secondary text-primary rounded-md' : 'text-gray-600 font-light')} onClick={(e)=> handleStatusSim(e,'Aktif')}>Aktif</button>
              <button className={'w-full p-2 text-start ' + (statusSim === 'Kadaluarsa' ? 'bg-secondary text-primary rounded-md' : 'text-gray-600 font-light')} onClick={(e)=> handleStatusSim(e,'Kadaluarsa')}>Kadaluarsa</button>
              <button className={'w-full p-2 text-start ' + (statusSim === 'Kena Tilang' ? 'bg-secondary text-primary rounded-md' : 'text-gray-600 font-light')} onClick={(e)=> handleStatusSim(e,'Kena Tilang')}>Kena Tilang</button>
              <button className={'w-full p-2 text-start ' + (statusSim === 'Hilang' ? 'bg-secondary text-primary rounded-md' : 'text-gray-600 font-light')} onClick={(e)=> handleStatusSim(e,'Hilang')}>Hilang</button>
            </div>
            {/* TANGGAL BERLAKU SIM */}
            <div>
              <label className="text-gray-700" htmlFor="tanggalSIM">SIM Berlaku Sampai Dengan...</label>
              <input name="tanggalSIM" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" type="date" value={tanggalSIM} onChange={(e)=> setTanggalSIM(e.target.value)}/>
            </div>

            {/* FOTO SIM */}
            <div>
              <label className="text-gray-700" htmlFor="fotoSIM">Upload SIM A</label>
              <input name="fotoSIM" className="block w-full text-sm text-gray-900 rounded-lg cursor-pointer bg-gray-100 focus:outline-none mt-1 mb-3" id="file_input" type="file" accept="image/*" onChange={handleFile}/>
            </div>

            {/* KONFIRMASI OS */}
            {
              tagihan ? (
                <div className="flex flex-col lg:text-xl text-md mt-1 mb-3">
                  {/* <label className=" mt-1 mb-3 p-3 w-full rounded-md bg-gray-100 border-red-500 flex lg:flex-row flex-col" htmlFor="goban">Anda memiliki tunggakan sebesar <span className="text-red-500 font-bold">Rp {tagihan.toLocaleString('id-ID')},-</span> <span className="text-xs text-red-500 align-top">{totalNonOS ? `Tagihan OR Rp ${totalNonOS.toLocaleString('id-ID')},-`: null}</span><span className="text-xs text-red-500 align-top">{totalOS ? `Tagihan OS Rp ${totalOS.toLocaleString('id-ID')},-`: null}</span><span className="text-xs text-red-500 align-top">{!isRepeatOrder ? `Biaya Joining Fee Rp 750.000,-`: null}</span></label> */}
                  <div className="mb-3 mt-1">
                    <CollapseAll contents={
                      [{title:(<p className="flex flex-col">tunggakan anda sebesar <span className="text-red-500 font-bold">Rp {tagihan.toLocaleString('id-ID')},-</span></p>),content:(detailTable)}]
                    } info={[`Rincian tagihan diatas adalah tagihan Anda saat ini.`,`Tagihan dapat berubah jika terdapat biaya tambahan apabila Anda baru saja mengembalikan unit, mohon untuk cek berkala aplikasi GoFleet Driver bagian tunggakan OS dan Non OS dalam waktu minimal H+2 hari kerja dari tanggal pengembalian unit.`]}></CollapseAll>
                  </div>
                  <label className="text-gray-700" htmlFor="goban">Apakah anda bersedia untuk membayar tunggakan di atas?</label>
                  <div className="flex mt-1 mb-3 w-full rounded-md bg-gray-100 border-transparent">

                    <button className={'w-1/2 py-2 ' + (bayarOS ? 'bg-secondary text-primary rounded-md' : '')} onClick={(e)=> handleBayarOS(e,true)}>Ya</button>
                    <button className={'w-1/2 py-2 ' + (!bayarOS ? 'bg-secondary text-primary rounded-md' : '')} onClick={(e)=> handleBayarOS(e,false)}>Tidak</button>
                  </div>
                </div>
              ): null
            }
            <button className="w-full bg-primary text-white rounded-md text-lg mt-5 p-2" onClick={verifyFirstPage}>Lanjut</button>
          </form>
        </div>
      )
      break;
    case 'second':
      output = (
        <div className="w-full h-full flex align-center justify-center">
          <div className="bg-white w-full rounded-lg self-center lg:p-10 p-5">
            <div className="flex flex-col mb-5">
              <p className="text-3xl font-bold text-center">Produk GoFleet apa yang Anda minati ?</p>
              {/* <span className="w-full text-center text-sm font-medium text-red-500 align-top">* pilih salah satu</span> */}
              <span className="w-full text-center text-sm font-medium text-red-500 align-top">* harga dapat berubah</span>
            </div>
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
              <button className={`flex-col justify-items-between align-center mt-1 w-full pt-3 rounded-md bg-gray-100 border-transparent ${product === 'regular' ?'bg-secondary text-primary' : 'hover:bg-secondary hover:text-primary'}`} onClick={()=>setProduct('regular')}>
                <p className="text-center text-xl font-semibold w-full">Program Regular</p>
                <img className="w-full" src="https://gofleetstorageprod.blob.core.windows.net/gofleetstorageprod/1687862216304365_MOBIL-01.svg" alt="avanza-xenia" />
                <div className="lg:px-3 px-1">
                  <ul className="list-disc list-inside font-normal text-start">
                    <li>Harga Sewa Rp 180.000*</li>
                    <li>Pilihan Mobil Avanza / Xenia</li>
                    <li>Diskon 50% untuk Biaya Komitmen</li>
                  </ul>
                </div>
                <p className="bg-primary w-full text-center py-2 mt-5 rounded-b-md text-white">
                  Saya {product !== 'regular'? `Mau`:`Pilih`} yang Ini !
                </p>
              </button>
              <button className={`flex-col justify-items-between align-center mt-1 w-full pt-3 rounded-md bg-gray-100 border-transparent ${product === 'andalan' ?'bg-secondary text-primary' : 'hover:bg-secondary hover:text-primary'}`} onClick={()=>setProduct('andalan')}>
                <p className="text-center text-xl font-semibold w-full">Program Andalan</p>
                <img className="w-full" src="https://gofleetstorageprod.blob.core.windows.net/gofleetstorageprod/1687862141103989_MOBIL-03.svg" alt="calya Sigra" />
                <div className="lg:px-3 px-1">
                  <ul className="list-disc list-inside font-normal text-start">
                    <li>Harga Sewa Rp 175.000</li>
                    <li>Pilihan Mobil Calya / Sigra</li>
                    <li>Diskon 50% untuk Biaya Komitmen</li>
                  </ul>
                </div>
                <p className="bg-primary w-full text-center py-2 mt-5 rounded-b-md text-white">
                  Saya {product !== 'andalan'? `Mau`:`Pilih`} yang Ini !
                </p>
              </button>
              <button className={`flex-col justify-items-between align-center mt-1 w-full pt-3 rounded-md bg-gray-100 border-transparent ${product === 'unggulan' ?'bg-secondary text-primary' : 'hover:bg-secondary hover:text-primary'}`} onClick={()=>setProduct('unggulan')}>
                <p className="text-center text-xl font-semibold w-full">Program Unggulan</p>
                <img className="w-full" src="https://gofleetstorageprod.blob.core.windows.net/gofleetstorageprod/1687862150422594_MOBIL-02.svg" alt="innova" />
                <div className="lg:px-3 px-1">
                  <ul className="list-disc list-inside font-normal text-start">
                    <li>Harga Sewa Mulai Dari Rp 300.000*</li>
                    <li>Pilihan Mobil Innova</li>
                    <li>Diskon 50% untuk Biaya Komitmen</li>
                  </ul>
                </div>
                <p className="bg-primary w-full text-center py-2 mt-5 rounded-b-md text-white">
                  Saya {product !== 'unggulan'? `Mau`:`Pilih`} yang Ini !
                </p>
              </button>
              
              <button className={`flex-col mt-1 w-full pt-3 rounded-md border-transparent lg:col-span-3 px-3 py-2 ${product === 'belum tahu' ?'bg-secondary text-primary' : 'hover:bg-secondary hover:text-primary bg-gray-100' }`} onClick={()=>setProduct('belum tahu')}>
                <p className="text-center w-full text-xl font-medium">
                  Belum tahu, saya ingin dijelaskan dahulu tentang semua program yang ada
                </p>
              </button>
              <div className="flex mt-1 lg:col-span-3 w-full items-center justify-center" >
                {
                  product ? (<button className="self-center lg:w-fit w-full rounded-full border-transparent px-5 lg:px-10 py-2 bg-primary text-white" onClick={submitRepeatOrder}>
                  <p className="text-center w-full text-xl font-medium">
                    Selesaikan Pendaftaran Repeat Order
                  </p>
                </button>) : null
                }
              </div>
            </div>
          </div>
        </div>
      )
      break;
    default:
      output = (
        // <div className="w-full h-full flex align-center justify-center">
        //   <div className="bg-white lg:w-1/2 w-full rounded-lg self-center p-10">
        //     <p className="text-xl font-bold mb-5">Verifikasi Repeat Order</p>
        //     <label className="text-gray-700" htmlFor="nik">Nomor Induk Kependudukan (NIK) Sesuai KTP</label>
        //     <input name="nik" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" type="text" value={nik} onChange={(e)=> setNik(e.target.value)} />
        //     <button className="w-full bg-primary text-white rounded-md text-xl mt-5 p-2" onClick={handleVerifikasi}>Lanjut</button>
        //   </div>
        // </div>
        <VerifyKTP handleVerifikasi={handleVerifikasi} title={'Repeat Order'}/>
      )
      break;
  }
    return (
      <Background>
        {output}
      </Background>
    );
  }
  
  export default RepeatOrder;
  