export const Background = ({children}) =>{
    return (
        <div className='relative bg-primary min-w-full min-h-screen flex flex-col item-center z-0'>
            <div className='bg-layer z-0'>
            </div>
            <div className="z-10 flex flex-col items-center lg:px-32 lg:py-24 px-8 py-4">
                {children}
            </div>
        </div>
    )
}