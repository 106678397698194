import { Background } from "../components/Background";

function Success() {
  const searchParams = new URLSearchParams(document.location.search)

  return (
    <Background>
      <div className='h-full w-full flex flex-col items-center justify-center'>
        {/* <div style={{backgroundImage:"url('big-logo.png')", height:"300px", width:"300px",resize:'both'}}/> */}
        <img src="big-logo.png" style={{width:'20vh', aspectRatio:'1/1'}} className='z-20 self-center rounded-full' alt="" />
        <p className="text-4xl text-white text-center self-center my-5 z-10">
          Terimakasih Sudah Mengisi Form {searchParams.get('prev')}
        </p>
      </div>
    </Background>
  );
}

export default Success;
