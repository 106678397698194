import { Background } from "../components/Background";

function Home() {
  return (
    <Background>
      <div className='flex flex-col'>
        {/* <div style={{backgroundImage:"url('big-logo.png')", height:"300px", width:"300px",resize:'both'}}/> */}
        <img src="big-logo.png" style={{width:'20vw', aspectRatio:'1/1'}} className='z-20 self-center rounded-full' alt="" />
        <p className="text-4xl text-white self-center my-5 z-10">
          Welcome to
        </p>
        <p className='text-4xl text-white self-center z-10'>
        forms.gofleet.id
        </p>
      </div>
    </Background>
  );
}

export default Home;
