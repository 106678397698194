import { useState,useEffect } from "react";
import { Background } from "../components/Background";
import VerifyKTP from "../components/VerifyKTP";
// import { Listbox } from "@headlessui/react";
import { getAdministrativeData } from "../helpers/helper";
import axios from "axios";
import swal from 'sweetalert2'
import env from "../config";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function GoBan(){
    let navigate = useNavigate()
    const [page, setPage] = useState('')
    // eslint-disable-next-line
    const [nik,setNik] = useState('')
    const [province, setProvince] = useState(0)
    const [regency, setRegency] = useState(0)
    const [district,setDistrict] = useState(0)
    const [village,setVillage] = useState(0)
    const [provinces, setProvinces] = useState([])
    const [regencies, setRegencies] = useState([])
    const [districts,setDistricts]=useState([])
    const [villages,setVillages] = useState([])
    const [userData,setUserData] = useState({
        name: '',
        email:'',
        phone:'',
        whatsapp:'',
        nik:'',
        sim:'',
        expiry_date:'',
        bank:'',
        bank_account:'',
        bank_branch:'',
        tax_number:'',
        postcode:'',
        address_detail:'',
        existing:false
    })
    let pageContent
    useEffect(()=>{
        let administrative = getAdministrativeData()
        setProvinces(administrative.provinces)
        setRegencies(administrative.regencies)
        setDistricts(administrative.districts)
        setVillages(administrative.villages)
    },[])
    
    function handleSubmit(e){
        console.log(userData);
        e.preventDefault()
        let payload = {...userData}

        let provinceDetail = provinces.find(item => +item.id === +province)
        let regencyDetail = regencies.find(item => +item.id === +regency)
        let districtDetail = districts.find(item => +item.id === +district)
        let villageDetail = villages.find(item => +item.id === +village)
        payload.province = provinceDetail?.name
        payload.regency = regencyDetail?.name
        payload.district = districtDetail?.name
        payload.village = villageDetail?.name
        let errors = validateForm()
        if(errors.length > 0){
            swal.fire('Data Belum Lengkap',errors.toString())
        }else{
            axios.post(`${env.backendEndpoint}/gofleet-forms/gabung-bandara`,{userData:payload})
            .then(res =>{
                if(res.status) navigate("/success?prev=Gofleet%20Bandara")
            })
            .catch(err =>{
                console.log(err);
                console.log(err.message);
                swal.fire('ERROR',err.message)
            })
        }


    }
    function handleChangeValue(e){
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let newUserData = {...userData}
        newUserData[name] = value

        setUserData(newUserData)
    }

    function handleVerifikasi(noik){
        // let data = {...userData}
        // data.nik = noik.toString()
        // setUserData(data)
        console.log(noik);
        if(noik.length !== 16){
          // errors.push('NIK Harus 16 digit')
          swal.fire('ERROR', 'NIK Harus 16 digit')
        }else {
          axios.post(`${env.backendEndpoint}/gofleet-forms/repeat-order/verify`,{nik:noik})
            .then(res =>{
              console.log(res.data);
              let newData = {...userData}
              newData.nik = noik
              if(!res.message){
                console.log(res);
                newData.address_detail = res.data.address
                newData.email = res.data.email
                newData.name = res.data.name
                newData.phone = res.data.phone
                newData.whatsapp = res.data.primary_phone_number
                newData.sim = res.data.sim
                newData.expiry_date = moment(res.data.sim_expired).format('YYYY-MM-DD')
                newData.tax_number = res.data.tax_exempt_number
                console.log(newData);
                if(!res?.data?.rentalDetail?.price_group?.toLowerCase().includes('sr') && !res?.data?.rentalDetail?.price_group?.toLowerCase().includes('prime') ){
                    setUserData(newData)
                    setPage('main')
                }else{

                    swal.fire('Mohon Maaf', 'Layanan Gofleet Bandara Hanya Tersedia untuk Mitra Regular (Avanza & Xenia)')
                }
            }else{
                setUserData(newData)
                setPage('main')
            }
              // swal.fire('success', `${res.data.name}, ${res.data.phone}`)
            })
            .catch(err =>{
              console.log(err?.response?.status);
              let errorMessage = err?.response?.data?.message || err?.message
              swal.fire('ERROR',errorMessage)
              
            })
        }
      }
    function validateForm(){
        let errors = []
        for (const key in userData) {
            let keyname = key.split('_').join(' ')
            if(!userData[key]?.toString()) errors.push(`${keyname} tidak boleh kosong`)
        }
        return errors
    }
    switch (page){
        case 'main':
            pageContent = (<form onSubmit={handleSubmit} className="flex flex-col bg-white rounded-lg p-5">
            <p className="text-primary font-bold text-2xl mt-3">Gabung Gofleet Bandara Sekarang!</p>
            <p className="text-gray-700 font-thin text-md mb-3">*Saat ini harnya berlaku untuk Jabodetabek. Whatsapp jika ada pertanyaan ke: <a className="text-gray-700" href="https://wa.me/6289501062333" target="_blank" rel="noreferrer">0895-0106-2333</a></p>
            <p className="text-primary font-bold text-xl my-3">Informasi Dasar</p>
            <label htmlFor="name" >Nama Lengkap</label>
            <input type="text" name="name" id="name" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" value={userData.name} onChange={handleChangeValue}/>
            <label htmlFor="email">Alamat Email</label>
            <input type="email" name="email" id="email" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" value={userData.email} onChange={handleChangeValue} />
            <label htmlFor="phone">Nomor Telepon Aktif</label>
            <input type="text" name="phone" id="phone" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" value={userData.phone} onChange={handleChangeValue} />
            <label htmlFor="whatsapp">Nomor Whatsapp Aktif</label>
            <input type="text" name="whatsapp" id="whatsapp" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" value={userData.whatsapp} onChange={handleChangeValue} />
            {/* <label htmlFor="nik">Nomor KTP</label>
            <input type="text" name="nik" id="nik" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" value={userData.nik} onChange={handleChangeValue} /> */}
            <label htmlFor="sim">Nomor SIM</label>
            <input type="text" name="sim" id="sim" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" value={userData.sim} onChange={handleChangeValue} />
            <label htmlFor="expiry_date">Masa Berlaku SIM</label>
            <input type="date" name="expiry_date" id="expiry_date" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" value={userData.expiry_date} onChange={handleChangeValue} />
            <label htmlFor="bank">Nama Bank yang Digunakan</label>
            <input type="text" name="bank" id="bank" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" value={userData.bank} onChange={handleChangeValue} />
            <label htmlFor="bank_account">Nomor Rekening</label>
            <input type="text" name="bank_account" id="bank_account" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" value={userData.bank_account} onChange={handleChangeValue}/>
            <label htmlFor="bank_branch">Cabang Pembukaan Rekening</label>
            <input type="text" name="bank_branch" id="bank_branch" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" value={userData.bank_branch} onChange={handleChangeValue} />
            <label htmlFor="tax_number">Nomor NPWP</label>
            <input type="text" name="tax_number" id="tax_number" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" value={userData.tax_number} onChange={handleChangeValue} />
            <p className="text-primary font-bold text-xl">Informasi Domisili</p>
            <label htmlFor="province">Provinsi</label>
            {/* <Listbox value={province} onChange={setProvince}>
                <Listbox.Button>{province.name}</Listbox.Button>
                <Listbox.Options>
                    {provinces.map((prov) => (
                    <Listbox.Option
                        key={prov.id}
                        value={prov}
                        disabled={prov.unavailable}
                    >
                        {prov.name}
                    </Listbox.Option>
                    ))}
                </Listbox.Options>
            </Listbox> */}
            <select defaultValue={0} name="province" id="province" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" value={province} onChange={e => setProvince(e.target.value)}>
                <option value={0}>Provinsi</option>
                {provinces.map((province,i) => <option key={i} value={province.id}>{province.name}</option>)}
            </select>
            {
                province ? 
                <div className="flex flex-col">
                    <label htmlFor="regency">Kota</label>
                    <select defaultValue={0} name="regency" id="regency" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"  value={regency} onChange={e => setRegency(e.target.value)}>
                    <option value={0}>Kota</option>
                        {
                        // eslint-disable-next-line
                        regencies.map((regency,i) => {
                            if(Number(regency.province_id) === Number(province)) {
                                return <option key={i} value={regency.id}>{regency.name}</option>
                            }
                        })}
                    </select>
                </div> : null
            }
            {
                regency ? 
                    <div className="flex flex-col">
                        <label htmlFor="district">Kecamatan</label>
                        <select defaultValue={0} name="district" id="district" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" onChange={e => setDistrict(e.target.value)}>
                            <option value={0}>Kecamatan</option>

                            {
                            // eslint-disable-next-line
                            districts.map((district,i) => {
                                if(Number(district.regency_id) === Number(regency)) {
                                    return <option key={i} value={district.id}>{district.name}</option>
                                }
                            })}
                        </select>
                    </div>
                    : null
            }
            {
                district ? 
                <div className="flex flex-col">
                    <label htmlFor="village">Kelurahan</label>
                    <select defaultValue={0} name="village" id="village" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" value={village} onChange={e => setVillage(e.target.value)}>
                    <option value={0}>Kelurahan</option>
                    {
                    // eslint-disable-next-line
                    villages.map((village,i) => {
                                if(Number(village.district_id) === Number(district)) {
                                    return <option key={i} value={village.id}>{village.name}</option>
                                }
                            })}
                    </select>
                </div>
                :null
            }
            <label htmlFor="postcode">Kode Pos</label>
            <input type="text" name="postcode" id="postcode" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" value={userData.postcode} onChange={handleChangeValue} />
            <label htmlFor="address_detail">Alamat Lengkap</label>
            <textarea name="address_detail" id="address_detail" cols="30" rows="10" className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" value={userData.address_detail} onChange={handleChangeValue}></textarea>
            <label htmlFor="existing">Apakah Pernah Menjadi Mitra Gofleet Sebelumnya?</label>
            <select name="existing" id="existing" value={userData.existing} onChange={e => handleChangeValue(e)} className="mt-1 mb-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                <option value="true">Ya</option>
                <option value="false">Tidak</option>
            </select>
            <p className="text-gray-700 font-thin text-md mb-3">
                Dengan klik "Daftar Sekarang", saya setuju dengan Ketentuan Penggunaan GoFleet.           
            </p>
            <p className="text-gray-700 font-thin text-md mb-3">
                Saya memahami bahwa semua penawaran di website ini bersifat tidak mengikat dan dapat berubah sewaktu-waktu. Saya juga setuju bahwa GOFLEET atau perwakilannya dapat menghubungi saya melalui email, telepon, atau SMS (termasuk dengan cara otomatis) dialamat email atau nomor yang saya berikan, termasuk untuk tujuan pemasaran.
            </p>
            <button className="w-full bg-primary text-white rounded-md text-lg mt-5 p-2" type="submit" value="submit">Selesaikan</button>

        </form>)
        break;
        default:
        pageContent = (<VerifyKTP title={'Gabung GoBan'} handleVerifikasi={handleVerifikasi}/>)
        break;
    }

    return (
        <Background>
            {pageContent}
        </Background>
    )
}

export default GoBan